define("coffeecup/components/button-submit-alt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * the tag name of the component
     */
    tagName: 'button',

    /**
     * static class bindings
     */
    classNames: ['btn', 'btn-primary'],

    /**
     * bind the disabled attribute
     */
    attributeBindings: ['disabled'],

    /**
     * determines if the button is enabled or disabled
     */
    disabled: false,

    /**
     * determines if the alternative mode is active
     */
    alternative: false,

    /**
     * the default label for the submit button
     */
    label: 'submit',

    /**
     * the alternative label for the submit button (will be triggered by pressing 'alt')
     */
    altLabel: 'alt-submit',

    /**
     * {Boolean} true if the alt action should be disabled
     */
    disableAltAction: false,

    /**
     * {Boolean} true if the alt mode is active
     */
    enableAltAction: Ember.computed('alternative', 'disableAltAction', {
      get: function get() {
        var disableAltAction = this.get('disableAltAction');
        var alternative = this.get('alternative');
        return disableAltAction ? false : alternative;
      }
    }),

    /**
     * the button label dependent on the current mode
     */
    _label: Ember.computed('enableAltAction', {
      get: function get() {
        return this.get('enableAltAction') ? this.get('altLabel') : this.get('label');
      }
    }),

    /**
     * register the alt-key handlers
     */
    didInsertElement: function didInsertElement() {
      $('body').keydown({
        _self: this
      }, this.setAlternative).keyup({
        _self: this
      }, this.setDefault);
    },

    /**
     * unregister the alt-key handlers
     */
    willDestroyElement: function willDestroyElement() {
      $('body').unbind('keydown', this.setAlternative).unbind('keyup', this.setDefault);
    },

    /**
     * sets the mode of this button component to alternative mode
     * @param e the key-event
     */
    setAlternative: function setAlternative(e) {
      if (e.keyCode == 18) {
        e.preventDefault();

        e.data._self.set('alternative', true);
      }
    },

    /**
     * sets the mode of this button component to default mode
     * @param e the key-event
     */
    setDefault: function setDefault(e) {
      if (e.keyCode == 18) {
        e.preventDefault();

        e.data._self.set('alternative', false);
      }
    },

    /**
     * triggered when the user clicks on the button.
     * Sends an action depending on the current mode
     * @param e the click-event
     */
    click: function click(e) {
      e.preventDefault();
      var alternative = this.get('enableAltAction');
      this.sendAction(alternative ? 'altAction' : 'action');
    }
  });

  _exports.default = _default;
});