define("coffeecup/components/input/text-field-time", ["exports", "coffeecup/utils/format-duration"], function (_exports, _formatDuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    classNames: 'form-control textfield-time',
    attributeBindings: ['disabled', 'duration'],
    disabled: null,
    duration: null,
    doNotRerender: false,
    initialMaxlength: null,
    formatter: Ember.inject.service(),
    init: function init() {
      this._super();

      this.on('paste', this, this._didUpdateElement);
      this.updateValue();
      this.set('initialMaxlength', this.get('maxlength'));
    },
    updateValue: function updateValue() {
      var duration = this.get('duration') || 0;
      var durationFmt = this.get('formatter').formatDuration(duration, true);
      this.set('value', durationFmt);
    },
    updateDuration: Ember.observer('value', function () {
      var currentValue = this.get('value');
      this.set('doNotRerender', true);

      this._updateMaxlength(currentValue);

      this.set('duration', Number(parseFloat((0, _formatDuration.normalizeDuration)(currentValue)).toFixed(4)));
      this.set('doNotRerender', false);
    }),
    _didUpdateElement: Ember.observer('duration', function () {
      var self = this;

      if (!self.get('doNotRerender')) {
        setTimeout(function () {
          if (!self.isDestroyed) {
            self.updateValue();
            self.rerender();
          }
        }, 10);
      }
    }),
    _updateMaxlength: function _updateMaxlength(currentValue) {
      if (currentValue.indexOf(',') == -1 && currentValue.indexOf('.') == -1 && currentValue.indexOf(':') == -1 && currentValue.indexOf(';') == -1) {
        //no digit places
        this.set('maxlength', this.get('initialMaxlength') - 3); //-3 because of "." and two "decimal places"
      } else {
        // Special Case, needed so that you can't write more than allowed digits on the left side of the decimal point,
        // when only one decimal place is set already.
        //      if(((currentValue.length-currentValue.indexOf(",")) == 2)
        //        || ((currentValue.length-currentValue.indexOf(".")) == 2)){ //If only one digit place is used
        //        this.set('maxlength', this.get('initialMaxlength')-1); //-1 because of one "decimal place"
        //      } else {
        this.set('maxlength', this.get('initialMaxlength')); //reset maxlength
        //      }
      }
    },
    keyDown: function keyDown(e) {
      this._super(); //Hack to let the user enter digit places, when the maxlength of the value is already reached


      var key = e.keyCode; // CAUTION Keycodes
      // in Chrome and IE (":"=186 , ";"=186 , "."=190 , ","=188),
      // in Mozilla (":"=190 , ";"=188 , "."=190 ","=188),
      // in Opera (":"=59 , ";"=59 , "."=46 , ","=44)!
      // see http://unixpapa.com/js/key.html  (partly out of date!), http://www.w3.org/2002/09/tests/keys.html ,
      // http://www.quirksmode.org/js/keys.html#t00

      if (key == 186 || key == 190 || key == 188 || key == 59 || key == 46 || key == 44) {
        this.set('maxlength', this.get('initialMaxlength')); //reset maxlength
      }
    },
    focusIn: function focusIn(e) {
      this._super();
    },
    focusOut: function focusOut(e) {
      this._super();

      this._didUpdateElement();
    }
  });

  _exports.default = _default;
});