define("coffeecup/components/planner/user-project-timeline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['row-timeline'],
    viewport: Ember.inject.service('planner.viewport'),
    activeModal: Ember.inject.service('planner.active-modal'),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    assignments: null,
    user: null,
    project: null,
    editable: null,
    actions: {
      onClick: function onClick() {
        this.sendAction('createAssignment', this.get('user'), this.get('project'), this.get('viewport.selectedDay'));
      },
      onSelect: function onSelect(startDate, endDate, allocation) {
        this.sendAction('createAssignment', this.get('user'), this.get('project'), startDate, endDate, allocation);
      },
      updateAssignment: function updateAssignment(model) {
        var _this = this;

        if (model.overlapsAnySibling()) {
          this.get('notifications').error('plannerAssignmentsMayNotOverlap');
          Ember.run.next(function () {
            model.rollbackAttributes();
          });
          return false;
        }

        return model.save().then(function () {
          _this.get('notifications').success('alert.saved', 'plannerAssignment');

          _this.sendAction('afterUpdateAssignment', model);
        }).catch(function (err) {
          // console.error(err);
          model.rollbackAttributes();

          _this.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'plannerAssignment');
        });
      },
      splitAssignment: function splitAssignment(plannerAssignment) {
        this.sendAction('splitAssignment', plannerAssignment);
      },
      editAssignment: function editAssignment(plannerAssignment) {
        this.sendAction('editAssignment', plannerAssignment);
      }
    }
  });

  _exports.default = _default;
});