define("coffeecup/components/planner/timeline-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['timeline-container'],
    classNameBindings: ['isDragging', 'isResizing', 'viewport.showSummary:summary-open', 'viewport.zoom.zoomClass', 'showMilestones:show-milestones'],
    viewport: Ember.inject.service('planner.viewport'),
    isDragging: false,
    isResizing: false,
    showMilestones: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var $win = Ember.$(window);
      $win.on('resize.schedule', Ember.run.bind(this, 'sendAction', 'onWindowResize'));
      $win.on('dragstart.schedule', Ember.run.bind(this, 'set', 'isDragging', true));
      $win.on('dragstop.schedule', Ember.run.bind(this, 'set', 'isDragging', false));
      $win.on('resizestart.schedule', Ember.run.bind(this, 'set', 'isResizing', true));
      $win.on('resizestop.schedule', Ember.run.bind(this, 'set', 'isResizing', false));
    },
    willDestroyElement: function willDestroyElement() {
      var $win = Ember.$(window);
      $win.off('.schedule');

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});