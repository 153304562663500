define("coffeecup/components/manage/projects/expenses/expense-modal", ["exports", "coffeecup/mixins/buffered-form", "coffeecup/validations/expense", "coffeecup/utils/attachment-upload", "coffeecup/mixins/form-validate-submit"], function (_exports, _bufferedForm, _expense, _attachmentUpload, _formValidateSubmit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_bufferedForm.default, _formValidateSubmit.default, {
    session: Ember.inject.service(),
    focus: 'description',
    expense: null,
    expenseCategories: null,
    formValidation: _expense.default,
    model: Ember.computed.alias('expense'),
    errors: {},
    attachment: null,
    attachmentBlob: null,
    attachmentChanged: false,
    dayProxy: Ember.computed('form.day.content', {
      get: function get() {
        var form = this.get('form');
        return Ember.get(form, 'day.content') || Ember.get(form, 'day');
      },
      set: function set(key, value) {
        var form = this.get('form');
        Ember.run.next(this, function () {
          form.set('day', value);
        });
        return value;
      }
    }),
    actions: {
      save: function save() {
        var _this = this;

        this.set('errors', {});

        if (!this.get('submitting')) {
          this.set('submitting', true);
          var isNew = this.get('expense.isNew');
          this.get('form').validate().then(function () {
            if (_this.get('form.isValid')) {
              _this.get('form').execute();

              return _this.get('expense').save();
            } else {
              _this.set('errors', _this.get('form.error'));

              throw {
                validationError: true
              };
            }
          }).then(function () {
            if (!_this.get('attachmentChanged')) {
              return;
            }

            var token = _this.get('session.data.authenticated.access_token');

            var modelId = _this.get('expense').id;

            return (0, _attachmentUpload.attachmentUploadFile)(_this.get('attachmentBlob'), _attachmentUpload.ATTACHMENT_MODEL_EXPENSE, modelId, token);
          }).then(function (res) {
            if (res) {
              _this.get('expense').set('attachment', res);
            }

            _this.sendAction('onCreateNewExpense', _this.get('expense'), isNew);

            _this.send('onHide');

            _this.get('notifications').success('alert.saved', 'expense');

            _this.set('submitting', false);

            _this.get('expense.project.analytics').reload();
          }).catch(function (err) {
            if (err && err.validationError) {
              _this.get('notifications').info('pleaseCheckYourInputs');
            } else {
              _this.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'expense');
            }

            _this.set('submitting', false);
          });
        }
      },
      onHide: function onHide() {
        if (this.get('expense.isNew')) {
          this.get('expense').destroyRecord();
        }

        this.sendAction('onHide');
      },
      downloadAttachment: function downloadAttachment() {
        this.sendAction('downloadAttachment', this.get('form.attachment.id'));
      },
      onFileChange: function onFileChange(blob, name) {
        var attachment = this.get('form.attachment') || {};
        Ember.set(attachment, 'filename', name);
        this.set('form.attachment', attachment);
        this.set('attachmentBlob', blob);
        this.set('attachmentChanged', true);
      },
      quantityChanged: function quantityChanged() {
        this.set('form.cost', this.get('form.quantity') * this.get('form.expenseCategory.pricePerUnit'));
      }
    }
  });

  _exports.default = _default;
});