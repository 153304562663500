define("coffeecup/components/dashboard-module/user-revenue", ["exports", "coffeecup/utils/build-url", "coffeecup/config/environment"], function (_exports, _buildUrl, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * INJECTIONS
     */
    store: Ember.inject.service(),
    formatter: Ember.inject.service(),
    session: Ember.inject.service(),

    /**
     * PROPERTIES
     */
    totalAmount: 0,
    hasBigValues: false,
    draggable: true,
    revenueData: [],
    currentUser: null,
    alertColor: _environment.default.constants.ALERT_COLOR,
    chartColors: [_environment.default.constants.REVENUE_COLOR, _environment.default.constants.REVENUE_COLOR],
    modes: ['performance'],

    /**
     * COMPUTED PROPERTIES
     */
    isModeRevenue: Ember.computed.equal('currentMode', 'performance'),
    legendItems: Ember.computed('amountSpentTotal', 'amountOutOfBudgetTotal', 'currentMode', function () {
      var formatter = this.get('formatter');
      return [{
        title: 'totalLabel',
        titleValue: formatter.formatCurrency(this.get('totalAmount'))
      }, {
        title: 'inBudget',
        titleValue: formatter.formatCurrency(this.get('amountSpentTotal')),
        color: _environment.default.constants.REVENUE_COLOR_LIGHT
      }, {
        title: 'outOfBudget',
        titleValue: formatter.formatCurrency(this.get('amountOutOfBudgetTotal')),
        color: _environment.default.constants.ALERT_COLOR
      }];
    }),
    currentMode: Ember.computed('module', {
      get: function get() {
        var currentMode = 'performance';
        var settings = this.get('module.settingsObj');

        if (settings && settings.currentMode) {
          currentMode = settings.currentMode;
        }

        return 'performance';
      },
      set: function set(key, newValue) {
        var module = this.get('module');
        var settings = module.get('settingsObj');

        if (newValue !== '') {
          settings.currentMode = newValue;
          module.set('settingsObj', settings);
          module.save();
        }

        return 'performance';
      }
    }),
    year: Ember.computed('module', {
      get: function get() {
        var year = this.get('clock.now').format('YYYY');
        var settings = this.get('module.settingsObj');

        if (settings && settings.year) {
          year = settings.year;
        }

        return year;
      },
      set: function set(key, newValue) {
        var module = this.get('module');
        var settings = module.get('settingsObj');

        if (newValue !== '') {
          settings.year = newValue;
          module.set('settingsObj', settings);
          module.save();
        }

        return newValue;
      }
    }),

    /**
     * OBSERVERS
     */
    _loadRevenueDataObserver: Ember.observer('year', 'currentUser', function () {
      Ember.run.once(this, '_loadRevenueData');
    }),
    _loadRevenueData: function _loadRevenueData() {
      var _this = this;

      var userId = this.get('currentUser.id');
      var formatter = this.get('formatter');
      var i18n = this.get('i18n');

      if (userId) {
        var revenueUrl = (0, _buildUrl.default)(this.get('store'), 'analytics/users/revenue/monthly/' + this.get('year'), {
          user: userId,
          cachedRevenues: false
        });
        this.get('session').getJSON(revenueUrl).then(function (revenueData) {
          var hasBigValues = false;
          revenueData = revenueData.map(function (revenueElement) {
            if (revenueElement.amount) {
              revenueElement.amount = Math.round(revenueElement.amount * 100) / 100;
              revenueElement.amountOutOfBudget = Math.round(revenueElement.amountOutOfBudget * 100) / 100;
              revenueElement.totalAmount = Math.round(revenueElement.totalAmount * 100) / 100; // used so that no line is drawn

              hasBigValues = true;
              revenueElement.legendText = moment.months(revenueElement.month - 1) + ': ' + formatter.formatCurrency(revenueElement.amount);
            } else {
              revenueElement.amount = 0;
            }

            revenueElement.month = moment.monthsShort(revenueElement.month - 1);
            var balloonText = "<b>".concat(i18n.t('totalLabel'), "</b>: ").concat(formatter.formatCurrency(revenueElement.totalAmount), "<br>");
            balloonText += "<b>".concat(i18n.t('inBudget'), "</b>: ").concat(formatter.formatCurrency(revenueElement.amount), "<br>");

            if (revenueElement.amountOutOfBudget !== 0) {
              balloonText = "".concat(balloonText, "<span class=\"text-danger\"><b>").concat(i18n.t('amountOutOfBudget'), "</b>: ").concat(formatter.formatCurrency(revenueElement.amountOutOfBudget), "</span><br>");
            }

            revenueElement.balloonText = balloonText;
            return revenueElement;
          });
          var totalSum = revenueData.reduce(function (prev, curr) {
            return prev + curr.amount;
          }, 0);
          var totalOutOfBudget = revenueData.reduce(function (prev, curr) {
            return prev + curr.amountOutOfBudget;
          }, 0);

          _this.set('hasBigValues', hasBigValues);

          _this.set('amountSpentTotal', totalSum);

          _this.set('amountOutOfBudgetTotal', totalOutOfBudget);

          _this.set('totalAmount', totalSum + totalOutOfBudget);

          _this.set('revenueData', revenueData);
        });
      }
    },
    _insertGraph: Ember.observer('revenueData', 'currentMode', function () {
      var data = this.get('revenueData');
      var formatter = this.get('formatter');
      var userValueAxis = {
        stackType: 'regular',
        gridAlpha: 0.07,
        position: 'left',
        unit: '€',
        autoGridCount: false,
        axisAlpha: 0,
        gridCount: 2,
        labelFunction: function labelFunction(value, valueText, valueAxis) {
          return formatter.formatCurrency(value, true);
        }
      };

      if (!this.get('hasBigValues')) {
        userValueAxis.maximum = 110;
      }

      var guides = [];
      var chartOptions = {
        type: 'serial',
        theme: 'light',
        marginRight: 30,
        color: '#4A4A4A',
        dataProvider: data,
        fontFamily: 'robotolight',
        fontSize: 11,
        valueAxes: [userValueAxis],
        colors: [_environment.default.constants.ALERT_COLOR, _environment.default.constants.REVENUE_COLOR],
        graphs: [{
          balloonText: '',
          fillAlphas: 1,
          lineAlpha: 0,
          title: 'User',
          valueField: 'amountOutOfBudget',
          type: 'column'
        }, {
          balloonText: '[[balloonText]]',
          fillAlphas: 1,
          lineAlpha: 0,
          title: 'User',
          colorField: 'customColor',
          valueField: 'amount',
          type: 'column'
        }],
        chartCursor: {
          enabled: true,
          cursorColor: _environment.default.constants.ALERT_COLOR,
          bulletsEnabled: true,
          zoomable: false
        },
        balloon: {
          textAlign: 'left',
          maxWidth: 500
        },
        guides: guides,
        plotAreaBorderAlpha: 0,
        marginTop: 10,
        marginLeft: 0,
        marginBottom: 0,
        categoryField: 'month',
        categoryAxis: {
          axisColor: '#DADADA',
          gridThickness: 0
        }
      };
      AmCharts.makeChart('chartdiv-' + this.get('elementId'), chartOptions);
    }),

    /**
     * FUNCTIONS
     */
    didInsertElement: function didInsertElement() {
      this._loadRevenueData();
    },
    actions: {
      rangeTypeChanged: function rangeTypeChanged() {}
    }
  });

  _exports.default = _default;
});