define("coffeecup/components/input/text-field-price", ["exports", "coffeecup/mixins/has-error", "numeral", "coffeecup/config/environment"], function (_exports, _hasError, _numeral, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component that displays an input field for entering currency. Appropriately displays the correct currency
   * symbol based on the account settings.
   *
   * @author Alexander Stonehouse
   */
  var TextFieldPriceComponent = Ember.Component.extend(_hasError.default, {
    accountService: Ember.inject.service('account'),
    accountSettings: Ember.computed.reads('accountService.accountSettings'),
    formatter: Ember.inject.service(),
    classNames: ['input-price'],
    classNameBindings: ['isSymbolWide:wide-currency', 'isLarge:large-text'],
    uid: null,
    name: 'textFieldPrice',
    placeholder: null,
    disabled: false,
    maxLength: 7,
    allowMinus: false,
    customCurrency: false,
    textSize: 'medium',
    didInsertElement: function didInsertElement() {
      var $input = this.$('input');
      Ember.run.next(this, function () {
        var _this = this;

        $input.on('blur', function () {
          Ember.run(function () {
            if (!_this.get('isDestroyed')) {
              _this.set('focus', null);

              if (_this.onBlur) {
                _this.onBlur();
              }
            }
          });
        });

        this._focus();

        this._customCurrencyPadding();
      });
    },
    _focus: function _focus() {
      var focus = this.get('focus');
      var key = this.get('key');

      if (focus && key && focus === key && this.$('input') && this.$('input').length > 0) {
        this.$('input').focus();
      }
    },
    options: Ember.computed('maxValue', 'allowMinus', 'accountSettings.numberFormat', function () {
      var numberFormat = this.get('accountSettings.numberFormat');
      var groupSeparator, radixPoint;

      switch (numberFormat) {
        case _environment.default.constants.NUMBER_FORMAT_COMMA_DELIMITER:
          groupSeparator = ',';
          radixPoint = '.';
          break;

        case _environment.default.constants.NUMBER_FORMAT_POINT_DELIMITER:
        default:
          groupSeparator = '.';
          radixPoint = ',';
          break;
      }

      var options = {
        alias: 'numeric',
        groupSeparator: groupSeparator,
        radixPoint: radixPoint,
        digits: 2,
        allowMinus: this.get('allowMinus'),
        prefix: '',
        placeholder: '0',
        unmaskAsNumber: true
      };
      if (this.get('maxValue')) options.max = this.get('maxValue');
      if (this.get('minValue')) options.max = this.get('minValue');
      return options;
    }),
    customCurrencyPadding: null,
    _customCurrencyPadding: Ember.observer('currencySymbol', function () {
      var _this2 = this;

      if (this.get('customCurrency')) {
        Ember.run.next(function () {
          var customWidthElement = $('.custom-currency-width');
          if (!customWidthElement || customWidthElement.length === 0) return null;

          _this2.set('customCurrencyPadding', "padding-right: ".concat(customWidthElement.width() + 10, "px;"));
        });
      } else {
        this.set('customCurrencyPadding', null);
      }
    }),
    _currency: 0,
    currency: Ember.computed('_currency', 'maxValue', {
      get: function get() {
        return this.get('_currency');
      },
      set: function set(key, val) {
        if (this.get('maxValue') && val > this.get('maxValue')) {
          val = this.get('maxValue');
        }

        this.set('_currency', val);
        return this.get('formatter').formatNumber(val);
      }
    }),
    onCurrencyChanged: Ember.observer('_currency', function () {
      this.sendAction('onChange', this.get('currency'));
    }),

    /**
     * Wide currency symbols such as CHF or NT$ need bit more space in the UI.
     */
    isSymbolWide: Ember.computed('currencySymbol', 'customCurrency', {
      get: function get() {
        var symbol = this.get('currencySymbol');
        return symbol.length > 2 && !this.get('customCurrency');
      }
    }),
    isLarge: Ember.computed.equal('textSize', 'large'),
    currencySymbol: Ember.computed('currency', {
      get: function get() {
        return _numeral.default.localeData().currency.symbol;
      }
    }),
    keyPress: function keyPress(event) {
      if (event.key === 'Enter') {
        this.sendAction('onEnter');
      }
    },
    keyDown: function keyDown(event) {
      if (event.key === 'Escape') {
        this.sendAction('cancel');
        this.sendAction('onEscape');
      }
    }
  });
  var _default = TextFieldPriceComponent;
  _exports.default = _default;
});