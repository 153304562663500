define("coffeecup/components/sortable-objects", ["exports", "ember-drag-drop/components/sortable-objects"], function (_exports, _sortableObjects) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _sortableObjects.default.extend({
    dragEnter: function dragEnter(event) {
      var _this = this;

      //needed so drop event will fire
      event.stopPropagation();

      if (this.get('enableSort')) {
        Ember.run.once(this, function () {
          if (!_this.get('dragCoordinator.isMoving') && _this.sortEndAction) {
            _this.sortEndAction(event, _this.get('sortableObjectList'), _this.get('day'), true);
          }
        });
      }

      return false;
    },
    dragOver: function dragOver(event) {
      //needed so drop event will fire
      event.stopPropagation();
      return false;
    },
    drop: function drop(event) {
      event.stopPropagation();

      if (this.get('enableSort') && this.sortEndAction) {
        this.sortEndAction(event, this.get('sortableObjectList'), this.get('day'));
      }
    }
  });

  _exports.default = _default;
});