define("coffeecup/adapters/team-assignment", ["exports", "coffeecup/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      return this._super.apply(this, arguments) + '?previousTeamId=' + snapshot.adapterOptions.previousTeamId;
    }
  });

  _exports.default = _default;
});