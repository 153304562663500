define("coffeecup/components/validation/footer-submit", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('currentUser'),
    currentUser: Ember.computed.alias('currentUserService.currentUser'),
    classNames: ['d-flex', 'validation-footer-element'],
    showSubmitButton: Ember.computed.and('validation.isRejected', 'currentUserIsValidationUser'),
    currentUserIsValidationUser: Ember.computed('validation.user', 'currentUser', function () {
      return this.get('validation.user.id') === this.get('currentUser.id');
    }),
    VALIDATION_SUBMITTED: _environment.default.constants.VALIDATION_SUBMITTED,
    actions: {
      submitValidation: function submitValidation() {
        this.set('openModal', true);
      }
    }
  });

  _exports.default = _default;
});