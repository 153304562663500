define("coffeecup/controllers/time-entries/verifying/index", ["exports", "coffeecup/utils/date", "coffeecup/utils/build-url"], function (_exports, _date, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    validationService: Ember.inject.service('validation'),
    worktimeController: Ember.inject.controller('time-entries.verifying'),
    daysInPeriod: Ember.computed('week', function () {
      var startDate = moment(this.get('week')).startOf('week');
      var endDate = moment(this.get('week')).endOf('week');
      return (0, _date.enumerateDaysBetweenDates)(startDate, endDate);
    }),
    weekLabel: Ember.computed('week', function () {
      return "".concat(this.get('i18n').t('calendarWeek'), " ").concat(moment(this.get('week')).isoWeek(), " ").concat(moment(this.get('week')).format('YYYY'));
    }),
    mondayDate: Ember.computed('daysInPeriod.[]', function () {
      return this.get('daysInPeriod')[0].format('DD.MM.');
    }),
    tuesdayDate: Ember.computed('daysInPeriod.[]', function () {
      return this.get('daysInPeriod')[1].format('DD.MM.');
    }),
    wednesdayDate: Ember.computed('daysInPeriod.[]', function () {
      return this.get('daysInPeriod')[2].format('DD.MM.');
    }),
    thursdayDate: Ember.computed('daysInPeriod.[]', function () {
      return this.get('daysInPeriod')[3].format('DD.MM.');
    }),
    fridayDate: Ember.computed('daysInPeriod.[]', function () {
      return this.get('daysInPeriod')[4].format('DD.MM.');
    }),
    saturdayDate: Ember.computed('daysInPeriod.[]', function () {
      return this.get('daysInPeriod')[5].format('DD.MM.');
    }),
    sundayDate: Ember.computed('daysInPeriod.[]', function () {
      return this.get('daysInPeriod')[6].format('DD.MM.');
    }),
    week: Ember.computed.alias('worktimeController.week'),
    validationFilter: Ember.computed.alias('worktimeController.validationFilter'),
    noValidationActive: false,
    isLoading: false,
    userData: [],
    accountService: Ember.inject.service('account'),
    accountSettings: Ember.computed.reads('accountService.accountSettings'),
    hasAnyValidationActive: Ember.computed.or('accountSettings.activeCommentValidation', 'accountSettings.activeBreakValidation', 'accountSettings.activeHoursValidation'),
    filteredUserData: Ember.computed('userData.[]', 'validationFilter', function () {
      var _this = this;

      var validationFilter = this.get('validationFilter');
      return this.get('userData').filter(function (el) {
        if (el.isAlreadyValidated && !el.validation) {
          el.validation = _this.get('store').peekRecord('validation', el.isAlreadyValidated);
        }

        if (validationFilter === 'all') {
          return true;
        } else if (validationFilter === 'approved') {
          return el.validation && el.validation.get('isApproved');
        } else if (validationFilter === 'rejected') {
          return el.validation && el.validation.get('isRejected');
        } else {
          return !el.validation || el.validation.get('isOpen') || el.validation.get('isSubmitted');
        }
      });
    }),
    actions: {
      updateList: function updateList() {
        var _this2 = this;

        this.notifyPropertyChange('validationFilter');
        this.get('session').getJSON((0, _buildUrl.default)(this.store, 'validate/invalid', {
          restrictOnAssignedUsers: true
        })).then(function (openValidationWeeks) {
          _this2.get('validationService').updateOpenValidationCount(openValidationWeeks);
        }).catch(console.error);
      }
    }
  });

  _exports.default = _default;
});