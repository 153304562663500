define("coffeecup/adapters/analytics/project-period", ["exports", "coffeecup/adapters/analytics/project"], function (_exports, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //adapter/analytics/project.js
  var _default = _project.default.extend({
    pathForType: function pathForType() {
      return 'analytics/projects';
    }
  });

  _exports.default = _default;
});