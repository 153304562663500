define("coffeecup/components/analytics-module/project/budget", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pieService: Ember.inject.service('charts/pie'),
    project: null,
    analytics: null,
    pieChartData: [],
    indicatorColor: _environment.default.constants.REVENUE_COLOR_LIGHT,
    isSpentOverBudget: Ember.computed.reads('analytics.isSpentOverBudget'),
    amountBudgetTotal: Ember.computed.reads('analytics.amountBudgetTotal'),
    billableTotal: Ember.computed.reads('analytics.amountSpentTotal'),
    billableTimeEntries: Ember.computed.reads('analytics.amountSpent'),
    billableExpenses: Ember.computed.reads('analytics.expensesTotal'),
    amountRemaining: Ember.computed.reads('analytics.amountRemaining'),
    budgetUsedInPercent: Ember.computed.reads('analytics.budgetUsedInPercent'),
    budgetRemainingInPercent: Ember.computed.reads('analytics.budgetRemainingInPercent'),
    didInsertElement: function didInsertElement() {
      this._updateUi();
    },
    amountRemainingAbs: Ember.computed('amountRemaining', function () {
      return Math.abs(this.get('amountRemaining'));
    }),
    _updateUi: Ember.observer('budgetUsedInPercent', function () {
      var percentage = this.get('budgetUsedInPercent');
      this.set('indicatorColor', this.get('pieService').getIndicatorColor(percentage * 100));
      if (percentage > 1) percentage -= 1;
      this.set('pieChartData', [{
        value: percentage
      }, {
        value: 1 - percentage
      }]);
    })
  });

  _exports.default = _default;
});