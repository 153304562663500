define("coffeecup/components/input/cc-create-select", ["exports", "ember-power-select-with-create/components/power-select-with-create"], function (_exports, _powerSelectWithCreate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _powerSelectWithCreate.default.extend({
    eventType: 'click',
    // Place here your system-wide preferences
    searchEnabled: true,
    allowClear: false,
    classNameBindings: ['showPlusIcon:select-plus-icon', 'isSmall:select-size-small'],
    showPlusIcon: false,
    isSmall: false,
    createPlaceholder: null,
    tagName: 'div',
    i18n: Ember.inject.service(),
    loadingMessage: Ember.computed('i18n.locale', function () {
      return this.get('i18n').t('selects.loading');
    }),
    buildSuggestionLabel: function buildSuggestionLabel(term) {
      if (this.buildSuggestion) {
        return this.buildSuggestion(term);
      }

      if (this.get('createPlaceholder')) {
        return this.get('i18n').t(this.get('createPlaceholder'), {
          term: term
        });
      }

      return "Add \"".concat(term, "\"...");
    }
  });

  _exports.default = _default;
});