define("coffeecup/components/notification-message", ["exports", "ember-cli-notifications/components/notification-message", "ember-get-config"], function (_exports, _notificationMessage, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var globals = _emberGetConfig.default['ember-cli-notifications'] || {}; // Import app config object

  var _default = _notificationMessage.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.icons = globals.icons || 'svg';

      if (this.icons === 'custom') {
        this.successIcon = globals.successIcon;
        this.infoIcon = globals.infoIcon;
        this.warningIcon = globals.warningIcon;
        this.errorIcon = globals.errorIcon;
        this.closeIcon = globals.closeIcon;
      }

      this.svgs = {
        'success': 'success',
        'warning': 'warning',
        'info': 'info',
        'error': 'error'
      };
    }
  });

  _exports.default = _default;
});