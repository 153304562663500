define("coffeecup/components/main-menu", ["exports", "coffeecup/mixins/image-preview"], function (_exports, _imagePreview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_imagePreview.default, {
    menu: [],
    currentUserService: Ember.inject.service('currentUser'),
    session: Ember.inject.service(),
    permissions: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    subscription: Ember.computed.alias('accountService.subscription'),
    currentUser: Ember.computed.oneWay('currentUserService.currentUser'),
    numberOfOpenAbsenceRequests: 0,
    numberOfOpenValidations: 0,
    subscriptionIsInTrialWithoutPayment: Ember.computed('permissions.customer', 'permissions.subscription', function () {
      var customer = this.get('permissions.customer') || {};
      var subscription = this.get('permissions.subscription') || {};
      return !customer.hasPaymentMethod && subscription.isInTrial;
    }),
    _updateMenuStructure: Ember.observer('permissions.activePermissions', function () {
      this.notifyPropertyChange('menu');
    }),
    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      }
    }
  });

  _exports.default = _default;
});