define("coffeecup/controllers/planner", ["exports", "coffeecup/config/environment", "ember-data", "coffeecup/utils/stream-map"], function (_exports, _environment, _emberData, _streamMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _ENV$constants = _environment.default.constants,
      PLANNER_MODE_PROJECTS = _ENV$constants.PLANNER_MODE_PROJECTS,
      PLANNER_MODE_TEAM = _ENV$constants.PLANNER_MODE_TEAM;

  var _default = Ember.Controller.extend({
    queryParams: {
      'viewport.startDateFormatted': {
        scope: 'controller',
        as: 'startDate'
      },
      'zoom.zoomedOut': {
        scope: 'controller',
        as: 'zoomedOut'
      }
    },
    mode: null,
    isModeProjects: Ember.computed.equal('mode', PLANNER_MODE_PROJECTS),
    isModeTeam: Ember.computed.equal('mode', PLANNER_MODE_TEAM),
    application: Ember.inject.controller(),
    plannerProjectsController: Ember.inject.controller('planner.projects'),
    accountService: Ember.inject.service('account'),
    permissions: Ember.inject.service(),
    accountSettings: Ember.computed.alias('accountService.accountSettings'),
    currentUserService: Ember.inject.service('currentUser'),
    userAssignmentsService: Ember.inject.service('user-assignments'),
    numberOfOpenAbsenceRequests: Ember.computed.reads('application.numberOfOpenAbsenceRequests'),
    rowsAreExpanded: null,
    clock: Ember.inject.service(),
    zoom: Ember.inject.service('planner.zoom'),
    router: Ember.inject.service(),
    viewport: Ember.inject.service('planner.viewport'),
    hideShowSummaryOption: null,
    showSummary: Ember.computed.reads('viewport.showSummary'),
    showPlannerProjectDuration: Ember.computed.reads('currentUserService.currentUser.uiState.showPlannerProjectDuration'),
    showAbsencesInPlanner: Ember.computed.reads('currentUserService.currentUser.uiState.showAbsencesInPlanner'),
    showPercentOrAbsoluteCapacities: Ember.computed.reads('currentUserService.currentUser.uiState.showPercentOrAbsoluteCapacities'),
    // showChartOnPlanningPage: reads('currentUserService.currentUser.uiState.showChartOnPlanningPage'),
    showHiddenUsers: Ember.computed.reads('currentUserService.currentUser.uiState.showHiddenUsers'),
    plannerShowMilestoneSummary: Ember.computed.reads('currentUserService.currentUser.uiState.plannerShowMilestoneSummary'),
    showAbsenceRequestsInPlanner: Ember.computed.reads('currentUserService.currentUser.uiState.showAbsenceRequestsInPlanner'),
    showDailySummaryView: Ember.computed.reads('currentUserService.currentUser.uiState.showDailySummaryView'),
    isDailyPlanningMode: Ember.computed.not('viewport.isWeeklyPlanningMode'),
    showSummaryOptionsInMenu: Ember.computed.and('isModeTeam', 'isDailyPlanningMode'),
    workingDaysService: Ember.inject.service('working-days'),
    isPlannerView: Ember.computed('router.currentRouteName', function () {
      var _this$get;

      return ((_this$get = this.get('router.currentRouteName')) === null || _this$get === void 0 ? void 0 : _this$get.indexOf('absences')) === -1;
    }),
    viewportObserver: Ember.observer('viewport.endDate', function () {
      var _this = this;

      Ember.run.later(function () {
        try {
          _this.send('viewportChanged', _this.get('viewport.startDate'), _this.get('viewport.endDate'));
        } catch (e) {}
      }, 1);
    }),
    setWeeksForWindow: Ember.on('init', Ember.observer('viewport.dayWidth', 'viewport.daysPerWeek', function () {
      var $window = Ember.$(window);
      var weeksWidth = this.get('viewport.dayWidth') * this.get('viewport.daysPerWeek');
      var weeksToDisplay = this.get('viewport.weeksToDisplay');
      var newWeeksToDisplay = Math.ceil(($window.width() - this.get('viewport.rowHeaderWidth')) / weeksWidth);

      if (weeksToDisplay !== newWeeksToDisplay) {
        this.set('viewport.weeksToDisplay', newWeeksToDisplay < 2 ? 2 : newWeeksToDisplay);
      }
    })),
    setSideBarWidth: Ember.on('init', function () {
      var $window = Ember.$(window);

      if ($window.width() >= 1200) {
        this.set('viewport.sideBarType', _environment.default.constants.SIDEBAR_TYPE_L);
      }

      if ($window.width() < 1200 && $window.width() >= 860) {
        this.set('viewport.sideBarType', _environment.default.constants.SIDEBAR_TYPE_M);
      }

      if ($window.width() < 860) {
        this.set('viewport.sideBarType', _environment.default.constants.SIDEBAR_TYPE_S);
      }
    }),
    daysForWeek: function daysForWeek(date) {
      var daysToDisplay = 7;
      var currentDay = this.get('clock.day');
      var days = Ember.A();
      var day = date.clone().day(1);

      for (var i = 0; i < daysToDisplay; i++) {
        days.pushObject({
          date: day.clone(),
          isToday: currentDay.isSame(day, 'day')
        });
        day.add(1, 'day');
      }

      return days;
    },
    fullWeekGrid: Ember.computed('accountSettings.activeWeekends', 'viewport.startDate', 'viewport.weeksToDisplay', 'clock.week', function () {
      var weeksToDisplay = this.get('viewport.weeksToDisplay');
      var weeks = Ember.A();
      var startDate = this.get('viewport.startDate').clone();
      var currentWeek = this.get('clock.week');

      for (var i = 0; i < weeksToDisplay; i++) {
        weeks.pushObject({
          date: startDate.clone(),
          isThisWeek: currentWeek.isSame(startDate, 'week'),
          isFirstWeekOfYear: 1 === startDate.week(),
          days: this.daysForWeek(startDate)
        });
        startDate.add(1, 'week');
      }

      return weeks;
    }),
    workWeekGrid: Ember.computed('fullWeekGrid.[]', function () {
      var fullWeekGrid = this.get('fullWeekGrid');
      fullWeekGrid.forEach(function (week) {
        week.days.removeAt(5, 2);
      });
      return fullWeekGrid;
    }),
    weekGrid: Ember.computed('fullWeekGrid', 'workWeekGrid', 'accountSettings.activeWeekends', function () {
      return this.get('accountSettings.activeWeekends') ? this.get('fullWeekGrid') : this.get('workWeekGrid');
    }),
    assignments: [],
    absences: [],
    absenceRequests: [],
    existingTags: [],
    tagAssignments: [],
    milestones: [],
    hasMilestones: Ember.computed.gt('milestones.length', 0),
    showMilestones: Ember.computed.and('hasMilestones', 'currentUserService.currentUser.uiState.plannerShowMilestoneSummary'),
    openAbsenceRequests: Ember.computed('absenceRequests', 'absenceRequests.[]', 'absenceRequests.@each.state', function () {
      return this.get('absenceRequests').filterBy('state', null);
    }),
    currentUserCanWrite: Ember.computed('currentUserService.isAdminOrProjectManager', 'permissions.canManageAllProjects', 'permissions.canManagePlanner', {
      get: function get() {
        var currentUserIsAdmin = this.get('currentUserService.isAdmin');
        if (currentUserIsAdmin) return true;
        var canManagePlanner = this.get('permissions.canManagePlanner');
        if (canManagePlanner) return true;
        var currentUserIsPm = this.get('currentUserService.isProjectManager');
        if (currentUserIsPm) return true;
        return false;
      }
    }),
    disableFrameCalculation: false,
    suspendAssignmentObserver: false,
    dataObserver: Ember.observer('assignments.[]', 'absences.[]', 'openAbsenceRequests.[]', function () {
      Ember.run.once(this, 'transformDataIntoStreams');
    }),
    transformDataIntoStreams: function transformDataIntoStreams() {
      if (!this.get('suspendAssignmentObserver')) {
        var projectPlannerAssignments = (0, _streamMap.default)('plannerAssignments', 'project', this.get('application.allProjects'));
        var userPlannerAssignments = (0, _streamMap.default)('plannerAssignments', 'user', this.get('application.activeUsers'));
        var userAbsences = (0, _streamMap.default)('absences', 'user', this.get('application.activeUsers'));
        var userAbsenceRequests = (0, _streamMap.default)('absenceRequests', 'user', this.get('application.activeUsers'));
        var milestones = (0, _streamMap.default)('milestones', 'project', this.get('application.allProjects'));
        this.get('assignments').forEach(function (assignment) {
          projectPlannerAssignments.push(assignment);
          userPlannerAssignments.push(assignment);
        });
        this.get('absences').forEach(function (absence) {
          userAbsences.push(absence);
        });
        this.get('openAbsenceRequests').forEach(function (absenceRequest) {
          userAbsenceRequests.push(absenceRequest);
        });
        this.get('milestones').forEach(function (ms) {
          milestones.push(ms);
        });
        projectPlannerAssignments.stream();
        userPlannerAssignments.stream();
        userAbsences.stream();
        userAbsenceRequests.stream();
        milestones.stream();
        this.setUserWorkingHours();
      }
    },
    setUserWorkingHours: function setUserWorkingHours() {
      var _this2 = this;

      var startDateWithMargin = this.get('viewport.startDateWithMargin');
      var endDateWithMargin = this.get('viewport.endDateWithMargin');
      this.get('application.activeUsers').forEach(function (user) {
        var workingHours = _this2.get('workingDaysService').getWorkingHoursArray(user, startDateWithMargin, endDateWithMargin, user.get('absences'), user.get('absenceRequests'));

        user.set('workingHours', workingHours);
      });
    },
    showExportModal: false,
    actions: {
      refreshPlannerData: function refreshPlannerData() {
        this.send('fetchProjectsSummaryData');
        this.send('refreshDeferedRows');
      },
      refreshDeferedRows: function refreshDeferedRows() {
        var $scrollable = Ember.$('.planner .scrollable');
        $scrollable.trigger('refresh');
      },

      /**
       * Please use this carefully because it rerenders the whole planner.
       * Rather update a users line directly.
       * If this is used problems with previously deleted elements can occur.
       * @param {PlannerAssignment} assignment
       */
      addAssignment: function addAssignment(assignment) {
        var newAssignment = assignment instanceof _emberData.default.Model ? assignment : this.store.peekRecord('planner-assignment', assignment);

        if (newAssignment.inRange(this.get('viewport.startDate'), this.get('viewport.endDate'))) {
          this.get('assignments').pushObject(newAssignment);
        }

        this.send('refreshPlannerData');
      },
      afterUpdateAssignment: function afterUpdateAssignment(assignment) {
        this.send('refreshPlannerData');
      },
      deleteAssignment: function deleteAssignment(assignment, user, project) {
        var _this3 = this;

        if (user.get('id') && project.get('id')) {
          user.get('plannerAssignments').removeObject(assignment);
          project.get('plannerAssignments').removeObject(assignment);
        }

        Ember.run.next(function () {
          _this3.send('refreshPlannerData');
        });
      },
      createUserAssignmentIfNotExists: function createUserAssignmentIfNotExists(assignment) {
        var _this4 = this;

        //  limit to admin and pm
        if (!this.get('currentUserService.isAdminOrProjectManager')) return;
        var user = assignment.get('user');
        var project = assignment.get('project');

        if (user && project) {
          this.get('userAssignmentsService').createUserAssignmentIfNotExists(user, project).then(function () {
            _this4.send('refreshPlannerData');
          });
        } else {
          this.send('refreshPlannerData');
        }
      },
      goToPreviousWeek: function goToPreviousWeek() {
        var previousWeek = this.get('viewport.startDate').clone().subtract(1, 'week');
        this.set('viewport.startDate', previousWeek);
      },
      goToThisWeek: function goToThisWeek() {
        var thisWeek = this.get('clock.localDayAsUtcMoment').startOf('isoWeek');
        this.set('viewport.startDate', thisWeek);
      },
      goToNextWeek: function goToNextWeek() {
        var nextWeek = this.get('viewport.startDate').clone().add(1, 'week');
        this.set('viewport.startDate', nextWeek);
      },
      onWindowResize: function onWindowResize() {
        this.setSideBarWidth();
        this.setWeeksForWindow();
      },
      zoomIn: function zoomIn() {
        this.get('zoom').in();
      },
      zoomOut: function zoomOut() {
        this.get('zoom').out();
      },
      toggleShowAbsencesInPlanner: function toggleShowAbsencesInPlanner() {
        var showAbsencesInPlanner = !this.get('showAbsencesInPlanner');
        this.get('currentUserService').saveUISettings({
          showAbsencesInPlanner: showAbsencesInPlanner
        });
      },
      toggleShowAbsenceRequestsInPlanner: function toggleShowAbsenceRequestsInPlanner() {
        var showAbsenceRequestsInPlanner = !this.get('showAbsenceRequestsInPlanner');
        this.get('currentUserService').saveUISettings({
          showAbsenceRequestsInPlanner: showAbsenceRequestsInPlanner
        });
      },
      toggleShowPercentOrAbsoluteCapacities: function toggleShowPercentOrAbsoluteCapacities() {
        var showPercentOrAbsoluteCapasities = !this.get('showPercentOrAbsoluteCapacities');
        this.get('currentUserService').saveUISettings({
          showPercentOrAbsoluteCapacities: showPercentOrAbsoluteCapasities
        });
      },
      // toggleShowChartOnPlanningPage() {
      //   let showChartOnPlanningPage = !this.get('showChartOnPlanningPage');
      //   this.get('currentUserService').saveUISettings({
      //     showChartOnPlanningPage: showChartOnPlanningPage,
      //   });
      // },
      toggleShowHiddenUsers: function toggleShowHiddenUsers() {
        var showHiddenUsers = !this.get('showHiddenUsers');
        this.get('currentUserService').saveUISettings({
          showHiddenUsers: showHiddenUsers
        });
      },
      toggleShowMilestones: function toggleShowMilestones() {
        var plannerShowMilestoneSummary = !this.get('plannerShowMilestoneSummary');
        this.get('currentUserService').saveUISettings({
          plannerShowMilestoneSummary: plannerShowMilestoneSummary
        });
      },
      toggleProjectsSummary: function toggleProjectsSummary() {
        var _this5 = this;

        var showSummary = !this.get('viewport.showSummary');
        this.get('currentUserService').saveUISettings({
          plannerSummary: showSummary
        }).then(function () {
          showSummary && _this5.send('fetchProjectsSummaryData');
        });
      },
      toggleShowPlannerProjectDuration: function toggleShowPlannerProjectDuration() {
        var showPlannerProjectDuration = !this.get('showPlannerProjectDuration');
        this.get('currentUserService').saveUISettings({
          showPlannerProjectDuration: showPlannerProjectDuration
        });
      },
      toggleShowDailySummaryView: function toggleShowDailySummaryView(newVal) {
        this.get('currentUserService').saveUISettings({
          showDailySummaryView: newVal
        });
      },
      toggleExportModal: function toggleExportModal() {
        this.set('showExportModal', !this.get('showExportModal'));
      }
    }
  });

  _exports.default = _default;
});