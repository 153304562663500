define("coffeecup/components/input/cc-radiobox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isInitial: true,
    _emitOnChange: Ember.observer('checked', 'value', function () {
      if (!this.get('isInitial') && this.get('checked') === this.get('value')) {
        if (false
        /* DEBUG */
        ) {
          (false && Ember.warn("onChange action was triggered in ".concat(this.toString()), {
            id: 'cc.onChangeAction'
          }));
        }

        this.sendAction('onChange');
      }
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      Ember.run.later(function () {
        if (!_this.get('isDestroyed')) {
          _this.set('isInitial', false);
        }
      }, 500);
    },
    guid: Ember.computed('id', {
      get: function get() {
        return Ember.guidFor(this.get('id') || this.get('translateKey'));
      }
    })
  });

  _exports.default = _default;
});