define("coffeecup/components/time-entry/top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['top'],
    classNameBindings: ['isDraggingDisabled:no-draggable'],
    isDraggingDisabled: null,
    click: function click() {
      this.sendAction('onClick');
    }
  });

  _exports.default = _default;
});