define("coffeecup/controllers/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AnalyticsController = Ember.Controller.extend({
    /**
     * an array that holds the main navigation
     */
    menu: []
  });
  var _default = AnalyticsController;
  _exports.default = _default;
});