define("coffeecup/components/absence-tracking/absence-period", ["exports", "coffeecup/utils/date", "coffeecup/config/environment"], function (_exports, _date, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AbsencePeriod = Ember.Component.extend({
    startDate: null,
    endDate: null,
    halfDay: false,
    maxEndDate: null,
    minStartDate: Ember.computed('startDate', {
      get: function get() {
        var startDate = moment(this.get('startDate'));

        if ((0, _date.isDateValid)(startDate)) {
          return startDate.clone();
        }
      }
    }),
    previousDatesAllowed: true,
    columnSize: Ember.computed('isSmall', 'hideHalfDay', function () {
      if (this.get('isSmall')) return 'col-xs-6 col-sm-3';
      if (this.get('hideHalfDay')) return 'col-sm-6';
      return 'col-xs-6 col-sm-4';
    }),
    _startDateObserver: Ember.observer('startDate', function () {
      var startDate = moment(this.get('startDate'));

      if (!this.get('halfDay')) {
        if (moment(this.get('endDate')).isBefore(startDate, 'day')) {
          this.set('endDate', startDate);
        }
      } else {
        if (!this.get('endDate') || !moment(this.get('endDate')).isSame(startDate, 'day')) {
          this.set('endDate', startDate);
        }
      }

      if (this.onUpdate) {
        this.onUpdate();
      }
    }),
    _endDateObserver: Ember.observer('endDate', function () {
      if (this.onUpdate) {
        this.onUpdate();
      }
    }),
    actions: {
      updateDate: function updateDate(key, date) {
        var _this = this;

        Ember.run.next(function () {
          _this.set(key, moment(date));
        });
      },
      halfDayUpdated: function halfDayUpdated() {
        var start = moment(this.get('startDate'), _environment.default.constants.DATE_PICKER_FORMAT);

        if (!this.get('endDate') || !moment(this.get('endDate')).isSame(start, 'day')) {
          this.set('endDate', start);
        }

        if (this.onUpdate) {
          this.onUpdate();
        }
      }
    }
  });
  var _default = AbsencePeriod;
  _exports.default = _default;
});