define("coffeecup/controllers/account/company", ["exports", "coffeecup/controllers/account/billing", "coffeecup/config/environment"], function (_exports, _billing, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _billing.default.extend({
    permissions: Ember.inject.service(),
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    planningModeDaily: _environment.default.constants.PLANNING_MODE_DAILY,
    planningModeWeekly: _environment.default.constants.PLANNING_MODE_WEEKLY,
    //Reference/ Comment type of validation
    noReferenceOrComment: _environment.default.constants.NO_REFERENCE_OR_COMMENT,
    referenceAndComments: _environment.default.constants.REFERENCE_AND_COMMENTS,
    commentsOnly: _environment.default.constants.COMMENTS_ONLY,
    referenceOrComments: _environment.default.constants.REFERENCE_OR_COMMENTS,
    referencesOnly: _environment.default.constants.REFERENCE_ONLY,
    currencyFormats: null,
    existingTagsObjects: [],
    currencyFormatsArray: Ember.computed('currencyFormats', 'model.numberFormat', 'model.currency', function () {
      var currencyFormats = this.get('currencyFormats');
      var symbol = this.get('model.currency.sign');
      var numberFormat = this.get('i18n').t('numberFormat' + this.get('model.numberFormat'));
      currencyFormats.map(function (currencyFormat) {
        currencyFormat.set('label', currencyFormat.get('prefixTheSign') ? "".concat(symbol, " ").concat(numberFormat) : "".concat(numberFormat, " ").concat(symbol));
      });
      return currencyFormats;
    }),
    selectedFiscalYearMonth: Ember.computed('model.fiscalYearMonth', 'months', function () {
      var _this = this;

      var months = this.get('months');

      if (!months) {
        return null;
      }

      return months.find(function (x) {
        return x.id === _this.get('model.fiscalYearMonth');
      });
    }),
    selectedCurrencyFormat: Ember.computed('model.currencyFormat', 'currencyFormatsArray', function () {
      var _this2 = this;

      var currencyFormatsArray = this.get('currencyFormatsArray');

      if (!currencyFormatsArray) {
        return null;
      }

      return currencyFormatsArray.find(function (x) {
        return x.id === _this2.get('model.currencyFormat');
      });
    }),
    selectedNumberFormat: Ember.computed('model.numberFormat', 'numberFormats', function () {
      var _this3 = this;

      var numberFormats = this.get('numberFormats');

      if (!numberFormats) {
        return null;
      }

      return numberFormats.find(function (x) {
        return x.id === _this3.get('model.numberFormat');
      });
    }),
    selectedDefaultTimezone: Ember.computed('model.defaultTimezone', 'timezones', function () {
      var _this4 = this;

      var timezones = this.get('timezones');

      if (!timezones) {
        return null;
      }

      return timezones.find(function (x) {
        return x.id === _this4.get('model.defaultTimezone');
      });
    }),
    selectedDefaultLanguage: Ember.computed('model.defaultLanguage', 'languages', function () {
      var _this5 = this;

      var languages = this.get('languages');

      if (!languages) {
        return null;
      }

      return languages.find(function (x) {
        return x.id === _this5.get('model.defaultLanguage');
      });
    }),
    selectedDefaultBeginningOfWeek: Ember.computed('model.defaultBeginningOfWeek', 'beginningOfWeeks', function () {
      var _this6 = this;

      var beginningOfWeeks = this.get('beginningOfWeeks');

      if (!beginningOfWeeks) {
        return null;
      }

      return beginningOfWeeks.find(function (x) {
        return x.id === _this6.get('model.defaultBeginningOfWeek');
      });
    }),
    selectedDefaultDateFormat: Ember.computed('model.defaultDateFormat', 'dateFormats', function () {
      var _this7 = this;

      var dateFormats = this.get('dateFormats');

      if (!dateFormats) {
        return null;
      }

      return dateFormats.find(function (x) {
        return x.id === _this7.get('model.defaultDateFormat');
      });
    }),
    commentValidationProxy: Ember.computed('model.activeCommentValidation', {
      get: function get() {
        return this.get('model.activeCommentValidation');
      },
      set: function set(newValue) {
        var numberValue = newValue;

        if (newValue === true) {
          numberValue = _environment.default.constants.COMMENTS_ONLY;
        } else if (newValue === false) {
          numberValue = _environment.default.constants.NO_REFERENCE_OR_COMMENT;
        }

        this.set('model.activeCommentValidation', numberValue);
      }
    }),
    numberFormats: null,
    actions: {
      deleteTag: function deleteTag(tags) {
        var _this8 = this;

        var existingTags = this.get('existingTags');
        var deletedTags = existingTags.filter(function (el) {
          return !tags.isAny('id', el.get('id'));
        });
        var deletePromises = [];
        deletedTags.forEach(function (tag) {
          deletePromises.push(tag.destroyRecord());
        });
        Ember.RSVP.all(deletePromises).then(function () {
          _this8.set('existingTagsObjects', tags);

          _this8.get('notifications').success('alert.deleted', 'tag');
        }).catch(function (err) {
          _this8.get('notifications').errorWithObject(err, 'alert.errorOnDelete', 'tag');
        });
      }
    }
  });

  _exports.default = _default;
});