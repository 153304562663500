define("coffeecup/controllers/projects/edit", ["exports", "ember-changeset", "ember-changeset-validations", "coffeecup/validations/project", "coffeecup/config/environment", "coffeecup/mixins/manage-tags", "coffeecup/controllers/manage/_super-edit", "coffeecup/utils/strip-tags", "coffeecup/utils/format-duration"], function (_exports, _emberChangeset, _emberChangesetValidations, _project, _environment, _manageTags, _superEdit, _stripTags, _formatDuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var ProjectsEditController = _superEdit.default.extend(_manageTags.default, {
    application: Ember.inject.controller(),
    screen: Ember.inject.service(),
    permissions: Ember.inject.service(),
    easybill: Ember.inject.service(),
    taskService: Ember.inject.service('task'),
    modalNewClientController: Ember.inject.controller('modal.new-client'),
    modalNewTaskController: Ember.inject.controller('modal.new-task'),
    ProjectValidations: _project.default,

    /**
     * returns a headline for the detailpane
     * @return {Handlebars.SafeString} a string that will not be html escaped by Handlebars
     */
    headline: Ember.computed('model.name', function () {
      var str = this.get('model.name');
      str = (0, _stripTags.default)(str);
      return Ember.String.htmlSafe(str);
    }),

    /**
     * the currently logged in user
     */
    currentUser: Ember.computed.oneWay('application.currentUser'),

    /**
     * Name (key) of input field to put focus on
     */
    focus: 'name',

    /**
     * selected project
     */
    model: null,

    /**
     * array of colors
     */
    colors: null,

    /**
     * array of users
     */
    users: Ember.computed.reads('application.allUsers'),

    /**
     * array of tasks
     */
    tasks: Ember.computed.reads('application.allTasks'),

    /**
     * array of clients
     */
    clients: Ember.computed.reads('application.allClients'),
    hasClients: Ember.computed.gt('clientsFiltered.length', 0),
    cachedClient: null,

    /**
     * array of activeExpenseCategories
     */
    activeExpenseCategories: Ember.computed.reads('application.activeExpenseCategories'),

    /**
     * array of expenses
     */
    expenses: null,

    /**
     * boolean whether it's a internal project
     */
    internalProject: false,

    /**
     * boolean, true if it's not a internal project
     */
    isNotInternalProject: Ember.computed.not('internalProject'),
    hasClient: Ember.computed.bool('model.client.id'),
    isNotInternalProjectAndHasClient: Ember.computed.and('isNotInternalProject', 'hasClient'),
    isInternalProjectAndHasClient: Ember.computed.not('isNotInternalProjectAndHasClient'),

    /**
     * temporary holds the selected user from the dropdown selectbox
     */
    selectedUserOfDropdown: null,

    /**
     * temporary holds the selected task from the dropdown selectbox
     */
    selectedTaskOfDropdown: null,

    /**
     * Timestamp when the page was loaded.
     * Can be used for observer on project
     */
    pageLoadedAt: null,

    /**
     * All invoices
     */
    allInvoices: Ember.computed.alias('application.allInvoices'),

    /**
     * @return array of active clients
     * sorted by name
     */
    clientsFiltered: Ember.computed.filter('clients', function (client) {
      return client.get('isNew') === false && client.get('status') === _environment.default.constants.STATUS_ENABLED;
    }).property('clients.@each.{isNew,isInactive,isActive}'),
    clientsSorted: Ember.computed.sort('clientsFiltered', 'clientsSorting'),
    clientsSorting: ['name'],

    /**
     * observes changes from the clients dropdown selectbox
     * necessary to toggle the checkbox for internalProject
     */
    _clientChanged: Ember.observer('model.client.id', function () {
      var client = this.get('model.client.id');

      if (client) {
        this.set('internalProject', false);
      } else {
        this.set('internalProject', true);
      }

      if (client !== this.get('model.client.id')) {
        this.set('model.contact', null);
      }
    }),

    /**
     * @return String
     */
    clientsDropDownPlaceholder: Ember.computed('internalProject', 'canCreateClient', function () {
      var internalProject = this.get('internalProject');
      var selectOrCreate = this.get('canCreateClient') ? 'selectOrCreateClient' : 'selectClient';
      return this.get('i18n').t(internalProject ? 'internalProject' : selectOrCreate);
    }),

    /**
     * observes changes from the checkbox for internalProject
     * necessary to reset the client dropdown selectbox
     */
    _internalProjectChanged: Ember.observer('internalProject', function () {
      var internalProject = this.get('internalProject');
      var selectedClient = this.get('model.client.id');

      if (internalProject && selectedClient) {
        if (selectedClient) {
          this.set('cachedClient', this.get('model.client'));
        }

        this.set('model.client', null);
      } else if (!internalProject && !selectedClient) {
        if (this.get('cachedClient')) {
          this.set('model.client', this.get('cachedClient'));
        }
      } else if (internalProject && !selectedClient) {
        this.set('model.contact', null);
      }

      this.send('saveProject');
    }),

    /**
     * array of active users
     * sorted by lastname
     */
    usersFiltered: Ember.computed('users.@each.{isActive}', function () {
      return this.users.filter(function (user) {
        return user.get('isActive') === true;
      });
    }),
    _userSorted: Ember.computed.sort('usersFiltered', 'userSortKeys'),
    usersSorted: Ember.computed('_userSorted', function () {
      var userSorted = this.get('_userSorted');
      var currentUser = this.get('currentUser'); // make sure currentUser is at first position

      userSorted = userSorted.removeObject(currentUser);
      userSorted.unshiftObject(currentUser);
      return userSorted;
    }),
    userSortKeys: _environment.default.constants.USER_SORT_KEYS,

    /**
     * @return array of users for the users dropdown selectbox
     * sorted by lastname
     * returns only users that are not already assigned to the project
     */
    usersSortedForDropdown: Ember.computed('usersSorted.length', 'model.userAssignments.length', function () {
      var users = this.get('usersSorted');
      var userAssignments = this.get('model.userAssignments');

      if (!userAssignments) {
        return [];
      }

      return users.filter(function (item) {
        return !userAssignments.findBy('user.id', item.id);
      });
    }),

    /**
     * @return array of currently selected userAssignments
     * sorted by user's lastname
     */
    userAssignmentsSorted: Ember.computed.sort('model.userAssignments', 'userAssignmentsSorting'),
    userAssignmentsSorting: _environment.default.constants.USER_ASSIGNMENT_SORT_KEYS,

    /**
     * observes changes from the users dropdown selectbox
     * removes the selected user from the dropdown
     * creates a user-assignment with the selected user
     */
    _selectedUserOfDropdownChanged: Ember.observer('usersSorted', 'selectedUserOfDropdown', function () {
      var _this = this;

      var user = this.get('selectedUserOfDropdown');
      if (!user) return;
      var project = this.get('model');
      var userAssignments = this.get('model.userAssignments');
      var userAssignment = project.get('userAssignments').findBy('user.id', user.get('id'));

      if (!userAssignment) {
        userAssignment = this.store.createRecord('user-assignment');
        userAssignment.set('user', user);
        userAssignment.set('project', project);
        userAssignment.applyUserDefaults();
        userAssignment.save().then(function () {
          _this.get('notifications').success('alert.saved', 'userAssignment');
        }).catch(function (err) {
          _this.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'userAssignment');
        });
      } // userAssignments.pushObject(userAssignment);


      this.set('selectedUserOfDropdown', null);
    }),
    codeWasManuallyChanged: false,
    codeWasManuallyChangedObserver: Ember.observer('model.code', 'model.name', 'model.client', function () {
      var code = this.get('model.code') || '';

      var generatedCode = this._createShortTag();

      this.set('codeWasManuallyChanged', code !== generatedCode);
    }),
    _createShortTag: function _createShortTag() {
      var name = this.get('model.name') || '';
      var clientTag = this.get('model.client.code');
      var tag = '';
      var maxLength = 18;
      var existingCodes = this.get('store').peekAll('project').getEach('code').removeObject(this.get('model.code'));

      if (clientTag) {
        maxLength = 12;
      }

      if (name) {
        tag += this.createShortTag(name, maxLength, existingCodes, 1, clientTag);
      }

      return tag;
    },
    _generatedCodeTag: Ember.observer('model.name', 'model.client', function () {
      if (this.get('model.hasDirtyAttributes') && !this.get('codeWasManuallyChanged')) {
        this.set('model.code', this._createShortTag());
      }
    }),
    budgetFmt: '',
    startDatePickerPosition: Ember.computed('screen.isExtraSmallAndDown', function () {
      return this.get('screen.isExtraSmallAndDown') ? 'left' : 'center';
    }),

    /**
     * returns formatted string for projects overall budget
     * based on all different billby and budgetby combinations and the according rates
     */
    // budgetHasChanged: observer(
    //   'model.budgetBy',
    //   'model.billBy',
    //   'model.hourlyRate',
    //   'model.budgetHours',
    //   'model.budget',
    //   'model.taskAssignments.@each.budgetHours',
    //   'model.taskAssignments.@each.hourlyRate',
    //   'model.taskAssignments.@each.billable',
    //   function() {
    //     once(this, () => {
    //       if (this.get('model.analytics')) {
    //         this.get('model.analytics').reload();
    //       }
    //     });
    //   }
    // ),
    budgetInPersonDays: Ember.computed('model.budgetHours', function () {
      return (0, _formatDuration.formatAsPersonDays)(this.get('model.budgetHours'), _environment.default.constants.STANDARD_WORKDAY_HOURS) + ' ' + this.get('i18n').t('abbr.personDays');
    }),
    expensesActive: Ember.computed.reads('permissions.expensesActive'),
    isBudgetByUser: Ember.computed.equal('model.budgetBy', _environment.default.constants.BUDGET_BY_USER),
    isBudgetByTask: Ember.computed.equal('model.budgetBy', _environment.default.constants.BUDGET_BY_TASK),
    isBillByTask: Ember.computed.equal('model.billBy', _environment.default.constants.BILL_BY_TASK),
    isBillByUser: Ember.computed.equal('model.billBy', _environment.default.constants.BILL_BY_USER),
    isBillByNone: Ember.computed.equal('model.billBy', _environment.default.constants.BILL_BY_NONE),
    hideTaskHourlyRate: Ember.computed.reads('isBillByUser'),
    hideUserHourlyRate: Ember.computed.or('isBillByNone', 'isBillByTask'),
    hideTaskBudget: Ember.computed.not('isBudgetByTask'),

    /**
     * Checks if there is one PM in user-assignments
     * @return boolean true when there is a pm
     */
    projectPmsCount: Ember.computed('model.userAssignments.@each.isProjectManager', function () {
      var selectedPms = this.get('model.userAssignments').filter(function (asgnmt) {
        return asgnmt.get('isProjectManager') === true;
      });
      return selectedPms.get('length');
    }),
    projectHasOnePm: Ember.computed.equal('projectPmsCount', 1),
    projectHasManyPms: Ember.computed.gt('projectPmsCount', 1),

    /**
     * @return boolean
     */
    canCreateClient: Ember.computed.or('permissions.isRootAdmin', 'permissions.canManageProjectRelations'),
    allowNewTask: Ember.computed.alias('canCreateClient'),
    errors: {},

    /**
     * set of actions
     */
    actions: {
      cancel: function cancel() {
        if (this.get('model.hasDirtyAttributes')) {
          this.get('model').rollbackAttributes();
        }

        this.transitionToRoute('projects');
      },
      refreshAnalytics: function refreshAnalytics() {
        // if single-project analytics were loaded, reload needs to load the "original" version
        // with the original id, not the 'single-project-' id.
        var analytics = this.store.peekRecord('analytics/project', this.get('model.id'));

        if (analytics) {
          analytics.reload();
        }
      },
      generateCodeAndSaveProject: function generateCodeAndSaveProject() {
        if (!this.get('codeWasManuallyChanged')) {
          this.set('model.code', this._createShortTag());
        }

        this.send('saveProject');
      },
      saveProject: function saveProject() {
        var _this2 = this;

        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var skipNotification = options.skipNotification;
        Ember.run.next(function () {
          _this2.set('errors', {});

          if (_this2.get('model.isDirty')) {
            var changeset = (0, _emberChangeset.Changeset)(_this2.get('model'), (0, _emberChangesetValidations.default)(_project.default), _project.default);
            changeset.validate().then(function () {
              if (changeset.get('isValid')) {
                if (_this2.get('model.endDate') && _this2.get('model.endDate').isBefore(_this2.get('model.startDate'))) _this2.set('model.endDate', _this2.get('model.startDate'));

                _this2.get('model').save().then(function () {
                  _this2.send('refreshAnalytics');

                  if (!skipNotification) _this2.get('notifications').success('alert.saved', 'project');
                }).catch(function (err) {
                  _this2.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'project');
                });
              } else {
                _this2.set('errors', changeset.error);

                _this2.get('notifications').info('pleaseCheckYourInputs');
              }
            }).catch(function () {
              _this2.get('notifications').info('pleaseCheckYourInputs');
            });
          }
        });
      },
      resetProject: function resetProject() {
        this.get('model').rollbackAttributes();
        this.send('refreshAnalytics');
      },
      removeUserAssignment: function removeUserAssignment(userAssignment) {
        var _this3 = this;

        userAssignment.destroyRecord().then(function () {
          _this3.send('refreshAnalytics');

          _this3.get('notifications').success('alert.deleted', 'userAssignment');
        }).catch(function (err) {
          _this3.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'userAssignment');
        });
      },
      saveUserAssignment: function saveUserAssignment(userAssignment) {
        var _this4 = this;

        Ember.run.next(function () {
          // wait till change is propagated
          if (userAssignment.get('hasDirtyAttributes')) {
            userAssignment.save().then(function () {
              _this4.send('refreshAnalytics');

              _this4.get('notifications').success('alert.saved', 'userAssignment');
            }).catch(function (err) {
              _this4.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'userAssignment');

              userAssignment.rollbackAttributes();
            });
          }
        });
      },
      resetUserAssignment: function resetUserAssignment(userAssignment) {
        userAssignment.rollbackAttributes();
      },
      createNewClient: function createNewClient(label) {
        var _this5 = this;

        if (!this.get('canCreateClient')) {
          return;
        }

        if (_typeof(label) === 'object') {
          label = '';
        }

        var i18n = this.get('i18n');
        var modalNewClientController = this.get('modalNewClientController');
        var client = this.store.createRecord('client');
        client.set('name', label);
        modalNewClientController.set('model', client);
        modalNewClientController.set('options', {
          title: i18n.t('newClient'),
          closeBtnText: i18n.t('cancel'),
          confirmBtnText: i18n.t('save'),
          confirmCallback: function confirmCallback() {
            _this5.get('notifications').success('alert.saved', client.get('constructor.modelName'));

            _this5.set('model.client', client);

            _this5.send('saveProject', {
              skipNotification: true
            });
          }
        });
        modalNewClientController.set('openModal', true);
      },
      bill: function bill(item) {
        this.get('application').send('billProject', item.id);
      },
      complete: function complete(item) {
        this.get('application').send('completeProject', item.id);
      },
      openBillingSummary: function openBillingSummary(invoice) {
        this.get('application').send('billProject', this.get('model.id'), null, null, null, invoice, 'success');
      }
    }
  });

  var _default = ProjectsEditController;
  _exports.default = _default;
});