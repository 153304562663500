define("coffeecup/models/billing/subscription", ["exports", "coffeecup/config/environment", "ember-data"], function (_exports, _environment, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    formatter: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    plan: _emberData.default.belongsTo('billing/plan', {
      async: true
    }),
    amount: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    currencyCode: _emberData.default.attr('string'),
    planQuantity: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    activatedAt: _emberData.default.attr('date'),
    startedAt: _emberData.default.attr('date'),
    cancelledAt: _emberData.default.attr('date'),
    status: _emberData.default.attr('string'),
    trialStart: _emberData.default.attr('date'),
    trialEnd: _emberData.default.attr('date'),
    billingPeriod: _emberData.default.attr('number'),
    billingPeriodUnit: _emberData.default.attr('string'),
    currentTermStart: _emberData.default.attr('date'),
    currentTermEnd: _emberData.default.attr('date'),
    dueInvoicesCount: _emberData.default.attr('number'),
    dueSince: _emberData.default.attr('date'),
    totalDues: _emberData.default.attr('number'),
    nextBillingAt: _emberData.default.attr('date'),
    hasScheduledChanges: _emberData.default.attr('boolean'),
    // the following are only present if hasScheduledChanges = true
    nextPlan: _emberData.default.belongsTo('billing/plan', {
      async: true
    }),
    nextplanQuantity: _emberData.default.attr('number'),
    nextPrice: _emberData.default.attr('number'),
    nextBillingPeriod: _emberData.default.attr('number'),
    nextBillingPeriodUnit: _emberData.default.attr('string'),
    // creditcard values
    cardMaskedNumber: _emberData.default.attr('string'),
    cardNameOnCard: _emberData.default.attr('string'),
    cardExpiryMonth: _emberData.default.attr('string'),
    cardExpiryYear: _emberData.default.attr('string'),
    cardStatus: _emberData.default.attr('string'),
    // subscription permissions
    featureFlags: _emberData.default.attr(),
    amountFmt: Ember.computed('amount', 'currencySign', function () {
      var formattedPrice = this.get('formatter').formatNumber(this.get('amount') / 100, '0.00');
      return formattedPrice + ' ' + this.get('currencySign');
    }),
    currencySign: Ember.computed('currencyCode', function () {
      var currencyCode = this.get('currencyCode');
      var currency = currencyCode && this.get('store').peekRecord('currency', currencyCode);
      if (!currency) currency = this.get('accountService.accountSettings.currency');
      return currency.get('sign');
    }),
    cardValidDateFmt: Ember.computed('cardExpiryMonth', 'cardExpiryYear', function () {
      if (!this.get('cardExpiryMonth') || !this.get('cardExpiryYear')) return;
      var validDate = moment();
      validDate.set('month', this.get('cardExpiryMonth') - 1); // subtract is required to fix the month

      validDate.set('year', this.get('cardExpiryYear'));
      return validDate.format('MMM YYYY');
    }),
    remainingTrialDays: Ember.computed('trialEnd', function () {
      var trialEnd = moment(this.get('trialEnd')).endOf('day');
      return trialEnd.diff(this.get('clock.day'), 'days');
    }),
    trialEndsInOneWeekOrLess: Ember.computed.lte('remainingTrialDays', 7),
    trialEndsInTwoWeeksOrLess: Ember.computed.lte('remainingTrialDays', 14),
    trialEndsTomorrow: Ember.computed.equal('remainingTrialDays', 1),
    trialEndsToday: Ember.computed.equal('remainingTrialDays', 0),
    trialEnded: Ember.computed.lt('remainingTrialDays', 0),
    trialEndedWithoutRenewing: Ember.computed.and('trialEnded', 'isNotActive'),
    isCardValid: Ember.computed.equal('cardStatus', 'valid'),
    isCardInvalid: Ember.computed.not('isCardValid'),
    isActive: Ember.computed.equal('status', 'active'),
    isNotActive: Ember.computed.not('isActive'),
    isInTrial: Ember.computed.equal('status', 'in_trial'),
    isCancelled: Ember.computed.equal('status', 'cancelled'),
    isOver: Ember.computed.or('isCancelled', 'trialEndedWithoutRenewing'),
    isInTrialAndNoValidCard: Ember.computed.and('isCardInvalid', 'isInTrial'),
    isNotActiveAndNoValidCard: Ember.computed.and('isCardInvalid', 'isNotActive'),
    friendlyTrialReminder: Ember.computed('remainingTrialDays', 'status', function () {
      var remainingTrialDays = this.get('remainingTrialDays');
      var trialEndsInTwoWeeksOrLess = this.get('trialEndsInTwoWeeksOrLess');
      var trialEndsTomorrow = this.get('trialEndsTomorrow');
      var trialEndsToday = this.get('trialEndsToday');
      var isOver = this.get('isOver');
      var trialEnd = this.get('formatter').formatDate(this.get('trialEnd'));

      if (isOver) {
        return this.get('i18n').t('trialEnded', {
          trialEnd: trialEnd
        });
      }

      if (trialEndsToday) {
        return this.get('i18n').t('trialEndsToday');
      }

      if (trialEndsTomorrow) {
        return this.get('i18n').t('trialEndsTomorrow');
      }

      if (trialEndsInTwoWeeksOrLess) {
        return this.get('i18n').t('trialRemainingDaysInfo', {
          remainingDays: remainingTrialDays
        });
      }

      return this.get('i18n').t('trialRemainingDaysFriendlyInfo', {
        remainingDays: remainingTrialDays
      });
    }),
    currentPeriodStart: Ember.computed('status', 'trialStart', 'currentTermStart', function () {
      var trialStart = this.get('trialStart');
      var currentTermStart = this.get('currentTermStart');
      return this.get('isInTrial') ? trialStart : currentTermStart;
    }),
    currentPeriodEnd: Ember.computed('status', 'trialEnd', 'currentTermEnd', function () {
      var trialEnd = this.get('trialEnd');
      var currentTermEnd = this.get('currentTermEnd');
      return this.get('isInTrial') ? trialEnd : currentTermEnd;
    }),
    nextPeriodStart: Ember.computed.reads('nextBillingAt'),
    nextPeriodEnd: Ember.computed('nextBillingAt', 'billingPeriod', 'billingPeriodUnit', function () {
      var nextBillingAt = this.get('nextBillingAt');
      var billingPeriod = this.get('billingPeriod');
      var billingPeriodUnit = this.get('billingPeriodUnit');
      return moment(nextBillingAt).add(billingPeriod, billingPeriodUnit);
    }),
    willNotBeRenewed: Ember.computed('status', 'isInTrialAndNoValidCard', function () {
      return this.get('status') === 'non_renewing' || this.get('isInTrialAndNoValidCard');
    }),
    nextPlanComputed: Ember.computed('plan', 'nextPlan', 'hasScheduledChanges', function () {
      if (this.get('hasScheduledChanges')) {
        return this.get('nextPlan');
      } else {
        return this.get('plan');
      }
    }),
    statusLabel: Ember.computed('status', function () {
      var status = this.get('status');
      var daysLeft = this.get('remainingTrialDays');
      var label = this.get('i18n').t(status);

      if (this.get('isInTrialAndNoValidCard')) {
        if (this.get('trialEndsTomorrow')) {
          label += ' - ' + this.get('i18n').t('dayLeft', {
            daysLeft: daysLeft
          });
        } else if (this.get('trialEndsInOneWeekOrLess') || this.get('trialEndsToday')) {
          label += ' - ' + this.get('i18n').t('daysLeft', {
            daysLeft: daysLeft
          });
        }
      }

      return label;
    }),
    nextStatus: Ember.computed('statusLabel', 'willNotBeRenewed', function () {
      if (this.get('willNotBeRenewed')) {
        return this.get('i18n').t('non_renewing');
      }

      return this.get('statusLabel');
    }),
    nextStatusColor: Ember.computed('status', 'isInTrialAndNoValidCard', function () {
      if (this.get('isActive')) return _environment.default.constants.COLOR_SUCCESS;

      if (this.get('isInTrialAndNoValidCard')) {
        return _environment.default.constants.COLOR_ERROR;
      }

      return this.get(statusColor);
    }),
    statusColor: Ember.computed('status', function () {
      if (this.get('isActive')) return _environment.default.constants.COLOR_SUCCESS;
      if (this.get('isOver')) return _environment.default.constants.COLOR_ERROR;

      if (this.get('isInTrialAndNoValidCard')) {
        if (this.get('trialEndsTomorrow') || this.get('trialEndsToday')) return _environment.default.constants.COLOR_WARNING;
        return _environment.default.constants.COLOR_SUCCESS;
      }

      if (this.get('isInTrial')) return _environment.default.constants.COLOR_SUCCESS;
      return _environment.default.constants.GRAY_DARKER;
    })
  });

  _exports.default = _default;
});