define("coffeecup/components/modals/reconnect-easybill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    easybill: Ember.inject.service(),
    actions: {
      onHide: function onHide() {
        this.set('openModal', false);
      },
      reconnectEasybill: function reconnectEasybill() {
        var _this = this;

        this.get('easybill').startAuthFlow().finally(function () {
          _this.set('openModal', false);
        });
      }
    }
  });

  _exports.default = _default;
});