define("coffeecup/components/planner/user-project-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'row-info',
    projectService: Ember.inject.service('project'),
    currentUserService: Ember.inject.service('current-user'),
    userAssignmentsService: Ember.inject.service('user-assignments'),
    easybill: Ember.inject.service(),
    user: null,
    project: null,
    editable: null,
    userIsPm: Ember.computed('user', 'project', 'project.userAssignments.@each.isProjectManager', {
      get: function get() {
        var userAssignment = this.get('project.userAssignments').findBy('user.id', this.get('user.id'));
        return userAssignment && userAssignment.get('isProjectManager');
      }
    }),
    actions: {
      toggleUserPM: function toggleUserPM() {
        var _this = this;

        if (!this.get('currentUserService.isAdmin')) return;
        var user = this.get('user');
        var project = this.get('project');

        if (user && project) {
          this.get('userAssignmentsService').createUserAssignmentIfNotExists(user, project).then(function (userAssignment) {
            if (userAssignment && typeof userAssignment.toggleProperty !== 'undefined') {
              userAssignment.toggleProperty('isProjectManager');
              userAssignment.save().then(function () {
                _this.get('notifications').success('alert.saved', 'user');
              }).catch(function (err) {
                userAssignment.rollbackAttributes();

                _this.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'user');
              });
            }
          });
        }
      },
      showTimeEntries: function showTimeEntries() {
        this.sendAction.apply(this, ['showTimeEntries'].concat(Array.prototype.slice.call(arguments)));
      },
      billProject: function billProject() {
        this.sendAction('customAction', 'billProject', {
          projectId: this.get('project.id')
        });
      },
      completeProject: function completeProject() {
        this.sendAction('customAction', 'completeProject', {
          projectId: this.get('project.id')
        });
      }
    }
  });

  _exports.default = _default;
});