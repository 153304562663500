define("coffeecup/components/manage/expense-categories/icon-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['expense-categories-icon-cell'],
    classNameBindings: ['small:small', 'extraSmall:extra-small'],
    icon: null,
    style: null,
    small: false,
    extraSmall: false
  });

  _exports.default = _default;
});