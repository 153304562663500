define("coffeecup/components/tag-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['filter-search-choice'],
    label: null,
    value: null,
    actions: {
      click: function click() {
        this.sendAction('onClick', this.get('value'));
      }
    }
  });

  _exports.default = _default;
});