define("coffeecup/components/planner/time-frame", ["exports", "coffeecup/utils/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    viewport: Ember.inject.service('planner.viewport'),
    attributeBindings: ['style'],
    startDate: null,
    endDate: null,
    style: Ember.computed('width', 'left', function () {
      return Ember.String.htmlSafe('width:' + this.get('width') + 'px;left:' + this.get('left') + 'px;');
    }),
    width: 0,
    left: 0,
    daySpan: 0,
    atLeastTwoDays: Ember.computed.gte('daySpan', 2),
    processTimeFrameData: function processTimeFrameData() {
      var startDate = this.get('startDate');
      var endDate = this.get('endDate');
      var viewPortStartDate = this.get('viewport.startDate');
      var viewportDayWidth = this.get('viewport.dayWidth');

      if (viewportDayWidth && (0, _date.isDateValid)(viewPortStartDate) && (0, _date.isDateValid)(startDate) && (0, _date.isDateValid)(endDate)) {
        var daySpan = 1 + this.get('viewport').numberOfDaysBetween(startDate, endDate);
        var width = daySpan * viewportDayWidth - 1;
        var left = this.get('viewport').numberOfDaysBetween(viewPortStartDate, startDate) * viewportDayWidth;

        if (width || left) {
          this.setProperties({
            daySpan: daySpan,
            width: width,
            left: left
          });
        }
      }
    },
    timeFrameDataObserver: Ember.observer('startDate', 'endDate', 'viewport.startDate', 'viewport.dayWidth', function () {
      Ember.run.once(this, 'processTimeFrameData');
    }).on('init')
  });

  _exports.default = _default;
});