define("coffeecup/components/planner/project-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'row-info',
    click: function click() {
      this.sendAction('onExpand');
    },
    actions: {
      showTimeEntries: function showTimeEntries() {
        this.sendAction.apply(this, ['showTimeEntries'].concat(Array.prototype.slice.call(arguments)));
      },
      completeProject: function completeProject(project) {
        this.sendAction('completeProject', project);
      },
      billProject: function billProject(project) {
        this.sendAction('billProject', project);
      }
    }
  });

  _exports.default = _default;
});