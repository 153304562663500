define("coffeecup/demo-data/dashboard/project-details-module", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.userAssignmentList = _exports.filteredDemoMilestones = _exports.projectDetailsModuleData = void 0;
  var projectDetailsModuleData = {
    name: 'Demo-Project',
    hourlyRate: 100,
    isModeFixedBudget: true,
    get: function get() {},
    projectState: {
      color: '#4FBF88',
      label: 'In Progress'
    },
    analytics: {
      amountRemaining: 1280,
      amountBudgetTotal: 5000,
      amountSpentTotal: 3720,
      budgetRemainingInPercent: 0.256,
      plannedAmount: 800,
      hoursPlanned: 8,
      budgetPlannedInPercent: 0.625,
      profitabilityTotal: 900,
      invoicesTotal: 3400,
      amountNotBilledTotal: 320,
      amountBilledTotal: 3400,
      totalCosts: 2500,
      profitabilityInPercent: 0.7352941176,
      amountSpent: 3500,
      expensesTotal: 270,
      hoursTotal: 270,
      monthlyHistogram: [{
        date: (0, _moment.default)().subtract(5, 'month'),
        totalCosts: 240,
        amountSpentTotal: 480
      }, {
        date: (0, _moment.default)().subtract(4, 'month'),
        totalCosts: 240,
        amountSpentTotal: 480
      }, {
        date: (0, _moment.default)().subtract(3, 'month'),
        totalCosts: 240,
        amountSpentTotal: 480
      }, {
        date: (0, _moment.default)().subtract(2, 'month'),
        totalCosts: 240,
        amountSpentTotal: 480
      }, {
        date: (0, _moment.default)().subtract(1, 'month'),
        totalCosts: 240,
        amountSpentTotal: 480
      }, {
        date: (0, _moment.default)(),
        totalCosts: 240,
        amountSpentTotal: 480
      }]
    }
  };
  _exports.projectDetailsModuleData = projectDetailsModuleData;
  var filteredDemoMilestones = [{
    day: (0, _moment.default)(),
    name: 'Schulterblick'
  }, {
    day: (0, _moment.default)().add(7, 'days'),
    name: 'Abgabe Feature 1'
  }, {
    day: (0, _moment.default)().add(23, 'days'),
    name: 'Finale Abgabe'
  }];
  _exports.filteredDemoMilestones = filteredDemoMilestones;
  var userAssignmentList = [{
    isProjectManager: true,
    user: Ember.Object.create({
      label: 'Johannes Stich',
      imageSmall: '/assets/images/demo/demo-user-3.png'
    })
  }, {
    isProjectManager: true,
    user: Ember.Object.create({
      label: 'Clairé Assange',
      imageSmall: '/assets/images/demo/demo-user-4.png'
    })
  }, {
    isProjectManager: false,
    user: Ember.Object.create({
      label: 'Lisa Feldmayer',
      imageSmall: '/assets/images/demo/demo-user-5.png'
    })
  }, {
    isProjectManager: false,
    user: Ember.Object.create({
      label: 'Joshua Smith',
      imageSmall: '/assets/images/demo/demo-user-1.png'
    })
  }, {
    isProjectManager: false,
    user: Ember.Object.create({
      label: 'Natasha Ramirez',
      imageSmall: '/assets/images/demo/demo-user-2.png'
    })
  }, {
    isProjectManager: false,
    user: Ember.Object.create({
      label: 'Niko Reishofer',
      isImageInitials: true
    })
  }, {
    isProjectManager: false,
    user: Ember.Object.create({
      label: 'Robert Cunningham',
      isImageInitials: true
    })
  }, {
    isProjectManager: false,
    user: Ember.Object.create({
      label: 'Josef Ebel',
      isImageInitials: true
    })
  }, {
    isProjectManager: false,
    user: Ember.Object.create({
      label: 'Alexandra Huber',
      isImageInitials: true
    })
  }];
  _exports.userAssignmentList = userAssignmentList;
});