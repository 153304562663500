define("coffeecup/components/assignment-pill", ["exports", "ember-cli-tooltipster/components/tool-tipster", "coffeecup/utils/tooltip-planner"], function (_exports, _toolTipster, _tooltipPlanner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _toolTipster.default.extend({
    formatter: Ember.inject.service(),
    attributeBindings: ['style'],
    actionName: null,
    actionIcon: null,
    isValidated: false,
    isReadOnly: Ember.computed('isValidated', 'project.projectState.isTrackable', function () {
      return this.get('isValidated') || !this.get('project.projectState.isTrackable');
    }),
    projectId: Ember.computed.reads('project.id'),
    flexWidth: Ember.computed.reads('displayValue'),
    label: Ember.computed.reads('displayValue'),
    backgroundColor: Ember.computed.reads('project.color.hexString'),
    classNames: ['assignment-pill'],
    classNameBindings: ['isReadOnly:read-only', 'isFaded:is-faded'],
    side: 'bottom',
    content: Ember.computed('project', 'tracked', 'planned', function () {
      var i18n = this.get('i18n');
      var formatter = this.get('formatter');
      var hasClient = Ember.isPresent(this.get('project.client.label'));
      var hasComment = Ember.isPresent(this.get('comment'));
      var tooltipObject = {
        i18n: i18n,
        comment: hasComment ? "<div class=\"tooltip-comment\">".concat(Ember.String.htmlSafe(this.get('comment')), "</div>") : '',
        date: formatter.formatDuration(this.get('planned')),
        project: this.get('project.fullName'),
        client: this.get('project.client.label'),
        daily: 0,
        total: 0,
        color: this.get('project.projectState.color'),
        status: this.get('project.projectState.label'),
        isTrackable: this.get('project.projectState.isTrackable'),
        isPlanned: true,
        tracked: this.get('tracked') !== undefined ? formatter.formatDuration(this.get('tracked')) : undefined,
        hasAllocation: false,
        hasTotal: false,
        hasProject: true,
        hasClient: hasClient
      };
      return (0, _tooltipPlanner.default)(tooltipObject);
    }),
    contentAsHTML: true,
    style: Ember.computed('backgroundColor', {
      get: function get() {
        return Ember.String.htmlSafe(
        /*css*/
        "\n        flex: ".concat(this.get('flexWidth'), ";\n        background-color: ").concat(this.get('backgroundColor'), ";\n        ").concat(!this.isFaded ? "border-color: ".concat(this.get('backgroundColor'), ";") : '', "\n      "));
      }
    }),
    click: function click() {
      if (this.get('actionName') && !this.get('isReadOnly')) {
        this.sendAction('actionName', {
          day: this.get('day').format(),
          projectId: this.get('projectId')
        });
      }
    }
  });

  _exports.default = _default;
});