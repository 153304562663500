define("coffeecup/controllers/planner/projects/milestone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    isInitialEdit: false
  });

  _exports.default = _default;
});