define("coffeecup/controllers/analytics/clients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /*
    INJECTIONS
    */
    application: Ember.inject.controller(),
    detailsController: Ember.inject.controller('analytics.clients.details'),
    router: Ember.inject.service(),
    i18n: Ember.inject.service(),
    permissions: Ember.inject.service(),
    clientService: Ember.inject.service('client'),

    /*
    PROPERTIES
     */
    openModal: false,
    selectedClientId: null,

    /*
    COMPUTED PROPERTIES
     */
    selectedClient: Ember.computed.reads('detailsController.selectedClient'),
    startDate: Ember.computed.reads('detailsController.startDate'),
    endDate: Ember.computed.reads('detailsController.endDate'),
    hasNoClients: Ember.computed.equal('clients.length', 0),
    canManageProjectRelations: Ember.computed.or('permissions.isRootAdmin', 'permissions.canManageProjectRelations'),

    /**
     * @return array of active clients sorted by label
     */
    clientsSorted: Ember.computed('clients', function () {
      return this.get('clientService').clientsSortedAndGroupedByInactive(this.get('clients'));
    }),

    /*
    OBSERVERS
     */
    _updateFromDetailsPage: Ember.observer('selectedClient.id', function () {
      if (this.get('selectedClient.id') && this.get('selectedClient.id') !== this.get('selectedClientId')) {
        this.set('selectedClientId', this.get('selectedClient.id'));
      }
    }),
    changeToClient: Ember.observer('selectedClientId', function () {
      var selectedClient = this.get('selectedClientId') || this.get('selectedClient.id');

      if (selectedClient) {
        this.get('router').transitionTo('analytics.clients.details', selectedClient);
      }
    })
  });

  _exports.default = _default;
});