define("coffeecup/components/validation/user-row", ["exports", "coffeecup/config/environment", "ember-in-viewport", "coffeecup/utils/build-url"], function (_exports, _environment, _emberInViewport, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend(_emberInViewport.default, {
    classNameBindings: ['isRemoved:removed'],
    userData: null,
    selectedDay: null,
    week: null,
    isRemoved: false,
    isInViewport: false,
    breakMissingOnDay: false,
    openModal: false,
    hideFromUI: false,
    openTimeEntryModal: false,
    datesWithMissingBreaks: [],
    datesWithMissingComments: [],
    currentUserService: Ember.inject.service('currentUser'),
    store: Ember.inject.service(),
    permissions: Ember.inject.service(),
    formatter: Ember.inject.service(),
    session: Ember.inject.service(),
    i18n: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    accountSettings: Ember.computed.reads('accountService.accountSettings'),
    VALIDATION_OPEN: _environment.default.constants.VALIDATION_OPEN,
    VALIDATION_SUBMITTED: _environment.default.constants.VALIDATION_SUBMITTED,
    VALIDATION_REJECTED: _environment.default.constants.VALIDATION_REJECTED,
    VALIDATION_APPROVED: _environment.default.constants.VALIDATION_APPROVED,
    user: Ember.computed.alias('userData.user'),
    daysInPeriod: [],
    timeEntriesForDay: [],
    accuraciesForDay: [],
    didEnterViewport: function didEnterViewport() {
      var isTearingDown = this.isDestroyed || this.isDestroying;

      if (!isTearingDown) {
        this.set('isInViewport', true);
      }
    },
    didExitViewport: function didExitViewport() {
      var isTearingDown = this.isDestroyed || this.isDestroying;

      if (!isTearingDown) {
        this.set('isInViewport', false);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('viewportSpy', true);
      this.set('viewportTolerance', {
        top: 200,
        left: 0,
        bottom: 200,
        right: 0
      });
    },
    selectedFilterMode: null,
    _updateHideFromUI: Ember.observer('selectedFilterMode', function () {
      this.set('hideFromUI', false);
    }),
    _tooltipRow: function _tooltipRow(isValid, label, value, expectedValue) {
      var i18n = this.get('i18n'); // prettier-ignore

      return "<div><span class=\"".concat(isValid ? 'icomoon-checkmark' : 'icomoon-cross-exit', "\"></span> ").concat(i18n.t(label), " ").concat(value, " (").concat(expectedValue, ")</div>");
    },
    _reasonRow: function _reasonRow(label, value, expectedValue) {
      var i18n = this.get('i18n'); // prettier-ignore

      return "".concat(i18n.t(label), ": ").concat(value, " ").concat(i18n.t('of'), " ").concat(expectedValue, "\n");
    },
    tagColor: Ember.computed('userData.validation.state', function () {
      var validation = this.get('userData.validation');
      var state = validation ? validation.get('state') : this.get('VALIDATION_OPEN');

      if (state === _environment.default.constants.VALIDATION_APPROVED) {
        return Ember.String.htmlSafe(_environment.default.constants.COLOR_SUCCESS);
      } else if (state === _environment.default.constants.VALIDATION_REJECTED) {
        return Ember.String.htmlSafe(_environment.default.constants.ALERT_COLOR);
      } else {
        return Ember.String.htmlSafe('#F2F2F2; color: #4a4a4a');
      }
    }),
    hasAnyValidationActive: Ember.computed.or('accountSettings.activeCommentValidation', 'accountSettings.activeBreakValidation', 'accountSettings.activeHoursValidation'),
    statusTooltip: Ember.computed('accountSettings.activeCommentValidation', 'accountSettings.activeBreakValidation', 'accountSettings.activeHoursValidation', 'userData.durationHoursValid', 'userData.durationBreakValid', 'userData.durationCommentsValid', 'totalDuration', 'totalBreaks', 'totalComments', function () {
      var activeHoursValidation = this.get('accountSettings.activeHoursValidation');
      var activeBreakValidation = this.get('accountSettings.activeBreakValidation');
      var activeCommentValidation = this.get('accountSettings.activeCommentValidation');
      var i18n = this.get('i18n');
      var formatter = this.get('formatter');
      var baseString = '<div>';

      if (activeHoursValidation) {
        // prettier-ignore
        baseString += this._tooltipRow(this.get('userData.durationHoursValid'), 'validHours', formatter.formatDuration(this.get('totalDuration')), formatter.formatDuration(this.get('totalExpectedDuration')));
      }

      if (activeBreakValidation) {
        // prettier-ignore
        baseString += this._tooltipRow(this.get('userData.durationBreakValid'), 'validBreaks', this.get('totalBreaks'), this.get('totalExpectedBreaks'));
      }

      if (this.get('accountSettings.activeCommentValidation') !== 0) {
        if (this.get('accountSettings.activeCommentValidation') === 1) {
          baseString += baseString += this._tooltipRow(this.get('userData.durationCommentsValid'), 'validComments', this.get('totalComments'), this.get('totalExpectedComments'));
        } else if (this.get('accountSettings.activeCommentValidation') === 2) {
          baseString += baseString += this._tooltipRow(this.get('userData.durationCommentsValid'), 'validCommentsOrReferences', this.get('totalComments'), this.get('totalExpectedComments'));
        } else if (this.get('accountSettings.activeCommentValidation') === 3) {
          baseString += baseString += this._tooltipRow(this.get('userData.durationCommentsValid'), 'validReferences', this.get('totalComments'), this.get('totalExpectedComments'));
        } else if (this.get('accountSettings.activeCommentValidation') === 4) {
          baseString += baseString += this._tooltipRow(this.get('userData.durationCommentsValid'), 'validCommentsAndReferences', this.get('totalComments'), this.get('totalExpectedComments'));
        }
      }

      if (!activeBreakValidation && activeCommentValidation === 0 && !activeHoursValidation) {
        baseString += i18n.t('noValidationActive');
      }

      baseString += '</div>';
      return Ember.String.htmlSafe(baseString);
    }),
    rejectReason: Ember.computed('accountSettings.activeHoursValidation', 'accountSettings.activeBreakValidation', 'accountSettings.activeCommentValidation', 'totalExpectedDuration', 'totalDuration', 'totalExpectedBreaks', 'totalBreaks', 'totalExpectedComments', 'totalComments', function () {
      var _this = this;

      var activeHoursValidation = this.get('accountSettings.activeHoursValidation');
      var activeBreakValidation = this.get('accountSettings.activeBreakValidation');
      var activeCommentValidation = this.get('accountSettings.activeCommentValidation');
      var i18n = this.get('i18n');
      var reason = "";

      if (activeHoursValidation && !this.get('userData.durationHoursValid')) {
        reason += "- ".concat(i18n.t('hoursMissing'), "\n");
      }

      if (activeBreakValidation && !this.get('userData.durationBreakValid')) {
        var countMissingBreaks = this.get('totalExpectedBreaks') - this.get('totalBreaks');
        var formattedDates = this.datesWithMissingBreaks.map(function (el) {
          return _this.formatter.formatDate(moment(el));
        }).join(', ');

        if (countMissingBreaks === 1) {
          reason += "- ".concat(countMissingBreaks, " ").concat(i18n.t('breakMissing'), " (").concat(formattedDates, ")\n");
        } else {
          reason += "- ".concat(countMissingBreaks, " ").concat(i18n.t('breaksMissing'), " (").concat(formattedDates, ")\n");
        }
      }

      if (activeCommentValidation !== 0 && !this.get('userData.durationCommentsValid')) {
        var countMissingComments = this.get('totalExpectedComments') - this.get('totalComments');

        var _formattedDates = this.datesWithMissingComments.map(function (el) {
          return _this.formatter.formatDate(moment(el));
        }).join(', ');

        if (countMissingComments === 1) {
          reason += "- ".concat(countMissingComments, " ").concat(i18n.t('commentMissing'), " (").concat(_formattedDates, ")\n");
        } else {
          reason += "- ".concat(countMissingComments, " ").concat(i18n.t('commentsMissing'), " (").concat(_formattedDates, ")\n");
        }
      }

      return reason;
    }),
    computedDays: Ember.computed('daysInPeriod.[]', 'userData.days.[]', 'userData.absences.[]', function () {
      var _this2 = this;

      if (this.get('userData.days')) {
        var store = this.get('store');
        return this.get('daysInPeriod').map(function (day) {
          var userDataDays = _this2.get('userData.days');

          var userDataAbsences = _this2.get('userData.absences');

          var filteredContent = userDataDays && userDataDays.filter(function (dayDay) {
            return moment(dayDay.day).isSame(day, 'day');
          }).get('firstObject');
          var filteredAbsences = userDataAbsences && userDataAbsences.filter(function (absence) {
            return moment(absence.startDate).isSameOrBefore(day, 'day') && moment(absence.endDate).isSameOrAfter(day, 'day');
          }).map(function (el) {
            if (el.absenceType && !el.absenceType.get) {
              el.absenceType = store.peekRecord('absence-type', el.absenceType);
            }

            return el;
          });
          var publicHolidays = filteredAbsences.filterBy('absenceType.isPublicHoliday');
          filteredAbsences = filteredAbsences.filterBy('absenceType.isPublicHoliday', false);
          var counter = 0;

          if (filteredContent && filteredContent.timeEntries) {
            filteredContent.timeEntries = filteredContent.timeEntries.map(function (el) {
              return {
                id: el.id,
                duration: el.duration,
                hasComment: el.hasComment,
                hasReference: el.hasReference,
                user: el.user && store.peekRecord('user', typeof el.user === 'number' ? el.user : el.user.get('id')),
                project: el.project && store.peekRecord('project', typeof el.project === 'number' ? el.project : el.project.get('id')),
                task: el.task && store.peekRecord('task', typeof el.task === 'number' ? el.task : el.task.get('id'))
              };
            });
            counter++;
          }

          if (filteredAbsences && filteredAbsences.length > 0) {
            counter++;
          }

          var holidays = [].concat(_toConsumableArray(_this2.get('userData.holidays') && _this2.get('userData.holidays').filter(function (el) {
            return el.get('date').format('YYYY-MM-DD') === day.format('YYYY-MM-DD');
          }) || []), _toConsumableArray(publicHolidays.map(function (absence) {
            var _absence$comment;

            return Ember.Object.create({
              name: (_absence$comment = absence.comment) !== null && _absence$comment !== void 0 ? _absence$comment : _this2.get('i18n').t('publicHoliday'),
              type: 'holiday'
            });
          })));
          return {
            counter: counter,
            totalDayDuration: filteredContent ? filteredContent.totalDuration / 3600 : 0,
            content: filteredContent,
            durationHoursValid: _this2.get('userData.durationHoursValid'),
            absences: filteredAbsences,
            holidays: holidays
          };
        });
      } else {
        return [];
      }
    }),
    _updateCounterValues: Ember.observer('computedDays.[]', function () {
      var _this3 = this;

      var totalDuration = 0;
      var totalExpectedDuration = 0;
      var totalBreaks = 0;
      var totalExpectedBreaks = 0;
      var totalComments = 0;
      var totalExpectedComments = 0;
      var datesWithMissingBreaks = [];
      var datesWithMissingComments = [];
      this.get('computedDays').forEach(function (day) {
        totalDuration += day.totalDayDuration;
        totalExpectedDuration += day.content ? day.content.secondsToWork / 3600 : 0;
        var breaksOfDay = day.content && day.content.breaks && day.content.breaks.length > 0 ? 1 : 0;
        totalBreaks += breaksOfDay;
        var expectedBreaksOfDay = !day.content || !day.content.secondsToWork || day.content.secondsToWork.length === 0 ? 0 : 1;
        var commentsOfDay = day.content && day.content.timeEntries ? day.content.timeEntries.filter(function (te) {
          switch (_this3.get('accountSettings.activeCommentValidation')) {
            case 1:
              {
                return te.hasComment;
              }

            case 2:
              {
                return te.hasComment || te.hasReference;
              }

            case 3:
              {
                return te.hasReference;
              }

            case 4:
              {
                return te.hasComment && te.hasReference;
              }
          }

          return te.hasComment;
        }).length : 0;
        totalComments += commentsOfDay;
        var expectedCommentsOfDay = day.content && day.content.timeEntries ? day.content.timeEntries.length : 0;
        totalExpectedComments += expectedCommentsOfDay;

        if (breaksOfDay < expectedBreaksOfDay) {
          datesWithMissingBreaks.push(day.content.day);
        }

        if (commentsOfDay < expectedCommentsOfDay) {
          datesWithMissingComments.push(day.content.day);
        }
      });
      this.setProperties({
        totalDuration: totalDuration,
        totalExpectedDuration: totalExpectedDuration,
        totalBreaks: totalBreaks,
        totalExpectedBreaks: totalExpectedBreaks,
        totalComments: totalComments,
        totalExpectedComments: totalExpectedComments,
        datesWithMissingBreaks: datesWithMissingBreaks,
        datesWithMissingComments: datesWithMissingComments
      });
    }).on('init'),
    hoursTooltip: Ember.computed('totalDuration', 'totalExpectedDuration', 'userData.durationHoursValid', function () {
      var i18n = this.get('i18n');
      var formatter = this.get('formatter');
      return i18n.t('amountOfHours', {
        value: formatter.formatDuration(this.get('totalDuration'), true),
        expectedValue: formatter.formatDuration(this.get('totalExpectedDuration'), true)
      });
    }),
    breaksTooltip: Ember.computed('totalBreaks', 'totalExpectedBreaks', 'userData.durationBreakValid', function () {
      var i18n = this.get('i18n');
      return i18n.t('amountOfBreaks', {
        value: this.get('totalBreaks'),
        expectedValue: this.get('totalExpectedBreaks')
      });
    }),
    commentsTooltip: Ember.computed('totalComments', 'totalExpectedComments', 'userData.durationCommentsValid', function () {
      var i18n = this.get('i18n');
      return i18n.t('amountOfComments', {
        value: this.get('totalComments'),
        expectedValue: this.get('totalExpectedComments')
      });
    }),
    referenceTooltip: Ember.computed('totalComments', 'totalExpectedComments', 'userData.durationCommentsValid', function () {
      var i18n = this.get('i18n');
      return i18n.t('amountOfReferences', {
        value: this.get('totalComments'),
        expectedValue: this.get('totalExpectedComments')
      });
    }),
    commentsOrReferenceTooltip: Ember.computed('totalComments', 'totalExpectedComments', 'userData.durationCommentsValid', function () {
      var i18n = this.get('i18n');
      return i18n.t('amountOfReferencesOrComments', {
        value: this.get('totalComments'),
        expectedValue: this.get('totalExpectedComments')
      });
    }),
    commentsAndReferencesTooltip: Ember.computed('totalComments', 'totalExpectedComments', 'userData.durationCommentsValid', function () {
      var i18n = this.get('i18n');
      return i18n.t('amountOfReferencesAndComments', {
        value: this.get('totalComments'),
        expectedValue: this.get('totalExpectedComments')
      });
    }),
    _setRowHeightToZero: function _setRowHeightToZero() {
      this.set('isRemoved', true);
    },
    _removeFromList: function _removeFromList() {
      if (this.get('selectedFilterMode') !== 'all') {
        this.set('hideFromUI', true);
      }

      this.sendAction('onRemove', this.get('userData'));
    },
    _createAndSaveNewValidation: function _createAndSaveNewValidation(validationState) {
      var _this4 = this;

      var currentUser = this.get('currentUserService.currentUser');
      var newValidation = this.get('store').createRecord('validation', {
        validatedBy: currentUser,
        user: this.get('userData.user'),
        state: validationState,
        week: moment(this.get('week'))
      });
      newValidation.save().then(function () {
        _this4.get('notifications').success('alert.saved', 'validation');

        if (!_this4.get('isDestroyed')) {
          Ember.run.later(_this4, function () {
            _this4.set('userData.validation', newValidation);

            _this4._removeFromList();
          }, 400);
        }
      }).catch(function (err) {
        _this4.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'validation');
      });
    },
    _updatedAndSaveValidation: function _updatedAndSaveValidation(validationState) {
      var _this5 = this;

      var validation = this.get('userData.validation');
      validation.set('state', validationState);
      validation.save().then(function () {
        _this5.get('notifications').success('alert.saved', 'validation');

        Ember.run.later(_this5, function () {
          if (!_this5.get('isDestroyed')) {
            _this5.set('userData.validation', validation);

            _this5._removeFromList();
          }
        }, 400);
      }).catch(function (err) {
        _this5.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'validation');
      });
    },
    disabledReminder: Ember.computed('clock.day', 'userData.validation.remindedAt', function () {
      return this.get('userData.validation.remindedAt') && this.get('clock.now').isSame(this.get('userData.validation.remindedAt'), 'day');
    }),
    actions: {
      didDaySelect: function didDaySelect(day) {
        var _this6 = this;

        this.set('selectedDay', day);

        if (day) {
          this.set('breakMissingOnDay', day.content.breakMissing);
          var timeEntryIds = [];
          if (day && day.content && day.content.timeEntries) timeEntryIds.pushObjects(day.content.timeEntries.getEach('id'));
          if (day && day.content && day.content.breaks) timeEntryIds.pushObjects(day.content.breaks.getEach('id'));

          if (day.content && day.content.timeEntries) {
            this.get('store').query('time-entry', {
              where: {
                id: timeEntryIds
              }
            }).then(function (timeEntries) {
              _this6.set('timeEntriesForDay', timeEntries);

              _this6.set('accuraciesForDay', day.content.accuracies);
            }).catch(console.error);
          } else {
            var hasAbsenceOnDay = day && day.absences && day.absences.length > 0;
            var hasAbsenceWithNoExpectedHours = hasAbsenceOnDay && day.absences.reduce(function (prev, el) {
              if (!el.workHoursExpected && el.isHalfDay) {
                return prev + 0.5;
              } else if (!el.workHoursExpected && el.isHalfDay) {
                return prev + 1;
              } else {
                return prev;
              }
            }, 0);
            var hasNoExpectedHours = day && day.content && day.content.secondsToWork === 0;
            this.set('hasNotToWorkOnDay', hasAbsenceWithNoExpectedHours >= 1 || hasNoExpectedHours);
            this.set('timeEntriesForDay', []);
            this.set('accuraciesForDay', []);
          }
        }
      },
      remindUser: function remindUser() {
        var _this7 = this;

        this.get('session').postJSON((0, _buildUrl.default)(this.get('store'), 'validate/remindUser'), {
          validationId: this.get('userData.validation.id')
        }).then(function () {
          _this7.set('userData.validation.remindedAt', _this7.get('clock.now'));

          _this7.get('notifications').success('userRemindedToUpdateValdidation');
        }).catch(function (err) {
          _this7.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'validation');
        });
      },
      updateValidation: function updateValidation(validationState, alreadyCreatedValidation) {
        var _this8 = this;

        this.set('selectedDay', null);
        var delay = 0;

        if (validationState === _environment.default.constants.VALIDATION_APPROVED || validationState === _environment.default.constants.VALIDATION_REJECTED) {
          this.set('showPlaceholder', true);
          delay = 400;
        }

        if (this.get('selectedFilterMode') !== 'all') {
          Ember.run.later(this, function () {
            if (alreadyCreatedValidation) {
              _this8.set('userData.validation', alreadyCreatedValidation);

              _this8._removeFromList();
            } else {
              if (!_this8.get('userData.isAlreadyValidated') && !_this8.get('userData.validation')) {
                _this8._createAndSaveNewValidation(validationState);
              } else {
                _this8._updatedAndSaveValidation(validationState);
              }
            }
          }, delay);
          Ember.run.later(this, this._setRowHeightToZero, 400);
        } else {
          if (alreadyCreatedValidation) {
            this.set('userData.validation', alreadyCreatedValidation);
          } else {
            if (!this.get('userData.isAlreadyValidated') && !this.get('userData.validation')) {
              this._createAndSaveNewValidation(validationState);
            } else {
              this._updatedAndSaveValidation(validationState);
            }
          }

          this.set('showPlaceholder', false);
        }
      },
      selectTimeEntry: function selectTimeEntry(timeEntry) {
        this.toggleProperty('openTimeEntryModal');
        this.set('selectedTimeEntry', timeEntry);
      }
    }
  });

  _exports.default = _default;
});