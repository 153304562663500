define("coffeecup/adapters/-super-sails-socket", ["exports", "coffeecup/config/environment", "coffeecup/mixins/auth-data-adapter-mixin", "@ember-data/adapter/rest", "coffeecup/mixins/reconnect", "ember-inflector", "ember-data-sails/adapters/sails-socket"], function (_exports, _environment, _authDataAdapterMixin, _rest, _reconnect, _emberInflector, _sailsSocket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import SailsRestAdapter from 'ember-data-sails/adapters/sails-rest';

  /*global io*/
  // TODO Fix general issue with socket adapter in tests
  var Adapter;

  if (_environment.default.environment === 'test') {
    Adapter = _rest.default;
  } else {
    Adapter = _sailsSocket.default;
  }

  var SuperSailsSocketAdapter = Adapter.extend(_authDataAdapterMixin.default, _reconnect.default, {
    host: _environment.default.apiHost,
    authorizer: 'authorizer:oauth2',
    session: Ember.inject.service(),

    /**
     * Whether to use CSRF tokens or not
     */
    useCSRF: false,

    /**
     * Whether to group multiple find by ID with one request with a `where`
     */
    coalesceFindRequests: false,

    /**
     * The namespace of your API
     */
    namespace: 'v1',

    /**
     * If you want to use https://github.com/mphasize/sails-generate-ember-blueprints,
     * you need to override the default serializer to be used
     */
    defaultSerializer: '-rest',
    _appendAccessToken: function _appendAccessToken(url) {
      var accessToken = this.get('session.data.authenticated.access_token');

      if (accessToken) {
        url += '?access_token=' + accessToken;
      }

      return url;
    },

    /**
     * By default Sails does not pluralize the model names when creating urls to a model's route.
     * This is different from the normal Ember Data conventions. To make it easy to get started the SailsRESTAdapter and
     * SailsSocketAdapter will attempt to singularize the model name when requesting a model's route.
     * If you would like to use a pluralized model name in the route you can override the default pathForType method.
     */
    pathForType: function pathForType(type) {
      var camelized = Ember.String.camelize(type);
      return (0, _emberInflector.pluralize)(camelized);
    },
    buildURL: function buildURL(type) {
      this._listenToSocket(type);

      return this._super.apply(this, arguments);
    }
  });
  var _default = SuperSailsSocketAdapter;
  _exports.default = _default;
});