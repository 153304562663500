define("coffeecup/components/validation/footer-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['d-flex', 'validation-footer-element'],
    accountService: Ember.inject.service('account'),
    permissions: Ember.inject.service(),
    accountSettings: Ember.computed.reads('accountService.accountSettings'),
    currentUser: null,
    selectedUser: null,
    validationCheck: null,
    userHasFlexibleHours: false,
    userHoursExpected: 0,
    expectedHoursSurpassed: Ember.computed('userHoursTotal', 'userHoursExpected', function () {
      return this.get('userHoursTotal') >= this.get('userHoursExpected');
    }),
    _updateCounterValues: Ember.observer('validationCheck', function () {
      var _this = this;

      var validationCheck = this.get('validationCheck');
      var breaks = 0;
      var expectedBreaks = 0;
      var comments = 0;
      var expectedComments = 0;
      var totalDuration = 0;
      var totalExpectedDuration = 0;

      if (validationCheck && validationCheck.days) {
        validationCheck.days.forEach(function (validationDay) {
          expectedBreaks += validationDay.secondsToWork && validationDay.secondsToWork > 0 ? 1 : 0;
          breaks += validationDay.breaks && validationDay.breaks.length > 0 ? 1 : 0;
          comments += validationDay.timeEntries ? validationDay.timeEntries.filter(function (el) {
            switch (_this.get('accountSettings.activeCommentValidation')) {
              case 1:
                {
                  return el.hasComment;
                }

              case 2:
                {
                  return el.hasComment || el.hasReference;
                }

              case 3:
                {
                  return el.hasReference;
                }

              case 4:
                {
                  return el.hasComment && el.hasReference;
                }
            }

            return el.hasComment;
          }).length : 0;
          expectedComments += validationDay.timeEntries ? validationDay.timeEntries.length : 0;
          totalDuration += validationDay.totalDuration ? validationDay.totalDuration : 0;
          totalExpectedDuration += validationDay.secondsToWork ? validationDay.secondsToWork : 0;
        });
      }

      this.set('breaks', breaks);
      this.set('expectedBreaks', expectedBreaks);
      this.set('comments', comments);
      this.set('expectedComments', expectedComments);
      this.set('totalDuration', totalDuration / 3600);
      this.set('totalExpectedDuration', totalExpectedDuration / 3600);
    }).on('init')
  });

  _exports.default = _default;
});