define("coffeecup/demo-data/dashboard/my-week-planning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.myWeekPlanningData = void 0;
  var myWeekPlanningData = [{
    project: {
      uppercaseCode: 'INTRN'
    },
    isProjectManagerForProject: false,
    trackedHours: 2.75,
    plannedHours: 5,
    expectedHours: 33,
    progress: 0.55,
    remainingHours: 2.25
  }, {
    project: {
      uppercaseCode: 'TL-SCA'
    },
    isProjectManagerForProject: false,
    trackedHours: 4.25,
    plannedHours: 1,
    expectedHours: 33,
    progress: 4.25,
    remainingHours: -3.25
  }, {
    project: {
      uppercaseCode: 'TL-WRTNG'
    },
    isProjectManagerForProject: false,
    trackedHours: 0,
    plannedHours: 1,
    expectedHours: 33,
    progress: 0,
    remainingHours: 1
  }, {
    trackedHours: 0.75,
    plannedHours: 4,
    expectedHours: 33,
    isProjectManagerForProject: false,
    progress: 0.1875,
    remainingHours: 3.25,
    numberOfProjects: 3
  }];
  _exports.myWeekPlanningData = myWeekPlanningData;
});