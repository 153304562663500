define("coffeecup/controllers/admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AdminController = Ember.Controller.extend({
    menu: []
  });
  var _default = AdminController;
  _exports.default = _default;
});