define("coffeecup/components/summary-bar-entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * deteremines wetherthe entry should be displayed
     * @returns {boolean}
     */
    visible: Ember.computed('budgetBy', {
      get: function get() {
        return this.get('value') != '-' || !this.get('value');
      }
    }),

    /**
     * the position of the tooltip
     */
    tooltipPlace: 'top-right',

    /**
     * the label of the entry
     */
    label: null,

    /**
     * the value of the entry
     */
    value: null
  });

  _exports.default = _default;
});