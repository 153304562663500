define("coffeecup/components/server-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['server-alert'],
    classNameBindings: ['hidden'],
    hidden: Ember.computed.not('show'),
    msg: 'serverRestartAlert',
    blocks: false,
    reload: false,
    message: Ember.computed('i18n.locale', 'msg', {
      get: function get() {
        return this.get('i18n').t(this.get('msg'));
      }
    }),
    actions: {
      reloadPage: function reloadPage() {
        window.location.reload();
      }
    }
  });

  _exports.default = _default;
});