define("coffeecup/components/planner/assignment-frame", ["exports", "coffeecup/components/planner/time-frame", "coffeecup/mixins/deferred-setup", "coffeecup/mixins/planner/label-position", "coffeecup/utils/tooltip-planner"], function (_exports, _timeFrame, _deferredSetup, _labelPosition, _tooltipPlanner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _timeFrame.default.extend(_deferredSetup.default, _labelPosition.default, {
    activeModal: Ember.inject.service('planner.active-modal'),
    classNames: ['assignment-frame'],
    classNameBindings: ['editable::readonly', 'isActive:is-active', 'isActive:modal-reference', 'assignment.activeOnDaysOff'],
    assignment: null,
    isActive: false,
    editable: null,
    mouseDownTarget: null,
    mouseUpTarget: null,
    tornDown: false,
    useProjectColor: null,
    backgroundColor: Ember.computed('assignment.project.color', 'projectColor', {
      get: function get() {
        var projectColor = this.get('assignment.project.color');
        var useProjectColor = this.get('useProjectColor');

        if (projectColor && useProjectColor) {
          return projectColor.get('hexString');
        }
      }
    }),
    tooltipContent: Ember.computed('assignment.comment', 'assignment.startDateFmt', 'assignment.endDateFmt', 'assignment.totalAllocation', 'assignment.allocationAsInt', {
      get: function get() {
        var _ref;

        var i18n = this.get('i18n');
        var comment = this.get('assignment.comment'); // startDateFmt and endDateFmt are formatted with the 'L' setting, which is localized per moment rules

        var startDate = moment(this.get('assignment.startDateFmt'), 'L');
        var endDate = moment(this.get('assignment.endDateFmt'), 'L'); // TODO - These custom format strings should use the user's local settings so that Americans get their MM/DD format
        // However, we don't currently have a stored preference for just the Month/Day setting

        var date = "".concat(startDate.format('DD.MM.'), " - ").concat(endDate.format('DD.MM.'));
        var allocation = this.get('assignment.allocationAsInt');
        var isWeekly = this.get('viewport.isWeeklyPlanningMode');
        var periodAllocation = (_ref = (isWeekly ? 5 : 1) * allocation) !== null && _ref !== void 0 ? _ref : 0;
        var tooltipObject = {
          i18n: i18n,
          comment: comment ? "<div class=\"tooltip-comment\">".concat(Ember.String.htmlSafe(comment), "</div>") : '',
          date: date,
          allocation: periodAllocation,
          isWeekly: isWeekly,
          total: Math.round(this.get('assignment.totalAllocation')),
          color: this.get('assignment.project.projectState.color'),
          status: this.get('assignment.project.projectState.label'),
          isTrackable: this.get('assignment.project.projectState.isTrackable'),
          hasAllocation: true,
          isPlanned: false,
          hasTotal: true,
          hasProject: false,
          hasClient: false
        };
        return (0, _tooltipPlanner.default)(tooltipObject);
      }
    }),
    content: Ember.computed.reads('assignment.comment'),
    style: Ember.computed('width', 'left', 'backgroundColor', function () {
      var backgroundColor = this.get('backgroundColor') ? "background-color: ".concat(this.get('backgroundColor'), ";") : '';
      return Ember.String.htmlSafe('width:' + this.get('width') + 'px;left:' + this.get('left') + 'px;' + backgroundColor);
    }),
    startDate: Ember.computed('assignment.startDateFmt', {
      get: function get() {
        return moment(this.get('assignment.startDate'));
      }
    }),
    endDate: Ember.computed('assignment.endDateFmt', {
      get: function get() {
        return moment(this.get('assignment.endDate'));
      }
    }),
    setup: Ember.on('didInsertElement', function () {
      if (this.get('isActive')) {
        var viewport = this.get('viewport');
        var startDate = moment(this.get('startDate'));
        var viewportStartDate = viewport.get('startDate');

        if (startDate.isBefore(viewportStartDate)) {
          startDate = viewportStartDate;
          Ember.run.next(this, function () {
            viewport.get('selectedDay') || viewport.selectDay(startDate);
          });
        }
      }
    }),
    editableObserver: Ember.observer('editable', function () {
      this.get('editable') ? this.resolveSetup() : this.teardown();
    }),
    displayAsWeek: Ember.computed('viewport.isWeeklyPlanningMode', 'assignment.startDate', 'assignment.endDate', function () {
      return this.get('viewport.isWeeklyPlanningMode') && this.get('assignment.startDate').day() === 1 && this.get('assignment.endDate').day() === 5;
    }),
    allocationPerPeriod: Ember.computed('assignment.allocation', 'assignment.startDate', 'assignment.endDate', function () {
      var baseAllocation = this.get('assignment.allocation');
      return Math.round((this.get('displayAsWeek') ? baseAllocation * 5 : baseAllocation) * 100) / 100;
    }),
    showTotalAllocation: Ember.computed.reads('atLeastTwoDays'),
    totalAllocation: Ember.computed('assignment.totalAllocation', function () {
      return Math.round(this.get('assignment.totalAllocation')) + 'h';
    }),
    gridSize: Ember.computed('displayAsWeek', 'viewport.dayWidth', function () {
      return this.get('displayAsWeek') ? this.get('viewport.assignmentWidth') : this.get('viewport.dayWidth');
    }),
    assignmentWidthObserver: Ember.observer('gridSize', function () {
      if (this.get('isSetup') && this.get('editable')) {
        this.$().draggable('option', 'grid', [this.get('gridSize'), 0]);
        this.$().resizable('option', 'grid', [this.get('gridSize'), 0]);
      }
    }),
    resolveSetup: function resolveSetup() {
      if (this.get('editable')) {
        this._super();

        this.set('tornDown', false);
        this.setupDrag();
        this.setupResize();
      }
    },
    setupDrag: function setupDrag() {
      var leftPosition = null;
      this.$().draggable({
        axis: 'x',
        grid: [this.get('gridSize'), 0],
        start: Ember.run.bind(this, function (event, elem) {
          this.$().addClass('is-active');
          leftPosition = elem.originalPosition.left;
        }),
        drag: Ember.run.bind(this, function (event, elem) {
          var dayWidth = this.get('viewport.dayWidth');

          if (leftPosition !== elem.position.left) {
            this.moveAssignmentLabel((elem.originalPosition.left - elem.position.left) / dayWidth);
            leftPosition = elem.position.left;
          }
        }),
        stop: Ember.run.bind(this, function (event, elem) {
          this.$().removeClass('is-active');
          var dayWidth = this.get('viewport.dayWidth');
          var shift = (elem.position.left - elem.originalPosition.left) / dayWidth;
          var assignment = this.get('assignment');

          if (shift) {
            assignment.set('startDate', this.get('viewport').shiftDate(assignment.get('startDate'), shift));
            assignment.set('endDate', this.get('viewport').shiftDate(assignment.get('endDate'), shift));
            this.sendAction('onMoved', assignment);
          }

          this.$().draggable('destroy');
          this.setupDrag();
        })
      });
    },
    setupResize: function setupResize() {
      this.$().resizable({
        handles: 'e, w',
        grid: [this.get('gridSize'), 0],
        classes: {
          'ui-resizable-handle': 'icomoon-assignment-handle'
        },
        animate: !1,
        start: Ember.run.bind(this, function (e, t) {
          this.$().addClass('is-active');
        }),
        stop: Ember.run.bind(this, function (e, t) {
          this.$().removeClass('is-active');
          var dayWidth = this.get('viewport.dayWidth');
          var assignment = this.get('assignment');
          var daysToResize = (t.position.left - t.originalPosition.left) / dayWidth;

          if (daysToResize) {
            assignment.set('startDate', this.get('viewport').shiftDate(assignment.get('startDate'), daysToResize));
          } else {
            daysToResize = (t.size.width - t.originalSize.width) / dayWidth;

            if (daysToResize) {
              assignment.set('endDate', this.get('viewport').shiftDate(assignment.get('endDate'), daysToResize));
            }
          }

          if (daysToResize) {
            this.sendAction('onMoved', assignment);
          }
        })
      });
    },
    teardownDrag: function teardownDrag() {
      this.$().draggable('destroy');
    },
    teardownResize: function teardownResize() {
      this.$().resizable('destroy');
    },
    teardown: function teardown() {
      if (!this.get('tornDown') && this.get('isSetup')) {
        this.set('tornDown', true);
        this.teardownDrag();
        this.teardownResize();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.teardown();
    },
    clickedDay: function clickedDay(clientX) {
      var left = this.$().offset().left;
      var dayWidth = this.get('viewport.dayWidth');
      var daysToShift = Math.floor((clientX - left) / dayWidth);
      return this.get('viewport').shiftDate(this.get('startDate'), daysToShift);
    },
    click: function click(event) {
      var plannerAssignment = this.get('assignment');

      if (this.get('editable') && this.get('mouseDownTarget') === this.get('mouseUpTarget')) {
        this.get('viewport').selectDay(this.clickedDay(event.clientX));
        this.get('activeModal').setPlannerAssignment(plannerAssignment.get('id'));
        this.sendAction('onClick', plannerAssignment);
        this.set('mouseDownTarget', null);
        this.set('mouseUpTarget', null);
      }
    },
    mouseUp: function mouseUp(event) {
      this.set('mouseUpTarget', event.target);
    },
    mouseDown: function mouseDown(event) {
      this.set('mouseDownTarget', event.target);
    },
    actions: {
      onSplit: function onSplit() {
        this.sendAction('onSplit', this.get('assignment'));
      }
    }
  });

  _exports.default = _default;
});