define("coffeecup/components/analytics/project-budget-remaining", ["exports", "coffeecup/mixins/project-budget"], function (_exports, _projectBudget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_projectBudget.default, {
    tagName: 'span'
  });

  _exports.default = _default;
});