define("coffeecup/components/viewport-date-picker", ["exports", "coffeecup/utils/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    drpObj: null,
    separator: ' - ',
    drops: 'down',
    opens: 'center',
    classNames: ['date-month-selector-parent'],
    viewport: Ember.inject.service('planner.viewport'),
    formatter: Ember.inject.service(),
    isCurrentWeek: Ember.computed('viewport.startDate', 'currentWeek', {
      get: function get() {
        return this.get('currentWeek').isSame(this.get('viewport.startDate'), 'day');
      }
    }),
    currentWeek: Ember.computed('clock.week', {
      get: function get() {
        return this.get('clock.localDayAsUtcMoment').startOf('isoWeek');
      }
    }),
    numberOfWeeks: Ember.computed('viewport.startDate', 'viewport.endDate', function () {
      return (0, _date.numberOfWeeks)(this.get('viewport.startDate'), this.get('viewport.endDate').clone().subtract(1, 'weeks'));
    }),
    weekRange: Ember.computed('viewport.startDate', 'viewport.endDate', {
      get: function get() {
        var formatter = this.get('formatter');
        return formatter.formatDate(this.get('viewport.startDate')) + this.get('separator') + formatter.formatDate(this.get('viewport.endDate').clone().subtract(1, 'weeks'));
      }
    }),
    setDrpDateRange: Ember.observer('viewport.startDate', function () {
      var $drpObj = this.get('drpObj');

      if ($drpObj) {
        $drpObj.setStartDate(this.get('viewport.startDate'));
        $drpObj.setEndDate(this.get('viewport.startDate').clone().endOf('isoWeek'));
      }
    }),
    //Init the dropdown when the component is added to the DOM
    didInsertElement: function didInsertElement() {
      var _this = this;

      var $input = this.$('.date-range-picker').daterangepicker({
        parentEl: '#date-picker-container',
        ranges: null,
        singleDatePicker: true,
        showWeekNumbers: true,
        showDropdowns: true,
        drops: this.get('drops'),
        opens: this.get('opens')
      }, function (start, end, label) {
        start = start.utc().startOf('isoWeek');

        _this.set('viewport.startDate', start);
      }); // save a reference to the date picker-obj

      this.set('drpObj', $input.data('daterangepicker')); // add a css class

      this.get('drpObj').container.first().addClass('date-week-picker'); // highlight current selected week

      this.setDrpDateRange();
    },
    //Remove the hidden dropdown when this component is destroyed
    willDestroy: function willDestroy() {
      this.get('drpObj').remove();
    },
    actions: {
      currentWeek: function currentWeek() {
        var thisWeek = this.get('clock.localDayAsUtcMoment').startOf('isoWeek');
        this.set('viewport.startDate', thisWeek);
      },
      prevWeek: function prevWeek() {
        var previousWeek = this.get('viewport.startDate').clone().subtract(1, 'week');
        this.set('viewport.startDate', previousWeek);
      },
      nextWeek: function nextWeek() {
        var nextWeek = this.get('viewport.startDate').clone().add(1, 'week');
        this.set('viewport.startDate', nextWeek);
      }
    }
  });

  _exports.default = _default;
});