define("coffeecup/components/manage/projects/expenses/expense-list", ["exports", "coffeecup/mixins/download-attachment", "moment"], function (_exports, _downloadAttachment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_downloadAttachment.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUserService: Ember.inject.service('currentUser'),
    isAdmin: Ember.computed.reads('currentUserService.isAdmin'),
    showModal: false,
    expenses: null,
    expenseCategories: null,
    selectedExpense: null,
    project: null,
    inBudgetExpensesTotal: Ember.computed('inBudgetExpenses.[]', function () {
      var total = {
        external: 0,
        internal: 0
      };
      this.get('inBudgetExpenses').forEach(function (el) {
        total.external += el.get('priceExternal');
        total.internal += el.get('priceInternal');
      });
      return total;
    }),
    extraExpensesTotal: Ember.computed('extraExpenses.[]', function () {
      var total = {
        external: 0,
        internal: 0
      };
      this.get('extraExpenses').forEach(function (el) {
        total.external += el.get('priceExternal');
        total.internal += el.get('priceInternal');
      });
      return total;
    }),
    inBudgetExpenses: Ember.computed.filterBy('expenses', 'inBudget', true),
    orderedInBudgetExpenses: Ember.computed('inBudgetExpenses.[]', function () {
      return this.get('inBudgetExpenses').sortBy('createdAt').reverse();
    }),
    extraExpenses: Ember.computed.filterBy('expenses', 'inBudget', false),
    totalExpenseAmount: Ember.computed('expenses.@each.{priceExternal}', function () {
      if (!this.get('expenses')) return 0;
      return this.get('expenses').reduce(function (prev, el) {
        return prev + el.get('priceExternal');
      }, 0);
    }),
    totalExpenseInternal: Ember.computed('expenses.@each.{priceInternal}', function () {
      if (!this.get('expenses')) return 0;
      return this.get('expenses').reduce(function (prev, el) {
        return prev + el.get('priceInternal');
      }, 0);
    }),
    actions: {
      hideExpenseModal: function hideExpenseModal() {
        this.set('showModal', false);
      },
      showExpenseModal: function showExpenseModal(expense) {
        if (expense) {
          this.set('selectedExpense', expense);
        } else {
          this.set('selectedExpense', this.get('store').createRecord('expense', {
            project: this.get('project'),
            day: this.get('clock.now'),
            expenseCategory: this.get('expenseCategories.firstObject')
          }));
        }

        this.set('showModal', true);
      },
      onCreateNewExpense: function onCreateNewExpense(expense, isNew) {
        if (isNew) {
          this.get('expenses').pushObject(expense);
        }
      },
      deleteElement: function deleteElement(expense) {
        var _this = this;

        var project = expense.get('project');
        expense.deleteRecord();
        expense.save().then(function () {
          _this.get('expenses').removeObject(expense);

          _this.get('notifications').success('alert.deleted', 'expense');

          project.get('analytics').reload();
        }).catch(function (err) {
          _this.get('notifications').errorWithObject(err, 'alert.errorOnDelete', 'expense');
        });
      },
      markExpenseAsUnbilled: function markExpenseAsUnbilled(expense) {
        var _this2 = this;

        expense.set('billedAt', null);
        expense.save().then(function () {
          _this2.get('notifications').success('alert.saved', 'expense');
        });
      },
      markAsBilled: function markAsBilled(expense) {
        var _this3 = this;

        expense.set('billedAt', (0, _moment.default)());
        expense.save().then(function () {
          _this3.get('notifications').success('alert.saved', 'expense');
        });
      }
    }
  });

  _exports.default = _default;
});