define("coffeecup/demo-data/dashboard/my-week-events", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.eventsDemoData = _exports.myWeekEventsData = void 0;
  var myWeekEventsData = [{
    day: (0, _moment.default)().startOf('week').format('DD.MM.'),
    rawDay: (0, _moment.default)().startOf('week'),
    events: [{
      type: 'milestone',
      color: '#028ac0',
      label: '3 Meilensteine'
    }, {
      type: 'birthday',
      color: '#9B51E0',
      label: '1 Geburtstag'
    }, {
      type: 'sameDate',
      color: '#0388C9',
      label: '2 Abwesenheiten'
    }]
  }, {
    day: (0, _moment.default)().startOf('week').add(1, 'day').format('DD.MM.'),
    rawDay: (0, _moment.default)().startOf('week').add(1, 'day'),
    events: [{
      type: 'absence',
      color: '#CA3F50',
      label: 'Lisa Feldmayer',
      icon: 'illness'
    }, {
      type: 'absence',
      color: '#0388C9',
      label: 'Johannes Stich',
      icon: 'vacation'
    }]
  }, {
    day: (0, _moment.default)().startOf('week').add(2, 'day').format('DD.MM.'),
    rawDay: (0, _moment.default)().startOf('week').add(2, 'day'),
    events: [{
      type: 'absence',
      color: '#CA3F50',
      label: 'Joshua Smith',
      icon: 'illness'
    }]
  }, {
    day: (0, _moment.default)().startOf('week').add(3, 'day').format('DD.MM.'),
    rawDay: (0, _moment.default)().startOf('week').add(3, 'day'),
    events: [{
      type: 'milestone',
      color: '#985b9c',
      label: 'M/S / CC-MVP-MOTIVATION'
    }]
  }, {
    day: (0, _moment.default)().startOf('week').add(4, 'day').format('DD.MM.'),
    rawDay: (0, _moment.default)().startOf('week').add(4, 'day'),
    events: [{
      type: 'milestone',
      color: '#028ac0',
      label: 'M/S / CC-NDUPDT'
    }, {
      type: 'absence',
      color: '#0388C9',
      label: 'Joshua Smith',
      icon: 'vacation'
    }, {
      type: 'absence',
      color: '#0388C9',
      label: 'Natasha Ramirez',
      icon: 'vacation'
    }]
  }];
  _exports.myWeekEventsData = myWeekEventsData;
  var eventsDemoData = [{
    day: (0, _moment.default)().add(1, 'day').format('DD.MM.'),
    rawDay: (0, _moment.default)().add(1, 'day'),
    events: [{
      type: 'milestone',
      color: '#5f7997',
      label: 'M/S / INTRN',
      tooltipLabel: 'Meilenstein',
      tooltipSublabel: 'Intern / Internes Projekt'
    }]
  }, {
    day: (0, _moment.default)().add(7, 'day').format('DD.MM.'),
    rawDay: (0, _moment.default)().add(7, 'day'),
    events: [{
      type: 'milestone',
      color: '#5f97ab',
      label: 'M/S / FSC-CPU',
      tooltipLabel: 'Meilenstein',
      tooltipSublabel: 'Cordova & Plugins Up... / Deutscher Fahrschulverlag GmbH'
    }]
  }, {
    day: (0, _moment.default)().add(10, 'day').format('DD.MM.'),
    rawDay: (0, _moment.default)().add(10, 'day'),
    events: [{
      type: 'milestone',
      color: '#028ac0',
      label: 'M/S / CC-MRKTNG',
      tooltipLabel: 'Meilenstein',
      tooltipSublabel: 'Marketing & Sales / CoffeeCup GmbH'
    }, {
      type: 'milestone',
      color: '#028ac0',
      label: 'M/S / CC-WFA',
      tooltipLabel: 'Meilenstein',
      tooltipSublabel: 'Website & Free Accou... / CoffeeCup GmbH'
    }]
  }, {
    day: (0, _moment.default)().add(1, 'month').format('DD.MM.'),
    rawDay: (0, _moment.default)().add(1, 'month'),
    events: [{
      type: 'milestone',
      color: '#5f7997',
      label: 'M/S / INTRN',
      tooltipLabel: 'Meilenstein',
      tooltipSublabel: 'Intern / Internes Projekt'
    }]
  }, {
    day: (0, _moment.default)().add(2, 'month').format('DD.MM.'),
    rawDay: (0, _moment.default)().add(2, 'month'),
    events: [{
      type: 'milestone',
      color: '#5f7997',
      label: 'M/S / INTRN',
      tooltipLabel: 'Meilenstein',
      tooltipSublabel: 'Intern / Internes Projekt'
    }]
  }, {
    day: (0, _moment.default)().add(4, 'month').format('DD.MM.'),
    rawDay: (0, _moment.default)().add(4, 'month'),
    events: [{
      type: 'milestone',
      color: '#5f7997',
      label: 'M/S / INTRN',
      tooltipLabel: 'Meilenstein',
      tooltipSublabel: 'Intern / Internes Projekt'
    }]
  }];
  _exports.eventsDemoData = eventsDemoData;
});