define("coffeecup/components/modals/reject-validation", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {},
    store: Ember.inject.service(),
    currentUserService: Ember.inject.service('currentUser'),
    currentUser: Ember.computed.reads('currentUserService.currentUser'),
    openModal: false,
    reason: null,
    validation: null,
    recipient: null,
    week: null,
    state: _environment.default.constants.VALIDATION_REJECTED,
    isModeReject: Ember.computed.equal('state', _environment.default.constants.VALIDATION_REJECTED),
    _createAndSaveLog: function _createAndSaveLog(validation, currentUser) {
      var _this = this;

      var recipient = this.get('recipient');
      this.get('store').createRecord('validation-log', {
        validation: validation,
        author: currentUser,
        recipient: recipient,
        message: this.get('reason')
      }).save().then(function (logObject) {
        if (!_this.get('isDestroying') || !_this.get('isDestroyed')) {
          _this.set('openModal', false);
        }

        _this.sendAction('afterSave', validation);
      }).catch(console.error);
    },
    actions: {
      onHide: function onHide() {
        if (!this.get('isDestroying') || !this.get('isDestroyed')) {
          this.set('openModal', false);
        }

        this.sendAction('onHide');
      },
      rejectAndNotify: function rejectAndNotify() {
        var _this2 = this;

        var existingValidation = this.get('validation');
        var recipient = this.get('recipient');
        var currentUser = this.get('currentUser');

        if (existingValidation) {
          existingValidation.set('state', this.get('state'));
          existingValidation.save().then(function () {
            _this2._createAndSaveLog(existingValidation, currentUser);
          }).catch(console.error);
        } else {
          var newValidation = this.get('store').createRecord('validation', {
            validatedBy: currentUser,
            user: recipient,
            state: this.get('state'),
            week: moment(this.get('week'))
          });
          newValidation.save().then(function (validation) {
            _this2._createAndSaveLog(validation, currentUser);
          }).catch(console.error);
        }
      }
    }
  });

  _exports.default = _default;
});