define("coffeecup/components/input-group", ["exports", "coffeecup/components/input-field"], function (_exports, _inputField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _inputField.default.extend({
    /**
     * the first addon
     * visible, when provided
     */
    firstAddon: null,

    /**
     * the last addon
     * visible, when provided
     */
    lastAddon: null
  });

  _exports.default = _default;
});