define("coffeecup/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "coffeecup/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: _environment.default.apiHost + '/oauth2/token',
    session: Ember.inject.service(),

    /**
     * Keeps a reference to pending token refresh promises
     */
    _pendingRefreshPromise: null,
    makeRequest: function makeRequest(url, data) {
      // Used when root admin is logging in, indicates which user they want to be logged in as.
      var targetUserData = {
        client_id: 'ember',
        client_secret: 'public',
        password: '-',
        grant_type: 'password'
      }; // If username is prefixed with root then we assume this is a root admin log in

      if (data.username && data.username.indexOf('root..') === 0) {
        targetUserData.username = data.username.split('..')[1];
        data.client_id = 'admin';
        data.client_secret = data.password;
        data.grant_type = 'client_credentials'; // Don't send password as we're authenticating with client secret

        delete data.username;
        delete data.password;
      } else {
        // Standard log in
        data.client_id = 'ember';
        data.client_secret = 'public';
      } // a jQuery deffered is expected as return type


      var deferred = Ember.$.Deferred();

      var request = this._super(url, data);

      request.then(function (response) {
        // If a username has been set, then we are root and now want to log in as a given user
        if (targetUserData.username) {
          // Root admin bearer token allows us to log in as given target user
          var targetUserPromise = Ember.$.ajax({
            url: url + '/ignorePassword',
            type: 'POST',
            data: targetUserData,
            dataType: 'json',
            contentType: 'application/x-www-form-urlencoded',
            headers: {
              Authorization: "Bearer ".concat(response.access_token)
            }
          });
          targetUserPromise.then(deferred.resolve);
          targetUserPromise.fail(deferred.reject);
        } else {
          // Normal log in, simply resolve
          deferred.resolve(response);
        }
      }).catch(deferred.reject);
      return deferred.promise();
    },

    /**
     * This method is called when simple-auth thinks it is time to refresh the token.
     * We override it to store a reference to the returned Promise.
     */
    _refreshAccessToken: function _refreshAccessToken() {
      var _this = this;

      var refreshPromise = this.get('_unresolvedRefreshPromise');

      if (refreshPromise) {
        refreshPromise.reject('New refresh was started');
        this.set('_unresolvedRefreshPromise', null);
      }

      var unsetFieldIfEqual = function unsetFieldIfEqual(resolvedPromise) {
        var fieldValue = _this.get('_pendingRefreshPromise');

        if (fieldValue === resolvedPromise) {
          _this.set('_pendingRefreshPromise', null);
        }
      };

      refreshPromise = this._super.apply(this, arguments).then(function (res) {
        unsetFieldIfEqual(refreshPromise);
        return res;
      }, function (err) {
        unsetFieldIfEqual(refreshPromise);
        throw err || 'request failed';
      });
      this.set('_pendingRefreshPromise', refreshPromise);
      return refreshPromise;
    },
    init: function init() {
      this._super.apply(this, arguments);

      window.addEventListener('online', Ember.run.bind(this, this.updateTokenAfterNewConnection));
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      window.removeEventListener('online', Ember.run.bind(this, this.updateTokenAfterNewConnection));
    },
    updateTokenAfterNewConnection: function updateTokenAfterNewConnection() {
      var session = this.get('session');
      var sessionData = session.get('data.authenticated');

      if (navigator && navigator.onLine && session && sessionData) {
        this._scheduleAccessTokenRefresh(sessionData['expires_in'], new Date().getTime() + 1200, sessionData['refresh_token']);
      }
    },
    // we need to overwrite _scheduleAccessTokenRefresh because ember simple auth has a bug with fastboot
    _scheduleAccessTokenRefresh: function _scheduleAccessTokenRefresh(expiresIn, expiresAt, refreshToken) {
      var refreshAccessTokens = this.get('refreshAccessTokens');

      if (refreshAccessTokens) {
        var now = new Date().getTime();

        if (Ember.isEmpty(expiresAt) && !Ember.isEmpty(expiresIn)) {
          expiresAt = new Date(now + expiresIn * 1000).getTime();
        }

        var offset = this.get('tokenRefreshOffset');

        if (!Ember.isEmpty(refreshToken) && !Ember.isEmpty(expiresAt) && expiresAt > now - (offset || 0)) {
          Ember.run.cancel(this._refreshTokenTimeout);
          delete this._refreshTokenTimeout;

          if (!Ember.testing) {
            this._refreshTokenTimeout = Ember.run.later(this, this._refreshAccessToken, expiresIn, refreshToken, expiresAt - now - offset);
          }
        }
      }
    },

    /**
     * This method returns a promise that resolves when the authenticator is considered idle.
     * Currently this only considers whether there is a pending token refresh and resolves regardless
     * of refresh success.
     */
    awaitIdle: function awaitIdle() {
      var _this2 = this;

      var refreshPromise = this.get('_pendingRefreshPromise');

      if (!refreshPromise) {
        return Ember.RSVP.resolve();
      }

      return refreshPromise.then( // the logic in ember simple auth after the promise resolves is scheduled on the runloop
      // we have to wait for the 'sessionDataUpdated' before access token are actually available
      function () {
        return new Promise(function (resolve) {
          return _this2.one('sessionDataUpdated', resolve);
        });
      }, function () {
        return null;
      });
    }
  });

  _exports.default = _default;
});