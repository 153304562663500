define("coffeecup/components/planner/user-project", ["exports", "coffeecup/components/deferred-row", "coffeecup/utils/array", "coffeecup/utils/list-tooltip-builder"], function (_exports, _deferredRow, _array, _listTooltipBuilder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _deferredRow.default.extend({
    viewport: Ember.inject.service('planner.viewport'),
    permissions: Ember.inject.service(),
    workingDays: Ember.inject.service(),
    formatter: Ember.inject.service(),
    i18n: Ember.inject.service(),
    classNameBindings: [':content-child-row'],
    currentUserService: Ember.inject.service('currentUser'),
    project: null,
    user: null,
    projectUserAssignments: null,
    currentUserCanWrite: null,
    isCurrentUser: Ember.computed('currentUserService.currentUser', 'user', function () {
      return this.get('currentUserService.currentUser.id') === this.get('user.id');
    }),
    currentUserIsPmOrAdmin: Ember.computed('currentUserService.currentUser', 'isCurrentUser', 'currentUserService.isAdmin', 'project', 'permissions.canManageAllProjects', 'permissions.canManageOwnPlanner', 'project.userAssignments.@each.isProjectManager', {
      get: function get() {
        if (this.get('currentUserService.isAdmin')) return true;
        if (this.get('permissions.canManageAllProjects')) return true;
        if (this.get('permissions.canManageOwnPlanner') && this.get('isCurrentUser')) return true;
        var userAssignment = this.get('project.userAssignments').findBy('user.id', this.get('currentUserService.currentUser.id'));
        return userAssignment && userAssignment.get('isProjectManager');
      }
    }),
    canEditThisUser: Ember.computed('currentUserCanWrite', 'permissions.canManageOwnPlanner', 'isCurrentUser', function () {
      return this.get('currentUserCanWrite') || this.get('permissions.canManageOwnPlanner') && this.get('isCurrentUser');
    }),
    editable: Ember.computed.and('canEditThisUser', 'currentUserIsPmOrAdmin'),
    projectUserAssignmentsObserver: Ember.observer('project.plannerAssignments.[]', 'user.plannerAssignments.[]', 'user.plannerAssignments.@each.totalAllocation', 'user.plannerAssignments.@each.startDate', function () {
      Ember.run.later(this, 'processProjectUserAssignments');
    }).on('init'),
    processProjectUserAssignments: function processProjectUserAssignments() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      var projectPlannerAssignments = this.get('project.plannerAssignments');
      var userPlannerAssignments = this.get('user.plannerAssignments');

      if (projectPlannerAssignments && userPlannerAssignments) {
        var projectUserAssignments = (0, _array.intersect)(projectPlannerAssignments, userPlannerAssignments);
        this.set('projectUserAssignments', projectUserAssignments);
      }
    },
    planningStartDate: Ember.computed('startDate', function () {
      return this.get('startDate') || moment().add(1, 'days');
    }),
    planningEndDate: Ember.computed('endDate', 'viewport.endDate', function () {
      return this.get('endDate') || this.get('viewport.endDate').clone().subtract(1, 'weeks');
    }),
    formattedStartDate: Ember.computed('planningStartDate', function () {
      return this.get('formatter').formatDate(this.get('planningStartDate'));
    }),
    formattedEndDate: Ember.computed('planningEndDate', function () {
      return this.get('formatter').formatDate(this.get('planningEndDate'));
    }),
    userHoursPlanned: Ember.computed('plannedHoursByProject', function () {
      var _this = this;

      var foundProjectHours = this.get('plannedHoursByProject').find(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
            project = _ref2[0];

        return project.idAsInt == _this.get('project.idAsInt');
      });
      return foundProjectHours ? foundProjectHours[1] : 0;
    }),
    userRoundedHoursPlanned: Ember.computed('userHoursPlanned', {
      get: function get() {
        return Math.round(this.get('userHoursPlanned') * 2) / 2;
      }
    }),
    projectHoursPlanned: Ember.computed('projectUserAssignments.[]', 'planningStartDate', 'planningEndDate', function () {
      var planningStartDate = this.get('planningStartDate');
      var planningEndDate = this.get('planningEndDate');
      return (this.get('projectUserAssignments') || []).filter(function (assignment) {
        return assignment.inRange(planningStartDate, planningEndDate);
      }).reduce(function (hours, assignment) {
        return hours + assignment.allocationForPeriod(planningStartDate, planningEndDate);
      }, 0);
    }),
    projectHoursPlannedForScope: Ember.computed('projectUserAssignments.[]', 'viewport.scopeStartDate', 'viewport.scopeEndDate', function () {
      var planningStartDate = this.get('viewport.scopeStartDate');
      var planningEndDate = this.get('viewport.scopeEndDate');
      return (this.get('projectUserAssignments') || []).filter(function (assignment) {
        return assignment.inRange(planningStartDate, planningEndDate);
      }).reduce(function (hours, assignment) {
        return hours + assignment.allocationForPeriod(planningStartDate, planningEndDate);
      }, 0);
    }),
    projectRoundedHoursPlanned: Ember.computed('projectHoursPlanned', {
      get: function get() {
        return Math.round(this.get('projectHoursPlanned') * 10) / 10;
      }
    }),
    userSummaryTooltip: Ember.computed('plannedHoursByProject', function () {
      var dateLabelFormat = 'DD. MMM';
      var listItems = this.get('plannedHoursByProject').map(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
            project = _ref4[0],
            hours = _ref4[1];

        return {
          content: "".concat(hours, " h"),
          subtitle: project.get('label')
        };
      });
      return (0, _listTooltipBuilder.default)({
        header: "".concat(this.get('user.firstname')).concat(this.get('i18n').t('usersPlanningFrom'), " ").concat(this.get('startDate').format(dateLabelFormat), " - ").concat(this.get('endDate').format(dateLabelFormat)),
        listItems: listItems
      });
    }),
    actions: {
      createAssignment: function createAssignment(user, project, startDate, endDate, allocation) {
        this.sendAction('createAssignment', user, project, startDate, endDate, allocation);
      },
      editAssignment: function editAssignment(plannerAssignment) {
        this.sendAction('editAssignment', plannerAssignment);
      },
      splitAssignment: function splitAssignment(plannerAssignment) {
        this.sendAction('splitAssignment', plannerAssignment);
      },
      afterUpdateAssignment: function afterUpdateAssignment(plannerAssignment) {
        this.sendAction('afterUpdateAssignment', plannerAssignment);
      },
      customAction: function customAction(actionName, data) {
        this.sendAction('customAction', actionName, data);
      },
      showTimeEntries: function showTimeEntries() {
        Array.prototype.push.call(arguments, {
          user: this.get('user.id')
        });
        this.sendAction.apply(this, ['showTimeEntries'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });

  _exports.default = _default;
});