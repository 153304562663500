define("coffeecup/components/date-last-x-days-picker", ["exports", "coffeecup/mixins/empty-action"], function (_exports, _emptyAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emptyAction.default, {
    size: 'large',
    isSizeLarge: Ember.computed.equal('size', 'large'),
    isSizeSmall: Ember.computed.equal('size', 'small'),
    availableNumberOfDays: [0],
    selectedNumberOfDays: null,
    selectedIndex: Ember.computed('availableNumberOfDays.[]', 'selectedNumberOfDays', {
      get: function get() {
        var availableNumberOfDays = this.get('availableNumberOfDays');
        var selectedNumberOfDays = this.get('selectedNumberOfDays');
        return availableNumberOfDays.indexOf(selectedNumberOfDays);
      },
      set: function set(key, index) {
        var availableNumberOfDays = this.get('availableNumberOfDays');
        var anodLen = availableNumberOfDays.length;
        var selectedNum;

        if (anodLen > 0) {
          // "real" modulo operation, i.e. -1 -> anodLen-1
          index = (index % anodLen + anodLen) % anodLen;
          selectedNum = availableNumberOfDays[index];
        } else {
          index = -1;
          selectedNum = null;
        }

        this.set('selectedNumberOfDays', selectedNum);
        return index;
      }
    }),
    items: Ember.computed('availableNumberOfDays.[]', 'selectedNumberOfDays', function () {
      var availableNumberOfDays = this.get('availableNumberOfDays');
      var selectedNumberOfDays = this.get('selectedNumberOfDays');
      return availableNumberOfDays.map(function (days) {
        return {
          days: days,
          isActive: days === selectedNumberOfDays
        };
      });
    }),
    actions: {
      pickedNumberOfDays: function pickedNumberOfDays(numberOfDays) {
        this.set('selectedNumberOfDays', numberOfDays);
        this.$('button.close-btn').trigger('click');
      },
      close: function close() {
        return true;
      },
      prevNumberOfDays: function prevNumberOfDays() {
        this.decrementProperty('selectedIndex');
      },
      nextNumberOfDays: function nextNumberOfDays() {
        this.incrementProperty('selectedIndex');
      }
    }
  });

  _exports.default = _default;
});