define("coffeecup/components/date-year-picker", ["exports", "coffeecup/config/environment", "moment", "coffeecup/mixins/empty-action"], function (_exports, _environment, _moment, _emptyAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emptyAction.default, {
    currentYearLabel: Ember.computed(function () {
      return this.get('i18n').t('thisYear');
    }),
    activeYearText: Ember.computed('year', {
      get: function get() {
        var yearObj = (0, _moment.default)(this.get('year'), _environment.default.constants.DATE_YEAR_PICKER_FORMAT);
        return yearObj.format(_environment.default.constants.DATE_YEAR_PICKER_FORMAT);
      }
    }),
    activeYearTextShort: Ember.computed.alias('activeYearText'),
    currentYear: Ember.computed({
      get: function get() {
        return this.get('clock.year').format(_environment.default.constants.DATE_YEAR_PICKER_FORMAT);
      }
    }),
    size: 'large',
    isSizeLarge: Ember.computed.equal('size', 'large'),
    isSizeSmall: Ember.computed.equal('size', 'small'),
    customElementActive: false,
    isItemActive: Ember.computed('customElementActive', function () {
      return this.get('customElementActive') ? '' : 'active';
    }),
    isCurrentYear: Ember.computed('month', {
      get: function get() {
        return this.get('currentYear') === this.get('year');
      }
    }),
    year: null,
    years: Ember.computed('year', {
      get: function get() {
        var year = parseInt(this.get('year'), 10);
        var yearsOnPage = [];
        var minYear = year - 3;
        var maxYear = year + 3;

        if (this.get('customElement')) {
          minYear = year - 2;
          maxYear = year + 2;
        }

        for (var i = minYear; i <= maxYear; i++) {
          yearsOnPage.push({
            year: i,
            isActive: year === i ? 'selected' : ''
          });
        }

        return yearsOnPage;
      }
    }),
    actions: {
      customElementAction: function customElementAction(value) {
        this.set('customElementActive', value);
        this.sendAction('customElementAction', value);
      },
      currentYear: function currentYear() {
        this.send('pickedYear', this.get('currentYear'));
      },
      pickedYear: function pickedYear(year) {
        this.send('customElementAction', false);
        this.set('year', year);
        this.$('button.close-btn').trigger('click');
      },
      close: function close() {
        return true;
      },
      prevYear: function prevYear() {
        this.send('customElementAction', false);
        this.decrementProperty('year');
      },
      nextYear: function nextYear() {
        this.send('customElementAction', false);
        this.incrementProperty('year');
      },
      showToday: function showToday() {
        this.send('customElementAction', false);
        this.sendAction('showToday');
      }
    }
  });

  _exports.default = _default;
});