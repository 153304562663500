define("coffeecup/components/validation/validation-button", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['flex-col-4', 'd-flex', 'validation-row-action-wrapper'],
    classNameBindings: ['isActive:active', 'isRemoved:removed', 'isPlaceholder:placeholder'],
    isActive: false,
    isRemoved: false,
    buttonDisabled: false,
    validationState: false,
    actions: {
      onClick: function onClick(e) {
        e.preventDefault();
        if (this.get('isPlaceholder')) return;

        if (this.get('validationState') === _environment.default.constants.VALIDATION_REJECTED) {
          this.set('openModal', true);
        } else {
          this.send('triggerAnimation');
        }
      },
      triggerAnimation: function triggerAnimation(alreadyCreatedValidation) {
        var _this = this;

        if (!this.get('isDestroyed') && !this.get('isDestroying') && (this.get('validationState') === _environment.default.constants.VALIDATION_REJECTED || this.get('validationState') === _environment.default.constants.VALIDATION_APPROVED)) {
          this.set('isActive', true);
        }

        this.sendAction('onClick', this.get('validationState'), alreadyCreatedValidation);
        Ember.run.later(this, function () {
          if (!_this.get('isDestroyed')) {
            _this.set('isActive', false);
          }
        }, 500);
      }
    }
  });

  _exports.default = _default;
});