define("coffeecup/components/custom-icon-configurator/icon-picker", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['icon-wrapper'],
    classNameBindings: ['isIconSelected:active'],
    icon: null,
    index: null,
    selectedIconIndex: null,
    selectedColorIndex: null,
    possibleColors: _environment.default.constants.DEFAULT_COLORS,
    selectedColor: Ember.computed('selectedColorIndex', function () {
      return Ember.String.htmlSafe(this.get('possibleColors')[this.get('selectedColorIndex') - 1]);
    }),
    isIconSelected: Ember.computed('selectedIconIndex', 'index', function () {
      return this.get('index') === this.get('selectedIconIndex') - 1;
    }),
    click: function click() {
      this.sendAction('selectIcon');
    }
  });

  _exports.default = _default;
});