define("coffeecup/components/modals/prompt-billing-update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    subscription: null,
    currentUser: null,
    isOver: Ember.computed('subscription.isOver', function () {
      var isOver = this.get('subscription.isOver');
      return isOver === true || isOver === undefined;
    }),
    modalHeader: Ember.computed('currentUser', 'subscription', function () {
      var i18n = this.get('i18n');

      if (this.get('currentUser.isAdmin')) {
        if (this.get('isOver')) {
          return i18n.t('trialEndedHeaderAdmin');
        } else {
          return i18n.t('trialEndsSoon');
        }
      } else {
        return i18n.t('trialEndedHeaderUser');
      }
    }),
    modalDescription: Ember.computed('currentUser', 'subscription', function () {
      var i18n = this.get('i18n');

      if (this.get('currentUser.isAdmin')) {
        if (this.get('isOver')) {
          return i18n.t('trialEndedDescriptionAdmin');
        } else {
          return i18n.t('manageSubscriptionDescription', {
            remainingDays: this.get('subscription.remainingTrialDays') || 0
          });
        }
      } else {
        return i18n.t('trialEndedDescriptionUser');
      }
    }),
    buttonText: Ember.computed('currentUser', function () {
      var i18n = this.get('i18n');

      if (this.get('currentUser.isAdmin')) {
        return i18n.t('upgradeNow');
      } else {
        return i18n.t('OK');
      }
    }),
    actions: {
      goToBilling: function goToBilling() {
        if (this.get('currentUser.isAdmin')) this.get('router').transitionTo('account.billing');
        this.send('onHide');
      },
      onHide: function onHide() {
        this.set('openModal', false);
      }
    }
  });

  _exports.default = _default;
});