define("coffeecup/components/pie-timer", ["exports", "ember-cli-tooltipster/components/tool-tipster"], function (_exports, _toolTipster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _toolTipster.default.extend({
    tagName: 'span',

    /**
     * the static classnames
     */
    classNames: ['pie-timer'],

    /**
     * binded classnames
     */
    classNameBindings: ['isHidden:hidden'],

    /**
     * the run mode of the pie
     * possible values: busy (default), progress
     */
    mode: 'progress',

    /**
     * returns true, if the current mode is 'busy'
     */
    isModeBusy: Ember.computed('mode', {
      get: function get() {
        return this.get('mode') == 'busy';
      }
    }),

    /**
     * returns true, if the current mode is 'progress'
     */
    isModeProgress: Ember.computed('mode', {
      get: function get() {
        return this.get('mode') == 'progress';
      }
    }),

    /**
     * the percentage of time completed
     * value ranges from 0.0 to 1.0
     */
    percentCompleted: null,
    dashSize: Ember.computed('percentCompleted', function () {
      var FULL_SIZE = 94; // the base SVG's radius is 15px -> pi * r * 2 = 94.2

      return FULL_SIZE * (this.get('percentCompleted') || 0);
    }),

    /**
     * generate tooltip
     */
    content: Ember.computed('percentCompleted', 'mode', 'tt', function () {
      var tt = this.get('tt');
      var i18n = this.get('i18n');
      var mode = this.get('mode');

      if (tt) {
        return tt;
      }

      switch (mode) {
        case 'busy':
          tt = i18n.t('busy');
          break;

        case 'progress':
          tt = "".concat(this.get('value'), " ").concat(i18n.t('of'), " ").concat(this.get('expectedValue'));
          break;
      }

      return tt;
    })
  });

  _exports.default = _default;
});