define("coffeecup/components/menu-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    permissions: Ember.inject.service(),
    router: Ember.inject.service(),
    intercom: Ember.inject.service('intercom-analytics'),
    easybill: Ember.inject.service(),
    sevdesk: Ember.inject.service(),
    label: null,
    numberOfOpenAbsenceRequests: 0,
    numberOfOpenValidations: 0,
    hideOnSmall: false,
    isPulsing: false,
    currentUser: null,
    isAlertBadge: Ember.computed('hasBillingStaleApiKeyBadge', function () {
      return this.get('hasBillingStaleApiKeyBadge');
    }),
    hasBadge: Ember.computed('hasPlannerBadge', 'hasValidationBadge', 'hasIntercomBadge', 'hasBillingStaleApiKeyBadge', function () {
      return this.get('hasPlannerBadge') || this.get('hasValidationBadge') || this.get('hasOnboardingBadge') || this.get('hasIntercomBadge') || this.get('hasBillingStaleApiKeyBadge');
    }),
    hasBillingStaleApiKeyBadge: Ember.computed('label', 'easybill.connectionHasStaleApiKey', 'sevdesk.connectionHasStaleApiKey', 'permissions.canAccessBillingServices', function () {
      return (this.get('label') === 'account' || this.get('label') === 'account.integrations') && (this.get('easybill.connectionHasStaleApiKey') || this.get('sevdesk.connectionHasStaleApiKey')) && this.get('permissions.canAccessBillingServices');
    }),
    hasIntercomBadge: Ember.computed('label', 'intercom.unreadCount', function () {
      return this.get('label') === 'intercom' && this.get('intercom.unreadCount') > 0;
    }),
    hasPlannerBadge: Ember.computed('label', 'numberOfOpenAbsenceRequests', 'router.currentRouteName', 'permissions.canManageAbsenceRequests', function () {
      var currentRouteName = this.get('router.currentRouteName');
      return this.get('permissions.canManageAbsenceRequests') && (this.get('label') === 'planner' || this.get('label') === 'planner.absences' || this.get('label') === 'planner.absences' && currentRouteName.indexOf('planner.') !== -1) && this.get('numberOfOpenAbsenceRequests') > 0;
    }),
    hasValidationBadge: Ember.computed('label', 'numberOfOpenValidations', 'router.currentRouteName', 'permissions.worktimeValidationActive', 'permissions.canValidateWorkTime', function () {
      var currentRouteName = this.get('router.currentRouteName');
      return (this.get('permissions.worktimeValidationActive') && this.get('permissions.canValidateWorkTime') || this.get('permissions.canViewTeamTimeEntries')) && (this.get('label') === 'time-entries' || this.get('label') === 'time-entries.verifying') && this.get('numberOfOpenValidations');
    })
  });

  _exports.default = _default;
});