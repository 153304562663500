define("coffeecup/components/analytics-module/project/hour-budget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    project: null,
    analytics: null,
    pieChartData: [],
    hoursBudget: Ember.computed.reads('analytics.hoursBudget'),
    hoursSpent: Ember.computed.reads('analytics.hoursTotal'),
    hoursRemaining: Ember.computed.reads('analytics.hoursRemaining'),
    isOverHoursBudget: Ember.computed.reads('analytics.isOverHoursBudget'),
    hoursRemainingInPercent: Ember.computed.reads('analytics.hoursRemainingInPercent'),
    didInsertElement: function didInsertElement() {
      this._updateUi();
    },
    _updateUi: Ember.observer('hoursRemaining', 'hoursRemainingInPercent', function () {
      var hoursRemaining = this.get('hoursRemaining');
      var hoursRemainingInPercent = this.get('hoursRemainingInPercent');
      this.set('pieChartData', [{
        value: hoursRemaining > 0 ? 1 - hoursRemainingInPercent : Math.abs(hoursRemainingInPercent)
      }, {
        value: hoursRemaining > 0 ? hoursRemainingInPercent : 1 - Math.abs(hoursRemainingInPercent)
      }]);
    })
  });

  _exports.default = _default;
});