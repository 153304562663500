define("coffeecup/components/manage/projects/expenses/expense-list/row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    expense: null,
    actions: {
      downloadAttachment: function downloadAttachment() {
        if (this.get('expense.attachment.id')) this.sendAction('downloadAttachment', this.get('expense.attachment.id'));
      },
      showExpenseModal: function showExpenseModal() {
        this.sendAction('showExpenseModal', this.get('expense'));
      },
      deleteElement: function deleteElement() {
        this.sendAction('deleteElement', this.get('expense'));
      },
      markExpenseAsUnbilled: function markExpenseAsUnbilled() {
        this.sendAction('markExpenseAsUnbilled', this.get('expense'));
      },
      markAsBilled: function markAsBilled() {
        this.sendAction('markAsBilled', this.get('expense'));
      }
    }
  });

  _exports.default = _default;
});