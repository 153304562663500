define("coffeecup/components/x-carousel", ["exports", "coffeecup/components/x-tabs"], function (_exports, _xTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var CarouselComponent = _xTabs.default.extend({
    classNames: ['x-carousel'],
    // Hide controls if there's only one pane
    showControls: Ember.computed('panes.[]', {
      get: function get() {
        return this.get('panes').length > 1;
      }
    }),
    next: function next() {
      var index = this.get('selected');
      index++;
      var panes = this.get('panes');

      if (index > panes.length - 1) {
        index = 0;
      }

      this.select(panes.objectAt(index));
    },
    previous: function previous() {
      var index = this.get('selected');
      var panes = this.get('panes');
      index--;

      if (index < 0) {
        index = panes.length - 1;
      }

      this.select(panes.objectAt(index));
    },
    actions: {
      next: function next() {
        this.next();
      },
      previous: function previous() {
        this.previous();
      }
    }
  });

  var _default = CarouselComponent;
  _exports.default = _default;
});