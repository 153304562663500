define("coffeecup/controllers/time-entries/verifying/list", ["exports", "coffeecup/config/environment", "coffeecup/utils/build-url"], function (_exports, _environment, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberController$ext;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend((_EmberController$ext = {
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    validationService: Ember.inject.service('validation'),
    worktimeController: Ember.inject.controller('time-entries.verifying'),
    adminFilter: Ember.computed.alias('worktimeController.adminFilter'),
    hasWeeks: true,
    openWeeks: null,
    isLoading: false,
    pageIsLoading: false,
    noValidationActive: false,
    accountService: Ember.inject.service('account'),
    accountSettings: Ember.computed.reads('accountService.accountSettings'),
    hasAnyValidationActive: Ember.computed.or('accountSettings.activeCommentValidation', 'accountSettings.activeBreakValidation', 'accountSettings.activeHoursValidation'),
    routeIsActive: Ember.computed('router.currentRouteName', function () {
      return this.get('router').isActive('time-entries.verifying.list');
    })
  }, _defineProperty(_EmberController$ext, "validationService", Ember.inject.service('validation')), _defineProperty(_EmberController$ext, "actions", {
    updateList: function updateList(userData, week) {
      var _this = this;

      if (userData.validation && userData.validation.get('isClosed')) {
        if (this.get('weeks')[week] && this.get('weeks')[week].userData) {
          this.get('weeks')[week].userData.removeObject(userData);

          if (this.get('weeks')[week].userData.length === 0) {
            delete this.get('weeks')[week];

            if (this.get('weeks') && Object.keys(this.get('weeks')).length === 0) {
              this.set('weeks', null);
            }
          }
        }
      }

      var options = {};
      if (this.get('adminFilter') === 'assignedTimeSheets') options.restrictOnAssignedUsers = true;
      this.get('session').getJSON((0, _buildUrl.default)(this.store, 'validate/invalid', options)).then(function (openValidationWeeks) {
        _this.get('validationService').updateOpenValidationCount(openValidationWeeks);
      }).catch(console.error);
    },
    loadPreviousWeek: function loadPreviousWeek() {
      var _this2 = this;

      var openWeeks = this.get('openWeeks');

      if (!openWeeks) {
        this.set('isLoading', false);
        return;
      }

      var weekKeys = Object.keys(openWeeks.weeks);

      if (weekKeys.length > 0) {
        var nextWeekToLoad = openWeeks.weeks[weekKeys[0]];
        var startOfPreviousWeek = moment(weekKeys[0]);
        var startDate = startOfPreviousWeek.startOf('isoWeek').format(_environment.default.constants.DATE_FORMAT_DAY);
        var endDate = startOfPreviousWeek.endOf('isoWeek').format(_environment.default.constants.DATE_FORMAT_DAY);
        this.set('isLoading', true);
        this.get('validationService').loadRemainingValidations(weekKeys[0]).then(function () {
          _this2.get('session').getJSON((0, _buildUrl.default)(_this2.store, 'validate/checkUserWorktime', {
            startDate: startDate,
            endDate: endDate,
            users: JSON.stringify(nextWeekToLoad.openUsers)
          })).then(function (rawUserData) {
            var weekData = {
              startDate: startDate,
              endDate: endDate
            };
            var userData = rawUserData.userData.map(function (user) {
              user.user = _this2.get('store').peekRecord('user', user.id);
              user.validation = user.isAlreadyValidated ? _this2.store.peekRecord('validation', user.isAlreadyValidated) : null;
              user.holidays = _this2.store.peekAll('user-holiday').filter(function (el) {
                return el.get('date').isSameOrAfter(startDate, 'day') && el.get('date').isSameOrBefore(endDate, 'day');
              });
              return user;
            }).filter(function (us) {
              if (_this2.get('team')) {
                return us.user.get('team.id') === _this2.get('team');
              }

              return true;
            });
            weekData.userData = userData;
            _this2.get('weeks')[startDate] = weekData;

            _this2.set('isLoading', false);

            _this2.notifyPropertyChange('weeks');

            delete openWeeks.weeks[weekKeys[0]];
          }).catch(console.error);
        });
      } else {
        this.set('isLoading', false);
        return;
      }
    }
  }), _EmberController$ext));

  _exports.default = _default;
});