define("coffeecup/components/validation/value-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['flex-1', 'align-items-center', 'd-flex', 'validation-value-check'],
    label: null
  });

  _exports.default = _default;
});