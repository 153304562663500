define("coffeecup/components/sortable-time-entry-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SORTABLE_ATTR = void 0;
  var SORTABLE_ATTR = 'data-sortable-id';
  _exports.SORTABLE_ATTR = SORTABLE_ATTR;
  var SortableTimeEntryList = Ember.Component.extend({
    responsive: Ember.inject.service(),
    items: [],
    enabled: true,
    connectLists: true,
    timeEntryDay: null,
    columnSelector: '.time-entry-col',
    itemSelector: '> div:not(.new-entry-wrapper)',
    // Excludes .new-entry-wrapper
    handleSelector: 'section.top:not(.no-draggable)',
    draggingClass: 'time-entry-drag',
    placeholderContainer: null,
    placeholder: 'placeholder-container',
    onEnabledChanged: Ember.observer('enabled', function () {
      this._checkSortableState();
    }),
    addItem: function addItem(item) {
      this.get('items').pushObject(item);
    },
    removeItem: function removeItem(item) {
      this.get('items').removeObject(item);
    },
    sortable: function sortable() {
      return this.$(this.get('columnSelector'));
    },
    sortableInstance: function sortableInstance() {
      var sortable = this.sortable();
      if (!sortable) return null;
      return sortable.sortable('instance');
    },
    _checkSortableState: function _checkSortableState() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        if (!this.sortableInstance()) {
          return;
        }

        var enabled = this.get('enabled');

        if (!enabled) {
          this.sortable().sortable('disable');
        } else {
          this.sortable().sortable('enable');
        }
      });
    },
    didInsertElement: function didInsertElement() {
      this._super();

      var self = this;
      var draggingClass = this.get('draggingClass');

      if (this.get('responsive').isMobile()) {
        // Do not enable list sorting on mobile
        return;
      }

      Ember.run.scheduleOnce('afterRender', this, function () {
        // some jQuery UI stuff
        var sortableConfig = {
          items: this.get('itemSelector'),
          handle: this.get('handleSelector'),
          placeholder: this.get('placeholder'),
          opacity: '.6',
          // Add delay/distance to prevent unintended sorting
          delay: 100,
          distance: 50,
          // element has been dragged onto the list
          over: function over(event, ui) {
            var _this = this;

            Ember.run(function () {
              Ember.$(_this).addClass('selected');
              var entryBG = ui.item.children().attr('data-bg-color');

              if (entryBG) {
                ui.placeholder.css('background-color', entryBG);
                ui.placeholder.prependTo(ui.placeholder.parent());
              }
            });
          },
          // element has been dragged out of the list
          out: function out(event, ui) {
            var _this2 = this;

            Ember.run(function () {
              return Ember.$(_this2).removeClass('selected');
            });
          },
          // start dragging / sorting
          start: function start(event, ui) {
            if (self.get('placeholderContainer')) {
              ui.placeholder.width(ui.item.find(self.get('placeholderContainer')).outerWidth() - 4);
              ui.placeholder.height(ui.item.find(self.get('placeholderContainer')).outerHeight() - 4);
            }

            ui.placeholder.height(ui.item.height());
            Ember.run(function () {
              ui.item.children().addClass(draggingClass);
              var itemSorting = self.get('items').filterBy('entryId', ui.item.attr(SORTABLE_ATTR)).objectAt(0);

              if (itemSorting) {
                itemSorting.set('isSorting', true);
              }
            });
            Ember.run(function () {
              self.dragStart(ui);
            });
          },
          // end sorting
          stop: function stop(event, ui) {
            Ember.run(function () {
              ui.item.children().removeClass(draggingClass);
              var itemSorting = self.get('items').filterBy('entryId', ui.item.attr(SORTABLE_ATTR)).objectAt(0);

              if (itemSorting) {
                itemSorting.set('isSorting', false);
              }
            }); // call the action if the element was dragged within the list, otherwise receive will be called

            if (this == ui.item.parent()[0]) {
              Ember.run(function () {
                var opts = {
                  withinList: true
                };
                self.sortEnd(ui, opts);

                if (opts.cancelDrag) {
                  self.sortable().sortable('cancel');
                }
              });
            }
          },
          // the event when the object is dropped
          receive: function receive(event, ui) {
            Ember.run(function () {
              self.dragEnd(ui, {
                timeEntryDay: self.get('timeEntryDay'),
                timeEntryIds: ui.item.data('timeEntryIds'),
                withinList: false
              });
            });
          },
          // this event is called before the receive event.
          remove: function remove(event, ui) {
            // save new sorting to element
            Ember.run(function () {
              self.setTimeEntryIds(ui); // cancel the action since ember takes care of rerendering the days

              self.sortable().sortable('cancel');
            });
          },
          // connected sortable lists receive drag start event
          activate: function activate(event, ui) {}
        };

        if (this.get('connectLists')) {
          sortableConfig.connectWith = this.get('columnSelector');
        }

        this.$(this.get('columnSelector')).sortable(sortableConfig);

        this._checkSortableState();
      });
    },
    dragStart: function dragStart(ui) {
      this.sendAction('dragStartAction', ui.item.attr(SORTABLE_ATTR));
    },
    dragEnd: function dragEnd(ui, opts) {
      opts = opts || {};
      this.dragEndAction(opts, ui);
    },
    sortEnd: function sortEnd(ui, opts) {
      opts = opts || {};
      this.setTimeEntryIds(ui);
      Ember.$.extend(opts, {
        timeEntryDay: this.get('timeEntryDay'),
        timeEntryIds: ui.item.data('timeEntryIds')
      });
      this.dragEnd(ui, opts);
    },
    setTimeEntryIds: function setTimeEntryIds(ui) {
      var entryIds = this.getEntryIds(ui);
      var timeEntryIds = {
        timeEntryPrevious: entryIds.entryPrevious,
        timeEntryCurrent: entryIds.entryCurrent,
        timeEntryNext: entryIds.entryNext
      };
      ui.item.data('timeEntryIds', timeEntryIds);
    },
    getEntryIds: function getEntryIds(ui) {
      var dataAttribute = SORTABLE_ATTR;
      var idPrevious = ui.item.prev().attr(dataAttribute);
      var idNext = ui.item.next().attr(dataAttribute);
      var idCurrent = ui.item.attr(dataAttribute);
      return {
        entryPrevious: idPrevious,
        entryCurrent: idCurrent,
        entryNext: idNext
      };
    },
    willDestroyElement: function willDestroyElement() {
      // Has resizable been initialised?
      if (this.sortableInstance()) {
        this.sortable().sortable('destroy');
      }
    }
  });
  var _default = SortableTimeEntryList;
  _exports.default = _default;
});