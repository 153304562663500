define("coffeecup/demo-data/dashboard/team-hours-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.teamWorkload = _exports.nonBillableRatio = _exports.billableRatio = _exports.teamHoursData = void 0;
  var teamHoursData = [{
    month: 'Jan',
    monthTooltip: 'Januar 2020',
    billableWorkload: 46.45420321515464,
    tooltipBillableWorkload: '46.45%',
    nonBillableWorkload: 54.41058619008706,
    teamWorkload: 1.008647894052417,
    tooltipTeamWorkload: '100.86%',
    teamTooltip: "\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Lisa Feldmayer</div><div class=\"row-value\">67,64%</div><div class=\"row-value\">101,87%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Iris Kouvlakis</div><div class=\"row-value\">0,43%</div><div class=\"row-value\">101,18%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Johannes Stick</div><div class=\"row-value\">97,86%</div><div class=\"row-value\">100,48%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Natasha Ramirex</div><div class=\"row-value\">0,00%</div><div class=\"row-value\">100,42%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Robert Cunningham</div><div class=\"row-value\">90,88%</div><div class=\"row-value\">100,38%</div></div>",
    billableRatio: 0.4605591652852896,
    nonBillableRatio: 0.5394408347147104
  }, {
    month: 'Febr',
    monthTooltip: 'Februar 2020',
    billableWorkload: 63.812013486431226,
    tooltipBillableWorkload: '63.81%',
    nonBillableWorkload: 39.52040392543482,
    teamWorkload: 1.0333241741186605,
    tooltipTeamWorkload: '103.33%',
    teamTooltip: "\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Iris Kouvlakis</div><div class=\"row-value\">25,68%</div><div class=\"row-value\">107,15%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Lisa Feldmayer</div><div class=\"row-value\">83,13%</div><div class=\"row-value\">105,15%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Johannes Stick</div><div class=\"row-value\">98,51%</div><div class=\"row-value\">100,69%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Robert Cunningham</div><div class=\"row-value\">89,01%</div><div class=\"row-value\">100,34%</div></div>",
    billableRatio: 0.6175410881184268,
    nonBillableRatio: 0.3824589118815732
  }, {
    month: 'Mrz',
    monthTooltip: 'März 2020',
    billableWorkload: 63.37186484712113,
    tooltipBillableWorkload: '63.37%',
    nonBillableWorkload: 61.807744866082324,
    teamWorkload: 1.2517960971320345,
    tooltipTeamWorkload: '125.17%',
    teamTooltip: "\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Lisa Feldmayer</div><div class=\"row-value\">65,36%</div><div class=\"row-value\">192,95%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Iris Kouvlakis</div><div class=\"row-value\">0,12%</div><div class=\"row-value\">105,25%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Robert Cunningham</div><div class=\"row-value\">84,19%</div><div class=\"row-value\">101,49%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Johannes Stick</div><div class=\"row-value\">98,77%</div><div class=\"row-value\">101,03%</div></div>",
    billableRatio: 0.5062475030263408,
    nonBillableRatio: 0.4937524969736592
  }, {
    month: 'Apr',
    monthTooltip: 'April 2020',
    billableWorkload: 75.99458550804496,
    tooltipBillableWorkload: '75.99%',
    nonBillableWorkload: 26.220073378271895,
    teamWorkload: 1.0221465888631687,
    tooltipTeamWorkload: '102.21%',
    teamTooltip: "\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Clair\xE9 Assange</div><div class=\"row-value\">96,74%</div><div class=\"row-value\">105,17%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Robert Cunningham</div><div class=\"row-value\">81,48%</div><div class=\"row-value\">104,24%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Iris Kouvlakis</div><div class=\"row-value\">42,85%</div><div class=\"row-value\">101,81%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Johannes Stick</div><div class=\"row-value\">96,65%</div><div class=\"row-value\">101,34%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Joshua Smith</div><div class=\"row-value\">90,46%</div><div class=\"row-value\">100,77%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Niko Reishofer</div><div class=\"row-value\">67,80%</div><div class=\"row-value\">99,95%</div></div>",
    billableRatio: 0.743480302493267,
    nonBillableRatio: 0.256519697506733
  }, {
    month: 'Mai',
    monthTooltip: 'Mai 2020',
    billableWorkload: 77.99488783443825,
    tooltipBillableWorkload: '77.99%',
    nonBillableWorkload: 21.231356317534736,
    teamWorkload: 0.9922624415197298,
    tooltipTeamWorkload: '99.22%',
    teamTooltip: "\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Clair\xE9 Assange</div><div class=\"row-value\">102,29%</div><div class=\"row-value\">107,45%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Robert Cunningham</div><div class=\"row-value\">79,82%</div><div class=\"row-value\">106,37%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Joshua Smith</div><div class=\"row-value\">97,15%</div><div class=\"row-value\">101,31%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Niko Reishofer</div><div class=\"row-value\">92,70%</div><div class=\"row-value\">100,70%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Johannes Stick</div><div class=\"row-value\">96,32%</div><div class=\"row-value\">100,65%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Iris Kouvlakis</div><div class=\"row-value\">62,90%</div><div class=\"row-value\">99,98%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-labelkaki\">Natasha Ramirez</div><div class=\"row-value\">0,00%</div><div class=\"row-value\">78,13%</div></div>",
    billableRatio: 0.7860308379200849,
    nonBillableRatio: 0.21396916207991512
  }, {
    month: 'Jun',
    monthTooltip: 'Juni 2020',
    billableWorkload: 87.44122332916305,
    tooltipBillableWorkload: '87.44%',
    nonBillableWorkload: 14.945448151345236,
    teamWorkload: 1.0238667148050828,
    tooltipTeamWorkload: '102.38%',
    teamTooltip: "\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Clair\xE9 Assange</div><div class=\"row-value\">100,56%</div><div class=\"row-value\">106,20%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Niko Reishofer</div><div class=\"row-value\">98,70%</div><div class=\"row-value\">104,56%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Iris Kouvlakis</div><div class=\"row-value\">61,78%</div><div class=\"row-value\">101,67%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Johannes Stick</div><div class=\"row-value\">94,98%</div><div class=\"row-value\">101,17%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Joshua Smith</div><div class=\"row-value\">96,86%</div><div class=\"row-value\">100,73%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Robert Cunningham</div><div class=\"row-value\">75,00%</div><div class=\"row-value\">100,00%</div></div>",
    billableRatio: 0.8540293581651353,
    nonBillableRatio: 0.14597064183486474
  }, {
    month: 'Jul',
    monthTooltip: 'Juli 2020',
    billableWorkload: 70.5735686611692,
    tooltipBillableWorkload: '70.57%',
    nonBillableWorkload: 32.34419737430666,
    teamWorkload: 1.0291776603547587,
    tooltipTeamWorkload: '102.91%',
    teamTooltip: "\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Iris Kouvlakis</div><div class=\"row-value\">71,89%</div><div class=\"row-value\">107,21%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Johannes Stick</div><div class=\"row-value\">97,84%</div><div class=\"row-value\">103,67%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Joshua Smith</div><div class=\"row-value\">99,58%</div><div class=\"row-value\">102,84%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Robert Cunningham</div><div class=\"row-value\">86,83%</div><div class=\"row-value\">101,69%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Clair\xE9 Assange</div><div class=\"row-value\">63,97%</div><div class=\"row-value\">101,58%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Niko Reishofer</div><div class=\"row-value\">94,50%</div><div class=\"row-value\">100,51%</div></div>",
    billableRatio: 0.6857277550781895,
    nonBillableRatio: 0.3142722449218105
  }, {
    month: 'Aug',
    monthTooltip: 'August 2020',
    billableWorkload: 57.321271255482074,
    tooltipBillableWorkload: '57.32%',
    nonBillableWorkload: 46.44391506514753,
    teamWorkload: 1.037651863206296,
    tooltipTeamWorkload: '103.76%',
    teamTooltip: "\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Joshua Smith</div><div class=\"row-value\">75,18%</div><div class=\"row-value\">125,01%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Niko Reishofer</div><div class=\"row-value\">95,53%</div><div class=\"row-value\">102,72%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Clair\xE9 Assange</div><div class=\"row-value\">0,00%</div><div class=\"row-value\">101,84%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Johannes Stick</div><div class=\"row-value\">93,44%</div><div class=\"row-value\">100,95%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Iris Kouvlakis</div><div class=\"row-value\">19,86%</div><div class=\"row-value\">100,59%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Josef Ebel</div><div class=\"row-value\">92,33%</div><div class=\"row-value\">100,00%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Robert Cunningham</div><div class=\"row-value\">60,25%</div><div class=\"row-value\">95,25%</div></div>",
    billableRatio: 0.5524133217316453,
    nonBillableRatio: 0.44758667826835474
  }, {
    month: 'Sept',
    monthTooltip: 'September 2020',
    billableWorkload: 76.45574114405956,
    tooltipBillableWorkload: '76.45%',
    nonBillableWorkload: 27.156097740218254,
    teamWorkload: 1.036118388842778,
    tooltipTeamWorkload: '103.61%',
    teamTooltip: "\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Alexandra Huber</div><div class=\"row-value\">78,82%</div><div class=\"row-value\">106,20%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Michel Bierwirth</div><div class=\"row-value\">72,19%</div><div class=\"row-value\">105,15%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Niko Reishofer</div><div class=\"row-value\">98,50%</div><div class=\"row-value\">103,77%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Clair\xE9 Assange</div><div class=\"row-value\">74,73%</div><div class=\"row-value\">103,52%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Johannes Stick</div><div class=\"row-value\">95,12%</div><div class=\"row-value\">102,96%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Josef Ebel</div><div class=\"row-value\">94,96%</div><div class=\"row-value\">102,06%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Iris Kouvlakis</div><div class=\"row-value\">33,00%</div><div class=\"row-value\">101,63%</div></div>",
    billableRatio: 0.7379054552776695,
    nonBillableRatio: 0.2620945447223305
  }, {
    month: 'Okt',
    monthTooltip: 'Oktober 2020',
    billableWorkload: 66.65156890095258,
    tooltipBillableWorkload: '66.65%',
    nonBillableWorkload: 28.208745636618886,
    teamWorkload: 0.9486031453757148,
    tooltipTeamWorkload: '94.86%',
    teamTooltip: "\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Alexandra Huber</div><div class=\"row-value\">79,64%</div><div class=\"row-value\">108,91%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Michel Bierwirth</div><div class=\"row-value\">85,68%</div><div class=\"row-value\">104,72%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Josef Ebel</div><div class=\"row-value\">98,83%</div><div class=\"row-value\">103,51%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Iris Kouvlakis</div><div class=\"row-value\">18,24%</div><div class=\"row-value\">101,95%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Johannes Stick</div><div class=\"row-value\">92,04%</div><div class=\"row-value\">101,38%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Niko Reishofer</div><div class=\"row-value\">94,56%</div><div class=\"row-value\">100,52%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Clair\xE9 Assange</div><div class=\"row-value\">93,24%</div><div class=\"row-value\">100,21%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Nicole Ramirez</div><div class=\"row-value\">8,47%</div><div class=\"row-value\">24,29%</div></div>",
    billableRatio: 0.7026285884236004,
    nonBillableRatio: 0.29737141157639957
  }, {
    month: 'Nov',
    monthTooltip: 'November 2020',
    billableWorkload: 72.51238036348154,
    tooltipBillableWorkload: '72.51%',
    nonBillableWorkload: 27.72042948231347,
    teamWorkload: 1.0023280984579501,
    tooltipTeamWorkload: '100.23%',
    teamTooltip: "\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Martina Wimmer</div><div class=\"row-value\">0,00%</div><div class=\"row-value\">114,34%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Michel Bierwirth</div><div class=\"row-value\">91,87%</div><div class=\"row-value\">111,87%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Alexandra Huber</div><div class=\"row-value\">71,09%</div><div class=\"row-value\">109,13%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Josef Ebel</div><div class=\"row-value\">105,08%</div><div class=\"row-value\">107,93%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Niko Reishofer</div><div class=\"row-value\">100,92%</div><div class=\"row-value\">107,78%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Iris Kouvlakis</div><div class=\"row-value\">8,90%</div><div class=\"row-value\">107,14%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Johannes Stick</div><div class=\"row-value\">99,10%</div><div class=\"row-value\">106,71%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Clair\xE9 Assange</div><div class=\"row-value\">99,22%</div><div class=\"row-value\">106,03%</div></div>",
    billableRatio: 0.7234395650988886,
    nonBillableRatio: 0.2765604349011114
  }, {
    month: 'Dez',
    monthTooltip: 'Dezember 2020',
    billableWorkload: 66.09497029697836,
    tooltipBillableWorkload: '66.09%',
    nonBillableWorkload: 28.117986401058776,
    teamWorkload: 0.9421295669803713,
    tooltipTeamWorkload: '94.21%',
    teamTooltip: "\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Alexandra Huber</div><div class=\"row-value\">72,23%</div><div class=\"row-value\">103,38%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Iris Kouvlakis</div><div class=\"row-value\">10,80%</div><div class=\"row-value\">103,08%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Josef Ebel</div><div class=\"row-value\">98,59%</div><div class=\"row-value\">102,17%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Clair\xE9 Assange</div><div class=\"row-value\">95,22%</div><div class=\"row-value\">101,76%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Niko Reishofer</div><div class=\"row-value\">94,23%</div><div class=\"row-value\">101,57%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Johannes Stick</div><div class=\"row-value\">92,45%</div><div class=\"row-value\">100,60%</div></div>\n      <div class=\"tooltip-row\">\n        <div class=\"row-label\">Michel Bierwirth</div><div class=\"row-value\">71,68%</div><div class=\"row-value\">100,20%</div></div>",
    billableRatio: 0.7015486257248034,
    nonBillableRatio: 0.29845137427519663
  }];
  _exports.teamHoursData = teamHoursData;
  var billableRatio = 0.6726;
  _exports.billableRatio = billableRatio;
  var nonBillableRatio = 0.3274;
  _exports.nonBillableRatio = nonBillableRatio;
  var teamWorkload = 1.0273;
  _exports.teamWorkload = teamWorkload;
});