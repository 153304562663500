define("coffeecup/components/analytics/projects/budget-fmt", ["exports", "coffeecup/mixins/project-budget"], function (_exports, _projectBudget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_projectBudget.default);

  _exports.default = _default;
});