define("coffeecup/components/planner/non-working-day", ["exports", "coffeecup/config/environment", "coffeecup/components/planner/time-frame", "coffeecup/mixins/planner/label-position"], function (_exports, _environment, _timeFrame, _labelPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _timeFrame.default.extend(_labelPosition.default, {
    classNameBindings: ['showAbsenceOrAbsenceRequest:absence', 'isAbsenceRequest:absence-request', 'showAbsenceWithoutType:absence-without-type', 'isOff:off', 'activeOnDaysOff:active-on-days-off', 'workHoursExpected:work-hours-expected', 'isHalfDay:half-day'],
    workHoursExpected: Ember.computed.or('maxAllocation', 'isHalfDay'),
    noWorkHoursExpected: Ember.computed.not('workHoursExpected'),
    isOff: null,
    showAbsencesInPlanner: null,
    showAbsenceRequestsInPlanner: null,
    hideAbsencesInPlanner: Ember.computed.not('showAbsencesInPlanner'),
    hideAbsenceRequestsInPlanner: Ember.computed.not('showAbsenceRequestsInPlanner'),
    showAbsenceWithoutType: Ember.computed.and('noWorkHoursExpected', 'absenceType', 'hideAbsencesInPlanner'),
    hideAbsenceRequest: Ember.computed.and('hideAbsenceRequestsInPlanner', 'isAbsenceRequest'),
    hideAbsence: Ember.computed.and('hideAbsencesInPlanner', 'isNotAbsenceRequest'),
    hideAbsenceOrAbsenceRequest: Ember.computed.or('hideAbsenceRequest', 'hideAbsence'),
    showAbsenceOrAbsenceRequest: Ember.computed.not('hideAbsenceOrAbsenceRequest'),
    absenceType: null,
    absenceComment: null,
    absenceRangeType: null,
    isAbsenceRequest: null,
    isNotAbsenceRequest: Ember.computed.not('isAbsenceRequest'),
    isHalfDay: Ember.computed.equal('absenceRangeType', _environment.default.constants.RANGE_TYPE_ONE_DAY_HALF),
    maxAllocation: null,
    absenceColor: Ember.computed('absenceType', 'absenceType.color', function () {
      if (this.get('absenceType.isPublicHoliday')) {
        return '-1';
      }

      return this.get('absenceType.color');
    }),
    tooltip: Ember.computed('absenceType.label', 'absenceType.localizedLabel', 'isHalfDay', function () {
      var _this$get;

      var i18n = this.get('i18n');
      var halfday = this.get('isHalfDay') ? i18n.t('halfDay') : '';
      var absenceLabel = (_this$get = this.get('absenceType.localizedLabel')) !== null && _this$get !== void 0 ? _this$get : i18n.t('absence');
      var iconClass = this.get('absenceType.iconClass');
      var icon = iconClass ? "<span class=\"icomoon-".concat(iconClass, "\"></span> ") : '';
      var absenceRequest = this.get('isAbsenceRequest') ? i18n.t('request') : '';
      var comment = this.get('absenceType.isPublicHoliday') ? " - ".concat(this.get('absenceComment')) : '';
      var tooltip = icon + absenceLabel + comment;
      tooltip += halfday && " (".concat(halfday, ")");
      tooltip += absenceRequest && " (".concat(absenceRequest, ")");
      return Ember.String.htmlSafe(tooltip);
    })
  });

  _exports.default = _default;
});