define("coffeecup/components/planner/user-row", ["exports", "coffeecup/config/environment", "coffeecup/mixins/planner/expandable-rows", "moment", "coffeecup/utils/allocation-math"], function (_exports, _environment, _expandableRows, _moment, _allocationMath) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend(_expandableRows.ExpandableRow, {
    viewport: Ember.inject.service('planner.viewport'),
    activeModal: Ember.inject.service('planner.active-modal'),
    store: Ember.inject.service(),
    permissions: Ember.inject.service(),
    currentUserService: Ember.inject.service('currentUser'),
    workingDaysService: Ember.inject.service('working-days'),
    classNames: 'user-row',
    currentUserCanWrite: null,
    expandedRows: null,
    activeModel: null,
    model: Ember.computed.alias('user'),
    user: null,
    projects: null,
    absences: null,
    canWriteThisUser: Ember.computed('permissions.canManageOwnPlanner', 'currentUserCanWrite', function () {
      var isOwnUser = this.get('user.id') === this.get('currentUserService.currentUser.id');
      return this.get('currentUserCanWrite') || isOwnUser && this.get('permissions.canManageOwnPlanner');
    }),
    // filteredAssignments == planner-assignments
    filteredAssignments: Ember.computed.alias('user.plannerAssignments'),
    projectSortKeys: _environment.default.constants.PROJECT_SORT_KEYS,
    sortedProjects: Ember.computed.alias('projects'),
    assignedProjects: Ember.computed('filteredAssignments.[]', function () {
      return _toConsumableArray(new Set(this.get('filteredAssignments').mapBy('project')));
    }),
    sortedAssignedProjects: Ember.computed.sort('assignedProjects', 'projectSortKeys'),
    projectsWithoutAssignment: Ember.computed('assignedProjects', function () {
      var assignedProjects = new Set(this.get('assignedProjects'));
      return this.get('sortedProjects').filter(function (project) {
        return !assignedProjects.has(project);
      });
    }),
    plannedHoursByProject: Ember.computed('user.plannerAssignments.@each.startDate', 'user.plannerAssignments.@each.endDate', 'user.plannerAssignments.@each.totalAllocation', 'viewport.scopeStartDate', 'viewport.scopeEndDate', function () {
      var _this = this;

      var scopeStartDate = this.get('viewport.scopeStartDate');
      var scopeEndDate = this.get('viewport.scopeEndDate');
      var isWeeklyPlanning = this.get('viewport.isWeeklyPlanningMode');
      var user = this.get('user');
      return this.get('filteredAssignments').filter(function (assignment) {
        return assignment.inRange(scopeStartDate, scopeEndDate);
      }).reduce(function (projectMap, assignment) {
        var project = assignment.get('project');
        var previousHours = projectMap.get(project) || 0;
        var assignStart = assignment.get('startDate');
        var assignEnd = assignment.get('endDate');
        var startDate = assignStart < scopeStartDate ? scopeStartDate : assignStart;
        var endDate = assignEnd > scopeEndDate ? scopeEndDate : assignEnd;

        var totalDays = _this.get('workingDaysService').getWorkingDaysOfTimePeriod(user, startDate, endDate, user.get('absences'), isWeeklyPlanning);

        projectMap.set(project, previousHours + _allocationMath.default.multiplyAllocation(assignment.get('allocation'), totalDays));
        return projectMap;
      }, new Map());
    }),
    sortedPlannedHoursByProject: Ember.computed('plannedHoursByProject', function () {
      var plannedMap = this.get('plannedHoursByProject');

      var sortedKeys = _toConsumableArray(plannedMap.keys()).sortBy('client.name', 'name');

      return sortedKeys.map(function (key) {
        return [key, plannedMap.get(key)];
      });
    }),
    actions: {
      createAssignment: function createAssignment(user, project, startDate, endDate, allocation) {
        var _this2 = this;

        // TODO move this action into a service or any other re-usable component
        // IMPORTANT: changes made here need to be copied to project-row.js::createAssignment
        if (!this.get('activeModal.plannerAssignment')) {
          endDate = endDate || startDate.clone();

          if (!allocation) {
            var allocatedDays = user.allocatedDays(startDate, startDate);
            var firstFoundAllocatedDay = allocatedDays && allocatedDays[0];

            if (!firstFoundAllocatedDay) {
              this.get('notifications').error('missingUserEmploymentForDate', {
                firstname: user.get('firstname'),
                date: startDate.format('L')
              }, {
                key: 'missingUserEmploymentForDate',
                clearDuration: 3000
              });
              return false;
            }

            allocation = 1;

            if (firstFoundAllocatedDay && Ember.computed.notEmpty(firstFoundAllocatedDay['expectedHours']) && Ember.computed.notEmpty(firstFoundAllocatedDay['allocation'])) {
              allocation = Math.max(1, firstFoundAllocatedDay['expectedHours'] - firstFoundAllocatedDay['allocation']);
            }
          }

          var plannerAssignment = this.get('store').createRecord('planner-assignment');
          plannerAssignment.set('startDate', _moment.default.utc(startDate));
          plannerAssignment.set('endDate', _moment.default.utc(endDate));
          plannerAssignment.set('project', project);
          plannerAssignment.set('user', user);
          plannerAssignment.set('allocation', allocation);

          if (plannerAssignment.overlapsAnySibling()) {
            this.get('notifications').error('plannerAssignmentsMayNotOverlap');
            plannerAssignment.destroyRecord();
          } else {
            plannerAssignment.save().then(function () {
              _this2.get('viewport').selectDay(_moment.default.utc(startDate));

              _this2.get('activeModal').setPlannerAssignment(plannerAssignment.get('id'));

              if (plannerAssignment.inRange(_this2.get('viewport.startDate'), _this2.get('viewport.endDate'))) {
                user.get('plannerAssignments').pushObject(plannerAssignment);
                project.get('plannerAssignments').pushObject(plannerAssignment);
              }

              Ember.run.next(function () {
                _this2.sendAction('editAssignment', plannerAssignment,
                /*isInitialEditing*/
                true);
              });
            }).catch(function (error) {
              _this2.get('notifications').errorWithObject(error, 'alert.errorOnSave', 'plannerAssignment');
            });
          }
        }
      },
      createAssignmentForProject: function createAssignmentForProject(project, startDate, endDate, allocation) {
        startDate = startDate || this.get('viewport.defaultAssignmentStartDate');
        endDate = endDate || this.get('viewport.defaultAssignmentEndDate');
        this.send('createAssignment', this.get('model'), project, startDate, endDate, allocation);
      },
      splitAssignment: function splitAssignment(plannerAssignment) {
        this.sendAction('splitAssignment', plannerAssignment, this.get('viewport.selectedDay'));
      },
      editAssignment: function editAssignment(plannerAssignment) {
        this.sendAction('editAssignment', plannerAssignment);
      },
      afterUpdateAssignment: function afterUpdateAssignment(plannerAssignment) {
        this.sendAction('afterUpdateAssignment', plannerAssignment);
      },
      showTimeEntries: function showTimeEntries() {
        this.sendAction.apply(this, ['showTimeEntries'].concat(Array.prototype.slice.call(arguments)));
      },
      customAction: function customAction(actionName, data) {
        this.sendAction('customAction', actionName, data);
      }
    }
  });

  _exports.default = _default;
});