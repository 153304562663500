define("coffeecup/components/input/cc-toggle-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    disabled: false,
    actions: {
      onChange: function onChange() {
        if (this.disabled) return;
        this.onChange();
      }
    }
  });

  _exports.default = _default;
});