define("coffeecup/components/dropdown-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  //{{dropdown-option label=(t "key.value") current="foo" value="bar" action="deleteTimeEntry" param=model}}
  var _default = Ember.Component.extend((_EmberComponent$exte = {
    tagName: 'li',
    classNames: ['dropdown-menu-item'],
    attributeBindings: ['data-remain-open']
  }, _defineProperty(_EmberComponent$exte, 'data-remain-open', Ember.computed.alias('remainOpen')), _defineProperty(_EmberComponent$exte, "current", null), _defineProperty(_EmberComponent$exte, "value", null), _defineProperty(_EmberComponent$exte, "label", null), _defineProperty(_EmberComponent$exte, "icon", null), _defineProperty(_EmberComponent$exte, "iconName", 'checkmark'), _defineProperty(_EmberComponent$exte, "remainOpen", true), _defineProperty(_EmberComponent$exte, "isChecked", Ember.computed('current', {
    get: function get() {
      var current = this.get('current');
      var value = this.get('value');
      return current == value;
    }
  })), _defineProperty(_EmberComponent$exte, "actions", {
    handleClick: function handleClick() {
      if (this.action && !this.disabled) {
        this.action(this.get('value'));
      }
    }
  }), _EmberComponent$exte));

  _exports.default = _default;
});