define("coffeecup/components/bar-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    legendItems: [],
    isLoading: false,
    chartId: null,
    height: 225,
    heightProperty: 'px',
    width: 100,
    widthProperty: '%',
    barChartStyle: Ember.computed('heightValue', 'widthValue', 'isLoading', function () {
      var isLoading = this.get('isLoading') ? 'display: none;' : '';
      var heightValue = this.get('heightValue');
      var widthValue = this.get('widthValue');
      return Ember.String.htmlSafe("".concat(heightValue, " ").concat(widthValue, " ").concat(isLoading));
    }),
    heightValue: Ember.computed('height', 'heightProperty', function () {
      var height = this.get('height');
      var heightProperty = this.get('heightProperty');
      return Ember.String.htmlSafe("height: ".concat(height).concat(heightProperty, ";"));
    }),
    widthValue: Ember.computed('width', 'widthProperty', function () {
      var width = this.get('width');
      var widthProperty = this.get('widthProperty');
      return Ember.String.htmlSafe("width: ".concat(width).concat(widthProperty, ";"));
    })
  });

  _exports.default = _default;
});