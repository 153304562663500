define("coffeecup/adapters/analytics/planner", ["exports", "coffeecup/adapters/-super-sails-rest"], function (_exports, _superSailsRest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //adapter/analytics/planner.js
  var _default = _superSailsRest.default.extend({
    /**
     * 'planner' will not be pluralized
     */
    pathForType: function pathForType(type) {
      return Ember.String.camelize(type);
    }
  });

  _exports.default = _default;
});