define("coffeecup/controllers/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ProfileController = Ember.Controller.extend({
    menu: []
  });
  var _default = ProfileController;
  _exports.default = _default;
});