define("coffeecup/controllers/planner/absences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AbsenceController = Ember.Controller.extend({
    needs: 'application',
    application: Ember.inject.controller(),
    router: Ember.inject.service(),
    currentUserService: Ember.inject.service('currentUser'),
    permissions: Ember.inject.service(),
    hasAbsenceIntegration: Ember.computed.alias('permissions.absenceIntegrationActive'),
    queryParams: {
      teamId: {
        scope: 'controller',
        as: 'team'
      }
    },
    schedulerController: Ember.inject.controller('planner.absences.scheduler'),
    calendarController: Ember.inject.controller('planner.absences.calendar'),
    isPanelOpen: Ember.computed('currentUser.uiState.showRequestPanel', {
      get: function get() {
        return this.get('currentUser.uiState.showRequestPanel');
      },
      set: function set(key, val) {
        this.get('currentUserService').saveUISettings({
          showRequestPanel: val
        });
        return val;
      }
    }),
    requestsOnly: false,
    clickedRequest: null,
    absenceRequests: null,
    currentUser: Ember.computed.alias('application.currentUser'),
    additionalClass: Ember.computed('isPanelOpen', 'requestsOnly', {
      get: function get() {
        if (this.get('hasAbsenceIntegration')) {
          return 'no-requests';
        }

        if (this.get('isPanelOpen')) {
          return 'open';
        } else {
          return 'closed';
        }
      }
    }),
    _controllerFor: function _controllerFor(route) {
      switch (route) {
        case 'planner.absences.scheduler':
          return this.get('schedulerController');

        case 'planner.absences.calendar':
          return this.get('calendarController');
      }
    },
    actions: {
      confirmAbsenceRequest: function confirmAbsenceRequest(clickedRequest) {
        this.get('router').transitionTo(this.get('currentAbsenceRoute') + '.confirm', clickedRequest);
      },
      deleteAbsenceRequest: function deleteAbsenceRequest(absenceRequest) {
        var _this = this;

        absenceRequest.deleteRecord();
        absenceRequest.save().then(function () {
          if (_this.get('currentAbsenceRoute')) {
            _this._controllerFor(_this.get('currentAbsenceRoute')).send('absenceRequestDeleted', {
              entry: absenceRequest
            });
          }

          _this.get('notifications').success('alert.deleted', 'absenceRequest');
        }).catch(function (err) {
          _this.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'absenceRequest');
        });
      },
      saveAbsenceRequest: function saveAbsenceRequest(absenceRequest) {
        var _this2 = this;

        var isNew = absenceRequest.get('isNew');
        absenceRequest.save().then(function () {
          if (_this2.get('currentAbsenceRoute')) {
            if (isNew) {
              _this2._controllerFor(_this2.get('currentAbsenceRoute')).send('absenceRequestCreated', {
                entry: absenceRequest
              });
            } else {
              _this2._controllerFor(_this2.get('currentAbsenceRoute')).send('absenceRequestUpdated', {
                entry: absenceRequest
              });
            }
          }

          _this2.get('notifications').success('alert.saved', 'absenceRequest');
        }).catch(function (err) {
          _this2.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'absenceRequest');
        });
      },
      absenceRequestCreated: function absenceRequestCreated(data) {
        var entry = data.entry;
        var id = !Ember.isEmpty(data.id) ? data.id : entry.get('id');
        var absences = this.get('absenceRequests');

        if (!absences.findBy('id', id)) {
          absences.pushObject(entry);
        }
      },
      absenceRequestUpdated: function absenceRequestUpdated(data) {
        var entry = data.entry;
        var absences = this.get('absenceRequests');

        if (absences) {
          absences.removeObject(entry);
          absences.unshiftObject(entry);
        }
      },
      absenceRequestDeleted: function absenceRequestDeleted(data) {
        var entry = data.entry;
        this.get('absenceRequests').removeObject(entry);
      }
    }
  });
  var _default = AbsenceController;
  _exports.default = _default;
});