define("coffeecup/components/fsg-selectable-list", ["exports", "coffeecup/components/fsg-list"], function (_exports, _fsgList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var FilteredSortedGroupedSelectableListComponent = _fsgList.default.extend({
    layoutName: 'components/fsg-list',
    tagName: 'tbody',
    classNames: ['clickable'],
    // activeRecord
    activeItemId: null,
    _defaultFilterFn: function _defaultFilterFn(item) {
      var purify = function purify(dirtyStr) {
        return dirtyStr.toLowerCase().replace(/\s+/g, '');
      };

      var getValue = function getValue(key) {
        return item.get(key);
      }; // always display current selected item


      if (this.get('activeItemId') == item.get('id')) {
        return true;
      }

      var stack = purify(this.get('_filterKeys').map(getValue).join(''));
      var needle = purify(this.get('filterTerm'));
      return stack.indexOf(needle) > -1;
    }
  });

  var _default = FilteredSortedGroupedSelectableListComponent;
  _exports.default = _default;
});