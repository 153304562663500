define("coffeecup/components/validation/validation-dot", ["exports", "ember-cli-tooltipster/components/tool-tipster"], function (_exports, _toolTipster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _toolTipster.default.extend({
    classNames: ['validation-dot'],
    classNameBindings: ['isInvalid:is-not-valid'],
    content: Ember.computed('isInvalid', 'tooltipText', function () {
      return this.get('isInvalid') ? this.get('tooltipText') : null;
    })
  });

  _exports.default = _default;
});