define("coffeecup/components/resizable-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['timeEntryId:data-time-entry-id'],
    enabled: true,

    /**
     * the id of the entry
     */
    timeEntryId: null,

    /**
     * the property to update on resize
     */
    propertyToUpdateOnResize: 'duration',

    /**
     * determines if the item is currently resizing
     */
    isResizing: false,
    _initResizable: Ember.observer('enabled', function () {
      var _this = this;

      var timeEntryId = this.get('timeEntryId');
      var resizeQuery = document.querySelector('#' + this.get('elementId') + ' .ui-resizable-handle');
      var resizeItem = resizeQuery.parentElement.parentElement;
      var startY, startHeight;

      var doDrag = function doDrag(e) {
        Ember.run.once(function () {
          if (!_this.get('isDestroyed') && !_this.get('isResizing')) {
            _this.set('isResizing', true);

            _this.sendAction('startResizeAction', {
              timeEntryId: timeEntryId,
              propertyToUpdateOnResize: _this.get('propertyToUpdateOnResize'),
              timeEntrySize: startHeight + e.clientY - startY
            });
          }
        });
        Ember.run(function () {
          if (!_this.get('isDestroyed')) {
            _this.sendAction('resizeAction', {
              timeEntryId: timeEntryId,
              propertyToUpdateOnResize: _this.get('propertyToUpdateOnResize'),
              timeEntrySize: startHeight + e.clientY - startY
            });
          }
        });
        resizeItem.style.height = startHeight + e.clientY - startY + 'px';
      };

      var stopDrag = function stopDrag(e) {
        Ember.run(function () {
          if (!_this.get('isDestroyed')) {
            if (e.clientY !== startY) {
              // only drigger action if the cursor has actually moved during drag
              _this.set('isResizing', false);

              _this.sendAction('stopResizeAction', {
                timeEntryId: timeEntryId,
                propertyToUpdateOnResize: _this.get('propertyToUpdateOnResize'),
                resizeMinuteSnap: _this.get('resizeMinuteSnap'),
                timeEntrySize: startHeight + e.clientY - startY
              });
            } else {
              _this.set('isResizing', false);

              _this.sendAction('cancelResize');
            }
          }
        });
        document.documentElement.removeEventListener('mousemove', doDrag, false);
        document.documentElement.removeEventListener('mouseup', stopDrag, false);
      };

      var initDrag = function initDrag(e) {
        startY = e.clientY;
        startHeight = parseInt(document.defaultView.getComputedStyle(resizeItem).height, 10);
        document.documentElement.addEventListener('mousemove', doDrag, false);
        document.documentElement.addEventListener('mouseup', stopDrag, false);
      };

      if (this.get('enabled')) {
        this.set('_initDrag', initDrag);
        resizeQuery.addEventListener('mousedown', initDrag, false);
      }
    }),
    _destroyResizable: function _destroyResizable() {
      var resizeQuery = document.querySelector('#' + this.get('elementId') + ' .ui-resizable-handle');
      var initDrag = this.get('_initDrag');

      if (initDrag && resizeQuery) {
        resizeQuery.removeEventListener('mousedown', initDrag, false);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._initResizable();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._destroyResizable();
    }
  });

  _exports.default = _default;
});