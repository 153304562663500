define("coffeecup/controllers/manage/_super-edit", ["exports", "coffeecup/mixins/buffered-form", "coffeecup/mixins/form-validate-submit", "coffeecup/mixins/create-short-tag", "coffeecup/mixins/image-preview", "coffeecup/mixins/format-currency"], function (_exports, _bufferedForm, _formValidateSubmit, _createShortTag, _imagePreview, _formatCurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_bufferedForm.default, _formValidateSubmit.default, _createShortTag.default, _imagePreview.default, _formatCurrency.default);

  _exports.default = _default;
});