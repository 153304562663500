define("coffeecup/controllers/modal/new-client", ["exports", "coffeecup/controllers/projects/clients/edit", "coffeecup/utils/focus-error-input"], function (_exports, _edit, _focusErrorInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //controllers/modal/new-client.js
  var ModalNewClientController = _edit.default.extend({
    options: null,
    isQuickCreate: true,

    /**
     * set of actions
     */
    actions: {
      confirm: function confirm(callback) {
        var _this = this;

        this.get('form').validate().then(function () {
          _this.get('form').execute();

          _this.get('model').save().then(function () {
            _this.set('openModal', false);

            _this.send('confirmModal', callback);
          }).catch(function (err) {
            _this.get('notifications').errorWithObject(err, 'alert.errorOnSave', _this.get('model.constructor.modelName'));
          });
        }).catch(function (errors) {
          (0, _focusErrorInput.default)(_this, errors);
        });
      },
      onShow: function onShow() {},
      onHide: function onHide() {
        if (this.get('form.isNew')) {
          this.get('model').destroyRecord();
        }

        this.set('openModal', false);
      }
    }
  });

  var _default = ModalNewClientController;
  _exports.default = _default;
});