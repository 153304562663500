define("coffeecup/adapters/menu", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Adapter.extend({
    updateRecord: function updateRecord() {
      return Ember.RSVP.resolve();
    }
  });

  _exports.default = _default;
});