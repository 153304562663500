define("coffeecup/components/navbar-top/right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pull-right', 'navbar-top-right', 'd-flex', 'align-items-center']
  });

  _exports.default = _default;
});