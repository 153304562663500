define("coffeecup/components/cc-dropdown", ["exports", "coffeecup/mixins/empty-action"], function (_exports, _emptyAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emptyAction.default, {
    classNameBindings: ['isFloatingRight:pull-right', 'isFloatingLeft:pull-left', 'isSmallSpaceLeft:small-space-left', 'isLargeSpaceLeft:space-left', 'isOpen:dropdown-open'],
    floatDirection: null,
    spaceDirection: 'left',
    verticalPosition: 'below',
    opens: 'auto-right',
    icon: 'icomoon-more2',
    iconLeft: null,
    label: 'Toggle Options',
    showLabel: false,
    smallIcon: false,
    noBorder: false,
    hasBorder: false,
    smallSpace: false,
    largeSpace: false,
    customTriggerClass: null,
    instantClose: false,
    isOpen: false,
    noButton: false,
    isSpaceLeft: Ember.computed.equal('spaceDirection', 'left'),
    isSpaceRight: Ember.computed.equal('spaceDirection', 'right'),
    isFloatingRight: Ember.computed.equal('floatDirection', 'right'),
    isFloatingLeft: Ember.computed.equal('floatDirection', 'left'),
    isSmallSpaceLeft: Ember.computed.and('smallSpace', 'isSpaceLeft'),
    isLargeSpaceLeft: Ember.computed.and('largeSpace', 'isSpaceLeft'),
    triggerClass: Ember.computed('noBorder', 'hasBorder', 'customTriggerClass', function () {
      if (this.get('noButton')) return '';
      var baseString = 'btn btn-default';
      if (this.get('customTriggerClass')) baseString = this.get('customTriggerClass');
      if (this.get('noBorder')) baseString += ' btn-noborder';
      if (this.get('hasBorder')) baseString += ' has-border';
      return Ember.String.htmlSafe(baseString);
    }),
    dropdownOptions: null,
    empty: function empty(event) {
      event.stopPropagation();
    },
    actions: {
      onOpen: function onOpen(options, event) {
        this.set('dropdownOptions', options);
        this.set('isOpen', true);
      },
      onClose: function onClose(options) {
        this.set('isOpen', false);
      },
      closeDropdown: function closeDropdown(event) {
        var _this = this;

        var remainOpenElement = event.target.closest('[data-remain-open]');
        var remainOpen = remainOpenElement === null || remainOpenElement === void 0 ? void 0 : remainOpenElement.dataset.remainOpen;
        var dropdownOptions = this.get('dropdownOptions');

        if (!remainOpen && this.dropdownOptions && this.dropdownOptions.actions) {
          if (this.get('instantClose')) {
            this.dropdownOptions.actions.close();
          } else {
            Ember.run.later(function () {
              _this.dropdownOptions.actions.close();
            }, 50);
          }
        }
      }
    }
  });

  _exports.default = _default;
});