define("coffeecup/components/list-item-analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ListItemAnalytics = Ember.Component.extend({
    /**
     * part of a table
     */
    tagName: 'td',

    /**
     * default css classes
     */
    classNames: ['text-center', 'list-item-analytics'],

    /**
     * the label of the item
     */
    label: null,

    /**
     * the amountof the item
     */
    amount: null,

    /**
     * the time of the item
     */
    hours: null,

    /**
     * the time of the item
     */
    percent: null,

    /**
     * use small values (small class)
     */
    smallValues: false,
    hasAmount: Ember.computed.notEmpty('amount'),
    hasHours: Ember.computed.notEmpty('hours'),
    hasPercent: Ember.computed.notEmpty('percent'),

    /**
     * returns true, if the item has only a single value
     */
    hasSingleValue: Ember.computed('amount', {
      get: function get() {
        var amount = this.get('amount');
        var hours = this.get('hours');
        var percent = this.get('percent');
        var isSingle = (amount ? 1 : 0) ^ (hours ? 1 : 0) ^ (percent ? 1 : 0);
        return isSingle == 1;
      }
    }),

    /**
     * returns true, if the amount is negative
     */
    amountIsNegative: Ember.computed('amount', {
      get: function get() {
        var amount = this.get('amount');
        return amount < 0;
      }
    }),

    /**
     * determines the n/a condition
     */
    notAvailable: Ember.computed('hours', 'amount', 'percent', {
      get: function get() {
        return Ember.isEmpty(this.get('amount')) && Ember.isEmpty(this.get('hours')) && Ember.isEmpty(this.get('percent'));
      }
    })
  });
  var _default = ListItemAnalytics;
  _exports.default = _default;
});