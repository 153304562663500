define("coffeecup/helpers/attr-safe", ["exports", "coffeecup/utils/attr-safe"], function (_exports, _attrSafe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Takes a given name and ensures it is safe for HTML attributes.
   */
  var _default = Ember.Helper.helper(function (names) {
    return names.reduce(function (previous, name) {
      return previous + (0, _attrSafe.default)(name);
    }, '');
  });

  _exports.default = _default;
});