define("coffeecup/controllers/time-entries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    trackingController: Ember.inject.controller('time-entries.tracking'),
    activeDayShortLabel: Ember.computed.alias('trackingController.activeDayShortLabel'),
    activeDayMonthYearLabel: Ember.computed.alias('trackingController.activeDayMonthYearLabel'),
    timeEntryBackgroundColorOptions: Ember.computed.alias('trackingController.timeEntryBackgroundColorOptions'),
    currentUser: Ember.computed.alias('trackingController.currentUser'),
    showWeekends: Ember.computed.alias('trackingController.showWeekends'),
    timerModeIsStartTimeEndTime: Ember.computed.alias('trackingController.timerModeIsStartTimeEndTime'),
    showStartAndEndTime: Ember.computed.alias('trackingController.showStartAndEndTime'),
    plannerActive: Ember.computed.alias('trackingController.plannerActive'),
    showPlannerAssignments: Ember.computed.alias('trackingController.showPlannerAssignments'),
    generalAllowConcurrentTimeEntries: Ember.computed.alias('trackingController.generalAllowConcurrentTimeEntries'),
    allowConcurrentTimeEntries: Ember.computed.alias('trackingController.allowConcurrentTimeEntries'),
    showSensitiveData: Ember.computed.alias('trackingController.showSensitiveData'),
    shortTimeEntryTag: Ember.computed.alias('trackingController.shortTimeEntryTag'),
    actions: {
      prevDay: function prevDay() {
        this.get('trackingController').send('prevDay');
      },
      nextDay: function nextDay() {
        this.get('trackingController').send('nextDay');
      },
      toggleWeekends: function toggleWeekends() {
        this.get('trackingController').send('toggleWeekends');
      },
      toggleShowStartAndEndTime: function toggleShowStartAndEndTime() {
        this.get('trackingController').send('toggleShowStartAndEndTime');
      },
      toggleShowPlannerAssignments: function toggleShowPlannerAssignments() {
        this.get('trackingController').send('toggleShowPlannerAssignments');
      },
      toggleConcurrentTimeEntries: function toggleConcurrentTimeEntries() {
        this.get('trackingController').send('toggleConcurrentTimeEntries');
      },
      toggleShowSensitiveData: function toggleShowSensitiveData() {
        this.get('trackingController').send('toggleShowSensitiveData');
      },
      toggleTimeEntryLabelStyle: function toggleTimeEntryLabelStyle() {
        this.get('trackingController').send('toggleTimeEntryLabelStyle');
      },
      setTimeEntryBackgroundColor: function setTimeEntryBackgroundColor(item) {
        this.get('trackingController').send('setTimeEntryBackgroundColor', item);
      }
    }
  });

  _exports.default = _default;
});