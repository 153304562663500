define("coffeecup/components/t-tip-time-entry", ["exports", "coffeecup/utils/strip-tags"], function (_exports, _stripTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    timeEntry: null,
    content: Ember.computed('timeEntry', {
      get: function get() {
        return "".concat((0, _stripTags.default)(this.get('timeEntry.project.name')), "<br /><i>").concat((0, _stripTags.default)(this.get('timeEntry.project.clientName')), "</i><br />").concat((0, _stripTags.default)(this.get('timeEntry.comment')));
      }
    })
  });

  _exports.default = _default;
});