define("coffeecup/controllers/projects/clients/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * INJECTIONS
     */
    formatter: Ember.inject.service(),
    permissions: Ember.inject.service(),
    application: Ember.inject.controller(),

    /**
     * PROPERTIES
     */
    startDate: 0,
    endDate: 0,
    analyticsTabs: [{
      worktimeMode: 'projects',
      translationKey: 'projects',
      icon: 'icomoon-projects'
    }, {
      worktimeMode: 'tasks',
      translationKey: 'tasks',
      icon: 'icomoon-circle-check'
    }, {
      worktimeMode: 'users',
      translationKey: 'people',
      icon: 'icomoon-person'
    }
    /* Coming in a later update */
    // {
    //   worktimeMode: 'teams',
    //   translationKey: 'teams',
    //   icon: 'icomoon-tasks',
    // },
    ],
    selectedWorktimeMode: 'projects',

    /*
    COMPUTED PROPERTIES
     */
    currentUser: Ember.computed.alias('application.currentUser'),
    clientId: Ember.computed.alias('client.id'),
    canAccessProjectCosts: Ember.computed.or('permissions.isRootAdmin', 'permissions.canAccessProjectCosts'),
    clientAnalytics: Ember.computed.reads('clientAnalyticsArray.firstObject'),
    isLoaded: Ember.computed('clientAnalyticsArray.isFulfilled', function () {
      return this.get('clientAnalyticsArray.isFulfilled');
    }),
    hasBillableProject: Ember.computed('client.projects', function () {
      return this.get('client.projects').any(function (project) {
        return project.get('isBillable');
      });
    }),
    hasFixedBudgetProject: Ember.computed('client.projects', function () {
      return this.get('client.projects').any(function (project) {
        return project.get('isModeFixedBudget');
      });
    }),
    histogram: Ember.computed('clientAnalytics.histogram', function () {
      return this.get('clientAnalytics.histogram') || [];
    }),
    hasHourBudget: Ember.computed('client', 'clientAnalytics.histogram', function () {
      var histogram = this.get('histogram');
      return histogram && !histogram.isAny('amountSpent');
    }),
    clientInvoices: Ember.computed.filter('allInvoices', function (invoice) {
      return invoice.get('project.client.id') === this.get('client.id');
    }),
    chartColors: Ember.computed('remainingBudget', function () {
      return ['#5FB77F', '#5FB77F'];
    }),
    filteredTabs: Ember.computed('clientAnalytics.projects', 'clientAnalytics.users', 'clientAnalytics.tasks', function () {
      var _this = this;

      return this.get('analyticsTabs').filter(function (tab) {
        return (_this.get("clientAnalytics.".concat(tab.worktimeMode)) || []).length;
      });
    }),
    worktimeMode: Ember.computed('selectedWorktimeMode', 'filteredTabs', function () {
      var filteredModes = this.get('filteredTabs').mapBy('worktimeMode');

      if (filteredModes.includes(this.get('selectedWorktimeMode'))) {
        return this.get('selectedWorktimeMode');
      }

      return filteredModes[0];
    }),
    revenueModule: Ember.computed('currentUser', function () {
      var _this2 = this;

      var i18n = this.get('i18n');
      var uiState = this.get('currentUser.uiState');
      var revenueModule = Ember.Object.create({
        settingsObj: uiState.get('state.clientAnalyticsModuleState.revenue') || {},
        localizedLabel: i18n.t('hoursModuleTitle'),
        isTypeUserWorktime: true,
        isTypeClientReport: true,
        save: function save() {
          _this2._updateAnalyticsModule('revenue');
        }
      });
      return revenueModule;
    }),
    hoursModule: Ember.computed('currentUser', function () {
      var _this3 = this;

      var i18n = this.get('i18n');
      var uiState = this.get('currentUser.uiState');
      return Ember.Object.create({
        settingsObj: uiState.get('state.clientAnalyticsModuleState.hours') || {},
        constructor: {
          modelName: 'dashboard-module'
        },
        localizedLabel: i18n.t('hoursModuleTitle'),
        isTypeUserWorktime: true,
        isTypeClientReport: true,
        save: function save() {
          _this3._updateAnalyticsModule('hours');
        }
      });
    }),
    projectIdsAsArray: Ember.computed('client.projects', function () {
      return this.get('client.projects').map(function (project) {
        return project.idAsInt;
      });
    }),

    /**
     * FUNCTIONS
     */
    _updateAnalyticsModule: function _updateAnalyticsModule(moduleName) {
      var currentUser = this.get('currentUser');
      var analyticsModuleState = currentUser.get('uiState.clientAnalyticsModuleState');
      analyticsModuleState[moduleName] = this.get(moduleName + 'Module.settingsObj');
      currentUser.get('uiState').setProperties({
        analyticsModuleState: analyticsModuleState
      });
      currentUser.save();
    },
    actions: {
      showTimeEntries: function showTimeEntries() {
        var _this$get;

        (_this$get = this.get('application')).send.apply(_this$get, ['showTimeEntries'].concat(Array.prototype.slice.call(arguments)));
      },
      customAction: function customAction(actionName, data) {
        this.get('application').send(actionName, data.projectId);
      },
      closePane: function closePane() {
        this.transitionToRoute('projects');
      },
      selectWorktimeMode: function selectWorktimeMode(newWorktimeMode) {
        this.set('selectedWorktimeMode', newWorktimeMode);
      }
    }
  });

  _exports.default = _default;
});