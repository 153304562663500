define("coffeecup/components/planner/nav-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    numberOfOpenAbsenceRequests: 0,
    currentUser: Ember.inject.service('currentUser'),
    permissions: Ember.inject.service(),
    isAdminOrProjectManager: Ember.computed.reads('currentUser.isAdminOrProjectManager'),
    accountService: Ember.inject.service('account'),
    plannerActive: Ember.computed('isAdminOrProjectManager', 'permissions.canAccessPlanner', 'permissions.canManagePlanner', 'permissions.plannerActive', {
      get: function get() {
        var plannerIsEnabled = this.get('permissions.plannerActive');
        var isAdminOrProjectManager = this.get('isAdminOrProjectManager');
        var canAccessPlanner = this.get('permissions.canAccessPlanner');
        var canManagePlanner = this.get('permissions.canManagePlanner');
        return plannerIsEnabled && (isAdminOrProjectManager || canAccessPlanner || canManagePlanner);
      }
    })
  });

  _exports.default = _default;
});