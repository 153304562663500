define("coffeecup/controllers/resetpassword", ["exports", "coffeecup/controllers/welcome"], function (_exports, _welcome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _welcome.default.extend({
    formIsValid: Ember.computed.and('passwordTrimmed', 'passwordRepeatTrimmed'),
    statusError: Ember.computed.or('statusPasswordMismatch', 'statusPasswordTooShort', 'statusGeneralError'),
    actions: {
      submit: function submit() {
        this._checkPasswords() && this._setPasswordAndLogin();
      }
    }
  });

  _exports.default = _default;
});