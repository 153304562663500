define("coffeecup/controllers/account/profile", ["exports", "ember-changeset", "ember-changeset-validations", "coffeecup/validations/user", "coffeecup/config/environment", "coffeecup/utils/attachment-upload", "coffeecup/utils/attachment-load", "coffeecup/utils/attachment-preview", "coffeecup/mixins/image-preview", "coffeecup/utils/initials"], function (_exports, _emberChangeset, _emberChangesetValidations, _user, _environment, _attachmentUpload, _attachmentLoad, _attachmentPreview, _imagePreview, _initials) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_imagePreview.default, {
    application: Ember.inject.controller(),
    accountService: Ember.inject.service('account'),
    session: Ember.inject.service(),
    accountSettings: Ember.computed.alias('accountService.accountSettings'),
    focus: 'firstname',
    initialFooterHideTimeEntries: null,
    model: Ember.computed.alias('application.currentUser'),
    selectedTimeEntryBackgroundColor: Ember.computed('timeEntryBackgroundColorOptions', 'model.uiState.timeEntryBackgroundColor', function () {
      var _this = this;

      var timeEntryBackgroundColorOptions = this.get('timeEntryBackgroundColorOptions');

      if (!timeEntryBackgroundColorOptions) {
        return null;
      }

      return timeEntryBackgroundColorOptions.find(function (x) {
        return x.get('id') === _this.get('model.uiState.timeEntryBackgroundColor').toString();
      });
    }),
    selectedBeginningOfWeek: Ember.computed('beginningOfWeeks', 'model.beginningOfWeek', function () {
      var _this2 = this;

      var beginningOfWeeks = this.get('beginningOfWeeks');

      if (!beginningOfWeeks) {
        return null;
      }

      return beginningOfWeeks.find(function (x) {
        return x.get('id') === _this2.get('model.beginningOfWeek').toString();
      });
    }),
    selectedTimezone: Ember.computed('timezones', 'model.timezone', function () {
      var _this3 = this;

      var timezones = this.get('timezones');

      if (!timezones) {
        return null;
      }

      return timezones.find(function (x) {
        return x.get('id') === _this3.get('model.timezone').toString();
      });
    }),
    selectedLanguage: Ember.computed('languages', 'model.language', function () {
      var _this4 = this;

      var languages = this.get('languages');

      if (!languages) {
        return null;
      }

      return languages.find(function (x) {
        return x.get('id') === _this4.get('model.language').toString();
      });
    }),

    /**
     * disable email editing of admins
     */
    disableEmail: Ember.computed('accountSettings.accountOwner', 'model.id', function () {
      var accountOwner = this.get('accountSettings.accountOwner');
      var modelId = this.get('model.id');
      return accountOwner.get('id') === modelId;
    }),

    /**
     * array of image uploads
     */
    attachments: [],
    generalAllowConcurrentTimeEntries: Ember.computed.alias('accountSettings.allowConcurrentTimeEntries'),

    /**
     * array of preview images for pending uploads
     */
    previewImgs: [],

    /**
     * imageType for different drop-down actions
     */
    imageType: Ember.computed.alias('model.imageType'),

    /**
     * array of timezones
     */
    timezones: null,

    /**
     * array of languages
     */
    languages: null,

    /**
     * array of beginningOfWeeks
     */
    beginningOfWeeks: null,
    durationFormatHM: _environment.default.constants.DURATION_FORMAT_HM,
    durationFormatDecimal: _environment.default.constants.DURATION_FORMAT_DECIMAL,
    timeFormatIndex12: _environment.default.constants.TIME_FORMAT_12,
    timeFormatIndex24: _environment.default.constants.TIME_FORMAT_24,
    UserValidations: _user.default,

    /**
     * returns a preview image which can either be a preview of the selected file-upload, a gravatar or a previously
     * uploaded file
     */
    previewImg: Ember.computed('imageType', 'model.email', 'previewImgs.length', 'previewImgs.firstObject.data', 'model.imageSmall', 'model.imageDirectoryURL', function () {
      if (!this.get('isImageGravatar') && this.get('previewImgs.length') > 0) {
        return this.get('previewImgs.firstObject.data');
      } else {
        var id = this.get('model.imageDirectoryURL');
        var email = this.get('model.email');
        var emailIsValid = !this.get('errors.email.firstObject');
        return this._image(id, 'm', email, emailIsValid);
      }
    }),
    initials: Ember.computed('model.firstname', 'model.lastname', function () {
      var firstname = this.get('model.firstname') || '';
      var lastname = this.get('model.lastname') || '';
      return (0, _initials.default)(firstname + ' ' + lastname);
    }),
    formIsInvalid: Ember.computed.alias('model.isInvalid'),

    /**
     * Groups the timezones by continent and builds a select-2 compatible representation of the json
     * @return hierarchical array for select-2
     */
    timezonesGrouped: Ember.computed('timezones', function () {
      var result = [];
      var content = this.get('timezones');
      content.forEach(function (apt) {
        var group = apt.get('group');
        var found = result.findBy('groupName', group);

        if (!found) {
          result.pushObject(Ember.Object.create({
            groupName: group,
            options: []
          }));
        }

        result.findBy('groupName', group).get('options').pushObject(apt);
      });
      return result;
    }),
    errors: {},

    /* ACTIONS */
    actions: {
      saveUser: function saveUser() {
        var _this5 = this;

        Ember.run.next(function () {
          _this5.set('errors', {});

          var hasHideTimeEntryChanged = _this5.get('initialFooterHideTimeEntries') !== _this5.get('model.uiState.footerHideTimeEntries');

          if (_this5.get('model.hasDirtyAttributes') || _this5.get('model').didChange('insuranceType') || hasHideTimeEntryChanged) {
            var changeset = (0, _emberChangeset.Changeset)(_this5.get('model'), (0, _emberChangesetValidations.default)(_user.default), _user.default);
            changeset.validate().then(function () {
              if (changeset.get('isValid')) {
                _this5.get('model').save().then(function () {
                  _this5.set('initialFooterHideTimeEntries', _this5.get('model.uiState.footerHideTimeEntries'));

                  _this5.get('notifications').success('alert.saved', 'user');
                }).catch(function (err) {
                  _this5.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'user');
                });
              } else {
                _this5.set('errors', changeset.error);

                _this5.get('notifications').info('pleaseCheckYourInputs');
              }
            }).catch(function () {
              _this5.get('notifications').info('pleaseCheckYourInputs');
            });
          }
        });
      },
      resetUser: function resetUser() {
        this.get('model').rollbackAttributes();
      },
      addImage: function addImage(files) {
        var _this6 = this;

        var model = this.get('model');
        var accessToken = this.get('session.data.authenticated.access_token');
        this.get('attachments').setObjects([]);
        this.get('previewImgs').setObjects([]);
        files.forEach(function (file) {
          if (!_this6.get('attachments').findBy('name', file.name)) {
            _this6.get('attachments').pushObject(file);
          }
        });
        this.get('attachments').map(function (file) {
          (0, _attachmentLoad.default)(file).then(function (data) {
            (0, _attachmentUpload.attachmentUpload)(file, _attachmentUpload.ATTACHMENT_MODEL_USER_IMAGE, _this6.get('model.id'), accessToken).then(function () {
              (0, _attachmentPreview.imagePreview)(file).then(function (data) {
                _this6.get('previewImgs').pushObject(Ember.Object.create({
                  data: data
                }));

                model.set('imageType', _environment.default.constants.IMG_TYPE_UPLOAD);
                model.save().then(function () {
                  _this6.get('notifications').success('alert.saved', 'user');
                });
              }, function (err) {
                _this6.get('notifications').errorWithObject(err, 'alert.errorOnImagePreview');

                model.set('imageType', _environment.default.constants.IMG_TYPE_GRAVATAR);

                _this6.send('saveUser');
              });
            }).catch(function (error) {
              _this6.get('notifications').errorWithObject(error, 'alert.errorOnImagePreview');
            });
          }).catch(function (error) {
            _this6.get('notifications').errorWithObject(error, 'alert.errorOnImagePreview');
          });
        });
      },
      gravatarImg: function gravatarImg(afterHook) {
        this.get('attachments').setObjects([]);
        this.get('previewImgs').setObjects([]);
        this.set('model.imageType', _environment.default.constants.IMG_TYPE_GRAVATAR);
        this.send('saveUser');

        if (afterHook && typeof afterHook === 'function') {
          afterHook();
        }
      },

      /**
       * use the initials image (css generated)
       */
      initialsImg: function initialsImg(afterHook) {
        this.get('attachments').setObjects([]);
        this.get('previewImgs').setObjects([]);
        this.set('model.imageType', _environment.default.constants.IMG_TYPE_INITIALS);
        this.send('saveUser');

        if (afterHook && typeof afterHook === 'function') {
          afterHook();
        }
      }
    }
  });

  _exports.default = _default;
});