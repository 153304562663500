define("coffeecup/components/color-picker-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Custom color picker view on basis of the color picker component by Heather Brysiewicz:
   * https://gist.github.com/hbrysiewicz/11016021
   * */
  var ColorPickerOption = Ember.Component.extend({
    tagName: 'li',
    color: undefined,
    activeColor: undefined,

    /**
     * the hex value of this color options
     */
    hexValue: Ember.computed('color', {
      get: function get() {
        return this.get('color.hexString');
      }
    }),

    /**
     * the html inline style for this color option
     */
    fillStyle: Ember.computed('hexValue', {
      get: function get() {
        return Ember.String.htmlSafe('background-color:' + this.get('hexValue'));
      }
    }),

    /**
     * decide whether to use the black or the white check-mark
     */
    cssValue: Ember.computed('color', {
      get: function get() {
        var color = this.get('color');
        var value = color.get('luminance') >= 128 ? 'light' : 'dark';
        if (this.get('active')) return 'active-check-' + value;
      }
    }),

    /**
     * @returns {Boolean} true when the color is the active color
     */
    active: Ember.computed('color', 'activeColor', {
      get: function get() {
        var color = this.get('color');
        var activeColor = this.get('activeColor');
        return color.get('hex') === activeColor.get('hex');
      }
    }),

    /**
     * define an action to be triggered when choosing a color of the picker
     * @event {Object} the javascript click event
     */
    click: function click(event) {
      this.sendAction('action', this.get('color'));
    }
  });
  var _default = ColorPickerOption;
  _exports.default = _default;
});