define("coffeecup/components/custom-content-panel", ["exports", "coffeecup/components/content-panel"], function (_exports, _contentPanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _contentPanel.default.extend({});

  _exports.default = _default;
});