define("coffeecup/adapters/-super-sails-rest", ["exports", "ember-data", "coffeecup/config/environment", "coffeecup/mixins/reconnect", "ember-data-sails/adapters/sails-rest", "coffeecup/mixins/auth-data-adapter-mixin", "ember-inflector"], function (_exports, _emberData, _environment, _reconnect, _sailsRest, _authDataAdapterMixin, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var SuperSailsRestAdapter = _sailsRest.default.extend(_authDataAdapterMixin.default, _reconnect.default, {
    host: _environment.default.apiHost,
    authorizer: 'authorizer:oauth2',
    session: Ember.inject.service(),

    /**
     * Whether to use CSRF tokens or not
     */
    useCSRF: false,

    /**
     * Whether to group multiple find by ID with one request with a `where`
     */
    coalesceFindRequests: false,
    nonceToken: null,
    access_token: Ember.computed.alias('session.data.authenticated.access_token'),
    headers: Ember.computed('nonceToken', 'access_token', function () {
      if (!this.get('nonceToken')) {
        this.set('nonceToken', this._generateToken());
      }

      return {
        'Socket-Nonce': this.get('nonceToken')
      };
    }),

    /**
     * The namespace of your API
     */
    namespace: 'v1',

    /**
     * If you want to use https://github.com/mphasize/sails-generate-ember-blueprints,
     * you need to override the default serializer to be used
     */
    defaultSerializer: '-rest',
    _appendAccessToken: function _appendAccessToken(url) {
      return url;
    },

    /**
     * By default Sails does not pluralize the model names when creating urls to a model's route.
     * This is different from the normal Ember Data conventions. To make it easy to get started the SailsRESTAdapter and
     * SailsSocketAdapter will attempt to singularize the model name when requesting a model's route.
     * If you would like to use a pluralized model name in the route you can override the default pathForType method.
     */
    pathForType: function pathForType(type) {
      var camelized = Ember.String.camelize(type);
      return (0, _emberInflector.pluralize)(camelized);
    },
    generateNewNonceToken: function generateNewNonceToken() {
      this.set('nonceToken', this._generateToken());
    },
    _generateToken: function _generateToken() {
      // token must be between a size of 16 to 64 characters
      var tokenLength = Math.floor(Math.random() * 48) + 16;
      var token = '';
      var availableLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';

      for (var i = 0; i < tokenLength; i++) {
        token += availableLetters.charAt(Math.floor(Math.random() * availableLetters.length));
      }

      return token;
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (this.isSuccess(status, headers, payload)) {
        return payload;
      } else if (this.isInvalid(status, headers, payload)) {
        return new _emberData.default.InvalidError(payload.errors);
      }

      return this._super(status, headers, JSON.stringify(payload), requestData);
    }
  });

  var _default = SuperSailsRestAdapter;
  _exports.default = _default;
});