define("coffeecup/components/modals/user-analytics/export", ["exports", "coffeecup/utils/build-url", "coffeecup/config/environment"], function (_exports, _buildUrl, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend((_EmberComponent$exte = {
    formatter: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    clock: Ember.inject.service(),
    user: null,
    openModal: false,
    submitting: false,
    closeModal: false,
    showStartEnd: false,
    accessToken: null,
    startDate: Ember.computed('clock.now', {
      get: function get() {
        return this.get('clock.now').startOf('month');
      }
    }),
    endDate: Ember.computed('clock.now', {
      get: function get() {
        return this.get('clock.now').subtract(1, 'days');
      }
    }),
    maxEndDate: Ember.computed('clock.now', {
      get: function get() {
        return this.get('clock.now').endOf('month');
      }
    }),
    title: Ember.computed('startDate', 'endDate', function () {
      var i18n = this.get('i18n');
      var formatter = this.get('formatter');
      var startDate = this.get('startDate');
      var endDate = this.get('endDate');
      return i18n.t('exportTitle', {
        from: formatter.formatDate(startDate),
        to: formatter.formatDate(endDate)
      });
    }),
    exportFormat: 0,
    durationFormat: 0,
    EXPORT_FORMAT_PDF: 0,
    EXPORT_FORMAT_EXCEL: 1,
    EXPORT_FORMAT_CSV: 2,
    isColumnWorkTimeVisible: true,
    isSignatureVisible: true,
    isColumnBreaksVisible: true,
    isColumnOverTimeVisible: true,
    isColumnStartEndVisible: Ember.computed.equal('showStartEnd', true),
    DURATION_FORMAT_DECIMAL: _environment.default.constants.DURATION_FORMAT_DECIMAL,
    DURATION_FORMAT_HM: _environment.default.constants.DURATION_FORMAT_HM
  }, _defineProperty(_EmberComponent$exte, "accessToken", Ember.computed.alias('session.data.authenticated.access_token')), _defineProperty(_EmberComponent$exte, "isExportPdf", Ember.computed('exportFormat', {
    get: function get() {
      return this.get('exportFormat') === this.EXPORT_FORMAT_PDF;
    }
  })), _defineProperty(_EmberComponent$exte, "exportTimeEntries", function exportTimeEntries(queryParams, format) {
    format = format || 'csv';
    queryParams = queryParams || {};
    var url = (0, _buildUrl.default)(this.get('store'), 'export/workinghours', Ember.$.extend(null, queryParams, {
      format: format,
      access_token: this.get('accessToken')
    }));
    window.open(url);
    return false;
  }), _defineProperty(_EmberComponent$exte, "actions", {
    onHide: function onHide() {
      this.set('openModal', false);
    },
    download: function download() {
      var params = Ember.$.extend({}, this.get('timeEntries.filterParams'));
      params.start_date = this.get('startDate').format(_environment.default.constants.DATE_FORMAT_DAY);
      params.end_date = this.get('endDate').format(_environment.default.constants.DATE_FORMAT_DAY);
      params.title = this.get('title').toString();
      params.duration_format = this.get('durationFormat');
      params.columns = {
        breaks: this.get('isColumnBreaksVisible'),
        overtime: this.get('isColumnOverTimeVisible'),
        start_end: this.get('isColumnStartEndVisible')
      };
      params.user = [this.get('user.id')];
      params.signature = this.get('isSignatureVisible');
      var format;

      switch (this.get('exportFormat')) {
        case this.get('EXPORT_FORMAT_PDF'):
          format = 'pdf';
          break;

        case this.get('EXPORT_FORMAT_EXCEL'):
          format = 'xlsx';
          break;

        default:
          format = 'csv';
          break;
      }

      this.exportTimeEntries(params, format); // hide modal after download

      this.send('onHide');
    }
  }), _EmberComponent$exte));

  _exports.default = _default;
});