define("coffeecup/components/validation/time-entry-overlay", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['validation.isApproved:success', 'validation.isRejected:danger'],
    classNames: ['flex-full-screen-center', 'vertically-ordered'],
    formatter: Ember.inject.service(),
    currentUserService: Ember.inject.service('currentUser'),
    currentUser: Ember.computed.reads('currentUserService.currentUser'),
    VALIDATION_SUBMITTED: _environment.default.constants.VALIDATION_SUBMITTED,
    openValidationLog: false,
    validation: null,
    validationLogs: [],
    latestValidationLog: Ember.computed('validationLogs.[]', function () {
      return this.get('validationLogs.lastObject');
    }),
    showButton: Ember.computed('validation', 'currentUser', function () {
      return this.get('currentUser.id') === this.get('validation.user.id');
    }),
    validationRecipient: Ember.computed('validation', 'currentUser', function () {
      return this.get('validation.isAutomatic') ? this.get('currentUser.supervisor') : this.get('validation.validatedBy');
    }),
    icon: Ember.computed('validation.state', function () {
      if (this.get('validation.isApproved')) {
        return 'icomoon-checkmark';
      } else if (this.get('validation.isRejected')) {
        return 'icomoon-warning2';
      } else {
        return 'icomoon-hour-glass2';
      }
    }),
    label: Ember.computed('validation.state', function () {
      var i18n = this.get('i18n');
      var formatter = this.get('formatter');
      var validation = this.get('validation');

      if (this.get('validation.isApproved')) {
        if (this.get('validation.isAutomatic')) {
          return i18n.t('weekAutomaticallyApproved', {
            date: formatter.formatDate(validation.get('createdAt'))
          });
        } else {
          return i18n.t('weekApprovedBy', {
            date: formatter.formatDate(validation.get('createdAt')),
            validatedBy: validation.get('validatedBy.label')
          });
        }
      } else if (this.get('validation.isRejected')) {
        return i18n.t('weekRejected');
      } else {
        return i18n.t('weekSubmited', {
          date: formatter.formatDate(validation.get('date'))
        });
      }
    }),
    buttonLabel: Ember.computed('validation.state', function () {
      return this.get('validation.isRejected') ? this.get('i18n').t('submitChanges') : null;
    }),
    actions: {
      toggleOverlay: function toggleOverlay() {
        if (this.get('validation.isRejected')) {
          this.toggleProperty('openValidationLog');
        }
      },
      closeOverlay: function closeOverlay() {
        this.set('openValidationLog', false);
      },
      onClick: function onClick() {
        this.set('openValidationLog', false);
        this.set('openModal', true);
      }
    }
  });

  _exports.default = _default;
});