define("coffeecup/components/input/input-clearable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['data-project-tour'],
    classNames: ['has-feedback'],
    icon: null,
    iconCss: Ember.computed('icon', {
      get: function get() {
        return "icon ".concat(this.get('icon'), " form-control-feedback");
      }
    }),
    value: null,
    placeholder: null,
    showClear: Ember.computed.reads('value'),
    actions: {
      clearValue: function clearValue() {
        this.set('value', null);
        if (this.$('input') && this.$('input').length > 0) this.$('input').focus();
      }
    }
  });

  _exports.default = _default;
});