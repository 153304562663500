define("coffeecup/components/initials-avatar", ["exports", "coffeecup/utils/initials"], function (_exports, _initials) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['initials-avatar', 'noselect'],
    classNameBindings: ['isLarge:large', 'isMedium:medium', 'isSmall:small', 'isExtraSmall:extra-small', 'typeClient:client', 'color'],

    /**
     * returns true, if the avatar is as editable / large element
     * (adds a top margin to the element)
     */
    isLarge: Ember.computed('size', {
      get: function get() {
        return this.get('size') == 'large';
      }
    }),

    /**
     * returns true, if the avatar is as editable / large element
     * (adds a top margin to the element)
     */
    isExtraSmall: Ember.computed('size', {
      get: function get() {
        return this.get('size') == 'extra-small';
      }
    }),
    isSmall: Ember.computed('size', {
      get: function get() {
        return this.get('size') == 'small';
      }
    }),
    isMedium: Ember.computed('size', {
      get: function get() {
        return this.get('size') == 'medium';
      }
    }),

    /**
     * the background color of the avatar
     */
    color: null,

    /**
     * the size of the avatar
     * possible values are: small (24x24), default (48x48) and large (112x112)
     */
    size: 'default',

    /**
     * the name og the user, company, etc
     */
    name: null,

    /**
     * if the type is client, we dont render the initials
     * we render an icon instead
     */
    typeClient: false,

    /**
     * Indicates if the raw value of name property should be returned
     */
    skipGenerate: false,

    /**
     * the initials
     */
    initials: Ember.computed('name', {
      get: function get() {
        if (this.get('skipGenerate')) return this.get('name');
        return (0, _initials.default)(this.get('name'));
      }
    })
  });

  _exports.default = _default;
});