define("coffeecup/components/icon-switch", ["exports", "ember-cli-tooltipster/components/tool-tipster"], function (_exports, _toolTipster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _toolTipster.default.extend({
    i18n: Ember.inject.service(),
    classNameBindings: ['activeIf:active', 'showAsInlineSwitch:inline-switch', 'showAsButton:btn-toggle'],
    value: null,
    tooltipOff: null,
    tooltipOn: null,
    activeIf: null,
    showAsInlineSwitch: false,
    showAsButton: false,
    side: 'left',
    tooltipValue: Ember.computed('value', {
      get: function get() {
        return this.get('value') ? this.get('tooltipOff') : this.get('tooltipOn');
      }
    }),
    content: Ember.computed('tooltipValue', function () {
      var tooltipValue = this.get('tooltipValue');
      return tooltipValue ? this.get('i18n').t(tooltipValue) : null;
    }),
    click: function click() {
      if (!this.disabled) {
        this.toggleProperty('value');
      }
    }
  });

  _exports.default = _default;
});