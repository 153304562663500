define("coffeecup/helpers/and", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.and = and;
  _exports.default = void 0;

  function and(params) {
    return params.every(function (param) {
      return !!param;
    });
  }

  var _default = Ember.Helper.helper(and);

  _exports.default = _default;
});