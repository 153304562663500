define("coffeecup/components/button-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['data-project-tour'],
    classNames: ['btn-group']
  });

  _exports.default = _default;
});