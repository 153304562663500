define("coffeecup/controllers/analytics/clients/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * INJECTIONS
     */
    formatter: Ember.inject.service(),
    permissions: Ember.inject.service(),
    application: Ember.inject.controller(),

    /**
     * PROPERTIES
     */
    startDate: 0,
    endDate: 0,

    /*
    COMPUTED PROPERTIES
     */
    currentUser: Ember.computed.alias('application.currentUser'),
    selectedClientId: Ember.computed.alias('selectedClient.id'),
    canAccessProjectCosts: Ember.computed.or('permissions.isRootAdmin', 'permissions.canAccessProjectCosts'),
    hasHourBudget: Ember.computed('selectedClient', 'selectedClientAnalytics.histogram', function () {
      var histogram = this.get('selectedClientAnalytics.histogram');
      return !histogram.isAny('amountSpent');
    }),
    chartColors: Ember.computed('remainingBudget', function () {
      return ['#5FB77F', '#5FB77F'];
    }),
    revenueModule: Ember.computed('currentUser', function () {
      var _this = this;

      var i18n = this.get('i18n');
      var uiState = this.get('currentUser.uiState');
      var revenueModule = Ember.Object.create({
        settingsObj: uiState.get('state.clientAnalyticsModuleState.revenue') || {},
        localizedLabel: i18n.t('hoursModuleTitle'),
        isTypeUserWorktime: true,
        isTypeClientReport: true,
        save: function save() {
          _this._updateAnalyticsModule('revenue');
        }
      });
      return revenueModule;
    }),
    hoursModule: Ember.computed('currentUser', function () {
      var _this2 = this;

      var i18n = this.get('i18n');
      var uiState = this.get('currentUser.uiState');
      return Ember.Object.create({
        settingsObj: uiState.get('state.clientAnalyticsModuleState.hours') || {},
        constructor: {
          modelName: 'dashboard-module'
        },
        localizedLabel: i18n.t('hoursModuleTitle'),
        isTypeUserWorktime: true,
        isTypeClientReport: true,
        save: function save() {
          _this2._updateAnalyticsModule('hours');
        }
      });
    }),

    /**
     * FUNCTIONS
     */
    _updateAnalyticsModule: function _updateAnalyticsModule(moduleName) {
      var currentUser = this.get('currentUser');
      var analyticsModuleState = currentUser.get('uiState.clientAnalyticsModuleState');
      analyticsModuleState[moduleName] = this.get(moduleName + 'Module.settingsObj');
      currentUser.get('uiState').setProperties({
        analyticsModuleState: analyticsModuleState
      });
      currentUser.save();
    },
    actions: {
      showTimeEntries: function showTimeEntries() {
        var _this$get;

        (_this$get = this.get('application')).send.apply(_this$get, ['showTimeEntries'].concat(Array.prototype.slice.call(arguments)));
      },
      customAction: function customAction(actionName, data) {
        this.get('application').send(actionName, data.projectId);
      }
    }
  });

  _exports.default = _default;
});