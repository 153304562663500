define("coffeecup/controllers/analytics/time-entries/batch-update", ["exports", "coffeecup/config/environment", "coffeecup/utils/build-url"], function (_exports, _environment, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    needs: ['application'],
    application: Ember.inject.controller(),
    timeEntriesController: Ember.inject.controller('analytics.time-entries'),

    /**
     * the selected filterParams as Object
     */
    filterParams: Ember.computed.reads('timeEntriesController.filterParams'),

    /**
     * query param from date
     */
    from: Ember.computed.reads('timeEntriesController.from'),
    formattedFrom: Ember.computed('from', function () {
      return moment(this.get('from')).format('L');
    }),

    /**
     * query param to date
     */
    to: Ember.computed.reads('timeEntriesController.to'),
    formattedTo: Ember.computed('to', function () {
      return moment(this.get('to')).format('L');
    }),
    isOnlyOpen: Ember.computed.reads('timeEntriesController.isOnlyOpen'),
    isOnlyLocked: Ember.computed.reads('timeEntriesController.isOnlyLocked'),
    isOnlyNonBillable: Ember.computed.reads('timeEntriesController.isOnlyNonBillable'),
    hasStatusFilter: Ember.computed.reads('timeEntriesController.hasStatusFilter'),

    /**
     * show billable hours
     */
    billable: Ember.computed.reads('timeEntriesController.billable'),

    /**
     * show billed hours
     */
    billed: Ember.computed.reads('timeEntriesController.billed'),

    /**
     * array of filtered clients
     */
    client: Ember.computed.reads('timeEntriesController.client'),

    /**
     * array of filtered projects
     */
    project: Ember.computed.reads('timeEntriesController.project'),

    /**
     * array of filtered tasks
     */
    task: Ember.computed.reads('timeEntriesController.task'),

    /**
     * array of filtered users
     */
    user: Ember.computed.reads('timeEntriesController.user'),

    /**
     * array of filtered tags
     */
    tag: Ember.computed.reads('timeEntriesController.tag'),

    /**
     * array of all clients
     */
    clients: Ember.computed.reads('timeEntriesController.clients'),

    /**
     * array of all projects
     */
    projects: Ember.computed.reads('timeEntriesController.projects'),

    /**
     * array of all tasks
     */
    tasks: Ember.computed.reads('timeEntriesController.tasks'),

    /**
     * array of all users
     */
    users: Ember.computed.reads('timeEntriesController.users'),

    /**
     * array of all tags
     */
    tags: Ember.computed.reads('timeEntriesController.tags'),

    /**
     * boolean whether the modal should be opened
     */
    openModal: false,

    /**
     * boolean whether the modal should be closed
     */
    closeModal: false,

    /**
     * boolean, true when the form is being submitted
     */
    submitting: false,

    /**
     * modal subtitle
     */
    modalSubTitle: null,
    warning: null,

    /**
     * disables the submit button when the form is not valid or it's currently being submitted
     * @return boolean
     */
    submitIsDisabled: Ember.computed.alias('submitting'),

    /**
     * returns the selected clients
     */
    clientsSelected: Ember.computed('client', 'clients', {
      get: function get() {
        var clients = this.get('clients');
        var selectedIds = this.get('client');
        return clients.filter(function (item) {
          return selectedIds.indexOf(item.get('id')) > -1;
        });
      }
    }),

    /**
     * returns the selected projects
     */
    projectsSelected: Ember.computed('project', 'projects', {
      get: function get() {
        var projects = this.get('projects');
        var selectedIds = this.get('project');
        return projects.filter(function (item) {
          return selectedIds.indexOf(item.get('id')) > -1;
        });
      }
    }),

    /**
     * returns the selected tasks
     */
    tasksSelected: Ember.computed('task', 'tasks', {
      get: function get() {
        var tasks = this.get('tasks');
        var selectedIds = this.get('task');
        return tasks.filter(function (item) {
          return selectedIds.indexOf(item.get('id')) > -1;
        });
      }
    }),

    /**
     * returns the selected users
     */
    usersSelected: Ember.computed('user', 'users', {
      get: function get() {
        var users = this.get('users');
        var selectedIds = this.get('user');
        return users.filter(function (item) {
          return selectedIds.indexOf(item.get('id')) > -1;
        });
      }
    }),

    /**
     * returns the selected tags
     */
    tagsSelected: Ember.computed('tag', 'tags', {
      get: function get() {
        var tags = this.get('tags');
        var selectedIds = this.get('tag');
        return tags.filter(function (item) {
          return selectedIds.indexOf(item.get('id')) > -1;
        });
      }
    }),

    /**
     * set of actions
     */
    actions: {
      onHide: function onHide() {
        this.transitionToRoute('analytics.time-entries');
      },
      bulkChange: function bulkChange() {
        var _this = this;

        this.set('submitting', true);
        var allowedParams = _environment.default.constants.ALLOWED_BATCHUPDATE_PARAMS;
        var k = this.get('model.k');
        var v = this.get('model.v');
        var i18n = this.get('i18n');

        if (allowedParams.indexOf(k) > -1) {
          var params = this.get('filterParams');
          var url = (0, _buildUrl.default)(this.store, 'timeEntries/batchUpdate', params);
          var body = {
            timeEntry: {}
          };
          body.timeEntry[k] = v;
          return this.get('session').postJSON(url, body).then(function (result) {
            _this.send('onHide');

            _this.send('refreshModel');

            _this.get('notifications').success('alert.bulkSaved', {
              key: i18n.t('timeEntries'),
              count: result.updatedEntries
            });
          }).catch(function (err) {
            _this.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'timeEntry');
          }).finally(function () {
            _this.set('submitting', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});