define("coffeecup/components/date-week-picker", ["exports", "coffeecup/config/environment", "coffeecup/components/date-range-picker"], function (_exports, _environment, _dateRangePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dateRangePicker.default.extend({
    week: null,
    drpObj: null,
    weekLabel: 'Week',
    currentWeekLabel: 'this week',
    maxDate: Ember.computed('maxWeek', function () {
      if (!this.get('maxWeek')) return null;
      return moment(this.get('maxWeek')).endOf('isoWeek');
    }),
    maxWeek: null,
    isInMaxWeek: Ember.computed('maxWeek', 'week', function () {
      return this.get('week') === this.get('maxWeek');
    }),
    weekNumber: Ember.computed('week', {
      get: function get() {
        var weekObj = moment(this.get('week'), _environment.default.constants.DATE_PICKER_FORMAT);
        return this.get('weekLabel') + ' ' + weekObj.isoWeek();
      }
    }),
    isCurrentWeek: Ember.computed('week', 'currentWeek', {
      get: function get() {
        return this.get('currentWeek') === this.get('week');
      }
    }),
    currentWeek: Ember.computed('clock.week', {
      get: function get() {
        return this.get('clock.week').format(_environment.default.constants.DATE_PICKER_FORMAT);
      }
    }),
    startDate: Ember.computed('week', {
      get: function get() {
        var weekObj = moment(this.get('week'), _environment.default.constants.DATE_PICKER_FORMAT);
        return weekObj.startOf('isoWeek');
      }
    }),
    endDate: Ember.computed('week', {
      get: function get() {
        var weekObj = moment(this.get('week'), _environment.default.constants.DATE_PICKER_FORMAT);
        return weekObj.startOf('isoWeek').add(6, 'days');
      }
    }),
    weekRange: Ember.computed('startDate', 'endDate', {
      get: function get() {
        var formatter = this.get('formatter');
        return formatter.formatDate(this.get('startDate')) + this.get('separator') + formatter.formatDate(this.get('endDate'));
      }
    }),
    setDrpDateRange: Ember.observer('week', function () {
      var $drpObj = this.get('drpObj');

      if ($drpObj) {
        $drpObj.setStartDate(this.get('startDate'));
        $drpObj.setEndDate(this.get('endDate'));
      }
    }),
    //Init the dropdown when the component is added to the DOM
    didInsertElement: function didInsertElement() {
      var self = this;
      var $input = this.$('.date-range-picker').daterangepicker({
        parentEl: '#date-picker-container',
        ranges: null,
        showWeekNumbers: true,
        singleDatePicker: true,
        showDropdowns: true,
        drops: this.get('drops'),
        opens: this.get('opens'),
        maxDate: this.get('maxDate') || this.get('clock.week').add(5, 'years')
      }, function (start, end, label) {
        start = start.startOf('week');
        self.set('week', start.format(_environment.default.constants.DATE_PICKER_FORMAT));
      }); // save a reference to the date picker-obj

      this.set('drpObj', $input.data('daterangepicker')); // add a css class

      this.get('drpObj').container.first().addClass('date-week-picker'); // highlight current selected week

      this.setDrpDateRange();
    },
    //Remove the hidden dropdown when this component is destroyed
    willDestroy: function willDestroy() {
      this.get('drpObj').remove();
    },
    actions: {
      currentWeek: function currentWeek() {
        this.set('week', this.get('currentWeek'));
      },
      prevWeek: function prevWeek() {
        var weekObj = moment(this.get('week'), _environment.default.constants.DATE_PICKER_FORMAT);
        this.set('week', weekObj.subtract('days', 7).format(_environment.default.constants.DATE_PICKER_FORMAT));
      },
      nextWeek: function nextWeek() {
        var weekObj = moment(this.get('week'), _environment.default.constants.DATE_PICKER_FORMAT);
        this.set('week', weekObj.add(7, 'days').format(_environment.default.constants.DATE_PICKER_FORMAT));
      }
    }
  });

  _exports.default = _default;
});