define("coffeecup/controllers/planner/team", ["exports", "coffeecup/config/environment", "coffeecup/mixins/planner/expandable-rows", "coffeecup/mixins/planner/filter-terms", "coffeecup/components/popover-filter/popover-filter", "coffeecup/utils/string", "coffeecup/utils/list-tooltip-builder"], function (_exports, _environment, _expandableRows, _filterTerms, _popoverFilter, _string, _listTooltipBuilder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TAG_MODEL = 'user';

  var _default = Ember.Controller.extend(_expandableRows.ExpandableRows, _filterTerms.default, {
    viewport: Ember.inject.service('planner.viewport'),
    currentUserService: Ember.inject.service('currentUser'),
    formatter: Ember.inject.service('formatter'),
    permissions: Ember.inject.service(),
    zoom: Ember.inject.service('planner.zoom'),
    clientService: Ember.inject.service('client'),
    i18n: Ember.inject.service(),
    application: Ember.inject.controller(),
    planner: Ember.inject.controller('planner'),
    queryParams: {
      'selectedFilters.userIds': {
        scope: 'controller',
        as: 'user'
      },
      'selectedFilters.tagIds': {
        scope: 'controller',
        as: 'tag'
      },
      'selectedFilters.projectIds': {
        scope: 'controller',
        as: 'project'
      },
      'selectedFilters.clientIds': {
        scope: 'controller',
        as: 'client'
      },
      'selectedFilters.teamIds': {
        scope: 'controller',
        as: 'team'
      }
    },
    tagModel: [TAG_MODEL],
    disableFrameCalculation: Ember.computed.reads('planner.disableFrameCalculation'),
    currentUserCanWrite: Ember.computed.reads('planner.currentUserCanWrite'),
    showHiddenUsers: Ember.computed.reads('currentUserService.currentUser.uiState.showHiddenUsers'),
    weekGrid: Ember.computed.alias('planner.weekGrid'),
    existingTags: Ember.computed.reads('planner.existingTags'),
    tagAssignments: Ember.computed.alias('planner.tagAssignments'),
    teams: Ember.computed.alias('application.allTeams'),
    activeProjects: Ember.computed.alias('application.activeProjects'),
    projectSortKeys: _environment.default.constants.PROJECT_SORT_KEYS,
    activeProjectsSorted: Ember.computed.sort('activeProjects', 'projectSortKeys'),
    activeUsers: Ember.computed.alias('application.activeUsers'),
    activeAndShownUsers: Ember.computed('currentUserService.currentUser.uiState.showHiddenUsers', 'activeUsers', function () {
      var showHiddenUsers = this.get('currentUserService.currentUser.uiState.showHiddenUsers');
      return this.get('activeUsers').filter(function (user) {
        return showHiddenUsers || user.get('showInPlanner');
      });
    }),
    sortKeys: _environment.default.constants.USER_SORT_KEYS_WITH_EMPLOYMENT,
    sortedUsers: Ember.computed.sort('activeAndShownUsers', 'sortKeys'),
    showMilestones: Ember.computed.reads('planner.showMilestones'),
    milestones: Ember.computed.reads('planner.milestones'),
    sortedClients: Ember.computed('application.allClients', function () {
      return this.get('clientService').clientsSortedAndGroupedByInactive(this.get('application.allClients'));
    }),
    visibleRows: Ember.computed('sortedUsers', 'sortedUsers.@each.showInPlanner', 'planner.assignments.[]', 'showHiddenUsers', 'selectedFilters.userIds', 'selectedFilters.tagIds', 'selectedFilters.projectIds', 'selectedFilters.clientIds', 'selectedFilters.teamIds', function (user) {
      var _this = this;

      return this.get('sortedUsers').filter(function (user) {
        if (!user.get('showInPlanner') && !_this.get('showHiddenUsers')) return false;

        if (_this.get('canOnlyManageOwnPlanner') && user.get('id') !== _this.get('currentUserService.currentUser.id')) {
          return false;
        }

        var _this$get = _this.get('selectedFilters'),
            users = _this$get.users,
            projects = _this$get.projects,
            clients = _this$get.clients,
            tags = _this$get.tags,
            teams = _this$get.teams;

        var hasUsersFilter = !!users.length;
        var hasProjectsFilter = !!projects.length;
        var hasClientsFilter = !!clients.length;
        var hasTeamFilter = !!teams.length;
        var hasTagsFilter = tags[TAG_MODEL] ? !!tags[TAG_MODEL].length : false;

        if (hasUsersFilter && !users.includes(user)) {
          return false;
        }

        if (hasTagsFilter) {
          var matchesTagFilter = tags[TAG_MODEL].every(function (tag) {
            return tag.get('tagAssignments').toArray().some(function (tagAssignment) {
              return tagAssignment.get('model') === TAG_MODEL && tagAssignment.get('record') === user.get('idAsInt');
            });
          });

          if (!matchesTagFilter) {
            return false;
          }
        }

        var userPlannerAssignments = _this.get('planner.assignments').filter(function (assignment) {
          return assignment.get('user.id') === user.get('id');
        });

        if (hasProjectsFilter) {
          var userHasProjectPlannerAssignment = userPlannerAssignments.some(function (assignment) {
            return projects.includes(assignment.get('project'));
          });

          if (!userHasProjectPlannerAssignment) {
            return false;
          }
        }

        if (hasClientsFilter) {
          var projectsForClients = clients.map(function (client) {
            return client.get('projects').toArray();
          }).flat();
          var userHasClientPlannerAssignment = userPlannerAssignments.some(function (assignment) {
            return projectsForClients.includes(assignment.get('project'));
          });

          if (!userHasClientPlannerAssignment) {
            return false;
          }
        }

        if (hasTeamFilter) {
          var userInTeam = teams.some(function (team) {
            return team.get('id') === user.get('team.id');
          });

          if (!userInTeam) {
            return false;
          }
        }

        return true;
      });
    }),
    canOnlyManageOwnPlanner: Ember.computed.reads('permissions.canOnlyManageOwnPlanner'),
    filterOrder: Ember.computed('canOnlyManageOwnPlanner', function () {
      // in base case, use default values from popover-filter.ts
      return this.get('canOnlyManageOwnPlanner') ? ['projects', 'clients'] : undefined;
    }),
    noVisibleRows: Ember.computed.empty('visibleRows'),
    rowsAreExpanded: Ember.computed.alias('planner.rowsAreExpanded'),
    startDateForTooltip: Ember.computed('viewport.scopeStartDate', function () {
      return this.get('formatter').formatDate(this.get('viewport.scopeStartDate'));
    }),
    endDateForTooltip: Ember.computed('viewport.scopeEndDate', function () {
      return this.get('formatter').formatDate(this.get('viewport.scopeEndDate'));
    }),
    capacitySummaryTooltip: Ember.computed('capacitySummary.planned', 'capacitySummary.capacity', 'viewport.scipeStartDate', 'viewport.scopeEndDate', function () {
      var dateLabelFormat = 'DD. MMM';
      var startDate = this.get('viewport.scopeStartDate');
      var endDate = this.get('viewport.scopeEndDate');
      var i18n = this.get('i18n');
      var listItems = [{
        content: "".concat(this.get('capacitySummary.planned'), " h"),
        subtitle: (0, _string.toLowerCaseStart)(i18n.t('planned'))
      }, {
        content: "".concat(this.get('capacitySummary.capacity'), " h"),
        subtitle: (0, _string.toLowerCaseStart)(i18n.t('free'))
      }];

      if (this.get('capacitySummary.overCapacity') > 0) {
        listItems.push({
          content: "".concat(this.get('capacitySummary.overCapacity'), " h"),
          subtitle: (0, _string.toLowerCaseStart)(this.i18n.t('overCapacity')),
          contentClass: 'over-capacity'
        });
      }

      return (0, _listTooltipBuilder.default)({
        header: "".concat(i18n.t('capacitiesFrom'), " ").concat(startDate.format(dateLabelFormat), " - ").concat(endDate.format(dateLabelFormat)),
        listItems: listItems
      });
    }),
    showCapacityRow: Ember.computed.alias('viewport.showCapacityRow'),
    capacitySummary: {
      planned: 0,
      capacity: 0,
      overCapacity: 0
    },
    updateCapacitySummary: Ember.observer('visibleRows.[]', 'visibleRows.@each.plannerAssignmentsUpdatedAtHash', 'viewport.scopeStartDate', 'viewport.scopeEndDate', function () {
      var _this2 = this;

      var startDate = this.get('viewport.scopeStartDate');
      var endDate = this.get('viewport.scopeEndDate');
      var newCapacitySummary = this.get('visibleRows').flatMap(function (user) {
        return _this2.getCachedAllocatedDays(user, startDate, endDate);
      }).reduce(function (acc, day) {
        acc.planned += day.allocation;
        acc.capacity += Math.max(0, (day.expectedHours || 0) - day.allocation);
        acc.overCapacity += Math.max(0, day.allocation - (day.expectedHours || 0));
        return acc;
      }, {
        planned: 0,
        capacity: 0,
        overCapacity: 0
      });
      newCapacitySummary.planned = Math.round(newCapacitySummary.planned * 100) / 100;
      newCapacitySummary.capacity = Math.round(newCapacitySummary.capacity * 100) / 100;
      newCapacitySummary.overCapacity = Math.round(newCapacitySummary.overCapacity * 100) / 100;
      this.set('capacitySummary', newCapacitySummary);
    }),
    getCachedAllocatedDays: function getCachedAllocatedDays(user, startDate, endDate) {
      var cacheKey = "".concat(user.get('id'), "-").concat(startDate.format(), "-").concat(endDate.format(), "-").concat(user.get('plannerAssignmentsUpdatedAtHash'), "-").concat(user.get('workingHours').length);
      var cacheHit = this.get("userCapacityCache.".concat(cacheKey));

      if (!cacheHit) {
        var allocatedDays = user.allocatedDays(startDate, endDate);
        this.set("userCapacityCache.".concat(cacheKey), allocatedDays);
        return allocatedDays;
      }

      return cacheHit;
    },
    userCapacityCache: {},
    // This would be more at home in the Viewport service, but the behavior only applies to the Team view
    // Observer was not working correctly when listening on 'zoom.zoomedIn'
    updateViewportOnZoom: Ember.observer('viewport.dayWidth', function () {
      if (this.get('zoom.zoomedIn') && !this.get('viewport.linkScopeToViewport') && this.get('viewport.scopeEndDate').isAfter(this.get('viewport.endDate'))) {
        this.get('viewport').set('startDate', this.get('viewport.scopeStartDate'));
      }
    }),
    selectedFilters: new _popoverFilter.SelectedFilters(),
    actions: {
      customAction: function customAction(actionName, data) {
        this.send(actionName, data);
      },
      billProject: function billProject(data) {
        this.get('application').send('billProject', data.projectId);
      },
      completeProject: function completeProject(data) {
        this.get('application').send('completeProject', data.projectId);
      },
      showTimeEntries: function showTimeEntries() {
        var _this$get2;

        (_this$get2 = this.get('application')).send.apply(_this$get2, ['showTimeEntries'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });

  _exports.default = _default;
});