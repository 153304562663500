define("coffeecup/components/input/cc-checkbox-with-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    initialValue: null,
    valueHasChanged: Ember.computed('value', 'initialValue', function () {
      return this.get('value') !== this.get('initialValue');
    }),
    didInsertElement: function didInsertElement() {
      this.set('initialValue', this.get('value'));
    },
    showConfirmation: function showConfirmation() {
      var _this = this;

      this.set('modalOptions', {
        icon: this.get('modalIcon'),
        title: this.get('modalTitle'),
        text: this.get('modalText'),
        closeBtnText: this.get('i18n').t('cancel'),
        confirmBtnText: this.get('i18n').t('OK'),
        confirmCallback: function confirmCallback(cb) {
          _this.onChange();
        },
        cancelCallback: function cancelCallback(cb) {
          _this.set('value', _this.get('initialValue'));
        },
        hideCallback: function hideCallback(cb) {
          _this.set('value', _this.get('initialValue'));
        }
      });
      this.set('showModal', true);
    },
    actions: {
      onConfirm: function onConfirm() {
        var _this2 = this;

        Ember.run.next(function () {
          // wait till the value change is emitted to the app
          _this2.showConfirmation();
        });
      },
      hideModal: function hideModal() {
        this.set('showModal', false);
      }
    }
  });

  _exports.default = _default;
});