define("coffeecup/components/planner/stretchable-overlay", ["exports", "coffeecup/components/planner/timeline-overlay", "coffeecup/utils/allocation-math"], function (_exports, _timelineOverlay, _allocationMath) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _timelineOverlay.default.extend({
    attributeBindings: ['width'],
    classNameBindings: ['beingSelected'],
    width: null,
    beingSelected: false,
    user: null,
    isResizeSetup: false,
    viewport: Ember.inject.service('planner.viewport'),
    workingDays: Ember.inject.service(),
    formatter: Ember.inject.service(),
    startPosition: 0,
    selectedAllocation: 1,
    totalAllocation: 1,
    tooltipContent: '-',
    // cannot be an empty string, tooltipster won't initialize if it's empty
    tooltipsterInstance: null,
    setupResize: function () {
      var _tooltipsterInstance,
          _this = this;

      var assignmentWidth = this.get('viewport.assignmentWidth');
      var tooltipsterInstance = this.get('tooltipsterInstance');

      if (!(tooltipsterInstance && typeof tooltipsterInstance.tooltipster === 'function')) {
        tooltipsterInstance = this.$().find('.tooltipster').tooltipster('instance');
      }

      this.set('rowElementPositionY', this.$().closest('.content-child-row').offset().top);
      this.set('tooltipsterInstance', tooltipsterInstance);
      (_tooltipsterInstance = tooltipsterInstance) === null || _tooltipsterInstance === void 0 ? void 0 : _tooltipsterInstance.on('reposition', function () {
        var _tooltipsterInstance2;

        (_tooltipsterInstance2 = tooltipsterInstance) === null || _tooltipsterInstance2 === void 0 ? void 0 : _tooltipsterInstance2._$tooltip.css({
          top: _this.get('rowElementPositionY') - 11,
          left: _this.get('left') + 345,
          height: 68
        });
      });
      this.$().closest('.add-assignment').resizable({
        handles: 'e,sq,ne',
        grid: [assignmentWidth, 0],
        animate: false,
        start: Ember.run.bind(this, function (event, element) {
          this.set('startPosition', event.screenY);
          this.set('beingSelected', true);
          this.set('viewport.selectedDay', this.get('startDate'));
          this.get('tooltipsterInstance').open();
          this.updateTooltipContent();
        }),
        stop: Ember.run.bind(this, function (event, element) {
          var _this2 = this;

          if (this.get('beingSelected')) {
            event.preventDefault();
            this.setProperties({
              width: element.size.width
            });
            this.get('tooltipsterInstance').close();
            this.get('viewport').selectDay(this.get('endDate'));
            this.sendAction('onSelect', this.get('startDate'), this.get('endDate'), this.get('selectedAllocation'));
            Ember.run.next(this, function () {
              return _this2.set('beingSelected', false);
            });
            Ember.run.later(this, function () {
              _this2.setProperties({});

              _this2.set('selectedAllocation', _this2.get('allocation'));

              _this2.set('totalAllocation', _this2.get('allocation'));

              _this2.set('width', _this2.get('viewport.assignmentWidth')), _this2.moveOverlay(event);
            }, 1000);
          }
        }),
        resize: Ember.run.bind(this, function (event, ui) {
          var allocation = this.get('allocation');
          var verticalAdjustment = Math.round((this.get('startPosition') - event.screenY) / ui.originalSize.height * 2);
          var selectedAllocation = Math.min(24, Math.max(1, allocation + verticalAdjustment));
          var totalDays = this.calcTotalDays();
          ui.size.height = ui.originalSize.height;
          ui.position.top = ui.originalPosition.top;
          this.updateTooltipContent();
          this.set('width', ui.size.width);
          this.set('selectedAllocation', selectedAllocation);
          this.set('totalAllocation', _allocationMath.default.multiplyAllocation(selectedAllocation, totalDays));
        })
      });
    }.on('didInsertElement'),
    updateTooltipContent: function updateTooltipContent() {
      var i18n = this.get('i18n');
      this.set('tooltipContent', "\n      <div class=\"cc-planner-tooltip\">\n        <div class=\"h3 no-space d-flex align-items-baseline \">\n          <div class=\"leading-content\">".concat(this.get('selectedAllocation'), "</div>\n          <div class=\"smaller\">").concat(i18n.t('hoursPerDay'), "</div>\n        </div>\n        <div class=\"small-space-top\"></div>\n          <div class=\"h3 no-space d-flex align-items-baseline\">\n          <div class=\"leading-content\">").concat(this.get('totalAllocation'), "</div>\n          <div class=\"smaller\">").concat(i18n.t('hoursOfDuration'), "</div>\n        </div>\n      </div>"));
    },
    mouseEnter: function mouseEnter() {
      var user = this.get('user');
      var startDate = this.get('startDate');
      var endDate = this.get('endDate');
      var allocatedDays = user.allocatedDays(startDate, endDate);
      var firstFoundAllocatedDay = allocatedDays && allocatedDays[0];

      if (!firstFoundAllocatedDay) {
        this.get('notifications').error('missingUserEmploymentForDate', {
          firstname: user.get('firstname'),
          date: startDate.format('L')
        }, {
          key: 'missingUserEmploymentForDate',
          clearDuration: 3000
        });
        return false;
      }

      var allocation = 1;

      if (firstFoundAllocatedDay && Ember.computed.notEmpty(firstFoundAllocatedDay['expectedHours']) && Ember.computed.notEmpty(firstFoundAllocatedDay['allocation'])) {
        allocation = Math.max(1, firstFoundAllocatedDay['expectedHours'] - firstFoundAllocatedDay['allocation']);
      }

      this.set('allocation', allocation);
      this.set('selectedAllocation', allocation);
      this.set('totalAllocation', allocation);
    },
    assignmentWidthObserver: Ember.observer('viewport.assignmentWidth', function () {
      if (this.get('isResizeSetup')) {
        this.$().resizable('option', 'grid', [this.get('viewport.assignmentWidth'), 0]);
      }
    }),
    calcTotalDays: function calcTotalDays() {
      var user = this.get('user');
      var startDate = this.get('startDate');
      var endDate = this.get('endDate');
      var absences = this.get('user.absences');
      return this.get('workingDays').getWorkingDaysOfTimePeriod(user, startDate, endDate, absences);
    },
    click: function click(event) {
      if (!this.get('beingSelected')) {
        // TODO-RH - Check if this needs to be set back to startDate/startDate for Daily planning mode
        this.sendAction('onSelect', this.get('startDate'), this.get('endDate'));
      }
    },
    teardownResize: function () {
      this.$().resizable('destroy');
    }.on('willDestroyElement'),
    startDate: Ember.computed('viewport.dayWidth', 'viewport.showWeekends', 'viewport.startDate', 'left', function () {
      var offset = this.get('left') / this.get('viewport.dayWidth');
      return this.get('viewport').shiftDate(this.get('viewport.startDate'), offset);
    }),
    endDate: Ember.computed('viewport.dayWidth', 'viewport.showWeekends', 'startDate', 'width', function () {
      var elementWidth = this.get('width') / this.get('viewport.dayWidth');
      return this.get('viewport').shiftDate(this.get('startDate'), elementWidth - 1);
    }),
    moveOverlay: function moveOverlay(event) {
      if (!this.get('beingSelected')) {
        this.set('width', this.get('viewport.assignmentWidth'));

        this._super(event);
      }
    },
    style: Ember.computed('left', 'width', 'projectColor', 'viewport.assignmentWidth', function () {
      var left = this.get('left');
      var width = this.get('width') || this.get('viewport.assignmentWidth');
      var backgroundColor = this.get('projectColor');
      var elementStyle = "left: ".concat(left, "px; width: ").concat(width, "px; background-color: ").concat(backgroundColor, ";");
      return Ember.String.htmlSafe(elementStyle);
    })
  });

  _exports.default = _default;
});