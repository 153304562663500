define("coffeecup/components/fsg-list", ["exports", "coffeecup/utils/string", "coffeecup/mixins/fsg-deferred", "coffeecup/config/environment"], function (_exports, _string, _fsgDeferred, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // helpers
  var customComputed = {
    keys: function keys(sourceKey) {
      return Ember.computed(sourceKey + '.[]', {
        get: function get() {
          var source = this.get(sourceKey);

          if (Ember.isArray(source) && source.length > 0) {
            return source;
          }
        }
      });
    },
    fn: function fn(sourceKey) {
      return Ember.computed(sourceKey, {
        get: function get() {
          var source = this.get(sourceKey);

          if (typeof source === 'function') {
            return source;
          }
        }
      });
    }
  };
  var FilteredSortedGroupedListComponent = Ember.Component.extend(_fsgDeferred.default, {
    tagName: 'tbody',
    list: [],
    // itemPartial
    itemPartial: '',
    isDefaultUser: null,
    showCommentType: _environment.default.constants.COMMENT_COMPLETE_LINE_BREAKS,
    actions: {
      _selectItem: function _selectItem() {
        // Bubble action up to the controller
        if (this.get('onClick')) {
          // Only for testing
          if (typeof DummyENV !== 'undefined') {
            if (DummyENV.environment === 'test' && window._counter >= 0) {
              window._counter += 1;
            }
          }

          this.sendAction.bind(this, 'onClick').apply(this, arguments);
        }
      },

      /**
       * @usage: {{action 'buttonClick' 'pauseTimeEntry' item bubbles=false}}
       *
       * @param action
       * @param item
       * @returns {boolean}
       */
      buttonClick: function buttonClick(action) {
        var args = [];

        for (var i = 1; i < arguments.length; i++) {
          args.push(arguments[i]);
        } // Trigger action of controller or route


        this.triggerAction({
          action: action,
          actionContext: args
        });
        return false;
      }
    },
    // ---------- filter
    filterTerm: null,
    // filter can be a function of an array of list item keys
    // function : function(item, index, list)
    // keys     : ['id', 'name']
    filterBy: [],
    _filterKeys: customComputed.keys('filterBy'),
    _filterFn: customComputed.fn('filterBy'),
    _defaultFilterFn: function _defaultFilterFn(item) {
      var getValue = function getValue(key) {
        return item.get(key);
      };

      var stack = (0, _string.purify)(this.get('_filterKeys').map(getValue).join(''));
      var needle = (0, _string.purify)(this.get('filterTerm'));
      return stack.indexOf(needle) > -1;
    },
    _fList: Ember.computed('list.[]', 'filterTerm', '_filterKeys', '_filterFn', {
      get: function get() {
        var list = this.get('list').toArray();

        if (!this.get('filterTerm')) {
          return list;
        }

        if (this.get('_filterKeys')) {
          return list.filter(this.get('_defaultFilterFn').bind(this));
        }

        if (this.get('_filterFn')) {
          return list.filter(this.get('_filterFn').bind(this));
        }

        return list;
      }
    }),
    // ---------- sort
    sortBy: [],
    _sortKeys: customComputed.keys('sortBy'),
    _sortFn: customComputed.fn('sortBy'),
    _defaultSortFn: function _defaultSortFn(a, b) {
      var compareValue;
      this.get('_sortKeys').some(function (metaKey) {
        var keys = metaKey.split(':');
        var key = keys[0];
        var asc = keys[1] === 'desc' ? -1 : 1;
        var propA = a.get(key);
        var propB = b.get(key);
        compareValue = Ember.compare(propA, propB) * asc;
        return compareValue;
      });
      return compareValue || 0;
    },
    _fsList: Ember.computed('_fList', '_sortKeys', '_sortFn', {
      get: function get() {
        var list = this.get('_fList').toArray();

        if (this.get('_sortKeys')) {
          return list.sort(this.get('_defaultSortFn').bind(this));
        }

        if (this.get('_sortFn')) {
          return list.sort(this.get('_sortFn').bind(this));
        }

        return list;
      }
    }),
    // ---------- group
    groupBy: null,
    groupTitleAttrs: [],
    _fsgList: Ember.computed('_fsList', 'groupBy', 'analytics.groupedBy', {
      get: function get() {
        var groupBy = this.get('groupBy');
        var list = this.get('_fsList').toArray();

        if (!groupBy) {
          return list;
        }

        var groups = this._groupsFromList(list, groupBy);

        var fsgList = this._listFromGroups(groups);

        return fsgList;
      }
    }),
    _groupsFromList: function _groupsFromList(list, groupBy) {
      var addItemToGroup = function addItemToGroup(groups, item) {
        var key = groupBy(item);

        if (groups[key]) {
          groups[key].push(item);
        } else {
          groups[key] = [item];
        }

        return groups;
      };

      return list.reduce(addItemToGroup, {});
    },
    _listFromGroups: function _listFromGroups(groups) {
      var _this = this;

      var groupedAnalytics = this.get('analytics.groupedBy') || [];
      var component = this;
      var list = [];

      var _loop = function _loop(key) {
        var _groupAnalytics$hours, _groupAnalytics, _groupAnalytics$amoun, _groupAnalytics2, _groupAnalytics$hours2, _groupAnalytics3;

        var groupAnalytics = void 0;
        var img = void 0;
        var object = void 0;
        var keyId = void 0;
        var url = void 0;

        switch (_this.get('criteriaNumber')) {
          case _environment.default.constants.GROUP_BY_DATE:
            img = '';
            keyId = groups[key][0].get('day').format('YYYY-MM-DD');
            groupAnalytics = groupedAnalytics.find(function (_ref) {
              var day = _ref.day;
              return day.slice(0, 10) === keyId;
            });
            break;

          case _environment.default.constants.GROUP_BY_CLIENT:
            img = Ember.String.htmlSafe("<span class=\"icon icomoon-business\" style=\"margin-right: 5px\"></span>");
            keyId = groups[key][0].get('project.client.id');
            groupAnalytics = groupedAnalytics.find(function (_ref2) {
              var client = _ref2.client;
              return client == keyId;
            });
            break;

          case _environment.default.constants.GROUP_BY_PROJECT:
            img = Ember.String.htmlSafe("<span class=\"icon icomoon-projects\" style=\"margin-right: 5px\"></span>");
            keyId = groups[key][0].get('project.id');
            groupAnalytics = groupedAnalytics.find(function (_ref3) {
              var project = _ref3.project;
              return project == keyId;
            });
            break;

          case _environment.default.constants.GROUP_BY_STAFF:
            object = _this.get('list').toArray().find(function (elem) {
              return elem.get('user.label') === key;
            });
            img = Ember.String.htmlSafe("<span class=\"super-extra-small\">".concat(object.get('user.imageExtraSmallHTML'), "</span>"));
            keyId = groups[key][0].get('user.id');
            groupAnalytics = groupedAnalytics.find(function (_ref4) {
              var user = _ref4.user;
              return user == keyId;
            });
            break;

          case _environment.default.constants.GROUP_BY_TEAM:
            img = Ember.String.htmlSafe("<span class=\"icon icomoon-team\" style=\"margin-right: 5px\"></span>");
            keyId = groups[key][0].get('team.id');
            groupAnalytics = groupedAnalytics.find(function (_ref5) {
              var team = _ref5.team;
              return team == keyId;
            });
            break;

          case _environment.default.constants.GROUP_BY_TASK:
            img = Ember.String.htmlSafe("<span class=\"icon icomoon-circle-check\" style=\"margin-right: 5px\"></span>");
            keyId = groups[key][0].get('task.id');
            groupAnalytics = groupedAnalytics.find(function (_ref6) {
              var task = _ref6.task;
              return task == keyId;
            });
            break;

          case _environment.default.constants.GROUP_BY_REFERENCE:
            object = _this.get('list').toArray().find(function (elem) {
              return elem.get('timeEntryReference.shortCodeAndPageTitle') === key;
            });
            img = object !== undefined ? Ember.String.htmlSafe("<span style=\"margin-right: 5px\">".concat(object.get('timeEntryReference.platformImageHTML'), "</span>")) : '';
            keyId = groups[key][0].get('timeEntryReference.id');
            groupAnalytics = groupedAnalytics.find(function (_ref7) {
              var timeEntryReference = _ref7.timeEntryReference;
              return timeEntryReference == keyId;
            });
            url = groups[key][0].get('timeEntryReference.url');
            break;

          default:
            img = '';
            keyId = undefined;
            break;
        }

        titleObj = Ember.Object.create({
          _isTitle: true,
          _title: key !== 'undefined' ? key : _this.get('i18n').t('noReferencesEnabled'),
          _img: img,
          _billable: (_groupAnalytics$hours = (_groupAnalytics = groupAnalytics) === null || _groupAnalytics === void 0 ? void 0 : _groupAnalytics.hoursSpent) !== null && _groupAnalytics$hours !== void 0 ? _groupAnalytics$hours : 0,
          _amount: (_groupAnalytics$amoun = (_groupAnalytics2 = groupAnalytics) === null || _groupAnalytics2 === void 0 ? void 0 : _groupAnalytics2.amountSpent) !== null && _groupAnalytics$amoun !== void 0 ? _groupAnalytics$amoun : 0,
          _tracked: (_groupAnalytics$hours2 = (_groupAnalytics3 = groupAnalytics) === null || _groupAnalytics3 === void 0 ? void 0 : _groupAnalytics3.hoursTotal) !== null && _groupAnalytics$hours2 !== void 0 ? _groupAnalytics$hours2 : 0,
          _keyId: keyId,
          _timeEntryReferenceUrl: url
        });
        component.get('groupTitleAttrs').forEach(function (attr) {
          var fn = component.get(attr);
          titleObj[attr] = fn(key, component);
        });
        list.pushObject(titleObj);
        list.pushObjects(groups[key]);
      };

      for (var key in groups) {
        var titleObj;

        _loop(key);
      }

      return list;
    },
    divideBy: null,
    _fsgdList: Ember.computed('_fsgList', 'divider', {
      get: function get() {
        var divideBy = this.get('divideBy');
        var isDisabledBy = this.get('isDisabled');
        var list = this.get('_fsgList').toArray();

        if (!divideBy) {
          return list;
        }

        return this._addDividerToList(list, divideBy, isDisabledBy);
      }
    }),
    _addDividerToList: function _addDividerToList(list, divideBy, isDisabledBy) {
      var listWithDivder = Ember.A();
      list.forEach(function (item) {
        listWithDivder.pushObject(item);
        var divider = divideBy(item);
        var disabled = false;

        if (typeof isDisabledBy === 'function') {
          disabled = isDisabledBy(item);
        }

        if (divider) {
          var dividerObj = Ember.Object.create({
            _isDivider: true,
            _divider: divider,
            id: item.get('id'),
            disabled: disabled
          });
          listWithDivder.pushObject(dividerObj);
        }
      });
      return listWithDivder;
    },
    fsgList: Ember.computed.alias('_fsgdList')
  });
  var _default = FilteredSortedGroupedListComponent;
  _exports.default = _default;
});