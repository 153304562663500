define("coffeecup/controllers/planner/absences/calendar/feed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    needs: ['application'],

    /**
     * calendar-feed
     */
    model: null,

    /**
     * set of actions
     */
    actions: {
      revokeFeed: function revokeFeed() {
        var _this = this;

        this.model.destroyRecord().then(function () {
          return _this.send('refreshCalendarFeed');
        });
      },
      onCopySuccess: function onCopySuccess() {
        this.get('notifications').success('linkCopied');
      },
      onHide: function onHide() {
        // Navigate away from route
        this.send('transitionToList');
      }
    }
  });

  _exports.default = _default;
});