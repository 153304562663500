define("coffeecup/components/pagination-sorter", ["exports", "coffeecup/mixins/empty-action", "coffeecup/config/environment"], function (_exports, _emptyAction, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emptyAction.default, {
    classNames: 'btn-group',
    sort: _environment.default.constants.SORT_ASC,
    sortOptions: Ember.A([_environment.default.constants.SORT_ASC, _environment.default.constants.SORT_DESC]),
    total: null,
    limit: null,
    page: null,
    from: Ember.computed('page', 'limit', {
      get: function get() {
        var page = this.get('page');
        var limit = this.get('limit');
        return page * limit + 1;
      }
    }),
    to: Ember.computed('page', 'limit', 'total', {
      get: function get() {
        var page = this.get('page');
        var limit = this.get('limit');
        var total = this.get('total');
        return Math.min(total, page * limit + limit);
      }
    }),
    actions: {
      setSortOption: function setSortOption(sort) {
        this.set('sort', sort);
      }
    }
  });

  _exports.default = _default;
});