define("coffeecup/components/input-date-range", ["exports", "coffeecup/components/date-range-picker"], function (_exports, _dateRangePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dateRangePicker.default.extend({
    formatter: Ember.inject.service(),
    formattedStartEndDate: Ember.computed('start', 'end', function () {
      var formatter = this.get('formatter');
      return "".concat(formatter.formatDate(this.get('start')), " - ").concat(formatter.formatDate(this.get('end')));
    }),
    _updateFormattedDateRange: Ember.observer('start', 'end', function () {
      this.sendAction('updateFormattedDateRange', this.get('formattedDateRange'));
    }),
    ranges: Ember.computed(function () {
      var ranges = {};
      ranges[this.get('i18n').t('thisMonth')] = [this.get('clock.now').startOf('month'), this.get('clock.now').endOf('month'), 'thisMonth'];
      var startOfLastMonth = this.get('clock.now').subtract(1, 'month').startOf('month');
      ranges[this.get('i18n').t('lastMonth')] = [startOfLastMonth, startOfLastMonth.clone().endOf('month'), 'lastMonth'];
      ranges[this.get('i18n').t('thisQuarter')] = [this.get('clock.now').startOf('quarter'), this.get('clock.now').endOf('quarter'), 'thisQuarter'];
      ranges[this.get('i18n').t('thisYear')] = [this.get('clock.now').startOf('year'), this.get('clock.now').endOf('year'), 'thisYear'];
      var startOfLastYear = this.get('clock.now').subtract(1, 'year').startOf('year');
      ranges[this.get('i18n').t('lastYear')] = [startOfLastYear, startOfLastYear.clone().endOf('year'), 'lastYear'];
      return ranges;
    }),
    didInsertElement: function didInsertElement() {
      this._super();

      this._updateFormattedDateRange();
    }
  });

  _exports.default = _default;
});