define("coffeecup/components/modal-dialog", ["exports", "ember-modal-dialog/components/modal-dialog", "ember-keyboard"], function (_exports, _modalDialog, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _modalDialog.default.extend(_emberKeyboard.EKMixin, {
    overrideOutsideClick: false,
    preventCloseOnEscape: false,
    dismissibleByBackdrop: true,
    clickOutsideToClose: Ember.computed('overrideOutsideClick', 'dismissibleByBackdrop', function () {
      return this.get('overrideOutsideClick') && this.get('dismissibleByBackdrop');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('keyboardActivated', true);
    },
    allWrapperClasses: Ember.computed('wrapperClassNames', 'wrapperClass', function () {
      var wrapperClasses = this.wrapperClassNames;

      if (this.wrapperClass) {
        wrapperClasses = [].concat(_toConsumableArray(this.wrapperClassNames), [this.wrapperClass]);
      }

      return ".".concat(wrapperClasses.join('.'));
    }),
    willDestroyElement: function willDestroyElement() {
      document.removeEventListener('click', this.handleClick);

      this._super.apply(this, arguments);

      document.querySelector(this.allWrapperClasses).removeEventListener('mousedown', Ember.run.bind(this, this.mousedownHandler), true);
      document.querySelector(this.allWrapperClasses).removeEventListener('mouseup', Ember.run.bind(this, this.mouseupHandler), true);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      // This click handler prevents modals from closing based on elements that are not in the dom of the modal
      // but contain content for the modal like date pickers or dropdowns
      this.handleClick = function (_ref) {
        var target = _ref.target;

        // if the click has already resulted in the target
        // being removed or hidden, do nothing
        if (_this.isDestroying || _this.isDestroyed) {
          return;
        }

        if (target.closest('#date-picker-container') || target.closest('#ember-basic-dropdown-wormhole')) {
          _this.set('overrideOutsideClick', false);

          Ember.run.later(function () {
            _this.set('overrideOutsideClick', true);
          }, 100);
        }
      };

      var registerClick = function registerClick() {
        return document.addEventListener('click', _this.handleClick);
      };

      setTimeout(registerClick);
      document.querySelector(this.allWrapperClasses).addEventListener('mouseup', Ember.run.bind(this, this.mouseupHandler));
      document.querySelector(this.allWrapperClasses).addEventListener('mousedown', Ember.run.bind(this, this.mousedownHandler));

      this._super.apply(this, arguments);
    },
    mousedownHandler: function mousedownHandler(e) {
      if (e.target.closest('.ember-modal-dialog')) {
        this.set('overrideOutsideClick', false);
      }
    },
    mouseupHandler: function mouseupHandler(e) {
      if (!this.get('isDestroyed')) {
        this.set('overrideOutsideClick', true);
      }
    },
    closeOnEsc: Ember.on((0, _emberKeyboard.keyDown)('Escape'), function () {
      if (this.get('preventCloseOnEscape')) return;
      var onClose = this.get('onClose');
      onClose && typeof onClose === 'function' && onClose();
    }),
    actions: {
      onClose: function onClose(e) {
        var _this2 = this;

        Ember.run.next(function () {
          if (_this2.get('clickOutsideToClose')) {
            _this2.sendAction('onClose');
          }
        });
      },
      onClickOverlay: function onClickOverlay(e) {
        e.preventDefault();

        if (this.get('onClickOverlay') && this.get('clickOutsideToClose')) {
          this.sendAction('onClickOverlay');
        }
      }
    }
  });

  _exports.default = _default;
});