define("coffeecup/components/pagination-limiter", ["exports", "coffeecup/mixins/empty-action"], function (_exports, _emptyAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emptyAction.default, {
    classNames: 'btn-group',
    limit: 100,
    limitOptions: Ember.A([100, 200, 300]),
    actions: {
      setLimitOption: function setLimitOption(limit) {
        this.set('limit', limit);
      }
    }
  });

  _exports.default = _default;
});