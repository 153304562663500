define("coffeecup/components/analytics-module/user/performance", ["exports", "coffeecup/config/environment", "coffeecup/utils/build-url"], function (_exports, _environment, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    userCosts: null,
    userRevenueInBudget: 0,
    userRevenueOutOfBudget: 0,
    userRevenueTotal: 0,
    selectedYear: moment().format('YYYY'),
    showAllData: false,
    didInsertElement: function didInsertElement() {
      this._updateProperties();
    },
    hourlyRate: 0,
    _updatePropertiesObserver: Ember.observer('user', 'selectedYear', 'showAllData', function () {
      Ember.run.once(this, '_updateProperties');
    }),
    _updateProperties: function _updateProperties() {
      var _this = this;

      var selectedYear = this.get('selectedYear').toString();
      var emplyomentStart = this.get('user.firstEmploymentEntry.validFrom');
      var startOfYear = moment(selectedYear).startOf('year');
      var startDate = this.get('showAllData') ? emplyomentStart : startOfYear;
      var endDate = this.get('showAllData') || moment().format('YYYY') === selectedYear ? moment().endOf('month') : moment(selectedYear).endOf('year');
      var updateOptions = {
        user: this.get('user.id'),
        start_date: startDate.format(_environment.default.constants.DATE_FORMAT_DAY),
        end_date: endDate.format(_environment.default.constants.DATE_FORMAT_DAY),
        prev_start_date: startDate.format(_environment.default.constants.DATE_FORMAT_DAY),
        prev_end_date: endDate.format(_environment.default.constants.DATE_FORMAT_DAY)
      };
      this.get('session').getJSON((0, _buildUrl.default)(this.get('store'), 'analytics/users/costs/aggregated', updateOptions)).then(function (userCosts) {
        _this.set('hourlyRate', userCosts.hourlyRate);

        _this.set('userCosts', userCosts);

        _this.set('userRemunerationCosts', userCosts.current.employees + userCosts.current.freelancers);

        _this.set('userTotalCosts', _this.get('userRemunerationCosts') + userCosts.current.overtimeReduction);
      });
      this.get('session').getJSON((0, _buildUrl.default)(this.get('store'), 'analytics/users/revenue/monthly/' + selectedYear, {
        user: this.get('user.id'),
        cachedRevenues: false // Change this flag to get the cached revenues

      })).then(function (userRevenue) {
        _this.set('userRevenueInBudget', userRevenue.reduce(function (prev, el) {
          return prev + el.amount;
        }, 0));

        _this.set('userRevenueTotal', userRevenue.reduce(function (prev, el) {
          return prev + el.totalAmount;
        }, 0));

        _this.set('userRevenueOutOfBudget', userRevenue.reduce(function (prev, el) {
          return prev + Math.round(el.amountOutOfBudget * 100) / 100;
        }, 0));
      });
    },
    yearPickerCustomElement: Ember.computed(function () {
      var validFrom = this.get('user.firstEmploymentEntry.validFrom') || moment();
      return validFrom.format('YYYY') + ' - ' + moment().format('YYYY');
    }),
    userProfitability: Ember.computed('userRevenueInBudget', 'userCosts.previous.amount', function () {
      var userRevenue = this.get('userRevenueInBudget') || 0;
      var userCosts = this.get('userCosts.previous.amount') || 0;
      return userRevenue - userCosts;
    }),
    isNotProfitable: Ember.computed('userProfitability', function () {
      return this.get('userProfitability') < 0;
    }),
    profitabilityPercentage: Ember.computed('userProfitability', 'userCosts.previous.amount', function () {
      var userProfitability = this.get('userProfitability') || 0;
      var userCosts = this.get('userCosts.previous.amount') || 0;
      return userCosts !== 0 ? userProfitability / userCosts : 0;
    }),
    pieChartData: Ember.computed('profitabilityPercentage', function () {
      var profitabilityPercentage = Math.abs(this.get('profitabilityPercentage'));

      if (profitabilityPercentage !== 0) {
        return [{
          value: 1 - profitabilityPercentage
        }, {
          value: profitabilityPercentage
        }];
      } else {
        return [{
          value: 1
        }, {
          value: 0
        }];
      }
    }),
    actions: {
      yearPickerCustomElementAction: function yearPickerCustomElementAction(value) {
        this.set('showAllData', value);
      }
    }
  });

  _exports.default = _default;
});