define("coffeecup/app", ["exports", "coffeecup/resolver", "ember-load-initializers", "coffeecup/config/environment", "coffeecup/sentry"], function (_exports, _resolver, _emberLoadInitializers, _environment, _sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  (0, _sentry.startSentry)();
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,
    destroy: function destroy() {
      this._super.apply(this, arguments);

      if (Ember.I18n != null) {
        Ember.I18n.destroy();
        Ember.I18n = null;
      }
    }
  });

  if (_environment.default.environment === 'test' || _environment.default.environment === 'development') {
    Ember.run.backburner.DEBUG = true;
  }

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});