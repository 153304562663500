define("coffeecup/adapters/analytics/project", ["exports", "coffeecup/adapters/-super-sails-rest"], function (_exports, _superSailsRest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //adapter/analytics/project.js
  var _default = _superSailsRest.default.extend({
    findRecord: function findRecord(store, type, id, snapshot) {
      var url = this.buildURL(type.modelName, null, snapshot, 'findRecord');
      var query = this.buildQuery(snapshot);
      query.project = id;
      return this.ajax(url, 'GET', {
        data: query
      });
    }
  });

  _exports.default = _default;
});