define("coffeecup/components/analytics/time-entries/list-row", ["exports", "coffeecup/config/environment", "coffeecup/components/fsg-row"], function (_exports, _environment, _fsgRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var AnalyticsTimeEntriesListRowComponent = _fsgRow.default.extend({
    router: Ember.inject.service(),
    timeEntryService: Ember.inject.service('time-entry'),
    tagName: 'div',
    projectIsBillable: Ember.computed.not('item.project.isNotBillable'),
    showBillingDetails: Ember.computed.and('currentUserIsPmOfTimeEntry', 'projectIsBillable'),
    currentUserIsPmOfTimeEntry: false,
    showStartAndEndTime: Ember.computed.and('item.hasStartAndEndTime'),
    groupByDate: _environment.default.constants.GROUP_BY_DATE,
    groupByClient: _environment.default.constants.GROUP_BY_CLIENT,
    groupByProject: _environment.default.constants.GROUP_BY_PROJECT,
    groupByStaff: _environment.default.constants.GROUP_BY_STAFF,
    groupByTeam: _environment.default.constants.GROUP_BY_TEAM,
    groupByTask: _environment.default.constants.GROUP_BY_TASK,
    groupByReference: _environment.default.constants.GROUP_BY_REFERENCE,
    COMMENT_COMPLETE_LINE_BREAKS: _environment.default.constants.COMMENT_COMPLETE_LINE_BREAKS,
    COMMENT_COMPLETE: _environment.default.constants.COMMENT_COMPLETE,
    COMMENT_COMPACT: _environment.default.constants.COMMENT_COMPACT,
    COMMENT_ICON_ONLY: _environment.default.constants.COMMENT_ICON_ONLY,
    clientTooltip: "",
    projectTooltip: "",
    teamTooltip: "",
    staffTooltip: "",
    referenceTooltip: "",
    taskTooltip: '',
    createTooltipFor: function createTooltipFor(columnName, clickTranslation, optionAndClickTranslation) {
      return Ember.String.htmlSafe("\n        <div style=\"padding: 12px\">\n            <div style=\"font-weight: 500; font-size: 14px; line-height: 150%;\">".concat(this.get('item').get(columnName), "</div>\n            ").concat(this.get('isTooltipHintsVisible') ? "\n                <div style=\"margin-top: 14px\">\n                  ".concat(clickTranslation ? "<div class=\"tooltip-list-row-button-line\">\n                         <div class=\"tooltip-list-row-button\">".concat(this.get('i18n').t('click'), "</div>\n                         <span>").concat(this.get('i18n').t('onTheItemToAddAFilter', {
        what: this.get('i18n').t(clickTranslation)
      }), "</span>\n                       </div>") : '', "\n                  ").concat(optionAndClickTranslation ? "<div class=\"tooltip-list-row-button-line\">\n                         <div class=\"tooltip-list-row-button\">".concat(this.get('i18n').t('option'), "</div>\n                         <div class=\"tooltip-list-row-button\">").concat(this.get('i18n').t('click'), "</div>\n                         <span>").concat(this.get('i18n').t('onTheItemToMoveTo', {
        what: this.get('i18n').t(optionAndClickTranslation)
      }), "</span>\n                       </div>") : '', "\n                </div>") : '', "\n        </div>"));
    },

    get groupHeaderTooltip() {
      var groupHeader;

      if (this.get('item._isTitle')) {
        switch (this.get('criteriaNumber')) {
          case _environment.default.constants.GROUP_BY_CLIENT:
            groupHeader = this.createTooltipFor('_title', 'aClient', 'clientAnalytics');
            break;

          case _environment.default.constants.GROUP_BY_PROJECT:
            groupHeader = this.createTooltipFor('_title', 'aProject', 'projectAnalyticsSmall');
            break;

          case _environment.default.constants.GROUP_BY_STAFF:
            groupHeader = this.createTooltipFor('_title', 'aStaff', 'staffAnalytics');
            break;

          case _environment.default.constants.GROUP_BY_TEAM:
            groupHeader = this.createTooltipFor('_title', 'aTeam', 'teamSettings');
            break;

          case _environment.default.constants.GROUP_BY_TASK:
            groupHeader = this.createTooltipFor('_title', 'aTask');
            break;

          case _environment.default.constants.GROUP_BY_REFERENCE:
            groupHeader = this.createTooltipFor('_title', 'aReference', 'reference');
            break;

          default:
            break;
        }
      }

      return groupHeader;
    },

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('isItem')) {
        var item = this.get('item');
        this.get('timeEntryService').currentUserIsPmOfTimeEntry(item).then(function (result) {
          _this.set('currentUserIsPmOfTimeEntry', !!result);
        });
        this.set('clientTooltip', this.createTooltipFor('project.clientName', 'aClient', 'clientAnalytics'));
        this.set('projectTooltip', this.createTooltipFor('project.label', 'aProject', 'projectAnalyticsSmall'));
        this.set('teamTooltip', this.createTooltipFor('team.label', 'aTeam', 'teamSettings'));
        this.set('staffTooltip', this.createTooltipFor('user.label', 'aUser', 'staffAnalytics'));
        this.set('taskTooltip', this.createTooltipFor('task.label', 'aTask'));
        this.set('referenceTooltip', this.createTooltipFor('timeEntryReference.shortCodeAndPageTitle', 'reference', 'reference'));
      }
    },
    colNum: Ember.computed('isDefaultUser', 'screen.isMediumAndUp', 'screen.isSmallAndUp', {
      get: function get() {
        var isMediumAndUp = this.get('screen.isMediumAndUp');
        var isSmallAndUp = this.get('screen.isSmallAndUp');
        var isDefaultUser = this.get('isDefaultUser');

        if (isMediumAndUp) {
          return isDefaultUser ? 5 : 8;
        } else if (isSmallAndUp) {
          return isDefaultUser ? 4 : 6;
        } else {
          return isDefaultUser ? 3 : 4;
        }
      }
    }),
    checked: Ember.computed('checkedItems', {
      get: function get() {
        return this.get('checkedItems').includes(this.get('item').id);
      },
      set: function set(name, newValue) {
        this.get('onCheckChange')(this.get('item').id, newValue);
      }
    }),
    actions: {
      stopPropagation: function stopPropagation(event) {
        event.stopPropagation();
      },
      clientClick: function clientClick(e) {
        if (e.altKey) {
          this.router.transitionTo('projects.clients.analytics', this.get('item.project.client.id'));
        } else {
          this.get('selectClients')(this.get('item.project.client.id'));
        }

        event.stopPropagation();
      },
      userClick: function userClick(e) {
        if (e.altKey) {
          this.router.transitionTo('team.users.analytics', this.get('item.user.id'));
        } else {
          this.get('selectUsers')(this.get('item.user.id'));
        }

        event.stopPropagation();
      },
      taskClick: function taskClick(e) {
        this.get('selectTasks')(this.get('item.task.id'));
        event.stopPropagation();
      },
      teamClick: function teamClick(e) {
        if (e.altKey) {
          this.router.transitionTo('team.edit', this.get('item.team.id'));
        } else {
          this.get('selectTeams')(this.get('item.team.id'));
        }

        event.stopPropagation();
      },
      timeEntryReferenceClick: function timeEntryReferenceClick(e) {
        if (e.altKey) {
          window.open(this.get('item.timeEntryReference.url'), '_blank');
        } else {
          this.get('selectTimeEntryReferences')(this.get('item.timeEntryReference.id'));
        }

        event.stopPropagation();
      },
      projectClick: function projectClick(e) {
        if (e.altKey) {
          this.router.transitionTo('projects.analytics', this.get('item.project.id'));
        } else {
          this.get('selectProjects')(this.get('item.project.id'));
        }

        event.stopPropagation();
      },
      blankClick: function blankClick() {},
      headerClick: function headerClick(e) {
        var keyId = this.get('item._keyId');

        switch (this.get('criteriaNumber')) {
          case _environment.default.constants.GROUP_BY_CLIENT:
            if (e.altKey) {
              this.router.transitionTo('projects.clients.analytics', keyId);
            } else {
              this.get('selectClients')(keyId);
            }

            event.stopPropagation();
            break;

          case _environment.default.constants.GROUP_BY_PROJECT:
            if (e.altKey) {
              this.router.transitionTo('projects.analytics', keyId);
            } else {
              this.get('selectProjects')(keyId);
            }

            event.stopPropagation();
            break;

          case _environment.default.constants.GROUP_BY_STAFF:
            if (e.altKey) {
              this.router.transitionTo('team.users.analytics', keyId);
            } else {
              this.get('selectUsers')(keyId);
            }

            event.stopPropagation();
            break;

          case _environment.default.constants.GROUP_BY_TEAM:
            if (e.altKey) {
              this.router.transitionTo('team.edit', keyId);
            } else {
              this.get('selectTeams')(keyId);
            }

            event.stopPropagation();
            break;

          case _environment.default.constants.GROUP_BY_TASK:
            this.get('selectTasks')(keyId);
            event.stopPropagation();
            break;

          case _environment.default.constants.GROUP_BY_REFERENCE:
            if (e.altKey) {
              window.open(this.get('item._timeEntryReferenceUrl'), '_blank');
            } else {
              this.get('selectTimeEntryReferences')(keyId);
            }

            event.stopPropagation();
            break;

          default:
            this.actions.blankClick();
        }
      }
    }
  });

  var _default = AnalyticsTimeEntriesListRowComponent;
  _exports.default = _default;
});