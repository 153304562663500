define("coffeecup/components/analytics-module/project/profitability", ["exports", "coffeecup/utils/build-url", "coffeecup/config/environment"], function (_exports, _buildUrl, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    project: null,
    analytics: null,
    pieChartData: [],
    showInvoiceDetails: false,
    invoices: [],
    profitColor: _environment.default.constants.PROFIT_COLOR,
    showNotInvoicedAmount: Ember.computed.reads('analytics.showNotInvoicedAmount'),
    notInvoicedAmount: Ember.computed.reads('analytics.notInvoicedAmount'),
    invoicesTotal: Ember.computed.reads('analytics.invoicesTotal'),
    totalCosts: Ember.computed.reads('analytics.totalCosts'),
    profitabilityTotal: Ember.computed.reads('analytics.profitabilityTotal'),
    userCosts: Ember.computed.reads('analytics.userCosts'),
    costsExpenses: Ember.computed.reads('analytics.expensesCosts'),
    profitabilityInPercent: Ember.computed.reads('analytics.profitabilityInPercent'),
    notInvoicedAmounts: null,
    isNotProfitable: Ember.computed.reads('analytics.isNotProfitable'),
    didInsertElement: function didInsertElement() {
      this._updateUi();

      this._checkForNotInvoicedTimeEntries();
    },
    _checkForNotInvoicedTimeEntries: Ember.observer('project', 'invoicesTotal', function () {
      var _this = this;

      this.get('session').getJSON((0, _buildUrl.default)(this.get('store'), 'invoices/notInvoicedAmounts', {
        project: this.get('project.id')
      })).then(function (res) {
        _this.set('notInvoicedAmounts', res);
      });
    }),
    _updateUi: Ember.observer('profitabilityInPercent', function () {
      var profitabilityInPercent = this.get('profitabilityInPercent');
      this.set('pieChartData', [{
        value: profitabilityInPercent > 0 ? 1 - profitabilityInPercent : Math.abs(profitabilityInPercent)
      }, {
        value: profitabilityInPercent > 0 ? profitabilityInPercent : 1 - Math.abs(profitabilityInPercent)
      }]);
    }),
    actions: {
      showInvoiceDetails: function showInvoiceDetails() {
        this.toggleProperty('showInvoiceDetails');
      },
      customAction: function customAction(actionName) {
        for (var _len = arguments.length, data = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          data[_key - 1] = arguments[_key];
        }

        this.sendAction.apply(this, ['customAction', actionName].concat(data));
      },
      billProject: function billProject() {
        this.sendAction('customAction', 'billProject', this.get('project.id'));
      }
    }
  });

  _exports.default = _default;
});