define("coffeecup/components/color-picker", ["exports", "coffeecup/mixins/empty-action"], function (_exports, _emptyAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Custom color picker component, equal to the dropdown-group
   * */
  var ColorPicker = Ember.Component.extend(_emptyAction.default, {
    tagName: ['div'],
    classNames: [''],
    attributeBindings: ['color', 'colors'],
    color: null,
    colors: [],
    filteredColors: Ember.computed.filterBy('colors', 'status'),
    sortKeys: ['order:asc', 'id:asc'],
    sortedColors: Ember.computed.sort('filteredColors', 'sortKeys'),

    /**
     * the component's style
     */
    style: Ember.computed('color', {
      get: function get() {
        return Ember.String.htmlSafe('background-color:' + this.get('color'));
      }
    }),
    newColorGrid: Ember.computed.gt('colors.length', 25),

    /**
     * the hex value of the selected color
     */
    hexValue: Ember.computed('color', {
      get: function get() {
        return this.get('color.hexString');
      }
    }),

    /**
     * the fill-style of the color-preview
     */
    fillStyle: Ember.computed('hexValue', {
      get: function get() {
        return Ember.String.htmlSafe('background-color:' + this.get('hexValue'));
      }
    }),

    /**
     * the color-picker's actions
     */
    actions: {
      setColor: function setColor(color) {
        this.set('color', color);
        this.sendAction('action', color);
      }
    }
  });
  var _default = ColorPicker;
  _exports.default = _default;
});