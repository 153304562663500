define("coffeecup/components/analytics-module/client/profitability", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    project: null,
    analytics: null,
    pieChartData: [],
    profitColor: _environment.default.constants.PROFIT_COLOR,
    invoicesTotal: Ember.computed.reads('analytics.invoicesTotal'),
    totalCosts: Ember.computed.reads('analytics.totalCosts'),
    profitabilityTotal: Ember.computed.reads('analytics.profitabilityTotal'),
    userCosts: Ember.computed.reads('analytics.userCosts'),
    costsExpenses: Ember.computed.reads('analytics.expensesCosts'),
    profitabilityInPercent: Ember.computed.reads('analytics.profitabilityInPercent'),
    isNotProfitable: Ember.computed.reads('analytics.isNotProfitable'),
    didInsertElement: function didInsertElement() {
      this._updateUi();
    },
    _updateUi: Ember.observer('profitabilityInPercent', function () {
      var profitabilityInPercent = this.get('profitabilityInPercent');
      this.set('pieChartData', [{
        value: profitabilityInPercent > 0 ? 1 - profitabilityInPercent : Math.abs(profitabilityInPercent)
      }, {
        value: profitabilityInPercent > 0 ? profitabilityInPercent : 1 - Math.abs(profitabilityInPercent)
      }]);
    })
  });

  _exports.default = _default;
});