define("coffeecup/components/validation/day-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['validation-day-box'],
    classNameBindings: ['isActive:active'],
    day: null,
    selectedDay: null,
    accountService: Ember.inject.service('account'),
    accountSettings: Ember.computed.alias('accountService.accountSettings'),
    isActive: Ember.computed('selectedDay', 'day', function () {
      return this.get('selectedDay.content.day') && this.get('day.content.day') && this.get('selectedDay.content.day') === this.get('day.content.day');
    }),
    hasToWork: Ember.computed('day.content', function () {
      return this.get('day.content.secondsToWork') && this.get('day.content.secondsToWork') > 0;
    }),
    isValid: Ember.computed('day.content', 'hasToWork', 'isWorkDayComplete', function () {
      return this.get('day.content.isValid') && this.get('hasToWork') && this.get('isWorkDayComplete');
    }),
    isWorkDayComplete: Ember.computed('day.content', 'day.durationHoursValid', function () {
      return this.get('day.content.totalDuration') >= this.get('day.content.secondsToWork') || this.get('day.durationHoursValid');
    }),
    hasAbsences: Ember.computed.gt('day.absences.length', 0),
    hasFullDayAbsences: Ember.computed('day.absences.[]', function () {
      if (!this.get('hasAbsences')) return false;
      return !this.get('day.absences').every(function (el) {
        return el.isHalfDay || el.absenceType.get('defaultWorkHoursExpected');
      });
    }),
    click: function click() {
      var selectedDay = this.get('day.content.day') === this.get('selectedDay.content.day') ? null : this.get('day');
      this.sendAction('onClick', selectedDay);
    }
  });

  _exports.default = _default;
});