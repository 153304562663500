define("coffeecup/controllers/planner/absences/calendar/new", ["exports", "coffeecup/config/environment", "coffeecup/validations/absence-request", "coffeecup/validations/absence", "coffeecup/mixins/buffered-form", "coffeecup/mixins/calculate-absencedays", "coffeecup/models/absence"], function (_exports, _environment, _absenceRequest, _absence, _bufferedForm, _calculateAbsencedays, _absence2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_bufferedForm.default, _calculateAbsencedays.default, {
    queryParams: ['absenceStartDate', 'user'],
    permissions: Ember.inject.service(),
    store: Ember.inject.service(),
    application: Ember.inject.controller(),
    absenceTypeService: Ember.inject.service('absence-type'),
    needs: 'application',

    /**
     * the currently logged in user
     */
    currentUser: null,

    /**
     * absence
     */
    model: null,
    AbsenceRequestValidation: _absenceRequest.default,
    AbsenceValidation: _absence.default,
    formValidation: Ember.computed('requestOnly', function () {
      if (this.get('requestOnly')) {
        return _absenceRequest.default;
      }

      return _absence.default;
    }),
    startDateProxy: Ember.computed('form.startDate.content', {
      get: function get() {
        var form = this.get('form');
        return Ember.get(form, 'startDate.content') || Ember.get(form, 'startDate');
      },
      set: function set(key, value) {
        var form = this.get('form');
        Ember.run.next(this, function () {
          form.set('startDate', value);
          form.validate();
        });
        return value;
      }
    }),
    endDateProxy: Ember.computed('form.endDate.content', {
      get: function get() {
        var form = this.get('form');
        return Ember.get(form, 'endDate.content') || Ember.get(form, 'endDate');
      },
      set: function set(key, value) {
        var form = this.get('form');
        Ember.run.next(this, function () {
          form.set('endDate', value);
          form.validate();
        });
        return value;
      }
    }),
    halfDayProxy: Ember.computed('form.halfDay', {
      get: function get() {
        return this.get('form.halfDay');
      },
      set: function set(key, value) {
        var form = this.get('form');
        var halfDayProperty = (0, _absence2.calculateRangeType)(value);
        form.set('rangeType', halfDayProperty);
        form.set('halfDay', value);
        form.validate();
        return value;
      }
    }),
    absenceTypeProxy: Ember.computed('form.absenceType', {
      get: function get() {
        return this.get('form.absenceType');
      },
      set: function set(key, value) {
        var form = this.get('form');
        Ember.run.next(this, function () {
          form.set('absenceType', value);
          form.validate();
        });
        return value;
      }
    }),
    alreadyAdded: false,

    /**
     * boolean, true when the form is being submitted
     */
    submitting: false,
    canCreateAbsenceForUser: function canCreateAbsenceForUser(user) {
      if (!user || user.get('id') === this.get('currentUser.id') && !this.get('currentUser.isAdmin')) {
        return false;
      }

      var canAccessUsers = this.get('permissions.canAccessUsers');
      var canManageTeamAbsences = this.get('permissions.canManageTeamAbsences');
      var userIsOnOurTeam = user.get('team.id') === this.get('currentUser.team.id');
      return canAccessUsers || canManageTeamAbsences && userIsOnOurTeam;
    },
    canCreateAbsenceForSelectedUser: Ember.computed('user', 'permissions.canAccessUsers', 'permissions.canManageTeamAbsences', 'currentUser.id', 'currentUser.team.id', function () {
      return this.canCreateAbsenceForUser(this.model.user);
    }),
    requestOnly: Ember.computed('currentUser.isAdmin', 'canCreateAbsenceForSelectedUser', {
      get: function get() {
        return !this.get('canCreateAbsenceForSelectedUser');
      }
    }),
    modalContainerClass: Ember.computed('requestOnly', {
      get: function get() {
        return this.get('requestOnly') ? 'absence-request-modal' : 'edit-absence-modal';
      }
    }),
    countsAsVacation: Ember.computed('form.isNew', 'form.absenceType', function () {
      return this.get('form.isNew') ? this.get('form.absenceType.defaultCountsAsVacation') : this.get('form.countsAsVacation');
    }),

    /**
     * determines which field should be focused
     */
    focus: 'absence-type',
    previousDatesAllowed: Ember.computed.not('requestOnly'),
    absencesController: Ember.inject.controller('planner.absences.calendar'),
    absenceTypes: Ember.computed.reads('application.activeAbsenceTypes'),
    absenceTypeFiltered: Ember.computed('absenceTypes', 'canCreateAbsenceForSelectedUser', function () {
      var absenceTypeService = this.get('absenceTypeService');

      if (this.get('canCreateAbsenceForSelectedUser')) {
        return absenceTypeService.getSortedAbsenceTypes(this.get('absenceTypes'));
      }

      var absenceTypes = this.get('absenceTypes').filter(function (absenceType) {
        return absenceType.get('canBeRequested');
      });
      return absenceTypeService.getSortedAbsenceTypes(absenceTypes);
    }),
    absenceTypesSorted: Ember.computed.sort('absenceTypeFiltered', 'absenceTypesSorting'),
    absenceTypesSorting: ['order'],
    activeUsers: Ember.computed.reads('application.activeUsers'),
    userSortKeys: _environment.default.constants.USER_SORT_KEYS,
    _userSorted: Ember.computed.sort('activeUsers', 'userSortKeys'),

    /**
     * @return array of active users sorted by currentUser, lastname
     */
    usersSorted: Ember.computed('_userSorted', {
      get: function get() {
        var userSorted = this.get('_userSorted');
        var currentUser = this.get('currentUser'); // make sure currentUser is at first position

        userSorted = userSorted.removeObject(currentUser);
        userSorted.unshiftObject(currentUser);
        return userSorted;
      }
    }),
    usersFiltered: Ember.computed('usersSorted', 'currentUser.team.id', {
      get: function get() {
        var _this = this;

        return this.get('usersSorted').filter(function (user) {
          return _this.canCreateAbsenceForUser(user);
        });
      }
    }),
    supervisorsSorted: Ember.computed('_userSorted', function () {
      var _this2 = this;

      return this.get('_userSorted').filter(function (user) {
        return user.get('role.canManageAbsenceRequests') && user.get('id') !== _this2.get('currentUser.id');
      });
    }),

    /**
     * disables the submit button when the form is not valid or it's currently being submitted
     * @return boolean
     */
    submitIsDisabled: Ember.computed('submitting', 'form.isValid', {
      get: function get() {
        var submitting = this.get('submitting');
        var isValid = this.get('form.isValid');
        return submitting || !isValid;
      }
    }),

    /**
     * handles focusing of form inputs
     * will automatically focus the next input field after certain values have been changed
     * @private
     */
    _addFocusObserver: function () {
      this.addObserver('form.user', this, this.formUserObsever);
      this.addObserver('form.absenceType', this, this.formAbsenceObsever);
    }.on('init'),
    formUserObsever: function formUserObsever() {
      this.set('focus', 'absence-type');
    },
    formAbsenceObsever: function formAbsenceObsever() {
      this.set('focus', 'comment');
    },

    /**
     * destroys automatically focusing of input fields
     * @private
     */
    _destroyFocusObserver: function _destroyFocusObserver() {
      try {
        this.removeObserver('form.user', this, this.formUserObsever);
        this.removeObserver('form.absenceType', this, this.formAbsenceObsever);
      } catch (error) {
        if (false
        /* DEBUG */
        ) {
          console.warn('could not destroy observer', error);
        }
      }
    },

    /**
     * set of actions
     */
    actions: {
      saveAbsence: function saveAbsence() {
        var _this3 = this;

        if (!this.get('alreadyAdded')) {
          this.set('alreadyAdded', true);
          var createActionName = this.get('requestOnly') ? 'absenceRequestCreated' : 'absenceCreated';
          var updateActionName = this.get('requestOnly') ? 'absenceRequestUpdated' : 'absenceUpdated';
          var className = this.get('requestOnly') ? 'absenceRequest' : 'absence';
          this.get('form').validate().then(function () {
            if (_this3.get('form.isValid')) {
              _this3.get('form').execute();

              var isNew = _this3.get('model.isNew');

              return _this3.get('model').save().then(function () {
                if (isNew) {
                  _this3.get('absencesController').send(createActionName, {
                    entry: _this3.get('model')
                  });
                } else {
                  _this3.get('absencesController').send(updateActionName, {
                    entry: _this3.get('model')
                  });
                }

                _this3.get('notifications').success('alert.saved', className);

                _this3.send('onHide');
              }).catch(function (err) {
                _this3.get('notifications').errorWithObject(err, 'alert.errorOnSave', className);
              });
            } else {
              _this3.set('alreadyAdded', false);

              _this3.get('notifications').info('pleaseCheckYourInputs');
            }
          }).catch(function (err) {
            _this3.get('notifications').info('pleaseCheckYourInputs');
          });
        }
      },
      deleteAbsence: function deleteAbsence() {
        var _this4 = this;

        var model = this.get('model');
        model.destroyRecord().then(function () {
          _this4.get('absencesController').send('absenceDeleted', {
            entry: _this4.get('model')
          });

          _this4.get('notifications').success('alert.deleted', 'absence');

          _this4.send('onHide');
        }).catch(function (err) {
          _this4.get('notifications').errorWithObject(err, 'alert.errorOnDelete', 'absence');
        });
      },
      onUpdateAbsenceDates: function onUpdateAbsenceDates() {
        var _this5 = this;

        Ember.run.next(function () {
          _this5.get('form').validate();
        });
      },
      updateAbsenceType: function updateAbsenceType(value) {
        this.set('absenceTypeProxy', value);
      },
      onHide: function onHide() {
        var _this6 = this;

        /**
         * removes focus observers of form elements
         */
        this._destroyFocusObserver(); // Navigate away from route


        this.send('transitionToList'); // needs to run with a timeout due to a potential race condition that could occur
        // when onHide is being triggered before saveTimeEntry and its validate are done
        // => execute would be called AFTER rollback => ERROR

        Ember.run.later(function () {
          var form = _this6.get('form');

          if (form) {
            form.rollback();
          }
        }, 1000);
      }
    }
  });

  _exports.default = _default;
});