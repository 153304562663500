define("coffeecup/components/notice-period/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    noticePeriodUnits: Ember.computed(function () {
      return this.get('store').peekAll('notice-period/unit');
    }),
    noticePeriodExtras: Ember.computed(function () {
      return this.get('store').peekAll('notice-period/extra');
    }),
    selectedNoticePeriod: Ember.computed('noticePeriodUnits', 'noticePeriod.unit', function () {
      var _this = this;

      var noticePeriodUnits = this.get('noticePeriodUnits');

      if (!noticePeriodUnits) {
        return null;
      }

      return noticePeriodUnits.find(function (x) {
        return x.get('id') === _this.get('noticePeriod.unit');
      });
    }),
    selectedNoticeExtra: Ember.computed('noticePeriodExtras', 'noticePeriod.extra', function () {
      var _this2 = this;

      var noticePeriodExtras = this.get('noticePeriodExtras');

      if (!noticePeriodExtras) {
        return null;
      }

      return noticePeriodExtras.find(function (x) {
        return x.get('id') === _this2.get('noticePeriod.extra');
      });
    }),
    actions: {
      saveEntity: function saveEntity() {
        this.sendAction('saveEntity');
      },
      resetEntity: function resetEntity() {
        this.sendAction('resetEntity');
      }
    }
  });

  _exports.default = _default;
});