define("coffeecup/controllers/analytics/projects/details/complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    actions: {
      billProject: function billProject() {
        var histogram = this.get('selectedProjectAnalytics.monthlyHistogram');
        var histogramStartDate = moment(histogram.get('firstObject').get('date')).startOf('month');
        var histogramEndDate = moment(histogram.get('lastObject').get('date')).endOf('month');
        this.get('application').send('billProject', this.get('selectedProject.id'), histogramStartDate ? histogramStartDate : this.get('selectedProject.startDate'), histogramEndDate ? histogramEndDate : this.get('selectedProject.endDate'));
      }
    }
  });

  _exports.default = _default;
});