define("coffeecup/components/input/file-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    didInsertElement: function didInsertElement() {
      var self = this;
      var fileInput = new mOxie.FileInput({
        browse_button: this.$('a').get(0) || this.$('button').get(0),
        accept: [{
          title: 'Images',
          extensions: 'jpg,gif,png'
        } // accept only images
        ],
        multiple: false
      });

      fileInput.onchange = function (e) {
        self.sendAction('addImage', fileInput.files);
      };

      fileInput.init();
    },
    willDestroyElement: function willDestroyElement() {
      console.log('TODO: destroy filePicker');
    }
  });

  _exports.default = _default;
});