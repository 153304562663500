define("coffeecup/helpers/file-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fileIcon = fileIcon;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function fileIcon(_ref
  /*, hash*/
  ) {
    var _ref2 = _slicedToArray(_ref, 2),
        fileType = _ref2[0],
        showColor = _ref2[1];

    switch (fileType) {
      case 'application/pdf':
        return 'icomoon-document-file-pdf' + (showColor ? ' pdf-color' : '');

      case 'image/png':
        return 'icomoon-document-file-png' + (showColor ? ' image-color' : '');

      case 'image/jpeg':
        return 'icomoon-document-file-jpg' + (showColor ? ' image-color' : '');

      case 'application/zip':
        return 'icomoon-document-file-zip' + (showColor ? ' zip-color' : '');

      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel':
      case 'text/csv':
        return 'icomoon-document-file-xlsx' + (showColor ? ' sheets-color' : '');

      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.ms-powerpoint':
        return 'icomoon-document-file-ppt' + (showColor ? ' presentation-color' : '');

      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword':
        return 'icomoon-document-file-docx' + (showColor ? ' docs-color' : '');

      case 'image/tiff':
        return 'icomoon-document-file-tiff' + (showColor ? ' image-color' : '');

      case 'application/postscript':
        return 'icomoon-document-file-eps' + (showColor ? ' image-color' : '');

      case 'application/x-iwork-keynote-sffkey':
        return 'icomoon-document-file-key' + (showColor ? ' presentation-color' : '');

      case 'application/x-iwork-pages-sffpages':
        return 'icomoon-document-file-pages' + (showColor ? ' docs-color' : '');

      case 'application/x-iwork-numbers-sffnumbers':
        return 'icomoon-document-file-numbers' + (showColor ? ' sheets-color' : '');

      default:
        return 'icomoon-document';
    }
  }

  var _default = Ember.Helper.helper(fileIcon);

  _exports.default = _default;
});