define("coffeecup/components/input/cc-time-field", ["exports", "coffeecup/mixins/has-error", "coffeecup/utils/date", "coffeecup/utils/format-duration"], function (_exports, _hasError, _date, _formatDuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend(_hasError.default, {
    classNames: ['person-days'],
    formatter: Ember.inject.service(),
    utcMoment: true,
    maxLength: 5,
    date: null,
    canBeEmpty: true,
    isDatePickerOpen: false,
    isFocused: false,
    initialDurationClone: null,
    updateDurationClone: function updateDurationClone() {
      var duration = this.get('duration');

      if (duration && (0, _date.isDateValid)(duration)) {
        this.set('initialDurationClone', duration);
      }
    },
    didEnter: function didEnter() {
      if (false
      /* DEBUG */
      ) {
        (false && Ember.warn("onEnter action was triggered in ".concat(this.toString(), ": ").concat(this.get('durationFMT')), {
          id: 'cc.onEnterAction'
        }));
      }

      this.set('duration', Number((0, _formatDuration.normalizeDuration)(this.get('duration'))));
      this.sendAction('onEnter');
      this.updateDurationClone();
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super();

      var $input = this.$('input');
      this.updateDurationClone();
      Ember.run.next(function () {
        $input.on('blur', function () {
          Ember.run(function () {
            _this.set('isFocused', false);

            if (false
            /* DEBUG */
            ) {
              (false && Ember.warn("onBlur action was triggered in ".concat(_this.toString(), ": ").concat(_this.get('durationFMT')), {
                id: 'cc.onBlurAction'
              }));
            }

            _this.set('duration', Number((0, _formatDuration.normalizeDuration)(_this.get('duration'))));

            _this.onBlur();

            _this.updateDurationClone();
          });
        });
        $input.on('focus', function () {
          Ember.run(function () {
            _this.set('isFocused', true);
          });
        });
      });
    },
    durationFMT: Ember.computed('duration', {
      get: function get() {
        var duration = Number(parseFloat((0, _formatDuration.normalizeDuration)(this.get('duration'))).toFixed(4));
        return this.get('isFocused') ? duration : this.get('formatter').formatDuration(duration, true);
      },
      set: function set(key, val) {
        var maxLength = parseInt(this.get('maxLength'));
        var sizeToCut = val.indexOf(':') !== -1 ? maxLength : maxLength - 2;

        if (val.length > sizeToCut) {
          var _val$split = val.split(':'),
              _val$split2 = _slicedToArray(_val$split, 2),
              hours = _val$split2[0],
              minutes = _val$split2[1];

          if (minutes) sizeToCut -= 2;
          val = "".concat(hours.slice(0, sizeToCut)).concat(minutes ? ':' + minutes : '');
        }

        this.set('duration', val);
        return val;
      }
    }),
    keyPress: function keyPress(event) {
      if (event.key === 'Enter') {
        this.didEnter();
      }
    },
    actions: {
      cancel: function cancel() {
        if (false
        /* DEBUG */
        ) {
          (false && Ember.warn("onEscape action was triggered in ".concat(this.toString(), ": ").concat(this.get('durationFMT')), {
            id: 'cc.onEscapeAction'
          }));
        }

        this.sendAction('onEscape');
        this.updateDurationClone();
      },
      resetIfEmpty: function resetIfEmpty() {
        var canBeEmpty = this.get('canBeEmpty');
        var durationFMT = this.get('durationFMT');
        var initialDurationClone = this.get('initialDurationClone');

        if (!canBeEmpty && !durationFMT && initialDurationClone) {
          this.set('duration', initialDurationClone);
        }
      }
    }
  });

  _exports.default = _default;
});