define("coffeecup/demo-data/dashboard/effort-costs-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.totalCostsDemo = _exports.totalRevenueDemo = _exports.effortCostsData = void 0;
  var effortCostsData = [{
    month: 'Jan',
    totalAmountEffective: 34450.5,
    totalAmount: 37649.875,
    totalAmountPlanned: 0,
    totalAmountPlannedEffective: 0,
    totalAmountExpenses: 0,
    totalOverBudget: 3199.375,
    totalAmountPlannedOverBudget: 0,
    chartTotalAmountPlanned: 0,
    chartTotalOverBudget: 3199.375,
    chartTotalCosts: -34450.5,
    chartCostsSurplus: -40056.58,
    chartTotalCostsPlanned: 0,
    monthTooltip: 'Januar 2020',
    chartTotalAmount: 34450.5,
    chartRevenueSurplus: 0,
    overBudgetClass: 'danger',
    amountClass: 'danger',
    amountPlannedClass: 'danger',
    plannedHiddenClass: 'hidden',
    effortHiddenClass: '',
    plannedOverBudgetClass: 'danger',
    tooltipTotalEffort: 37649.875,
    tooltipTotalEffortWithPlanned: 37649.875,
    tooltipOverBudget: 3199.375,
    tooltipOverBudgetWithPlanned: 3199.375,
    tooltipEmployeeCosts: 59340,
    tooltipEmployeeCostsWithPlanned: 59340,
    tooltipFreelancerCosts: 15167.08,
    tooltipFreelancerCostsWithPlanned: 15167.08,
    tooltipTotalAmount: -40056.58,
    tooltipTotalAmountWithPlanned: -40056.58
  }, {
    month: 'Febr',
    totalAmountEffective: 42315.9375,
    totalAmount: 42315.9375,
    totalAmountPlanned: 0,
    totalAmountPlannedEffective: 0,
    totalAmountExpenses: 757.8000011444092,
    totalOverBudget: 0,
    totalAmountPlannedOverBudget: 0,
    chartTotalAmountPlanned: 0,
    chartTotalOverBudget: 0,
    chartTotalCosts: -43073.73750114441,
    chartCostsSurplus: -20670.232498855592,
    chartTotalCostsPlanned: 0,
    monthTooltip: 'Februar 2020',
    chartTotalAmount: 42315.9375,
    chartRevenueSurplus: 0,
    overBudgetClass: '',
    amountClass: 'danger',
    amountPlannedClass: 'danger',
    plannedHiddenClass: 'hidden',
    effortHiddenClass: '',
    plannedOverBudgetClass: '',
    tooltipTotalEffort: 43073.73750114441,
    tooltipTotalEffortWithPlanned: 43073.73750114441,
    tooltipOverBudget: 0,
    tooltipOverBudgetWithPlanned: 0,
    tooltipEmployeeCosts: 59340,
    tooltipEmployeeCostsWithPlanned: 59340,
    tooltipFreelancerCosts: 4403.97,
    tooltipFreelancerCostsWithPlanned: 4403.97,
    tooltipTotalAmount: -20670.232498855592,
    tooltipTotalAmountWithPlanned: -20670.232498855592
  }, {
    month: 'Mrz',
    totalAmountEffective: 37543.75,
    totalAmount: 37543.75,
    totalAmountPlanned: 0,
    totalAmountPlannedEffective: 0,
    totalAmountExpenses: 33,
    totalOverBudget: 0,
    totalAmountPlannedOverBudget: 0,
    chartTotalAmountPlanned: 0,
    chartTotalOverBudget: 0,
    chartTotalCosts: -37576.75,
    chartCostsSurplus: -22756.590000000004,
    chartTotalCostsPlanned: 0,
    monthTooltip: 'März 2020',
    chartTotalAmount: 37543.75,
    chartRevenueSurplus: 0,
    overBudgetClass: '',
    amountClass: 'danger',
    amountPlannedClass: 'danger',
    plannedHiddenClass: 'hidden',
    effortHiddenClass: '',
    plannedOverBudgetClass: '',
    tooltipTotalEffort: 37576.75,
    tooltipTotalEffortWithPlanned: 37576.75,
    tooltipOverBudget: 0,
    tooltipOverBudgetWithPlanned: 0,
    tooltipEmployeeCosts: 57055.01,
    tooltipEmployeeCostsWithPlanned: 57055.01,
    tooltipFreelancerCosts: 3278.33,
    tooltipFreelancerCostsWithPlanned: 3278.33,
    tooltipTotalAmount: -22756.590000000004,
    tooltipTotalAmountWithPlanned: -22756.590000000004
  }, {
    month: 'Apr',
    totalAmountEffective: 61489.4375,
    totalAmount: 61489.4375,
    totalAmountPlanned: 0,
    totalAmountPlannedEffective: 0,
    totalAmountExpenses: 0,
    totalOverBudget: 0,
    totalAmountPlannedOverBudget: 0,
    chartTotalAmountPlanned: 0,
    chartTotalOverBudget: 0,
    chartTotalCosts: -61489.4375,
    chartCostsSurplus: -14896.542499999996,
    chartTotalCostsPlanned: 0,
    monthTooltip: 'April 2020',
    chartTotalAmount: 61489.4375,
    chartRevenueSurplus: 0,
    overBudgetClass: '',
    amountClass: 'danger',
    amountPlannedClass: 'danger',
    plannedHiddenClass: '',
    effortHiddenClass: '',
    plannedOverBudgetClass: '',
    tooltipTotalEffort: 61489.4375,
    tooltipTotalEffortWithPlanned: 61489.4375,
    tooltipOverBudget: 0,
    tooltipOverBudgetWithPlanned: 0,
    tooltipEmployeeCosts: 69127.45,
    tooltipEmployeeCostsWithPlanned: 69127.45,
    tooltipFreelancerCosts: 7258.53,
    tooltipFreelancerCostsWithPlanned: 7258.53,
    tooltipTotalAmount: -14896.542499999996,
    tooltipTotalAmountWithPlanned: -14896.542499999996
  }, {
    month: 'Mai',
    totalAmountEffective: 72397,
    totalAmount: 72402,
    totalAmountPlanned: 0,
    totalAmountPlannedEffective: 0,
    totalAmountExpenses: 230,
    totalOverBudget: 5,
    totalAmountPlannedOverBudget: 0,
    chartTotalAmountPlanned: 0,
    chartTotalOverBudget: 5,
    chartTotalCosts: -72627,
    chartCostsSurplus: -15687.36,
    chartTotalCostsPlanned: 0,
    monthTooltip: 'Mai 2020',
    chartTotalAmount: 72397,
    chartRevenueSurplus: 0,
    overBudgetClass: 'danger',
    amountClass: 'danger',
    amountPlannedClass: 'danger',
    plannedHiddenClass: '',
    effortHiddenClass: 'hidden',
    plannedOverBudgetClass: 'danger',
    tooltipTotalEffort: 72632,
    tooltipTotalEffortWithPlanned: 72632,
    tooltipOverBudget: 5,
    tooltipOverBudgetWithPlanned: 5,
    tooltipEmployeeCosts: 82727.67,
    tooltipEmployeeCostsWithPlanned: 82727.67,
    tooltipFreelancerCosts: 5586.69,
    tooltipFreelancerCostsWithPlanned: 5586.69,
    tooltipTotalAmount: -15687.36,
    tooltipTotalAmountWithPlanned: -15687.36
  }, {
    month: 'Jun',
    totalAmountEffective: 64605.375,
    totalAmount: 64605.375,
    totalAmountPlanned: 0,
    totalAmountPlannedEffective: 0,
    totalAmountExpenses: 0,
    totalOverBudget: 0,
    totalAmountPlannedOverBudget: 0,
    chartTotalAmountPlanned: 0,
    chartTotalOverBudget: 0,
    chartTotalCosts: -64605.375,
    chartCostsSurplus: -10708.175000000003,
    chartTotalCostsPlanned: 0,
    monthTooltip: 'Juni 2020',
    chartTotalAmount: 64605.375,
    chartRevenueSurplus: 0,
    overBudgetClass: '',
    amountClass: 'danger',
    amountPlannedClass: 'danger',
    plannedHiddenClass: '',
    effortHiddenClass: 'hidden',
    plannedOverBudgetClass: '',
    tooltipTotalEffort: 64605.375,
    tooltipTotalEffortWithPlanned: 64605.375,
    tooltipOverBudget: 0,
    tooltipOverBudgetWithPlanned: 0,
    tooltipEmployeeCosts: 72127.33,
    tooltipEmployeeCostsWithPlanned: 72127.33,
    tooltipFreelancerCosts: 3186.22,
    tooltipFreelancerCostsWithPlanned: 3186.22,
    tooltipTotalAmount: -10708.175000000003,
    tooltipTotalAmountWithPlanned: -10708.175000000003
  }, {
    month: 'Jul',
    totalAmountEffective: 80165.84166665666,
    totalAmount: 80165.84166665666,
    totalAmountPlanned: 0,
    totalAmountPlannedEffective: 0,
    totalAmountExpenses: 0,
    totalOverBudget: 0,
    totalAmountPlannedOverBudget: 0,
    chartTotalAmountPlanned: 0,
    chartTotalOverBudget: 0,
    chartTotalCosts: -80165.84166665666,
    chartCostsSurplus: -12473.808333343331,
    chartTotalCostsPlanned: 0,
    monthTooltip: 'Juli 2020',
    chartTotalAmount: 80165.84166665666,
    chartRevenueSurplus: 0,
    overBudgetClass: '',
    amountClass: 'danger',
    amountPlannedClass: 'danger',
    plannedHiddenClass: '',
    effortHiddenClass: 'hidden',
    plannedOverBudgetClass: '',
    tooltipTotalEffort: 80165.84166665666,
    tooltipTotalEffortWithPlanned: 80165.84166665666,
    tooltipOverBudget: 0,
    tooltipOverBudgetWithPlanned: 0,
    tooltipEmployeeCosts: 78840,
    tooltipEmployeeCostsWithPlanned: 78840,
    tooltipFreelancerCosts: 13799.65,
    tooltipFreelancerCostsWithPlanned: 13799.65,
    tooltipTotalAmount: -12473.808333343331,
    tooltipTotalAmountWithPlanned: -12473.808333343331
  }, {
    month: 'Aug',
    totalAmountEffective: 43126.125,
    totalAmount: 43126.125,
    totalAmountPlanned: 0,
    totalAmountPlannedEffective: 0,
    totalAmountExpenses: 0,
    totalOverBudget: 0,
    totalAmountPlannedOverBudget: 0,
    chartTotalAmountPlanned: 0,
    chartTotalOverBudget: 0,
    chartTotalCosts: -43126.125,
    chartCostsSurplus: -47570.83500000001,
    chartTotalCostsPlanned: 0,
    monthTooltip: 'August 2020',
    chartTotalAmount: 43126.125,
    chartRevenueSurplus: 0,
    overBudgetClass: '',
    amountClass: 'danger',
    amountPlannedClass: 'danger',
    plannedHiddenClass: '',
    effortHiddenClass: 'hidden',
    plannedOverBudgetClass: '',
    tooltipTotalEffort: 43126.125,
    tooltipTotalEffortWithPlanned: 43126.125,
    tooltipOverBudget: 0,
    tooltipOverBudgetWithPlanned: 0,
    tooltipEmployeeCosts: 88796.46,
    tooltipEmployeeCostsWithPlanned: 88796.46,
    tooltipFreelancerCosts: 1900.5,
    tooltipFreelancerCostsWithPlanned: 1900.5,
    tooltipTotalAmount: -47570.83500000001,
    tooltipTotalAmountWithPlanned: -47570.83500000001
  }, {
    month: 'Sept',
    totalAmountEffective: 89652.5,
    totalAmount: 89822.52777777666,
    totalAmountPlanned: 0,
    totalAmountPlannedEffective: 0,
    totalAmountExpenses: 0,
    totalOverBudget: 170.0277777766671,
    totalAmountPlannedOverBudget: 0,
    chartTotalAmountPlanned: 0,
    chartTotalOverBudget: 170.0277777766671,
    chartTotalCosts: -89652.5,
    chartCostsSurplus: -14119.14,
    chartTotalCostsPlanned: 0,
    monthTooltip: 'September 2020',
    chartTotalAmount: 89652.5,
    chartRevenueSurplus: 0,
    overBudgetClass: 'danger',
    amountClass: 'danger',
    amountPlannedClass: 'danger',
    plannedHiddenClass: '',
    effortHiddenClass: 'hidden',
    plannedOverBudgetClass: 'danger',
    tooltipTotalEffort: 89822.52777777666,
    tooltipTotalEffortWithPlanned: 89822.52777777666,
    tooltipOverBudget: 170.0277777766671,
    tooltipOverBudgetWithPlanned: 170.0277777766671,
    tooltipEmployeeCosts: 99919.64,
    tooltipEmployeeCostsWithPlanned: 99919.64,
    tooltipFreelancerCosts: 3852,
    tooltipFreelancerCostsWithPlanned: 3852,
    tooltipTotalAmount: -14119.14,
    tooltipTotalAmountWithPlanned: -14119.14
  }, {
    month: 'Okt',
    totalAmountEffective: 87136.93,
    totalAmount: 92176.64583333333,
    totalAmountPlanned: 0,
    totalAmountPlannedEffective: 0,
    totalAmountExpenses: 0,
    totalOverBudget: 5039.715833333333,
    totalAmountPlannedOverBudget: 0,
    chartTotalAmountPlanned: 0,
    chartTotalOverBudget: 5039.715833333333,
    chartTotalCosts: -87136.93,
    chartCostsSurplus: -17983.110000000015,
    chartTotalCostsPlanned: 0,
    monthTooltip: 'Oktober 2020',
    chartTotalAmount: 87136.93,
    chartRevenueSurplus: 0,
    overBudgetClass: 'danger',
    amountClass: 'danger',
    amountPlannedClass: 'danger',
    plannedHiddenClass: '',
    effortHiddenClass: 'hidden',
    plannedOverBudgetClass: 'danger',
    tooltipTotalEffort: 92176.64583333333,
    tooltipTotalEffortWithPlanned: 92176.64583333333,
    tooltipOverBudget: 5039.715833333333,
    tooltipOverBudgetWithPlanned: 5039.715833333333,
    tooltipEmployeeCosts: 96200,
    tooltipEmployeeCostsWithPlanned: 96200,
    tooltipFreelancerCosts: 8920.04,
    tooltipFreelancerCostsWithPlanned: 8920.04,
    tooltipTotalAmount: -17983.110000000015,
    tooltipTotalAmountWithPlanned: -17983.110000000015
  }, {
    month: 'Nov',
    totalAmountEffective: 81721.9375,
    totalAmount: 84064.0625,
    totalAmountPlanned: 0,
    totalAmountPlannedEffective: 0,
    totalAmountExpenses: 0,
    totalOverBudget: 2342.125,
    totalAmountPlannedOverBudget: 0,
    chartTotalAmountPlanned: 0,
    chartTotalOverBudget: 2342.125,
    chartTotalCosts: -81721.9375,
    chartCostsSurplus: -29086.012499999997,
    chartTotalCostsPlanned: 0,
    monthTooltip: 'November 2020',
    chartTotalAmount: 81721.9375,
    chartRevenueSurplus: 0,
    overBudgetClass: 'danger',
    amountClass: 'danger',
    amountPlannedClass: 'danger',
    plannedHiddenClass: '',
    effortHiddenClass: 'hidden',
    plannedOverBudgetClass: 'danger',
    tooltipTotalEffort: 84064.0625,
    tooltipTotalEffortWithPlanned: 84064.0625,
    tooltipOverBudget: 2342.125,
    tooltipOverBudgetWithPlanned: 2342.125,
    tooltipEmployeeCosts: 104600,
    tooltipEmployeeCostsWithPlanned: 104600,
    tooltipFreelancerCosts: 6207.95,
    tooltipFreelancerCostsWithPlanned: 6207.95,
    tooltipTotalAmount: -29086.012499999997,
    tooltipTotalAmountWithPlanned: -29086.012499999997
  }, {
    month: 'Dez',
    totalAmountEffective: 76024.875,
    totalAmount: 76024.875,
    totalAmountPlanned: 0,
    totalAmountPlannedEffective: 0,
    totalAmountExpenses: 0,
    totalOverBudget: 0,
    totalAmountPlannedOverBudget: 0,
    chartTotalAmountPlanned: 0,
    chartTotalOverBudget: 0,
    chartTotalCosts: -76024.875,
    chartCostsSurplus: -34740.145000000004,
    chartTotalCostsPlanned: 0,
    monthTooltip: 'Dezember 2020',
    chartTotalAmount: 76024.875,
    chartRevenueSurplus: 0,
    overBudgetClass: '',
    amountClass: 'danger',
    amountPlannedClass: 'danger',
    plannedHiddenClass: '',
    effortHiddenClass: 'hidden',
    plannedOverBudgetClass: '',
    tooltipTotalEffort: 76024.875,
    tooltipTotalEffortWithPlanned: 76024.875,
    tooltipOverBudget: 0,
    tooltipOverBudgetWithPlanned: 0,
    tooltipEmployeeCosts: 104600,
    tooltipEmployeeCostsWithPlanned: 104600,
    tooltipFreelancerCosts: 6165.02,
    tooltipFreelancerCostsWithPlanned: 6165.02,
    tooltipTotalAmount: -34740.145000000004,
    tooltipTotalAmountWithPlanned: -34740.145000000004
  }];
  _exports.effortCostsData = effortCostsData;
  var totalRevenueDemo = 770630;
  _exports.totalRevenueDemo = totalRevenueDemo;
  var totalCostsDemo = 1052399;
  _exports.totalCostsDemo = totalCostsDemo;
});