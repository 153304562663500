define("coffeecup/demo-data/dashboard/my-week-work-hours", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.myWeekWorkHoursData = void 0;
  var myWeekWorkHoursData = [{
    invisible: false,
    intermediate: false
  }, {
    invisible: false,
    intermediate: false
  }, {
    invisible: false,
    intermediate: false
  }, {
    invisible: false,
    intermediate: false
  }, {
    invisible: false,
    intermediate: false
  }, {
    invisible: false,
    intermediate: false
  }, {
    invisible: false,
    intermediate: false
  }, {
    invisible: false,
    intermediate: false
  }, {
    invisible: false,
    intermediate: false
  }, {
    invisible: false,
    intermediate: false
  }, {
    invisible: false,
    intermediate: false
  }, {
    invisible: false,
    intermediate: false
  }, {
    invisible: false,
    intermediate: false
  }, {
    invisible: false,
    intermediate: false
  }, {
    invisible: false,
    intermediate: false
  }, {
    invisible: false,
    intermediate: false
  }, {
    invisible: false,
    intermediate: false
  }, {
    invisible: false,
    intermediate: false
  }, {
    invisible: false,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }, {
    invisible: true,
    intermediate: false
  }];
  _exports.myWeekWorkHoursData = myWeekWorkHoursData;
});