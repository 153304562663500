define("coffeecup/routes/manage/_super-route", ["exports", "coffeecup/config/environment", "coffeecup/routes/_super-form-route", "coffeecup/utils/build-url", "ember-inflector"], function (_exports, _environment, _superFormRoute, _buildUrl, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var DISPLAYED_RELATIONS_MAX = 10;

  var ManageSuperRoute = _superFormRoute.default.extend({
    permissions: Ember.inject.service(),
    subscription: Ember.computed.alias('accountService.subscription'),
    setupController: function setupController(controller, context, transition) {
      this._super.apply(this, [controller, context, transition]);
    },

    /**
     *
     * @param opts { icon, title, text }
     * @private
     */
    _showAlert: function _showAlert(opts) {
      var modalController = this.controllerFor('modal.alert');
      modalController.set('showModal', true);
      modalController.set('model', {
        icon: opts.icon,
        title: opts.title,
        text: opts.text
      });
      modalController.set('openModal', true);
    },

    /**
     *
     * @param opts { icon, title, text, closeBtnText, confirmBtnText, confirmCallback, action }
     * @private
     */
    _showConfirm: function _showConfirm(opts) {
      var _this = this;

      var modalController = this.controllerFor('modal.confirm');
      modalController.set('showModal', true);
      modalController.set('model', {
        icon: this._defineModalIcon(opts.action),
        title: opts.title,
        text: opts.textContent,
        hint: opts.hintContent,
        items: opts.items,
        closeBtnText: this.get('i18n').t('cancel'),
        confirmBtnText: this.get('i18n').t(opts.action + 'Now'),
        confirmCallback: function confirmCallback() {
          // use the fallback action in case of a fallback
          _this['_' + opts.action](opts.model, opts.modelKey, opts.routeTransition);
        },
        action: opts.action
      });
      modalController.set('openModal', true);
    },
    _showActivateModal: function _showActivateModal(model, routeTransition) {
      var modelKey = model.get('constructor.modelName');
      var modalConfig = _environment.default.modalConfigs[modelKey];
      var activateConfig = modalConfig && modalConfig['activate'];

      this._activate(model, modelKey, routeTransition || activateConfig && activateConfig.routeTransition);
    },

    /**
     * setup of the general modal that can be used in various use cases and in all sections of the app
     *
     * @param model {Object} the model with the information that will be presented in the modal
     * @param modalAction {String} the action that is performed with this modal
     * @private
     */
    _showModal: function _showModal(model, modalAction, routeTransition) {
      var _this2 = this;

      /**
       prepare general variables
       */
      var modelKey = Ember.String.camelize(model.get('constructor.modelName'));
      var modalConfig = _environment.default.modalConfigs[modelKey][modalAction];
      /**
       prepare the query with the model id and the limit of DISPLAYED_RELATIONS_MAX
       */

      var query = {
        // limit to one entry only, since we get the total count anyways.
        limit: modalConfig.showRelations ? DISPLAYED_RELATIONS_MAX : 1
      };
      query[modelKey] = model.get('id');
      /**
       prepare variables, arrays and objects
       */

      var textContent = '';
      var hintContent;
      var title = this.get('i18n').t(modalAction);
      var action = modalAction;
      var items = [];
      var total;
      /**
       prepare promises for finding related models
       */

      var relatedModelsPromises = [];

      if (modalConfig.relations && Ember.isArray(modalConfig.relations)) {
        relatedModelsPromises = modalConfig.relations.map(function (relation) {
          return _this2.store.query(relation, query);
        });
      }
      /**
       execute the promises and wait for callback
       */


      Ember.RSVP.all(relatedModelsPromises).then(function (relations) {
        /**
         decide whether a list is required for the modal or not
         */
        var validRelations = [];
        var listRequired = false;
        relations.forEach(function (relation) {
          if (relation.get('meta.total') > 0) {
            listRequired = true;
            validRelations.push(relation.get('firstObject.constructor.modelName'));
          }
        });
        /**
         prepare the list content when a list is required
         a list is required when at least one model is related to the current model
         */

        if (listRequired) {
          var relationsStr = _this2._buildRelationsStr(validRelations); // get fallback action identifier if relations have been found


          if (action === 'delete' && typeof modalConfig.onRelationFallback !== 'undefined') {
            action = modalConfig.onRelationFallback;
            title = _this2.get('i18n').t(action);
          }

          if (modalConfig.relationAction) {
            if (modelKey === 'client') {
              hintContent = _this2.get('i18n').t(modalAction + '.client.rel.hint', {
                relation: relationsStr,
                changeMode: _this2.get('i18n').t(modalConfig.relationAction).toString().toLowerCase()
              });
            } else {
              hintContent = _this2.get('i18n').t(modalAction + '.rel.hint', {
                relation: relationsStr,
                changeMode: _this2.get('i18n').t(modalConfig.relationAction).toString().toLowerCase()
              });
            }
          } // prepare the list elements


          relations.forEach(function (models) {
            if (modalConfig.showRelations) {
              // show more detailed information
              total = models.get('meta.total');
              models.forEach(function (model) {
                items.push({
                  label: _this2.get('i18n').t('modal.item', {
                    key: _this2.get('i18n').t(model.get('constructor.modelName')),
                    name: model.get('label')
                  })
                }); // TODO wordings item list in modals
              });

              if (total > DISPLAYED_RELATIONS_MAX) {
                var rest = total - DISPLAYED_RELATIONS_MAX;
                items.push({
                  label: _this2.get('i18n').t('additional', {
                    total: rest
                  })
                });
              }
            } else {
              // show total values only
              total = models.get('meta.total');

              if (total > 0) {
                var typeKey = models.get('firstObject.constructor.modelName');
                var key = total !== 1 ? (0, _emberInflector.pluralize)(typeKey) : typeKey;
                items.push({
                  label: total + ' ' + _this2.get('i18n').t(key)
                });
              }
            }
          }); // define a default text for relations

          if (modelKey === 'client') {
            textContent = _this2.get('i18n').t(modalAction + '.client.rel', {
              actionName: _this2.get('i18n').t(action).toString().toLowerCase(),
              relation: relationsStr,
              key: _this2.get('i18n').t(modelKey),
              name: model.get('label'),
              total: total === 1 ? total.toString() + ' ' + _this2.get('i18n').t('project') : total.toString() + ' ' + _this2.get('i18n').t('projectMany')
            });
            console.log(_typeof(modelKey));
          } else {
            textContent = _this2.get('i18n').t(modalAction + '.rel', {
              actionName: _this2.get('i18n').t(action).toString().toLowerCase(),
              relation: relationsStr,
              key: _this2.get('i18n').t(modelKey),
              name: model.get('label'),
              total: total.toString()
            });
          }
        } else {
          // define a default text
          textContent = _this2.get('i18n').t(modalAction + '.no.rel', {
            key: _this2.get('i18n').t(modelKey),
            name: model.get('label')
          });
        }

        _this2._showConfirm({
          icon: _this2._defineModalIcon(action),
          title: title,
          textContent: textContent,
          hintContent: hintContent,
          items: modelKey !== 'client' ? items : [],
          closeBtnText: _this2.get('i18n').t('cancel'),
          confirmBtnText: _this2.get('i18n').t(action + 'Now'),
          confirmCallback: function confirmCallback() {
            // use the fallback action in case of a fallback
            _this2['_' + action](model, modelKey, routeTransition || modalConfig.routeTransition);
          },
          action: action,
          model: model,
          modelKey: modelKey,
          routeTransition: routeTransition || modalConfig.routeTransition
        });
      });
    },

    /**
     * get the class for the correct icon that will be displayed in the modal
     * @param action {string} the action that is being performed
     * @returns {string} the name of the css class
     * @private
     */
    _defineModalIcon: function _defineModalIcon(action) {
      switch (action) {
        case 'mail':
          return 'icomoon-paperplane';

        case 'success':
          return 'icomoon-checkmark';

        case 'delete':
          return 'icomoon-bin';

        case 'complete':
        case 'archive':
          return 'icomoon-archive-in';

        case 'copy':
          return 'icomoon-file-plus2';
      }
    },

    /**
     * Build a string that explains the current relation of the archive / deletion acton.
     * If an array of relations is given it will concat the explanations.
     * @param relations {Array} an array with model relations
     * @returns {string} a string with all explanations
     * @private
     */
    _buildRelationsStr: function _buildRelationsStr(relations) {
      var strRelations = '';
      var len = relations.length;

      if (len > 1) {
        for (var i = 0; i < len; i++) {
          if (i > 0 && i < len - 1) {
            strRelations = strRelations + ', ';
          } else if (i === len - 1) {
            strRelations = strRelations + ' ' + this.get('i18n').t('modal.item.and') + ' ';
          }

          var translationString = this.get('i18n').t((0, _emberInflector.pluralize)(relations[i]));

          if (translationString && translationString.toString && typeof translationString.toString === 'function') {
            translationString = translationString.toString();
          }

          strRelations = strRelations + translationString;
        }
      } else {
        strRelations = this.get('i18n').t((0, _emberInflector.pluralize)(relations[0]));
      }

      return strRelations;
    },

    /**
     * reactivates an archived record
     * @param model {Object} the model to perform the action on
     * @param modelKey {String}
     * @param routeTransition {String} transition to a certain route after the action has been performed
     * @private
     */
    _activate: function _activate(model, modelKey, routeTransition) {
      var activeUsers = this.controllerFor('application').get('activeUsers').length;

      if (activeUsers >= this.subscription.quantity && modelKey === 'user') {
        var modalController = this.controllerFor('modal.not-enough-seats');
        modalController.set('showModal', true);
      } else {
        this._storeAction(model, _environment.default.constants.STATUS_ENABLED, modelKey, routeTransition, 'alert.activated', 'alert.errorOnSave');
      }
    },
    _complete: function _complete(model, modelKey, routeTransition) {
      if (modelKey !== 'project') {
        throw 'This entity type cannot be completed';
      }

      var applicationController = this.controllerFor('application');
      applicationController.send('completeProject', model.get('id'));
    },

    /**
     * reactivates an archived record
     * @param model {Object} the model to perform the action on
     * @param modelKey {String}
     * @param routeTransition {String} transition to a certain route after the action has been performed
     * @private
     */
    _archive: function _archive(model, modelKey, routeTransition) {
      if (Number(this.get('accountService.accountSettings.accountOwner.id')) === model.get('idAsInt') && modelKey === 'user') {
        this.get('notifications').error('alert.accountOwnerActivation');
      } else {
        this._storeAction(model, _environment.default.constants.STATUS_ARCHIVED, modelKey, routeTransition, 'alert.archived');
      }
    },

    /**
     * deletes a record
     * @param model {Object} the model to perform the action on
     * @param modelKey {String}
     * @param routeTransition {String} transition to a certain route after the action has been performed
     * @private
     */
    _delete: function _delete(model, modelKey, routeTransition) {
      var _this3 = this;

      // destroys the active record
      model.destroyRecord().then(function () {
        _this3.get('notifications').success('alert.deleted', modelKey); // set _forceTransition to avoid modal confirm dialog


        _this3.set('_forceTransition', true);

        _this3.controller.transitionToRoute(routeTransition);

        Ember.run.later(function () {
          _this3.set('_forceTransition', false);
        }, 1000);
      }).catch(function (error) {
        _this3.get('notifications').errorWithObject(error, 'alert.errorOnSave', modelKey);
      });
    },

    /**
     * resets the password of a given user
     * @param user {Object} the user to perform the password-reset on
     * @private
     */
    _showResetPasswordModal: function _showResetPasswordModal(user) {
      var _this4 = this;

      this.get('session').ajax({
        url: (0, _buildUrl.default)(this.store, "users/".concat(user.get('id'), "/invalidatePasswordAndSendMail")),
        type: 'POST'
      }).then(function () {
        _this4.get('notifications').success('alert.passwordResetted', 'user');

        var oldStatus = user.passwordStatus;
        var isInvite = [_environment.default.constants.PASSWORD_STATUS_NEEDS_SET, _environment.default.constants.PASSWORD_STATUS_NOT_INVITED].includes(oldStatus);
        user.reload();

        _this4._showAlert({
          icon: _this4._defineModalIcon('mail'),
          title: _this4.get('i18n').t(isInvite ? 'confirmSendInviteEmail' : 'confirmResetPassword'),
          text: _this4.get('i18n').t('confirmResetPasswordDescription', {
            mail: user.get('email')
          })
        });
      }, function (err) {
        _this4.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'user');
      });
    },
    _copyLink: function _copyLink(user) {
      var _this5 = this;

      this.get('session').ajax({
        url: (0, _buildUrl.default)(this.store, "users/".concat(user.get('id'), "/copyUserPasswordLink")),
        type: 'POST'
      }).then(function (data) {
        _this5.get('notifications').success('alert.linkCopy', 'user');

        navigator.clipboard.writeText(data);
        user.reload();
      }, function (err) {
        _this5.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'user');
      });
    },

    /**
     * Sets the status of a record to a given value.
     *
     * @param model the model that will be saved
     * @param modelStatus the new status of the model
     * @param modelKey {String}
     * @param routeTransition the route to go to after the job was done
     * @param successTextId the id of the success alert-message
     * @param errorTextId the id of the error alert-message
     * @private
     */
    _storeAction: function _storeAction(model, modelStatus, modelKey, routeTransition, successTextId) {
      var _this6 = this;

      // set the model's status and set successor- / error-function
      model.set('status', modelStatus);
      model.save().then(function () {
        _this6.get('notifications').success(successTextId, modelKey);
      }, function (err) {
        _this6.get('notifications').errorWithObject(err, 'alert.errorOnSave', modelKey);

        model.rollbackAttributes();
      }); // return to the given route

      this.controller.transitionToRoute(routeTransition);
    },
    _transitionToList: function _transitionToList() {
      var route = this.get('listController').dasherize();
      this.transitionTo(route);
    },
    _resetAndCallback: function _resetAndCallback() {
      // together with the ember-data.dependent-relations.js initializer, at the time of writing this seems to be the
      // only way to reset records with relations
      this._transitionToList();

      Ember.run.later(function () {}, 1);
    },
    _afterSave: function _afterSave() {
      this._transitionToList();
    },
    _executeCallback: function _executeCallback(cb) {
      if (typeof cb === 'function') {
        cb();
      }
    },
    actions: {
      new: function _new() {
        var route = this.get('routeName');
        this.transitionTo(route + '.new');
      },
      confirmModal: function confirmModal(callback) {
        this._executeCallback(callback);
      },
      cancel: function cancel() {
        if (this.controller.get('model.hasDirtyAttributes')) {
          this.controller.get('model').rollbackAttributes();
        }

        this._transitionToList();
      },
      copy: function copy(model) {
        var canCopyModel = this.get('permissions.isRootAdmin');

        if (!canCopyModel) {
          var projectRelationModels = ['expense-category', 'client', 'task'];

          if (projectRelationModels.indexOf(model.constructor.modelName) !== -1) {
            canCopyModel = this.get('permissions.canManageProjectRelations');
          }
        }

        if (!canCopyModel && model.constructor.modelName === 'project') {
          canCopyModel = this.get('permissions.canManageOwnProjects');
        }

        if (!canCopyModel && model.constructor.modelName === 'user') {
          canCopyModel = this.get('permissions.canManageUsers');
        }

        if (!canCopyModel) return;
        var self = this;
        var listController = this.get('listController');
        var targetRoutePart = '.copy'; // e.g. transform projects.tasks.index => projects.tasks.copy
        //var route = this.get('routeName').replace(/\.index$/i, targetRoutePart);

        var route = this.get('routeName') + targetRoutePart;

        if (model.constructor.modelName === 'client' && this.get('routeName') === 'projects') {
          route = 'projects.clients.copy';
        }

        if (!listController) {
          self.transitionTo(route, model.id);
        } else {
          // when called from within the edit view, there is a listController property to rely on
          route = listController.dasherize() + targetRoutePart;
          self.transitionTo(route, model.id);
        }
      },
      resetPassword: function resetPassword(user) {
        this._showResetPasswordModal(user);
      },
      copyPasswordLink: function copyPasswordLink(user) {
        this._copyLink(user);
      },
      archive: function archive(model, routeTransition) {
        this._showModal(model, 'archive', routeTransition);
      },
      deactivate: function deactivate(model, routeTransition) {
        this._showModal(model, 'archive', routeTransition);
      },
      activate: function activate(model, routeTransition) {
        this._showActivateModal(model, routeTransition);
      },
      delete: function _delete(model) {
        this._showModal(model, 'delete');
      },
      analyze: function analyze(model) {
        var modelType = model.get('constructor.modelName');
        var route = 'analytics.' + modelType + 's.details';
        this.transitionTo(route, model.id);
      }
    }
  });

  var _default = ManageSuperRoute;
  _exports.default = _default;
});