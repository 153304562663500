define("coffeecup/components/gauge-chart", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isChartHovered: false,
    formatter: Ember.inject.service(),
    gaugeChartService: Ember.inject.service('charts/gauge'),
    classNames: ['gauge-chart-container'],
    isCurrencyNone: Ember.computed('currency', function () {
      return this.get('currency') === 'none';
    }),
    isCurrencyTime: Ember.computed('currency', function () {
      return this.get('currency') === 'time';
    }),
    isCurrencyMoney: Ember.computed('currency', function () {
      return this.get('currency') === 'money';
    }),
    isCurrencyPercentage: Ember.computed('currency', function () {
      return this.get('currency') === 'percentage';
    }),
    chartColors: [_environment.default.constants.REVENUE_COLOR, _environment.default.constants.REVENUE_COLOR],
    currentMode: null,
    customBands: null,
    showTotal: false,
    hideLegend: false,
    showTooltip: true,
    isSmall: false,
    currentTotal: 0,
    currentPercentage: 0,
    previousTotal: 0,
    total: 0,
    secondTotal: null,
    fixedPercentage: null,
    hideChartOnSmall: false,
    _calculateChart: Ember.observer('fixedPercentage', 'currentTotal', 'previousTotal', 'currentMode', 'data', 'customBands', function () {
      Ember.run.later(this, '_createChart');
    }),
    _createChart: function _createChart() {
      var i18n = this.get('i18n');
      var formatter = this.get('formatter');
      var chartContainer = 'chartdiv-' + this.get('elementId');
      var chartColors = this.get('chartColors');

      if (this.get('customBands')) {
        var _chartData = this.get('gaugeChartService').getChartData(this.get('customBands'), 0, this.get('isSmall'));

        var _chart = AmCharts.makeChart(chartContainer, _chartData);

        return;
      }

      var hasFixedPercentage = !Ember.isEmpty(this.get('fixedPercentage'));
      var currentMonthBillable = this.get('total');
      var lastMonthBillable = this.get('previousTotal');
      var percentageOfLastMonth = 100;

      if (lastMonthBillable) {
        percentageOfLastMonth = currentMonthBillable / lastMonthBillable * 100;
      } // This is just to keep the diagram percentage in a certain range


      if (percentageOfLastMonth >= 130 && !hasFixedPercentage) {
        var temp = percentageOfLastMonth - 100;
        temp = Math.log(temp) / 0.1;
        percentageOfLastMonth = 100 + temp;

        if (percentageOfLastMonth > 190) {
          percentageOfLastMonth = 190;
        }
      }

      if (hasFixedPercentage) {
        percentageOfLastMonth = Math.abs(this.get('fixedPercentage')) * 100 * 2;
        percentageOfLastMonth = Math.min(200, percentageOfLastMonth);
      }

      var formattedAmount = this.get('isCurrencyMoney') ? formatter.formatCurrency(currentMonthBillable, true) : formatter.formatDuration(currentMonthBillable);
      var lastMonthFormattedAmount = this.get('isCurrencyMoney') ? formatter.formatCurrency(lastMonthBillable, true) : formatter.formatDuration(lastMonthBillable);
      var surplus = currentMonthBillable / lastMonthBillable - 1;
      var surplusFormattedAmount = isFinite(surplus) ? formatter.formatPercentage(surplus) : 'n/a';
      var lastMonthBand = {
        alpha: 0.3,
        color: chartColors[0],
        endValue: hasFixedPercentage ? 0 : 100,
        id: 'ToGo',
        innerRadius: '85%',
        radius: '100%',
        startValue: 0
      };
      var surplusBand = {
        alpha: 0,
        endValue: 0,
        innerRadius: '85%',
        radius: '95%',
        startValue: 0
      };
      var currentMonthBand = {
        alpha: 1,
        color: chartColors[0],
        endValue: percentageOfLastMonth,
        id: 'This Month',
        innerRadius: '85%',
        radius: '100%',
        startValue: 0
      };
      if (this.get('showTooltip')) lastMonthBand.balloonText = i18n.t('gaugeChartBalloonText', {
        currentAmount: formattedAmount,
        lastAmount: lastMonthFormattedAmount,
        delta: surplusFormattedAmount
      });
      if (this.get('showTooltip')) currentMonthBand.balloonText = i18n.t('gaugeChartBalloonText', {
        currentAmount: formattedAmount,
        lastAmount: lastMonthFormattedAmount,
        delta: surplusFormattedAmount
      });

      if (percentageOfLastMonth > 100) {
        surplusBand = {
          alpha: 1,
          color: chartColors[1],
          endValue: percentageOfLastMonth,
          id: 'Last Period',
          innerRadius: '85%',
          radius: '95%',
          startValue: 100
        };
        if (this.get('showTooltip')) surplusBand.balloonText = i18n.t('gaugeChartBalloonText', {
          currentAmount: formattedAmount,
          lastAmount: lastMonthFormattedAmount,
          delta: surplusFormattedAmount
        });
      }

      var chartStartValue = hasFixedPercentage ? 0 : 100;
      var chartData = this.get('gaugeChartService').getChartData([lastMonthBand, currentMonthBand, surplusBand], chartStartValue, this.get('isSmall'));
      var chart = AmCharts.makeChart(chartContainer, chartData);
    },
    data: '',
    didInsertElement: function didInsertElement() {
      this._calculateChart();
    },
    actions: {
      setCurrentMode: function setCurrentMode(newMode) {
        this.set('currentMode', newMode);
      }
    }
  });

  _exports.default = _default;
});