define("coffeecup/controllers/analytics/projects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * filter properties are set by filtered-content mixin
   * @see mixins/filtered-content.js
   */
  var _default = Ember.Controller.extend({
    /*
    INJECTIONS
    */
    application: Ember.inject.controller(),
    detailsController: Ember.inject.controller('analytics.projects.details'),
    router: Ember.inject.service(),
    permissions: Ember.inject.service(),
    projectService: Ember.inject.service('project'),
    easybill: Ember.inject.service(),

    /*
    PROPERTIES
     */
    openModal: false,

    /*
    COMPUTED PROPERTIES
     */
    selectedProject: Ember.computed.alias('detailsController.selectedProject'),
    startDate: Ember.computed.reads('detailsController.startDate'),
    endDate: Ember.computed.reads('detailsController.endDate'),
    hasNoProjects: Ember.computed.equal('projects.length', 0),

    /**
     * @return array of active users sorted by currentUser, lastname
     */
    projectsSorted: Ember.computed('projects', function () {
      var projectsSorted = this.get('projects').sortBy('label');
      return projectsSorted;
    }),
    projectsGrouped: Ember.computed('projectsSorted', {
      get: function get() {
        return this.get('projectService').projectsGroupedByClientAndInactive(this.get('projectsSorted'));
      }
    }),

    /*
    OBSERVERS
     */
    changeToProject: Ember.observer('selectedProject.id', function () {
      var selectedProject = this.get('selectedProject.id');

      if (selectedProject) {
        this.get('router').transitionTo('analytics.projects.details', selectedProject);
      }
    }),

    /**
     * ACTIONS
     */
    actions: {
      billProject: function billProject(item) {
        this.get('application').send('billProject', item.id);
      }
    }
  });

  _exports.default = _default;
});