define("coffeecup/controllers/projects/expense-categories/edit", ["exports", "coffeecup/controllers/manage/_super-edit", "ember-changeset-validations", "coffeecup/validations/expense-category", "ember-changeset", "coffeecup/mixins/manage-tags", "coffeecup/config/environment"], function (_exports, _superEdit, _emberChangesetValidations, _expenseCategory, _emberChangeset, _manageTags, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superEdit.default.extend(_manageTags.default, {
    store: Ember.inject.service(),
    headline: Ember.computed.alias('model.label'),
    colors: null,
    focus: 'label',
    model: null,
    errors: {},
    ExpenseCategoryValidations: _expenseCategory.default,
    possibleIcons: _environment.default.constants.EXPENSE_CATEGORY_ICONS,
    actions: {
      backAction: function backAction() {
        this.transitionToRoute('projects.expense-categories');
      },
      saveExpenseCategory: function saveExpenseCategory() {
        var _this = this;

        Ember.run.next(function () {
          _this.set('errors', {});

          if (_this.get('model.isDirty')) {
            var changeset = (0, _emberChangeset.Changeset)(_this.get('model'), (0, _emberChangesetValidations.default)(_expenseCategory.default), _expenseCategory.default);
            changeset.validate().then(function () {
              if (changeset.get('isValid')) {
                _this.get('model').save().then(function () {
                  _this.get('notifications').success('alert.saved', 'expense-categories.title');
                }).catch(function (err) {
                  _this.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'expense-categories.title');
                });
              } else {
                _this.set('errors', changeset.error);

                _this.get('notifications').info('pleaseCheckYourInputs');
              }
            }).catch(function () {
              _this.get('notifications').info('pleaseCheckYourInputs');
            });
          }
        });
      },
      resetExpenseCategory: function resetExpenseCategory() {
        this.get('model').rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});