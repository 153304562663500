define("coffeecup/components/analytics-module/project/duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    project: null,
    pieChartData: [],
    startDate: Ember.computed.reads('project.startDate'),
    endDate: Ember.computed.reads('project.endDate'),
    isOverDue: Ember.computed.reads('project.isOverDue'),
    remainingDaysForProject: Ember.computed.reads('project.remainingDaysForProject'),
    daysSinceProjectStart: Ember.computed.reads('project.daysSinceProjectStart'),
    projectDurationInWeeks: Ember.computed.reads('project.projectDurationInWeeks'),
    didInsertElement: function didInsertElement() {
      this._updateUi();
    },
    _updateUi: Ember.observer('startDate', 'endDate', function () {
      var remainingDaysForProject = this.get('project.remainingDaysForProject');

      if (remainingDaysForProject) {
        var startDate = this.get('startDate');
        var endDate = this.get('endDate');
        this.set('pieChartData', [{
          value: remainingDaysForProject < 0 ? 1 : this.get('clock.now').diff(startDate, 'days')
        }, {
          value: remainingDaysForProject < 0 ? 0 : endDate.diff(this.get('clock.now'), 'days')
        }]);
      } else {
        this.set('pieChartData', [{
          value: 1
        }, {
          value: 0
        }]);
      }
    })
  });

  _exports.default = _default;
});