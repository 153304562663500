define("coffeecup/components/planner/project-user", ["exports", "coffeecup/components/planner/user-project"], function (_exports, _userProject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userProject.default.extend();

  _exports.default = _default;
});