define("coffeecup/components/input/text-field-wrapper", ["exports", "ember-cli-tooltipster/components/tool-tipster", "coffeecup/mixins/has-error"], function (_exports, _toolTipster, _hasError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * DEPRECATED!!
   * USE CC-TEXT-FIELD INSTEAD!!
   */
  var _default = _toolTipster.default.extend(_hasError.default, {
    classNameBindings: ['defaultClass', 'isRequired'],
    defaultClass: 'form-group',
    labelClass: '',
    inputClass: '',
    inputContainerClass: '',
    value: null,
    type: null,
    errors: null,
    validations: null,
    placeholder: null,
    disabled: false,
    key: null,
    focus: null,
    isFocused: false,
    readonly: false,
    copyButtonSelector: null,
    onCopySuccess: null,
    onCopyError: null,
    _clipboard: null,
    didInsertElement: function didInsertElement() {
      var $input = this.$('input');
      Ember.run.next(this, function () {
        var _this = this;

        $input.on('focus', function () {
          Ember.run(function () {
            _this.set('isFocused', true);
          });
        });
        $input.on('blur', function () {
          Ember.run(function () {
            _this.set('isFocused', false);

            _this.set('focus', null);
          });
        });

        this._focus();
      });
      var copyButtonSelector = this.get('copyButtonSelector');

      if (copyButtonSelector) {
        var clipboard = new Clipboard(copyButtonSelector, {
          target: function target() {
            return $input.get(0);
          }
        });
        var onCopySuccess = this.get('onCopySuccess');

        if (onCopySuccess) {
          clipboard.on('success', onCopySuccess);
        }

        var onCopyError = this.get('onCopyError');

        if (onCopyError) {
          clipboard.on('error', onCopyError);
        }

        this.set('_clipboard', clipboard);
      }
    },
    willDestroyElement: function willDestroyElement() {
      var clipboard = this.get('_clipboard');

      if (clipboard) {
        clipboard.destroy();
      }

      var $input = this.$('input'); // unbind focus and blur

      $input.off('focus blur');
    },
    label: Ember.computed('i18n.locale', {
      get: function get() {
        return this.get('i18n').t(this.get('key'));
      }
    }),
    focusField: Ember.observer('focus', function () {
      this._focus();
    }),
    _focus: function _focus() {
      var focus = this.get('focus');
      var key = this.get('key');

      if (focus && key && focus === key && this.$('input') && this.$('input').length > 0) {
        this.$('input').focus();
      }
    },
    // computed values
    interpolatedPlaceholder: Ember.computed('key', 'placeholder', {
      get: function get() {
        var placeholder = this.get('i18n').t(this.get('key'));
        return this.get('i18n').t('enterKey', {
          key: placeholder
        });
      }
    }),
    // https://github.com/emberjs/ember.js/issues/11828#issuecomment-140313886
    interpolatedReadonly: Ember.computed('readonly', {
      get: function get() {
        if (this.get('readonly')) {
          return true;
        } else {
          return undefined;
        }
      }
    }),
    isRequired: Ember.computed('validations', {
      get: function get() {
        var validations = this.get('validations.presence');

        if (validations === true) {
          return 'required';
        }
      }
    }),
    labelClasses: Ember.computed('labelClass', {
      get: function get() {
        return this.get('labelClass') + ' control-label';
      }
    }),
    inputClasses: Ember.computed('inputClass', {
      get: function get() {
        return this.get('inputClass') + ' form-control';
      }
    }),
    inputContainerClasses: Ember.computed('inputContainerClass', {
      get: function get() {
        return this.get('inputContainerClass') + ' controls';
      }
    }),
    // tooltip content
    content: Ember.computed.alias('translatedError'),
    // side if the tooltip
    side: ['bottom'],
    // tooltip will only show when input is focused
    triggerEvent: 'custom',
    _triggerTooltip: Ember.observer('hasError', function () {
      var _this2 = this;

      Ember.run.next(function () {
        var tooltip = _this2.get('tooltipsterInstance');

        var hasError = _this2.get('hasError');

        if (tooltip) {
          if (hasError) {
            tooltip.open();
          } else {
            tooltip.close();
          }
        }
      });
    }),
    keyPress: function keyPress(event) {
      if (event.key === 'Enter') {
        this.$('input').blur();
      }
    },
    actions: {
      cancel: function cancel() {
        this.$('input').blur();
      }
    }
  });

  _exports.default = _default;
});