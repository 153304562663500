define("coffeecup/components/time-entries/header/absence-icon", ["exports", "ember-cli-tooltipster/components/tool-tipster", "coffeecup/utils/nl2br"], function (_exports, _toolTipster, _nl2br) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _toolTipster.default.extend({
    classNames: ['absence-icon-wrapper'],
    formatter: Ember.inject.service(),
    absence: null,
    tooltipPlace: 'bottom',
    contentAsHTML: true,
    content: Ember.computed('absence', {
      get: function get() {
        var absence = this.get('absence');
        var formatter = this.get('formatter');

        if (absence.get('name')) {
          return Ember.String.htmlSafe(absence.get('name'));
        } else {
          var i18n = this.get('i18n');
          var html = '';

          if (absence.get('isOneDay')) {
            html = i18n.t('absenceDurationDescriptionOneDay', {
              absenceType: absence.get('absenceType.localizedLabel'),
              startDate: formatter.formatDate(absence.get('startDate'))
            });
          } else {
            html = i18n.t('absenceDurationDescription', {
              absenceType: absence.get('absenceType.localizedLabel'),
              startDate: formatter.formatDate(absence.get('startDate')),
              endDate: formatter.formatDate(absence.get('endDate'))
            });
          }

          if (absence.get('halfDay')) {
            html += ' (' + i18n.t('halfDay') + ')';
          }

          var comment = absence.get('comment');

          if (comment) {
            html += '<br><br><b>' + i18n.t('comment') + ':</b><br>' + (0, _nl2br.default)(comment);
          }

          return Ember.String.htmlSafe(html);
        }
      }
    })
  });

  _exports.default = _default;
});