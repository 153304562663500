define("coffeecup/components/planner/planner-assignment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['planner-assignment'],
    assignment: null,
    activeModal: Ember.inject.service('planner.active-modal'),
    isActive: Ember.computed('activeModal.plannerAssignment', function () {
      return this.get('assignment.id') === this.get('activeModal.plannerAssignment');
    }),
    editable: null,
    useProjectColor: null
  });

  _exports.default = _default;
});