define("coffeecup/components/time-entry", ["exports", "coffeecup/config/environment", "coffeecup/utils/format-duration"], function (_exports, _environment, _formatDuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TimeEntryComponent = Ember.Component.extend({
    timeEntryService: Ember.inject.service('time-entry'),
    accountSettings: Ember.inject.service('account'),
    permissions: Ember.inject.service(),
    currentUser: null,
    timeEntryAccuracies: [],

    /**
     * time-entry
     */
    item: null,
    accuracyOfTimeEntry: Ember.computed('item.id', 'timeEntryAccuracies.[]', function () {
      if (this.get('timeEntryAccuracies')) {
        return this.get('timeEntryAccuracies').findBy('timeEntry', this.get('item.id'));
      } else {
        return null;
      }
    }),
    allowBreaks: true,
    classNameBindings: ['item.isToday:today', ':time-entry', 'item.isBreak:is-break', 'isEditDisabled:no-edit', 'isResizeDisabled:no-resize', 'isDraggingDisabled:no-draggable', 'isDuplicate:duplicate', 'holdingModifier:copying', 'isCompactLayout:compact'],
    attributeBindings: ['style:style', 'bgColor:data-bg-color'],
    style: Ember.computed('height', {
      get: function get() {
        var style = "height: ".concat(this.get('height'), "px;");
        return Ember.String.htmlSafe(style);
      }
    }),
    showQuality: Ember.computed('item.{running,isBreak}', 'showTimeEntryQuality', function () {
      var canReadQuality = this.get('currentUserIsOwnerOfTimeEntry') || this.get('permissions.isRootAdmin');
      return !this.get('item.running') && !this.get('item.isBreak') && this.get('showTimeEntryQuality') && canReadQuality;
    }),
    isEditDisabled: Ember.computed('item.isEditDisabled', 'allowBreaks', 'item.isBreak', function () {
      return this.get('item.isEditDisabled') || this.get('item.isBreak') && !this.get('allowBreaks');
    }),
    isDraggingDisabled: Ember.computed.or('item.isEditDisabled', 'currentUserIsNotOwnerOfTimeEntry', 'item.running', 'isWeekValidated'),
    isResizeDisabled: Ember.computed('item.isEditDisabled', 'showBillingDetails', 'currentUserIsOwnerOfTimeEntry', 'currentUserIsPmOfTimeEntry', 'item.isBreak', 'item.running', 'isWeekValidated', function () {
      var isEditDisabled = this.get('item.isEditDisabled');
      var showBillingDetails = this.get('showBillingDetails');
      var currentUserIsOwnerOfTimeEntry = this.get('currentUserIsOwnerOfTimeEntry');
      var currentUserIsPmOfTimeEntry = this.get('currentUserIsPmOfTimeEntry');
      var isBreak = this.get('item.isBreak');
      var isRunning = this.get('item.running');
      var isWeekValidated = this.get('isWeekValidated');

      if (isBreak && !currentUserIsOwnerOfTimeEntry) {
        return true;
      }

      if (isEditDisabled) {
        return true;
      }

      if (isWeekValidated && !currentUserIsPmOfTimeEntry) {
        return true;
      }

      if (!showBillingDetails && !currentUserIsOwnerOfTimeEntry) {
        return true;
      }

      if (isRunning && !currentUserIsOwnerOfTimeEntry) {
        return true;
      }

      return false;
    }),
    isResizeEnabled: Ember.computed.not('isResizeDisabled'),
    validationObj: null,
    isWeekValidated: Ember.computed('validationObj.isApproved', 'permissions.worktimeValidationActive', function () {
      var worktimeValidationActive = this.get('permissions.worktimeValidationActive');
      var isApproved = this.get('validationObj.isApproved');
      return worktimeValidationActive && isApproved;
    }),
    isWeekNotValidated: Ember.computed('validationObj.isApproved', 'permissions.worktimeValidationActive', function () {
      var isValidationDisabled = !this.get('permissions.worktimeValidationActive');
      var isNotApproved = this.get('validationObj.isNotApproved');
      return isValidationDisabled || isNotApproved;
    }),
    minuteSnap: null,
    resizeMinuteSnap: _environment.default.constants.RESIZE_MINUTE_SNAP,
    currentUserIsPmOfTimeEntry: false,
    currentUserIsOwnerOfTimeEntry: Ember.computed('currentUser.id', 'item.user.id', {
      get: function get() {
        return this.get('currentUser.id') === this.get('item.user.id');
      }
    }),
    currentUserIsNotOwnerOfTimeEntry: Ember.computed.not('currentUserIsOwnerOfTimeEntry'),
    canEditDurationRounded: Ember.computed.and('currentUserIsPmOfTimeEntry', 'item.isWork', 'hasHourlyRate'),
    hasHourlyRate: Ember.computed.bool('finalHourlyRate'),
    projectIsBillable: Ember.computed.not('item.project.isNotBillable'),
    showBillingDetails: Ember.computed.and('canEditDurationRounded', 'projectIsBillable'),
    currentUserCanStartOrStop: Ember.computed('currentUserIsOwnerOfTimeEntry', 'item.running', 'accountSettings.accountSettings.timerModeIsStartTimeEndTime', function () {
      var timeEntryFromYesterday = moment().isAfter(moment(this.get('item.day')), 'day');
      return this.get('currentUserIsOwnerOfTimeEntry') || this.get('item.running') && timeEntryFromYesterday && this.get('item.duration') > _environment.default.constants.ALLOW_TIMEENTRIES_TO_BE_STOPPED_AFTER;
    }),
    propertyToUpdateOnResize: Ember.computed('currentUserIsPmOfTimeEntry', 'currentUserIsOwnerOfTimeEntry', 'item.isBreak', 'item.isEditDisabled', 'validationObj.isApproved', function () {
      var isEditDisabled = this.get('item.isEditDisabled');
      var currentUserIsPmOfTimeEntry = this.get('currentUserIsPmOfTimeEntry');
      var currentUserIsOwnerOfTimeEntry = this.get('currentUserIsOwnerOfTimeEntry');
      var isBreak = this.get('item.isBreak');
      var isApproved = this.get('validationObj.isApproved');

      if (isEditDisabled) {
        return null;
      }

      if (isBreak && currentUserIsOwnerOfTimeEntry && !isApproved) {
        return 'duration';
      }

      if (currentUserIsOwnerOfTimeEntry && currentUserIsPmOfTimeEntry && !isApproved) {
        return _environment.default.constants.SYNC;
      }

      if (currentUserIsPmOfTimeEntry) {
        return 'durationRounded';
      }

      if (currentUserIsOwnerOfTimeEntry && !isApproved) {
        return 'duration';
      }

      return null;
    }),
    durationHeightProperty: Ember.computed('currentUserIsPmOfTimeEntry', 'currentUserIsOwnerOfTimeEntry', 'item.isBreak', 'validationObj.isApproved', function () {
      var currentUserIsPmOfTimeEntry = this.get('currentUserIsPmOfTimeEntry');
      var currentUserIsOwnerOfTimeEntry = this.get('currentUserIsOwnerOfTimeEntry');
      var isBreak = this.get('item.isBreak');
      var isApproved = this.get('validationObj.isApproved');

      if (isBreak) {
        return 'duration';
      }

      if (currentUserIsOwnerOfTimeEntry && currentUserIsPmOfTimeEntry && isApproved) {
        return 'durationRounded';
      }

      if (currentUserIsPmOfTimeEntry && currentUserIsOwnerOfTimeEntry) {
        return 'duration';
      }

      if (currentUserIsOwnerOfTimeEntry) {
        return 'duration';
      }

      if (currentUserIsPmOfTimeEntry) {
        return 'durationRounded';
      }

      return 'duration';
    }),
    durationHeight: Ember.computed('durationHeightProperty', 'item.duration', 'item.durationRounded', {
      get: function get() {
        var durationHeightProperty = this.get('durationHeightProperty');
        var duration = this.get('item').get(durationHeightProperty);
        return (0, _formatDuration.calculateUIHeightForTime)(duration) - 2 * _environment.default.constants.TIME_ENTRY_ITEM_MARGIN;
      }
    }),
    backgroundColor: null,
    isDragging: false,
    isResizing: false,
    isToday: Ember.computed.alias('item.isToday'),
    durationRounded: Ember.computed('currentUserIsPmOfTimeEntry', 'currentUserIsOwnerOfTimeEntry', 'item.durationRounded', 'item.projectRoundingAmount', function () {
      var duration = this.get('item.durationRounded');
      var projectRoundingType = this.get('item.project.roundingType');
      var projectRoundingAmount = this.get('item.projectRoundingAmount');
      var durationRounded = (0, _formatDuration.getRoundedDuration)(duration, projectRoundingAmount, projectRoundingType);
      return durationRounded / 3600;
    }),
    hourlyRate: Ember.computed('item.project', 'item.project.hourlyRate', 'item.project.billBy', 'item.project.budgetBy', 'item.task', 'item.project.taskAssignments.@each.hourlyRate', 'item.project.taskAssignments.@each.billable', 'item.user', 'item.project.userAssignments.@each.hourlyRate', 'item.project.userAssignments.@each.billable', 'item.finalHourlyRate', {
      get: function get() {
        var userId = this.get('item.user.id');
        var projectHourlyRate = this.get('item.project.hourlyRate');
        var billBy = this.get('item.project.billBy');
        var taskId = this.get('item.task.id');
        var userAssignments = this.get('item.project.userAssignments');
        var taskAssignments = this.get('item.project.taskAssignments');
        var taskAssignment = taskAssignments && typeof taskAssignments.findBy !== 'undefined' && taskAssignments.findBy('task.id', taskId);

        if (taskId && taskAssignment && !taskAssignment.get('billable')) {
          return 0;
        }

        switch (billBy) {
          // no hourly rate
          case _environment.default.constants.BILL_BY_NONE:
            return 0;
          // fixed hourly rate for every user and task

          case _environment.default.constants.BILL_BY_PROJECT:
            if (projectHourlyRate) {
              return projectHourlyRate;
            }

            break;
          // by task aka. taskAssignment

          case _environment.default.constants.BILL_BY_TASK:
            if (taskId) {
              if (taskAssignment) {
                return taskAssignment.get('hourlyRate') * taskAssignment.get('billable');
              }
            }

            break;
          // by user aka. userAssignment

          case _environment.default.constants.BILL_BY_USER:
            var userAssignment = userAssignments.findBy('user.id', userId);

            if (userAssignment) {
              return userAssignment.get('hourlyRate');
            }

            break;
        }
      }
    }),
    compactThreshold: _environment.default.constants.TIME_ENTRY_HEIGHT_FACTOR - 10,

    /**
     * Compact layout hides amount and task label so that project/company is more visible
     */
    isCompactLayout: Ember.computed('durationHeight', {
      get: function get() {
        return this.get('durationHeight') < this.get('compactThreshold');
      }
    }),
    height: Ember.computed.alias('durationHeight'),
    bgColorType: Ember.computed('item.project.color', 'item.task.color', 'backgroundColor', {
      get: function get() {
        var colorOption = this.get('backgroundColor');

        switch (colorOption) {
          case _environment.default.constants.TIME_ENTRY_COLOR_TASK:
            return 'task';

          case _environment.default.constants.TIME_ENTRY_COLOR_PROJECT:
            return 'project';

          default:
            return null;
        }
      }
    }),
    bgColor: Ember.computed('item.project.color', 'item.task.color', 'backgroundColor', {
      get: function get() {
        var colorType = this.get('bgColorType');

        if (colorType) {
          return this.get('item.' + colorType + '.color.hexString');
        }

        return '';
      }
    }),
    overlayStyle: Ember.computed('durationHeight', {
      get: function get() {
        var res = '';
        var height = Math.max(this.get('durationHeight'), _environment.default.constants.TIME_ENTRY_ITEM_MIN_HEIGHT);
        res = "height: ".concat(height, "px;");
        return Ember.String.htmlSafe(res);
      }
    }),
    isDuplicate: false,
    duplicateTimeEntry: function duplicateTimeEntry() {
      this.sendAction('onDuplicate', this.get('item'));
    },
    triggerDuplicate: Ember.observer('isDragging', 'holdingModifier', function () {
      if (this.get('isDragging')) {
        if (this.get('holdingModifier')) {
          this.duplicateTimeEntry();
        } else {
          this.sendAction('onCancelDuplicate', this.get('item'));
        }
      }
    }),

    /**
     * Indicates whether the user is currently holding the modifier key 'alt'.
     */
    holdingModifier: false,
    handleKeyDown: function handleKeyDown(event) {
      if (!this.get('isDestroyed')) {
        if (event.altKey) {
          this.set('holdingModifier', true);
        }
      }
    },
    handleKeyUp: function handleKeyUp() {
      if (!this.get('isDestroyed')) {
        if (this.set('holdingModifier')) {
          this.set('holdingModifier', false);
        }
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments); // Observer isSorting property from parent sortable-item component


      var component = this;

      if (this.get('parentView')) {
        this.get('parentView').addObserver('isSorting', function () {
          if (!component.get('isDestroyed')) {
            component.set('isDragging', this.get('isSorting'));
          }
        });
      }

      this.$(window).keydown(function (event) {
        return _this.handleKeyDown(event);
      });
      this.$(window).keyup(function (event) {
        return _this.handleKeyUp(event);
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var item = this.get('item');
      this.get('timeEntryService').currentUserIsPmOfTimeEntry(item).then(function (result) {
        _this2.set('currentUserIsPmOfTimeEntry', !!result);
      });
    },
    actions: {
      edit: function edit() {
        this.sendAction('edit', this.get('item'));
      },
      play: function play() {
        this.sendAction('play', this.get('item'));
      },
      pause: function pause() {
        this.sendAction('pause', this.get('item'));
      },
      startResizeAction: function startResizeAction(data) {
        this.sendAction('startResizeAction', data);
        this.set('isResizing', true);
      },
      resizeAction: function resizeAction(data) {
        this.sendAction('resizeAction', data);
      },
      stopResizeAction: function stopResizeAction(data) {
        if (this.get('isResizing')) {
          this.sendAction('stopResizeAction', data);
          this.set('isResizing', false);
        }
      },
      cancelResize: function cancelResize() {
        if (this.get('isResizing')) {
          this.set('isResizing', false);
        }
      }
    }
  });
  var _default = TimeEntryComponent;
  _exports.default = _default;
});