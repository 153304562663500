define("coffeecup/components/absence-type-row", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    rollingBack: false,
    _saveOnChange: Ember.observer('absenceType.{canBeRequested,defaultCountsAsVacation,defaultWorkHoursExpected,detailsVisibleToOtherUsers,defaultRemunerationActive,defaultReducesOvertime}', function () {
      if (!this.get('rollingBack')) {
        Ember.run.once(this, '_saveAbsenceType');
      } else {
        this.set('rollingBack', false);
      }
    }),
    _saveAbsenceType: function _saveAbsenceType() {
      var _this = this;

      this.get('absenceType').save().then(function () {
        _this.get('notifications').success('alert.saved', 'absenceType');
      }).catch(function () {
        _this.set('rollingBack', true);

        _this.get('absenceType').rollbackAttributes();

        _this.get('notifications').error('alert.errorOnSave', 'absenceType');
      });
    },
    actions: {
      saveAbsenceType: function saveAbsenceType() {
        this._saveOnChange();
      },
      deleteAbsenceType: function deleteAbsenceType() {
        var _this2 = this;

        var absenceType = this.get('absenceType');
        absenceType.set('status', _environment.default.constants.STATUS_ARCHIVED);
        absenceType.save().then(function () {
          _this2.get('notifications').success('alert.deleted', 'absenceType');
        });
      },
      saveAbsenceTypeLabel: function saveAbsenceTypeLabel(label) {
        this.set('absenceType.label', label);

        this._saveOnChange();
      }
    }
  });

  _exports.default = _default;
});