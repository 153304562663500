define("coffeecup/components/button-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'a',
    classNames: 'btn',
    param: null,
    param2: null,
    bubbles: true,
    click: function click() {
      this.sendAction('action', this.get('param'), this.get('param2'));
      return this.get('bubbles');
    }
  });

  _exports.default = _default;
});