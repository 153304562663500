define("coffeecup/components/manage/projects/budget-configurator", ["exports", "coffeecup/utils/format-duration", "coffeecup/config/environment"], function (_exports, _formatDuration, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * budget & time constants
     */
    BILL_BY_NONE: _environment.default.constants.BILL_BY_NONE,
    // do not apply hourly rate
    BILL_BY_CLIENT: _environment.default.constants.BILL_BY_CLIENT,
    // client hourly rate
    BILL_BY_PROJECT: _environment.default.constants.BILL_BY_PROJECT,
    // project hourly rate
    BILL_BY_USER: _environment.default.constants.BILL_BY_USER,
    // user hourly rate
    BILL_BY_TASK: _environment.default.constants.BILL_BY_TASK,
    // task hourly rate
    BUDGET_BY_EFFORT: _environment.default.constants.BUDGET_BY_EFFORT,
    // no budget, billing by effort
    BUDGET_BY_TOTAL_HOURS: _environment.default.constants.BUDGET_BY_TOTAL_HOURS,
    // total project hours
    BUDGET_BY_TOTAL_AMOUNT: _environment.default.constants.BUDGET_BY_TOTAL_AMOUNT,
    // total project amount
    BUDGET_BY_USER: _environment.default.constants.BUDGET_BY_USER,
    // budget per user
    BUDGET_BY_TASK: _environment.default.constants.BUDGET_BY_TASK,
    // budget per task
    ROUNDING_TYPE_NONE: _environment.default.constants.ROUNDING_TYPE_NONE,
    ROUNDING_TYPE_UP: _environment.default.constants.ROUNDING_TYPE_UP,
    ROUNDING_TYPE_DOWN: _environment.default.constants.ROUNDING_TYPE_DOWN,
    ROUNDING_AMOUNT_5: _environment.default.constants.ROUNDING_AMOUNT_5,
    ROUNDING_AMOUNT_15: _environment.default.constants.ROUNDING_AMOUNT_15,
    ROUNDING_AMOUNT_30: _environment.default.constants.ROUNDING_AMOUNT_30,
    isModeFixedPrice: Ember.computed.equal('selectedMode', 'budgetByFixedPrice'),
    isModeByEffort: Ember.computed.equal('selectedMode', 'budgetByEffort'),
    isModeNotBillable: Ember.computed.equal('selectedMode', 'notBillable'),
    isBudgetByTotalHours: Ember.computed.equal('project.budgetBy', _environment.default.constants.BUDGET_BY_TOTAL_HOURS),
    isBudgetByTotalAmount: Ember.computed.equal('project.budgetBy', _environment.default.constants.BUDGET_BY_TOTAL_AMOUNT),
    isBudgetByUser: Ember.computed.equal('project.budgetBy', _environment.default.constants.BUDGET_BY_USER),
    isBudgetByTask: Ember.computed.equal('project.budgetBy', _environment.default.constants.BUDGET_BY_TASK),
    isBudgetByEffort: Ember.computed.equal('project.budgetBy', _environment.default.constants.BUDGET_BY_EFFORT),
    isBillByClient: Ember.computed.equal('project.billBy', _environment.default.constants.BILL_BY_CLIENT),
    isBillByProject: Ember.computed.equal('project.billBy', _environment.default.constants.BILL_BY_PROJECT),
    isBillByTask: Ember.computed.equal('project.billBy', _environment.default.constants.BILL_BY_TASK),
    isBillByUser: Ember.computed.equal('project.billBy', _environment.default.constants.BILL_BY_USER),
    isBillByNone: Ember.computed.equal('project.billBy', _environment.default.constants.BILL_BY_NONE),
    showSelector: true,
    budgetInPersonDays: Ember.computed('project.budgetHours', 'i18n.locale', function () {
      return (0, _formatDuration.formatAsPersonDays)(this.get('project.budgetHours'), _environment.default.constants.STANDARD_WORKDAY_HOURS) + ' ' + this.get('i18n').t('abbr.personDays');
    }),

    /**
     * @return boolean whether the 'use client's hourly rate' button should be displayed
     */
    showUseClientHourlyRateButton: Ember.computed('isModeNotBillable', 'project.client', 'project.hourlyRate', function () {
      var isModeNotBillable = this.get('isModeNotBillable');
      var clientId = this.get('project.client.id');
      var clientHourlyRate = this.get('project.client.hourlyRate');
      var hourlyRate = this.get('project.hourlyRate');
      return !isModeNotBillable && clientId && clientHourlyRate !== hourlyRate;
    }),
    availableRoundingTypes: Ember.computed(function () {
      var i18n = this.get('i18n');
      var roundingNone = Ember.Object.extend({
        id: _environment.default.constants.ROUNDING_TYPE_NONE,
        label: i18n.t('doNotRound')
      }).create();
      var roundingUp = Ember.Object.extend({
        id: _environment.default.constants.ROUNDING_TYPE_UP,
        label: i18n.t('roundUp')
      }).create();
      var roundingDown = Ember.Object.extend({
        id: _environment.default.constants.ROUNDING_TYPE_DOWN,
        label: i18n.t('roundDown')
      }).create();
      return [roundingNone, roundingUp, roundingDown];
    }),
    selectedRoundingType: Ember.computed('project.roundingType', 'availableRoundingTypes', function () {
      var _this = this;

      var availableRoundingTypes = this.get('availableRoundingTypes');

      if (!availableRoundingTypes) {
        return null;
      }

      return availableRoundingTypes.find(function (x) {
        return x.get('id') === _this.get('project.roundingType');
      });
    }),
    selectedRoundingAmount: Ember.computed('project.roundingAmount', 'availableRoundingAmounts', function () {
      var _this2 = this;

      var availableRoundingAmounts = this.get('availableRoundingAmounts');

      if (!availableRoundingAmounts) {
        return null;
      }

      return availableRoundingAmounts.find(function (x) {
        return x.get('id') === _this2.get('project.roundingAmount');
      });
    }),
    availableRoundingAmounts: Ember.computed('project.roundingType', function () {
      var i18n = this.get('i18n');
      var rounding5 = Ember.Object.extend({
        id: _environment.default.constants.ROUNDING_AMOUNT_5,
        label: i18n.t('5Minutes')
      }).create();
      var rounding15 = Ember.Object.extend({
        id: _environment.default.constants.ROUNDING_AMOUNT_15,
        label: i18n.t('15Minutes')
      }).create();
      var rounding30 = Ember.Object.extend({
        id: _environment.default.constants.ROUNDING_AMOUNT_30,
        label: i18n.t('30Minutes')
      }).create();
      var rounding60 = Ember.Object.extend({
        id: _environment.default.constants.ROUNDING_AMOUNT_60,
        label: i18n.t('60Minutes')
      }).create();
      return [rounding5, rounding15, rounding30, rounding60];
    }),
    hideRoundingAmount: Ember.computed.equal('project.roundingType', _environment.default.constants.ROUNDING_TYPE_NONE),
    selectedMode: Ember.computed('project.isModeFixedBudget', 'project.isModeByEffort', 'project.isModeNotBillable', function () {
      if (this.get('project.isModeFixedBudget')) {
        return 'budgetByFixedPrice';
      } else if (this.get('project.isModeByEffort')) {
        return 'budgetByEffort';
      } else if (this.get('project.isModeNotBillable')) {
        return 'notBillable';
      }

      return 'budgetByFixedPrice';
    }),
    showTimeEntryWarning: Ember.computed('project.analytics.hoursTotal', 'project.billBy', 'project.budgetBy', function () {
      return this.get('project.analytics.hoursTotal') && !this.get('project.isModeNotBillable');
    }),
    actions: {
      /**
       * Setting the selected mode in the component and resetting necessary properties,
       * that are not available anymore in the selected mode
       *
       * @param {string} mode
       */
      selectMode: function selectMode(mode) {
        if (mode === 'budgetByFixedPrice' && this.get('project.billBy') === _environment.default.constants.BILL_BY_NONE) {
          this.set('project.billBy', _environment.default.constants.BILL_BY_PROJECT);
        }

        if (mode === 'budgetByFixedPrice' && this.get('project.budgetBy') === _environment.default.constants.BUDGET_BY_EFFORT) {
          this.set('project.budgetBy', _environment.default.constants.BUDGET_BY_TOTAL_AMOUNT);
        } else if (mode === 'budgetByEffort') {
          this.set('project.budgetBy', _environment.default.constants.BUDGET_BY_EFFORT);

          if (this.get('project.billBy') === _environment.default.constants.BILL_BY_NONE) {
            this.set('project.billBy', _environment.default.constants.BILL_BY_PROJECT);
          }
        } else if (mode === 'notBillable') {
          this.set('project.billBy', _environment.default.constants.BILL_BY_NONE);

          if (this.get('project.budgetBy') === _environment.default.constants.BUDGET_BY_TOTAL_AMOUNT) {
            this.set('project.budgetBy', _environment.default.constants.BUDGET_BY_TOTAL_HOURS);
          }
        }

        this.send('saveProject');
      },

      /**
       * Saves the project and resets properties that are not available in the new combination, to prevent server errors.
       *
       * @param {object} modifiers
       */
      saveProject: function saveProject() {
        var modifiers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        if (modifiers.hasNoBilledBy) {
          this.set('project.billBy', _environment.default.constants.BILL_BY_NONE);
        }

        if (modifiers.hasNoBudgetBy) {
          this.set('project.budgetBy', _environment.default.constants.BUDGET_BY_EFFORT);
        }

        if (modifiers.resetBillBy) {
          this.set('project.billBy', _environment.default.constants.BILL_BY_PROJECT);
        }

        this.sendAction('saveProject');
      },
      useClientHourlyRate: function useClientHourlyRate() {
        var project = this.get('project'); //TODO Check if there is already tracked time for this project.
        //TODO If yes promped an user alert to inform, that the budget calculation is effected by changing the project hourlyRate!?

        project.set('hourlyRate', project.get('client.hourlyRate'));
        this.send('saveProject');
      },
      resetProject: function resetProject() {
        this.sendAction('resetProject');
      }
    }
  });

  _exports.default = _default;
});