define("coffeecup/controllers/planner/absences/scheduler/new", ["exports", "coffeecup/controllers/planner/absences/calendar/new"], function (_exports, _new) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _new.default.extend({
    absencesController: Ember.inject.controller('planner.absences.scheduler')
  });

  _exports.default = _default;
});