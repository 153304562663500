define("coffeecup/components/planner/project-user-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    viewport: Ember.inject.service('planner.viewport'),
    classNames: ['content-child-row', 'content-add-row'],
    users: null,
    selectedUser: null,
    _selectedUserOfDropdownChanged: Ember.observer('selectedUser', function () {
      var selectedUser = this.get('selectedUser');

      if (selectedUser) {
        this.sendAction('createAssignmentForUser', this.get('selectedUser'));
      }

      this.set('selectedUser', null);
    }),
    showCapacityModal: false,
    actions: {
      toggleUserCapacityModal: function toggleUserCapacityModal() {
        $(document.body).toggleClass('capacity-modal-shown', !this.get('showCapacityModal'));
        this.set('showCapacityModal', !this.get('showCapacityModal'));
      },
      createAssignmentForUser: function createAssignmentForUser(user, startDate, endDate, dailyAllocation) {
        this.sendAction('createAssignmentForUser', user, startDate, endDate, dailyAllocation);
      }
    }
  });

  _exports.default = _default;
});