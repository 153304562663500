define("coffeecup/components/text-limit", ["exports", "coffeecup/utils/browser"], function (_exports, _browser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TextLimit = Ember.Component.extend({
    tagName: 'div',
    classNames: ['text-limit'],
    classNameBindings: ['browserType'],
    attributeBindings: ['style'],
    text: null,
    lines: 2,
    //Default = 2
    lineOffset: 8,
    //Default = 8 for font-size = 14
    fontSize: 14,
    browserType: '',
    //  initialLines: null,
    init: function init() {
      this._super(); //    this.set('initialLines', this.get('lines'));

    },
    style: Ember.computed('lines', {
      get: function get() {
        var self = this;
        var style = '';

        var bt = _browser.default.getType();

        var l = self.get('lines');
        var fs = self.get('fontSize');
        var lo = self.get('lineOffset');

        if (bt == 'Chrome') {
          //Chrome
          self.set('browserType', 'bt-' + bt.toLowerCase());
          style = '-webkit-line-clamp: ' + l;
          style += '; max-height: ' + l * (fs + lo) + 'px;';
        } else if (bt == 'Firefox') {
          //Firefox
          self.set('browserType', 'bt-' + bt.toLowerCase());
          style = 'max-height: ' + l * (fs + lo) + 'px;';
        } else if (bt == 'msie') {
          self.set('browserType', 'bt-' + bt.toLowerCase()); //TODO css testing for IE
        }

        return Ember.String.htmlSafe(style);
      }
    })
  });
  var _default = TextLimit;
  _exports.default = _default;
});