define("coffeecup/components/icon-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['transparent:btn-nobg', 'hasPrimaryColor:btn-primary', 'hasPrimaryColor:icon-btn'],
    classNames: ['btn'],
    hideLabelOnMobile: false,
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(this.onClick && typeof this.onClick === 'function') && Ember.assert('icon-button requires an `onClick` function', this.onClick && typeof this.onClick === 'function'));
    },
    propagateClicks: true,
    transparent: false,
    hasPrimaryColor: Ember.computed.not('transparent'),
    click: function click(event) {
      var propagateClicks = this.get('propagateClicks');

      if (propagateClicks === false || propagateClicks === 'false') {
        event.stopPropagation();
      }

      this.onClick();
    }
  });

  _exports.default = _default;
});