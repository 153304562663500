define("coffeecup/components/dashboard-module/user-remuneration", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * INJECTIONS
     */
    formatter: Ember.inject.service(),
    permissions: Ember.inject.service(),

    /**
     * PROPERTIES
     */
    presentationModes: null,
    selectedPresentationMode: null,
    currentMode: null,
    currentUser: null,
    actionNames: null,
    employmentEntries: null,
    draggable: true,
    salaryTypes: Ember.computed('permissions', function () {
      return this.get('permissions.useHourlyCosts') ? ['freelancer'] : ['permanentEmployment', 'freelancer'];
    }),
    selectedSalaryType: Ember.computed('module', 'currentUser', {
      get: function get() {
        var currentMode = this.get('currentUser.hasFixedHourlyCosts') ? 'freelancer' : 'permanentEmployment';
        var settings = this.get('module.settingsObj');

        if (settings && settings.currentMode) {
          currentMode = settings.currentMode;
        }

        return currentMode;
      },
      set: function set(key, newValue) {
        var module = this.get('module');
        var settings = module.get('settingsObj');

        if (newValue !== '') {
          settings.currentMode = newValue;
          module.set('settingsObj', settings);
          module.save();
        }

        return newValue;
      }
    }),

    /**
     * COMPUTED PROPERTIES
     */
    defaultMode: 'permanentEmployment',
    isModePermanentEmployment: Ember.computed.equal('selectedSalaryType', 'permanentEmployment'),
    isModeFreelancer: Ember.computed.equal('selectedSalaryType', 'freelancer'),
    remunerationGraphData: Ember.computed('employmentEntries', 'selectedSalaryType', function () {
      var _this = this;

      var usedSalaryType = this.get('isModeFreelancer') ? 'hourlyRemuneration' : 'monthlyRemuneration';
      var employmentEntries = this.get('employmentEntries').filter(function (el) {
        return typeof el.get(usedSalaryType) === 'number';
      });
      var formatter = this.get('formatter');
      var graphData = [];
      var prevData = null;
      var currentEntries = employmentEntries.filter(function (el) {
        return el.get('validFrom').isSameOrBefore(_this.get('clock.now'));
      });
      var futureEntries = employmentEntries.filter(function (el) {
        return el.get('validFrom').isAfter(_this.get('clock.now'));
      });
      currentEntries.forEach(function (employmentEntry) {
        var salary = employmentEntry.get(usedSalaryType);

        if (salary || salary >= 0) {
          if (usedSalaryType === 'monthlyRemuneration') {
            salary *= 12;
          }

          var label = formatter.formatCurrency(salary);

          if (prevData && prevData.salary) {
            var salaryDiffPrefix = salary >= prevData.salary ? '+' : '';
            var salaryDiff = salary - prevData.salary;
            label += '<br/> ' + salaryDiffPrefix + formatter.formatPercentage(salaryDiff / prevData.salary);
          }

          var graphEntry = {
            date: employmentEntry.get('validFrom').format('DD.MM.YYYY'),
            salary: salary,
            label: label,
            bullet: 'round'
          };
          prevData = graphEntry;
          graphData.push(graphEntry);
        }
      });

      if (futureEntries.length > 0) {
        futureEntries.forEach(function (employmentEntry, index) {
          var salary = employmentEntry.get(usedSalaryType);

          if (salary || salary >= 0) {
            if (usedSalaryType === 'monthlyRemuneration') {
              salary *= 12;
            }

            var label = formatter.formatCurrency(salary);

            if (prevData && prevData.salary) {
              var salaryDiffPrefix = salary >= prevData.salary ? '+' : '';
              var salaryDiff = salary - prevData.salary;
              label += '<br/> ' + salaryDiffPrefix + formatter.formatPercentage(salaryDiff / prevData.salary);
            }

            if (index === 0) {
              var _graphEntry = {
                date: employmentEntry.get('validFrom').format('DD.MM.YYYY'),
                salary: salary
              };
              graphData.push(_graphEntry);
            }

            var graphEntry = {
              date: employmentEntry.get('validFrom').format('DD.MM.YYYY'),
              planned: salary,
              label: label,
              bullet: 'round'
            };
            prevData = graphEntry;
            graphData.push(graphEntry);
          }
        });

        if (prevData) {
          var graphEntry = {
            date: moment(prevData.date, 'DD.MM.YYYY').add(3, 'months').format('DD.MM.YYYY'),
            planned: prevData.planned
          };
          graphData.push(graphEntry);
        }
      } else {
        if (prevData) {
          var _graphEntry2 = {
            date: this.get('clock.day').format('DD.MM.YYYY'),
            salary: prevData.salary,
            label: formatter.formatCurrency(prevData.salary)
          };
          graphData.push(_graphEntry2);
        }
      }

      return graphData;
    }),

    /**
     * OBSERVERS
     */
    _generateRemunerationChart: Ember.observer('remunerationGraphData', function () {
      var _this2 = this;

      var data = this.get('remunerationGraphData');
      Ember.run.next(function () {
        var chart = AmCharts.makeChart('salary-chart-div-' + _this2.get('elementId'), {
          type: 'serial',
          fontFamily: 'robotolight',
          color: '#4A4A4A',
          fontSize: 11,
          dataDateFormat: 'DD.MM.YYYY',
          colors: [_environment.default.constants.COSTS_COLOR_LIGHTER, _environment.default.constants.COSTS_COLOR_LIGHTER],
          valueAxes: [{
            id: 'v1',
            axisAlpha: 0,
            unit: '€'
          }],
          balloon: {
            borderThickness: 1,
            shadowAlpha: 0
          },
          graphs: [{
            balloon: {
              adjustBorderColor: false,
              color: '#ffffff'
            },
            bulletField: 'bullet',
            bulletBorderAlpha: 1,
            bulletBorderColor: '#FFFFFF',
            bulletColor: _environment.default.constants.COSTS_COLOR_LIGHTER,
            type: 'step',
            bulletSize: 10,
            hideBulletsCount: 50,
            lineThickness: 2,
            title: 'red line',
            valueField: 'salary',
            balloonText: '[[label]]'
          }, {
            balloon: {
              adjustBorderColor: false,
              color: '#ffffff'
            },
            bulletField: 'bullet',
            bulletBorderAlpha: 1,
            bulletBorderColor: '#FFFFFF',
            bulletColor: _environment.default.constants.COSTS_COLOR_LIGHTER,
            type: 'step',
            dashLength: 5,
            bulletSize: 10,
            hideBulletsCount: 50,
            lineThickness: 2,
            title: 'red line',
            valueField: 'planned',
            balloonText: '[[label]]'
          }],
          zoomOutText: '',
          chartCursor: {
            cursorAlpha: 1,
            cursorColor: _environment.default.constants.ALERT_COLOR,
            zoomable: false,
            valueLineAlpha: 0.2
          },
          categoryField: 'date',
          categoryAxis: {
            parseDates: true,
            dashLength: 1,
            gridThickness: 0
          },
          dataProvider: data
        });
      });
    }),

    /**
     * FUNCTIONS
     */
    didInsertElement: function didInsertElement() {
      this._generateRemunerationChart();
    }
  });

  _exports.default = _default;
});