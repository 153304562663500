define("coffeecup/components/analytics-module/project/hours", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    project: null,
    remaining: null,
    duration: null,
    pieChartData: [],
    isBudgetByTotalHours: Ember.computed.reads('project.isBudgetByTotalHours'),
    hoursTotal: Ember.computed.reads('analytics.hoursTotal'),
    hoursRoundedSpent: Ember.computed.reads('analytics.hoursRoundedSpent'),
    amountSpentInBudget: Ember.computed.reads('analytics.amountSpentInBudget'),
    amountOutOfBudget: Ember.computed.reads('analytics.amountOutOfBudget'),
    amountBilled: Ember.computed.reads('analytics.amountBilled'),
    amountInBudgetNotBilled: Ember.computed.reads('analytics.amountInBudgetNotBilled'),
    amountNotBilled: Ember.computed.reads('analytics.amountNotBilled'),
    hoursBillable: Ember.computed.reads('analytics.hoursBillable'),
    hoursOutOfBudget: Ember.computed.reads('analytics.hoursOutOfBudget'),
    hoursNonBillable: Ember.computed.reads('analytics.hoursNonBillable'),
    hoursBillableInPercent: Ember.computed.reads('analytics.hoursBillableInPercent'),
    didInsertElement: function didInsertElement() {
      this._updateUi();
    },
    _updateUi: Ember.observer('hoursNonBillable', 'hoursBillable', 'hoursOutOfBudget', function () {
      var hoursNonBillable = this.get('hoursNonBillable');

      if (!hoursNonBillable && !this.get('hoursBillable') && !this.get('hoursOutOfBudget')) {
        // setting the value to 1 so that the chart gets rendered
        hoursNonBillable = 1;
      }

      this.set('pieChartData', [{
        value: hoursNonBillable
      }, {
        value: this.get('hoursBillable')
      }, {
        value: this.get('hoursOutOfBudget')
      }]);
    })
  });

  _exports.default = _default;
});