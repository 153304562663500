define("coffeecup/components/vacation-overview", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    vacations: [],
    hasShowMore: Ember.computed.gt('totalLength', 5),
    totalLength: 0,
    selectedYear: null,
    vacationEntriesSorted: Ember.computed('vacationsToShow.[]', function () {
      var vacations = this.get('vacationsToShow');

      if (vacations) {
        var yearlyBudget = vacations.findBy('vacationType', _environment.default.constants.VACATION_TYPE_YEARLY_BUDGET);
        var vacationCarryover = vacations.findBy('vacationType', _environment.default.constants.VACATION_TYPE_ALLOWANCE);
        if (yearlyBudget) vacations.removeObject(yearlyBudget);
        if (vacationCarryover) vacations.removeObject(vacationCarryover);
        vacations = vacations.sortBy('date');
        if (yearlyBudget) vacations.unshiftObject(yearlyBudget);
        if (vacationCarryover) vacations.unshiftObject(vacationCarryover);
        return vacations;
      } else {
        return vacations;
      }
    }),
    vacationsByYear: Ember.computed('selectedYear', 'vacations.[]', function () {
      var selectedYear = this.get('selectedYear');
      var vacations = this.get('vacations');
      return vacations.filter(function (el) {
        return el.get('year') === selectedYear;
      });
    }),
    remainingVacationDays: Ember.computed('clock.localDayAsUtcMoment', 'vacationsByYear.[]', 'vacationsByYear.@each.mode', 'vacationsByYear.@each.quantiy', 'vacationsByYear.@each.date', 'clock.localDayAsUtcMoment', function () {
      var vacationEntries = this.get('vacationsByYear');
      var summedValues = 0;

      if (vacationEntries) {
        summedValues = vacationEntries.reduce(function (prev, vacationEntry) {
          if (vacationEntry.get('quantity')) {
            return prev + parseFloat(vacationEntry.get('quantity'));
          } else {
            return prev;
          }
        }, 0);
      }

      return summedValues;
    }),
    vacationsToShow: Ember.computed('showMore', 'vacationsByYear.[]', 'vacationsByYear.@each.date', function () {
      if (this.get('vacationsByYear')) {
        var vacations = this.get('vacationsByYear').sortBy('date');
        this.set('totalLength', vacations.length);

        if (this.get('showMore')) {
          return vacations;
        }

        return vacations.slice(0, 5);
      }
    }),
    showNewVacationBudget: false,
    actions: {
      toggleShowMoreVacationObjects: function toggleShowMoreVacationObjects() {
        this.toggleProperty('showMore');
      },
      toggleAddNewVacationBudget: function toggleAddNewVacationBudget() {
        this.set('showNewVacationBudget', true);
      },
      cancelEdit: function cancelEdit() {
        this.set('showNewVacationBudget', false);
      },
      publishChange: function publishChange(param) {
        this.sendAction('publishChange', param);
        this.set('showNewVacationBudget', false);
      }
    }
  });

  _exports.default = _default;
});