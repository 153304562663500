define("coffeecup/components/enter-action-listener", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Simple component that listens for modifier+enter key press.
   *
   * Modifier is control key or windows/command key depending on platform.
   */
  var EnterActionListener = Ember.Component.extend({
    actionEventHandlerFactory: function actionEventHandlerFactory() {
      var _this = this;

      return Ember.$.proxy(function (event) {
        if (event.keyCode === 13 && (event.ctrlKey || event.metaKey)) {
          Ember.run.scheduleOnce('actions', _this, function () {
            this.sendAction('onEnter');
          });
        }
      }, this);
    },
    didInsertElement: function didInsertElement() {
      this.$(window).keydown(this.actionEventHandlerFactory());
    },
    willDestroyElement: function willDestroyElement() {
      this.$(window).unbind('keydown', this.actionEventHandlerFactory());
    }
  });
  var _default = EnterActionListener;
  _exports.default = _default;
});