define("coffeecup/components/analytics-module/user/worktime", ["exports", "coffeecup/utils/build-url", "coffeecup/config/environment"], function (_exports, _buildUrl, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    workingDaysService: Ember.inject.service('working-days'),
    workload: null,
    user: null,
    absences: [],
    totalOvertime: 0,
    overtimePreviousYear: 0,
    plannedHours: 0,
    actualHours: 0,
    selectedYear: moment().format('YYYY'),
    previousYear: Ember.computed('selectedYear', function () {
      return moment().year(this.get('selectedYear')).subtract(1, 'year').format('YYYY');
    }),
    showAllData: false,
    userHasFixedWeek: Ember.computed.reads('user.hasFixedWeek'),
    userHasFlexibleHours: Ember.computed.reads('user.hasFlexibleHours'),
    _updateDataObserver: Ember.observer('selectedYear', 'user.id', function () {
      Ember.run.once(this, '_updateData');
    }),
    _updateData: function _updateData() {
      var _this = this;

      var selectedYear = this.get('selectedYear').toString();
      var workloadEndDate = this.get('clock.now').subtract(1, 'days');

      if (this.get('userHasFixedWeek')) {
        workloadEndDate = moment.max(this.get('clock.now').startOf('year'), this.get('clock.now').subtract(1, 'weeks').endOf('week'));
      } else if (this.get('userHasFlexibleHours')) {
        workloadEndDate = this.get('clock.now');
      }

      var workloadOptions = {
        cachedWorkloads: false
      };
      this.get('session').getJSON((0, _buildUrl.default)(this.get('store'), 'users/' + this.get('user.id') + '/workload/' + selectedYear + '/monthly', workloadOptions)).then(function (workload) {
        return _this.set('workload', workload);
      }).catch(function () {
        return _this.set('workload', null);
      });
      this.get('session').getJSON((0, _buildUrl.default)(this.get('store'), 'overtimereductions/calc', {
        year: selectedYear,
        user: this.get('user.id'),
        end_date: workloadEndDate.get('year').toString() === selectedYear ? workloadEndDate.format(_environment.default.constants.DATE_FORMAT_DAY) : null
      })).then(function (overtimeCalc) {
        return _this.set('overtimeCalc', overtimeCalc);
      }).catch(function () {
        return _this.set('overtimeCalc', []);
      });
      var absenceFromDate = moment().year(selectedYear).startOf('year');
      var absenceToDate = moment().year(selectedYear).endOf('year'); // absences

      this.get('store').query('absence', {
        sort: 'startDate DESC',
        limit: 1000,
        where: {
          or: [{
            startDate: {
              '>=': absenceFromDate.toJSON(),
              '<=': absenceToDate.toJSON()
            }
          }, {
            endDate: {
              '>=': absenceFromDate.toJSON(),
              '<=': absenceToDate.toJSON()
            }
          }],
          user: this.get('user.id')
        }
      }).then(function (absences) {
        _this.set('absences', absences);
      });
    },
    missedDays: Ember.computed('absences.[]', function () {
      var absences = this.get('absences');
      var workingDaysService = this.get('workingDaysService');
      var user = this.get('user');
      var year = this.get('selectedYear');
      var absencesFiltered = absences.filterBy('absenceType.isIllness');
      var absencesDuration = absencesFiltered.reduce(function (prev, item) {
        return prev + workingDaysService.getWorkingDaysOfAbsenceInYear(user, item, year);
      }, 0);
      return absencesDuration;
    }),
    _updateOvertimeCalcProps: Ember.observer('overtimeCalc', function () {
      var totalOvertime = 0;
      var overtimePreviousYear = 0;
      var overtimeCurrentYear = 0;
      var overtimeReductionsTotal = 0;
      this.get('overtimeCalc').forEach(function (el) {
        if (el.year === 'previous') overtimePreviousYear = el.quantity;
        if (el.overtimeCalcType === 1) overtimeCurrentYear = el.quantity;
        totalOvertime += el.quantity;

        if (el.overtimeCalcType === 3 || el.overtimeCalcType === 2) {
          overtimeReductionsTotal += el.quantity;
        }
      });
      this.set('totalOvertime', totalOvertime);
      this.set('overtimePreviousYear', overtimePreviousYear);
      this.set('overtimeCurrentYear', overtimeCurrentYear);
      this.set('overtimeReductionsTotal', overtimeReductionsTotal);
      this.set('overtimeBalance', totalOvertime);
    }),
    _updateWorkloadProps: Ember.observer('workload.[]', function () {
      if (!this.get('workload')) {
        this.set('plannedHours', 0);
        this.set('actualHours', 0);
      }

      var workload = this.get('workload.monthlyWorkload').reduce(function (prev, el) {
        prev.actualHours += el.hours;
        prev.plannedHours += el.expectedHours;
        return prev;
      }, {
        plannedHours: 0,
        actualHours: 0
      });
      this.setProperties({
        plannedHours: workload.plannedHours,
        actualHours: workload.actualHours
      });
    }),
    didInsertElement: function didInsertElement() {
      this._updateData();
    },
    yearPickerCustomElement: Ember.computed(function () {
      var i18n = this.get('i18n');
      return i18n.t('showAll');
    }),
    customBands: Ember.computed('missedDays', function () {
      var missedDays = this.get('missedDays');
      var percentage = missedDays / 30;
      var startValue = 0;
      var endValue = percentage * 200;
      return [{
        alpha: 1,
        color: percentage > 0 ? _environment.default.constants.ALERT_COLOR : _environment.default.constants.REVENUE_COLOR,
        endValue: endValue,
        id: 'This Month',
        innerRadius: '85%',
        radius: '100%',
        startValue: startValue
      }];
    }),
    actions: {
      yearPickerCustomElementAction: function yearPickerCustomElementAction(value) {
        this.set('showAllData', value);
      }
    }
  });

  _exports.default = _default;
});