define("coffeecup/components/comment-history", ["exports", "ember-cli-tooltipster/components/tool-tipster"], function (_exports, _toolTipster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _toolTipster.default.extend({
    classNames: ['comment-history'],
    timeEntryService: Ember.inject.service('time-entry'),
    commentsGroup: [],
    comment: null,
    showComments: false,
    btnClass: Ember.computed('loading', {
      get: function get() {
        return 'btn btn-noborder' + (this.get('loading') ? ' animated infinite swing' : '');
      }
    }),
    _openModal: function _openModal() {
      var trigger = document.querySelector("#".concat(this.elementId, " .ember-power-select-trigger"));
      var customEvent = document.createEvent('Event');
      customEvent.initEvent('click', true, true);

      if (trigger) {
        trigger.dispatchEvent(customEvent);
      }
    },
    actions: {
      selectComment: function selectComment(comment) {
        this.set('comment', comment);
        this.sendAction('onSelect', this.get('comment.comment'));
      },
      loadComments: function loadComments() {
        var _this = this;

        if (Ember.isEmpty(this.get('commentsGroup'))) {
          Ember.run.later(function () {
            if (!_this.get('showComments')) {
              _this.set('loading', true);
            }
          }, 500);
          this.get('timeEntryService').getLatestTimeEntriesWithCommentForUser().then(function (commentsUnfiltered) {
            var uniqueObjects = [];
            var commentsGroup = commentsUnfiltered.filter(function (item) {
              if (!uniqueObjects.isAny('comment', item.get('comment'))) {
                uniqueObjects.push(item);
                return true;
              }

              return false;
            });

            _this.set('commentsGroup', commentsGroup);

            _this._openModal();

            _this.set('showComments', true);

            _this.set('loading', false);
          });
        } else {
          this.set('showComments', true);

          this._openModal();
        }
      }
    }
  });

  _exports.default = _default;
});