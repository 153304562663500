define("coffeecup/components/validation/revalidation-week", ["exports", "coffeecup/utils/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['revalidation-week-container'],
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    accountSettings: Ember.computed.reads('accountService.accountSettings'),
    daysInPeriod: Ember.computed('week', function () {
      var startDate = moment(this.get('week.startDate')).startOf('week');
      var endDate = moment(this.get('week.endDate')).endOf('week');
      return (0, _date.enumerateDaysBetweenDates)(startDate, endDate);
    }),
    selectedFilterMode: null,
    userData: Ember.computed.alias('week.userData'),
    hasAnyValidationActive: Ember.computed.or('accountSettings.activeCommentValidation', 'accountSettings.activeBreakValidation', 'accountSettings.activeHoursValidation'),
    weekLabel: Ember.computed('week', function () {
      return "".concat(this.get('i18n').t('calendarWeek'), " ").concat(moment(this.get('week.endDate')).format('ww YYYY'));
    }),
    mondayDate: Ember.computed('daysInPeriod.[]', function () {
      return this.get('daysInPeriod')[0].format('DD.MM.');
    }),
    tuesdayDate: Ember.computed('daysInPeriod.[]', function () {
      return this.get('daysInPeriod')[1].format('DD.MM.');
    }),
    wednesdayDate: Ember.computed('daysInPeriod.[]', function () {
      return this.get('daysInPeriod')[2].format('DD.MM.');
    }),
    thursdayDate: Ember.computed('daysInPeriod.[]', function () {
      return this.get('daysInPeriod')[3].format('DD.MM.');
    }),
    fridayDate: Ember.computed('daysInPeriod.[]', function () {
      return this.get('daysInPeriod')[4].format('DD.MM.');
    }),
    saturdayDate: Ember.computed('daysInPeriod.[]', function () {
      return this.get('daysInPeriod')[5].format('DD.MM.');
    }),
    sundayDate: Ember.computed('daysInPeriod.[]', function () {
      return this.get('daysInPeriod')[6].format('DD.MM.');
    }),
    actions: {
      updateList: function updateList(userData) {
        this.sendAction('updateList', userData, this.get('week.startDate'));
      },
      loadMore: function loadMore() {
        this.sendAction('loadPreviousWeek', this.get('week.startDate'));
      }
    }
  });

  _exports.default = _default;
});