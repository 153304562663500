define("coffeecup/controllers/manage/_super", ["exports", "coffeecup/mixins/filtered-content"], function (_exports, _filteredContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_filteredContent.default, {
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    _selectedId: Ember.observer('activeRecord', function () {
      var selectedRecord = this.get('activeRecord');
      if (!selectedRecord) return;
      var DEFAULT_ROUTE = 'projects';
      var modelName = this.get('model').modelName;

      if (!modelName) {
        this.get('router').transitionTo(DEFAULT_ROUTE);
        return null;
      }

      this.set('showInactive', selectedRecord.get('isInactive'));
      Ember.run.next(function () {
        var listElement = document.querySelector("[data-entity-id=\"".concat(selectedRecord.get('id'), "\"]"));

        if (listElement) {
          listElement.scrollIntoView({
            block: 'nearest'
          });
        }
      });
      var target = DEFAULT_ROUTE;

      switch (modelName) {
        case 'project':
          target = 'manage.projects.edit';
          break;

        case 'user':
          target = 'manage.users.edit';
          break;

        case 'client':
          target = 'manage.clients.edit';
          break;

        case 'task':
          target = 'projects.tasks.edit';
          break;

        case 'expense-category':
          target = 'projects.expense-categories.edit';
          break;
      }

      if (target !== DEFAULT_ROUTE) {
        // Nested routes can act strangely with query params https://github.com/emberjs/ember.js/issues/17494
        var newUrl = this.get('router').urlFor(target, selectedRecord.get('id'));
        this.get('router').transitionTo(newUrl + window.location.search);
        return selectedRecord.get('id');
      } else {
        this.get('router').transitionTo(target);
        return null;
      }
    })
  });

  _exports.default = _default;
});