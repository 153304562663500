define("coffeecup/components/t-tip-icon", ["exports", "ember-cli-tooltipster/components/tool-tipster"], function (_exports, _toolTipster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _toolTipster.default.extend({
    tagName: 'span',
    icon: 'question4',
    content: null,
    maxWidth: 300,
    additionalIconClasses: null
  });

  _exports.default = _default;
});