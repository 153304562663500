define("coffeecup/controllers/application", ["exports", "coffeecup/config/environment", "coffeecup/utils/ui", "coffeecup/utils/model", "coffeecup/services/events", "ember-cli-app-version/utils/regexp"], function (_exports, _environment, _ui, _model, _events, _regexp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    easybill: Ember.inject.service(),
    router: Ember.inject.service(),
    showVersion: Ember.FEATURES.isEnabled('CC_SHOW_VERSION'),
    appVersion: Ember.computed('showVersion', function () {
      var _ENV$APP$version$matc;

      var showVersion = this.get('showVersion');
      var shortVersion = (_ENV$APP$version$matc = _environment.default.APP.version.match(_regexp.versionRegExp)) === null || _ENV$APP$version$matc === void 0 ? void 0 : _ENV$APP$version$matc.toString();
      return "".concat(showVersion ? "".concat(_environment.default.environment, " ") : '', "v").concat(shortVersion);
    }),
    eventsService: Ember.inject.service('events'),
    viewport: Ember.inject.service('planner.viewport'),
    accountService: Ember.inject.service('account'),
    validationService: Ember.inject.service('validation'),
    hideNotificationContainer: false,

    /**
     * an array that holds the main navigation
     */
    menu: [],

    /**
     * the user, which is currently logged in (myself)
     */
    currentUser: null,
    mustReconnectEasybill: false,
    absenceTypes: null,
    activeAbsenceTypes: Ember.computed.filterBy('absenceTypes', 'isActive', true),
    absenceRequests: null,
    openAbsenceRequests: Ember.computed('absenceRequests.[]', 'absenceRequests.@each.state', function () {
      var _this = this;

      var filteredAbsenceRequests = (this.get('absenceRequests') || []).filter(function (absenceRequest) {
        var currentUserId = _this.currentUser.get('id');

        var absenceRequestUser = absenceRequest.get('sender.id');
        return absenceRequest.get('state') === null && currentUserId !== absenceRequestUser;
      });
      return filteredAbsenceRequests;
    }),
    numberOfOpenAbsenceRequests: Ember.computed.alias('openAbsenceRequests.length'),
    numberOfOpenValidations: Ember.computed.alias('validationService.numberOfOpenValidations'),
    currentUserIsDefaultUser: Ember.computed.equal('currentPermissions', _environment.default.constants.ROLE_DEFAULT),
    currentUserIsStaffPm: Ember.computed.equal('currentPermissions', _environment.default.constants.ROLE_STAFF_PM),
    currentUserIsPm: Ember.computed.equal('currentPermissions', _environment.default.constants.ROLE_PM),
    currentUserIsAdmin: Ember.computed.equal('currentPermissions', _environment.default.constants.ROLE_ADMIN),

    /**
     * {String} permissions of currentUser, either ROLE_DEFAULT, ROLE_STAFF_PM, ROLE_PM or ROLE_ADMIN
     */
    currentPermissions: Ember.computed('currentUser.role', 'userAssignments.@each.isProjectManager', 'userAssignments.length', {
      get: function get() {
        switch (this.get('currentUser.role.name')) {
          case _environment.default.constants.ROLE_DEFAULT:
            var assignments = this.get('userAssignments') || [];
            var uid = this.get('currentUser.id'); // check for pm user assignments

            var assignmentCheck = function assignmentCheck(userAssignment) {
              return userAssignment.get('user.id') === uid && userAssignment.get('isProjectManager');
            };

            return assignments.any(assignmentCheck) ? _environment.default.constants.ROLE_STAFF_PM : _environment.default.constants.ROLE_DEFAULT;

          case _environment.default.constants.ROLE_PM:
            return _environment.default.constants.ROLE_PM;

          case _environment.default.constants.ROLE_ADMIN:
            return _environment.default.constants.ROLE_ADMIN;

          default:
            return null;
        }
      }
    }),
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.eventsService.on(_events.DRAG_START, function () {
        _this2.set('hideNotificationContainer', true);
      });
      this.eventsService.on(_events.DRAG_END, function () {
        _this2.set('hideNotificationContainer', false);
      });
    },

    /**
     * sets the body class
     */
    _setBodyClass: Ember.observer('currentRouteHasSubmenu', 'currentPermissions', 'permissions.activePermissions', function () {
      var classes = _environment.default.environment === 'test' ? [] : ['ember-application']; // the default class

      classes.push(this._getCurrentPermissionsClassName());
      var currentRouteHasSubmenu = this.get('currentRouteHasSubmenu');
      var activePermissions = this.get('permissions.activePermissions');

      if (currentRouteHasSubmenu) {
        classes.push('has-submenu');
      }

      var _EmberGetOwner$looku = Ember.getOwner(this).lookup('service:-document'),
          body = _EmberGetOwner$looku.body;

      Ember.$(body).attr('class', classes.join(' '));
    }).on('init'),
    currentRouteHasSubmenu: Ember.computed('router.currentRouteName', 'menu', {
      get: function get() {
        var currentRouteName = this.get('router.currentRouteName');
        var currentRouteNameClean = currentRouteName && currentRouteName.split('.').slice(0, 2).join('.');
        var currentRoute = this.get('menu').filter(function (x) {
          var hasModel = x.get('modelKey');
          var hasChildren = x.get('parent.visibleChildren') && x.get('parent.visibleChildren.length') > 1;
          return (hasModel || hasChildren) && (currentRouteNameClean === x.get('url') || currentRouteNameClean === x.get('url') + '.index');
        });
        return currentRoute && (currentRoute.get('firstObject.parent.id') || currentRoute.get('firstObject.modelKey'));
      }
    }),
    _getCurrentPermissionsClassName: function _getCurrentPermissionsClassName() {
      var roles = [];

      if (this.get('permissions.isRootAdmin')) {
        return 'user-is-admin';
      }

      if (this.get('permissions.canManageOwnProjects')) {
        roles.push('user-is-pm');
      }

      if (this.get('permissions.canAccessUsers')) {
        roles.push('user-is-hr');
      }

      if (this.get('permissions.isStaffPM') && !this.get('permissions.canManageOwnProjects')) {
        roles.push('user-is-staffpm');
      }

      if (this.get('permissions.isDefaultUser') || roles.length === 0) {
        roles.push('user-is-default');
      }

      return roles.join(' ');
    },

    /**
     * an array of roles
     */
    roles: null,

    /**
     * an array of user assignments for the current user
     */
    userAssignments: [],
    allUserAssignments: Ember.computed.alias('userAssignments'),
    allMyUserAssignments: Ember.computed('allUserAssignments', function () {
      var _this3 = this;

      var allUserAssignments = this.get('allUserAssignments');
      return allUserAssignments.filter(function (ua) {
        return ua.get('user.id') === _this3.get('currentUser.id');
      });
    }),

    /**
     * an array of tasks that the user has access to
     */
    tasks: [],
    allTasks: Ember.computed.alias('tasks'),
    activeTasks: Ember.computed('tasks.@each.isActive', function () {
      return this.get('tasks').filter(_model.default.isSavedAndActive);
    }),

    /**
     * an array of tasks that the user has access to
     */
    invoices: [],
    allInvoices: Ember.computed.alias('invoices'),

    /**
     * an array of users that the user has access to
     */
    users: [],
    allUsers: Ember.computed.alias('users'),
    enabledUsers: Ember.computed('users.@each.isActive', function () {
      return this.get('users').filter(function (user) {
        return _model.default.isSavedAndActive(user);
      });
    }),
    activeUsers: Ember.computed('enabledUsers.@each.isActive', 'enabledUsers.@each.isFormer', function () {
      return this.get('enabledUsers').filter(function (user) {
        return !user.get('isFormer');
      });
    }),

    /**
     * an array of team assignments
     */
    teamAssignments: [],
    allTeamAssignments: Ember.computed.alias('teamAssignments'),

    /**
     * an array of teams
     */
    teams: [],
    allTeams: Ember.computed.alias('teams'),

    /**
     * an array of clients that the user has access to
     */
    clients: [],
    allClients: Ember.computed.alias('clients'),
    activeClients: Ember.computed('clients.@each.isActive', function () {
      return this.get('clients').filter(_model.default.isSavedAndActive);
    }),

    /**
     * an array of projects that the user has access to
     */
    projects: [],
    allProjects: Ember.computed.alias('projects'),
    activeProjects: Ember.computed('projects.@each.isActive', function () {
      return this.get('projects').filter(_model.default.isSavedAndActive);
    }),

    /**
     * an array of tags
     */
    tags: [],
    allTags: Ember.computed.alias('tags'),

    /**
     * an array of timeEntryReferences
     */
    timeEntryReferences: [],
    allTimeEntryReferences: Ember.computed.alias('timeEntryReferences'),

    /**
     * an array of task assignments that the user has access to
     */
    taskAssignments: [],
    allTaskAssignments: Ember.computed.alias('taskAssignments'),
    activeTaskAssignments: Ember.computed('taskAssignments.@each.isActive', function () {
      return this.get('taskAssignments').filter(_model.default.isSavedAndActive);
    }),
    expenseCategories: [],
    allExpenseCategories: Ember.computed.alias('expenseCategories'),
    activeExpenseCategories: Ember.computed('expenseCategories.@each.isActive', function () {
      return this.get('expenseCategories').filter(_model.default.isSavedAndActive);
    }),

    /**
     * the general account / admin settings
     */
    accountSettings: Ember.computed.alias('accountService.accountSettings'),
    activeWeekendsObserver: Ember.observer('accountSettings.activeWeekends', function () {
      this.get('viewport').setShowWeekends(this.get('accountSettings.activeWeekends'));
    }),
    planningModeObserver: Ember.observer('accountSettings.planningMode', function () {
      this.get('viewport').setPlanningMode(this.get('accountSettings.planningMode'));
    }),

    /**
     * Latest time entry (required for new time entry modal for pre-selecting project and task)
     */
    latestTimeEntry: null,

    /**
     * Toggle whether or not mobile drawer menu is open
     */
    mobileMenuOpen: false,
    isMobile: false,
    session: Ember.inject.service(),
    permissions: Ember.inject.service(),
    currentTutorialUrlKey: Ember.computed('currentRouteName', function () {
      // TODO: [CC-2807] Reenable when support site is ready for update
      return 'defaultTutorialUrl'; // const currentRouteName = this.get('currentRouteName');
      // if (!currentRouteName) {
      //   return null;
      // }
      // const tutorialUrls = {
      //   index: 'dashboardTutorialUrl',
      //   'time-entries.index': 'timeTrackingTutorialUrl',
      //   'projects.tasks.new': 'newTaskTutorialUrl',
      //   'projects.tasks.edit': 'newTaskTutorialUrl',
      //   'projects.tasks.index': 'taskTutorialUrl',
      //   'manage.clients.new': 'newClientTutorialUrl',
      //   'manage.clients.edit': 'newClientTutorialUrl',
      //   'manage.clients.index': 'clientTutorialUrl',
      //   'manage.projects.new': 'newProjectTutorialUrl',
      //   'manage.projects.edit': 'newProjectTutorialUrl',
      //   'manage.projects.index': 'projectTutorialUrl',
      //   'manage.users.new': 'newEmployeeTutorialUrl',
      //   'manage.users.edit.index': 'newEmployeeTutorialUrl',
      //   'manage.users.index': 'employeeTutorialUrl',
      //   'account.company': 'companySettingsTutorialUrl',
      //   'account.holidays': 'holidaySettingsTutorialUrl',
      //   'account.billing': 'billingSettingsTutorialUrl',
      //   'account.profile': 'profileSettingsTutorialUrl',
      //   'account.integrations.index': 'integrationSettingsTutorialUrl',
      //   'account.user-interface': 'userInterfaceSettingsTutorialUrl',
      // };
      // if (!isEmpty(tutorialUrls[currentRouteName])) {
      //   return tutorialUrls[currentRouteName];
      // }
      // return null;
    }),
    updateUserSettings: Ember.observer('accountSettings.currencyFormat', 'accountSettings.currency', 'accountSettings.numberFormat', 'currentUser.language', 'currentUser.beginningOfWeek', function () {
      var _this4 = this;

      var currentUser = this.get('currentUser');
      var accountSettings = this.get('accountSettings');
      Ember.run.once(function () {
        return _ui.default.setLocales(_this4.get('i18n'), currentUser, accountSettings);
      });
    }),
    actions: {
      toggleMobileMenu: function toggleMobileMenu() {
        this.set('mobileMenuOpen', !this.get('mobileMenuOpen'));
      },
      setLatestTimeEntry: function setLatestTimeEntry(timeEntry) {
        this.set('latestTimeEntry', timeEntry);
      }
    }
  });

  _exports.default = _default;
});