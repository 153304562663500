define("coffeecup/components/toggle-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * use the footer tag directly
     */
    tagName: 'footer',

    /**
     * classes used by the component
     */
    classNames: ['clearfix', 'toggle-footer', 'clickable'],

    /**
     * binded classnames
     */
    classNameBindings: ['isExpanded:large', 'isExpanded:expanded'],

    /**
     * expand button tooltip
     */
    expandTooltip: null,

    /**
     * collapse button tooltip
     */
    collapseTooltip: null,

    /**
     * the user has expanded the filter details
     */
    isExpanded: null,

    /**
     * defines the buttonsLabel relative to its isExpanded property
     */
    toggleButtonLabel: Ember.computed('isExpanded', {
      get: function get() {
        return this.get('isExpanded') ? this.get('collapseLabel') : this.get('expandLabel');
      }
    }),

    /**
     * defines the buttons icon class relative to its isExpanded property
     */
    toggleButtonIconClass: Ember.computed('isExpanded', {
      get: function get() {
        return this.get('isExpanded') ? 'icon-arrow-65-1 small' : 'icon-arrow-66-1 small';
      }
    }),
    actions: {},
    click: function click() {
      this.toggleProperty('isExpanded');
    }
  });

  _exports.default = _default;
});