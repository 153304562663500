define("coffeecup/components/time-entries/list", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TimeEntriesList = Ember.Component.extend({
    classNames: ['time-entry-col'],
    classNameBindings: ['todayCssClass', 'timeEntryColorTypeClass', 'absenceTypeClass'],
    attributeBindings: ['style'],
    currentUser: null,
    userCanCreateTimeEntry: false,
    userCanCreateBreaks: false,
    day: null,
    timeEntryDuplicate: null,
    timeEntryColorTypeClass: null,
    timeEntryBackgroundColor: null,
    minuteSnap: null,
    allowBreaks: true,
    validationObj: null,
    //==========================================
    // Computed Properties
    //==========================================
    todayCssClass: Ember.computed.alias('relativeToToday'),
    style: Ember.computed('day.absenceForDay.absenceType', function () {
      if (this.get('day.absenceForDay') && !this.get('day.holidayForDay')) {
        return Ember.String.htmlSafe("background-color: ".concat(this.get('day.absenceForDay.absenceType.lighterColorCode')));
      }
    }),
    absenceTypeClass: Ember.computed('day.absenceForDay.absenceType.typeClass', 'day.holidayForDay', {
      get: function get() {
        if (this.get('day.holidayForDay')) {
          return this.get('day.holidayForDay.type');
        } else {
          return this.get('day.absenceForDay.absenceType.typeClass');
        }
      }
    }),
    relativeToToday: Ember.computed('day.day', 'clock.day', {
      get: function get() {
        var now = this.get('clock.now');
        var currentDay = this.get('day.day');
        var dateIs = _environment.default.constants.DATE_IS_FUTURE;

        if (currentDay.isSame(now, 'day.day')) {
          dateIs = _environment.default.constants.DATE_IS_PRESENT;
        } else if (currentDay.isBefore(now, 'day.day')) {
          dateIs = _environment.default.constants.DATE_IS_PAST;
        }

        return dateIs;
      }
    }),
    actions: {
      newEntry: function newEntry() {
        var params = {
          day: this.get('day.day').format()
        };
        this.sendAction('onNewEntry', params);
      },
      newBreak: function newBreak() {
        this.sendAction('onNewBreak', {
          day: this.get('day.day').format()
        });
      },
      edit: function edit(timeEntry) {
        this.sendAction('onEdit', timeEntry);
      },
      pause: function pause(timeEntry) {
        this.sendAction('onPause', timeEntry);
      },
      play: function play(timeEntry) {
        this.sendAction('onPlay', timeEntry);
      },
      duplicateTimeEntry: function duplicateTimeEntry(timeEntry) {
        this.sendAction('onDuplicate', timeEntry);
      },
      cancelDuplicateTimeEntry: function cancelDuplicateTimeEntry() {
        this.sendAction('onCancelDuplicate');
      },
      startResizeAction: function startResizeAction(data) {
        this.sendAction('onStartResize', data);
      },
      resizeAction: function resizeAction(data) {
        this.sendAction('onResize', data);
      },
      stopResizeAction: function stopResizeAction(data) {
        this.sendAction('onStopResize', data);
      }
    }
  });
  var _default = TimeEntriesList;
  _exports.default = _default;
});