define("coffeecup/components/button-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['btn', 'btn-switch'],
    classNameBindings: ['isActive:primary-background-color', 'isActive:active-switch', 'isNotActive:has-border'],
    target: null,
    label: null,
    selectedLabel: null,
    router: Ember.inject.service(),
    isActive: Ember.computed('target', 'router.currentRouteName', 'label', 'selectedLabel', function () {
      if (this.get('target')) {
        return this.get('router.currentRouteName').indexOf(this.get('target')) === 0;
      } else if (this.get('label')) {
        return this.get('label') === this.get('selectedLabel');
      }
    }),
    isNotActive: Ember.computed.not('isActive'),
    click: function click() {
      if (this.get('target')) {
        this.get('router').transitionTo(this.get('target'));
      } else if (this.get('label')) {
        this.sendAction('onClick', this.get('label'));
      }
    }
  });

  _exports.default = _default;
});