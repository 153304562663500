define("coffeecup/components/clickable-code-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    label: null,
    size: null,
    isExpandable: false,
    type: null,
    icon: null,
    tagName: 'span',
    propagateClicks: true,
    click: function click(event) {
      var propagateClicks = this.get('propagateClicks');

      if (propagateClicks === false || propagateClicks === 'false') {
        event.stopPropagation();
      }

      this.sendAction('onClick');
    }
  });

  _exports.default = _default;
});