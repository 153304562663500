define("coffeecup/controllers/planner/absences/calendar/confirm", ["exports", "coffeecup/mixins/buffered-form", "coffeecup/mixins/calculate-absencedays"], function (_exports, _bufferedForm, _calculateAbsencedays) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_bufferedForm.default, _calculateAbsencedays.default, {
    permissions: Ember.inject.service(),
    application: Ember.inject.controller('application'),
    absencesController: Ember.inject.controller('planner.absences.calendar'),

    /**
     * the currently logged in user
     */
    currentUser: Ember.computed.oneWay('application.currentUser'),
    formStateIsNull: Ember.computed.equal('form.state', null),
    canConfirm: Ember.computed('formStateIsNull', 'currentUser.id', 'form.sender.id', function () {
      var userIsAdmin = this.get('currentUser.isAdmin');
      var hasPermission = this.get('permissions.canManageAbsenceRequests');
      var userCanConfirm = userIsAdmin || hasPermission && this.get('currentUser.id') !== this.get('form.sender.id');
      return this.get('formStateIsNull') && userCanConfirm;
    }),

    /**
     * absence
     */
    model: null,
    alreadyAdded: false,
    startDateProxy: Ember.computed('form.startDate.content', {
      get: function get() {
        var form = this.get('form');
        return Ember.get(form, 'startDate.content') || Ember.get(form, 'startDate');
      },
      set: function set(key, value) {
        var form = this.get('form');
        next(this, function () {
          form.set('startDate', value);
          form.validate();
        });
        return value;
      }
    }),
    endDateProxy: Ember.computed('form.endDate.content', {
      get: function get() {
        var form = this.get('form');
        return Ember.get(form, 'endDate.content') || Ember.get(form, 'endDate');
      },
      set: function set(key, value) {
        var form = this.get('form');
        next(this, function () {
          form.set('endDate', value);
          form.validate();
        });
        return value;
      }
    }),

    /**
     * boolean whether the modal should be opened
     */
    openModal: false,

    /**
     * boolean whether the modal should be closed
     */
    closeModal: false,

    /**
     * boolean, true when the form is being submitted
     */
    submitting: false,

    /**
     * disables the submit button when the form is not valid or it's currently being submitted
     * @return boolean
     */
    submitIsDisabled: Ember.computed('submitting', 'form.isValid', {
      get: function get() {
        var submitting = this.get('submitting');
        var isValid = this.get('form.isValid');
        return submitting || !isValid;
      }
    }),

    /**
     * handles focusing of form inputs
     * will automatically focus the next input field after certain values have been changed
     * @private
     */
    _addFocusObserver: function () {
      this.addObserver('form.user', this, this.formUserObserver);
      this.addObserver('form.absenceType', this, this.formAbsenceObserver);
    }.on('init'),
    formUserObserver: function formUserObserver() {
      this.set('focus', 'absence-type');
    },
    formAbsenceObserver: function formAbsenceObserver() {
      this.set('focus', 'comment');
    },

    /**
     * destroys automatically focusing of input fields
     * @private
     */
    _destroyFocusObserver: function _destroyFocusObserver() {
      try {
        this.removeObserver('form.user', this, this.formUserObserver);
        this.removeObserver('form.absenceType', this, this.formAbsenceObserver);
      } catch (error) {
        if (false
        /* DEBUG */
        ) {
          console.warn('could not destroy observer', error);
        }
      }
    },

    /**
     * set of actions
     */
    actions: {
      acceptAbsenceRequest: function acceptAbsenceRequest() {
        this.send('saveAbsenceRequest', 'APPROVED');
      },
      rejectAbsenceRequest: function rejectAbsenceRequest() {
        this.send('saveAbsenceRequest', 'REJECTED');
      },
      saveAbsenceRequest: function saveAbsenceRequest(state) {
        var _this = this;

        if (!this.get('alreadyAdded')) {
          this.set('alreadyAdded', true);
          this.set('form.state', state);
          var className = 'absenceRequest';
          this.get('form').validate().then(function () {
            _this.get('form').execute();

            return _this.get('model').save().then(function () {
              _this.get('absencesController').send('absenceRequestUpdated', {
                entry: _this.get('model')
              });

              _this.get('notifications').success('alert.saved', className);
            }).catch(function (err) {
              _this.get('notifications').errorWithObject(err, 'alert.errorOnSave', className);
            });
          }).catch(function (err) {
            _this.get('notifications').errorWithObject(err, 'alert.errorOnSave', className);
          }).finally(function () {
            _this.set('closeModal', true); // Navigate away from route


            _this.send('transitionToList');
          });
        }
      },
      onHide: function onHide() {
        var _this2 = this;

        /**
         * removes focus observers of form elements
         */
        this._destroyFocusObserver(); // Navigate away from route


        this.send('transitionToList'); // needs to run with a timeout due to a potential race condition that could occur
        // when onHide is being triggered before saveTimeEntry and its validate are done
        // => execute would be called AFTER rollback => ERROR

        Ember.run.later(function () {
          var form = _this2.get('form');

          if (form) {
            form.rollback();
          }
        }, 1000);
      }
    }
  });

  _exports.default = _default;
});