define("coffeecup/components/planner/timeline-overlay", ["exports", "coffeecup/utils/date", "coffeecup/utils/color-hex2rgba"], function (_exports, _date, _colorHex2rgba) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['style', 'title'],
    classNameBindings: ['hidden', 'color'],
    viewport: Ember.inject.service('planner.viewport'),
    blockedTimeframes: null,
    isHovering: false,
    left: 0,
    currentDate: Ember.computed('viewport.startDate', 'viewport.dayWidth', 'left', function () {
      var distanceY = this.get('left') / this.get('viewport.dayWidth');
      return this.get('viewport').shiftDate(this.get('viewport.startDate'), distanceY);
    }),
    hidden: Ember.computed('currentDate', 'isHovering', 'blockedTimeframes.@each.startDateFmt', 'blockedTimeframes.@each.endDateFmt', function () {
      var _this = this;

      var currentDate = this.get('currentDate');
      var blockedTimeframes = this.get('blockedTimeframes') || Ember.A();
      return !this.get('isHovering') || !!blockedTimeframes.find(function (timeFrame) {
        if (timeFrame && (0, _date.isDateValid)(timeFrame.get('startDate')) && (0, _date.isDateValid)(timeFrame.get('endDate'))) {
          // In weekly planning mode, do not allow adding a weekly planning to an existing daily planned item
          if (_this.get('viewport.isWeeklyPlanningMode')) {
            return (0, _date.isInRange)(currentDate, timeFrame.get('startDate').clone().startOf('isoWeek'), timeFrame.get('endDate').clone().endOf('isoWeek'));
          } else {
            return (0, _date.isInRange)(currentDate, timeFrame.get('startDate'), timeFrame.get('endDate'));
          }
        }
      });
    }),
    width: Ember.computed('assignmentWidth', {
      get: function get() {
        var viewportAssignmentWidth = this.get('assignmentWidth');
        return viewportAssignmentWidth - 1;
      }
    }),
    style: Ember.computed('left', function () {
      var bgColor = _colorHex2rgba.RGBA.convertFromHex(this.get('projectColor'), 0.8);

      var styleString = 'left: ' + this.get('left') + 'px; width: ' + this.get('width') + 'px; background-color:' + bgColor.toCSS() + ';';
      return Ember.String.htmlSafe(styleString);
    }),
    watchTimeline: Ember.on('didInsertElement', function () {
      var $row = this.$().closest('.row-timeline');
      this.set('top', $row.offset().top);
      $row.on({
        mouseenter: Ember.run.bind(this, function (e) {
          this.set('isActive', false);
          this.set('isHovering', true);
          this.moveOverlay(e);
        }),
        mousemove: Ember.run.bind(this, function (e) {
          this.moveOverlay(e);
        }),
        mouseleave: Ember.run.bind(this, function () {
          this.set('isActive', false);
          this.set('isHovering', false);
        })
      });
    }),
    willDestroyElement: function willDestroyElement() {
      this.$().closest('.row-timeline').off('mouseenter mouseleave mousemove');
    },
    assignmentWidth: Ember.computed.reads('viewport.assignmentWidth'),
    moveOverlay: function moveOverlay(e) {
      var leftDistance = (e ? e.pageX : this.get('left')) - this.get('viewport.rowHeaderWidth');
      this.set('left', leftDistance - leftDistance % this.get('assignmentWidth'));
    },
    click: function click() {
      this.set('isActive', true);
      this.get('viewport').selectDay(this.get('currentDate'));
      this.sendAction('onClick');
    }
  });

  _exports.default = _default;
});