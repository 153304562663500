define("coffeecup/demo-data/dashboard/my-week-team", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.myWeekTeamData = void 0;
  var myWeekTeamData = [{
    user: Ember.Object.create({
      label: 'Johannes Stich',
      imageSmall: '/assets/images/demo/demo-user-3.png'
    }),
    projects: [],
    absencesForToday: [],
    isProjectManagerAnywhere: true
  }, {
    user: Ember.Object.create({
      label: 'Clairé Assange',
      imageSmall: '/assets/images/demo/demo-user-4.png'
    }),
    projects: [],
    absencesForToday: [{
      absenceType: {
        colorCode: '#028ac0'
      }
    }],
    isProjectManagerAnywhere: true
  }, {
    user: Ember.Object.create({
      label: 'Lisa Feldmayer',
      imageSmall: '/assets/images/demo/demo-user-5.png'
    }),
    projects: [],
    absencesForToday: [],
    isProjectManagerAnywhere: false
  }, {
    user: Ember.Object.create({
      label: 'Joshua Smith',
      imageSmall: '/assets/images/demo/demo-user-1.png'
    }),
    projects: [],
    absencesForToday: [],
    isProjectManagerAnywhere: false
  }, {
    user: Ember.Object.create({
      label: 'Natasha Ramirez',
      imageSmall: '/assets/images/demo/demo-user-2.png',
      hasBirthday: true
    }),
    projects: [],
    absencesForToday: [],
    isProjectManagerAnywhere: false
  }];
  _exports.myWeekTeamData = myWeekTeamData;
});