define("coffeecup/components/manage/projects/project-settlements/settlement-list", ["exports", "coffeecup/utils/build-url"], function (_exports, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    formatter: Ember.inject.service(),
    easybill: Ember.inject.service(),
    sevdesk: Ember.inject.service(),
    project: null,
    pageLoadedAt: null,
    modalOptions: null,
    unlockTimEntriesBeforeDelete: true,
    showConfirmModal: false,
    showAllInvoices: false,
    invoices: [],
    orderedInvoices: Ember.computed('invoices', function () {
      return this.get('invoices').sortBy('createdAt').reverse();
    }),
    limitedInvoices: Ember.computed('orderedInvoices', 'showAllInvoices', function () {
      if (this.get('showAllInvoices')) return this.get('orderedInvoices');
      return this.get('orderedInvoices').slice(0, 5);
    }),
    invoicesToLoadQuantity: Ember.computed('orderedInvoices', 'showAllInvoices', function () {
      if (this.get('showAllInvoices')) return 0;
      return this.get('orderedInvoices').length - 5;
    }),
    didInsertElement: function didInsertElement() {
      this._loadInvoices();
    },
    _loadInvoicesObserver: Ember.observer('allInvoices.[]', 'project', 'pageLoadedAt', function () {
      Ember.run.once(this, '_loadInvoices');
    }).on('init'),
    _loadInvoices: function _loadInvoices() {
      var _this = this;

      if (this.get('project')) {
        this.set('isLoading', true);
        this.set('fixedBilledAmount', 0);
        this.get('store').query('invoice', {
          where: {
            project: this.get('project.id')
          }
        }).then(function (invoices) {
          _this.set('isLoading', false);

          _this.set('invoices', invoices);
        }).catch(function (err) {
          _this.set('isLoading', false);
        });
      } else {
        this.set('invoices', []);
      }
    },
    totalInvoicesAmount: Ember.computed('invoices.@each.{amount}', function () {
      if (!this.get('invoices')) return 0;
      return this.get('invoices').reduce(function (prev, el) {
        return prev + el.get('amount');
      }, 0);
    }),
    remainingBudget: Ember.computed('project.budgetAmount', 'totalInvoicesAmount', function () {
      if (!this.get('project') || !this.get('project.isModeFixedBudget')) return null;
      return this.get('project.budgetAmount') - this.get('totalInvoicesAmount');
    }),
    actions: {
      onCreateNew: function onCreateNew(project) {
        this.sendAction('onCreateNew', project);
      },
      updateInvoice: function updateInvoice(invoice) {
        var _this2 = this;

        invoice.set('startDate', moment.utc(invoice.get('startDate')));
        invoice.set('endDate', moment.utc(invoice.get('endDate')));
        invoice.save().then(function () {
          _this2.get('notifications').success('alert.saved', 'settlement');
        }).catch(function (error) {
          _this2.get('notifications').errorWithObject(error, 'alert.errorOnSave', 'settlement');
        });
      },
      deleteWithoutUnlock: function deleteWithoutUnlock(invoice) {
        var _this3 = this;

        var invoiceProjectId = invoice.get('project.id');
        invoice.destroyRecord().then(function () {
          _this3.get('store').findAll('expense');

          _this3.get('store').findRecord('analytics/project', invoiceProjectId);

          _this3.get('notifications').success('alert.deleted', 'settlement');
        }).catch(function (error) {
          _this3.get('notifications').errorWithObject(error, 'alert.errorOnSave', 'settlement');
        });
      },
      delete: function _delete(invoice) {
        var _this4 = this;

        if (!this.get('unlockTimEntriesBeforeDelete')) {
          this.send('deleteWithoutUnlock', invoice);
          return;
        }

        this.get('session').postJSON((0, _buildUrl.default)(this.get('store'), 'invoices/unlock-time-entries', {
          invoice: invoice.get('id'),
          project: invoice.get('project.id')
        })).then(function () {
          _this4.set('unlockTimEntriesBeforeDelete', true);

          _this4.send('deleteWithoutUnlock', invoice);
        }).catch(function (error) {
          _this4.get('notifications').errorWithObject(error, 'alert.errorOnSave', 'settlement');
        });
      },
      deleteInvoice: function deleteInvoice(invoice) {
        var _this5 = this;

        this.set('unlockTimEntriesBeforeDelete', true);
        this.set('modalOptions', {
          title: this.get('i18n').t('deleteInvoice'),
          checkboxLabel: this.get('i18n').t('unlockTimeEntriesAndExpensesDescription', {
            startDate: this.get('formatter').formatDate(invoice.get('startDate')),
            endDate: this.get('formatter').formatDate(invoice.get('endDate'))
          }),
          closeBtnText: this.get('i18n').t('cancel'),
          confirmBtnText: this.get('i18n').t('confirm'),
          confirmCallback: function confirmCallback() {
            _this5.send('delete', invoice);
          }
        });
        this.set('showConfirmModal', true);
      },
      hideModal: function hideModal() {
        this.set('showConfirmModal', false);
      },
      toggleAllInvoices: function toggleAllInvoices() {
        this.set('showAllInvoices', !this.get('showAllInvoices'));
      },
      openExternalInvoice: function openExternalInvoice(invoice) {
        if (invoice.get('externalReference.service.name') === 'sevdesk') {
          this.get('sevdesk').openInvoiceInNewWindow(invoice);
        }

        if (invoice.get('externalReference.service.name') === 'easybill') {
          if (!invoice.get('externalReference.externalCompanySubdomain')) return;
          this.get('easybill').openInvoiceInNewWindow(invoice, "".concat(invoice.get('externalReference.externalCompanySubdomain'), ".easybill.de"));
        }
      },
      openBillingSummary: function openBillingSummary() {
        this.sendAction.apply(this, ['openBillingSummary'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });

  _exports.default = _default;
});