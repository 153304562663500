define("coffeecup/components/t-tip", ["exports", "ember-cli-tooltipster/components/tool-tipster"], function (_exports, _toolTipster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _toolTipster.default.extend({
    tagName: 'span',
    classNames: ['t-tip']
  });

  _exports.default = _default;
});