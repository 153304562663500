define("coffeecup/components/planner/milestone-frame", ["exports", "coffeecup/components/planner/time-frame", "coffeecup/mixins/deferred-setup"], function (_exports, _timeFrame, _deferredSetup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _timeFrame.default.extend(_deferredSetup.default, {
    activeModal: Ember.inject.service('planner.active-modal'),
    isActive: Ember.computed('activeModal.milestone', function () {
      return this.get('milestone.id') === this.get('activeModal.milestone');
    }),
    classNames: ['milestone'],
    classNameBindings: ['editable::readonly', 'isActive:is-active', 'isActive:modal-reference'],
    milestone: null,
    editable: null,
    mouseDownTarget: null,
    mouseUpTarget: null,
    tornDown: false,
    projectColor: null,
    useProjectColor: null,
    style: Ember.computed('left', 'projectColor', function () {
      return Ember.String.htmlSafe("left: ".concat(this.get('left'), "px; color: ").concat(this.get('projectColor')));
    }),
    day: Ember.computed.alias('milestone.day'),
    startDate: Ember.computed.alias('milestone.day'),
    endDate: Ember.computed.alias('milestone.day'),
    setup: Ember.on('didInsertElement', function () {
      if (this.get('isActive')) {
        var viewport = this.get('viewport');
        var day = moment(this.get('day'));
        var viewportStartDate = viewport.get('startDate');

        if (day.isBefore(viewportStartDate)) {
          day = viewportStartDate;
          Ember.run.next(this, function () {
            viewport.get('selectedDay') || viewport.selectDay(day);
          });
        }
      }
    }),
    editableObserver: Ember.observer('editable', function () {
      this.get('editable') ? this.resolveSetup() : this.teardown();
    }),
    dayWidthObserver: Ember.observer('viewport.dayWidth', function () {
      if (this.get('isSetup') && this.get('editable')) {
        this.$().draggable('option', 'grid', [this.get('viewport.dayWidth'), 0]);
      }
    }),
    resolveSetup: function resolveSetup() {
      if (this.get('editable')) {
        this._super();

        this.set('tornDown', false);
        this.setupDrag();
      }
    },
    setupDrag: function setupDrag() {
      var leftPosition = null;
      this.$().draggable({
        axis: 'x',
        grid: [this.get('viewport.dayWidth'), 0],
        start: Ember.run.bind(this, function (event, elem) {
          this.$().addClass('is-dragging');
          leftPosition = elem.originalPosition.left;
        }),
        stop: Ember.run.bind(this, function (event, elem) {
          this.$().removeClass('is-dragging');
          var dayWidth = this.get('viewport.dayWidth');
          var shift = (elem.position.left - elem.originalPosition.left) / dayWidth;
          var milestone = this.get('milestone');

          if (shift) {
            milestone.set('day', this.get('viewport').shiftDate(milestone.get('day'), shift));
            this.sendAction('onMoved', milestone);
          }

          this.$().draggable('destroy');
          this.setupDrag();
        })
      });
    },
    teardownDrag: function teardownDrag() {
      this.$().draggable('destroy');
    },
    teardown: function teardown() {
      if (!this.get('tornDown') && this.get('isSetup')) {
        this.set('tornDown', true);
        this.teardownDrag();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.teardown();
    },
    clickedDay: function clickedDay(clientX) {
      var left = this.$().offset().left;
      var dayWidth = this.get('viewport.dayWidth');
      var daysToShift = Math.floor((clientX - left) / dayWidth);
      return this.get('viewport').shiftDate(this.get('startDate'), daysToShift);
    },
    click: function click(event) {
      var milestone = this.get('milestone');

      if (this.get('editable') && this.get('mouseDownTarget') === this.get('mouseUpTarget')) {
        this.get('viewport').selectDay(this.clickedDay(event.clientX));
        this.get('activeModal').setMilestone(milestone.get('id'));
        this.sendAction('onClick', milestone);
        this.set('mouseDownTarget', null);
        this.set('mouseUpTarget', null);
      }
    },
    mouseUp: function mouseUp(event) {
      this.set('mouseUpTarget', event.target);
    },
    mouseDown: function mouseDown(event) {
      this.set('mouseDownTarget', event.target);
    }
  });

  _exports.default = _default;
});