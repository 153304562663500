define("coffeecup/components/analytics-module/user/general-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * The hourly rate property is set in analytics-module/user/performance
     * and is passed via the analytics/users/details controller.
     */
    hourlyRate: 0,
    currentUserIsAdmin: false,
    hasProbationaryPeriod: Ember.computed('user.probationaryPeriod', function () {
      return this.get('user.probationaryPeriod') && this.get('user.probationaryPeriod').isAfter(this.get('clock.now'));
    }),
    hourlyRateNotAvailable: Ember.computed('currentEmploymentEntry.validFrom', function () {
      return !this.get('currentEmploymentEntry.validFrom') || this.get('currentEmploymentEntry.validFrom').isSame(this.get('clock.now'), 'day');
    })
  });

  _exports.default = _default;
});