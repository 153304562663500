define("coffeecup/controllers/time-entries/tracking/day", ["exports", "coffeecup/config/environment", "coffeecup/utils/format-duration"], function (_exports, _environment, _formatDuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TimeEntryDay = Ember.Object.extend({
    currentUser: null,
    timerModeIsDuration: true,
    day: null,
    user: null,
    minuteSnap: _environment.default.constants.RESIZE_MINUTE_SNAP,
    isResizing: false,
    timeEntries: [],
    absences: [],
    plannerAssignments: [],
    userHolidays: [],
    validations: null,
    expectedHours: 0,
    isWorking: null,
    // Ember dependency injection confuses me, I'll just pass the clock manually from TimeEntriesController
    clock: null,
    screen: null,
    dayFmt: Ember.computed('day', function () {
      return this.get('day').format(_environment.default.constants.DATE_FORMAT_DAY);
    }),
    firstDayMonday: Ember.computed.equal('currentUser.beginningOfWeek', _environment.default.constants.WEEKDAY_MONDAY),
    entriesForDay: Ember.computed('dayFmt', 'timeEntries', 'timeEntries.[]', 'timeEntries.@each.dayFmt', function () {
      var dayFmt = this.get('dayFmt');
      return this.get('timeEntries') ? this.get('timeEntries').filter(function (timeEntry) {
        return timeEntry.get('dayFmt') === dayFmt;
      }) : [];
    }),
    absencesSorted: Ember.computed.sort('absences', function (a, b) {
      return moment(a.get('startDate')).isBefore(b.get('startDate')) ? -1 : 1;
    }),
    absenceForDay: Ember.computed('entriesForDay', 'user.id', 'dayFmt', 'absencesSorted', 'absencesSorted.[]', 'absencesSorted.@each.startDate', 'absencesSorted.@each.endDate', 'absencesSorted.@each.user', function () {
      var _this = this;

      return this.get('absencesSorted').filterBy('absenceType.isPublicHoliday', false).reduce(function (prev, absence) {
        if (prev !== null) {
          return prev;
        } else if (absence.isAbsentOnDate(_this.get('day'))) {
          return absence;
        } else {
          return null;
        }
      }, null);
    }),
    absenceTypeClass: Ember.computed('absenceForDay.absenceType.typeClass', 'holidayForDay', function () {
      if (this.get('holidayForDay')) {
        return this.get('holidayForDay.type');
      } else {
        return this.get('absenceForDay.absenceType.typeClass');
      }
    }),
    plannerAssignmentsForDay: Ember.computed('entriesForDay', 'user.id', 'dayFmt', 'isWorking', 'absencesSorted', 'absencesSorted.[]', 'absencesSorted.@each.startDate', 'absencesSorted.@each.endDate', 'absencesSorted.@each.user', function () {
      var _this2 = this;

      var plannerAssignments = this.get('plannerAssignments');

      if (plannerAssignments) {
        var isWorking = this.get('isWorking');
        var noUserHolidayOnDay = Ember.isBlank(this.get('holidayForDay'));
        return plannerAssignments.filter(function (plannerAssignment) {
          var isActiveOnDaysOff = plannerAssignment.get('activeOnDaysOff');
          return plannerAssignment.get('isActive') && plannerAssignment.includesDate(_this2.get('day')) && (isActiveOnDaysOff || isWorking && noUserHolidayOnDay);
        });
      }
    }),
    holidayForDay: Ember.computed('userHolidays', 'dayFmt', {
      get: function get() {
        var _this3 = this;

        var publicHoliday = this.get('absencesSorted').find(function (absence) {
          return absence.get('absenceType.isPublicHoliday') && absence.isAbsentOnDate(_this3.get('day'));
        });

        if (publicHoliday) {
          return Ember.Object.create({
            name: publicHoliday.comment,
            type: 'holiday'
          });
        }

        var userHolidays = this.get('userHolidays');
        return userHolidays && userHolidays.reduce(function (prev, holiday) {
          if (prev !== null) {
            return prev;
          } else if (holiday.isHolidayOnDay(_this3.get('day'))) {
            return holiday;
          } else {
            return null;
          }
        }, null);
      }
    }),
    entriesForDaySorted: Ember.computed('entriesForDay', 'entriesForDay.length', 'entriesForDay.@each.startTime', 'entriesForDay.@each.day', 'entriesForDay.@each.sorting', {
      get: function get() {
        var timerModeIsDuration = this.get('timerModeIsDuration');

        if (timerModeIsDuration) {
          return this.get('entriesForDay').sort(function (a, b) {
            return a.get('sortingOrStartTime') < b.get('sortingOrStartTime') ? -1 : 1;
          });
        } else {
          return this.get('entriesForDay').sort(function (a, b) {
            return a.get('startTimeDecimal') < b.get('startTimeDecimal') ? -1 : 1;
          });
        }
      }
    }),
    isWeekend: Ember.computed('dayFmt', {
      get: function get() {
        var firstDayMonday = this.get('firstDayMonday');
        var weekday = this.get('day').weekday();

        if (firstDayMonday) {
          return weekday > 4;
        } else {
          return weekday % 6 === 0;
        }
      }
    }),
    isFuture: Ember.computed.equal('relativeToToday', _environment.default.constants.DATE_IS_FUTURE),
    isToday: Ember.computed.equal('relativeToToday', _environment.default.constants.DATE_IS_PRESENT),
    relativeToToday: Ember.computed('dayFmt', 'clock.day', {
      get: function get() {
        var today = this.get('clock.day');
        var currentDay = this.get('day');
        var dateIs = _environment.default.constants.DATE_IS_FUTURE;

        if (currentDay.isSame(today, 'day')) {
          dateIs = _environment.default.constants.DATE_IS_PRESENT;
        } else if (currentDay.isBefore(today, 'day')) {
          dateIs = _environment.default.constants.DATE_IS_PAST;
        }

        return dateIs;
      }
    }),
    hasChildren: Ember.computed.gt('entriesForDay.length', 0),
    showDay: Ember.computed('currentUser.uiState', 'hasChildren', 'isWeekend', 'screen.isSmallAndDown', {
      get: function get() {
        if (this.get('screen.isSmallAndDown')) {
          return true;
        }

        var state = this.get('currentUser.uiState');
        return state && state.get('visibleDaysPerWeek') === 7 || !this.get('isWeekend') || this.get('hasChildren');
      }
    }),
    //==========================================
    // Observers
    //==========================================
    itemsProcessor: Ember.on('init', Ember.observer('entriesForDay', 'entriesForDay.[]', 'entriesForDay.@each.duration', 'entriesForDay.@each.amount', 'entriesForDay.@each.billable', 'entriesForDay.@each.running', function () {
      var secondsTotal = 0;
      var secondsSpent = 0;
      var amountSpent = 0;
      var isBusy = false;
      var minuteSnap = this.get('minuteSnap');
      var resizing = this.get('isResizing');
      var entriesForDay = this.get('entriesForDay');
      var items = entriesForDay && entriesForDay.filterBy('trackingType', _environment.default.constants.TIME_ENTRY_TRACKING_TYPE_WORK);

      if (items) {
        items.forEach(function (item) {
          var duration = item.get('duration');
          var billable = item.get('billable') ? 1 : 0;
          var hourlyRate = item.get('finalHourlyRate');
          var durationRounded = item.get('durationRounded');
          var projectRoundingType = item.get('project.roundingType');
          var projectRoundingAmount = item.get('projectRoundingAmount');
          durationRounded = (0, _formatDuration.getRoundedDuration)(durationRounded, projectRoundingAmount, projectRoundingType);
          var factor = 1;
          var hours = duration / 3600; // Do minute snap for amount spent because we can't figure this out later without hourlyRate

          if (resizing) {
            factor = 60 * minuteSnap / 3600;
            hours = Math.ceil(hours / factor) * factor;
            duration = hours * 3600;
            amountSpent += billable * hourlyRate * durationRounded / 3600;
          } else {
            amountSpent += item.get('amount');
          }

          if (billable) {
            secondsSpent += duration;
          }

          secondsTotal += duration; // set day to busy, if the day has a running entry

          if (item.get('running')) {
            isBusy = true;
          }
        });
      }

      this.set('secondsTotal', secondsTotal);
      this.set('secondsSpent', secondsSpent);
      this.set('amountSpent', amountSpent);
      this.set('hoursTotal', secondsTotal / 3600);
      this.set('isBusy', isBusy);
    })),
    //==========================================
    // Set by observer
    //==========================================
    isBusy: false,
    secondsTotal: 0,
    secondsSpent: 0,
    hoursTotal: 0
  });
  var _default = TimeEntryDay;
  _exports.default = _default;
});