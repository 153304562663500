define("coffeecup/components/planner/allocated-frame", ["exports", "coffeecup/config/environment", "coffeecup/components/planner/time-frame", "coffeecup/utils/number", "coffeecup/utils/allocation-math", "coffeecup/mixins/planner/label-position"], function (_exports, _environment, _timeFrame, _number, _allocationMath, _labelPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _timeFrame.default.extend(_labelPosition.default, {
    viewport: Ember.inject.service('planner.viewport'),
    activeOnDaysOff: 0,
    classNameBindings: ['allocatedStatus', 'activeOnDaysOff:active-on-days-off', 'isHalfDay:half-day'],
    allocation: 0,
    isAbsent: false,
    isAbsenceRequest: false,
    absenceRangeType: null,
    isHalfDay: Ember.computed.equal('absenceRangeType', _environment.default.constants.RANGE_TYPE_ONE_DAY_HALF),
    isWorking: false,
    style: Ember.computed('width', 'left', function () {
      return Ember.String.htmlSafe('width:' + this.get('width') + 'px;left:' + this.get('left') + 'px;');
    }),
    hoursFree: Ember.computed('maxAllocationIfWorking', 'allocation', {
      get: function get() {
        var maxAllocationIfWorking = this.get('maxAllocationIfWorking');
        var allocation = this.get('allocation');
        return _allocationMath.default.subtractAllocation(maxAllocationIfWorking, allocation);
      }
    }),
    maxAllocationIfWorking: Ember.computed('maxAllocation', 'isWorking', {
      get: function get() {
        return this.get('isWorking') && this.get('maxAllocation');
      }
    }),
    label: Ember.computed('hoursFree', 'isWorking', 'isAbsent', {
      get: function get() {
        var isWeekly = this.get('viewport.isWeeklyPlanningMode');
        var allocation = Math.round(this.get('allocation'));
        if (!this.get('isWorking') && !allocation) return '';
        if (this.get('isAbsent') && allocation == 0) return '';
        var hoursFree = this.get('hoursFree');
        if (hoursFree === 0) return '';

        if (isWeekly) {
          // In weekly mode, we show the capacity over the total assignment frame (which is a max of one week long)
          hoursFree *= this.get('endDate').day() - this.get('startDate').day() + 1;
        }

        if (hoursFree < 0) {
          hoursFree *= -1;
        }

        return Math.round(hoursFree * 100) / 100;
      }
    }),
    isOvertime: Ember.computed.lt('hoursFree', 0),
    allocatedPercentageRange: Ember.computed('allocation', 'maxAllocationIfWorking', {
      get: function get() {
        var percentage = 10 * Math.floor(this.get('allocation') / this.get('maxAllocationIfWorking') * 100 / 10);
        return _number.default.clamp(10, 100, percentage);
      }
    }),
    allocatedStatus: Ember.computed('isAbsent', 'isAbsenceRequest', 'hoursFree', 'isWorking', 'allocatedPercentageRange', {
      get: function get() {
        var status = '';
        var hoursFree = this.get('hoursFree');

        if (this.get('isAbsent')) {
          status += 'allocation-time-off ';
        }

        if (this.get('isAbsenceRequest')) {
          status += 'absence-request-exists ';
        }

        if (hoursFree < 0) {
          status += 'allocation-over';
        } else {
          if (0 === hoursFree) {
            status += 'allocation-full';
          } else {
            status += 'allocation-open-' + (10 - this.get('allocatedPercentageRange') / 10);
          }
        }

        return status; //
        //
        // return this.get('isAbsent') ?
        //   (hoursFree < 0) ?
        //     'allocation-time-off-over' :
        //     'allocation-time-off' :
        //   hoursFree < 0 ?
        //     'allocation-over' :
        //     0 === hoursFree ?
        //       'allocation-full' :
        //       'allocation-open-' + (10 - this.get('allocatedPercentageRange') / 10);
      }
    })
  });

  _exports.default = _default;
});