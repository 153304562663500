define("coffeecup/controllers/time-entries/tracking/edit", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TimeEntriesModalController = Ember.Controller.extend({
    queryParams: ['date', 'userId', 'type'],
    type: _environment.default.constants.TIME_ENTRY_TRACKING_TYPE_WORK,
    accountService: Ember.inject.service('account'),
    permissions: Ember.inject.service(),
    router: Ember.inject.service(),
    accountSettings: Ember.computed.alias('accountService.accountSettings'),

    /**
     * reference to the active TE tracking controller
     */
    trackingController: Ember.inject.controller('time-entries.tracking'),
    entryType: Ember.computed.readOnly('model.trackingType'),
    applicationController: Ember.inject.controller('application'),
    timeEntriesController: Ember.inject.controller('time-entries.tracking'),
    currentUser: Ember.computed.alias('applicationController.currentUser'),
    allowConcurrentTimeEntries: Ember.computed.readOnly('timeEntriesController.allowConcurrentTimeEntries'),
    allowBreaks: Ember.computed.alias('timeEntriesController.allowBreaks'),
    timerModeIsDuration: Ember.computed.readOnly('accountSettings.timerModeIsDuration'),
    isBreakMode: Ember.computed.equal('entryType', _environment.default.constants.TIME_ENTRY_TRACKING_TYPE_BREAK),
    focus: Ember.computed('model.isNew', 'model.id', {
      get: function get() {
        if (this.get('model.isNew')) {
          return 'project';
        } else {
          return 'comment';
        }
      },
      set: function set(key, val) {
        return val;
      }
    }),

    /**
     * filters the available projects based on project and its userassignments
     * @return array of projects
     */
    projectsFiltered: Ember.computed.mapBy('model.user.activeUserAssignments', 'project'),

    /**
     * set of actions
     */
    actions: {
      addTimeEntryToList: function addTimeEntryToList(model) {
        if (!model.get('isBreak')) {
          this.get('applicationController').set('latestTimeEntry', model);
        }

        this.get('trackingController').send('timeEntryCreated', {
          entry: model,
          switchUser: true
        });
      },
      timeEntryDeleted: function timeEntryDeleted(model) {
        this.get('trackingController').send('timeEntryDeleted', {
          entry: model
        });
      }
    }
  });
  var _default = TimeEntriesModalController;
  _exports.default = _default;
});