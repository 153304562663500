define("coffeecup/components/x-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['x-tabs'],

    /**
     * an array with available panes
     */
    panes: null,
    selected: 0,
    selectedPane: Ember.computed('selected', {
      get: function get() {
        var panes = this.get('panes');
        var index = this.get('selected');
        var selectedPane = null;

        if (panes && index >= 0) {
          selectedPane = panes.objectAt(index);
        }

        return selectedPane;
      },
      set: function set(key, pane) {
        var selectedIndex = this.get('panes').reduce(function (previousValue, item, index) {
          return pane === item ? index : previousValue;
        }, -1);

        if (selectedIndex >= 0) {
          this.set('selected', selectedIndex);
        }

        return pane;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.panes = [];
    },
    addPane: function addPane(pane) {
      if (this.get('panes.length') === 0) {
        this.select(pane);
      }

      this.panes.pushObject(pane);
    },
    selectAtIndex: function selectAtIndex(index) {
      var panes = this.get('panes');

      if (index >= 0 && panes.length > index) {
        var pane = panes.objectAt(index);
        this.select(pane);
      }
    },
    select: function select(pane) {
      this.set('selectedPane', pane);
    },
    didInsertElement: function didInsertElement() {
      // Activate selected pane, because the panes were not added at the
      // time a persisted value would have been passed to the component.
      this.selectAtIndex(this.get('selected'));
    },
    actions: {
      select: function select(pane) {
        this.select(pane);
      }
    }
  });

  _exports.default = _default;
});