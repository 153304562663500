define("coffeecup/controllers/analytics/projects/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * INJECTIONS
     */
    formatter: Ember.inject.service(),
    application: Ember.inject.controller(),
    permissions: Ember.inject.service(),

    /**
     * PROPERTIES
     */
    startDate: 0,
    endDate: 0,

    /*
    COMPUTED PROPERTIES
     */
    currentUser: Ember.computed.alias('application.currentUser'),
    selectedProjectId: Ember.computed.alias('selectedProject.id'),
    hasHourBudget: Ember.computed.reads('selectedProject.isModeNotBillable'),
    canAccessProjectCosts: Ember.computed.reads('permissions.canAccessProjectCosts'),
    hasBudget: Ember.computed.or('selectedProjectAnalytics.hoursBudget', 'selectedProjectAnalytics.amountBudget'),
    projectBudget: Ember.computed('selectedProject', 'selectedProjectAnalytics.id', 'selectedProjectAnalytics.amountBudgetTotal', function () {
      var totalBudget = this.get('selectedProjectAnalytics.amountBudgetTotal');

      if (this.get('selectedProject.isModeNotBillable')) {
        totalBudget = this.get('selectedProjectAnalytics.hoursBudget');
      }

      return totalBudget;
    }),
    revenueModule: Ember.computed('currentUser', function () {
      var _this = this;

      var i18n = this.get('i18n');
      var uiState = this.get('currentUser.uiState');
      var revenueModule = Ember.Object.create({
        settingsObj: uiState.get('state.projectAnalyticsModuleState.revenue') || {},
        localizedLabel: i18n.t('hoursModuleTitle'),
        isTypeUserWorktime: true,
        save: function save() {
          _this._updateAnalyticsModule('revenue');
        }
      });
      return revenueModule;
    }),
    hoursModule: Ember.computed('currentUser', function () {
      var _this2 = this;

      var i18n = this.get('i18n');
      var uiState = this.get('currentUser.uiState');
      return Ember.Object.create({
        settingsObj: uiState.get('state.projectAnalyticsModuleState.hours') || {},
        constructor: {
          modelName: 'dashboard-module'
        },
        localizedLabel: i18n.t('hoursModuleTitle'),
        isTypeUserWorktime: true,
        save: function save() {
          _this2._updateAnalyticsModule('hours');
        }
      });
    }),

    /**
     * FUNCTIONS
     */
    _updateAnalyticsModule: function _updateAnalyticsModule(moduleName) {
      var currentUser = this.get('currentUser');
      var analyticsModuleState = currentUser.get('uiState.projectAnalyticsModuleState');
      analyticsModuleState[moduleName] = this.get(moduleName + 'Module.settingsObj');
      currentUser.get('uiState').setProperties({
        analyticsModuleState: analyticsModuleState
      });
      currentUser.save();
    },
    actions: {
      customAction: function customAction(actionName) {
        var _this$get;

        for (var _len = arguments.length, data = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          data[_key - 1] = arguments[_key];
        }

        (_this$get = this.get('application')).send.apply(_this$get, [actionName].concat(data));
      },
      showTimeEntries: function showTimeEntries() {
        var _this$get2;

        (_this$get2 = this.get('application')).send.apply(_this$get2, ['showTimeEntries'].concat(Array.prototype.slice.call(arguments)));
      },
      completeProject: function completeProject() {
        this.get('application').send('completeProject', this.get('selectedProjectId'));
      },
      billProject: function billProject(startDate, endDate) {
        this.get('application').send('billProject', this.get('selectedProjectId')
        /*startDate, endDate*/
        );
      }
    }
  });

  _exports.default = _default;
});