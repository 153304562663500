define("coffeecup/components/vacation-list/element/edit", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isEditing: false,
    selectedYear: null,
    vacationBudget: null,
    isVacationBudget: Ember.computed.equal('vacationBudget.vacationType', _environment.default.constants.VACATION_TYPE_EXTRA_BUDGET),
    isYearlyVacation: Ember.computed.equal('vacationBudget.vacationType', _environment.default.constants.VACATION_TYPE_YEARLY_BUDGET),
    bufferedVacationBudget: {
      quantity: 0,
      comment: ''
    },
    didInsertElement: function didInsertElement() {
      this.set('bufferedVacationBudget.quantity', this.get('vacationBudget.quantity'));
      this.set('bufferedVacationBudget.comment', this.get('vacationBudget.comment'));
    },
    keyDown: function keyDown(event) {
      if (event.key === 'Escape') {
        this.send('cancel');
      }
    },
    actions: {
      save: function save() {
        var vacationBudget = this.get('vacationBudget');
        var quantity = vacationBudget.get('quantity');

        if (quantity !== undefined) {
          if (this.get('isVacationBudget') || this.get('isYearlyVacation')) {
            vacationBudget.set('mode', 'update');
          } else {
            vacationBudget.set('mode', 'new');
            vacationBudget.set('year', this.get('selectedYear'));
            vacationBudget.set('date', this.get('clock.localDayAsUtcMoment'));
          }

          this.sendAction('publishChange', vacationBudget);
        }
      },
      cancel: function cancel() {
        this.set('vacationBudget.quantity', this.get('bufferedVacationBudget.quantity'));
        this.set('vacationBudget.comment', this.get('bufferedVacationBudget.comment'));
        this.sendAction('toggleDisplay');
        this.sendAction('cancelEdit');
      }
    }
  });

  _exports.default = _default;
});