define("coffeecup/components/project-settlement-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['flex-row', 'vertical-center', 'list-row', 'settlement-row'],
    icon: Ember.computed('invoice.externalRefence', function () {
      return "icomoon-".concat(this.invoice.get('externalReference.service.name'), " ").concat(this.invoice.get('externalReference.service.name'));
    }),
    actions: {
      deleteInvoice: function deleteInvoice() {
        this.sendAction('deleteInvoice', this.get('invoice'));
      },
      updateInvoice: function updateInvoice() {
        this.sendAction('updateInvoice', this.get('invoice'));
      },
      openExternalInvoice: function openExternalInvoice() {
        this.sendAction('openExternalInvoice', this.get('invoice'));
      },
      openBillingSummary: function openBillingSummary() {
        this.sendAction('openBillingSummary', this.get('invoice'));
      }
    }
  });

  _exports.default = _default;
});