define("coffeecup/components/input/cc-text-area", ["exports", "coffeecup/utils/strip-tags", "ember-cli-tooltipster/components/tool-tipster", "coffeecup/mixins/has-error", "coffeecup/utils/ui/i18n"], function (_exports, _stripTags, _toolTipster, _hasError, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _toolTipster.default.extend(_hasError.default, {
    classNameBindings: ['defaultClass', 'isRequired'],
    defaultClass: 'form-group',
    labelClass: '',
    inputClass: null,
    inputContainerClass: '',
    value: null,
    type: null,
    disabled: false,
    readonly: false,
    rows: 4,
    errors: null,
    validations: null,
    label: null,
    placeholder: null,
    initialValue: null,
    key: null,
    focus: null,
    charCounterLimit: Ember.computed.alias('validations.length.maximum'),
    showCharCounterIfPercent: 0.1,
    // show counter if only 10% of charCounterLimit remain
    showCharCounter: Ember.computed('charCounterLimit', 'charsLeft', 'showCharCounterIfPercent', function () {
      var charCounterLimit = parseInt(this.get('charCounterLimit'), 10);
      var charsLeft = parseInt(this.get('charsLeft'), 10);
      var showCharCounterIfPercent = parseFloat(this.get('showCharCounterIfPercent'));

      if (charCounterLimit && !isNaN(charCounterLimit)) {
        return charsLeft < charCounterLimit * showCharCounterIfPercent;
      }
    }),
    charCounterClass: 'has-feedback',
    maxHeight: 140,
    autogrow: true,
    autofocus: false,
    isFocused: false,
    didInsertElement: function didInsertElement() {
      var $textarea = this.$('textarea');
      var $expander = this.$('.expander');
      this.set('initialValue', this.get('value'));
      Ember.run.next(this, function () {
        var _this = this;

        $textarea.on('focus', function () {
          Ember.run(function () {
            return _this.set('isFocused', true);
          });
        });
        $textarea.on('blur', function () {
          Ember.run(function () {
            _this.set('isFocused', false);

            _this.set('focus', null);

            if (false
            /* DEBUG */
            ) {
              (false && Ember.warn("onBlur action was triggered in ".concat(_this.toString()), {
                id: 'cc.onBlurAction'
              }));
            }

            if (_this.onBlur) {
              _this.onBlur();
            }
          });
        });

        this._focus();

        if (this.get('autogrow')) {
          this._copyStyles($textarea, $expander); // Setup textarea


          $textarea.css({
            'max-height': this.get('maxHeight'),
            'min-height': this.get('minHeight') ? this.get('minHeight') : $textarea.outerHeight(),
            height: this.get('minHeight') ? this.get('minHeight') : $textarea.outerHeight(),
            'overflow-y': 'hidden',
            resize: 'none',
            'box-sizing': 'border-box'
          });
        } else {
          $textarea.css({
            'max-height': this.get('maxHeight'),
            'min-height': this.get('minHeight') ? this.get('minHeight') : $textarea.outerHeight()
          });
        } // add autogrow observer


        this.addObserver('minHeight', this, this._setMinHeightTextarea);
        this.addObserver('autogrow', this, this._resizeAutogrowingTextarea);
        this.addObserver('value', this, this._resizeAutogrowingTextarea);

        this._resizeAutogrowingTextarea();
      });
    },
    willDestroyElement: function willDestroyElement() {
      var $textarea = this.$('textarea'); // unbind focus and blur

      $textarea.off('focus blur');

      try {
        // remove autogrow observer
        this.removeObserver('minHeight', this, this._setMinHeightTextarea);
        this.removeObserver('autogrow', this, this._resizeAutogrowingTextarea);
        this.removeObserver('value', this, this._resizeAutogrowingTextarea);
      } catch (error) {
        if (false
        /* DEBUG */
        ) {
          console.warn('could not destroy observer', error);
        }
      }
    },
    focusField: Ember.observer('focus', function () {
      this._focus();
    }),
    _focus: function _focus() {
      var focus = this.get('focus');
      var key = this.get('key');

      if (focus && key && focus === key && this.$('textarea') && this.$('textarea').length > 0) {
        this.$('textarea').focus();
      }
    },
    isRequired: Ember.computed('validations', function () {
      var validations = this.get('validations.presence');

      if (validations === true) {
        return 'required';
      }
    }),
    labelClasses: Ember.computed('labelClass', function () {
      return this.get('labelClass') + ' control-label';
    }),
    inputClasses: Ember.computed('inputClass', function () {
      return this.get('inputClass') + ' form-control';
    }),
    inputContainerClasses: Ember.computed('inputContainerClass', function () {
      return this.get('inputContainerClass') + ' controls relative';
    }),
    charsLeft: Ember.computed('value', function () {
      var chrsLft;

      if (this.get('value') == null) {
        chrsLft = this.get('charCounterLimit');
      } else {
        chrsLft = this.get('charCounterLimit') - this.get('value').length;
      }

      return chrsLft;
    }),
    charsLeftLabel: Ember.computed('charsLeft', function () {
      var chrsLftLbl;

      if (this.get('charsLeft') >= 0) {
        chrsLftLbl = this.get('charsLeft') + ' ' + this.get('i18n').t('charactersLeft');
      } else {
        chrsLftLbl = Math.abs(this.get('charsLeft')) + ' ' + this.get('i18n').t('label.charsReduce');
      }

      return chrsLftLbl;
    }),
    valueForAutogrow: Ember.computed('value', function () {
      var val = this.get('value') || '';
      val = (0, _stripTags.default)(val, 'z');
      return val.replace(/\n/g, '<br>');
    }),
    _setMinHeightTextarea: function _setMinHeightTextarea() {
      var minHeight = this.get('minHeight');
      var $textarea = this.$('textarea');
      $textarea.css({
        'min-height': minHeight ? minHeight : $textarea.outerHeight()
      });
    },
    _resizeAutogrowingTextarea: function _resizeAutogrowingTextarea() {
      var _this2 = this;

      if (this.get('autogrow')) {
        var $textarea = this.$('textarea');
        var $expander = this.$('.expander');
        Ember.run.later(function () {
          $expander.css('width', $textarea.innerWidth());

          if ($expander.height() > _this2.get('maxHeight')) {
            $textarea.css('overflow-y', 'scroll');
          } else {
            $textarea.css('overflow-y', 'hidden');
          }

          $textarea.css('height', $expander.innerHeight());
        }, 50);
      }
    },
    _copyStyles: function _copyStyles($from, $to) {
      var properties = ['-webkit-appearance', '-moz-appearance', '-o-appearance', 'appearance', 'font-family', 'font-size', 'font-weight', 'font-style', 'border', 'border-top', 'border-right', 'border-bottom', 'border-left', 'box-sizing', 'padding', 'padding-top', 'padding-right', 'padding-bottom', 'padding-left', 'min-height', 'max-height', 'line-height'];
      properties.forEach(function (p) {
        $to.css(p, $from.css(p));
      });
    },
    hasError: Ember.computed('errorMsg', function () {
      return !!this.get('errorMsg');
    }),
    // tooltip content
    content: Ember.computed('errorMsg', function () {
      return (0, _i18n.translateValidation)(this.get('i18n'), this.get('errorMsg'));
    }),
    // side if the tooltip
    side: ['top'],
    // tooltip will only show when input is focused
    triggerEvent: 'custom',
    _triggerTooltip: Ember.observer('hasError', function () {
      var _this3 = this;

      Ember.run.next(function () {
        var tooltip = _this3.get('tooltipsterInstance');

        var hasError = _this3.get('hasError');

        if (tooltip) {
          if (hasError) {
            tooltip.open();
          } else {
            tooltip.close();
          }
        }
      });
    }),
    actions: {
      cancel: function cancel() {
        if (false
        /* DEBUG */
        ) {
          (false && Ember.warn("onEscape action was triggered in ".concat(this.toString()), {
            id: 'cc.onEscapeAction'
          }));
        }

        this.sendAction('onEscape');
      }
    }
  });

  _exports.default = _default;
});