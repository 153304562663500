define("coffeecup/components/modals/edit-client-contact", ["exports", "coffeecup/mixins/buffered-form", "coffeecup/validations/contact", "coffeecup/utils/focus-error-input"], function (_exports, _bufferedForm, _contact, _focusErrorInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_bufferedForm.default, {
    focus: 'firstname',
    formValidation: _contact.default,
    setFocus: Ember.observer('form', function () {
      this.set('focus', 'firstname');
    }),
    selectedCountry: Ember.computed('form.country', 'countries', function () {
      var _this = this;

      var countries = this.get('countries');

      if (!countries) {
        return null;
      }

      return countries.find(function (x) {
        return x.get('code') === _this.get('form.country').toString();
      });
    }),
    birthdayProxy: Ember.computed('form.birthday.content', {
      get: function get() {
        var form = this.get('form');
        return Ember.get(form, 'birthday.content') || Ember.get(form, 'birthday');
      },
      set: function set(key, value) {
        var form = this.get('form');
        Ember.run.next(this, function () {
          form.set('birthday', value);
        });
        return value;
      }
    }),
    actions: {
      saveClientContact: function saveClientContact() {
        var _this2 = this;

        if (!this.get('submitting')) {
          this.set('submitting', true);
          this.get('form').validate().then(function () {
            if (_this2.get('form.isValid')) {
              _this2.get('form').execute();

              var model = _this2.get('model');

              return model.save().then(function () {
                _this2.get('notifications').success('alert.saved', 'contactPerson');

                _this2.set('openModal', false); // if (this.get('afterSave')) {
                //   this.sendAction('afterSave', model);
                // }

              }).catch(function (err) {
                // console.error(err);
                _this2.get('form').rollback();

                model.rollbackAttributes();

                _this2.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'contactPerson');
              }).finally(function () {
                if (!_this2.get('isDestroyed')) {
                  _this2.set('submitting', false);

                  try {
                    _this2.set('openModal', false);
                  } catch (e) {
                    console.error(e);
                  }
                }
              });
            } else {
              _this2.set('submitting', false);

              _this2.get('notifications').info('pleaseCheckYourInputs');
            }
          }).catch(function (err) {
            _this2.set('submitting', false);

            (0, _focusErrorInput.default)(_this2, err);

            _this2.get('notifications').info('pleaseCheckYourInputs');
          });
        }
      },
      deleteClientContact: function deleteClientContact() {
        var _this3 = this;

        var model = this.get('model');
        model.destroyRecord().then(function () {
          _this3.get('notifications').success('alert.deleted', 'contactPerson');

          _this3.set('openModal', false);
        }).catch(function (err) {
          _this3.get('notifications').errorWithObject(err, 'alert.errorOnDelete', 'timeEntry');
        });
      },
      onHide: function onHide() {
        if (this.get('model.isNew')) this.get('model').deleteRecord();
        this.set('openModal', false);
      }
    }
  });

  _exports.default = _default;
});