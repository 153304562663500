define("coffeecup/controllers/planner/projects", ["exports", "coffeecup/config/environment", "coffeecup/mixins/planner/expandable-rows", "coffeecup/mixins/planner/filter-terms", "coffeecup/components/popover-filter/popover-filter"], function (_exports, _environment, _expandableRows, _filterTerms, _popoverFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TAG_MODEL = 'project';

  var _default = Ember.Controller.extend(_expandableRows.ExpandableRows, _filterTerms.default, {
    viewport: Ember.inject.service('planner.viewport'),
    currentUserService: Ember.inject.service('currentUser'),
    application: Ember.inject.controller(),
    planner: Ember.inject.controller('planner'),
    clientService: Ember.inject.service('client'),
    permissions: Ember.inject.service(),
    queryParams: {
      'selectedFilters.userIds': {
        scope: 'controller',
        as: 'user'
      },
      'selectedFilters.tagIds': {
        scope: 'controller',
        as: 'tag'
      },
      'selectedFilters.projectIds': {
        scope: 'controller',
        as: 'project'
      },
      'selectedFilters.clientIds': {
        scope: 'controller',
        as: 'client'
      },
      'selectedFilters.teamIds': {
        scope: 'controller',
        as: 'team'
      }
    },
    tagModel: [TAG_MODEL],
    showPlannerProjectDuration: Ember.computed.reads('currentUserService.currentUser.uiState.showPlannerProjectDuration'),
    disableFrameCalculation: Ember.computed.reads('planner.disableFrameCalculation'),
    currentUserCanWrite: Ember.computed.reads('planner.currentUserCanWrite'),
    existingTags: Ember.computed.reads('planner.existingTags'),
    weekGrid: Ember.computed.alias('planner.weekGrid'),
    activeUsers: Ember.computed.alias('application.activeUsers'),
    shownActiveUsers: Ember.computed.filter('activeUsers', ['planner.showHiddenUsers'], function () {
      if (!this.get('planner.showHiddenUsers')) {
        return this.get('activeUsers');
      }

      return this.get('activeUsers').filterBy('showInPlanner');
    }),
    sortKeys: _environment.default.constants.PROJECT_SORT_KEYS,
    activeProjects: Ember.computed.alias('application.activeProjects'),
    tagAssignments: Ember.computed.alias('planner.tagAssignments'),
    teams: Ember.computed.alias('application.allTeams'),
    filterKeys: _environment.default.constants.PROJECT_FILTER_KEYS,
    sortedProjects: Ember.computed.sort('activeProjects', 'sortKeys'),
    milestones: Ember.computed.reads('planner.milestones'),
    hasMilestones: Ember.computed.reads('planner.hasMilestones'),
    showMilestones: Ember.computed.reads('planner.showMilestones'),
    sortedClients: Ember.computed('application.allClients', function () {
      return this.get('clientService').clientsSortedAndGroupedByInactive(this.get('application.allClients'));
    }),
    canOnlyManageOwnPlanner: Ember.computed.reads('permissions.canOnlyManageOwnPlanner'),
    visibleRows: Ember.computed('sortedProjects', 'planner.assignments.[]', 'selectedFilters.userIds', 'selectedFilters.tagIds', 'selectedFilters.projectIds', 'selectedFilters.clientIds', 'selectedFilters.teamIds', function () {
      var _this = this;

      var allPlannerAssignments = this.get('planner.assignments');
      var currentUserAssignmentProjectIds = this.get('currentUserService.currentUser.userAssignments').mapBy('project.id');
      return this.get('sortedProjects').filter(function (project) {
        if (_this.get('canOnlyManageOwnPlanner') && !currentUserAssignmentProjectIds.includes(project.get('id'))) {
          return false;
        }

        var _this$get = _this.get('selectedFilters'),
            users = _this$get.users,
            projects = _this$get.projects,
            clients = _this$get.clients,
            tags = _this$get.tags,
            teams = _this$get.teams;

        var hasUsersFilter = !!users.length;
        var hasProjectsFilter = !!projects.length;
        var hasClientsFilter = !!clients.length;
        var hasTeamFilter = !!teams.length;
        var hasTagsFilter = tags[TAG_MODEL] ? !!tags[TAG_MODEL].length : false;

        if (hasUsersFilter) {
          var projectHasUserPlannerAssignment = allPlannerAssignments.filter(function (assignment) {
            return assignment.get('project') === project;
          }).some(function (assignment) {
            return users.some(function (user) {
              return assignment.get('user.id') === user.get('id');
            });
          });

          if (!projectHasUserPlannerAssignment) {
            return false;
          }
        }

        if (hasProjectsFilter && !projects.includes(project)) {
          return false;
        }

        if (hasClientsFilter) {
          var projectBelongsToClient = clients.some(function (client) {
            return project.get('client.id') === client.get('id');
          });

          if (!projectBelongsToClient) {
            return false;
          }
        }

        if (hasTagsFilter) {
          var matchesTagFilter = tags[TAG_MODEL].every(function (tag) {
            return tag.get('tagAssignments').toArray().some(function (tagAssignment) {
              return tagAssignment.get('model') === TAG_MODEL && tagAssignment.get('record') === project.get('idAsInt');
            });
          });

          if (!matchesTagFilter) {
            return false;
          }
        }

        if (hasTeamFilter) {
          // give me the users in those selected teams
          var usersInTeams = _this.get('activeUsers').filter(function (user) {
            return teams.findIndex(function (team) {
              return team.get('id') === user.team.get('id');
            }) > -1;
          }); // give me the project assigment of those selected users


          var usersInProject = allPlannerAssignments.filter(function (assignment) {
            return usersInTeams.findIndex(function (uss) {
              return uss.get('id') === assignment.get('user.id');
            }) > -1;
          }); // check if those project assigments belong to the current project

          var projectHasUsers = usersInProject.some(function (ass) {
            return ass.get('project.id') === project.get('id');
          });

          if (!projectHasUsers) {
            return false;
          }
        }

        return true;
      });
    }),
    filterOrder: Ember.computed('canOnlyManageOwnPlanner', function () {
      // in base case, use default values from popover-filter.ts
      return this.get('canOnlyManageOwnPlanner') ? ['projects', 'clients'] : undefined;
    }),
    noVisibleRows: Ember.computed.empty('visibleRows'),
    rowsAreExpanded: Ember.computed.alias('planner.rowsAreExpanded'),
    selectedFilters: new _popoverFilter.SelectedFilters(),
    plannedInScope: Ember.computed('viewport.scopeStartDate', 'viewport.scopeEndDate', 'visibleRows.[]', 'visibleRows.@each.plannerAssignmentsUpdatedAtHash', function () {
      var startDate = this.get('viewport.scopeStartDate');
      var endDate = this.get('viewport.scopeEndDate');
      var total = this.get('visibleRows').reduce(function (sum, project) {
        return sum + project.get('plannerAssignments').reduce(function (paTotal, plannerAssignment) {
          return paTotal + plannerAssignment.allocationForPeriod(startDate, endDate);
        }, 0);
      }, 0);
      return Math.floor(total * 10) / 10;
    }),
    actions: {
      afterSaveMilestone: function afterSaveMilestone(milestone) {
        var milestones = this.get('milestones');

        if (milestones && milestone && !milestones.findBy('id', milestone.get('id'))) {
          milestones.addObject(milestone);
        }
      },
      deleteMilestone: function deleteMilestone(milestone, project) {
        if (project) {
          project.get('milestones').removeObject(milestone);
        }

        this.get('milestones').removeObject(milestone);
      },
      completeProject: function completeProject(project) {
        this.get('application').send('completeProject', project.id);
      },
      billProject: function billProject(project) {
        this.get('application').send('billProject', project.id);
      },
      showTimeEntries: function showTimeEntries() {
        var _this$get2;

        (_this$get2 = this.get('application')).send.apply(_this$get2, ['showTimeEntries'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });

  _exports.default = _default;
});