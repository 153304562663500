define("coffeecup/components/analytics/time-entries/list", ["exports", "coffeecup/components/fsg-list"], function (_exports, _fsgList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var TEAnalyticsList = _fsgList.default.extend({});

  var _default = TEAnalyticsList;
  _exports.default = _default;
});