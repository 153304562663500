define("coffeecup/components/planner/week-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'week-grid',
    viewport: Ember.inject.service('planner.viewport'),
    left: 0,
    throttle: null,
    today: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.today = this.$('.timeline-day-highlight');
      var $body = Ember.$(document.body);
      $body.on({
        'mouseenter.weekgrid': Ember.run.bind(this, 'showHighlight'),
        'mouseleave.weekgrid': Ember.run.bind(this, 'hideHighlight'),
        'mousemove.weekgrid': Ember.run.bind(this, function (elem) {
          this.throttle = Ember.run.throttle(this, 'highlightHoveredDay', elem, 50);
        })
      }, '.planner-rows');
    },
    willDestroyElement: function willDestroyElement() {
      var $body = Ember.$(document.body);
      $body.off('.weekgrid', '.planner-rows');

      if (this.throttle) {
        Ember.run.cancel(this.throttle);
        this.throttle = null;
      }

      this._super.apply(this, arguments);
    },
    showHighlight: function showHighlight() {
      this.today.show();
    },
    hideHighlight: function hideHighlight() {
      this.today.hide();
    },
    highlightHoveredDay: function highlightHoveredDay(elem) {
      var x = elem.pageX - this.get('viewport.rowHeaderWidth');

      if ((x = x < 0 ? 9999 : x - x % this.get('viewport.dayWidth')) !== this.get('left')) {
        this.today.css('left', x);
        this.set('left', x);
      }
    }
  });

  _exports.default = _default;
});