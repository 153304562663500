define("coffeecup/helpers/is-eq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Compares equality of two objects
   */
  var _default = Ember.Helper.helper(function (objects) {
    return Ember.isArray(objects) && objects.length == 2 && objects[0] == objects[1];
  });

  _exports.default = _default;
});