define("coffeecup/components/sortable-item", ["exports", "coffeecup/components/sortable-time-entry-list"], function (_exports, _sortableTimeEntryList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['sortable-item'],
    attributeBindings: ['entryId:' + _sortableTimeEntryList.SORTABLE_ATTR],

    /**
     * the id of the entry
     */
    entryId: null,
    isSorting: false,
    didInsertElement: function didInsertElement() {
      var parentView = this.get('parentView');

      while (parentView) {
        if (typeof parentView.addItem !== 'undefined') {
          parentView.addItem(this);
          break;
        }

        parentView = parentView.get('parentView');
      }
    },
    willDestroyElement: function willDestroyElement() {
      var parentView = this.get('parentView');

      while (parentView) {
        if (typeof parentView.removeItem !== 'undefined') {
          parentView.removeItem(this);
          break;
        }

        parentView = parentView.get('parentView');
      }
    }
  });

  _exports.default = _default;
});