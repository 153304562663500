define("coffeecup/components/dashboard-module/top-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*
     * PROPERTIES
     */
    actionNames: [],
    hasCustomElement: false,
    ranges: undefined,
    informationTooltip: null,
    draggable: true,

    /*
     * COMPUTED PROPERTIES
     */
    showDatePicker: Ember.computed.or('year', 'from', 'availableNumberOfDays'),

    /*
     * ACTIONS
     */
    actions: {
      startAction: function startAction(name) {
        this.sendAction('startAction', name);
      },
      rangeTypeChanged: function rangeTypeChanged(dateRange) {
        this.rangeTypeChanged(dateRange);
      },
      yearPickerCustomElementAction: function yearPickerCustomElementAction(value) {
        this.sendAction('yearPickerCustomElementAction', value);
      }
    }
  });

  _exports.default = _default;
});