define("coffeecup/controllers/modal/confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * boolean whether the modal should be opened
     */
    showModal: false,

    /**
     * set of actions
     */
    actions: {
      confirm: function confirm(callback) {
        var modelCallback = this.get('model.confirmCallback');

        if (typeof modelCallback === 'function') {
          modelCallback();
        } else {
          this.send('confirmModal', callback);
        }

        this.set('showModal', false);
      },
      onHide: function onHide() {
        var modelCallback = this.get('model.cancelCallback');

        if (typeof modelCallback === 'function') {
          modelCallback();
        }

        this.set('showModal', false);
      }
    }
  });

  _exports.default = _default;
});