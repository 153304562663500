define("coffeecup/components/absence-tracking/request-panel", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*
    INJECTIONS
     */
    currentUserService: Ember.inject.service('currentUser'),

    /*
    PROPERTIES
     */
    isPanelOpen: false,
    absenceRequests: null,
    currentTab: null,
    clickedRequest: null,

    /*
    COMPUTED PROPERTIES
     */
    openRequests: Ember.computed.filter('absenceRequests', function (absenceRequest) {
      var currentUserId = this.currentUser.get('id');
      var absenceRequestUser = absenceRequest.get('sender.id');
      var isAdmin = this.get('currentUserService.isAdmin');
      var senderTeamId = absenceRequest.get('sender.teamAssignment.team.id');
      var matchesTeam = !this.get('teamId') || this.get('teamId') == senderTeamId;
      return matchesTeam && absenceRequest.get('state') === null && (isAdmin || currentUserId !== absenceRequestUser);
    }).property('teamId', 'absenceRequests.@each.state', 'absenceRequests.[]'),
    openRequestCount: Ember.computed.alias('openRequests.length'),
    currentUser: Ember.computed.alias('currentUserService.currentUser'),
    isOpenTab: Ember.computed.equal('currentTab', null),
    isApprovedTab: Ember.computed.equal('currentTab', _environment.default.constants.ABSENCE_REQUEST_APPROVED),
    isRejectedTab: Ember.computed.equal('currentTab', _environment.default.constants.ABSENCE_REQUEST_REJECTED),
    visibleAbsenceRequests: Ember.computed('currentTab', 'absenceRequests.[]', 'absenceRequests.@each.state', 'teamId', function () {
      var _this = this;

      return this.get('absenceRequests').filter(function (absenceRequest) {
        var currentUserId = _this.currentUser.get('id');

        var absenceRequestUser = absenceRequest.get('sender.id');

        var isAdmin = _this.get('currentUserService.isAdmin');

        var senderTeamId = absenceRequest.get('sender.teamAssignment.team.id');
        var matchesTeam = !_this.get('teamId') || _this.get('teamId') == senderTeamId;
        return matchesTeam && absenceRequest.get('state') === _this.get('currentTab') && (isAdmin || currentUserId !== absenceRequestUser);
      });
    }),

    /*
    ACTIONS
     */
    actions: {
      togglePanel: function togglePanel() {
        this.toggleProperty('isPanelOpen');
      },
      showTab: function showTab(tabName) {
        this.set('currentTab', tabName);
      },
      deleteRequest: function deleteRequest(absenceRequest) {
        this.sendAction('deleteAbsenceRequest', absenceRequest);
      },
      approveRequest: function approveRequest(absenceRequest) {
        absenceRequest.set('state', _environment.default.constants.ABSENCE_REQUEST_APPROVED);
        this.sendAction('saveAbsenceRequest', absenceRequest);
      },
      rejectRequest: function rejectRequest(absenceRequest) {
        absenceRequest.set('state', _environment.default.constants.ABSENCE_REQUEST_REJECTED);
        this.sendAction('saveAbsenceRequest', absenceRequest);
      },
      clickOnRequest: function clickOnRequest(request) {
        this.set('clickedRequest', null);
        this.set('clickedRequest', request);
        this.sendAction('confirmAbsenceRequest', request.get('id'));
      }
    }
  });

  _exports.default = _default;
});