define("coffeecup/controllers/planner/absences/calendar", ["exports", "moment", "coffeecup/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['month'],
    application: Ember.inject.controller(),
    absenceController: Ember.inject.controller('planner/absences'),
    currentRoute: 'planner.absences.calendar',
    i18n: Ember.inject.service(),
    router: Ember.inject.service(),
    permissions: Ember.inject.service(),
    notifications: Ember.inject.service(),
    teamService: Ember.inject.service('team'),
    absences: [],
    month: null,
    absenceTypes: [],
    showHiddenUsersInAbsences: Ember.computed.reads('currentUser.currentUser.uiState.showHiddenUsersInAbsences'),
    currentUser: Ember.inject.service('currentUser'),
    hideCalendar: Ember.computed('currentUser', {
      get: function get() {
        return this.get('currentUser.isAdmin');
      }
    }),
    hasAbsenceIntegration: Ember.computed.reads('permissions.absenceIntegrationActive'),
    teams: Ember.computed.alias('application.allTeams'),
    teamId: Ember.computed.alias('absenceController.teamId'),
    selectedTeam: Ember.computed('teamId', function () {
      var _this = this;

      return this.get('teams').find(function (team) {
        return team.get('id') === _this.get('teamId');
      }) || null;
    }),
    sortedAndActiveTeams: Ember.computed('teams', function () {
      var sortedTeams = this.get('teamService').sortTeamsByName(this.get('teams'));
      return this.get('teamService').activeTeams(sortedTeams);
    }),
    numberOfOpenAbsenceRequests: Ember.computed.reads('application.numberOfOpenAbsenceRequests'),
    absencesController: Ember.inject.controller('planner.absences'),
    clickedRequest: Ember.computed.alias('absencesController.clickedRequest'),
    handleRequestClick: Ember.observer('clickedRequest', function () {
      if (this.get('clickedRequest')) {
        this.set('month', this.get('clickedRequest.startDate').format(_environment.default.constants.DATE_PICKER_FORMAT));
      }
    }),
    canCreateRequest: Ember.computed('currentUser', 'absenceTypes.[]', function () {
      return (this.get('currentUser.isAdmin') || this.get('absenceTypes').isAny('canBeRequested')) && !this.get('hasAbsenceIntegration');
    }),
    calendarEvents: Ember.computed('absences.[]', 'userHolidays.[]', 'absenceRequests.[]', 'teamId', {
      get: function get() {
        var _this2 = this;

        var i18n = this.get('i18n');
        var absences = this.get('absences').filterBy('isActive', true).filter(function (absence) {
          return !_this2.get('teamId') || absence.get('user.teamAssignment.team.id') === _this2.get('teamId');
        }).map(function (absence) {
          var isPublicHoliday = absence.get('absenceType.isPublicHoliday');
          return {
            title: absence.get('user.firstname') ? absence.get('user.label') : absence.get('absenceType.localizedLabel'),
            type: absence.get('absenceType'),
            urlParams: "user=".concat(absence.get('user.id')) + "&startDate=".concat((0, _moment.default)(absence.get('startDate')).format('YYYY-MM-DD')) + "&endDate=".concat((0, _moment.default)(absence.get('endDate')).format('YYYY-MM-DD')) + "&rangeType=".concat(absence.get('halfDay') ? _environment.default.constants.RANGE_TYPE_ONE_DAY_HALF : _environment.default.constants.RANGE_TYPE_MULTIPLE_DAYS_FULL) + "&excludeAbsence=".concat(absence.get('id')),
            start: (0, _moment.default)(absence.get('startDate')),
            end: (0, _moment.default)(absence.get('endDate')).add(1, 'day'),
            halfDay: absence.get('halfDay'),
            id: absence.get('id'),
            comment: absence.get('comment'),
            resourceId: absence.get('user.id'),
            resourceEditable: false,
            editable: false,
            rendering: isPublicHoliday ? 'background' : undefined,
            allDay: isPublicHoliday ? true : undefined
          };
        });
        var absenceRequests = this.get('absenceRequests').filterBy('isActive', true).filter(function (request) {
          return !_this2.get('teamId') || request.get('sender.teamAssignment.team.id') === _this2.get('teamId');
        }).map(function (absence) {
          return {
            title: absence.get('sender.firstname') ? absence.get('sender.label') + ' (' + i18n.t('request') + ')' : '(' + i18n.t('request') + ')',
            type: absence.get('absenceType'),
            start: (0, _moment.default)(absence.get('startDate')),
            end: (0, _moment.default)(absence.get('endDate')).add(1, 'day'),
            halfDay: absence.get('halfDay'),
            id: absence.get('id'),
            urlParams: "user=".concat(absence.get('sender.id')) + "&startDate=".concat((0, _moment.default)(absence.get('startDate')).format('YYYY-MM-DD')) + "&endDate=".concat((0, _moment.default)(absence.get('endDate')).format('YYYY-MM-DD')) + "&rangeType=".concat(absence.get('halfDay') ? _environment.default.constants.RANGE_TYPE_ONE_DAY_HALF : _environment.default.constants.RANGE_TYPE_MULTIPLE_DAYS_FULL),
            comment: absence.get('senderComment'),
            resourceId: absence.get('sender.id'),
            resourceEditable: false,
            editable: false,
            options: {
              isRequest: true
            }
          };
        });
        var holidays = this.get('userHolidays').map(function (holiday) {
          return {
            title: holiday.get('name'),
            type: holiday.get('type'),
            start: (0, _moment.default)(holiday.get('date')),
            end: (0, _moment.default)(holiday.get('date')),
            id: holiday.get('id')
          };
        });
        return absences.concat(holidays).concat(absenceRequests);
      }
    }),
    requestOnly: Ember.computed('currentUser', 'permissions.canAccessUsers', 'permissions.canManageTeamAbsences', {
      get: function get() {
        if (this.get('currentUser.isAdmin')) return false;
        if (this.get('permissions.canAccessUsers')) return false;
        if (this.get('permissions.canManageTeamAbsences')) return false;
        return true;
      }
    }),
    readOnly: Ember.computed.or('requestOnly', 'hasAbsenceIntegration'),
    activeUsers: Ember.computed.reads('application.activeUsers'),
    usersVisibleInPlanner: Ember.computed('showHiddenUsersInAbsences', 'activeUsers', function () {
      var _this3 = this;

      var users = this.get('activeUsers');
      return users.filter(function (user) {
        return user.get('showInAbsencePlanner') || _this3.get('showHiddenUsersInAbsences');
      });
    }),
    userSortKeys: _environment.default.constants.USER_SORT_KEYS_WITH_EMPLOYMENT,
    usersSorted: Ember.computed.sort('usersVisibleInPlanner', 'userSortKeys'),
    usersFiltered: Ember.computed.alias('usersSorted'),
    // users are filtered in scheduler
    userResources: Ember.computed('usersFiltered', 'calendarEvents', 'filterKey', {
      get: function get() {
        var _this4 = this;

        var usersFiltered = this.get('usersFiltered');

        var userTag = function userTag(user) {
          var i18n = _this4.get('i18n');

          if (user.get('isFreelancer')) {
            return i18n.t('freelancerShort');
          }

          return null;
        };

        var resources = usersFiltered.map(function (user) {
          return {
            id: user.get('id'),
            user: user,
            userTag: userTag(user)
          };
        });
        return resources;
      }
    }),
    actions: {
      onDayClick: function onDayClick(day, userId) {
        var userIdToUse = userId ? userId : this.get('currentUser.currentUser.id');

        var dayFormatted = _moment.default.utc(day).format(_environment.default.constants.DATE_FORMAT_DAY);

        if (this.get('permissions.canManageTeamAbsences') && !this.get('permissions.canAccessUsers')) {
          var userObj = this.get('store').peekRecord('user', userIdToUse);

          if (userObj.get('team.id') !== this.get('currentUser.currentUser.team.id')) {
            this.get('notifications').info('cannotCreateAbsenceTeam');
            return;
          }
        }

        if (this.get('canCreateRequest')) {
          this.get('router').transitionTo(this.get('currentRoute') + '.new', {
            queryParams: {
              absenceStartDate: dayFormatted,
              user: userIdToUse
            }
          });
        }
      },
      toggleShowHiddenUsersInAbsences: function toggleShowHiddenUsersInAbsences() {
        var showHiddenUsersInAbsences = !this.get('showHiddenUsersInAbsences');
        this.get('currentUser').saveUISettings({
          showHiddenUsersInAbsences: showHiddenUsersInAbsences
        });
      },
      onEventClick: function onEventClick(event) {
        if (event.options && event.options.isRequest) {
          this.get('router').transitionTo(this.get('currentRoute') + '.confirm', event.id);
          return;
        }

        if (event.type !== 'holiday') {
          this.get('router').transitionTo(this.get('currentRoute') + '.edit', event.id, {
            queryParams: {
              user: event.resourceId
            }
          });
        } else {
          this.send('onDayClick', event.start);
        }
      },
      onEventEdit: function onEventEdit(event) {
        var _this5 = this;

        if (event.options && event.options.isRequest) {
          return;
        } else {
          this.store.findRecord('absence', event.id).then(function (updatedAbsence) {
            updatedAbsence.set('startDate', event.start);
            updatedAbsence.set('endDate', (0, _moment.default)(event.end).subtract(1, 'day'));
            updatedAbsence.save().then(function () {
              _this5.get('notifications').success('alert.saved', 'absence');
            });
          });
        }
      },
      absenceCreated: function absenceCreated(data) {
        var entry = data.entry;
        var id = !Ember.isEmpty(data.id) ? data.id : entry.get('id');
        var absences = this.get('absences');

        if (!absences.findBy('id', id)) {
          absences.pushObject(entry);
        }
      },
      absenceDeleted: function absenceDeleted(data) {
        var entry = data.entry;
        this.get('absences').removeObject(entry);
      },
      absenceUpdated: function absenceUpdated(data) {
        var entry = data.entry;
        var absences = this.get('absences');
        absences.removeObject(entry);
        absences.pushObject(entry);
      },
      absenceRequestCreated: function absenceRequestCreated(data) {
        var entry = data.entry;
        var id = !Ember.isEmpty(data.id) ? data.id : entry.get('id');
        var absences = this.get('absenceRequests');

        if (!this.get('absencesController.absenceRequests').findBy('id', id)) {
          this.get('absencesController.absenceRequests').pushObject(entry);
        }

        if (!absences.findBy('id', id)) {
          absences.pushObject(entry);
        }
      },
      absenceRequestUpdated: function absenceRequestUpdated(data) {
        var entry = data.entry;
        var absences = this.get('absenceRequests');

        if (absences) {
          absences.removeObject(entry);

          if (!entry.get('state')) {
            absences.pushObject(entry);
          }
        }
      },
      absenceRequestDeleted: function absenceRequestDeleted(data) {
        var entry = data.entry;
        this.get('absenceRequests').removeObject(entry);
      },
      toggleCalendarOrScheduler: function toggleCalendarOrScheduler() {
        this.set('hideCalendar', !this.get('hideCalendar'));

        if (this.get('hideCalendar')) {
          this.set('month', _moment.default.utc(this.get('month')).startOf('year').format('YYYY'));
        }
      },
      showToday: function showToday() {
        var event = Ember.Object.create({
          startDate: this.get('clock.now')
        });
        this.set('clickedRequest', event);
      },
      handleTeamChange: function handleTeamChange(newTeamSelection) {
        this.absenceController.set('teamId', newTeamSelection ? newTeamSelection.get('id') : null);
      },
      toggleExportModal: function toggleExportModal() {
        this.set('showExportModal', !this.get('showExportModal'));
      }
    }
  });

  _exports.default = _default;
});