define("coffeecup/components/viewport-trigger", ["exports", "ember-in-viewport"], function (_exports, _emberInViewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberInViewport.default, {
    didEnterViewport: function didEnterViewport() {
      this.sendAction('onEnter');
    },
    didExitViewport: function didExitViewport() {
      this.sendAction('onExit');
    }
  });

  _exports.default = _default;
});