define("coffeecup/components/modals/edit-time-entry", ["exports", "coffeecup/validations/time-entry", "coffeecup/config/environment", "coffeecup/utils/format-duration", "coffeecup/mixins/buffered-form", "coffeecup/mixins/project-budget"], function (_exports, _timeEntry, _environment, _formatDuration, _bufferedForm, _projectBudget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend(_bufferedForm.default, _projectBudget.default, {
    showDebug: false,
    permissions: Ember.inject.service(),
    timeEntryService: Ember.inject.service('time-entry'),
    projectService: Ember.inject.service('project'),
    store: Ember.inject.service(),
    clock: Ember.inject.service(),
    currentUserService: Ember.inject.service('currentUser'),
    accountService: Ember.inject.service('account'),
    accountSettings: Ember.computed.alias('accountService.accountSettings'),
    formValidation: _timeEntry.default,
    i18n: Ember.inject.service(),
    currentUser: null,
    timerModeIsDuration: true,
    preventCloseOnEscape: false,

    /**
     * time-entry
     */
    model: null,
    user: Ember.computed.alias('model.user'),
    project: Ember.computed.alias('form.project'),

    /**
     * boolean whether the modal should be opened
     */
    openModal: false,

    /**
     * boolean whether the modal should be closed
     */
    closeModal: false,

    /**
     * boolean, true when the form is being submitted
     */
    submitting: false,

    /**
     * determines which field should be focused
     */
    focus: null,
    allowBreaks: true,
    minimumSteps: 0.25,
    stepsOfRoundedDuration: Ember.computed('form.projectRoundingAmount', {
      get: function get() {
        var projectRoundingAmount = this.get('form.project.roundingAmount') || 0;
        var minimumSteps = this.get('minimumSteps');
        return Math.max(minimumSteps, projectRoundingAmount / 60);
      }
    }),
    commentProxy: Ember.computed('form.comment', {
      get: function get() {
        return this.get('form.comment');
      },
      set: function set(key, value) {
        var _this = this;

        Ember.run.next(this, function () {
          _this.set('form.comment', value);
        });
        return value;
      }
    }),
    hourlyRate: Ember.computed('form.project', 'form.project.hourlyRate', 'form.project.billBy', 'form.project.budgetBy', 'form.task', 'form.project.taskAssignments.@each.hourlyRate', 'form.project.taskAssignments.@each.billable', 'model.user', 'form.project.userAssignments.@each.hourlyRate', 'form.project.userAssignments.@each.billable', 'model.finalHourlyRate', 'isFormEnabled', {
      get: function get() {
        var userId = this.get('model.user.id');
        var isFormEnabled = this.get('isFormEnabled');
        var projectHourlyRate = this.get('form.project.hourlyRate');
        var billBy = this.get('form.project.billBy');
        var taskId = this.get('form.task.id');
        var userAssignments = this.get('form.project.userAssignments');
        var taskAssignments = this.get('form.project.taskAssignments');
        var taskAssignment = taskAssignments && typeof taskAssignments.findBy !== 'undefined' && taskAssignments.findBy('task.id', taskId);

        if (!isFormEnabled) {
          return this.get('model.finalHourlyRate');
        }

        if (taskId && taskAssignment && !taskAssignment.get('billable')) {
          return false;
        }

        switch (billBy) {
          // no hourly rate
          case _environment.default.constants.BILL_BY_NONE:
            return 0;
          // fixed hourly rate for every user and task

          case _environment.default.constants.BILL_BY_PROJECT:
            if (projectHourlyRate) {
              return projectHourlyRate;
            }

            break;
          // by task aka. taskAssignment

          case _environment.default.constants.BILL_BY_TASK:
            if (taskId) {
              if (taskAssignment) {
                if (!taskAssignment.get('billable')) {
                  return false;
                }

                return taskAssignment.get('hourlyRate') * taskAssignment.get('billable');
              }
            }

            break;
          // by user aka. userAssignment

          case _environment.default.constants.BILL_BY_USER:
            var userAssignment = userAssignments.findBy('user.id', userId);

            if (userAssignment) {
              return userAssignment.get('hourlyRate');
            }

            break;
        }
      }
    }),
    projectRoundingTypeLabel: Ember.computed('form.project.roundingType', {
      get: function get() {
        var roundingType = this.get('form.project.roundingType');
        var i18n = this.get('i18n');

        switch (roundingType) {
          case _environment.default.constants.ROUNDING_TYPE_UP:
            return i18n.t('roundUp');

          case _environment.default.constants.ROUNDING_TYPE_DOWN:
            return i18n.t('roundDown');
        }
      }
    }),
    _updateSelectedTask: Ember.observer('tasksFiltered', 'form.project', function () {
      var tasks = this.get('tasksFiltered');
      var currentTask = this.get('form.task');
      var taskExists = tasks && typeof tasks.some === 'function' && tasks.some(function (task) {
        return task && currentTask && task.get('id') === currentTask.get('id');
      });

      if (!taskExists) {
        this.set('form.task', tasks.get('firstObject'));
      }
    }),

    /**
     * boolean, true when concurrent time entries should be allowed
     * TODO: this should not be something this component should be concerned of
     */
    allowConcurrentTimeEntries: true,
    showModal: false,
    transitionTo: null,
    showForm: Ember.computed('usableProject', 'form.isNew', {
      get: function get() {
        return this.get('usableProject').length > 0 || !this.get('form.isNew');
      }
    }),
    timeEntryRunningForPmOrAdmin: Ember.computed('form.running', 'currentUserIsOwnerOfTimeEntry', 'clock.day', function () {
      var timeEntryFromYesterday = this.get('clock.day').isAfter(moment(this.get('form.day')), 'day');
      return !this.get('currentUserIsOwnerOfTimeEntry') && this.get('form.running') && !(timeEntryFromYesterday && this.get('form.duration') > _environment.default.constants.ALLOW_TIMEENTRIES_TO_BE_STOPPED_AFTER);
    }),
    isNotValidated: Ember.computed.not('lockedByValidation'),
    lockedByValidation: Ember.computed('form.validation.isApproved', 'form.user', 'permissions.worktimeValidationActive', function () {
      return this.get('form.validation.isApproved') && this.get('form.user.supervisor.id') && this.get('permissions.worktimeValidationActive') && !this.get('currentUserIsPmOfProject');
    }),
    isFormDisabled: Ember.computed('form.isEditDisabled', 'timeEntryRunningForPmOrAdmin', 'lockedByValidation', 'currentUserIsPmOfProject', function () {
      return this.get('form.isEditDisabled') || this.get('timeEntryRunningForPmOrAdmin') || this.get('lockedByValidation');
    }),
    isFormEnabled: Ember.computed.not('isFormDisabled'),
    showSaveButtons: Ember.computed.or('isFormEnabled', 'isBillableInformationEnabled'),
    currentUserIsPmOfProject: Ember.computed('currentUser.id', 'form.project', {
      get: function get() {
        var project = this.get('form.project');
        var currentUser = this.get('currentUser');
        return this.get('projectService').userIsAdminOrPmOfProject(project, currentUser);
      }
    }),
    currentUserIsOwnerOfTimeEntry: Ember.computed('currentUser.id', 'model.user.id', {
      get: function get() {
        return this.get('currentUser.id') === this.get('model.user.id');
      }
    }),
    canEditDuration: Ember.computed.and('currentUserIsOwnerOfTimeEntry', 'isNotValidated'),
    isDurationEnabled: Ember.computed.and('isFormEnabled', 'canEditDuration'),
    isDurationDisabled: Ember.computed.not('isDurationEnabled'),
    canDeleteTimeEntry: Ember.computed('isDurationEnabled', 'currentUserIsPmOfProject', 'form.duration', {
      get: function get() {
        var isDurationEnabled = this.get('isDurationEnabled');
        var currentUserIsPmOfProject = this.get('currentUserIsPmOfProject');
        var duration = this.get('form.duration');
        return isDurationEnabled || currentUserIsPmOfProject && !duration;
      }
    }),
    canEditDurationRounded: Ember.computed('currentUserIsPmOfProject', 'hourlyRate', function () {
      return this.get('currentUserIsPmOfProject') && this.get('hourlyRate') !== false;
    }),
    isBillableInformationEnabled: Ember.computed.and('form.isEditEnabled', 'currentUserIsPmOfProject'),
    isBillableInformationDisabled: Ember.computed.not('isBillableInformationEnabled'),
    isCommentEnabled: Ember.computed('form.isEditEnabled', 'currentUserIsPmOfProject', 'lockedByValidation', function () {
      if (this.get('currentUserIsPmOfProject') && this.get('form.isEditEnabled')) return true;
      return this.get('form.isEditEnabled') && !this.get('lockedByValidation');
    }),
    isCommentDisabled: Ember.computed.not('isCommentEnabled'),
    projectIsBillable: Ember.computed.not('form.project.isNotBillable'),
    showBillingDetails: Ember.computed.and('currentUserIsPmOfProject', 'projectIsBillable'),
    durationRounded: Ember.computed('canEditDurationRounded', 'userHasChangedTheDurationRounded', 'form.durationAsHours', 'form.project.roundingType', 'form.project.roundingAmount', {
      get: function get() {
        var canEditDurationRounded = this.get('canEditDurationRounded');

        if (canEditDurationRounded) {
          var durationRounded = this.get('form.durationRounded');
          var durationRoundedOverride = this.get('model.durationRoundedOverride');
          var duration = Math.round(this.get('form.durationAsHours') * 3600);
          var userHasChangedTheDurationRounded = this.get('userHasChangedTheDurationRounded');
          var projectRoundingType = this.get('form.project.roundingType');
          var projectRoundingAmount = this.get('form.project.roundingAmount');
          var value = null; // if userHasChangedTheDurationRounded has changed

          if (userHasChangedTheDurationRounded) {
            value = typeof durationRounded === 'number' ? durationRounded : durationRoundedOverride;
          } else {
            value = (0, _formatDuration.getRoundedDuration)(duration, projectRoundingAmount, projectRoundingType);
          }

          return value;
        }
      }
    }),
    durationRoundedAsHours: Ember.computed('canEditDurationRounded', 'durationRounded', {
      get: function get() {
        var canEditDurationRounded = this.get('canEditDurationRounded');

        if (canEditDurationRounded) {
          return this.get('durationRounded') / 3600 || 0;
        } else {
          return 0;
        }
      },
      set: function set(key, value) {
        return this.applyDurationRounded(value);
      }
    }),
    userHasChangedTheDurationRounded: Ember.computed.reads('model.userHasChangedTheDurationRounded'),
    amount: Ember.computed('hourlyRate', 'durationRoundedAsHours', {
      get: function get() {
        var hourlyRate = this.get('hourlyRate');
        var durationRounded = this.get('durationRoundedAsHours');
        return hourlyRate * durationRounded;
      }
    }),
    subtrahendAmount: Ember.computed('amount', 'model.amount', function () {
      var hourlyRate = this.get('hourlyRate');
      if (hourlyRate === 0) return 0;
      var amount = this.get('amount');
      var previousAmount = this.get('model.amount') || 0;
      return amount - previousAmount;
    }),
    subtrahendHours: Ember.computed('form.durationAsHours', 'model.durationAsHours', function () {
      var durationAsHours = this.get('form.durationAsHours');
      var previousDurationAsHourss = this.get('model.durationAsHours') || 0;
      return durationAsHours - previousDurationAsHourss;
    }),

    /**
     * sets the duration based on start and endtime
     * works also if the endtime is in the next day
     */
    _setDuration: Ember.observer('form.startTimeDecimal', 'form.endTimeDecimal', 'canEditDuration', function () {
      if (this.get('timerModeIsDuration')) {
        return;
      }

      if (!this.get('canEditDuration')) {
        return;
      }

      var startTimeDecimal = this.get('form.startTimeDecimal');
      var endTimeDecimal = this.get('form.endTimeDecimal');

      if (!Ember.isEmpty(startTimeDecimal) && !Ember.isEmpty(endTimeDecimal)) {
        var startTimeAsSeconds = Math.floor(Math.round(parseFloat(startTimeDecimal) * 3600) / 60) * 60;
        var endTimeAsSeconds = Math.floor(Math.round(parseFloat(endTimeDecimal) * 3600) / 60) * 60;
        var diff = (endTimeAsSeconds < startTimeAsSeconds ? 86400 : 0) + endTimeAsSeconds - startTimeAsSeconds;
        this.set('form.durationAsHours', diff / 3600);
      }

      if (Ember.isEmpty(endTimeDecimal) && !Ember.isEmpty(startTimeDecimal)) {
        this.set('form.durationAsHours', null);
      }
    }).on('init'),

    /**
     * unsets the startTime for new te's if the user is not the owner of the entry
     */
    _setStartTime: Ember.observer('canEditDuration', 'model.isNew', function () {
      if (this.get('timerModeIsDuration')) {
        return;
      }

      if (!this.get('canEditDuration') && this.get('model.isNew')) {
        this.set('form.startTimeDecimal', 0);
        return;
      }
    }).on('init'),

    /**
     * sets the endTime based on start and duration
     */
    _setEndTime: Ember.observer('form.durationAsHours', 'canEditDuration', function () {
      if (this.get('timerModeIsDuration')) {
        return;
      }

      var startTimeDecimal = this.get('form.startTimeDecimal');
      var durationAsHours = this.get('form.durationAsHours');

      if (!Ember.isEmpty(startTimeDecimal) && !Ember.isEmpty(durationAsHours)) {
        var startTimeAsSeconds = Math.floor(Math.round(parseFloat(startTimeDecimal) * 3600) / 60) * 60;
        var durationAsSeconds = Math.floor(Math.round(parseFloat(durationAsHours) * 3600) / 60) * 60;
        var endTimeAsSeconds = startTimeAsSeconds + durationAsSeconds;

        if (endTimeAsSeconds >= 86400) {
          endTimeAsSeconds = endTimeAsSeconds - 86400;
        }

        this.set('form.endTimeDecimal', endTimeAsSeconds / 3600);
      }

      if (Ember.isEmpty(durationAsHours)) {
        this.set('form.endTimeDecimal', null);
      }
    }).on('init'),
    projectsFiltered: Ember.computed.mapBy('user.activeUserAssignments', 'project'),
    onlyActiveProjects: Ember.computed.filterBy('projectsFiltered', 'isActive', true),
    onlyTrackableProjects: Ember.computed.filterBy('onlyActiveProjects', 'projectState.isTrackable', true),
    isSameUser: Ember.computed('user.id', 'currentUserService.currentUser.id', function () {
      return this.user.get('id') === this.currentUserService.currentUser.get('id');
    }),
    isAdminOrSuperPm: Ember.computed.or('currentUserService.isAdmin', 'currentUserService.currentUser.role.canManageAllProjects'),
    canUseUsersProjects: Ember.computed.or('isSameUser', 'isAdminOrSuperPm'),
    usableProject: Ember.computed('onlyTrackableProjects.[]', function () {
      if (this.canUseUsersProjects) {
        return this.onlyTrackableProjects;
      }

      var currentUserProjects = this.currentUserService.currentUser.userAssignments.filter(function (el) {
        return el.isProjectManager;
      }).map(function (el) {
        return el.project;
      });
      return this.onlyTrackableProjects.filter(function (el) {
        return currentUserProjects.some(function (projectOfCurrentUser) {
          return projectOfCurrentUser.get('id') === el.get('id');
        });
      });
    }),
    canStopCurrentTimeEntry: Ember.computed('clock.day', 'model.dayFmt', 'model.user', 'currentUser', 'model.duration', 'model.running', function () {
      var timeEntryFromYesterday = this.get('clock.day').isAfter(moment(this.get('model.day')), 'day');
      return this.get('model.running') && (this.get('model.user.id') === this.get('currentUser.id') || timeEntryFromYesterday && this.get('model.duration') > _environment.default.constants.ALLOW_TIMEENTRIES_TO_BE_STOPPED_AFTER);
    }),

    /**
     * Sorts the projects by clients
     * @return sorted array of project
     */
    projectsSorted: Ember.computed.sort('usableProject', 'projectsSorting'),
    projectsSorting: ['client.label'],
    recentProjectTaskCombinations: [],
    _updateRecentProjectTaskCombinations: function _updateRecentProjectTaskCombinations() {
      var _this2 = this;

      var RECENT_ENTRIES_TO_SHOW = 4; // Need to fetch more than just the ones we want to show due to de-duplicating

      var recentEntriesToFetch = RECENT_ENTRIES_TO_SHOW * 2;
      this.get('timeEntryService').findLatestTimeEntryForUser(false, false, recentEntriesToFetch).then(function (timeEntries) {
        var uniqueTimeEntries = [];
        timeEntries.filter(function (entry) {
          var isAssignedToProject = _this2.get('timeEntryService').isTimeEntryAssignedToUserProjects(entry);

          return isAssignedToProject && !entry.get('project.isInactive');
        }).forEach(function (filterElement) {
          if (!uniqueTimeEntries.any(function (matchingElement) {
            return matchingElement.get('id') !== filterElement.get('id') && matchingElement.get('project.id') === filterElement.get('project.id') && matchingElement.get('task.id') === filterElement.get('task.id');
          })) {
            uniqueTimeEntries.push(filterElement);
          }
        });
        var recentCombinations = [];
        uniqueTimeEntries.slice(0, RECENT_ENTRIES_TO_SHOW).forEach(function (timeEntry) {
          var project = timeEntry.get('project');
          var client = project.get('client');
          var task = timeEntry.get('task'); // This could cause one-by-one network requests if not pre-fetched

          var hasReference = !!timeEntry.belongsTo('timeEntryReference').id();
          var reference = timeEntry.get('timeEntryReference');
          var taskContent = hasReference ? Ember.String.htmlSafe("".concat(reference.get('platformImageHTML')).concat(reference.get('shortCodeAndPageTitle'))) : task.get('label');
          recentCombinations.push(Ember.Object.create({
            fullNameWithHiddenCodeAndClientHTML: Ember.String.htmlSafe("\n                <div class=\"recent-combination\">\n                  <div class=\"combination-label\">\n                    <div class=\"combination-client text-overflow\">".concat(client.get('label') || _this2.get('i18n').t('internalProject'), "</div>\n                    <div class=\"combination-project text-overflow\">").concat(project.get('label'), "</div>\n                    <div class=\"combination-task-reference text-overflow\">").concat(taskContent, "</div>\n                  </div>\n                </div>")),
            id: project.get('id'),
            taskId: task.get('id'),
            timeEntryReferenceId: reference.get('id'),
            label: "".concat(project.get('label'), " // ").concat(task.get('label'))
          }));
        });

        if (!_this2.get('isDestroyed')) {
          _this2.set('recentProjectTaskCombinations', recentCombinations);
        }
      });
    },

    /**
     * Groups the project by client and builds a select-2 compatible representation of the json
     * @return hierarchical array for select-2
     */
    projectsGrouped: Ember.computed('usableProject.[]', 'recentProjectTaskCombinations.[]', function () {
      var i18n = this.get('i18n');
      var projectsGrouped = this.get('projectService').projectsGroupedByClient(this.get('usableProject'));
      var recentCombinations = this.get('recentProjectTaskCombinations');
      if (recentCombinations.length === 0 || !this.isSameUser) return projectsGrouped;
      return [Ember.Object.create({
        groupName: i18n.t('recentCombinations').toString(),
        options: recentCombinations
      })].concat(_toConsumableArray(projectsGrouped));
    }),

    /**
     * filters the available tasks based on project and its taskassignments
     */
    tasksFiltered: Ember.computed.mapBy('form.project.activeTaskAssignments', 'task'),

    /**
     * Sorts the tasks by name
     * @return sorted array of project
     */
    tasksSorted: Ember.computed.sort('tasksFiltered', 'tasksSorting'),
    tasksSorting: ['label'],
    timeEntryReferencesFiltered: Ember.computed('form.project', {
      get: function get() {
        return this.form.project.get('projectReferencePlatforms').filter(function (projectReferencePlatform) {
          return projectReferencePlatform.get('isActive') && projectReferencePlatform.get('enabled');
        }).reduce(function (timeEntries, projectReferencePlatform) {
          return [].concat(_toConsumableArray(timeEntries), _toConsumableArray(projectReferencePlatform.get('timeEntryReferences').filter(function (el) {
            return el.isActive;
          }).toArray()));
        }, []);
      }
    }),
    timeEntryReferencesSorted: Ember.computed.sort('timeEntryReferencesFiltered', 'timeEntryReferencesSorting'),
    timeEntryReferencesSorting: ['shortCode'],
    isTimeEntryReferencesDisabled: Ember.computed('timeEntryReferencesFiltered', {
      get: function get() {
        return this.isFormDisabled || !this.timeEntryReferencesFiltered.length && !this.form.timeEntryReference;
      }
    }),
    timeEntryReferencesPlaceholder: Ember.computed('isTimeEntryReferencesDisabled', {
      get: function get() {
        return this.isTimeEntryReferencesDisabled ? this.i18n.t('noReferences') : this.i18n.t('chooseReference');
      }
    }),
    formIsValid: Ember.computed('canEditDuration', 'timerModeIsDuration', 'isToday', 'model.isNew', 'form.task', 'form.project', 'form.startTimeDecimal', 'form.startTimeDecimal', 'form.durationAsHours', {
      get: function get() {
        var canEditDuration = this.get('canEditDuration');
        var formIsValid = this.get('form.project.id') && this.get('form.task.id') || this.get('form.isBreak');
        var modelIsNew = this.get('model.isNew');
        var timerModeIsDuration = this.get('timerModeIsDuration');
        var duration = this.get('form.durationAsHours');
        var startTimeDecimal = this.get('form.startTimeDecimal');
        var endTimeDecimal = this.get('form.endTimeDecimal');
        var isToday = this.get('isToday');

        if (timerModeIsDuration) {
          if (modelIsNew && isToday) {
            return formIsValid;
          } else if (canEditDuration) {
            return formIsValid && !Ember.isEmpty(duration);
          } else {
            return formIsValid;
          }
        } else {
          if (modelIsNew && isToday && Ember.isEmpty(endTimeDecimal)) {
            var startTimeIsNowOrEarlier = !Ember.isEmpty(startTimeDecimal) ? (0, _formatDuration.decimalHoursisNowOrEarlierToday)(startTimeDecimal) : false;
            return formIsValid && startTimeIsNowOrEarlier;
          } else if (canEditDuration) {
            return formIsValid && !Ember.isEmpty(duration) && !Ember.isEmpty(startTimeDecimal);
          } else {
            return formIsValid;
          }
        }
      }
    }),

    /**
     * disables the submit button when the form is not valid, it's currently being submitted or when the user is not assigned to the selected project anymore
     * @return boolean
     */
    submitIsDisabled: Ember.computed('submitting', 'formIsValid', 'form.project', 'user.id', {
      get: function get() {
        var submitting = this.get('submitting');
        var isValid = this.get('formIsValid');
        var isBreak = this.get('form.isBreak');
        if (!submitting && isValid && isBreak) return false; // We are checking against the user bound to the form (not the logged user)

        var isUserAssignedToSelectedProject = this.get('form.project.userAssignments') && !!this.get('form.project.userAssignments').findBy('user.id', this.get('user.id'));
        return submitting || !isValid || !isUserAssignedToSelectedProject;
      }
    }),

    /**
     * @return boolean true if start button should be displayed instead of save button
     */
    showStartButton: Ember.computed('form.durationAsHours', 'isToday', 'canEditDuration', 'timerModeIsDuration', 'form.startTimeDecimal', 'form.endTimeDecimal', {
      get: function get() {
        var isToday = this.get('isToday');
        var canEditDuration = this.get('canEditDuration');
        var timerModeIsDuration = this.get('timerModeIsDuration');

        if (!isToday || !canEditDuration) {
          return false;
        }

        if (timerModeIsDuration) {
          var duration = parseFloat(this.get('form.durationAsHours') || 0);
          return duration === 0;
        } else {
          var startTimeDecimal = this.get('form.startTimeDecimal');
          var endTimeDecimal = this.get('form.endTimeDecimal');
          var startTimeIsNow = startTimeDecimal ? (0, _formatDuration.decimalHoursisNowOrEarlierToday)(startTimeDecimal) : false;
          return startTimeIsNow && Ember.isEmpty(endTimeDecimal);
        }
      }
    }),
    disableStartAndSaveButton: Ember.computed('isToday', 'canEditDuration', {
      get: function get() {
        var spentAtIsNotToday = !this.get('isToday');
        var canEditDuration = this.get('canEditDuration');
        var timerModeIsNotDuration = !this.get('timerModeIsDuration');
        return timerModeIsNotDuration || spentAtIsNotToday || !canEditDuration;
      }
    }),
    disableStartAndStopButton: Ember.computed('form.running', 'canEditDuration', {
      get: function get() {
        var running = this.get('form.running');
        var canEditDuration = this.get('canEditDuration');
        return !running || !canEditDuration;
      }
    }),
    saveAndKeepRunningIsDisabled: Ember.computed('isToday', 'form.running', 'canEditDuration', 'timerModeIsDuration', 'form.endTimeDecimal', {
      get: function get() {
        var isToday = this.get('isToday');
        var running = this.get('form.running');
        var canEditDuration = this.get('canEditDuration');
        var endTimeDecimal = this.get('form.endTimeDecimal');
        var timerModeIsNotDuration = !this.get('timerModeIsDuration');

        if (!isToday || !running || !canEditDuration) {
          return true;
        }

        if (timerModeIsNotDuration && !(0, _formatDuration.decimalHoursIsNow)(endTimeDecimal)) {
          return true;
        }

        return false;
      }
    }),

    /**
     * @return boolean true if the alt text should be hidden
     */
    hideAltHintToStartAndSave: Ember.computed('form.running', 'form.durationAsHours', 'isToday', 'canEditDuration', 'timerModeIsDuration', {
      get: function get() {
        var running = this.get('form.running');
        var duration = parseFloat(this.get('form.durationAsHours'));
        var spentAtIsNotToday = !this.get('isToday');
        var canEditDuration = this.get('canEditDuration');
        var timerModeIsNotDuration = !this.get('timerModeIsDuration');
        return !canEditDuration || running || duration === 0 || spentAtIsNotToday || timerModeIsNotDuration;
      }
    }),

    /**
     * @return {Boolean} true if day is today
     */
    isToday: Ember.computed('form.dayFmt', 'clock.day', {
      get: function get() {
        var day = this.get('form.day');
        return this.get('clock.day').isSame(day, 'day');
      }
    }),
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);

      this._updateRecentProjectTaskCombinations();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!this.get('form.isEditDisabled')) {
        if (this.get('form.isNew')) {
          this.set('focus', 'project');
        } else {
          this.set('focus', 'comment');
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },
    applyDurationRounded: function applyDurationRounded(value) {
      value = Ember.isEmpty(value) ? this.get('durationRoundedAsHours') : value;
      var canEditDurationRounded = this.get('canEditDurationRounded');

      if (canEditDurationRounded) {
        var newDurationRounded = parseFloat(value) * 3600;
        var duration = this.get('form.durationAsHours') * 3600;
        var projectRoundingType = this.get('form.project.roundingType');
        var projectRoundingAmount = this.get('form.project.roundingAmount');
        var durationRounded = (0, _formatDuration.getRoundedDuration)(duration, projectRoundingAmount, projectRoundingType);
        newDurationRounded = (0, _formatDuration.getRoundedDuration)(newDurationRounded, projectRoundingAmount, projectRoundingType);
        this.set('userHasChangedTheDurationRounded', durationRounded != newDurationRounded);
        this.set('form.durationRounded', newDurationRounded);
        return newDurationRounded / 3600;
      }

      return value;
    },
    applyComputedValues: function applyComputedValues() {
      this.applyDurationRounded();
    },
    dayProxy: Ember.computed('form.day.content', {
      get: function get() {
        var form = this.get('form');
        return Ember.get(form, 'day.content') || Ember.get(form, 'day');
      },
      set: function set(key, value) {
        var form = this.get('form');
        Ember.run.next(this, function () {
          form.set('day', value);
        });
        return value;
      }
    }),
    actions: {
      updateComment: function updateComment(val) {
        var comment = jQuery.trim(this.get('form.comment'));

        if (comment) {
          comment = comment + '\n' + val;
        } else {
          comment = jQuery.trim(val);
        }

        this.set('form.comment', comment);
        this.set('focus', 'comment');
      },
      onHide: function onHide() {
        var _this3 = this;

        // Navigate away from route
        if (this.get('transitionTo')) {
          this.sendAction('transitionTo');
        } // needs to run with a timeout due to a potential race condition that could occur
        // when onHide is being triggered before saveTimeEntry and its validate are done
        // => execute would be called AFTER rollback => ERROR


        Ember.run.later(function () {
          var form = _this3.get('form');

          if (form) {
            form.rollback();
          }

          if (!_this3.get('isDestroyed')) {
            _this3.set('submitting', false);
          }
        }, 1000);
      },
      deleteTimeEntry: function deleteTimeEntry() {
        var _this4 = this;

        var model = this.get('model');
        model.destroyRecord().then(function () {
          if (_this4.get('afterDelete')) {
            _this4.sendAction('afterDelete', model);
          }

          _this4.get('notifications').success('alert.deleted', 'timeEntry'); // Navigate away from route


          if (_this4.get('transitionTo')) {
            _this4.sendAction('transitionTo');
          }
        }).catch(function (err) {
          _this4.get('notifications').errorWithObject(err, 'alert.errorOnDelete', 'timeEntry');
        });
      },
      saveOrStart: function saveOrStart(modal, event) {
        this.send('saveTimeEntry', modal, event, {
          saveAndStart: this.get('showStartButton')
        });
      },
      saveAndStart: function saveAndStart(modal, event) {
        this.send('saveTimeEntry', modal, event, {
          saveAndStart: true
        });
      },
      saveAndStop: function saveAndStop(modal, event) {
        this.send('saveTimeEntry', modal, event, {
          saveAndStop: true
        });
      },
      saveAndKeepRunning: function saveAndKeepRunning(modal, event) {
        this.send('saveTimeEntry', modal, event, {
          saveAndKeepRunning: true
        });
      },
      resetDurationRounded: function resetDurationRounded(updateValueAction) {
        this.set('durationRoundedAsHours', this.get('form.durationAsHours'));
        updateValueAction(this.applyDurationRounded(this.get('form.durationAsHours')));
      },
      setTask: function setTask(taskId) {
        var task = this.get('store').peekRecord('task', taskId);
        this.set('form.task', task);
      },
      setTimeEntryReference: function setTimeEntryReference(timeEntryReferenceId) {
        var timeEntryReference = this.get('store').peekRecord('timeEntryReference', timeEntryReferenceId);
        this.set('form.timeEntryReference', timeEntryReference);
      },
      saveTimeEntry: function saveTimeEntry(modal, event, opts) {
        var _this5 = this;

        opts = opts || {};

        if (!this.get('submitting')) {
          this.set('submitting', true);
          this.get('form').validate().then(function () {
            if (_this5.get('form.isValid')) {
              if (_this5.get('userHasChangedTheDurationRounded') && _this5.get('form.isNew')) {
                _this5.set('form.durationRoundedOverride', _this5.get('form.durationRounded'));
              }

              _this5.get('form').execute();

              var model = _this5.get('model');

              if (!model.belongsTo('team').id()) {
                model.set('team', model.get('user.team'));
              }

              var isNew = model.get('isNew');

              _this5.applyComputedValues();

              _this5.get('timeEntryService').beforeSave(model).then(function () {
                var savePromise;

                if (opts.saveAndStart) {
                  savePromise = _this5.get('timeEntryService').startTimeEntry(model);
                } else if (opts.saveAndStop) {
                  if (_this5.get('timerModeIsDuration')) {
                    savePromise = _this5.get('timeEntryService').stopTimeEntry(model);
                  } else {
                    savePromise = _this5.get('timeEntryService').stopTimeEntry(model, {
                      dontSetEndTime: true
                    });
                  }
                } else if (opts.saveAndKeepRunning) {
                  savePromise = _this5.get('timeEntryService').saveTimeEntryAndKeepRunning(model);
                } else {
                  savePromise = model.save();
                }

                return savePromise.then(function () {
                  if (model.get('running')) {
                    model._reloadRunningTimeEntry();
                  }

                  if (isNew && _this5.get('afterSave') && model) {
                    // add item to filtered records,
                    // @see http://stackoverflow.com/questions/26488733/no-template-update-after-createrecord-with-findquery-fetching
                    _this5.sendAction('afterSave', model);
                  }

                  if (opts.saveAndStart) {
                    _this5.get('notifications').success('alert.started', 'timeEntry');
                  } else if (opts.saveAndStop) {
                    _this5.get('notifications').success('alert.stopped', 'timeEntry');
                  } else {
                    _this5.get('notifications').success('alert.saved', 'timeEntry');
                  }

                  if (!_this5.get('isDestroyed')) {
                    try {
                      _this5.set('closeModal', true); // Navigate away from route


                      if (_this5.get('transitionTo')) {
                        _this5.sendAction('transitionTo');
                      }
                    } catch (e) {
                      console.error(e);
                    }
                  }
                }).catch(function (err) {
                  _this5.set('submitting', false);

                  if (!_this5.get('form.isNew')) {
                    _this5.get('form').rollback();

                    model.rollbackAttributes();
                  }

                  if (err && err.errors && err.errors.length > 0) {
                    var errorObj = err.errors[0] && err.errors[0].detail && JSON.parse(err.errors[0].detail);

                    if (errorObj && errorObj.raw && errorObj.raw === _environment.default.constants.ERRORS.MOVED_TO_VALIDATED_WEEK) {
                      if (_this5.get('afterSave') && !isNew) {
                        _this5.sendAction('afterSave', model);
                      }

                      return _this5.get('notifications').error('cantMoveTimeEntryToValidatedWeek');
                    }
                  }

                  _this5.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'timeEntry');
                });
              });
            } else {
              _this5.set('submitting', false);

              _this5.get('notifications').info('pleaseCheckYourInputs');
            }
          }).catch(function (err) {
            _this5.set('submitting', false);

            _this5.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'timeEntry');
          });
        }
      },
      updateFormProject: function updateFormProject(projectCombination) {
        if (this.get('form.isEditEnabled')) {
          // Project combination can be a single project, recent combination, or just an id (from mobile dropdown)
          var project = _typeof(projectCombination) === 'object' ? this.get('store').peekRecord('project', projectCombination.get('id')) : this.get('store').peekRecord('project', projectCombination);

          if (project.get('id')) {
            var _projectCombination$g, _projectCombination$g2;

            this.set('form.project', project);
            var isRecentCombination = (_projectCombination$g = projectCombination.get) === null || _projectCombination$g === void 0 ? void 0 : _projectCombination$g.call(projectCombination, 'taskId');
            var isRecentCombinationWithoutReference = isRecentCombination && projectCombination.get('timeEntryReferenceId') === undefined;

            if ((_projectCombination$g2 = projectCombination.get) === null || _projectCombination$g2 === void 0 ? void 0 : _projectCombination$g2.call(projectCombination, 'timeEntryReferenceId')) {
              this.set('form.timeEntryReference', this.get('store').peekRecord('timeEntryReference', projectCombination.get('timeEntryReferenceId')));
            } else if (isRecentCombinationWithoutReference || !this.timeEntryReferencesFiltered.includes(this.form.timeEntryReference)) {
              this.set('form.timeEntryReference', null);
            }

            if (isRecentCombination) {
              this.set('form.task', this.get('store').peekRecord('task', projectCombination.get('taskId')));
              this.set('focus', 'comment');
            } else {
              this.set('focus', 'task');
            }
          }
        }
      },
      setFocusComment: function setFocusComment() {
        this.set('focus', 'comment');
      },
      togglePreventClose: function togglePreventClose() {
        var _this6 = this;

        // We need a runloop next here to allow the check in modal dialog for open dropdowns
        Ember.run.next(function () {
          _this6.toggleProperty('preventCloseOnEscape');
        });
      }
    }
  });

  _exports.default = _default;
});