define("coffeecup/controllers/modal/alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * boolean whether the modal should be opened
     */
    showModal: false,

    /**
     * Custom modal name for custom CSS
     */
    modalName: null,

    /**
     * set of actions
     */
    actions: {
      onHide: function onHide() {
        this.set('showModal', false);
      }
    }
  });

  _exports.default = _default;
});