define("coffeecup/components/planner/project-user-info", ["exports", "coffeecup/components/planner/user-project-info"], function (_exports, _userProjectInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userProjectInfo.default.extend();

  _exports.default = _default;
});