define("coffeecup/helpers/each-time-entries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.eachTimeEntires = eachTimeEntires;
  _exports.default = void 0;

  function eachTimeEntires(parameters) {
    return Ember.String.htmlSafe(parameters[0].map(function (result) {
      return '<tr>' + '<td>' + result.get('project.client.label') + '</td>' + '<td>' + result.get('project.label') + '</td>' + '</tr>';
    }).join(''));
  }

  var _default = Ember.Helper.helper(eachTimeEntires);

  _exports.default = _default;
});