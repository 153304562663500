define("coffeecup/components/analytics-module/project/billing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    project: null,
    analytics: null,
    pieChartData: [],
    amountSpentTotal: Ember.computed.reads('analytics.amountSpentTotal'),
    billedAmount: Ember.computed.reads('analytics.amountBilledTotal'),
    billableTotal: Ember.computed.reads('analytics.amountNotBilledTotal'),
    billableTimeEntries: Ember.computed.reads('analytics.amountNotBilled'),
    billableExpenses: Ember.computed.reads('analytics.expensesNotBilled'),
    isOverBilled: Ember.computed.reads('analytics.isOverBilled'),
    amountNotBilledTotalInPercent: Ember.computed.reads('analytics.amountNotBilledTotalInPercent'),
    didInsertElement: function didInsertElement() {
      this._super();

      this._updateUi();
    },
    _updateUi: Ember.observer('billableTotal', function () {
      var billableTotal = this.get('billableTotal');
      var amountNotBilledTotalInPercent = this.get('amountNotBilledTotalInPercent');
      this.set('pieChartData', [{
        value: 0
      }, {
        value: 1
      }]);
    })
  });

  _exports.default = _default;
});