define("coffeecup/components/manage/projects/user-assignment-list-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UserAssignmentListView = Ember.Component.extend({
    tagName: 'div',
    classNames: ['flex-row', 'assignment-table-row'],
    enableBudget: null,
    enableHourlyRate: null,
    hideHourlyRate: null,
    projectHourlyRate: null,
    disableBudget: Ember.computed.not('enableBudget'),
    disableHourlyRate: Ember.computed.not('enableHourlyRate'),
    projectHasManyPms: false,
    projectHasOnePm: false,
    canNotBeEdited: Ember.computed.or('userAssignment.isReadOnly', 'projectHasOnePm'),
    canNotBeEditedAndIsPm: Ember.computed.and('canNotBeEdited', 'userAssignment.isProjectManager'),
    canBeEdited: Ember.computed.not('canNotBeEdited'),
    tooltipContent: Ember.computed('canNotBeEditedAndIsPm', function () {
      if (this.get('canNotBeEditedAndIsPm')) {
        return this.get('i18n').t('OnePmIsRequired');
      }
    }),
    actions: {
      onEnter: function onEnter() {
        this.sendAction('onEnter', this.get('userAssignment'));
      },
      onBlur: function onBlur() {
        this.sendAction('onBlur', this.get('userAssignment'));
      },
      onEscape: function onEscape() {
        this.sendAction('onEscape', this.get('userAssignment'));
      },
      removeUserAssignment: function removeUserAssignment() {
        this.sendAction('removeUserAssignment', this.get('userAssignment'));
      }
    }
  });
  var _default = UserAssignmentListView;
  _exports.default = _default;
});