define("coffeecup/controllers/account/holidays", ["exports", "coffeecup/config/environment", "coffeecup/utils/build-url"], function (_exports, _environment, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _ENV$constants = _environment.default.constants,
      REGION_NATIONAL = _ENV$constants.REGION_NATIONAL,
      COUNTRY_GERMANY = _ENV$constants.COUNTRY_GERMANY;

  var _default = Ember.Controller.extend({
    /* INJECTIONS */
    application: Ember.inject.controller(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    publicHolidayService: Ember.inject.service('public-holiday'),
    permissions: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    absenceTypeService: Ember.inject.service('absence-type'),

    /* PROPERTIES */
    regions: null,
    initialUserPublicHoliday: false,
    selectedRegion: null,
    selectedCountry: null,
    publicHolidayRegions: Ember.computed.alias('publicHolidayService.regions'),
    isUpdatingHolidaySettings: false,
    useUserPublicHolidays: Ember.computed.alias('accountSettings.adminUiSettings.useUserPublicHolidays'),
    useUserPublicHolidaysProxy: Ember.computed.alias('accountSettings.adminUiSettings.useUserPublicHolidays'),
    currentDefaultPublicHolidayRegionId: Ember.computed.alias('accountSettings.adminUiSettings.defaultUserPublicHolidayRegion'),
    hasAbsenceIntegration: Ember.computed.alias('permissions.absenceIntegrationActive'),
    shouldSaveAvailableHolidays: false,
    selectedHolidays: [],
    alreadySelectedHolidays: [],
    availableAbsenceTypes: Ember.computed.reads('application.activeAbsenceTypes'),
    absenceTypesFiltered: Ember.computed.filter('availableAbsenceTypes', function (type) {
      if (type.get('isNew')) {
        return false;
      }

      if (this.get('hasAbsenceIntegration') && !type.get('externalId')) {
        return false;
      }

      return true;
    }),
    absenceTypesSorted: Ember.computed('absenceTypesFiltered', function () {
      return this.get('absenceTypeService').getSortedAbsenceTypes(this.get('absenceTypesFiltered'));
    }),
    selectedRegionObj: Ember.computed('regions', 'selectedRegion', function () {
      var _this = this;

      var regions = this.get('regions');

      if (!regions) {
        return null;
      }

      return regions.find(function (x) {
        return x.get('label') === _this.get('selectedRegion');
      });
    }),
    selectedAbsenceTypes: [],
    alreadySelectedAbsenceTypes: [],

    /* COMPUTED PROPERTIES */
    showRegionSelector: Ember.computed.equal('selectedCountry', COUNTRY_GERMANY),
    accountSettings: Ember.computed.alias('accountService.accountSettings'),
    availableHolidays: Ember.computed('selectedRegion', 'regions', 'selectedCountry', 'countries', function () {
      var selectedRegion = this.get('showRegionSelector') ? this.get('selectedRegion') : REGION_NATIONAL;
      return this.store.query('holiday', {
        sort: 'date ASC',
        where: {
          date: {
            '>=': this.get('clock.year').format(_environment.default.constants.DATE_FORMAT_DAY),
            '<=': this.get('clock.year').endOf('Year').format(_environment.default.constants.DATE_FORMAT_DAY)
          },
          region: selectedRegion,
          country: this.get('selectedCountry')
        }
      });
    }),

    /* OBSERVER*/
    setAllSelectedHolidays: Ember.observer('alreadySelectedHolidays.isFulfilled', function () {
      var _this2 = this;

      if (this.get('alreadySelectedHolidays.isFulfilled') && this.get('availableHolidays.isFulfilled')) {
        var alreadySelectedHolidays = this.get('alreadySelectedHolidays').slice();
        var availableHolidays = this.get('availableHolidays').slice();
        var holidaysToSelect = [];

        if (alreadySelectedHolidays.length > 0) {
          alreadySelectedHolidays.forEach(function (selectedHoliday) {
            availableHolidays.forEach(function (availableHoliday) {
              if (availableHoliday.get('name') === selectedHoliday.get('name')) {
                holidaysToSelect.push(availableHoliday);
              }
            });
          });
        } else {
          holidaysToSelect = availableHolidays;
        }

        this.set('selectedHolidays', holidaysToSelect.slice());

        if (this.get('shouldSaveAvailableHolidays')) {
          Ember.run.later(function () {
            _this2.send('save');
          }, 50);
          this.set('shouldSaveAvailableHolidays', false);
        }
      }
    }),
    getAlreadySelectedHolidays: Ember.observer('availableHolidays.isFulfilled', function () {
      var currentYearStart = this.get('clock.day');
      var currentYearEnd = this.get('clock.day').endOf('Day').add(1, 'years');
      var userHolidays = this.store.query('user-holiday', {
        sort: 'date ASC',
        limit: 1000,
        where: {
          date: {
            '>=': currentYearStart.format(_environment.default.constants.DATE_FORMAT_DAY),
            '<=': currentYearEnd.format(_environment.default.constants.DATE_FORMAT_DAY)
          }
        }
      });
      this.set('alreadySelectedHolidays', userHolidays);
    }),
    selectedPreviewPublicHolidayRegionId: null,
    previewPublicHolidays: [],

    /* ACTIONS */
    actions: {
      save: function save() {
        var _this3 = this;

        Ember.run.next(function () {
          var selectedHolidayNames = _this3.get('selectedHolidays').getEach('name');

          var selectedRegion = _this3.get('showRegionSelector') ? _this3.get('selectedRegion') : REGION_NATIONAL;
          var url = (0, _buildUrl.default)(_this3.store, 'userHolidays/setAccountHolidays');
          var body = {
            startDate: _this3.get('clock.year').format(_environment.default.constants.DATE_FORMAT_YYYYMMDD_DASH),
            country: _this3.get('selectedCountry'),
            region: selectedRegion,
            holidays: selectedHolidayNames
          };
          return _this3.get('session').postJSON(url, body).then(function () {
            _this3.get('notifications').success('alert.saved', 'userHoliday');

            var accountSettings = _this3.get('accountSettings');

            var settings = accountSettings.get('adminUiSettings') || {};
            settings.region = selectedRegion;
            settings.country = _this3.get('selectedCountry');
            accountSettings.set('adminUiSettings', settings);
            accountSettings.save().then(function () {
              _this3.send('reset');
            });
          }).catch(function (err) {
            _this3.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'userHoliday');
          });
        });
      },
      saveRegionSelection: function saveRegionSelection() {
        this.set('shouldSaveAvailableHolidays', true);
      },
      addAbsenceType: function addAbsenceType() {
        var i18n = this.get('i18n');
        var newAbsenceType = this.get('store').createRecord('absence-type', {
          label: i18n.t('newAbsenceType'),
          color: Math.floor(Math.random() * _environment.default.constants.DEFAULT_COLORS.length) + 1,
          icon: Math.floor(Math.random() * _environment.default.constants.DEFAULT_ICONS.length) + 1
        });
        newAbsenceType.save();
      },
      onUseUserPublicHolidaysChange: function onUseUserPublicHolidaysChange() {
        var _this4 = this;

        this.set('isUpdatingHolidaySettings', true);
        var accountSettings = this.get('accountSettings');
        accountSettings.set('adminUiSettings', _objectSpread({}, accountSettings.get('adminUiSettings')));
        accountSettings.save().then(function () {
          _this4.get('notifications').success('alert.saved', 'accountSetting');

          if (accountSettings.get('adminUiSettings.useUserPublicHolidays') === false) {
            _this4.getAlreadySelectedHolidays();
          }
        }).catch(function (e) {
          console.log(e);

          _this4.get('notifications').error('alert.errorOnSave', 'accountSettings');
        }).finally(function () {
          _this4.set('isUpdatingHolidaySettings', false);
        });
      },
      onSelectDefaultPublicHolidayRegion: function onSelectDefaultPublicHolidayRegion(region) {
        var _this5 = this;

        var accountSettings = this.get('accountSettings');
        var adminUiSettings = this.accountSettings.get('adminUiSettings');
        this.accountSettings.set('adminUiSettings', _objectSpread(_objectSpread({}, adminUiSettings), {}, {
          defaultUserPublicHolidayRegion: region
        }));
        this.accountSettings.save().then(function () {
          _this5.get('notifications').success('alert.saved', 'accountSettings');
        }).catch(function () {
          _this5.accountSettings.rollbackAttributes();

          _this5.get('notifications').error('alert.errorOnSave', 'accountSettings');
        });
      },
      onSelectUpdatePublicHolidayRegion: function onSelectUpdatePublicHolidayRegion(region) {
        this.set('selectedUpdatePublicHolidayRegionId', region);
      },
      submitUpdatePublicHolidayRegion: function submitUpdatePublicHolidayRegion() {
        var _this6 = this;

        this.set('updatingUserPublicHolidays', true);
        this.publicHolidayService.updateDefaultUserPublicHolidays(this.get('selectedUpdatePublicHolidayRegionId')).finally(function () {
          _this6.set('updatingUserPublicHolidays', false);
        });
      },
      onSelectPreviewPublicHolidayRegion: function onSelectPreviewPublicHolidayRegion(region) {
        var _this7 = this;

        this.set('selectedPreviewPublicHolidayRegionId', region);
        this.publicHolidayService.fetchPublicHolidays(region).then(function (holidays) {
          _this7.set('previewPublicHolidays', holidays);
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});