define("coffeecup/components/cc-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'cc-card',
    classNameBindings: ['isHoveredAndPrimary:primary-border-color', 'isHoveredAndPrimary:active', 'smallPadding:small-padding', 'isSelectedAndPrimary:primary-border-color', 'isSelectedAndPrimary:active', 'isFlexibleHeight:flex-height', 'hasLargeGap:large-gap', 'isLeftAligned:left-aligned'],
    attributeBindings: ['style'],
    style: Ember.computed('hasBarHeader', 'isHovered', 'content.color', 'isSelected', function () {
      if (this.get('hasBarHeader') && (this.get('isHovered') || this.get('isSelected'))) {
        return Ember.String.htmlSafe("border-color: ".concat(this.get('content.color'), ";"));
      } else if (this.get('hasBarHeader') && !this.get('isSelected')) {
        return Ember.String.htmlSafe("opacity: 0.6;");
      }
    }),
    isHovered: false,
    hasLargeGap: false,
    hideIcon: false,
    fixedHeight: true,
    hasIcon: false,
    skipTranslation: false,
    hasBarHeader: false,
    showPrimaryBorderColor: true,
    smallPadding: false,
    isLeftAligned: false,
    isHoveredAndPrimary: Ember.computed.and('showPrimaryBorderColor', 'isHovered'),
    isSelectedAndPrimary: Ember.computed.and('showPrimaryBorderColor', 'isSelected'),
    isFlexibleHeight: Ember.computed.not('fixedHeight'),
    iconBackground: Ember.computed('hasBarHeader', 'hasIcon', function () {
      if (this.get('hasIcon') && !this.get('hasBarHeader')) {
        return this.get('content.color');
      }

      return 'transparent';
    }),
    barBackground: Ember.computed('hasBarHeader', 'hasIcon', function () {
      if (this.get('hasIcon') && this.get('hasBarHeader')) {
        return this.get('content.color');
      }

      return 'transparent';
    }),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.element.removeEventListener('mouseenter', Ember.run.bind(this, this.handleMouseEnter));
      this.element.removeEventListener('mouseleave', Ember.run.bind(this, this.handleMouseLeave));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.element.addEventListener('mouseenter', Ember.run.bind(this, this.handleMouseEnter));
      this.element.addEventListener('mouseleave', Ember.run.bind(this, this.handleMouseLeave));
    },
    handleMouseEnter: function handleMouseEnter() {
      this.set('isHovered', true);
    },
    handleMouseLeave: function handleMouseLeave() {
      this.set('isHovered', false);
    },
    click: function click() {
      this.onClick(this.get('content'));
    }
  });

  _exports.default = _default;
});