define("coffeecup/components/cc-kpi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['cc-kpi'],
    classNameBindings: ['hasDivider:divider-right', 'hideDividerOnMobile:hide-divider', 'isExpandable:expandable', 'large:large']
  });

  _exports.default = _default;
});