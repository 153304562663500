define("coffeecup/routes/account/billing", ["exports", "coffeecup/routes/_super-form-route"], function (_exports, _superFormRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superFormRoute.default.extend({
    accountService: Ember.inject.service('account'),
    titleToken: function titleToken() {
      return this.get('i18n').t('billingSettings');
    },
    setupController: function setupController(controller, context, transition) {
      var accountSettings = controller.get('accountSettings');

      if (!accountSettings.get('firstAccountContact')) {
        var accountContact = this.store.createRecord('account-contact');
        accountContact.set('accountSettings', accountSettings);
        controller.set('modelContact', accountContact);
      } else {
        controller.set('modelContact', accountSettings.get('firstAccountContact'));
      }

      controller.set('model', this.get('accountService.accountSettings'));
      controller.set('timezones', this.store.peekAll('timezone')); // fixtures

      controller.set('languages', this.store.peekAll('language')); // fixtures

      controller.set('beginningOfWeeks', this.store.peekAll('beginning-of-week')); // fixtures

      controller.set('dateFormats', this.store.peekAll('date-format')); // fixtures

      controller.set('currencies', this.store.peekAll('currency')); // fixtures

      controller.set('wordingOptions', this.store.peekAll('wording-option')); // fixture

      controller.set('currencyFormats', this.store.peekAll('currency-format')).toArray(); // fixtures

      controller.set('numberFormats', this.store.peekAll('number-format')); // fixtures

      controller.set('timeRoundingTypes', this.store.peekAll('time-rounding-type')); // fixtures

      controller.set('countries', this.store.peekAll('country')); // fixtures

      controller.set('invoices', this.store.peekAll('billing/invoice'));
      controller.set('permissions.stripeSubscriptions', context.stripeSubscriptions);
      controller.set('stripePlans', context.stripePlans);
      controller.set('stripeInvoices', context.stripeInvoices);
      controller.set('stripeCustomer', context.stripeCustomer.get('firstObject'));
      controller.set('existingTags', this.store.peekAll('tag'));
      controller.set('existingTagsObjects', this.store.peekAll('tag').toArray());
      controller.set('users', this.store.peekAll('user'));
    },
    model: function model() {
      return Ember.RSVP.hash({
        stripeSubscriptions: this.store.findAll('stripe/subscription'),
        stripePlans: this.store.findAll('stripe/plan'),
        stripeCustomer: this.store.findAll('stripe/customer'),
        stripeInvoices: this.store.findAll('stripe/invoice')
      });
    }
  });

  _exports.default = _default;
});