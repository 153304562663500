define("coffeecup/components/date-picker", ["exports", "coffeecup/utils/date", "coffeecup/config/environment"], function (_exports, _date, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend((_EmberComponent$exte = {
    classNames: [],
    attributeBindings: ['date'],
    utcMoment: false,
    disabled: false,
    openPickerForDisabled: false,
    opens: 'center',
    drops: 'down',
    minDate: null
  }, _defineProperty(_EmberComponent$exte, "minDate", null), _defineProperty(_EmberComponent$exte, "options", function options() {
    var isMinDateValidAndAfterToday = (0, _date.isDateValid)(this.get('minDate')) && this.get('minDate').isAfter(this.get('clock.now'));
    var todayOrMinDate = isMinDateValidAndAfterToday ? this.get('minDate') : this.get('clock.now');
    var date = moment(this.get('date'));
    var startDate = (0, _date.isDateValid)(date) ? date : todayOrMinDate;
    var options = {
      parentEl: '#date-picker-container',
      format: _environment.default.constants.DATE_PICKER_FORMAT,
      singleDatePicker: true,
      showDropdowns: true,
      showWeekNumbers: true,
      drops: this.get('drops'),
      opens: this.get('opens'),
      startDate: startDate
    };
    if ((0, _date.isDateValid)(this.get('minDate'))) options.minDate = this.get('minDate');
    if ((0, _date.isDateValid)(this.get('maxDate'))) options.maxDate = this.get('maxDate');
    return options;
  }), _defineProperty(_EmberComponent$exte, "btnClass", null), _defineProperty(_EmberComponent$exte, "dateFMT", Ember.computed('date', {
    get: function get() {
      if ((0, _date.isDateValid)(moment(this.get('date')))) {
        return moment(this.get('date')).format('LL');
      }

      return '';
    }
  })), _defineProperty(_EmberComponent$exte, "drpObj", null), _defineProperty(_EmberComponent$exte, "updateDateObserver", Ember.observer('date', function () {
    if (this.$('.date-picker') && this.$('.date-picker').data('daterangepicker')) {
      var date = moment(this.get('date'));
      this.$('.date-picker').data('daterangepicker').setStartDate((0, _date.isDateValid)(date) ? date : moment());
      this.$('.date-picker').data('daterangepicker').setEndDate((0, _date.isDateValid)(date) ? date : moment());
    }
  })), _defineProperty(_EmberComponent$exte, "updateDatePicker", Ember.observer('minDate', 'disabled', function () {
    var _this = this;

    if (!this.get('disabled') || this.get('openPickerForDisabled')) {
      var _$input = this.$('.date-picker').daterangepicker(this.options(), function (start, end, label) {
        var date = start.format(_environment.default.constants.DATE_PICKER_FORMAT);

        if (_this.get('utcMoment')) {
          _this.set('date', moment.utc(date));
        } else {
          _this.set('date', moment(date));
        }
      }); // save a reference to the date picker-obj


      this.set('drpObj', _$input.data('daterangepicker'));
    }
  })), _defineProperty(_EmberComponent$exte, "didInsertElement", function didInsertElement() {
    this.updateDatePicker();
    this.$('.input-group-addon').click(function () {
      Ember.run.scheduleOnce('actions', this, function () {
        $input.trigger('focus');
      });
    });
  }), _defineProperty(_EmberComponent$exte, "willDestroy", function willDestroy() {
    if (this.get('drpObj')) {
      this.get('drpObj').remove();
    }
  }), _defineProperty(_EmberComponent$exte, "actions", {}), _EmberComponent$exte));

  _exports.default = _default;
});