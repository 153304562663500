define("coffeecup/components/validation/time-entry-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    accountService: Ember.inject.service('account'),
    accountSettings: Ember.computed.reads('accountService.accountSettings'),
    classNames: ['validation-time-entry', 'flex-row', 'clickable'],
    timeEntry: null,
    accuracyOfTimeEntry: Ember.computed('timeEntry.id', 'accuracies.[]', function () {
      if (this.get('accuracies')) {
        return this.get('accuracies').findBy('timeEntry', this.get('timeEntry.id'));
      } else {
        return null;
      }
    }),
    click: function click(e) {
      // Disabled forwarding of the event if an anchor tag was clicked.
      if (e.target.tagName === 'A') {
        return;
      }

      this.sendAction('onClick', this.get('timeEntry'));
    }
  });

  _exports.default = _default;
});