define("coffeecup/controllers/account/billing", ["exports", "ember-changeset", "ember-changeset-validations", "coffeecup/validations/account-setting", "coffeecup/config/environment", "coffeecup/services/stripe"], function (_exports, _emberChangeset, _emberChangesetValidations, _accountSetting, _environment, _stripe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend({
    /* INJECTIONS */
    application: Ember.inject.controller(),
    accountService: Ember.inject.service('account'),
    formatter: Ember.inject.service(),
    permissions: Ember.inject.service(),

    /* PROPERTIES */
    focus: 'companyName',
    users: null,
    currencies: null,
    timezones: null,
    beginningOfWeeks: null,
    languages: null,
    dateFormats: null,
    openContactModal: false,
    openPaymentModal: false,
    openSubscriptionModal: false,
    openSeatsModal: false,
    timerModeDuration: _environment.default.constants.TIMER_MODE_DURATION,
    timerModeDurationStartTimeEndTime: _environment.default.constants.TIMER_MODE_START_TIME_END_TIME,
    timeRoundingTypes: null,
    wordingOptions: null,

    /**
     * duration formats
     */
    durationFormatHM: _environment.default.constants.DURATION_FORMAT_HM,
    durationFormatDecimal: _environment.default.constants.DURATION_FORMAT_DECIMAL,

    /**
     * time formats
     */
    timeFormatIndex12: _environment.default.constants.TIME_FORMAT_12,
    timeFormatIndex24: _environment.default.constants.TIME_FORMAT_24,

    /* COMPUTED PROPERTIES */
    accountSettings: Ember.computed.alias('accountService.accountSettings'),
    currentUser: Ember.computed.oneWay('application.currentUser'),
    allInvoices: Ember.computed('invoices.[]', 'stripeInvoices.[]', function () {
      var invoices = this.get('invoices');
      var stripeInvoices = this.get('stripeInvoices');
      var allowedStripeStatuses = ['paid', 'open']; // should use the Enum once this is a typescript file

      var filteredStripeInvoices = (stripeInvoices ? stripeInvoices.toArray() : []).filter(function (invoice) {
        return allowedStripeStatuses.includes(invoice.status) && !invoice.isHidden;
      });
      return [].concat(_toConsumableArray(invoices ? invoices.toArray() : []), _toConsumableArray(filteredStripeInvoices));
    }),
    invoiceSortIds: ['date:desc'],
    allInvoicesSorted: Ember.computed.sort('allInvoices', 'invoiceSortIds'),
    contactInfoIsValid: Ember.computed('modelContact.isValid', function () {
      return this.get('modelContact.isValid') && this.get('modelContact.street');
    }),
    paymentTypeFormatted: Ember.computed('stripeCustomer.paymentType', function () {
      var paymentType = this.get('stripeCustomer.paymentType');

      if (paymentType === _stripe.PaymentType.CreditCard) {
        return this.get('i18n').t('creditCard');
      } else if (paymentType === _stripe.PaymentType.SEPA) {
        return this.get('i18n').t('sepa');
      } else {
        return this.get('i18n').t('none');
      }
    }),

    /**
     * Proxy value for the currency object on the form. Changing the ID finds and sets the correct currency object.
     */
    currencyId: Ember.computed('accountSettings.currency', {
      set: function set(key, newCurrency) {
        var currencies = this.get('currencies');
        var currency = currencies.filterBy('id', newCurrency).get('firstObject');
        this.set('accountSettings.currency', currency);
        return newCurrency;
      },
      get: function get() {
        return this.get('accountSettings.currency.id');
      }
    }),
    stripeSubscription: Ember.computed.oneWay('permissions.stripeSubscription'),
    activeUserCount: Ember.computed.oneWay('application.enabledUsers.length'),

    /**
     * Next Subscription section -- Most of this data is not avaialbe due to CC-3557
     */
    nextPeriodName: Ember.computed.alias('stripeSubscription.plan.displayName'),
    // TODO-P - Need to handle case where subscription is not renewed
    nextPeriodStart: Ember.computed.alias('stripeSubscription.currentPeriodEnd'),
    nextPeriodUsers: Ember.computed.alias('stripeSubscription.quantity'),
    // TODO-P - Properly calculate the next period's price - Blocked by CC-3557
    nextPeriodAmountFmt: Ember.computed.alias('stripeSubscription.amountFmt'),

    /**
     * a list of possible fiscal year starts (in month)
     */
    months: Ember.computed(function () {
      var monthList = [];
      var currentMonth;

      for (var i = 0; i < 12; i++) {
        currentMonth = {
          label: this.get('clock.now').month(i).format('MMMM'),
          id: i
        };
        monthList.push(currentMonth);
      }

      return monthList;
    }),
    errors: {},
    actions: {
      editContact: function editContact() {
        this.set('openContactModal', true);
      },
      hideContactModal: function hideContactModal() {
        this.set('openContactModal', false);
      },
      editPayment: function editPayment() {
        this.set('openPaymentModal', true);
      },
      hidePaymentModal: function hidePaymentModal() {
        this.set('openPaymentModal', false);
      },
      editSubscription: function editSubscription() {
        this.set('openSubscriptionModal', true);
      },
      doneEditingSubscription: function doneEditingSubscription() {
        this.set('openSubscriptionModal', false);
      },
      editSeats: function editSeats() {
        this.set('openSeatsModal', true);
      },
      hideSeatsModal: function hideSeatsModal() {
        this.set('openSeatsModal', false);
      },
      saveCompany: function saveCompany() {
        var _this = this;

        this.set('errors', {});
        Ember.run.next(function () {
          if (_this.get('model.hasDirtyAttributes') || _this.get('model.defaultNoticePeriod.isDirty') || _this.get('model').didChange('currency')) {
            var changeset = (0, _emberChangeset.Changeset)(_this.get('model'), (0, _emberChangesetValidations.default)(_accountSetting.default), _accountSetting.default);
            changeset.validate().then(function () {
              if (changeset.get('isValid')) {
                _this.get('model').save().then(function () {
                  _this.get('notifications').success('alert.saved', 'accountSetting');

                  _this.accountService.updateColorScheme(_this.model);
                }).catch(function (err) {
                  _this.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'accountSetting');
                });
              } else {
                _this.set('errors', changeset.error);

                _this.get('notifications').info('pleaseCheckYourInputs');
              }
            }).catch(function () {
              _this.get('notifications').info('pleaseCheckYourInputs');
            });
          }
        });
      },
      resetCompany: function resetCompany() {
        this.get('model').rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});