define("coffeecup/components/cc-ember-tooltip", ["exports", "ember-tooltips/components/ember-tooltip"], function (_exports, _emberTooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberTooltip.default.extend({
    popperOptions: {
      modifiers: {
        preventOverflow: {
          escapeWithReference: false
        }
      }
    },

    /* Copied/Overwritten from
     https://github.com/sir-dunxalot/ember-tooltips/blob/cfc0fec848b504d2660831db9aed6e1d5f58a1d7/addon/components/ember-tooltip-base.js
     but with keydown event removed
    */
    addTooltipTargetEventListeners: function addTooltipTargetEventListeners() {
      var _this = this;

      /* Setup event handling to hide and show the tooltip */
      var event = this.get('event');
      /* Setup event handling to hide and show the tooltip */

      if (event === 'none') {
        return;
      }

      var hideOn = this.get('hideOn');
      var showOn = this.get('showOn');
      /* If show and hide are the same (e.g. click) toggle
      the visibility */

      if (showOn === hideOn) {
        this._addEventListener(showOn, function () {
          _this.toggle();
        });
      } else {
        /* Else, add the show and hide events individually */
        if (showOn !== 'none') {
          this._addEventListener(showOn, function () {
            _this.show();
          });
        }

        if (hideOn !== 'none') {
          this._addEventListener(hideOn, function () {
            _this.hide();
          });
        }
      }
      /* Hide and show the tooltip on focus and escape
      for accessibility */


      if (event !== 'focus') {
        /* If the event is click, we don't want the
        click to also trigger focusin */
        if (event !== 'click') {
          this._addEventListener('focusin', function () {
            _this.show();
          });
        }

        this._addEventListener('focusout', function () {
          _this.hide();
        });
      }
    }
  });

  _exports.default = _default;
});