define("coffeecup/controllers/analytics/projects/billing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend({
    timeEntriesController: Ember.inject.controller('analytics.time-entries'),
    application: Ember.inject.controller(),
    router: Ember.inject.service(),
    openModal: false,
    allProjects: Ember.computed.reads('application.allProjects'),
    from: null,
    to: null,
    fixedBilledAmount: null,
    page: null,
    billTimeEntries: true,
    project: Ember.computed.reads('timeEntriesController.project'),
    setSelectedProjectIfPossible: Ember.observer('project', function () {
      var project = this.get('timeEntriesController.project');

      if (project && project.length && project.length === 1) {
        var selectedProject = this.store.peekRecord('project', project[0]);
        this.set('selectedProject', selectedProject);
      }
    }).on('init'),
    selectedProject: null,
    createdInvoice: null,
    showInvoiceSummary: false,
    actions: {
      handleBillingSuccess: function handleBillingSuccess() {
        var _this$router, _this$router$currentR, _this$router$currentR2;

        var timeEntryRouteAttributes = (_this$router = this.router) === null || _this$router === void 0 ? void 0 : (_this$router$currentR = _this$router.currentRoute) === null || _this$router$currentR === void 0 ? void 0 : (_this$router$currentR2 = _this$router$currentR.parent) === null || _this$router$currentR2 === void 0 ? void 0 : _this$router$currentR2.attributes;

        if ((timeEntryRouteAttributes === null || timeEntryRouteAttributes === void 0 ? void 0 : timeEntryRouteAttributes.timeEntriesQuery) && (timeEntryRouteAttributes === null || timeEntryRouteAttributes === void 0 ? void 0 : timeEntryRouteAttributes.analyticsQuery)) {
          this.store.query('time-entry', _objectSpread(_objectSpread({}, timeEntryRouteAttributes.timeEntriesQuery), {}, {
            where: JSON.parse(timeEntryRouteAttributes.timeEntriesQuery.where)
          }));
          this.store.query('analytics/time-entry', _objectSpread({}, timeEntryRouteAttributes.analyticsQuery));
        }
      },
      showTimeEntries: function showTimeEntries() {
        var _this$get;

        (_this$get = this.get('application')).send.apply(_this$get, ['showTimeEntries'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });

  _exports.default = _default;
});