define("coffeecup/components/planner/split-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    viewport: Ember.inject.service('planner.viewport'),
    classNames: ['split-overlay'],
    attributeBindings: ['style', 'title'],
    classNameBindings: ['isHidden:hidden', 'isActive:active'],
    assignmentEl: null,
    assignment: null,
    splitDate: null,
    left: 0,
    offset: 0,
    isActive: Ember.computed.bool('splitDate'),
    setLeftWhenActive: Ember.observer('splitDate', function () {
      if (this.get('isActive')) {
        // Ghost "splitDate"
        var daysBetween = this.get('viewport').numberOfDaysBetween(this.get('offset'), this.get('splitDate'));
        this.set('left', this.get('viewport.dayWidth') * daysBetween);
      }
    }).on('init'),
    currentDate: Ember.computed('offset', 'viewport.dayWidth', 'left', function () {
      // left: 500 , dayWidth = 50 => 10 day offset
      var dayWidth = this.get('viewport.dayWidth');
      var offsetDays = this.get('left') / dayWidth; // How many columns the split is relative to the start of the visible pill;

      var relativeDate = moment.max(this.get('offset'), this.get('viewport.startDate'));
      return this.get('viewport').shiftDate(relativeDate, offsetDays);
    }),
    isHidden: Ember.computed('isActive', 'currentDate', 'assignment.startDate', function () {
      var currentDate = this.get('currentDate');
      return !this.get('isActive') && (!currentDate || currentDate.isSame(this.get('assignment.startDate')) || currentDate.isAfter(this.get('assignment.endDate')));
    }),
    style: Ember.computed('left', function () {
      var overlayStyle = 'left: ' + this.get('left') + 'px;';
      return Ember.String.htmlSafe(overlayStyle);
    }),
    didInsertElement: function didInsertElement() {
      var assignmentElement = this.$().closest('.assignment-frame');
      this.set('assignmentEl', assignmentElement);
      assignmentElement.on('mouseenter.split-overlay mousemove.split-overlay', this.moveOverlay.bind(this));

      if (this.get('isActive')) {
        var viewport = this.get('viewport');
        Ember.run.next(viewport, 'selectDay', this.get('splitDate'));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.get('assignmentEl').off('.split-overlay');
    },
    moveOverlay: function moveOverlay(assignmentElement) {
      var distanceY = assignmentElement ? assignmentElement.pageX : this.get('left');
      this.translateMouseXToGridColumn(distanceY);
    },
    translateMouseXToGridColumn: function translateMouseXToGridColumn(distanceY) {
      Ember.run(this, function () {
        var viewportStartDate = this.get('viewport.startDate');
        var startDate = this.get('assignment.startDate');
        var dayWidth = this.get('viewport.dayWidth');
        var totalDistance = distanceY + dayWidth + (startDate.isBefore(viewportStartDate) ? this.get('viewport').numberOfDaysBetween(viewportStartDate, startDate) * this.get('viewport.dayWidth') : 0) - this.get('assignmentEl').offset().left;
        var distanceOfDay = (totalDistance + dayWidth / 2) % dayWidth;
        this.set('left', totalDistance - distanceOfDay - dayWidth / 2);
      });
    },
    click: function click(event) {
      event.stopPropagation();
      this.get('viewport').selectDay(this.get('currentDate'));
      this.sendAction('onClick', this.get('currentDate'));
    }
  });

  _exports.default = _default;
});