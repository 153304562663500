define("coffeecup/routes/account/company", ["exports", "coffeecup/routes/account/billing"], function (_exports, _billing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _billing.default.extend({
    titleToken: function titleToken() {
      return this.get('i18n').t('generalSettings');
    }
  });

  _exports.default = _default;
});