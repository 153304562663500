define("coffeecup/components/time-entries/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TimeEntriesHeader = Ember.Component.extend({
    classNames: ['table-fixed-cell', 'time-entry-day-header-item'],
    classNameBindings: ['todayCssClass', 'absenceTypeClass', 'isWorking::not-working'],
    attributeBindings: ['style'],
    screen: Ember.inject.service(),
    permissions: Ember.inject.service(),
    formatter: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    accountSettings: Ember.computed.reads('accountService.accountSettings'),
    hasMilestones: Ember.computed.gt('day.milestones.length', 0),
    milestoneGroup: Ember.computed('day.milestones.[]', function () {
      var _this$day, _this$formatter, _this$day2, _this$day2$milestones, _this$day3;

      var day = (_this$day = this.day) === null || _this$day === void 0 ? void 0 : _this$day.day;
      var dayFmt = (_this$formatter = this.formatter) === null || _this$formatter === void 0 ? void 0 : _this$formatter.formatDate(day);
      var count = (_this$day2 = this.day) === null || _this$day2 === void 0 ? void 0 : (_this$day2$milestones = _this$day2.milestones) === null || _this$day2$milestones === void 0 ? void 0 : _this$day2$milestones.length;
      var milestones = (_this$day3 = this.day) === null || _this$day3 === void 0 ? void 0 : _this$day3.milestones;
      return Ember.Object.create({
        day: day,
        dayFmt: dayFmt,
        count: count,
        milestones: milestones
      });
    }),
    day: null,
    user: null,
    expectedHours: Ember.computed.alias('day.expectedHours'),
    showSensitiveData: false,
    isBusy: Ember.computed.alias('day.isBusy'),
    secondsTotal: Ember.computed.alias('day.secondsTotal'),
    secondsSpent: Ember.computed.alias('day.secondsSpent'),
    amountSpent: Ember.computed.alias('day.amountSpent'),
    breakMissing: Ember.computed.alias('day.validation.breakMissing'),
    commentsMissing: Ember.computed.alias('day.validation.commentsMissing'),
    isValid: Ember.computed.alias('day.validation.isValid'),
    showValidation: Ember.computed.and('day.user.supervisor', 'day.validation.secondsToWork', 'permissions.worktimeValidationActive'),
    todayCssClass: Ember.computed.alias('relativeToToday'),
    isFuture: Ember.computed.alias('day.isFuture'),
    isToday: Ember.computed.alias('day.isToday'),
    relativeToToday: Ember.computed.alias('day.relativeToToday'),
    absenceTypeClass: Ember.computed('day.absenceForDay.absenceType.baseType', 'day.holidayForDay', {
      get: function get() {
        if (this.get('day.holidayForDay')) {
          return this.get('day.holidayForDay.type');
        } else if (this.get('day.absenceForDay')) {
          return 'absence-header';
        }
      }
    }),
    isWorking: Ember.computed.alias('day.isWorking'),
    dayIsValid: Ember.computed.and('isValid', 'dayCompleted'),
    validDayTooltip: Ember.computed('breakMissing', 'commentsMissing', 'dayCompleted', function () {
      var i18n = this.get('i18n');
      var baseString = '';

      if (this.get('accountSettings.activeHoursValidation')) {
        baseString += "<div><span class=\"icomoon-checkmark space-right\"></span>".concat(i18n.t('durationHoursValid'), "</div>");
      }

      if (this.get('accountSettings.activeCommentValidation') !== 0) {
        if (this.get('accountSettings.activeCommentValidation') === 1) {
          baseString += "<div><span class=\"icomoon-checkmark space-right\"></span>".concat(i18n.t('durationCommentsValid'), "</div>");
        } else if (this.get('accountSettings.activeCommentValidation') === 2) {
          baseString += "<div><span class=\"icomoon-checkmark space-right\"></span>".concat(i18n.t('durationCommentsOrReferencesValid'), "</div>");
        } else if (this.get('accountSettings.activeCommentValidation') === 3) {
          baseString += "<div><span class=\"icomoon-checkmark space-right\"></span>".concat(i18n.t('durationReferencesValid'), "</div>");
        } else if (this.get('accountSettings.activeCommentValidation') === 4) {
          baseString += "<div><span class=\"icomoon-checkmark space-right\"></span>".concat(i18n.t('durationCommentsAndReferencesValid'), "</div>");
        }
      }

      if (this.get('accountSettings.activeBreakValidation')) {
        baseString += "<div><span class=\"icomoon-checkmark space-right\"></span>".concat(i18n.t('durationBreakValid'), "</div>");
      }

      return Ember.String.htmlSafe(baseString);
    }),
    //==========================================
    // Computed Properties
    //==========================================
    style: Ember.computed('day.absenceForDay', 'day.holidayForDay', function () {
      var _this = this;

      if (this.get('day.absenceForDay') && !this.get('day.holidayForDay')) {
        var colorCode = this.get('day.absenceForDay.absenceType.colorCode');

        if (this.get('isToday')) {
          colorCode = this.get('day.absenceForDay.absenceType.darkerColorCode');
          Ember.run.next(function () {
            $('#' + _this.get('elementId')).find('.custom-icon').css('background-color', colorCode);
          });
        }

        return Ember.String.htmlSafe("background-color: ".concat(colorCode, ";"));
      }
    }),
    hasStrictWorkType: Ember.computed.readOnly('user.hasStrictWorkType'),
    hoursTotal: Ember.computed.alias('day.hoursTotal'),
    showProgress: Ember.computed('expectedHours', 'isFuture', {
      get: function get() {
        return !this.get('isFuture') && this.get('hasStrictWorkType') && this.get('expectedHours') > 0;
      }
    }),
    dayLabel: Ember.computed('day.day', {
      get: function get() {
        return this.get('day.day').format('dddd');
      }
    }),
    dayNumber: Ember.computed('day.day', {
      get: function get() {
        return this.get('day.day').format('Do');
      }
    }),
    percentCompleted: Ember.computed('hoursTotal', 'expectedHours', {
      get: function get() {
        if (this.get('expectedHours') > 0) {
          return this.get('hoursTotal') / this.get('expectedHours');
        } else {
          return 0;
        }
      }
    }),
    dayCompleted: Ember.computed('expectedHours', {
      get: function get() {
        return this.get('expectedHours') <= 0 || this.get('hoursTotal') >= this.get('expectedHours');
      }
    })
  });
  var _default = TimeEntriesHeader;
  _exports.default = _default;
});