define("coffeecup/controllers/time-entries/verifying/monthly", ["exports", "coffeecup/config/environment", "coffeecup/utils/build-url"], function (_exports, _environment, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberController$ext;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend((_EmberController$ext = {
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    validationService: Ember.inject.service('validation'),
    worktimeController: Ember.inject.controller('time-entries.verifying'),
    queryParams: ['month'],
    selectedFilterMode: Ember.computed.alias('worktimeController.validationFilter'),
    hasWeeks: true,
    isLoading: false,
    pageIsLoading: false,
    noValidationActive: false,
    accountService: Ember.inject.service('account'),
    accountSettings: Ember.computed.reads('accountService.accountSettings'),
    hasAnyValidationActive: Ember.computed.or('accountSettings.activeCommentValidation', 'accountSettings.activeBreakValidation', 'accountSettings.activeHoursValidation'),
    routeIsActive: Ember.computed('router.currentRouteName', function () {
      return this.get('router').isActive('time-entries.verifying.monthly');
    }),
    filteredWeeks: Ember.computed('month', 'weeks', 'selectedFilterMode', function () {
      var _this = this;

      var weekKeys = Object.keys(this.get('weeks'));
      var selectedFilterMode = this.get('selectedFilterMode');
      var weeks = this.get('weeks');
      var filteredWeeks = {};
      weekKeys.forEach(function (weekKey) {
        var weekData = weeks[weekKey];

        var userData = _toConsumableArray(weekData.userData);

        var filteredUserData = userData.filter(function (el) {
          if (selectedFilterMode === 'all') {
            return true;
          } else if (selectedFilterMode === 'approved') {
            return el.validation && el.validation.get('isApproved');
          } else if (selectedFilterMode === 'rejected') {
            return el.validation && el.validation.get('isRejected');
          } else {
            return !el.validation || el.validation.get('isOpen') || el.validation.get('isSubmitted');
          }
        });
        filteredWeeks[weekKey] = _objectSpread({}, weekData);
        delete filteredWeeks[weekKey].userData;

        if (filteredUserData.length > 0) {
          filteredWeeks[weekKey].userData = filteredUserData;
        }
      });
      var filteredWeekKeys = Object.keys(filteredWeeks);
      var hasWeeks = filteredWeekKeys.length > 0;
      Ember.run.next(this, function () {
        _this.set('hasWeeks', hasWeeks);
      });

      if (!hasWeeks) {
        this.send('loadPreviousWeek', moment(weekKeys[0] || moment(this.get('month', _environment.default.constants.DATE_MONTH_PICKER_FORMAT))));
      }

      if (filteredWeeks && filteredWeeks[filteredWeekKeys.get('lastObject')] && !filteredWeeks[filteredWeekKeys.get('lastObject')].userData) {
        this.send('loadPreviousWeek', moment(filteredWeekKeys.get('lastObject')));
      }

      return filteredWeeks;
    }),
    month: Ember.computed.alias('worktimeController.month')
  }, _defineProperty(_EmberController$ext, "validationService", Ember.inject.service('validation')), _defineProperty(_EmberController$ext, "actions", {
    updateList: function updateList() {
      var _this2 = this;

      this.notifyPropertyChange('weeks');
      this.get('session').getJSON((0, _buildUrl.default)(this.store, 'validate/invalid', {
        restrictOnAssignedUsers: true
      })).then(function (openValidationWeeks) {
        _this2.get('validationService').updateOpenValidationCount(openValidationWeeks);
      }).catch(console.error);
    },
    loadPreviousWeek: function loadPreviousWeek(startOfCurrentWeek) {
      var _this3 = this;

      var startOfPreviousWeek = moment(startOfCurrentWeek).subtract(1, 'week');
      var month = this.get('month');

      if (startOfPreviousWeek.isBefore(moment(month, _environment.default.constants.DATE_MONTH_PICKER_FORMAT).startOf('isoWeek'))) {
        this.set('isLoading', false);
        return;
      }

      if (!this.get('weeks')[startOfPreviousWeek.format(_environment.default.constants.DATE_FORMAT_DAY)]) {
        var startDate = startOfPreviousWeek.startOf('isoWeek').format(_environment.default.constants.DATE_FORMAT_DAY);
        var endDate = startOfPreviousWeek.endOf('isoWeek').format(_environment.default.constants.DATE_FORMAT_DAY);
        this.set('isLoading', true);
        this.get('session').getJSON((0, _buildUrl.default)(this.store, 'validate/checkUserWorktime', {
          startDate: startDate,
          endDate: endDate
        })).then(function (rawUserData) {
          var weekData = {
            startDate: startDate,
            endDate: endDate
          };
          var userData = rawUserData.userData.map(function (user) {
            user.user = _this3.get('store').peekRecord('user', user.id);
            user.validation = user.isAlreadyValidated ? _this3.store.peekRecord('validation', user.isAlreadyValidated) : null;
            user.holidays = _this3.store.peekAll('user-holiday').filter(function (el) {
              return el.get('date').isSameOrAfter(startDate, 'day') && el.get('date').isSameOrBefore(endDate, 'day');
            });
            return user;
          });
          weekData.userData = userData;
          _this3.get('weeks')[startDate] = weekData;

          _this3.set('isLoading', false);

          _this3.notifyPropertyChange('weeks');
        }).catch(console.error);
      } else {
        this.send('loadPreviousWeek', startOfPreviousWeek.format(_environment.default.constants.DATE_FORMAT_DAY));
      }
    }
  }), _EmberController$ext));

  _exports.default = _default;
});