define("coffeecup/components/dashboard-module/user-worktime", ["exports", "coffeecup/utils/color-hex2rgba", "coffeecup/config/environment", "coffeecup/utils/build-url"], function (_exports, _colorHex2rgba, _environment, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var MODE_EFFORT = 'trackedTime';
  var MODE_WORKLOAD = 'workload';
  var MODE_ABSENCES = 'absences';
  var PRESENTATION_AMOUNT = {
    name: 'showAmount',
    icon: 'clock',
    tooltip: 'showTime'
  };
  var PRESENTATION_PERCENT = {
    name: 'showPercentage',
    icon: 'percent_symbol',
    tooltip: 'showPercentage'
  };
  var WORKLOAD_VALUE = 'workload';
  var HOURS_VALUE = 'hours';
  var DashboardModuleProjectComponent = Ember.Component.extend({
    /*
     * INJECTIONS
     */
    store: Ember.inject.service(),
    formatter: Ember.inject.service(),
    session: Ember.inject.service(),
    clock: Ember.inject.service(),
    currentUserService: Ember.inject.service('currentUser'),
    workingDaysService: Ember.inject.service('working-days'),

    /*
     * PROPERTIES
     */
    module: null,
    defaultMode: MODE_EFFORT,
    draggable: true,
    modes: [MODE_EFFORT, MODE_WORKLOAD, MODE_ABSENCES],
    actionNames: [{
      name: 'showTimeEntries',
      icon: 'icomoon-clock'
    }, {
      name: 'removeModule',
      icon: 'icomoon-cross-exit'
    }],
    projects: [],
    absences: [],
    maxProjects: 4,
    totalSeconds: 0,
    totalAmount: 0,
    totalWorkedHours: 0,
    totalExpectedHours: 0,
    currentUser: null,
    isLoading: false,
    validationHash: null,
    alertColor: _environment.default.constants.ALERT_COLOR,
    chartColors: [_environment.default.constants.HOURS_COLOR_LIGHT, _environment.default.constants.HOURS_COLOR],
    absencesChartColors: [_environment.default.constants.HOURS_COLOR, _environment.default.constants.HOURS_COLOR_LIGHT, _environment.default.constants.HOURS_COLOR_LIGHTER, _environment.default.constants.HOURS_COLOR],

    /*
     * COMPUTED PROPERTIES
     */
    vacationColor: Ember.computed('absencesChartColors', function () {
      return _colorHex2rgba.RGBA.convertFromHex(this.get('absencesChartColors')[0], 1).toCSS();
    }),
    illnessColor: Ember.computed('absencesChartColors', function () {
      return _colorHex2rgba.RGBA.convertFromHex(this.get('absencesChartColors')[1], 1).toCSS();
    }),
    otherColor: Ember.computed('absencesChartColors', function () {
      return _colorHex2rgba.RGBA.convertFromHex(this.get('absencesChartColors')[2], 1).toCSS();
    }),
    totalHours: Ember.computed('totalSeconds', function () {
      return this.get('totalSeconds') / 3600;
    }),
    usedValue: Ember.computed('currentUser', function () {
      return this.get('currentUser.isFreelancer') || this.get('currentUser.hasFlexibleHours') ? HOURS_VALUE : WORKLOAD_VALUE;
    }),
    userHasFixedWeek: Ember.computed.alias('currentUser.hasFixedWeek'),
    userHasFlexibleHours: Ember.computed.alias('currentUser.hasFlexibleHours'),
    overtimeTooltip: Ember.computed('year', function () {
      var i18n = this.get('i18n');

      var _this$_getStartAndEnd = this._getStartAndEndHoursForTooltips(this.get('year')),
          startDate = _this$_getStartAndEnd.startDate,
          endDate = _this$_getStartAndEnd.endDate;

      return i18n.t('overtimeInPeriod', {
        startMonth: startDate,
        endMonth: endDate
      });
    }),
    _getStartAndEndHoursForTooltips: function _getStartAndEndHoursForTooltips(year) {
      var now = this.get('clock.now');
      var startMonth = moment().year(year).startOf('year');
      var startDate = startMonth.format('MMMM YYYY');
      var endMonth = moment().year(year).endOf('year');
      var endDate = endMonth.format('MMMM YYYY');

      if (now.isSame(startMonth, 'year')) {
        var currentEndDate = this._getUsersWorkloadEndDate(year);

        endDate = this.get('formatter').formatDate(currentEndDate);
      }

      return {
        startDate: startDate,
        endDate: endDate
      };
    },
    actualHoursTooltip: Ember.computed('year', function () {
      var i18n = this.get('i18n');

      var _this$_getStartAndEnd2 = this._getStartAndEndHoursForTooltips(this.get('year')),
          startDate = _this$_getStartAndEnd2.startDate,
          endDate = _this$_getStartAndEnd2.endDate;

      return i18n.t('actualHoursInPeriod', {
        startMonth: startDate,
        endMonth: endDate
      });
    }),
    plannedHoursTooltip: Ember.computed('year', function () {
      var i18n = this.get('i18n');

      var _this$_getStartAndEnd3 = this._getStartAndEndHoursForTooltips(this.get('year')),
          startDate = _this$_getStartAndEnd3.startDate,
          endDate = _this$_getStartAndEnd3.endDate;

      return i18n.t('plannedHoursInPeriod', {
        startMonth: startDate,
        endMonth: endDate
      });
    }),
    presentationModes: Ember.computed('currentMode', function () {
      if (this.get('currentMode') === MODE_EFFORT) {
        return [PRESENTATION_AMOUNT, PRESENTATION_PERCENT];
      }
    }),
    previousTotalSeconds: Ember.computed('previousTimeEntries.isFulfilled', function () {
      var prevTimeEntries = this.get('previousTimeEntries');
      return prevTimeEntries.reduce(function (accumulator, timeEntry) {
        return accumulator + parseFloat(timeEntry.get('duration') || 0);
      }, 0);
    }),
    previousTotalHours: Ember.computed('previousTotalSeconds', function () {
      return this.get('previousTotalSeconds') / 3600;
    }),
    previousUserTotalSeconds: Ember.computed('previousTimeEntries.isFulfilled', function () {
      var currentUser = this.get('currentUser');
      var prevTimeEntries = this.get('previousTimeEntries');
      return prevTimeEntries.filter(function (timeEntry) {
        return timeEntry.get('user.id') === currentUser.get('id');
      }).reduce(function (accumulator, timeEntry) {
        return accumulator + parseFloat(timeEntry.get('duration') || 0);
      }, 0);
    }),
    previousUserTotalHours: Ember.computed('previousUserTotalSeconds', function () {
      return this.get('previousUserTotalSeconds') / 3600;
    }),
    isModeEffort: Ember.computed.equal('currentMode', MODE_EFFORT),
    isModeWorkload: Ember.computed.equal('currentMode', MODE_WORKLOAD),
    isModeAbsences: Ember.computed.equal('currentMode', MODE_ABSENCES),
    showTotal: Ember.computed.equal('selectedPresentationMode.name', PRESENTATION_AMOUNT.name),
    isDataUpdating: Ember.computed.alias('timeEntries.isFulfilled'),
    workloadLegendItems: Ember.computed('totalWorkedHours', 'userHasFlexibleHours', 'totalExpectedHours', 'overtime', 'overtimeTooltip', function () {
      return [{
        title: 'actualHours',
        titleValue: this.get('totalWorkedHours'),
        tooltip: this.get('actualHoursTooltip'),
        hideElement: !this.get('totalWorkedHours') || this.get('userHasFlexibleHours')
      }, {
        title: 'plannedHours',
        titleValue: this.get('totalExpectedHours'),
        tooltip: this.get('plannedHoursTooltip'),
        hideElement: !this.get('totalExpectedHours') || this.get('userHasFlexibleHours')
      }, {
        title: 'workTime',
        titleValue: "".concat(this.get('totalWorkedHours')),
        tooltip: this.get('actualHoursTooltip'),
        hideElement: !this.get('totalExpectedHours') || !this.get('userHasFlexibleHours')
      }, {
        title: 'overtime',
        titleValue: "".concat(this.get('overtime')),
        tooltip: this.get('overtimeTooltip'),
        hideElement: !this.get('overtime') || this.get('userHasFlexibleHours')
      }];
    }),
    absencesLegendItems: Ember.computed('totalVacationDays', 'totalIllnessDays', 'totalOtherDays', function () {
      return [{
        title: 'vacationDays',
        titleValue: this.get('totalVacationDays'),
        color: this.get('vacationColor')
      }, {
        title: 'illnessDays',
        titleValue: this.get('totalIllnessDays'),
        color: this.get('illnessColor')
      }, {
        title: 'other',
        titleValue: this.get('totalOtherDays'),
        color: this.get('otherColor')
      }];
    }),
    currentMode: Ember.computed('module', {
      get: function get() {
        var currentMode = this.get('defaultMode');
        var availableModes = this.get('modes');
        var settings = this.get('module.settingsObj');

        if (settings.currentMode && availableModes.indexOf(settings.currentMode) !== -1) {
          currentMode = settings.currentMode;
        }

        return currentMode;
      },
      set: function set(key, newValue) {
        var module = this.get('module');
        var settings = module.get('settingsObj');

        if (newValue !== '') {
          settings.currentMode = newValue;
          module.set('settingsObj', settings);

          this._saveModule();
        }

        return newValue;
      }
    }),
    selectedPresentationMode: Ember.computed('module', {
      get: function get() {
        var presentationMode = PRESENTATION_AMOUNT;
        var settings = this.get('module.settingsObj');

        if (settings.presentationMode) {
          presentationMode = settings.presentationMode;
        }

        return presentationMode;
      },
      set: function set(key, newValue) {
        var module = this.get('module');
        var settings = module.get('settingsObj');

        if (!Ember.isEmpty(newValue)) {
          settings.presentationMode = newValue;
          module.set('settingsObj', settings);

          this._saveModule();
        }

        return newValue;
      }
    }),
    rangeType: Ember.computed('module', {
      get: function get() {
        var settings = this.get('module.settingsObj');

        if (settings.rangeType) {
          return settings.rangeType;
        } else {
          return undefined;
        }
      },
      set: function set(key, newValue) {
        var module = this.get('module');
        var settings = module.get('settingsObj');
        settings.rangeType = newValue;
        module.set('settingsObj', settings);

        this._saveModule();

        return newValue;
      }
    }),
    year: Ember.computed('module', {
      get: function get() {
        var year = this.get('clock.year').format('YYYY');
        var settings = this.get('module.settingsObj');

        if (settings.year) {
          year = settings.year;
        }

        return year;
      },
      set: function set(key, newValue) {
        var module = this.get('module');
        var settings = module.get('settingsObj');

        if (!Ember.isEmpty(newValue)) {
          settings.year = newValue;
          module.set('settingsObj', settings);

          this._saveModule();
        }

        return newValue;
      }
    }),

    /**
     * query param from date
     */
    from: Ember.computed('module', {
      /**
       * @returns String 'YYYY-MM-DD'
       */
      get: function get() {
        var settings = this.get('module.settingsObj');

        if (settings.rangeType) {
          return this._generateStartDate(settings.rangeType);
        } else {
          if (settings.fromDate) {
            return settings.fromDate;
          } else if (this.get('isModeWorkload') || this.get('isModeAbsences') || this.get('isModeShowYearly')) {
            return moment().year(this.get('year')).startOf('year').format(_environment.default.constants.DATE_FORMAT_DAY);
          } else {
            return this.get('clock.month').format(_environment.default.constants.DATE_FORMAT_DAY);
          }
        }
      },
      set: function set(key, newValue) {
        var module = this.get('module');
        var settings = module.get('settingsObj');

        if (!Ember.isEmpty(newValue)) {
          settings.fromDate = newValue;
          module.set('settingsObj', settings);

          this._saveModule();
        }

        return newValue;
      }
    }),

    /**
     * query param to date
     */
    to: Ember.computed('module', {
      /**
       * @returns String 'YYYY-MM-DD'
       */
      get: function get() {
        var settings = this.get('module.settingsObj');

        if (settings.rangeType) {
          return this._generateEndDate(settings.rangeType);
        } else {
          if (settings.toDate) {
            return settings.toDate;
          } else if (this.get('isModeWorkload') || this.get('isModeAbsences') || this.get('isModeShowYearly')) {
            return moment().year(this.get('year')).endOf('year').format(_environment.default.constants.DATE_FORMAT_DAY);
          } else {
            return this.get('clock.month').endOf('month').format(_environment.default.constants.DATE_FORMAT_DAY);
          }
        }
      },
      set: function set(key, newValue) {
        var module = this.get('module');
        var settings = module.get('settingsObj');

        if (!Ember.isEmpty(newValue)) {
          settings.toDate = newValue;
          module.set('settingsObj', settings);

          this._saveModule();
        }

        return newValue;
      }
    }),
    timeEntries: Ember.computed('currentUser.id', 'from', 'to', function () {
      var query = {
        trackingType: _environment.default.constants.TIME_ENTRY_TRACKING_TYPE_WORK,
        user: this.get('currentUser.id'),
        day: {
          '>=': this.get('from'),
          '<=': this.get('to')
        }
      };
      return this.get('store').query('time-entry', {
        where: query
      });
    }),
    previousTimeEntries: Ember.computed('currentUser.id', 'previousFrom', 'previousTo', function () {
      var query = {
        trackingType: _environment.default.constants.TIME_ENTRY_TRACKING_TYPE_WORK,
        user: this.get('currentUser.id'),
        day: {
          '>=': this.get('previousFrom'),
          '<=': this.get('previousTo')
        }
      };
      return this.get('store').query('time-entry', {
        where: query
      });
    }),
    dataHours: Ember.computed('timeEntries.isFulfilled', function () {
      var _this = this;

      if (!this.get('timeEntries').isFulfilled) {
        return '';
      }

      var projects = this.get('projects').sortBy('totalSeconds');
      var max = this.get('maxProjects');
      var data = [];
      var currentUser = this.get('currentUser');
      var remaining = {
        id: -99,
        label: this.get('i18n').t('other'),
        value: 0,
        total: 0
      };
      var totalSeconds = this.get('timeEntries').reduce(function (accumulator, timeEntry) {
        if (timeEntry.get('user.id') === currentUser.get('id')) {
          return accumulator + parseFloat(timeEntry.get('duration') || 0);
        } else {
          return accumulator;
        }
      }, 0);
      this.set('totalSeconds', totalSeconds);

      var _loop = function _loop() {
        var project = projects.pop(); // projects.get('project');

        var sumDuration = 0;

        _this.get('timeEntries').forEach(function (item) {
          if (item.get('project.id') === project.get('id') && item.get('user.id') === currentUser.get('id')) {
            sumDuration += parseFloat(item.get('duration') || 0);
          }
        });

        if (sumDuration > 0) {
          data.push({
            id: project.get('id'),
            label: project.get('fullName'),
            subLabel: project.get('clientName'),
            value: Math.round(sumDuration / totalSeconds * 100),
            total: sumDuration / 3600,
            colorCode: '#' + project.get('color.hex')
          });
        }
      };

      while (projects.length > 0) {
        _loop();
      }

      data = data.sortBy('value').reverse();
      var sortedData = [];
      data.forEach(function (project) {
        if (sortedData.length <= max) {
          sortedData.push(project);
        } else {
          remaining.value += project.value;
          remaining.total += project.total;
        }
      });

      if (remaining.value > 0) {
        remaining.colorCode = '#CCCCCC';
        sortedData.push(remaining);
      }

      return sortedData;
    }),

    /*
     * OBSERVERS
     */
    _generatePreviousStartDate: Ember.observer('currentMode', 'rangeType', 'from', 'to', function () {
      var from = moment(this.get('from'), _environment.default.constants.DATE_FORMAT_DAY);
      var to = moment(this.get('to'), _environment.default.constants.DATE_FORMAT_DAY);
      var rangeType = this.get('rangeType');
      var previousFrom, previousTo;

      switch (rangeType) {
        case _environment.default.constants.DATE_RANGE_TODAY:
          previousFrom = moment(from).subtract(1, 'day').startOf('day');
          previousTo = moment(to).subtract(1, 'day').endOf('day');
          break;

        case _environment.default.constants.DATE_RANGE_THIS_WEEK:
        case _environment.default.constants.DATE_RANGE_LAST_WEEK:
          previousFrom = moment(from).subtract(1, 'week').startOf('week');
          previousTo = moment(to).subtract(1, 'week').endOf('week');
          break;

        case _environment.default.constants.DATE_RANGE_THIS_MONTH:
        case _environment.default.constants.DATE_RANGE_LAST_MONTH:
          previousFrom = moment(from).subtract(1, 'month').startOf('month');
          previousTo = moment(to).subtract(1, 'month').endOf('month');
          break;

        case _environment.default.constants.DATE_RANGE_THIS_QUARTER:
          previousFrom = moment(from).subtract(3, 'month').startOf('month');
          previousTo = moment(to).subtract(3, 'month').endOf('month');
          break;

        case _environment.default.constants.DATE_RANGE_THIS_YEAR:
        case _environment.default.constants.DATE_RANGE_LAST_YEAR:
          previousFrom = moment(from).subtract(1, 'year').startOf('year');
          previousTo = moment(to).subtract(1, 'year').endOf('year');
          break;

        default:
          if (to.isSame(moment(to).endOf('month'), 'day') && from.isSame(moment(from).startOf('month'), 'day') && from.isSame(to, 'month')) {
            // check if the selected range is a month
            previousFrom = moment(from).subtract(1, 'month').startOf('month');
            previousTo = moment(to).subtract(1, 'month').endOf('month');
          } else if (to.isSame(moment(to).endOf('month'), 'day') && from.isSame(moment(from).startOf('month'), 'day') && moment(from).add(2, 'month').isSame(to, 'month')) {
            // check if the selected range is a quarter
            previousFrom = moment(from).subtract(3, 'month').startOf('month');
            previousTo = moment(to).subtract(3, 'month').endOf('month');
          } else if (to.isSame(moment(to).endOf('month'), 'day') && from.isSame(moment(from).startOf('month'), 'day') && moment(from).add(11, 'month').isSame(to, 'month')) {
            // check if the selected range is a year
            previousFrom = moment(from).subtract(12, 'month').startOf('month');
            previousTo = moment(to).subtract(12, 'month').endOf('month');
          } else {
            var diff = moment(to).startOf('day').diff(moment(from).subtract(1, 'day').endOf('day'));
            previousFrom = moment(from).subtract(diff);
            previousTo = moment(to).subtract(diff);
          }

      }

      if (moment.isMoment(previousFrom) && moment.isMoment(previousTo)) {
        this.set('previousFrom', previousFrom.format(_environment.default.constants.DATE_FORMAT_DAY));
        this.set('previousTo', previousTo.format(_environment.default.constants.DATE_FORMAT_DAY));
      }
    }),
    _loadAbsencesDataObserver: Ember.observer('currentMode', 'year', 'currentUser.id', function () {
      Ember.run.once(this, '_loadAbsencesData');
    }),
    _loadAbsencesData: function _loadAbsencesData() {
      var _this2 = this;

      if (this.get('isModeAbsences')) {
        this.set('isLoading', true);
        var year = this.get('year');
        var validationHash = year + this.get('currentMode');
        this.set('validationHash', validationHash);
        var query = {
          or: [{
            startDate: {
              '>=': moment().year(year).startOf('year').subtract(100, 'days').format(_environment.default.constants.DATE_FORMAT_DAY),
              '<=': moment().year(year).endOf('year').format(_environment.default.constants.DATE_FORMAT_DAY)
            }
          }, {
            endDate: {
              '>=': moment().year(year).startOf('year').format(_environment.default.constants.DATE_FORMAT_DAY),
              '<=': moment().year(year).endOf('year').add(100, 'days').format(_environment.default.constants.DATE_FORMAT_DAY)
            }
          }],
          user: this.get('currentUser.id')
        };
        this.get('store').query('absence', {
          where: query
        }).then(function (absences) {
          if (_this2.get('validationHash') === validationHash) {
            _this2.set('isLoading', false);

            _this2.set('absences', absences);
          }
        });
      }
    },
    _prepareAbsenceData: Ember.observer('absences.[]', function () {
      var year = this.get('clock.now').year(this.get('year'));
      var workingDaysService = this.get('workingDaysService');
      var user = this.get('currentUser');
      var absences = this.get('absences');
      var i18n = this.get('i18n');
      var monthlyAbsences = [];

      for (var i = 0; i < 12; i++) {
        monthlyAbsences[i] = {
          month: moment.monthsShort(i),
          vacation: 0,
          illness: 0,
          other: 0
        };
      }

      absences.forEach(function (absence) {
        var absenceStartDate = moment(absence.get('startDate'));
        var absenceEndDate = moment(absence.get('endDate'));
        var startDateMonth = absenceStartDate.month();
        var endDateMonth = absenceEndDate.month();
        var absenceType = absence.get('absenceType.localizedLabel');

        if (absence.get('countsAsVacation')) {
          absenceType = 'vacation';
        } else if (absence.get('absenceType.isIllness')) {
          absenceType = 'illness';
        }

        if (!monthlyAbsences[startDateMonth][absenceType]) monthlyAbsences[startDateMonth][absenceType] = 0;
        if (!monthlyAbsences[endDateMonth][absenceType]) monthlyAbsences[endDateMonth][absenceType] = 0;

        if (absenceStartDate.isSame(absenceEndDate, 'month') && absenceStartDate.isSame(absenceEndDate, 'year') && absenceStartDate.isSame(year, 'year')) {
          monthlyAbsences[startDateMonth][absenceType] += workingDaysService.getWorkingDaysOfAbsence(user, absence);

          if (absenceType !== 'vacation' && absenceType !== 'illness') {
            monthlyAbsences[startDateMonth]['other'] += workingDaysService.getWorkingDaysOfAbsence(user, absence);
          }
        } else {
          var usedDaysInStartMonth = 0;
          var usedDaysInEndMonth = 0;

          if (absenceStartDate.isSame(year, 'year')) {
            usedDaysInStartMonth = workingDaysService.getWorkingDaysOfTimePeriod(user, absenceStartDate, absenceStartDate.clone().endOf('month'));
          }

          if (absenceEndDate.isSame(year, 'year')) {
            usedDaysInEndMonth = workingDaysService.getWorkingDaysOfTimePeriod(user, absenceEndDate.clone().startOf('month'), absenceEndDate);
          }

          monthlyAbsences[startDateMonth][absenceType] += usedDaysInStartMonth;
          monthlyAbsences[endDateMonth][absenceType] += usedDaysInEndMonth;

          if (absenceType !== 'vacation' && absenceType !== 'illness') {
            monthlyAbsences[startDateMonth]['other'] += usedDaysInStartMonth;
            monthlyAbsences[endDateMonth]['other'] += usedDaysInEndMonth;
          }
        }
      });
      var totalOtherDays = 0;
      monthlyAbsences.map(function (monthlyAbsence) {
        var balloonText = '';

        for (var key in monthlyAbsence) {
          if (key && key !== 'month' && key !== 'other' && monthlyAbsence[key] > 0) {
            if (key === 'vacation' || key === 'illness') {
              balloonText += "".concat(i18n.t(key), ": ").concat(monthlyAbsence[key], " ").concat(i18n.t('days'), " <br/>");
            } else {
              balloonText += "".concat(key, ": ").concat(monthlyAbsence[key], " ").concat(i18n.t('days'), " <br/>");
              totalOtherDays += monthlyAbsence[key];
            }
          }
        }

        monthlyAbsence.balloonText = balloonText;
        return monthlyAbsence;
      });
      var totalVacationDays = monthlyAbsences.reduce(function (acc, el) {
        return el.vacation ? acc + el.vacation : acc;
      }, 0);
      var totalIllnessDays = monthlyAbsences.reduce(function (acc, el) {
        return el.illness ? acc + el.illness : acc;
      }, 0);
      this.set('totalVacationDays', totalVacationDays);
      this.set('totalIllnessDays', totalIllnessDays);
      this.set('totalOtherDays', totalOtherDays);

      this._insertAbsencesGraph(monthlyAbsences);
    }),
    _prepareWorkloadDataObserver: Ember.observer('year', 'currentMode', 'currentUser', function () {
      Ember.run.once(this, '_prepareWorkloadData');
    }),
    _prepareWorkloadData: function _prepareWorkloadData() {
      var _this3 = this;

      if (this.get('isModeWorkload')) {
        this.set('isLoading', true);
        var year = this.get('year');
        var currentUserId = this.get('currentUser.id');
        var usedValue = this.get('usedValue');

        this._loadWorkloadData(year, currentUserId, usedValue).then(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
              workloadData = _ref2[0],
              loadedYear = _ref2[1];

          if (parseInt(loadedYear) === parseInt(year)) {
            _this3.set('isLoading', false);

            _this3._insertWorkloadGraph(workloadData);
          }
        });
      }
    },

    /*
     * FUNCTIONS
     */

    /**
     * @param rangeType
     * @private
     * @return String 'YYYY-MM-DD'
     */
    _generateStartDate: function _generateStartDate(rangeType) {
      var startDate = this.get('clock.now');

      if (rangeType === _environment.default.constants.DATE_RANGE_TODAY) {
        startDate = this.get('clock.day');
      } else if (rangeType === _environment.default.constants.DATE_RANGE_THIS_WEEK) {
        startDate = this.get('clock.week');
      } else if (rangeType === _environment.default.constants.DATE_RANGE_LAST_WEEK) {
        startDate = this.get('clock.week').subtract(1, 'week').startOf('week');
      } else if (rangeType === _environment.default.constants.DATE_RANGE_THIS_MONTH) {
        startDate = this.get('clock.month');
      } else if (rangeType === _environment.default.constants.DATE_RANGE_LAST_MONTH) {
        startDate = this.get('clock.month').subtract(1, 'month').startOf('month');
      } else if (rangeType === _environment.default.constants.DATE_RANGE_THIS_QUARTER) {
        startDate = this.get('clock.quarter');
      } else if (rangeType === _environment.default.constants.DATE_RANGE_THIS_YEAR) {
        startDate = this.get('clock.year');
      } else if (rangeType === _environment.default.constants.DATE_RANGE_LAST_YEAR) {
        startDate = this.get('clock.year').subtract(1, 'year').startOf('year');
      }

      return startDate.format(_environment.default.constants.DATE_FORMAT_DAY);
    },

    /**
     * @param rangeType
     * @private
     * @return String 'YYYY-MM-DD'
     */
    _generateEndDate: function _generateEndDate(rangeType) {
      var endDate = this.get('clock.now');

      if (rangeType === _environment.default.constants.DATE_RANGE_TODAY) {
        endDate = this.get('clock.day').endOf('day');
      } else if (rangeType === _environment.default.constants.DATE_RANGE_THIS_WEEK) {
        endDate = this.get('clock.week').endOf('week');
      } else if (rangeType === _environment.default.constants.DATE_RANGE_LAST_WEEK) {
        endDate = this.get('clock.week').subtract(1, 'week').endOf('week');
      } else if (rangeType === _environment.default.constants.DATE_RANGE_THIS_MONTH) {
        endDate = this.get('clock.month').endOf('month');
      } else if (rangeType === _environment.default.constants.DATE_RANGE_LAST_MONTH) {
        endDate = this.get('clock.month').subtract(1, 'month').endOf('month');
      } else if (rangeType === _environment.default.constants.DATE_RANGE_THIS_QUARTER) {
        endDate = this.get('clock.quarter').endOf('quarter');
      } else if (rangeType === _environment.default.constants.DATE_RANGE_THIS_YEAR) {
        endDate = this.get('clock.year').endOf('year');
      } else if (rangeType === _environment.default.constants.DATE_RANGE_LAST_YEAR) {
        endDate = this.get('clock.year').subtract(1, 'year').endOf('year');
      }

      return endDate.format(_environment.default.constants.DATE_FORMAT_DAY);
    },
    didInsertElement: function didInsertElement() {
      this._generatePreviousStartDate();

      if (this.get('module.isTypeUserWorktime')) {
        this._prepareWorkloadData();

        this._loadAbsencesData();
      }

      var module = this.get('module');

      if (module.get('hasDirtyAttributes')) {
        this._saveModule();
      }
    },
    _saveModule: function _saveModule() {
      if (this.get('module.constructor.modelName') === 'dashboard-module') {
        Ember.run.once(this, '_pushData');
      }
    },
    _pushData: function _pushData() {
      var _this4 = this;

      Ember.run.later(this, function () {
        var module = _this4.get('module');

        module.save();
      }, 100);
    },
    _getUsersWorkloadEndDate: function _getUsersWorkloadEndDate(year) {
      var endDate = moment().year(year).endOf('year');

      if (this.get('clock.now').isSame(moment().year(year), 'year')) {
        endDate = this.get('clock.now').subtract(1, 'days');

        if (this.get('userHasFixedWeek')) {
          endDate = this.get('clock.now').subtract(1, 'weeks').endOf('week');
        } else if (this.get('userHasFlexibleHours')) {
          endDate = this.get('clock.now');
        }
      }

      return endDate;
    },
    _loadWorkloadData: function _loadWorkloadData(year, userId, usedValue) {
      var _this5 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var i18n = _this5.get('i18n');

        var formatter = _this5.get('formatter');

        if (userId) {
          var options = {
            cachedWorkloads: false
          };

          var endDate = _this5._getUsersWorkloadEndDate(year);

          var url = (0, _buildUrl.default)(_this5.get('store'), 'users/' + userId + '/workload/' + year + '/monthly', options);

          _this5.get('session').getJSON(url).then(function (data) {
            if (parseInt(data.year) === parseInt(_this5.get('year'))) {
              var hasBigValues = false;
              var numberOfActiveMonths = 0;
              data.monthlyWorkload.sort(function (a, b) {
                return a.month - b.month;
              });
              data.monthlyWorkload = data.monthlyWorkload.map(function (workloadElement) {
                if (usedValue === WORKLOAD_VALUE) {
                  if (workloadElement[usedValue]) {
                    // get percentage value from api response
                    workloadElement[usedValue] = parseInt(100 * workloadElement[usedValue]);

                    if (workloadElement[usedValue] >= 100) {
                      hasBigValues = true;
                    }

                    numberOfActiveMonths += 1;
                    workloadElement.legendText = moment.months(workloadElement.month - 1) + ': ' + workloadElement[usedValue] + '%';
                  } else {
                    workloadElement[usedValue] = 0;
                  }

                  workloadElement.hours = workloadElement[HOURS_VALUE]; // if user is flexible or freelancer for a period in year, the expected hours are set equal to worked hours
                  // this corrects wrong numbers in chart legend

                  if (workloadElement.expectedHours === 0) {
                    workloadElement.expectedHours = workloadElement.hours;
                  }

                  workloadElement.month = moment.monthsShort(workloadElement.month - 1);
                  workloadElement.balloonText = "".concat(i18n.t('workload'), ": ").concat(workloadElement[usedValue], "% <br />");
                  workloadElement.balloonText += "".concat(i18n.t('totalHours'), ": ").concat(formatter.formatDuration(workloadElement[HOURS_VALUE]), "<br/>");
                  workloadElement.balloonText += "".concat(i18n.t('plannedHours'), ": ").concat(formatter.formatDuration(workloadElement.expectedHours), "<br/>");
                  workloadElement.balloonText += "".concat(i18n.t('compensatedOvertime'), ": ").concat(formatter.formatDuration(-1 * workloadElement.overtimeReduced), "<br/>");

                  if (moment().year(year).month(workloadElement.month).isSame(endDate, 'month')) {
                    workloadElement.balloonText += "".concat(i18n.t(workloadElement.overtime >= 0 ? 'overTime' : 'missingHours'), ": ").concat(formatter.formatDuration(workloadElement.overtime), " ").concat(i18n.t('to'), " ").concat(formatter.formatDate(endDate), "<br/>");
                  } else {
                    workloadElement.balloonText += "".concat(i18n.t(workloadElement.overtime >= 0 ? 'overTime' : 'missingHours'), ": ").concat(formatter.formatDuration(workloadElement.overtime), "<br/>");
                  }

                  return workloadElement;
                } else if (usedValue === HOURS_VALUE) {
                  if (workloadElement[usedValue]) {
                    workloadElement[usedValue] = workloadElement[usedValue]; // used so that no line is drawn

                    hasBigValues = true;
                    numberOfActiveMonths += 1;
                    workloadElement.legendText = moment.months(workloadElement.month - 1) + ': ' + formatter.formatDuration(workloadElement[usedValue]);
                  } else {
                    workloadElement[usedValue] = 0;
                  }

                  workloadElement.hours = workloadElement[HOURS_VALUE];
                  workloadElement.month = moment.monthsShort(workloadElement.month - 1);
                  workloadElement.balloonText = formatter.formatDuration(workloadElement[usedValue]);
                  return workloadElement;
                }
              });

              if (numberOfActiveMonths === 0) {
                numberOfActiveMonths = 1;
              }

              var totalExpectedHours = data.monthlyWorkload.reduce(function (prev, curr) {
                return prev + curr.expectedHours;
              }, 0);
              var totalHours = data.monthlyWorkload.reduce(function (prev, curr) {
                return prev + curr.hours;
              }, 0);
              var totalOvertime = data.monthlyWorkload.reduce(function (prev, curr) {
                return prev + curr.overtime;
              }, 0);
              var totalOvertimeReduced = data.monthlyWorkload.reduce(function (prev, curr) {
                return prev + curr.overtimeReduced;
              }, 0);

              _this5.set('totalWorkedHours', formatter.formatDuration(totalHours));

              _this5.set('totalExpectedHours', formatter.formatDuration(totalExpectedHours));

              _this5.set('totalOvertimeReduced', formatter.formatDuration(totalOvertimeReduced));

              _this5.set('overtime', formatter.formatDuration(totalOvertime));

              _this5.set('hasBigValues', hasBigValues);

              resolve([data, year]);
            }
          });
        }
      });
    },
    _insertWorkloadGraph: function _insertWorkloadGraph(workloadData) {
      var i18n = this.get('i18n');
      var userValueAxis = {
        stackType: 'regular',
        gridAlpha: 0.07,
        position: 'left',
        unit: this.get('usedValue') === HOURS_VALUE ? 'h' : '%',
        autoGridCount: false,
        axisAlpha: 0,
        gridCount: 2
      };

      if (!this.get('hasBigValues')) {
        userValueAxis.maximum = 110;
      }

      var guides = [];

      if (this.get('usedValue') === WORKLOAD_VALUE) {
        guides.push({
          balloonText: i18n.t('standardWorkload'),
          id: 'Guide-1',
          fillColor: this.get('alertColor'),
          lineColor: this.get('alertColor'),
          lineAlpha: 1,
          inside: true,
          dashLength: 2,
          value: 100
        });
      }

      var chartOptions = {
        type: 'serial',
        theme: 'light',
        marginRight: 30,
        fontFamily: 'robotolight',
        color: '#4A4A4A',
        fontSize: 11,
        dataProvider: workloadData.monthlyWorkload,
        valueAxes: [userValueAxis],
        colors: [_environment.default.constants.HOURS_COLOR_LIGHT],
        graphs: [{
          balloonText: '[[balloonText]]',
          fillAlphas: 1,
          lineAlpha: 0,
          title: 'User',
          colorField: 'customColor',
          valueField: this.get('usedValue'),
          type: 'column'
        }],
        chartCursor: {
          enabled: true,
          cursorColor: _environment.default.constants.ALERT_COLOR,
          bulletsEnabled: true,
          zoomable: false
        },
        balloon: {
          textAlign: 'left',
          fillAlpha: 1,
          maxWidth: 500
        },
        guides: guides,
        plotAreaBorderAlpha: 0,
        marginTop: 10,
        marginLeft: 0,
        marginBottom: 0,
        categoryField: 'month',
        categoryAxis: {
          axisColor: '#DADADA',
          gridThickness: 0
        }
      };
      AmCharts.makeChart('chartdiv-' + this.get('elementId'), chartOptions);
    },
    _insertAbsencesGraph: function _insertAbsencesGraph(absencesData) {
      var i18n = this.get('i18n');
      var userValueAxis = {
        stackType: 'regular',
        gridAlpha: 0.07,
        position: 'left',
        unit: i18n.t('days'),
        autoGridCount: false,
        axisAlpha: 0,
        gridCount: 2
      };
      var guides = [];
      var chartOptions = {
        type: 'serial',
        theme: 'light',
        marginRight: 30,
        dataProvider: absencesData,
        valueAxes: [userValueAxis],
        fontFamily: 'robotolight',
        fontSize: 11,
        color: '#4A4A4A',
        colors: this.get('absencesChartColors'),
        graphs: [{
          balloonText: '[[balloonText]]',
          fillAlphas: 1,
          lineAlpha: 0,
          colorField: 'customColor',
          valueField: 'vacation',
          type: 'column'
        }, {
          balloonText: '',
          fillAlphas: 1,
          lineAlpha: 0,
          colorField: 'customColor',
          valueField: 'illness',
          type: 'column'
        }, {
          balloonText: '',
          fillAlphas: 1,
          lineAlpha: 0,
          colorField: 'customColor',
          valueField: 'other',
          type: 'column'
        }],
        chartCursor: {
          enabled: true,
          cursorColor: _environment.default.constants.ALERT_COLOR,
          bulletsEnabled: true,
          zoomable: false
        },
        balloon: {
          textAlign: 'left',
          maxWidth: 500
        },
        guides: guides,
        plotAreaBorderAlpha: 0,
        marginTop: 10,
        marginLeft: 0,
        marginBottom: 0,
        categoryField: 'month',
        categoryAxis: {
          axisColor: '#DADADA',
          gridThickness: 0
        }
      };
      AmCharts.makeChart('chartdiv-' + this.get('elementId'), chartOptions);
    },

    /*
     * ACTIONS
     */
    actions: {
      startAction: function startAction(name) {
        this.send(name);
      },
      removeModule: function removeModule() {
        this.sendAction('onRemove');
      },
      rangeTypeChanged: function rangeTypeChanged(newRangeType) {
        this.set('rangeType', newRangeType);
      },
      setCurrentMode: function setCurrentMode(mode) {
        this.set('currentMode', mode);
      },
      showTimeEntries: function showTimeEntries() {
        var from = moment(this.get('from'), _environment.default.constants.DATE_FORMAT_DAY).format(_environment.default.constants.DATE_FORMAT_DAY);
        var to = moment(this.get('to'), _environment.default.constants.DATE_FORMAT_DAY).format(_environment.default.constants.DATE_FORMAT_DAY);

        if (this.get('isModeWorkload') || this.get('isModeAbsences') || this.get('isModeShowYearly')) {
          from = moment().year(this.get('year')).startOf('year').format(_environment.default.constants.DATE_FORMAT_DAY);
          to = moment().year(this.get('year')).endOf('year').format(_environment.default.constants.DATE_FORMAT_DAY);
        }

        this.sendAction('customAction', 'showTimeEntries', {
          item: false,
          from: from,
          to: to,
          all: false,
          prm: {
            user: this.get('module.isUserModule') ? [this.get('currentUserService').getCurrentUser().get('idAsInt')] : []
          }
        });
      }
    }
  });
  var _default = DashboardModuleProjectComponent;
  _exports.default = _default;
});