define("coffeecup/components/planner/user-timeline", ["exports", "coffeecup/components/deferred-row"], function (_exports, _deferredRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _deferredRow.default.extend({
    classNames: ['user-timeline', 'deferred-row-timeline'],
    viewport: Ember.inject.service('planner.viewport'),
    currentUserService: Ember.inject.service('currentUser'),
    user: null,
    assignments: null,
    allocatedFrames: Ember.computed.filterBy('frames', 'isWorking', true),
    nonWorkingDayFrames: Ember.computed.filterBy('frames', 'isNonWorkingDay', true),
    disableFrameCalculation: null,
    frameArrayCached: [],
    showAbsencesInPlanner: Ember.computed.reads('currentUserService.currentUser.uiState.showAbsencesInPlanner'),
    showAbsenceRequestsInPlanner: Ember.computed.reads('currentUserService.currentUser.uiState.showAbsenceRequestsInPlanner'),
    frames: Ember.computed('assignments.@each.{startDateFmt,endDateFmt,allocation}', {
      get: function get() {
        var _this = this;

        if (this.get('disableFrameCalculation')) {
          return this.get('frameArrayCached');
        }

        var startDate = this.get('viewport.startDateWithMargin');
        var endDate = this.get('viewport.endDateWithMargin');
        var allocatedDaysArray = this.get('user').allocatedDays(startDate, endDate);
        var framesArray = Ember.A();
        var previousDay = null;
        var allocatedFrame = null;
        allocatedDaysArray.forEach(function (allocatedDay) {
          var allocationOrOff = allocatedDay.allocation || allocatedDay.isOff || allocatedDay.isAbsent || allocatedDay.isWorking === false || allocatedDay.absenceRangeType; // Always split the assignment frame in weekly mode on Mondays

          var splitFrameInWeeklyMode = _this.get('viewport.isWeeklyPlanningMode') && allocatedDay.date.day() === 1;

          if (allocationOrOff && previousDay && allocatedDay.allocation === previousDay.allocation && allocatedDay.isAbsent === previousDay.isAbsent && allocatedDay.isOff === previousDay.isOff && allocatedDay.absenceRangeType === previousDay.absenceRangeType && allocatedDay.absenceComment === previousDay.absenceComment && allocatedDay.absenceLength === previousDay.absenceLength && allocatedDay.absenceType === previousDay.absenceType && (allocatedDay.isWorking === previousDay.isWorking || allocatedDay.isAbsent && previousDay.isAbsent) && allocatedDay.expectedHours === previousDay.expectedHours && !splitFrameInWeeklyMode) {
            // if every crucial value from the previous day is identical, we don't add another frame to keep the dom slim
            allocatedFrame.endDate = allocatedDay.date.clone();
          } else {
            if (allocationOrOff) {
              allocatedFrame = framesArray.pushObject({
                startDate: allocatedDay.date.clone(),
                endDate: allocatedDay.date.clone(),
                allocation: allocatedDay.allocation,
                activeOnDaysOff: allocatedDay.activeOnDaysOff,
                isAbsent: allocatedDay.isAbsent,
                isWorking: allocatedDay.isWorking,
                isOff: allocatedDay.isOff,
                absenceType: allocatedDay.absenceType,
                absenceComment: allocatedDay.absenceComment,
                absenceRangeType: allocatedDay.absenceRangeType,
                isAbsenceRequest: allocatedDay.isAbsenceRequest,
                absenceLength: allocatedDay.absenceLength,
                maxAllocation: allocatedDay.expectedHours,
                isNonWorkingDay: !allocatedDay.isWorking || allocatedDay.isAbsent || !!allocatedDay.absenceRangeType,
                isAllocated: !!allocatedDay.allocation
              });
              previousDay = allocatedDay;
            } else {
              previousDay = null;
            }
          }
        });
        this.set('frameArrayCached', framesArray);
        return framesArray;
      }
    })
  });

  _exports.default = _default;
});