define("coffeecup/components/analytics-module/client/duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activeProjects: Ember.computed.reads('client.activeProjects'),
    completedProjects: Ember.computed.reads('client.completedProjects'),
    projectsWithStartDate: Ember.computed.filter('client.projects', function (el) {
      return !!el.get('startDate');
    }),
    averageProjectDuration: Ember.computed('projectsWithStartDate.[]', function () {
      var projectsWithStartDate = this.get('projectsWithStartDate');
      var totalDuration = projectsWithStartDate.reduce(function (prev, el) {
        var completedDate = el.get('completedAt') || moment();
        return prev += completedDate.diff(moment(el.get('startDate')), 'months');
      }, 0);
      return projectsWithStartDate.get('length') > 0 ? Math.round(totalDuration / projectsWithStartDate.get('length') * 100) / 100 : 0;
    }),
    pieChartData: [{
      value: 1
    }, {
      value: 0
    }]
  });

  _exports.default = _default;
});