define("coffeecup/components/planner/project-timeline", ["exports", "coffeecup/components/deferred-row", "coffeecup/utils/date"], function (_exports, _deferredRow, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _deferredRow.default.extend({
    classNames: ['deferred-row-timeline'],
    viewport: Ember.inject.service('planner.viewport'),
    project: null,
    assignments: null,
    editable: null,
    frameArrayCached: [],
    currentUserService: Ember.inject.service('currentUser'),
    showPlannerProjectDuration: Ember.computed.reads('currentUserService.currentUser.uiState.showPlannerProjectDuration'),
    frames: Ember.computed('assignments.@each.{startDateFmt,endDateFmt}', {
      get: function get() {
        if (this.get('disableFrameCalculation')) {
          return this.get('frameArrayCached');
        }

        var assignments = this.get('assignments');
        var viewport = this.get('viewport');
        var startDate = viewport.get('startDateWithMargin');
        var endDate = viewport.get('endDateWithMargin');
        var framesArray = Ember.A();
        var allocatedFrame = null;
        (0, _date.eachDay)(startDate, endDate, function (date) {
          var assignment = assignments.find(function (assgnmt) {
            return assgnmt.isActiveOnDate(date);
          });

          if (assignment) {
            if (allocatedFrame) {
              allocatedFrame.endDate.add(1, 'day');
            } else {
              allocatedFrame = framesArray.pushObject({
                startDate: date.clone(),
                endDate: date.clone()
              });
            }
          } else {
            allocatedFrame = null;
          }
        });
        this.set('frameArrayCached', framesArray);
        return framesArray;
      }
    }),
    actions: {
      createMilestone: function createMilestone(date) {
        this.sendAction('createMilestone', date);
      },
      editMilestone: function editMilestone(model) {
        this.sendAction('editMilestone', model);
      },
      updateMilestone: function updateMilestone(model) {
        var _this = this;

        if (model.overlapsAnySibling()) {
          this.get('notifications').error('plannerAssignmentsMayNotOverlap');
          Ember.run.next(function () {
            model.rollbackAttributes();
          });
          return false;
        }

        return model.save().then(function () {
          _this.get('notifications').success('alert.saved', 'milestone');

          _this.sendAction('afterUpdateMilestone', model);
        }).catch(function (err) {
          // console.error(err);
          model.rollbackAttributes();

          _this.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'milestone');
        });
      }
    }
  });

  _exports.default = _default;
});