define("coffeecup/components/absence-tracking/date-range-picker", ["exports", "coffeecup/components/date-picker", "coffeecup/config/environment"], function (_exports, _datePicker, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var AbsenceDateRangePicker = _datePicker.default.extend({
    previousDatesAllowed: true,
    minStartDate: null,
    maxEndDate: null,
    utcMoment: true,
    options: function options() {
      var formatter = this.get('formatter');

      var options = this._super();

      options.parentEl = '.edit-absence-modal';

      if (!this.get('previousDatesAllowed')) {
        options.minDate = this.get('clock.now');
      }

      if (this.get('maxEndDate')) {
        options.maxDate = this.get('maxEndDate');
      }

      if (this.get('minStartDate')) {
        options.minDate = this.get('minStartDate');
      }

      if (this.get('minStartDate') && this.get('formattedDate').isBefore(this.get('minStartDate'))) {
        options.startDate = this.get('minStartDate');
      } else {
        options.startDate = this.get('formattedDate');
      }

      return options;
    },
    formattedDate: Ember.computed('date', {
      get: function get() {
        return moment.utc(this.get('date'), _environment.default.constants.DATE_PICKER_FORMAT);
      },
      set: function set(key, val) {
        if (!val) val = moment();
        val = moment(val);
        var formatted = val.format(_environment.default.constants.DATE_PICKER_FORMAT);
        this.set('date', formatted);
        return val;
      }
    }),
    formatter: Ember.inject.service('formatter'),
    showNextStep: Ember.observer('minStartDate', function () {
      this.updateDatePicker(); // removed for now. There is a problem when editing a planner assignment.
      // The endDate Datepicker was always triggered
      // @see CC-2350
      //// this.$('.end-date-picker').trigger('click');
    }),
    dateFMT: Ember.computed('date', {
      get: function get() {
        if (!Ember.isEmpty(this.get('date'))) {
          var date = moment.utc(this.get('date'));
          var formatter = this.get('formatter');
          var html = formatter.formatDate(date);
          this.updateDate(this.get('date'));
          return Ember.String.htmlSafe(html);
        } else {
          return '';
        }
      }
    })
  });

  var _default = AbsenceDateRangePicker;
  _exports.default = _default;
});