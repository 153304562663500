define("coffeecup/components/button-confirm", ["exports", "ember-cli-tooltipster/components/tool-tipster"], function (_exports, _toolTipster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _toolTipster.default.extend({
    responsive: Ember.inject.service(),
    isShowingConfirmation: null,
    isIcon: false,
    autoReset: false,
    side: 'left',
    classNames: 'btn-confirm',
    defaultButtonClassNames: 'btn btn-nobg',
    confirmButtonClassNames: 'btn btn-danger',
    content: Ember.computed('isShowingConfirmation', function () {
      if (!this.get('isShowingConfirmation')) return '';
      return this.get('i18n').t('deleteConfirm');
    }),
    actions: {
      handleMouseLeave: function handleMouseLeave() {
        if (this.get('autoReset')) {
          this.set('isShowingConfirmation', false);
        }
      },
      showConfirmation: function showConfirmation() {
        var _this = this;

        this.set('isShowingConfirmation', true);

        if (this.get('responsive').isMobile()) {
          Ember.run.later(function () {
            if (!_this.get('isDestroyed')) {
              _this.set('isShowingConfirmation', false);
            }
          }, 3000);
        }
      },
      confirm: function confirm() {
        this.set('isShowingConfirmation', false);
        this.sendAction('action', this.get('param'));
      }
    }
  });

  _exports.default = _default;
});