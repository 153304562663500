define("coffeecup/components/inline-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    formatter: Ember.inject.service(),
    classNames: ['inline-input'],
    classNameBindings: ['disabled:disabled', 'isTypeDate:date-input'],
    isEditing: false,
    initialValue: null,
    skipSave: false,
    canBeEmpty: true,
    isDatePickerOpen: false,
    datePickerDrops: 'down',
    maxValue: null,
    isTypeCurrency: Ember.computed.equal('type', 'currency'),
    isTypeDateRange: Ember.computed.equal('type', 'dateRange'),
    isTypeDate: Ember.computed.equal('type', 'date'),
    editMode: Ember.computed('isEditing', 'disabled', function () {
      return !this.get('disabled') && this.get('isEditing');
    }),
    click: function click() {
      var _this = this;

      if (!this.get('isEditing')) {
        this.set('initialValue', this.get('value'));
      }

      this.set('isEditing', true);
      Ember.run.next(function () {
        if (_this.$('input') && _this.$('input').length > 0) _this.$('input').focus();

        if (_this.get('isTypeDateRange') || _this.get('isTypeDate')) {
          _this.set('isDatePickerOpen', true);
        }
      });
    },
    formattedDateRange: Ember.computed('value', 'extraValue', function () {
      var formatter = this.get('formatter');
      return formatter.formatDateRange(moment.utc(this.get('value')), moment.utc(this.get('extraValue')));
    }),
    _datePickerClosed: Ember.observer('isDatePickerOpen', function () {
      var _this2 = this;

      Ember.run.next(function () {
        _this2.focusOut();
      });
    }),
    focusOut: function focusOut() {
      if (!this.get('isDatePickerOpen') && !Ember.isEmpty(this.get('value'))) {
        this.set('isEditing', false);
        var skipSave = this.get('skipSave');

        if (this.get('value').toString().trim().length === 0 && !this.get('canBeEmpty')) {
          skipSave = true;
          this.send('cancel');
        }

        if (!skipSave && this.get('initialValue') !== this.get('value')) {
          this.sendAction('onFinish', this.get('value'));
        }

        this.set('skipSave', false);
      }
    },
    keyPress: function keyPress(event) {
      if (event.key === 'Enter') {
        this.$('input').blur();
        this.set('isEditing', false);
      }
    },
    actions: {
      cancel: function cancel() {
        this.set('skipSave', true);
        this.set('isEditing', false);
        this.set('value', this.get('initialValue'));
      },
      triggerUpdate: function triggerUpdate() {
        this.focusOut();
      }
    }
  });

  _exports.default = _default;
});