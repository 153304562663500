define("coffeecup/models/menu", ["exports", "ember-data", "coffeecup/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ROLE_DEFAULT = _environment.default.constants.ROLE_DEFAULT;
  var ROLE_STAFF_PM = _environment.default.constants.ROLE_STAFF_PM;
  var ROLE_PM = _environment.default.constants.ROLE_PM;
  var ROLE_ADMIN = _environment.default.constants.ROLE_ADMIN;
  var ROLE_UNKNOWN = 'UNKNOWN';
  var ROLE_ALL = [ROLE_ADMIN, ROLE_DEFAULT, ROLE_PM, ROLE_STAFF_PM, ROLE_UNKNOWN];

  var Menu = _emberData.default.Model.extend({
    permissions: Ember.inject.service(),
    dashboardService: Ember.inject.service('dashboard'),
    icon: _emberData.default.attr(),
    svgIcon: _emberData.default.attr('string'),
    label: _emberData.default.attr(),

    /**
     * This label is used as an alternative if children are not visible
     */
    singleMenuLabel: _emberData.default.attr(),
    subLabel: _emberData.default.attr(),
    permissionChecks: _emberData.default.attr('array'),
    hideFromPermissions: _emberData.default.attr('array'),
    featureFlags: _emberData.default.attr('array'),
    teamPermissions: _emberData.default.attr('array'),
    order: _emberData.default.attr(),
    url: Ember.computed.alias('id'),
    externalUrl: _emberData.default.attr('string'),
    modelKey: _emberData.default.attr('string'),
    hideDivider: _emberData.default.attr('boolean'),
    bottomAligned: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    submenuShowsProfile: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isFlexGrow: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isTrialCallout: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    customAction: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    firstAlignBottom: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    children: _emberData.default.hasMany('menu', {
      inverse: 'parent'
    }),
    parent: _emberData.default.belongsTo('menu', {
      inverse: 'children'
    }),
    group: _emberData.default.attr('string'),
    hideOnSmall: _emberData.default.attr('boolean'),
    hideFromRoles: _emberData.default.attr('array'),
    hasSubmenuBadge: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    hideBadgeOnSmall: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    visibleChildren: Ember.computed('children.[]', 'permissions.activePermissions', function () {
      this.get('permissions.activePermissions');
      return this.get('children').filter(function (el) {
        return !el.get('hideItem');
      });
    }),
    hasChildren: Ember.computed.gt('visibleChildren.length', 1),
    hasRelatedDashboards: Ember.computed.gt('relatedDashboards.length', 0),
    showSubMenu: Ember.computed.or('hasChildren', 'hasRelatedDashboards'),
    // computed properties
    title: Ember.computed('i18n.locale', 'label', 'singleMenuLabel', 'showSubMenu', {
      get: function get() {
        var label = this.get('label');
        var singleMenuLabel = this.get('singleMenuLabel');

        if (singleMenuLabel && !this.get('showSubMenu')) {
          return this.get('i18n').t(singleMenuLabel);
        } else if (label) {
          return this.get('i18n').t(label);
        }
      }
    }),
    subTitle: Ember.computed('i18n.locale', 'subLabel', {
      get: function get() {
        var subLabel = this.get('subLabel');

        if (subLabel) {
          return this.get('i18n').t(subLabel);
        }
      }
    }),
    relatedDashboards: Ember.computed('modelKey', 'dashboardService.dashboards.[]', function () {
      if (this.get('modelKey')) {
        return this.get('dashboardService.dashboards').sortBy('orderInList');
      }
    }),
    hideItem: Ember.computed('permissionChecks', 'hideFromPermissions', 'featureFlags', 'teamPermissions', 'permissions.activePermissions', 'permissions.activeFeatures', 'permissions.subscription', function () {
      var _this = this;

      this.get('permissions.activePermissions');
      this.get('permissions.activeFeatures');
      var hideItem = false;
      var featureFlags = this.get('featureFlags');

      if (featureFlags) {
        featureFlags.forEach(function (featureFlag) {
          if (!hideItem && featureFlag) {
            hideItem = !_this.get('permissions').get(featureFlag);
          }
        });

        if (hideItem) {
          return hideItem;
        }
      }

      var permissionChecks = this.get('permissionChecks');

      if (permissionChecks) {
        hideItem = true;
        permissionChecks.forEach(function (permissionCheck) {
          if (hideItem && permissionCheck) {
            hideItem = !_this.get('permissions').get(permissionCheck);
          }
        });
      }
      /* team permissions can only expand existing permissions */


      var teamPermissions = this.get('teamPermissions');

      if (teamPermissions) {
        teamPermissions.forEach(function (teamPermission) {
          if (hideItem && teamPermission) {
            hideItem = !_this.get('permissions').get(teamPermission);
          }
        });
      }

      var hideFromPermissions = this.get('hideFromPermissions') || [];
      hideItem = hideItem || hideFromPermissions.any(function (permission) {
        return _this.get("permissions.".concat(permission));
      });
      return hideItem;
    }),
    groupedChildren: Ember.computed('children', {
      get: function get() {
        var _this2 = this;

        var result = [];
        var children = this.get('children').sortBy('order');

        var checkRoles = function checkRoles(previousRoles, currentRoles) {
          var result = [];

          if (previousRoles.length >= currentRoles.length) {
            previousRoles.forEach(function (el) {
              if (currentRoles.indexOf(el) !== -1) {
                result.push(el);
              }
            });
          } else {
            currentRoles.forEach(function (el) {
              if (previousRoles.indexOf(el) !== -1) {
                result.push(el);
              }
            });
          }

          return result;
        };

        children.forEach(function (child) {
          var group = child.get('group');
          var partial = result.findBy('group', group);
          var parts = [];

          if (partial) {
            partial.children.push(child);
            partial.hideFromRoles = checkRoles(partial.hideFromRoles, child.get('hideFromRoles'));
            partial.cssClass = _this2._getCssClassesFromRoles(parts, partial.hideFromRoles).join(' ');
          } else {
            var _children = Ember.A([child]);

            partial = {
              group: group,
              cssClass: _this2._getCssClassesFromRoles(parts, child.get('hideFromRoles')).join(' '),
              hideFromRoles: child.get('hideFromRoles'),
              children: _children
            };
            partial.children.sortBy('order');
            result.push(partial);
          }
        });
        result = result.map(function (el) {
          el.hasVisibleElements = el.children && el.children.any(function (el) {
            return !el.get('hideItem');
          });
          el.hideDivider = el.children && el.children.any(function (el) {
            return el.get('hideDivider');
          });
          return el;
        });
        return result;
      }
    }),
    _getCssClassesFromRoles: function _getCssClassesFromRoles(parts, hideFromRoles) {
      parts = parts.concat(hideFromRoles.map(function (roleLabel) {
        switch (roleLabel) {
          case ROLE_ADMIN:
            return 'hide-if-admin';

          case ROLE_DEFAULT:
            return 'hide-if-default';

          case ROLE_STAFF_PM:
            return 'hide-if-lte-staffpm';

          case ROLE_PM:
            return 'hide-if-lt-pm';

          case ROLE_UNKNOWN:
            return 'hide-if-unknown';

          default:
            return null;
        }
      }));
      return parts;
    },
    cssClass: Ember.computed('url', 'hideFromRoles', 'showSubMenu', {
      get: function get() {
        var parts = []; // add a dasherized value of the label-string

        var css = this.get('label').toString().replace('.', '-');
        parts.push(css.dasherize()); // add classes that hide elememts for certain user roles

        parts = this._getCssClassesFromRoles(parts, this.get('hideFromRoles'));

        if (this.get('showSubMenu')) {
          parts.push('has-children');
        } else {
          parts.push('no-children');
        }

        if (this.get('bottomAligned')) {
          parts.push('bottom-aligned');
        }

        return parts.join(' ');
      }
    }),
    iconCssClass: Ember.computed('icon', {
      get: function get() {
        return 'icomoon-' + this.get('icon');
      }
    }),
    isSettings: Ember.computed('url', {
      get: function get() {
        return this.get('url') === 'account';
      }
    })
  });

  var _default = Menu;
  _exports.default = _default;
});