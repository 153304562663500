define("coffeecup/components/input-percentage", ["exports", "coffeecup/mixins/has-error", "numeral"], function (_exports, _hasError, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_hasError.default, {
    steps: 0.005,
    percentage: 0,
    percentageFormat: '0.00',
    percentageFmt: Ember.computed('percentage', {
      get: function get() {
        return this._formatPercentage();
      },
      set: function set(key, val) {
        var valWithPercentage = this._validatePercentage(val);

        var parsedVal = (0, _numeral.default)(valWithPercentage).value();
        this.set('percentage', parsedVal);
        return val;
      }
    }),
    _formatPercentage: function _formatPercentage() {
      return "".concat((0, _numeral.default)(this.get('percentage') * 100).format(this.percentageFormat), "%");
    },
    _validatePercentage: function _validatePercentage(val) {
      if (val.substring(val.length - 1, val.length) !== '%') {
        val += '%';
      }

      return val;
    },
    actions: {
      add: function add() {
        var newPercentage = this.get('percentage') + this.get('steps');

        if (newPercentage > 1) {
          newPercentage = 1;
        }

        this.set('percentage', newPercentage);

        if (this.sendAction && this.onChange) {
          this.onChange();
        }
      },
      sub: function sub() {
        var newPercentage = this.get('percentage') - this.get('steps');

        if (newPercentage < 0) {
          newPercentage = 0;
        }

        this.set('percentage', newPercentage);

        if (this.sendAction && this.onChange) {
          this.onChange();
        }
      },
      onBlur: function onBlur() {
        var percentage = this.get('percentage');

        if (percentage > 1) {
          this.set('percentage', 1);
        } else if (percentage < 0) {
          this.set('percentage', 0);
        }

        if (this.get('percentageFmt') !== this._formatPercentage()) {
          if (this.sendAction && this.onChange) {
            this.onChange();
          }
        }

        this.set('percentageFmt', this._formatPercentage());
      },
      onFocus: function onFocus() {
        var $input = this.$('input');
        $input.select();
      }
    }
  });

  _exports.default = _default;
});