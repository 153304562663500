define("coffeecup/components/custom-icon-configurator", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dropdown', 'custom-icon-wrapper', 'clickable'],
    customIcon: null,
    initialColor: null,
    initialIcon: null,
    isLeft: false,
    didInsertElement: function didInsertElement() {
      this.set('initialColor', this.get('customIcon.color'));
      this.set('initialIcon', this.get('customIcon.icon'));
    },
    calculateLeftPosition: function calculateLeftPosition(trigger, content) {
      var _trigger$getBoundingC = trigger.getBoundingClientRect(),
          top = _trigger$getBoundingC.top,
          right = _trigger$getBoundingC.right,
          left = _trigger$getBoundingC.left,
          width = _trigger$getBoundingC.width;

      var _content$getBoundingC = content.getBoundingClientRect(),
          contentWidth = _content$getBoundingC.width;

      var style = {
        left: left - contentWidth - 6,
        top: top
      };
      return {
        style: style
      };
    },
    calculateRightPosition: function calculateRightPosition(trigger, content) {
      var _trigger$getBoundingC2 = trigger.getBoundingClientRect(),
          top = _trigger$getBoundingC2.top,
          left = _trigger$getBoundingC2.left,
          width = _trigger$getBoundingC2.width;

      var style = {
        left: left + width + 6,
        top: top
      };
      return {
        style: style
      };
    },
    possibleColors: _environment.default.constants.DEFAULT_COLORS,
    possibleIcons: _environment.default.constants.DEFAULT_ICONS,
    actions: {
      selectColor: function selectColor(color) {
        this.set('customIcon.color', color + 1);
      },
      selectIcon: function selectIcon(icon) {
        this.set('customIcon.icon', icon + 1);
      },
      discardChanges: function discardChanges(afterHook) {
        this.set('customIcon.color', this.get('initialColor'));
        this.set('customIcon.icon', this.get('initialIcon'));

        if (afterHook && typeof afterHook === 'function') {
          afterHook();
        }
      },
      applyChanges: function applyChanges(afterHook) {
        this.setProperties({
          initialIcon: this.get('customIcon.icon'),
          initialColor: this.get('customIcon.color')
        });
        this.sendAction('onApply');

        if (afterHook && typeof afterHook === 'function') {
          afterHook();
        }
      }
    }
  });

  _exports.default = _default;
});