define("coffeecup/components/vacation-list/element", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    vacationEntry: null,
    isEditing: false,
    isEditable: Ember.computed.or('isYearlyVacation', 'isVacation', 'canBeDeleted'),
    canBeDeleted: Ember.computed.or('isVacationBudget'),
    isNegativeQuantity: Ember.computed.lt('vacationEntry.quantity', 0),
    isVacation: Ember.computed.equal('vacationEntry.vacationType', _environment.default.constants.VACATION_TYPE_VACATION_TAKEN),
    isVacationBudget: Ember.computed.equal('vacationEntry.vacationType', _environment.default.constants.VACATION_TYPE_EXTRA_BUDGET),
    isYearlyVacation: Ember.computed.equal('vacationEntry.vacationType', _environment.default.constants.VACATION_TYPE_YEARLY_BUDGET),
    showDivider: Ember.computed.and('hasAdditionalElements', 'isYearlyVacation'),
    hasAdditionalElements: false,
    actions: {
      toggleEditing: function toggleEditing() {
        if (this.get('isEditable') && (this.get('isVacationBudget') || this.get('isYearlyVacation'))) {
          this.set('isEditing', !this.get('isEditing'));
        } else if (this.get('isEditable') && this.get('isVacation')) {
          this.set('vacationEntry.mode', 'update');
          this.sendAction('publishChange', this.get('vacationEntry'));
        }
      },
      cancelEdit: function cancelEdit() {
        this.sendAction('cancelEdit');
      },
      publishChange: function publishChange(param) {
        this.set('isEditing', false);
        this.sendAction('publishChange', param);
      },
      deleteElement: function deleteElement() {
        this.set('vacationEntry.mode', 'delete');
        this.sendAction('publishChange', this.get('vacationEntry'));
      }
    }
  });

  _exports.default = _default;
});