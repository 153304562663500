define("coffeecup/components/button-group-option", ["exports", "ember-cli-tooltipster/components/tool-tipster"], function (_exports, _toolTipster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _toolTipster.default.extend({
    tagName: 'button',
    classNames: ['btn'],
    classNameBindings: ['isActive:active'],
    attributeBindings: ['type'],
    click: function click() {
      this.sendAction('action', this.get('value'));
    },

    /**
     * the type of the button
     */
    type: 'button',

    /**
     * the icon class to be used
     */
    icon: null,

    /**
     * the value of the option
     */
    value: null,

    /**
     * the icon class to be used
     */
    current: null,

    /**
     * the label
     */
    label: null,

    /**
     * the tooltip
     */
    tooltipLabel: null,

    /**
     * generate tooltip
     */
    content: Ember.computed('tooltipLabel', 'label', {
      get: function get() {
        var output = '';
        var i18n = this.get('i18n');
        var tooltip = this.get('tooltipLabel');
        var label = this.get('label');

        if (tooltip == null || tooltip == undefined) {
          output = label;
        } else {
          output = tooltip;
        }

        if (output) return i18n.t(output);
      }
    }),

    /**
     * determines, if the component is rendered in active state
     * @returns {boolean}
     */
    //isActive: Ember.computed.equal('current', 'value'),
    isActive: Ember.computed('current', {
      get: function get() {
        var current = this.get('current');
        var value = this.get('value');
        return !(current === null && value === null) && current == value;
      }
    })
  });

  _exports.default = _default;
});