define("coffeecup/demo-data/dashboard/team-activity-module", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.teamActivityData = void 0;
  var teamActivityData = [{
    user: Ember.Object.create({
      label: 'Lisa Feldmayer',
      department: 'Office Management',
      isImageInitials: true
    }),
    secondsToWork: 0,
    totalDuration: 13.805833333333334,
    progress: 0,
    missingHoursProgress: 0,
    days: [{
      day: (0, _moment.default)('2021-03-08T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 1.9644444444444444,
      isActive: false,
      timeEntries: [{
        id: 1612795,
        user: 37,
        project: 2805,
        task: 64,
        duration: 2700,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612864,
        user: 37,
        project: 14304,
        task: 69,
        duration: 4372,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-09T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 2.0933333333333333,
      isActive: false,
      timeEntries: [{
        id: 1613288,
        user: 37,
        project: 14304,
        task: 69,
        duration: 4836,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613482,
        user: 37,
        project: 2805,
        task: 64,
        duration: 2700,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-10T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0.5,
      isActive: false,
      timeEntries: [{
        id: 1613958,
        user: 37,
        project: 14306,
        task: 764,
        duration: 1800,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 5.222777777777778,
      isActive: false,
      timeEntries: [{
        id: 1614582,
        user: 37,
        project: 16858,
        task: 65,
        duration: 11386,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614602,
        user: 37,
        project: 14304,
        task: 69,
        duration: 3815,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614988,
        user: 37,
        project: 159,
        task: 64,
        duration: 3601,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 2.3733333333333335,
      isActive: false,
      timeEntries: [{
        id: 1615302,
        user: 37,
        project: 2805,
        task: 65,
        duration: 5044,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615346,
        user: 37,
        project: 17218,
        task: 64,
        duration: 1800,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615348,
        user: 37,
        project: 14306,
        task: 64,
        duration: 1700,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22830,
        user: 37,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-13T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0.9019444444444444,
      isActive: false,
      timeEntries: [{
        id: 1615674,
        user: 37,
        project: 14304,
        task: 671,
        duration: 3247,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-14T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0.75,
      isActive: false,
      timeEntries: [{
        id: 1615733,
        user: 37,
        project: 16858,
        task: 65,
        duration: 2700,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: []
    }]
  }, {
    user: Ember.Object.create({
      label: 'Iris Kouvlakis',
      department: 'Development',
      isImageInitials: true
    }),
    secondsToWork: 32,
    totalDuration: 34.65972222222222,
    progress: 108.31163194444444,
    missingHoursProgress: 0,
    days: [{
      day: (0, _moment.default)('2021-03-08T00:00:00.000Z'),
      isValid: false,
      hasToWork: true,
      totalDuration: 7.945555555555556,
      isActive: true,
      timeEntries: [{
        id: 1612711,
        user: 105,
        project: 16858,
        task: 1118,
        duration: 15104,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612975,
        user: 105,
        project: 17595,
        task: 61,
        duration: 3600,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612976,
        user: 105,
        project: 17717,
        task: 61,
        duration: 5400,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612977,
        user: 105,
        project: 159,
        task: 66,
        duration: 4500,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-09T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 8.41388888888889,
      isActive: true,
      timeEntries: [{
        id: 1613526,
        user: 105,
        project: 159,
        task: 66,
        duration: 3600,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613527,
        user: 105,
        project: 16858,
        task: 1118,
        duration: 2460,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613528,
        user: 105,
        project: 17717,
        task: 61,
        duration: 8400,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613559,
        user: 105,
        project: 159,
        task: 66,
        duration: 3223,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613632,
        user: 105,
        project: 17717,
        task: 2170,
        duration: 3600,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613633,
        user: 105,
        project: 17717,
        task: 64,
        duration: 9007,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22821,
        user: 105,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-09T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-09T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-10T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 3.250277777777778,
      isActive: false,
      timeEntries: [{
        id: 1613894,
        user: 105,
        project: 16858,
        task: 1118,
        duration: 7201,
        hasComment: false,
        trackedOnDay: true
      }, {
        id: 1613895,
        user: 105,
        project: 159,
        task: 66,
        duration: 900,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613896,
        user: 105,
        project: 17717,
        task: 2170,
        duration: 3600,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22939,
        user: 105,
        absenceType: {
          colorCode: '#CA3F50',
          label: 'Krankheit',
          status: 1,
          baseType: 2,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: false,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 2,
          icon: 2
        },
        startDate: (0, _moment.default)('2021-03-10T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-10T00:00:00.000Z'),
        workHoursExpected: false,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 8.033333333333333,
      isActive: true,
      timeEntries: [{
        id: 1614526,
        user: 105,
        project: 16858,
        task: 1118,
        duration: 14520,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614589,
        user: 105,
        project: 159,
        task: 66,
        duration: 900,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615035,
        user: 105,
        project: 159,
        task: 66,
        duration: 3600,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615037,
        user: 105,
        project: 14306,
        task: 66,
        duration: 4500,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615038,
        user: 105,
        project: 17717,
        task: 2170,
        duration: 3600,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615040,
        user: 105,
        project: 159,
        task: 66,
        duration: 1800,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
      isValid: false,
      hasToWork: true,
      totalDuration: 7.016666666666667,
      isActive: true,
      timeEntries: [{
        id: 1615209,
        user: 105,
        project: 16858,
        task: 1118,
        duration: 8160,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615232,
        user: 105,
        project: 17717,
        task: 2170,
        duration: 3600,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615570,
        user: 105,
        project: 159,
        task: 66,
        duration: 2700,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615571,
        user: 105,
        project: 14306,
        task: 66,
        duration: 1800,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615572,
        user: 105,
        project: 14306,
        task: 61,
        duration: 9000,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22828,
        user: 105,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-13T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-14T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }]
  }, {
    user: Ember.Object.create({
      label: 'Johannes Stich',
      department: 'Development',
      isImageInitials: true
    }),
    secondsToWork: 40,
    totalDuration: 41.26722222222222,
    progress: 103.16805555555557,
    missingHoursProgress: 0,
    days: [{
      day: (0, _moment.default)('2021-03-08T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 8.516111111111112,
      isActive: true,
      timeEntries: [{
        id: 1612685,
        user: 320,
        project: 2805,
        task: 61,
        duration: 23725,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612762,
        user: 320,
        project: 159,
        task: 66,
        duration: 813,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612834,
        user: 320,
        project: 159,
        task: 66,
        duration: 1496,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612874,
        user: 320,
        project: 159,
        task: 66,
        duration: 485,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612963,
        user: 320,
        project: 2805,
        task: 61,
        duration: 4139,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22756,
        user: 320,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-08T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-08T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-09T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 8.365555555555556,
      isActive: true,
      timeEntries: [{
        id: 1613243,
        user: 320,
        project: 2805,
        task: 61,
        duration: 13801,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613318,
        user: 320,
        project: 159,
        task: 66,
        duration: 629,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613349,
        user: 320,
        project: 159,
        task: 66,
        duration: 2432,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613455,
        user: 320,
        project: 2805,
        task: 61,
        duration: 13254,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-10T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 8.977222222222222,
      isActive: true,
      timeEntries: [{
        id: 1613763,
        user: 320,
        project: 2805,
        task: 61,
        duration: 24315,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613857,
        user: 320,
        project: 159,
        task: 66,
        duration: 615,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614057,
        user: 320,
        project: 159,
        task: 66,
        duration: 2460,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614114,
        user: 320,
        project: 159,
        task: 66,
        duration: 4928,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 8.215833333333334,
      isActive: true,
      timeEntries: [{
        id: 1614471,
        user: 320,
        project: 2805,
        task: 61,
        duration: 12925,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614554,
        user: 320,
        project: 159,
        task: 66,
        duration: 546,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614650,
        user: 320,
        project: 2805,
        task: 61,
        duration: 3706,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614667,
        user: 320,
        project: 159,
        task: 66,
        duration: 2229,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614893,
        user: 320,
        project: 2805,
        task: 2170,
        duration: 5251,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614962,
        user: 320,
        project: 17203,
        task: 2170,
        duration: 4920,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22757,
        user: 320,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
      isValid: false,
      hasToWork: true,
      totalDuration: 7.1925,
      isActive: true,
      timeEntries: [{
        id: 1615185,
        user: 320,
        project: 17203,
        task: 2170,
        duration: 3180,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615198,
        user: 320,
        project: 17203,
        task: 61,
        duration: 16930,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615257,
        user: 320,
        project: 159,
        task: 66,
        duration: 750,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615268,
        user: 320,
        project: 159,
        task: 66,
        duration: 1332,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615419,
        user: 320,
        project: 159,
        task: 66,
        duration: 1860,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615455,
        user: 320,
        project: 159,
        task: 66,
        duration: 1841,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22757,
        user: 320,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-13T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-14T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }]
  }, {
    user: Ember.Object.create({
      label: 'Natasha Ramirez',
      department: 'Head of Concept',
      isImageInitials: true
    }),
    secondsToWork: 40,
    totalDuration: 40.26416666666667,
    progress: 100.66041666666668,
    missingHoursProgress: 0,
    days: [{
      day: (0, _moment.default)('2021-03-08T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 9.114722222222222,
      isActive: true,
      timeEntries: [{
        id: 1612725,
        user: 12660,
        project: 2805,
        task: 61,
        duration: 29573,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612772,
        user: 12660,
        project: 2805,
        task: 61,
        duration: 1440,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612990,
        user: 12660,
        project: 2805,
        task: 61,
        duration: 1800,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22768,
        user: 12660,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-08T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-08T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-09T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 8.300277777777778,
      isActive: true,
      timeEntries: [{
        id: 1613296,
        user: 12660,
        project: 2805,
        task: 61,
        duration: 27961,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613481,
        user: 12660,
        project: 159,
        task: 66,
        duration: 720,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613678,
        user: 12660,
        project: 2805,
        task: 61,
        duration: 1200,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-10T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 8.158888888888889,
      isActive: true,
      timeEntries: [{
        id: 1613837,
        user: 12660,
        project: 2805,
        task: 61,
        duration: 26372,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614078,
        user: 12660,
        project: 159,
        task: 66,
        duration: 3000,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22919,
        user: 12660,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-10T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-10T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 9.002222222222223,
      isActive: true,
      timeEntries: [{
        id: 1614533,
        user: 12660,
        project: 2805,
        task: 61,
        duration: 31808,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614592,
        user: 12660,
        project: 159,
        task: 66,
        duration: 600,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22783,
        user: 12660,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
      isValid: false,
      hasToWork: true,
      totalDuration: 5.688055555555556,
      isActive: true,
      timeEntries: [{
        id: 1615240,
        user: 12660,
        project: 2805,
        task: 61,
        duration: 18069,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615265,
        user: 12660,
        project: 159,
        task: 66,
        duration: 600,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615418,
        user: 12660,
        project: 2805,
        task: 61,
        duration: 1808,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22783,
        user: 12660,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-13T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-14T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }]
  }, {
    user: Ember.Object.create({
      label: 'Robert Cunningham',
      department: 'Development',
      isImageInitials: true
    }),
    secondsToWork: 40,
    totalDuration: 40.06194444444444,
    progress: 100.1548611111111,
    missingHoursProgress: 0,
    days: [{
      day: (0, _moment.default)('2021-03-08T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 8.259722222222223,
      isActive: true,
      timeEntries: [{
        id: 1612736,
        user: 12666,
        project: 17905,
        task: 1118,
        duration: 25415,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612760,
        user: 12666,
        project: 159,
        task: 66,
        duration: 1469,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612835,
        user: 12666,
        project: 2805,
        task: 61,
        duration: 2131,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613144,
        user: 12666,
        project: 17905,
        task: 1118,
        duration: 720,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22765,
        user: 12666,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-08T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-08T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-09T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 8.155833333333334,
      isActive: true,
      timeEntries: [{
        id: 1613236,
        user: 12666,
        project: 17905,
        task: 1118,
        duration: 16980,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613311,
        user: 12666,
        project: 159,
        task: 66,
        duration: 1028,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613541,
        user: 12666,
        project: 2805,
        task: 61,
        duration: 11353,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22913,
        user: 12666,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-09T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-09T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: true
      }]
    }, {
      day: (0, _moment.default)('2021-03-10T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 9.963611111111112,
      isActive: true,
      timeEntries: [{
        id: 1613764,
        user: 12666,
        project: 2805,
        task: 61,
        duration: 13500,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613856,
        user: 12666,
        project: 159,
        task: 66,
        duration: 1500,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613962,
        user: 12666,
        project: 2805,
        task: 61,
        duration: 6840,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614002,
        user: 12666,
        project: 17905,
        task: 1118,
        duration: 5727,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614059,
        user: 12666,
        project: 159,
        task: 66,
        duration: 7592,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614273,
        user: 12666,
        project: 2805,
        task: 61,
        duration: 710,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 8.035555555555556,
      isActive: true,
      timeEntries: [{
        id: 1614493,
        user: 12666,
        project: 2805,
        task: 61,
        duration: 19500,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614498,
        user: 12666,
        project: 17905,
        task: 1118,
        duration: 6145,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614548,
        user: 12666,
        project: 159,
        task: 66,
        duration: 720,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615056,
        user: 12666,
        project: 17905,
        task: 1118,
        duration: 2563,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22782,
        user: 12666,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
      isValid: false,
      hasToWork: true,
      totalDuration: 5.647222222222222,
      isActive: true,
      timeEntries: [{
        id: 1615217,
        user: 12666,
        project: 17905,
        task: 1118,
        duration: 14019,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615256,
        user: 12666,
        project: 159,
        task: 66,
        duration: 4560,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615421,
        user: 12666,
        project: 2805,
        task: 61,
        duration: 1751,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22782,
        user: 12666,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-13T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-14T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }]
  }, {
    user: Ember.Object.create({
      label: 'Clairé Assange',
      department: 'Marketing Consultant',
      isImageInitials: true
    }),
    secondsToWork: 0,
    totalDuration: 0,
    progress: 0,
    missingHoursProgress: 0,
    days: [{
      day: (0, _moment.default)('2021-03-08T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-09T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-10T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-13T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-14T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }]
  }, {
    user: Ember.Object.create({
      label: 'Joshua Smith',
      department: 'UX Design',
      isImageInitials: true
    }),
    secondsToWork: 40,
    totalDuration: 38.9,
    progress: 97.25,
    missingHoursProgress: 2.7500000000000036,
    days: [{
      day: (0, _moment.default)('2021-03-08T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 9.25,
      isActive: true,
      timeEntries: [{
        id: 1613333,
        user: 12743,
        project: 17595,
        task: 61,
        duration: 900,
        hasComment: true,
        trackedOnDay: false
      }, {
        id: 1613335,
        user: 12743,
        project: 17717,
        task: 61,
        duration: 32400,
        hasComment: true,
        trackedOnDay: false
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-09T00:00:00.000Z'),
      isValid: false,
      hasToWork: true,
      totalDuration: 6.166666666666667,
      isActive: true,
      timeEntries: [{
        id: 1613465,
        user: 12743,
        project: 17434,
        task: 1118,
        duration: 19500,
        hasComment: false,
        trackedOnDay: true
      }, {
        id: 1613467,
        user: 12743,
        project: 17717,
        task: 61,
        duration: 2700,
        hasComment: false,
        trackedOnDay: true
      }],
      absences: [{
        id: 22813,
        user: 12743,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-09T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-10T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-10T00:00:00.000Z'),
      isValid: false,
      hasToWork: true,
      totalDuration: 5.716666666666667,
      isActive: true,
      timeEntries: [{
        id: 1614338,
        user: 12743,
        project: 159,
        task: 1789,
        duration: 10800,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614339,
        user: 12743,
        project: 17434,
        task: 1118,
        duration: 9780,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22813,
        user: 12743,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-09T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-10T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
      isValid: false,
      hasToWork: true,
      totalDuration: 6.75,
      isActive: true,
      timeEntries: [{
        id: 1615296,
        user: 12743,
        project: 17434,
        task: 1118,
        duration: 24300,
        hasComment: true,
        trackedOnDay: false
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 11.016666666666667,
      isActive: true,
      timeEntries: [{
        id: 1615288,
        user: 12743,
        project: 17434,
        task: 1118,
        duration: 37260,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615297,
        user: 12743,
        project: 17905,
        task: 1118,
        duration: 2400,
        hasComment: false,
        trackedOnDay: true
      }],
      absences: [{
        id: 22814,
        user: 12743,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-13T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-14T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }]
  }, {
    user: Ember.Object.create({
      label: 'Niko Reishofer',
      department: 'Development',
      isImageInitials: true
    }),
    secondsToWork: 40,
    totalDuration: 44.403333333333336,
    progress: 111.00833333333335,
    missingHoursProgress: 0,
    days: [{
      day: (0, _moment.default)('2021-03-08T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 8.75,
      isActive: true,
      timeEntries: [{
        id: 1612900,
        user: 12744,
        project: 17581,
        task: 720,
        duration: 2700,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612901,
        user: 12744,
        project: 17581,
        task: 720,
        duration: 8100,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612902,
        user: 12744,
        project: 2805,
        task: 2170,
        duration: 2700,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612903,
        user: 12744,
        project: 159,
        task: 69,
        duration: 3600,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612988,
        user: 12744,
        project: 17203,
        task: 2170,
        duration: 11700,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613021,
        user: 12744,
        project: 159,
        task: 88,
        duration: 2700,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-09T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 9.066666666666666,
      isActive: true,
      timeEntries: [{
        id: 1613314,
        user: 12744,
        project: 159,
        task: 88,
        duration: 4500,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613391,
        user: 12744,
        project: 17581,
        task: 720,
        duration: 5700,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613392,
        user: 12744,
        project: 17717,
        task: 2170,
        duration: 2700,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613596,
        user: 12744,
        project: 16817,
        task: 2170,
        duration: 7200,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613597,
        user: 12744,
        project: 2805,
        task: 2170,
        duration: 2700,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613598,
        user: 12744,
        project: 159,
        task: 69,
        duration: 1800,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613600,
        user: 12744,
        project: 2805,
        task: 2170,
        duration: 8040,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-10T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 8.386666666666667,
      isActive: true,
      timeEntries: [{
        id: 1613874,
        user: 12744,
        project: 159,
        task: 66,
        duration: 6240,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613946,
        user: 12744,
        project: 2805,
        task: 2170,
        duration: 11052,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614055,
        user: 12744,
        project: 159,
        task: 1789,
        duration: 2700,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614274,
        user: 12744,
        project: 159,
        task: 66,
        duration: 8700,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614549,
        user: 12744,
        project: 16817,
        task: 2170,
        duration: 1500,
        hasComment: true,
        trackedOnDay: false
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 9.333333333333334,
      isActive: true,
      timeEntries: [{
        id: 1614543,
        user: 12744,
        project: 159,
        task: 66,
        duration: 1800,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614546,
        user: 12744,
        project: 2805,
        task: 2170,
        duration: 11700,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615022,
        user: 12744,
        project: 2805,
        task: 163,
        duration: 1800,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615023,
        user: 12744,
        project: 159,
        task: 66,
        duration: 4500,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615387,
        user: 12744,
        project: 159,
        task: 788,
        duration: 1800,
        hasComment: true,
        trackedOnDay: false
      }, {
        id: 1615390,
        user: 12744,
        project: 159,
        task: 66,
        duration: 4020,
        hasComment: true,
        trackedOnDay: false
      }, {
        id: 1615393,
        user: 12744,
        project: 16817,
        task: 64,
        duration: 7980,
        hasComment: true,
        trackedOnDay: false
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 8.866666666666667,
      isActive: true,
      timeEntries: [{
        id: 1615383,
        user: 12744,
        project: 159,
        task: 66,
        duration: 3420,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615385,
        user: 12744,
        project: 2805,
        task: 2170,
        duration: 7200,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615386,
        user: 12744,
        project: 159,
        task: 640,
        duration: 2940,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615396,
        user: 12744,
        project: 2805,
        task: 163,
        duration: 2700,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615459,
        user: 12744,
        project: 159,
        task: 66,
        duration: 3480,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615634,
        user: 12744,
        project: 17203,
        task: 455,
        duration: 12180,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22829,
        user: 12744,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-13T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-14T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }]
  }, {
    user: Ember.Object.create({
      label: 'Josef Ebel',
      department: 'Team Assistant',
      isImageInitials: true
    }),
    secondsToWork: 15,
    totalDuration: 15.030277777777778,
    progress: 100.20185185185186,
    missingHoursProgress: 0,
    days: [{
      day: (0, _moment.default)('2021-03-08T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 5.045833333333333,
      isActive: true,
      timeEntries: [{
        id: 1612748,
        user: 12781,
        project: 159,
        task: 88,
        duration: 10440,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612770,
        user: 12781,
        project: 159,
        task: 66,
        duration: 902,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612781,
        user: 12781,
        project: 159,
        task: 88,
        duration: 915,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612782,
        user: 12781,
        project: 14306,
        task: 66,
        duration: 2308,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1612899,
        user: 12781,
        project: 14306,
        task: 671,
        duration: 3600,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-09T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-10T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 5.0569444444444445,
      isActive: true,
      timeEntries: [{
        id: 1613801,
        user: 12781,
        project: 159,
        task: 88,
        duration: 4140,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613802,
        user: 12781,
        project: 14306,
        task: 671,
        duration: 9576,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613803,
        user: 12781,
        project: 14306,
        task: 671,
        duration: 720,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613999,
        user: 12781,
        project: 14306,
        task: 671,
        duration: 3769,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22749,
        user: 12781,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-10T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-10T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
      isValid: false,
      hasToWork: true,
      totalDuration: 4.9275,
      isActive: true,
      timeEntries: [{
        id: 1614559,
        user: 12781,
        project: 159,
        task: 88,
        duration: 5962,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614565,
        user: 12781,
        project: 14306,
        task: 66,
        duration: 3600,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614567,
        user: 12781,
        project: 14306,
        task: 66,
        duration: 1800,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614569,
        user: 12781,
        project: 14304,
        task: 492,
        duration: 900,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614681,
        user: 12781,
        project: 14306,
        task: 671,
        duration: 5477,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22962,
        user: 12781,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-13T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-14T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }]
  }, {
    user: Ember.Object.create({
      label: 'Alexandra Huber',
      department: null,
      isImageInitials: true
    }),
    secondsToWork: 32,
    totalDuration: 32.1775,
    progress: 100.5546875,
    missingHoursProgress: 0,
    days: [{
      day: (0, _moment.default)('2021-03-08T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: [{
        id: 22769,
        user: 12814,
        absenceType: {
          colorCode: '#0388C9',
          label: 'Urlaub',
          status: 1,
          baseType: 1,
          canBeRequested: true,
          defaultCountsAsVacation: true,
          defaultWorkHoursExpected: false,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 1,
          icon: 1
        },
        startDate: (0, _moment.default)('2021-03-08T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-08T00:00:00.000Z'),
        workHoursExpected: false,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-09T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 8.177222222222222,
      isActive: true,
      timeEntries: [{
        id: 1613323,
        user: 12814,
        project: 17717,
        task: 61,
        duration: 28838,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613324,
        user: 12814,
        project: 159,
        task: 66,
        duration: 600,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-10T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 8,
      isActive: true,
      timeEntries: [{
        id: 1613880,
        user: 12814,
        project: 159,
        task: 66,
        duration: 900,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1613881,
        user: 12814,
        project: 17717,
        task: 61,
        duration: 22500,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614079,
        user: 12814,
        project: 159,
        task: 66,
        duration: 1800,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614223,
        user: 12814,
        project: 159,
        task: 66,
        duration: 3600,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 8.000277777777777,
      isActive: true,
      timeEntries: [{
        id: 1614833,
        user: 12814,
        project: 159,
        task: 66,
        duration: 900,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614834,
        user: 12814,
        project: 159,
        task: 66,
        duration: 900,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1614835,
        user: 12814,
        project: 17717,
        task: 61,
        duration: 27001,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22784,
        user: 12814,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
      isValid: true,
      hasToWork: true,
      totalDuration: 8,
      isActive: true,
      timeEntries: [{
        id: 1615546,
        user: 12814,
        project: 159,
        task: 66,
        duration: 900,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615547,
        user: 12814,
        project: 159,
        task: 66,
        duration: 1800,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615548,
        user: 12814,
        project: 17717,
        task: 61,
        duration: 10800,
        hasComment: true,
        trackedOnDay: true
      }, {
        id: 1615549,
        user: 12814,
        project: 17945,
        task: 1118,
        duration: 15300,
        hasComment: true,
        trackedOnDay: true
      }],
      absences: [{
        id: 22784,
        user: 12814,
        absenceType: {
          colorCode: '#34C391',
          label: 'Home Office',
          status: 1,
          baseType: null,
          canBeRequested: true,
          defaultCountsAsVacation: false,
          defaultWorkHoursExpected: true,
          detailsVisibleToOtherUsers: true,
          defaultRemunerationActive: true,
          defaultReducesOvertime: false,
          color: 3,
          icon: 7
        },
        startDate: (0, _moment.default)('2021-03-11T00:00:00.000Z'),
        endDate: (0, _moment.default)('2021-03-12T00:00:00.000Z'),
        workHoursExpected: true,
        isHalfDay: false
      }]
    }, {
      day: (0, _moment.default)('2021-03-13T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }, {
      day: (0, _moment.default)('2021-03-14T00:00:00.000Z'),
      isValid: true,
      hasToWork: false,
      totalDuration: 0,
      isActive: false,
      absences: []
    }]
  }];
  _exports.teamActivityData = teamActivityData;
});