define("coffeecup/components/t-split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend((_EmberComponent$exte = {
    value: null,
    splitValue: '%%split%%',
    firstPart: Ember.computed('value', 'splitValue', function () {
      var value = this.get('value');
      var splitValue = this.get('splitValue');
      if (!value || !splitValue) return '';
      return this.get('value').split(splitValue)[0];
    })
  }, _defineProperty(_EmberComponent$exte, "firstPart", Ember.computed('value', 'splitValue', function () {
    var value = this.get('value').toString();
    var splitValue = this.get('splitValue');
    if (!value || !splitValue) return '';
    return value.split(splitValue)[0];
  })), _defineProperty(_EmberComponent$exte, "secondPart", Ember.computed('value', 'splitValue', function () {
    var value = this.get('value').toString();
    var splitValue = this.get('splitValue');
    if (!value || !splitValue) return '';
    return value.split(splitValue)[1];
  })), _EmberComponent$exte));

  _exports.default = _default;
});