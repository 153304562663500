define("coffeecup/components/date-range-picker", ["exports", "coffeecup/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    drpObj: null,
    size: 'large',
    isSizeLarge: Ember.computed.equal('size', 'large'),
    isSizeSmall: Ember.computed.equal('size', 'small'),
    formatter: Ember.inject.service('formatter'),
    classNames: ['data-range-picker-container'],
    classNameBindings: ['isSizeSmall:small', 'isSizeLarge:large'],
    attributeBindings: ['start', 'end'],

    /**
     * determines, whether the pagination is shown
     */
    showPagination: false,

    /**
     * the start date
     */
    start: undefined,
    startDate: Ember.computed('start', {
      get: function get() {
        return (0, _moment.default)(this.get('start'), _environment.default.constants.DATE_PICKER_FORMAT);
      },
      set: function set(key, val) {
        if (!val) val = (0, _moment.default)();
        var formatted = val.format(_environment.default.constants.DATE_PICKER_FORMAT);
        this.set('start', formatted);
        return val;
      }
    }),

    /**
     * the end date
     */
    end: undefined,
    endDate: Ember.computed('end', {
      get: function get() {
        return (0, _moment.default)(this.get('end'), _environment.default.constants.DATE_PICKER_FORMAT);
      },
      set: function set(key, val) {
        if (!val) val = (0, _moment.default)();
        var formatted = val.format(_environment.default.constants.DATE_PICKER_FORMAT);
        this.set('end', formatted);
        return val;
      }
    }),

    /**
     * determines, whether the prev button is enabled
     */
    prevDisabled: Ember.computed('start', 'boundaryStart', function () {
      var start = this.get('startDate');
      var boundaryStart = this.get('boundaryStart');

      if (typeof boundaryStart === 'undefined') {
        return false;
      }

      return !start.isAfter((0, _moment.default)(boundaryStart).endOf('day'));
    }),

    /**
     * determines, whether the next button is enabled
     */
    nextDisabled: Ember.computed('end', 'boundaryEnd', function () {
      var end = this.get('endDate');
      var boundaryEnd = this.get('boundaryEnd');

      if (typeof boundaryEnd === 'undefined') {
        return false;
      }

      return !end.isBefore((0, _moment.default)(boundaryEnd).startOf('day'));
    }),

    /**
     * the earliest allowed start date
     */
    boundaryStart: undefined,

    /**
     * the latest allowed end date
     */
    boundaryEnd: undefined,

    /**
     * boolean
     */
    timePicker: false,
    disabled: false,
    rangeType: 'thisMonth',
    rangeTypeObserver: Ember.observer('start', 'end', function () {
      var ranges = this.get('ranges');
      var start = this.get('start');
      var end = this.get('end');
      var selectedRangeName;

      for (var rangeName in ranges) {
        var range = ranges[rangeName];

        if (range[0] && range[0].isSame(start, 'day') && range[1] && range[1].isSame(end, 'day')) {
          selectedRangeName = range[2];
        }
      }

      this.set('rangeType', selectedRangeName);

      if (this.rangeTypeChanged) {
        this.rangeTypeChanged(selectedRangeName);
      }
    }),

    /**
     * Text describing the time interval to be displayed on the button
     * @return SafeString html
     */
    intervalTitle: Ember.computed('start', 'end', 'rangeType', function () {
      var formatter = this.get('formatter');

      if (!Ember.isEmpty(this.get('start')) && !Ember.isEmpty(this.get('end'))) {
        var title = '';
        var rangeType = this.get('rangeType');

        if (rangeType) {
          title = this.get('i18n').t(rangeType);
        } else {
          var startDate = this.get('startDate');
          var endDate = this.get('endDate');

          var interval = formatter._detectInterval(startDate, endDate);

          if (formatter._isIntervalRange(startDate, endDate, interval)) {
            switch (interval) {
              case 'day':
                var today = this.get('clock.now');
                var tomorrow = today.clone().add(1, 'days');
                var yesterday = today.clone().subtract(1, 'days');

                if (startDate.isSame(today, 'day')) {
                  title += this.get('i18n').t('today');
                } else if (startDate.isSame(tomorrow, 'day')) {
                  title += this.get('i18n').t('tomorrow');
                } else if (startDate.isSame(yesterday, 'day')) {
                  title += this.get('i18n').t('yesterday');
                } else {
                  title += this.get('i18n').t(interval);
                }

                break;

              case 'week':
                title += this.get('i18n').t(interval);
                title += ' ' + startDate.week();
                break;

              case 'month':
                title += this.get('i18n').t(interval);
                break;

              case 'quarter':
                title += this.get('i18n').t(interval);
                title += ' ' + startDate.quarter();
                break;

              case 'year':
                title += this.get('i18n').t(interval);
                break;
            }
          } else {
            title += this.get('i18n').t('dateRange');
          }
        }

        return title;
      }

      return '';
    }),
    _openDatePicker: Ember.observer('isOpen', function () {
      if (this.get('disabled')) {
        return this.set('isOpen', false);
      }

      if (this.get('isOpen')) {
        this.get('drpObj').show();
      }
    }),

    /**
     * Date range formatted to be displayed on the button
     * @return SafeString html
     */
    formattedDateRange: Ember.computed('start', 'end', function () {
      var formatter = this.get('formatter');
      return formatter.formatDateRange(this.get('startDate'), this.get('endDate'));
    }),
    isOpen: false,
    drops: 'down',
    opens: 'center',
    separator: ' - ',
    singleDatePicker: false,
    icon: null,
    placeholder: null,
    buttonClasses: [],
    applyClass: null,
    cancelClass: null,
    ranges: Ember.computed({
      get: function get() {
        var ranges = {};
        ranges[this.get('i18n').t('today')] = [this.get('clock.now'), this.get('clock.now'), 'today'];
        ranges[this.get('i18n').t('thisWeek')] = [this.get('clock.now').startOf('isoWeek'), this.get('clock.now').endOf('isoWeek'), 'thisWeek'];
        ranges[this.get('i18n').t('lastWeek')] = [this.get('clock.now').subtract(1, 'week').startOf('isoWeek'), this.get('clock.now').subtract(1, 'week').endOf('isoWeek'), 'lastWeek'];
        ranges[this.get('i18n').t('thisMonth')] = [this.get('clock.now').startOf('month'), this.get('clock.now').endOf('month'), 'thisMonth'];
        var startOfLastMonth = this.get('clock.now').subtract(1, 'month').startOf('month');
        ranges[this.get('i18n').t('lastMonth')] = [startOfLastMonth, startOfLastMonth.clone().endOf('month'), 'lastMonth'];
        ranges[this.get('i18n').t('thisQuarter')] = [this.get('clock.now').startOf('quarter'), this.get('clock.now').endOf('quarter'), 'thisQuarter'];
        ranges[this.get('i18n').t('thisYear')] = [this.get('clock.now').startOf('year'), this.get('clock.now').endOf('year'), 'thisYear'];
        var startOfLastYear = this.get('clock.now').subtract(1, 'year').startOf('year');
        ranges[this.get('i18n').t('lastYear')] = [startOfLastYear, startOfLastYear.clone().endOf('year'), 'lastYear'];
        return ranges;
      }
    }),
    //Init the dropdown when the component is added to the DOM
    didInsertElement: function didInsertElement() {
      this._initDateRangePicker();

      this.rangeTypeObserver();
    },
    options: function options() {
      var i18n = this.get('i18n');
      var formatter = this.get('formatter');
      return {
        parentEl: '#date-picker-container',
        locale: {
          cancelLabel: i18n.t('cancel').toString(),
          applyLabel: i18n.t('apply').toString(),
          fromLabel: i18n.t('from').toString(),
          toLabel: i18n.t('to').toString(),
          customRangeLabel: i18n.t('customRange').toString()
        },
        format: formatter.getDateFormat(),
        startDate: (0, _moment.default)(this.get('start')).format(formatter.getDateFormat()),
        endDate: (0, _moment.default)(this.get('end')).format(formatter.getDateFormat()),
        timePicker: this.get('timePicker'),
        ranges: this.get('ranges'),
        buttonClasses: this.get('buttonClasses'),
        applyClass: this.get('applyClass'),
        cancelClass: this.get('cancelClass'),
        separator: this.get('separator'),
        singleDatePicker: this.get('singleDatePicker'),
        drops: this.get('drops'),
        opens: this.get('opens')
      };
    },
    _initDateRangePicker: Ember.observer('disabled', function () {
      var _this = this;

      if (this.get('disabled')) return;

      if (this.get('drpObj')) {
        this.get('drpObj').remove();
      }

      var input = this.$('.date-range-picker').daterangepicker(this.options());
      input.on('apply.daterangepicker', function (ev, picker) {
        _this.set('start', picker.startDate.format(_environment.default.constants.DATE_PICKER_FORMAT));

        _this.set('end', picker.endDate.format(_environment.default.constants.DATE_PICKER_FORMAT));

        if (_this.action) {
          _this.action();
        }
      });
      input.on('hide.daterangepicker', function (ev, picker) {
        _this.set('isOpen', false);
      });
      input.on('show.daterangepicker', function (ev, picker) {
        picker.setStartDate(_this.get('startDate'));
        picker.setEndDate(_this.get('endDate'));

        _this.set('isOpen', true);
      }); // save a reference to the date picker-obj

      this.set('drpObj', input.data('daterangepicker'));
      return input;
    }),
    //Remove the hidden dropdown when this component is destroyed
    willDestroy: function willDestroy() {
      if (this.get('disabled')) return;
      this.get('drpObj').remove();
    },
    actions: {
      /**
       * jumps to the next date-range directly after or before the current range
       * @param direction 'next' or 'prev'
       */
      jump: function jump(direction) {
        var formatter = this.get('formatter');
        var d = direction === 'next' ? 1 : direction === 'prev' ? -1 : 0;
        var start = this.get('startDate');
        var end = this.get('endDate');
        var diff = end.diff(start, 'd') + 1;

        var interval = formatter._detectInterval(start, end);

        var newStart, newEnd;

        if (typeof interval === 'undefined') {
          newStart = start.add(diff * d, 'd').startOf('day');
          newEnd = end.add(diff * d, 'd').endOf('day');
        } else {
          newStart = start.add(d, interval).startOf(interval);
          newEnd = end.add(d, interval).endOf(interval);
        }

        var boundaryStart = this.get('boundaryStart');
        var boundaryEnd = this.get('boundaryEnd');

        if (typeof boundaryStart !== 'undefined' && boundaryStart.isAfter(newStart)) {
          newStart = boundaryStart;
          newEnd = (0, _moment.default)(boundaryStart).add(+diff - 1, 'd').endOf('d');
        }

        if (typeof boundaryEnd !== 'undefined' && boundaryEnd.isBefore(newEnd)) {
          newEnd = boundaryEnd;
          newStart = (0, _moment.default)(boundaryEnd).add(-diff + 1, 'd').startOf('d');
        }

        this.set('start', newStart.format(_environment.default.constants.DATE_PICKER_FORMAT));
        this.set('end', newEnd.format(_environment.default.constants.DATE_PICKER_FORMAT));

        if (this.action) {
          this.action();
        }
      }
    }
  });

  _exports.default = _default;
});