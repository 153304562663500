define("coffeecup/components/date-month-picker", ["exports", "coffeecup/config/environment", "moment", "coffeecup/mixins/empty-action"], function (_exports, _environment, _moment, _emptyAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emptyAction.default, {
    currentMonthLabel: 'this month',
    activeMonthText: Ember.computed('month', {
      get: function get() {
        var monthObj = (0, _moment.default)(this.get('month'), _environment.default.constants.DATE_MONTH_PICKER_FORMAT);
        return monthObj.format(_environment.default.constants.DATE_FORMAT_FULL_MONTHS);
      }
    }),
    activeMonthTextShort: Ember.computed('month', {
      get: function get() {
        var monthObj = (0, _moment.default)(this.get('month'), _environment.default.constants.DATE_MONTH_PICKER_FORMAT);
        return monthObj.format(_environment.default.constants.DATE_FORMAT_MONTHS_SHORT);
      }
    }),
    currentMonth: Ember.computed({
      get: function get() {
        return this.get('clock.month').format(_environment.default.constants.DATE_MONTH_PICKER_FORMAT);
      }
    }),
    isCurrentMonth: Ember.computed('month', {
      get: function get() {
        return this.get('currentMonth') === this.get('month');
      }
    }),
    month: Ember.computed('year', 'monthNumber', {
      get: function get() {
        return this.buildMonthString(this.get('year'), this.get('monthNumber'));
      }
    }),
    year: Ember.computed('month', {
      get: function get() {
        var monthObj = (0, _moment.default)(this.get('month'), _environment.default.constants.DATE_MONTH_PICKER_FORMAT);
        return monthObj.format('YYYY');
      }
    }),
    currentYear: Ember.computed({
      get: function get() {
        return this.get('clock.year').format('YYYY');
      }
    }),
    isCurrentYear: Ember.computed('month', {
      get: function get() {
        return this.get('currentYear') === this.get('year');
      }
    }),
    maxYear: Ember.computed('maxMonth', function () {
      if (!this.get('maxMonth')) return null;
      return (0, _moment.default)(this.get('maxMonth')).year();
    }),
    isMaxMonth: Ember.computed('month', 'maxMonth', function () {
      return this.get('month') === this.get('maxMonth');
    }),
    maxMonth: null,
    years: Ember.computed('month', 'year', 'maxYear', {
      get: function get() {
        var year = parseInt(this.get('year'), 10);
        var yearsOnPage = [];
        var minYear = year - 10;
        var maxYear = this.get('maxYear') ? this.get('maxYear') : year + 10;

        for (var i = minYear; i <= maxYear; i++) {
          yearsOnPage.push({
            year: i,
            isActive: year === i ? 'selected' : ''
          });
        }

        return yearsOnPage;
      }
    }),
    months: Ember.computed('month', 'year', 'maxMonth', {
      get: function get() {
        var year = parseInt(this.get('year'), 10);
        var monthObj = (0, _moment.default)(this.get('month'), _environment.default.constants.DATE_MONTH_PICKER_FORMAT);
        var selectedMonth = monthObj.month();
        var selectedYear = monthObj.year();
        var isCurrentYear = year === selectedYear;
        var monthsOnPage = [];
        var monthsRow = [];

        var months = _moment.default.monthsShort();

        for (var i = 0; i < 12; i++) {
          if (i % 3 === 0 && i > 0) {
            monthsOnPage.push(monthsRow);
            monthsRow = [];
          }

          var month = this.get('clock.now').year(year).month(i);
          var maxMonth = this.get('maxMonth') ? (0, _moment.default)(this.get('maxMonth')) : null;

          if (!maxMonth || month.isSameOrBefore(maxMonth, 'month')) {
            monthsRow.push({
              month: month.format(_environment.default.constants.DATE_MONTH_PICKER_FORMAT),
              label: months[i],
              isActive: isCurrentYear && i === selectedMonth ? 'selected' : ''
            });
          }
        }

        monthsOnPage.push(monthsRow);
        return monthsOnPage;
      }
    }),
    actions: {
      currentMonth: function currentMonth() {
        this.set('month', this.get('currentMonth'));
      },
      pickedMonth: function pickedMonth(month) {
        this.set('month', month);
        this.$('button.close-btn').trigger('click');
      },
      close: function close() {
        return true;
      },
      prevMonth: function prevMonth() {
        var monthObj = (0, _moment.default)(this.get('month'), _environment.default.constants.DATE_MONTH_PICKER_FORMAT);
        this.set('month', monthObj.subtract(1, 'month').format(_environment.default.constants.DATE_MONTH_PICKER_FORMAT));
      },
      nextMonth: function nextMonth() {
        var monthObj = (0, _moment.default)(this.get('month'), _environment.default.constants.DATE_MONTH_PICKER_FORMAT);
        this.set('month', monthObj.add(1, 'month').format(_environment.default.constants.DATE_MONTH_PICKER_FORMAT));
      },
      prevYear: function prevYear() {
        this.decrementProperty('year');
      },
      nextYear: function nextYear() {
        this.incrementProperty('year');
      },
      pickedYear: function pickedYear(year) {
        this.set('year', year);
      },
      showCurrentMonth: function showCurrentMonth() {
        this.sendAction('showToday');
      }
    }
  });

  _exports.default = _default;
});