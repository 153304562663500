define("coffeecup/components/input/cc-date-field", ["exports", "coffeecup/components/date-picker", "moment", "coffeecup/mixins/has-error", "coffeecup/utils/date"], function (_exports, _datePicker, _moment, _hasError, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _datePicker.default.extend(_hasError.default, {
    formatter: Ember.inject.service(),
    utcMoment: true,
    _date: null,
    rawDate: null,
    minDate: null,
    maxDate: null,
    canBeEmpty: true,
    isDatePickerOpen: false,
    initialDateClone: null,
    updateDateClone: function updateDateClone() {
      var date = this.get('date');

      if (date && (0, _date.isDateValid)(date)) {
        this.set('initialDateClone', (0, _moment.default)(date));
      }
    },
    date: Ember.computed('_date', {
      get: function get() {
        return (0, _moment.default)(this.get('_date'));
      },
      set: function set(key, value) {
        this.set('_date', value);
        this.set('rawDate', this.get('dateFMT'));
        return (0, _moment.default)(value);
      }
    }),
    didEnter: function didEnter() {
      this.formatDateFromRawInput();

      if (this.isCorrectlyEmpty() || this.isDateChangeValid()) {
        if (false
        /* DEBUG */
        ) {
          (false && Ember.warn("onEnter action was triggered in ".concat(this.toString(), ": ").concat(this.get('dateFMT')), {
            id: 'cc.onEnterAction'
          }));
        }

        this.sendAction('onEnter');
      }

      this.updateDateClone();
    },

    /**
     * Checks if the current date has changed and if the change is valid
     * @returns true if date has really changed
     */
    isDateChangeValid: function isDateChangeValid() {
      var date = this.get('date');
      var initialDateClone = this.get('initialDateClone');
      return date && (0, _date.isDateValid)(date) && !date.isSame(initialDateClone);
    },

    /**
     * Checks if the date input can be empty and if it is empty
     * @returns true if the input is empty and is allowed to be empty
     */
    isCorrectlyEmpty: function isCorrectlyEmpty() {
      return this.get('canBeEmpty') && this.get('date') && this.get('date').parsingFlags().empty;
    },
    formatDateFromRawInput: function formatDateFromRawInput() {
      // Need to include format in case the date format is ambiguous (i.e. MM/DD/YYYY, DD/MM/YYYY)
      var format = this.get('formatter').getDateFormat();

      var newDate = _moment.default.utc(this.get('rawDate'), format);

      if (newDate.toString() !== this.get('date').toString()) {
        this.set('date', newDate);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super();

      var $input = this.$('input');
      var $datePicker = this.$('.date-picker');
      this.updateDateClone();
      Ember.run.next(function () {
        $input.on('blur', function () {
          _this.formatDateFromRawInput();

          Ember.run(function () {
            if ((_this.isCorrectlyEmpty() || _this.isDateChangeValid()) && !_this.get('isDatePickerOpen')) {
              if (false
              /* DEBUG */
              ) {
                (false && Ember.warn("onBlur action was triggered in ".concat(_this.toString(), ": ").concat(_this.get('dateFMT')), {
                  id: 'cc.onBlurAction'
                }));
              }

              _this.onBlur();
            }

            _this.updateDateClone();
          });
        });
        $datePicker.on('show.daterangepicker', function (ev, picker) {
          if (_this.get('disabled') && !_this.get('openPickerForDisabled')) {
            picker.hide();
          }

          _this.set('isDatePickerOpen', true);
        });
        $datePicker.on('hide.daterangepicker', function (ev, picker) {
          _this.set('isDatePickerOpen', false);

          _this.didEnter();
        });
      });
    },
    dateFMT: Ember.computed('date', {
      get: function get() {
        var date = this.get('date');

        if (date && (0, _date.isDateValid)(date)) {
          return this.get('formatter').formatDate(date);
        }

        return '';
      }
    }),
    keyPress: function keyPress(event) {
      if (event.key === 'Enter') {
        this.didEnter();
      }
    },
    _openDatePicker: Ember.observer('isDatePickerOpen', function () {
      if (this.get('disabled')) {
        return this.set('isDatePickerOpen', false);
      }

      if (this.get('isDatePickerOpen')) {
        this.get('drpObj').show();
      }
    }),
    actions: {
      cancel: function cancel() {
        var date = this.get('date');

        if (this.isCorrectlyEmpty() || this.isDateChangeValid()) {
          (false && Ember.warn("onEscape action was triggered in ".concat(this.toString(), ": ").concat(this.get('dateFMT')), {
            id: 'cc.onEscapeAction'
          }));
          this.sendAction('onEscape');
        }

        this.updateDateClone();
      },
      resetIfEmpty: function resetIfEmpty() {
        var canBeEmpty = this.get('canBeEmpty');
        var dateFMT = this.get('dateFMT');
        var initialDateClone = this.get('initialDateClone');

        if (!canBeEmpty && !dateFMT && initialDateClone && (0, _date.isDateValid)(initialDateClone)) {
          this.set('date', initialDateClone);
        }
      }
    }
  });

  _exports.default = _default;
});