define("coffeecup/controllers/team/users/edit", ["exports", "ember-changeset", "ember-changeset-validations", "coffeecup/validations/user", "coffeecup/mixins/manage-tags", "moment", "coffeecup/config/environment", "coffeecup/controllers/manage/_super-edit", "coffeecup/utils/attachment-preview", "coffeecup/utils/attachment-upload", "coffeecup/utils/attachment-load", "coffeecup/mixins/buffered-form", "coffeecup/validations/user-employment", "coffeecup/services/events"], function (_exports, _emberChangeset, _emberChangesetValidations, _user, _manageTags, _moment, _environment, _superEdit, _attachmentPreview, _attachmentUpload, _attachmentLoad, _bufferedForm, _userEmployment, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmploymentEntryViewModel = Ember.Object.extend(_bufferedForm.default, {
    blockedStartDates: [],
    model: null,
    validTill: null,
    isDeletable: true,
    isEditing: false,
    isInList: true,
    isActive: false,
    formValidation: _userEmployment.default,
    hasValidFromDate: function hasValidFromDate() {
      var validFrom = _moment.default.utc(this.get('form.validFrom')).startOf('day');

      return this.get('blockedStartDates').reduce(function (prev, date) {
        if (_moment.default.utc(date).isSame(validFrom, 'day')) {
          return false;
        } else {
          return prev;
        }
      }, true);
    },
    validate: function validate() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (_this.hasValidFromDate()) {
          var form = _this.get('form');

          form.validate().then(resolve).catch(reject);
        } else {
          reject({
            duplicateDate: true
          });
        }
      });
    },
    employerContributionAmount: Ember.computed('model.monthlyRemuneration', 'model.employerContribution', {
      get: function get() {
        var monthly = this.get('model.monthlyRemuneration');
        var percentage = this.get('model.employerContribution');
        return monthly * percentage;
      }
    }),

    /**
     * For some reason applying the buffer seems to fail for the specific field 'hourlyRemuneration'. This is a
     * hack to get it to work, but we really need to understand why this doesn't work as expected. But that's a
     * problem for future me, let that guy figure it out...
     *
     * @todo Find a solution for this hack.
     */
    applyBufferedChanges: function applyBufferedChanges() {
      var form = this.get('form');
      form.execute(['hourlyRemuneration']);
      form.execute();
    }
  });

  var _default = _superEdit.default.extend(_manageTags.default, {
    needs: ['application'],
    application: Ember.inject.controller(),
    teamController: Ember.inject.controller('team'),
    i18n: Ember.inject.service(),
    formatter: Ember.inject.service(),
    permissions: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    events: Ember.inject.service(),
    employmentService: Ember.inject.service('employment'),
    projectService: Ember.inject.service('project'),
    accountService: Ember.inject.service('account'),
    allTeams: Ember.computed.alias('application.allTeams'),
    teamService: Ember.inject.service('team'),
    modalController: Ember.inject.controller('modal/team-reasignment'),

    /* set by the route */
    userAssignments: [],
    dateFormats: null,
    timezones: null,
    languages: null,
    beginningOfWeeks: null,
    roles: null,

    /**
     * the currently logged in user
     */
    currentUser: Ember.computed.oneWay('application.currentUser'),
    hasAbsenceIntegration: Ember.computed.alias('permissions.absenceIntegrationActive'),
    useHourlyCosts: Ember.computed.alias('permissions.useHourlyCosts'),
    canAccessPlanner: Ember.computed('model.showInPlanner', 'permissions.plannerActive', 'currentUser.isAdminOrProjectManager', 'permissions.canAccessPlanner', 'permissions.canManagePlanner', function () {
      var showInPlanner = this.get('model.showInPlanner');
      var plannerActive = this.get('permissions.plannerActive');
      var userHasAccessToPlanner = this.get('currentUser.isAdminOrProjectManager') || this.get('permissions.canAccessPlanner') || this.get('permissions.canManagePlanner');
      return showInPlanner && plannerActive && userHasAccessToPlanner;
    }),
    canAccessDocuments: Ember.computed.reads('permissions.canManageUsers'),
    canAccessRemunerations: Ember.computed('model.id', 'permissions.canAccessRemunerations', 'permissions.canAccessAdminRemunerations', function () {
      var model = this.model;
      var canAccessRemunerations = this.permissions.canAccessRemunerations;
      var canAccessAdminRemunerations = this.permissions.canAccessAdminRemunerations;
      return canAccessAdminRemunerations || model && !model.get('role.isRootAdmin') && canAccessRemunerations;
    }),
    accountSettings: Ember.computed.alias('accountService.accountSettings'),
    newVacationEntry: null,
    selectedYear: null,
    users: Ember.computed.readOnly('teamController.users'),
    remunerationAccessUserList: Ember.computed('model.id', function () {
      var users = this.users;

      if (this.model.role.get('isRootAdmin')) {
        users = users.filter(function (user) {
          return user.role.get('canAccessAdminRemunerations');
        });
      } else {
        users = users.filter(function (user) {
          return user.role.get('canAccessRemunerations');
        });
      }

      var tooltip = "<div class=\"small-space-bottom\">".concat(this.i18n.t('remunerationVisibility'), "</div>");
      var i18n = this.get('i18n');
      var maxElements = 10;
      var index = 0;
      users.forEach(function (user) {
        if (index < maxElements) {
          tooltip += "<div class=\"".concat(users.length > 1 && index !== 4 ? 'small-space-bottom' : '', "\">").concat(user.get('fullName'), "</div>");
          index++;
        }
      });

      if (index < users.length) {
        tooltip += "<div class=\"small-space-top\">".concat(users.length - maxElements, " ").concat(i18n.t('otherUsers'), "</div>");
      }

      return tooltip;
    }),

    /**
     * Timestamp when the page was loaded.
     * Can be used for observer on user
     */
    pageLoadedAt: null,
    activateValidationForUser: true,
    currentUserisOnlySupervisor: Ember.computed.equal('usersExceptModel.length', 0),
    usersExceptModel: Ember.computed('users.[]', 'model', 'pageLoadedAt', function () {
      var i18n = this.get('i18n');
      var users = Ember.A();
      users.pushObjects(this.get('users').toArray().filter(function (el) {
        return el.get('role.canValidateWorkTime') && el.get('isActive');
      }));
      users.removeObject(this.get('model'));
      var teamMembersWithPermission = this.get('model.teamAssignment.team.teamAssignments').filterBy('canViewTimeEntries').mapBy('user');
      users.pushObjects(teamMembersWithPermission);
      return users.uniq();
    }),
    years: Ember.computed('clock.localDayAsUtcMoment', 'employmentEntryViewModelsSorted.firstObject.model.validFrom', 'employmentEntryViewModelsSorted.lastObject.model.validFrom', function () {
      var nextYear = this.get('clock.localDayAsUtcMoment').startOf('year').add(1, 'year').format('YYYY');
      var firstLastObject = this.get('useHourlyCosts') ? 'lastObject' : 'firstObject';
      var firstYear = (0, _moment.default)(this.get("employmentEntryViewModelsSorted.".concat(firstLastObject, ".model.validFrom"))).format('YYYY');
      var years = [];

      for (var i = nextYear; i >= firstYear; i--) {
        years.pushObject('' + i);
      }

      return years;
    }),

    /**
     * returns a headline for the detailpane
     * @return {Handlebars.SafeString} a string that will not be html escaped by Handlebars
     */
    headline: Ember.computed.alias('model.fullName'),

    /**
     * Name (key) of input field to put focus on
     */
    focus: 'firstname',

    /**
     * disable email editing of admins
     */
    disableEmail: Ember.computed('accountSettings.accountOwner', 'model.id', 'model.isPasswordPending', function () {
      var accountOwner = this.get('accountSettings.accountOwner');
      var modelId = this.get('model.id');
      return modelId && accountOwner.get('id') === modelId;
    }),
    TYPE_INDEX_EMPLOYEE: _environment.default.constants.USER_TYPE_EMPLOYEE,
    // employee
    TYPE_INDEX_CONTRACTOR: _environment.default.constants.USER_TYPE_FREELANCER,
    // contractor
    workTypeDaily: _environment.default.constants.WORK_HOURS_FIXED,
    workTypeNone: _environment.default.constants.WORK_HOURS_FLEXIBLE,
    durationFormatHM: _environment.default.constants.DURATION_FORMAT_HM,
    durationFormatDecimal: _environment.default.constants.DURATION_FORMAT_DECIMAL,

    /**
     * time formats
     */
    timeFormatIndex12: _environment.default.constants.TIME_FORMAT_12,
    timeFormatIndex24: _environment.default.constants.TIME_FORMAT_24,
    YES: _environment.default.constants.YES,
    NO: _environment.default.constants.NO,

    /**
     * checks if, the selected user has a daily or weekly worktype
     */
    isWorkTypeDaily: Ember.computed.equal('model.workHoursType', _environment.default.constants.WORK_HOURS_FIXED_DAY),
    vacationShowMore: false,
    editingViewModel: null,
    employmentEntryViewModels: Ember.computed('employmentEntries', 'employmentEntries.@each.validFrom', function () {
      return this._createEmploymentEntryViewModels();
    }),
    employmentEntryViewModelsSorted: Ember.computed.sort('employmentEntryViewModels.@each.model.validFrom', function (vm1, vm2) {
      var vf1 = (0, _moment.default)(vm1.get('model.validFrom'));
      var vf2 = (0, _moment.default)(vm2.get('model.validFrom'));
      return this.get('useHourlyCosts') ? vf2.diff(vf1, 'days') : vf1.diff(vf2, 'days');
    }),
    disableVacation: Ember.computed.equal('activeEmploymentEntry.employmentType', _environment.default.constants.USER_TYPE_FORMER),
    showVacation: Ember.computed('activeEmploymentEntry.employmentType', function () {
      var employmentType = this.get('activeEmploymentEntry.employmentType');

      if (employmentType !== _environment.default.constants.USER_TYPE_FORMER) {
        return true;
      } else {
        return this.get('vacationDetailObjects.length') > 2;
      }
    }),
    showOvertime: Ember.computed.alias('activeEmploymentEntry.isWorkTypeFixed'),
    activeEmploymentEntry: null,
    _activeEmploymentEntryObserver: Ember.observer('employmentEntryViewModelsSorted', 'employmentEntryViewModelsSorted.[]', 'editingViewModel', function () {
      this.set('activeEmploymentEntry', this.determineActiveEmploymentEntry());
    }),
    determineActiveEmploymentEntry: function determineActiveEmploymentEntry() {
      var activeEntry = null;
      var existingEntries = this.get('employmentEntryViewModelsSorted.length');
      var now = this.get('clock.localDayAsUtcMoment');

      if (existingEntries > 0) {
        var entries = this.get('employmentEntryViewModelsSorted').map(function (vm) {
          return vm.get('model');
        });
        var entry = this.get('useHourlyCosts') ? entries.shift() : entries.pop();

        while (entry) {
          var validFrom = entry.get('validFrom');

          if (!entry.get('isDeleted') && now.diff(_moment.default.utc(validFrom), 'days') >= 0) {
            activeEntry = entry;
            break;
          }

          entry = this.get('useHourlyCosts') ? entries.shift() : entries.pop();
        }
      }

      if (!activeEntry) {
        var editingForm = this.get('editingViewModel.form');

        if (editingForm) {
          var _validFrom = editingForm.get('validFrom');

          if (now.diff(_moment.default.utc(_validFrom), 'days') >= 0) {
            activeEntry = editingForm;
          }
        }
      }

      this._updateActiveState(activeEntry);

      return activeEntry;
    },
    internalHourlyRateLabel: Ember.computed('employmentEntryViewModelsSorted', 'activeEmploymentEntry.hourlyRemuneration', function () {
      var _this$activeEmploymen, _this$employmentEntry;

      // This is always firstObject, as this prop is _only_ accessed in hourlyCosts mode
      var activeOrNextEntry = (_this$activeEmploymen = this.activeEmploymentEntry) !== null && _this$activeEmploymen !== void 0 ? _this$activeEmploymen : (_this$employmentEntry = this.employmentEntryViewModelsSorted.firstObject) === null || _this$employmentEntry === void 0 ? void 0 : _this$employmentEntry.model;

      if (activeOrNextEntry && !activeOrNextEntry.get('isFormer')) {
        return "".concat(this.i18n.t('internalHourlyRate'), ": ").concat(this.formatter.formatCurrency(activeOrNextEntry.get('hourlyRemuneration')));
      }

      return '';
    }),
    // This is only used for the "old" employment entry panel
    remunerationLabel: Ember.computed('activeEmploymentEntry.employmentType', 'activeEmploymentEntry.monthlyRemuneration', 'activeEmploymentEntry.hourlyRemuneration', 'activeEmploymentEntry.hoursMonday', 'activeEmploymentEntry.hoursTuesday', 'activeEmploymentEntry.hoursWednesday', 'activeEmploymentEntry.hoursThursday', 'activeEmploymentEntry.hoursFriday', 'activeEmploymentEntry.hoursSaturday', 'activeEmploymentEntry.hoursSunday', 'activeEmploymentEntry.hoursWeekly', 'activeEmploymentEntry.workHoursType', 'activeEmploymentEntry.employerContribution', function () {
      var i18n = this.get('i18n');
      var formatter = this.get('formatter');
      var workHoursType = this.get('activeEmploymentEntry.workHoursType');
      var hoursDescription = '';

      if (workHoursType === _environment.default.constants.WORK_HOURS_FLEXIBLE) {
        hoursDescription = i18n.t('employeeRemunerationDescriptionFlexible');
      } else if (workHoursType === _environment.default.constants.WORK_HOURS_FIXED_DAY) {
        var hoursSum = this.get('activeEmploymentEntry.hoursMonday') + this.get('activeEmploymentEntry.hoursTuesday') + this.get('activeEmploymentEntry.hoursWednesday') + this.get('activeEmploymentEntry.hoursThursday') + this.get('activeEmploymentEntry.hoursFriday') + this.get('activeEmploymentEntry.hoursSaturday') + this.get('activeEmploymentEntry.hoursSunday');
        hoursDescription = i18n.t('employeeRemunerationDescriptionFix', {
          hours: hoursSum
        });
      } else if (workHoursType === _environment.default.constants.WORK_HOURS_FIXED_WEEK) {
        hoursDescription = i18n.t('employeeRemunerationDescriptionFix', {
          hours: this.get('activeEmploymentEntry.hoursWeekly')
        });
      } else {
        // User is not currently employed
        return i18n.t('employeeNotEmployed');
      }

      switch (this.get('activeEmploymentEntry.employmentType')) {
        case _environment.default.constants.USER_TYPE_EMPLOYEE:
          var monthlyRemuneration = this.get('activeEmploymentEntry.monthlyRemuneration');
          var employerContribution = this.get('activeEmploymentEntry.employerContribution');
          var remuneration = monthlyRemuneration + monthlyRemuneration * employerContribution;
          var formattedMonthly = formatter.formatCurrency(remuneration);
          return this.get('i18n').t('employeeRemunerationDescription', {
            remuneration: formattedMonthly,
            hours: hoursDescription
          });

        case _environment.default.constants.USER_TYPE_FREELANCER:
        case _environment.default.constants.USER_TYPE_FREELANCER_HOURLY:
        case _environment.default.constants.USER_TYPE_EMPLOYEE_HOURLY:
          var formattedHourly = formatter.formatCurrency(this.get('activeEmploymentEntry.hourlyRemuneration'));
          return this.get('i18n').t('freelancerRemunerationDescription', {
            remuneration: formattedHourly,
            hours: hoursDescription
          });

        default:
          return '';
      }
    }),
    // This is only used for the "old" employment entry panel
    employerContributionLabel: Ember.computed('activeEmploymentEntry.employmentType', 'activeEmploymentEntry.monthlyRemuneration', 'activeEmploymentEntry.employerContribution', function () {
      var formatter = this.get('formatter');
      var employmentType = this.get('activeEmploymentEntry.employmentType');
      var income = this.get('activeEmploymentEntry.monthlyRemuneration');
      var employerPercentage = this.get('activeEmploymentEntry.employerContribution');
      var rawContribution = income * employerPercentage;
      var contribution = formatter.formatCurrency(rawContribution);

      if (employmentType === _environment.default.constants.USER_TYPE_EMPLOYEE) {
        return this.get('i18n').t('employerContributionDescription', {
          contribution: contribution
        });
      } else {
        return '';
      }
    }),
    _updateActiveState: function _updateActiveState(activeEntry) {
      var viewModels = this.get('employmentEntryViewModelsSorted').toArray();
      viewModels.forEach(function (userEmploymentViewModel) {
        userEmploymentViewModel.set('isDeletable', true);

        if (userEmploymentViewModel.get('model.id') === (activeEntry === null || activeEntry === void 0 ? void 0 : activeEntry.id)) {
          userEmploymentViewModel.set('isActive', true);
        } else {
          userEmploymentViewModel.set('isActive', false);
        }
      });

      if (this.get('useHourlyCosts')) {
        viewModels.lastObject.set('isDeletable', false);
      } else {
        viewModels.firstObject.set('isDeletable', false);
      } // last only remaining object is not deletable


      if (viewModels.length === 1) {
        viewModels.firstObject.set('isDeletable', false);
      }
    },
    isFormer: Ember.computed.equal('activeEmploymentEntry.employmentType', _environment.default.constants.USER_TYPE_FORMER),
    isUserSelected: Ember.computed.equal('roleName', _environment.default.constants.ROLE_DEFAULT),
    isPMSelected: Ember.computed.equal('roleName', _environment.default.constants.ROLE_PM),
    isAdminSelected: Ember.computed.equal('roleName', _environment.default.constants.ROLE_ADMIN),
    hasEmploymentEntry: Ember.computed.gt('employmentEntryViewModels.length', 0),
    _createEmploymentEntryViewModels: function _createEmploymentEntryViewModels() {
      var _this2 = this;

      var remunerations = this.get('employmentEntries');
      var allStartDates = remunerations.map(function (rem) {
        return rem.get('validFrom');
      });
      var viewModels = remunerations.map(function (rem) {
        return _this2._createViewModelForRemuneration(rem, allStartDates);
      });
      return viewModels;
    },
    _createViewModelForRemuneration: function _createViewModelForRemuneration(remuneration, allStartDates) {
      var remunerations = this.get('employmentEntries');
      var index = remunerations.indexOf(remuneration);

      var currentStartDate = _moment.default.utc(remuneration.get('validFrom'));

      var blockedStartDates = allStartDates.filter(function (d) {
        if (remuneration.get('isNew')) return true;

        var date = _moment.default.utc(d);

        return !currentStartDate.isSame(date, 'day');
      });
      var type;

      if (remuneration.get('monthlyRemuneration') > 0) {
        type = _environment.default.constants.USER_TYPE_EMPLOYEE;
      } else {
        type = _environment.default.constants.USER_TYPE_FREELANCER;
      }

      var owner = Ember.getOwner(this);
      var vm = EmploymentEntryViewModel.create(owner.ownerInjection(), {
        model: remuneration,
        blockedStartDates: blockedStartDates,
        type: type
      });

      if (index !== remunerations.get('length') - 1) {
        var nextValidFrom = remunerations.objectAt(index + 1).get('validFrom');
        vm.set('validTill', _moment.default.utc(nextValidFrom).subtract(1, 'day'));
      }

      return vm;
    },
    _calculateValidTill: function _calculateValidTill() {
      var vms = this.get('employmentEntryViewModelsSorted');
      var allStartDates = vms.map(function (vm) {
        return vm.get('model.validFrom');
      });
      vms.forEach(function (vm, index) {
        var editable = index !== 0;
        vm.set('isDeletable', editable);

        var currentStartDate = _moment.default.utc(vm.get('model.validFrom'));

        var blockedStartDates = allStartDates.filter(function (d) {
          var date = _moment.default.utc(d);

          return !currentStartDate.isSame(date, 'day');
        });
        vm.set('blockedStartDates', blockedStartDates);

        if (index !== vms.get('length') - 1) {
          var nextValidFrom = vms.objectAt(index + 1).get('model.validFrom');
          vm.set('validTill', _moment.default.utc(nextValidFrom).subtract(1, 'day'));
        } else {
          vm.set('validTill', null);
        }
      });
    },
    vacationDetailObjects: Ember.A(),
    selectedLanguage: Ember.computed('languages', 'model.language', function () {
      var _this3 = this;

      var languages = this.get('languages');

      if (!languages) {
        return null;
      }

      return languages.find(function (x) {
        return x.get('id') === (_this3.get('model.language') || '').toString();
      });
    }),

    /**
     * array of image uploads
     */
    attachments: [],
    errors: {},

    /**
     * imageType for different drop-down actions
     */
    imageType: Ember.computed.alias('model.imageType'),
    localImg: null,
    UserValidations: _user.default,

    /**
     * returns a preview image which can either be a preview of the selected file-upload, a gravatar or a previously
     * uploaded file
     */
    previewImg: Ember.computed('imageType', 'localImg', 'model.email', 'model.imageDirectoryURL', function () {
      if (this.get('localImg')) return this.get('localImg.data');
      var id = this.get('model.imageDirectoryURL');
      var email = this.get('model.email');
      var emailIsValid = !this.get('errors.email.firstObject');
      return this._image(id, 'm', email, emailIsValid);
    }),
    initials: Ember.computed.alias('model.initials'),

    /**
     * @return array of timezones
     */
    timezonesSorted: Ember.computed.alias('timezones'),

    /**
     * Groups the timezones by continent and builds a select-2 compatible representation of the json
     * @return hierarchical array for select-2
     */
    timezonesGrouped: Ember.computed('timezones', function () {
      var result = [];
      var content = this.get('timezones');
      content.forEach(function (apt) {
        var group = apt.get('group');
        var found = result.findBy('text', group);

        if (!found) {
          result.pushObject(Ember.Object.create({
            text: group,
            children: []
          }));
        }

        result.findBy('text', group).get('children').pushObject(apt);
      });
      return result;
    }),

    /**
     * to hide e.g. password reset function for current user
     * or hide role selectbox
     * @return boolean
     */
    isNotCurrentUser: Ember.computed('model.id', function () {
      return this.get('currentUser.id') !== this.get('model.id');
    }),
    selectedCountry: Ember.computed('model.country', 'countries', function () {
      var _this4 = this;

      var countries = this.get('countries');

      if (!countries) {
        return null;
      }

      return countries.find(function (x) {
        return x.get('code') === (_this4.get('model.country') || '').toString();
      });
    }),
    roleName: Ember.computed('model.role', {
      get: function get() {
        return this.get('model.role.name');
      },
      set: function set(key, val) {
        var roles = this.store.peekAll('role');
        var role = roles.reduce(function (prev, role) {
          if (role.get('name') === val) {
            return role;
          } else {
            return prev;
          }
        }, null);
        this.set('model.role', role);
        return val;
      }
    }),
    hasRoles: Ember.computed.bool('rolesAllowed.length'),
    rolesAllowed: Ember.computed('roles', 'activeEmploymentEntry', 'activeEmploymentEntry.employmentType', function () {
      return this.get('roles').map(function (r) {
        return r.get('name');
      });
    }),
    rolesActivated: Ember.computed('rolesAllowed', function () {
      var _this5 = this;

      var allowed = this.get('rolesAllowed');
      return this.get('roles').sortBy('order').map(function (r) {
        var name = r.get('name');
        var disabled = !allowed.contains(name);
        return Ember.Object.create({
          role: r,
          showTooltip: disabled && _this5.get('isFormer'),
          tooltip: 'tooltipRoleFormer',
          isDisabled: disabled
        });
      });
    }),

    /**
     * set of actions
     */
    actions: {
      closePane: function closePane() {
        this.transitionToRoute('team');
      },
      saveUser: function saveUser() {
        var _this6 = this;

        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var skipNotification = options.skipNotification;
        Ember.run.next(function () {
          _this6.set('errors', {});

          if (_this6.get('model.hasDirtyAttributes') || _this6.get('model').didChange('role') || _this6.get('model').didChange('supervisor') || _this6.get('model').didChange('insuranceType') || _this6.get('model.noticePeriod.isDirty')) {
            var changeset = (0, _emberChangeset.Changeset)(_this6.get('model'), (0, _emberChangesetValidations.default)(_user.default), _user.default);
            changeset.validate().then(function () {
              if (changeset.get('isValid')) {
                _this6.get('model').save().then(function () {
                  if (!skipNotification) _this6.get('notifications').success('alert.saved', 'user');
                }).catch(function (err) {
                  _this6.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'user');
                });
              } else {
                _this6.set('errors', changeset.error);

                _this6.get('notifications').info('pleaseCheckYourInputs');
              }
            }).catch(function () {
              _this6.get('notifications').info('pleaseCheckYourInputs');
            });
          }
        });
      },
      resetUser: function resetUser() {
        this.get('model').rollbackAttributes();
      },
      addImage: function addImage(files) {
        var _this7 = this;

        var model = this.get('model');
        var accessToken = this.get('session.data.authenticated.access_token');
        this.get('attachments').setObjects([]);
        files.forEach(function (file) {
          if (!_this7.get('attachments').findBy('name', file.name)) {
            _this7.get('attachments').pushObject(file);
          }
        });

        var onError = function onError(err) {
          _this7.get('notifications').errorWithObject(err, 'alert.errorOnImagePreview');

          model.set('imageType', _environment.default.constants.IMG_TYPE_INITIALS);

          _this7.send('saveUser', {
            skipNotification: true
          });
        };

        this.get('attachments').map(function (file) {
          (0, _attachmentLoad.default)(file).then(function () {
            (0, _attachmentPreview.imagePreview)(file).then(function (data) {
              _this7.set('localImg', Ember.Object.create({
                data: data
              }));

              (0, _attachmentUpload.attachmentUpload)(file, _attachmentUpload.ATTACHMENT_MODEL_USER_IMAGE, _this7.get('model.id'), accessToken).then(function () {
                model.set('imageType', _environment.default.constants.IMG_TYPE_UPLOAD);
                model.save().then(function () {
                  _this7.get('notifications').success('alert.saved', 'user');
                });
              }).catch(function (error) {
                onError(error);
              });
            }).catch(function (error) {
              onError(error);
            });
          });
        });
      },

      /**
       * use the gravatar image
       */
      gravatarImg: function gravatarImg(afterHook) {
        this.get('attachments').setObjects([]);
        this.set('localImg', null);
        this.set('model.imageType', _environment.default.constants.IMG_TYPE_GRAVATAR);
        this.send('saveUser');

        if (afterHook && typeof afterHook === 'function') {
          afterHook();
        }
      },

      /**
       * use the initials image (css generated)
       */
      initialsImg: function initialsImg(afterHook) {
        this.get('attachments').setObjects([]);
        this.set('localImg', null);
        this.set('model.imageType', _environment.default.constants.IMG_TYPE_INITIALS);
        this.send('saveUser');

        if (afterHook && typeof afterHook === 'function') {
          afterHook();
        }
      },
      publishChange: function publishChange(vacationEntry) {
        var _this8 = this;

        if (vacationEntry.get('vacationId')) {
          this.transitionToRoute('team.users.edit.vacation', vacationEntry.get('vacationId'));
        } else if (vacationEntry.get('vacationBudgetId')) {
          var vacationBudgetId = vacationEntry.get('vacationBudgetId');

          if (vacationEntry.get('mode') === 'delete') {
            var vacationBudget = this.get('store').peekRecord('vacation-budget', vacationBudgetId);
            vacationBudget.destroyRecord().then(function () {
              _this8.get('notifications').success('alert.deleted', 'vacationEntry');

              _this8.get('vacationDetailObjects').removeObject(vacationEntry);

              _this8.send('reloadVacationEntries');
            }).catch(function (err) {
              _this8.get('notifications').errorWithObject(err, 'alert.errorOnDelete', 'vacationEntry');
            });
          } else if (vacationEntry.get('mode') === 'update') {
            var _vacationBudget = this.get('store').peekRecord('vacation-budget', vacationBudgetId);

            _vacationBudget.set('quantity', vacationEntry.get('quantity'));

            _vacationBudget.set('comment', vacationEntry.get('comment'));

            if (_vacationBudget.get('hasDirtyAttributes')) {
              _vacationBudget.save().then(function () {
                _this8.get('notifications').success('alert.saved', 'vacationEntry');

                vacationEntry.set('mode', null);

                _this8.send('reloadVacationEntries');
              }).catch(function (err) {
                _this8.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'vacationEntry');
              });
            }
          }
        } else if (vacationEntry.get('mode') === 'new') {
          var _vacationBudget2 = this.get('store').createRecord('vacation-budget', {
            vacationType: _environment.default.constants.VACATION_TYPE_EXTRA_BUDGET,
            year: vacationEntry.get('year'),
            user: this.get('model'),
            quantity: vacationEntry.get('quantity'),
            comment: vacationEntry.get('comment')
          });

          _vacationBudget2.save().then(function () {
            _this8.get('notifications').success('alert.saved', 'vacationEntry');

            vacationEntry.set('vacationBudgetId', _vacationBudget2.get('id'));
            vacationEntry.set('mode', null);
            vacationEntry.set('vacationType', _environment.default.constants.VACATION_TYPE_EXTRA_BUDGET);

            _this8.get('vacationDetailObjects').pushObject(vacationEntry);

            _this8.set('newVacationEntry', _this8.get('store').createRecord('vacationEntry', {
              mode: 'new'
            }));

            _this8.send('reloadVacationEntries');
          }).catch(function (err) {
            _this8.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'vacationEntry');
          });
        }
      },
      createEmploymentEntry: function createEmploymentEntry() {
        var user = this.get('model');
        var activeEmploymentEntry = this.get('activeEmploymentEntry');
        var newEmploymentEntry = this.get('employmentService').createDefaultEmploymentEntry(user, activeEmploymentEntry);
        var allStartDates = this.get('employmentEntryViewModels').map(function (vm) {
          return vm.get('model.validFrom');
        });

        var viewModel = this._createViewModelForRemuneration(newEmploymentEntry, allStartDates);

        viewModel.set('isEditing', true);
        viewModel.set('isInList', false);
        this.set('editingViewModel', viewModel);
      },
      editEmploymentEntry: function editEmploymentEntry(employmentEntry) {
        employmentEntry.set('isEditing', true);
        this.set('editingViewModel', employmentEntry);
      },
      employmentEntryChanged: function employmentEntryChanged(employmentEntry) {
        var _this9 = this;

        employmentEntry.set('isEditing', false);
        this.set('editingViewModel', null);

        if (!employmentEntry.get('isInList')) {
          employmentEntry.set('isInList', true);
          this.get('employmentEntryViewModels').pushObject(employmentEntry);
        } // Need to update validTill, in the event that the validFrom date has been changed


        this._calculateValidTill(); // Manually adjust changes because we're not observing all properties


        employmentEntry.get('model').save().then(function () {
          _this9.get('notifications').success('alert.saved', 'employmentEntry');

          _this9.get('events').trigger(_events.RELOAD_OVERTIME);
        }).catch(function (err) {
          _this9.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'employmentEntry');
        });
      },
      selectSupervisor: function selectSupervisor() {
        this.send('saveUser');
      },
      updateAutoApproval: function updateAutoApproval() {
        var autoApproval = this.get('userAutoApproval') === this.get('YES');
        this.set('model.activeAutoApproval', autoApproval);
        this.send('saveUser');
      },
      activateValidationForUser: function activateValidationForUser() {
        var activateValidationForUser = this.get('activateValidationForUser');

        if (!activateValidationForUser) {
          this.set('model.supervisor', null);
        } else {
          this.set('model.supervisor', this.get('usersExceptModel.firstObject'));
        }

        this.send('saveUser');
      },
      deleteEmploymentEntry: function deleteEmploymentEntry(employmentEntry) {
        var _this10 = this;

        var model = employmentEntry.get('model');

        if (model.get('isNew')) {
          this.get('employmentEntryViewModels').removeObject(employmentEntry);
        }

        model.destroyRecord().then(function () {
          _this10._calculateValidTill();

          _this10.set('activeEmploymentEntry', _this10.determineActiveEmploymentEntry());

          _this10.get('notifications').success('alert.deleted', 'employmentEntry');
        }).catch(function (err) {
          _this10.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'employmentEntry');
        });
      },
      removeUserAssignment: function removeUserAssignment(userAssignment) {
        var _this11 = this;

        this.get('selectedUserAssignments').removeObject(userAssignment);
        userAssignment.destroyRecord().then(function () {
          _this11.get('notifications').success('alert.deleted', 'userAssignment');
        }).catch(function (err) {
          _this11.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'userAssignment');
        });
      },
      togglePM: function togglePM(userAssignment) {
        var _this12 = this;

        Ember.run.next(function () {
          // wait till change is propagated
          userAssignment.save().then(function () {
            _this12.get('notifications').success('alert.saved', 'userAssignment');
          }).catch(function (err) {
            _this12.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'userAssignment');
          });
        });
      },
      toggleShowMoreUserAssignments: function toggleShowMoreUserAssignments() {
        this.toggleProperty('userAssignmentShowMore');
      },
      reassignUserIntoOtherTeam: function reassignUserIntoOtherTeam(userTeam) {
        var _this13 = this;

        var previousTeam = this.model.get('team');
        var modalController = this.get('modalController');
        modalController.set('showModal', true);
        modalController.set('model', {
          icon: 'icomoon-reassign',
          title: this.get('i18n').t('reassignTitle'),
          text: this.get('i18n').t('reassignText'),
          closeBtnText: this.get('i18n').t('cancel'),
          confirmBtnText: this.get('i18n').t('reassign' + 'Now'),
          confirmCallback: function confirmCallback(shouldMoveTE) {
            _this13.get('teamService').removeTeamAssignment(_this13.get('model.teamAssignment'), false).then(function () {
              return _this13.get('teamService').createTeamAssignment(userTeam, _this13.model, shouldMoveTE ? previousTeam : null);
            }).then(function () {
              _this13.get('notifications').success('alert.saved', 'teamAssignment');
            }).catch(function (err) {
              _this13.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'teamAssignment');
            });
          }
        });
        modalController.set('openModal', true);
      },
      change: function change() {
        var selectedEl = this.$('select')[0];
        var selectedIndex = selectedEl.selectedIndex;
        var content = this.get('model');
        var selectedValue = content[selectedIndex];
        this.set('selectedYear', selectedValue);
      },
      onChangeHolidayRegion: function onChangeHolidayRegion(newRegion) {
        var _this14 = this;

        this.set('model.holidayRegion', newRegion);
        this.set('model.useDefaultHolidayRegion', false);
        this.get('model').save().then(function () {
          _this14.get('notifications').success('alert.saved', 'region');
        });
      }
    }
  });

  _exports.default = _default;
});