define("coffeecup/components/planner/milestone-overlay", ["exports", "coffeecup/components/planner/timeline-overlay", "coffeecup/utils/date"], function (_exports, _timelineOverlay, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _timelineOverlay.default.extend({
    events: Ember.inject.service(),
    viewport: Ember.inject.service('planner/viewport'),
    attributeBindings: ['title'],
    hidden: Ember.computed('currentDate', 'isHovering', 'blockedTimeframes.@each.day', function () {
      var currentDate = this.get('currentDate');
      var blockedTimeframes = this.get('blockedTimeframes') || A();
      return !this.get('isHovering') || !!blockedTimeframes.find(function (timeFrame) {
        if (timeFrame && (0, _date.isDateValid)(timeFrame.get('day'))) {
          return currentDate.isSame(timeFrame.get('day'));
        }
      });
    }),
    style: Ember.computed('left', 'projectColor', function () {
      return Ember.String.htmlSafe("left: ".concat(this.get('left'), "px; color: ").concat(this.get('projectColor')));
    }),
    assignmentWidth: Ember.computed.reads('viewport.dayWidth'),
    click: function click() {
      var currentDate = this.get('currentDate');
      this.get('viewport').selectDay(currentDate);
      this.sendAction('onClick', currentDate);
    }
  });

  _exports.default = _default;
});