define("coffeecup/components/input/cc-text-field", ["exports", "ember-cli-tooltipster/components/tool-tipster", "coffeecup/mixins/has-error", "coffeecup/utils/ui/i18n"], function (_exports, _toolTipster, _hasError, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _toolTipster.default.extend(_hasError.default, {
    classNameBindings: ['defaultClass', 'isRequired'],
    defaultClass: 'form-group',
    labelClass: '',
    inputClass: '',
    inputContainerClass: '',
    value: null,
    type: null,
    errors: null,
    validations: null,
    placeholder: null,
    disabled: false,
    key: null,
    focus: null,
    readonly: false,

    /**
     * select input's value on focus
     */
    selectOnFocus: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      var $input = this.$('input');
      Ember.run.next(function () {
        $input.on('blur', function () {
          Ember.run(function () {
            _this.set('focus', null);

            if (false
            /* DEBUG */
            ) {
              (false && Ember.warn("onBlur action was triggered in ".concat(_this.toString(), ": ").concat(_this.get('value')), {
                id: 'cc.onBlurAction'
              }));
            }

            if (_this.onBlur) {
              _this.onBlur();
            }
          });
        });
        $input.on('focus', function () {
          Ember.run(function () {
            if (_this.get('selectOnFocus')) {
              _this.$('input').select();
            }
          });
        });

        _this._focus();
      });
    },
    willDestroyElement: function willDestroyElement() {
      var $input = this.$('input'); // unbind focus and blur

      $input.off('focus blur');
    },
    onInputChange: Ember.observer('value', function () {
      if (this.onDebounce) {
        Ember.run.debounce(this, 'onDebounce', 400);
      }
    }),
    label: Ember.computed('i18n.locale', function () {
      return this.get('key') ? this.get('i18n').t(this.get('key')) : '';
    }),
    focusField: Ember.observer('focus', function () {
      this._focus();
    }),
    _focus: function _focus() {
      var focus = this.get('focus');
      var key = this.get('key');

      if (focus && key && focus === key && this.$('input') && this.$('input').length > 0) {
        this.$('input').focus();
      }
    },
    isRequired: Ember.computed('validations', function () {
      var validations = this.get('validations.presence');

      if (validations === true) {
        return 'required';
      }
    }),
    labelClasses: Ember.computed('labelClass', function () {
      return this.get('labelClass') + ' control-label';
    }),
    inputClasses: Ember.computed('inputClass', function () {
      return this.get('inputClass') + ' form-control';
    }),
    inputContainerClasses: Ember.computed('inputContainerClass', function () {
      return this.get('inputContainerClass');
    }),
    hasError: Ember.computed('errorMsg', function () {
      return !!this.get('errorMsg');
    }),
    // tooltip content
    content: Ember.computed('errorMsg', function () {
      return (0, _i18n.translateValidation)(this.get('i18n'), this.get('errorMsg'));
    }),
    // side if the tooltip
    side: ['bottom'],
    // tooltip will only show when input is focused
    triggerEvent: 'custom',
    _triggerTooltip: Ember.observer('hasError', function () {
      var _this2 = this;

      Ember.run.next(function () {
        if (!_this2.get('isDestroyed')) {
          var tooltip = _this2.get('tooltipsterInstance');

          var hasError = _this2.get('hasError');

          if (tooltip) {
            if (hasError) {
              tooltip.open();
            } else {
              tooltip.close();
            }
          }
        }
      });
    }),
    keyPress: function keyPress(event) {
      if (event.key === 'Enter') {
        if (false
        /* DEBUG */
        ) {
          (false && Ember.warn("onEnter action was triggered in ".concat(this.toString()), {
            id: 'cc.onEnterAction'
          }));
        }

        if (this.onEnter) {
          this.onEnter();
        }

        return false;
      }
    },
    actions: {
      cancel: function cancel() {
        if (false
        /* DEBUG */
        ) {
          (false && Ember.warn("onEscape action was triggered in ".concat(this.toString()), {
            id: 'cc.onEscapeAction'
          }));
        }

        if (this.onEscape) {
          this.onEscape();
        }
      }
    }
  });

  _exports.default = _default;
});