define("coffeecup/components/dashboard-module/summary-bar/presentation-mode-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*
     * PROPERTIES
     */
    tagName: 'button',
    classNames: ['mode-button'],
    classNameBindings: ['isActive:active'],
    presentationMode: null,
    selectedPresentationMode: null,

    /*
     * COMPUTED PROPERTIES
     */
    isActive: Ember.computed('selectedPresentationMode', function () {
      return this.get('presentationMode.name') === this.get('selectedPresentationMode.name');
    }),

    /*
     * FUNCTIONS
     */
    click: function click(e) {
      e.preventDefault();
      this.sendAction('action', this.get('presentationMode'));
    }
  });

  _exports.default = _default;
});