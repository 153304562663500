define("coffeecup/adapters/stripe/customer", ["exports", "coffeecup/adapters/-super-sails-rest"], function (_exports, _superSailsRest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superSailsRest.default.extend({
    // Switch path to /user, and do not pluralize
    pathForType: function pathForType(type) {
      return 'stripe/user';
    }
  });

  _exports.default = _default;
});