define("coffeecup/components/planner/time-off-frame", ["exports", "coffeecup/components/planner/time-frame"], function (_exports, _timeFrame) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _timeFrame.default.extend({
    classNames: ['time-off-frame'],
    startDate: Ember.computed.alias('assignment.startDate'),
    endDate: Ember.computed.alias('assignment.endDate')
  });

  _exports.default = _default;
});