define("coffeecup/components/dashboard-module/summary-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*
     * PROPERTIES
     */
    modes: [],
    presentationModes: [],
    hasMultipleModes: Ember.computed.gt('modes.length', 1),

    /*
     * ACTIONS
     */
    actions: {
      setCurrentMode: function setCurrentMode(mode) {
        this.set('currentMode', mode);

        if (this.setCurrentMode) {
          this.setCurrentMode(mode);
        }
      },
      setPresentationMode: function setPresentationMode(mode) {
        this.set('selectedPresentationMode', mode);
      }
    }
  });

  _exports.default = _default;
});