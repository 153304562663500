define("coffeecup/components/analytics-module/project/invoice-popover", ["exports", "coffeecup/mixins/click-outside"], function (_exports, _clickOutside) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_clickOutside.default, {
    store: Ember.inject.service(),
    invoiceService: Ember.inject.service('invoice'),
    showInvoiceDetails: false,
    isInitialized: false,
    project: null,
    analytics: null,
    notInvoicedAmounts: null,
    invoices: [],
    showNotInvoicedAmount: Ember.computed.reads('analytics.showNotInvoicedAmount'),
    notInvoicedAmount: Ember.computed.reads('analytics.notInvoicedAmount'),
    startDate: Ember.computed.reads('notInvoicedAmounts.minDate'),
    endDate: Ember.computed.reads('notInvoicedAmounts.maxDate'),
    onOutsideClick: function onOutsideClick() {
      if (this.get('isInitialized')) {
        this.toggleProperty('showInvoiceDetails');
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.next(function () {
        _this.set('isInitialized', true);
      });
    },
    actions: {
      billProject: function billProject() {
        this.set('showInvoiceDetails', false);
        var from = moment(this.get('notInvoicedAmounts.minDate'));
        var to = moment(this.get('notInvoicedAmounts.maxDate'));
        this.sendAction('customAction', 'billProject', this.get('project.id'), from, to, this.get('notInvoicedAmount'));
      }
    }
  });

  _exports.default = _default;
});