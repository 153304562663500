define("coffeecup/components/vacation-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hasShowMore: Ember.computed.gt('totalLength', 5),
    totalLength: 0,
    vacationEntriesSorted: [],
    showNewVacationBudget: false,
    hasAdditionalElements: Ember.computed('vacationEntriesSorted.[]', function () {
      return this.get('vacationEntriesSorted.length') > 2;
    }),
    actions: {
      toggleShowMoreVacationObjects: function toggleShowMoreVacationObjects() {
        this.toggleProperty('showMore');
      },
      toggleAddNewVacationBudget: function toggleAddNewVacationBudget() {
        this.set('showNewVacationBudget', true);
      },
      cancelEdit: function cancelEdit() {
        this.set('showNewVacationBudget', false);
      },
      publishChange: function publishChange(param) {
        this.sendAction('publishChange', param);
        this.set('showNewVacationBudget', false);
      }
    }
  });

  _exports.default = _default;
});