define("coffeecup/components/planner/project-row", ["exports", "coffeecup/config/environment", "coffeecup/mixins/planner/expandable-rows", "moment"], function (_exports, _environment, _expandableRows, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_expandableRows.ExpandableRow, {
    viewport: Ember.inject.service('planner.viewport'),
    permissions: Ember.inject.service(),
    activeModal: Ember.inject.service('planner.active-modal'),
    store: Ember.inject.service(),
    classNames: 'project-row',
    currentUserService: Ember.inject.service('currentUser'),
    expandedRows: null,
    activeModel: null,
    model: Ember.computed.alias('project'),
    project: null,
    users: null,
    currentUserCanWrite: null,
    creatingMilestone: false,
    currentUserIsPmOrAdmin: Ember.computed('currentUserService.currentUser', 'currentUserService.isAdmin', 'project', 'permissions.canManageAllProjects', 'project.userAssignments.@each.isProjectManager', {
      get: function get() {
        if (this.get('currentUserService.isAdmin')) return true;
        if (this.get('permissions.canManageAllProjects')) return true;
        var userAssignment = this.get('project.userAssignments').findBy('user.id', this.get('currentUserService.currentUser.id'));
        return userAssignment && userAssignment.get('isProjectManager');
      }
    }),
    editable: Ember.computed.and('currentUserCanWrite', 'currentUserIsPmOrAdmin'),
    canAddNewUser: Ember.computed('editable', 'permissions.canManageOwnPlanner', 'usersWithoutAssignment.length', function () {
      var _this = this;

      var isAssigned = this.get('currentUserService.currentUser.userAssignments').mapBy('project.id').any(function (id) {
        return id === _this.get('project.id');
      });
      var hasUnassignedUsers = !!this.get('usersWithoutAssignment.length');
      return hasUnassignedUsers && (this.editable || isAssigned && this.get('permissions.canManageOwnPlanner'));
    }),
    // filteredAssignments == planner-assignments
    filteredAssignments: Ember.computed.alias('project.plannerAssignments'),
    userSortKeys: _environment.default.constants.USER_SORT_KEYS,
    filteredUsers: Ember.computed('users', function (user) {
      var canManagePlanner = this.get('permissions.canManagePlanner');
      var canManageOwnPlanner = this.get('permissions.canManageOwnPlanner');

      if (canManageOwnPlanner && !canManagePlanner) {
        return [this.get('currentUserService.currentUser')];
      }

      return this.get('users');
    }),
    sortedUsers: Ember.computed.sort('users', 'userSortKeys'),
    sortedFilteredUsers: Ember.computed.sort('filteredUsers', 'userSortKeys'),
    sortedAssignedUsers: Ember.computed.filter('sortedUsers', function (user) {
      return this.get('filteredAssignments').findBy('user.id', user.get('id'));
    }).property('filteredAssignments.[]'),
    usersWithoutAssignment: Ember.computed.filter('sortedFilteredUsers', function (user) {
      return !this.get('filteredAssignments').findBy('user.id', user.get('id'));
    }).property('filteredAssignments.[]'),
    // Project-user cells should start from tomorrow, to match the data in project analytics
    projectUserStartDate: (0, _moment.default)().add(1, 'days'),
    projectUserEndDate: Ember.computed.alias('project.endDate'),
    actions: {
      createAssignment: function createAssignment(user, project, startDate, endDate, allocation) {
        var _this2 = this;

        if (!this.get('activeModal.plannerAssignment')) {
          // TODO move this action into a service or any other re-usable component
          // IMPORTANT: changes made here need to be copied to user-row.js::createAssignment
          endDate = endDate || startDate.clone();

          if (!allocation) {
            var allocatedDays = user.allocatedDays(startDate, startDate);
            var firstFoundAllocatedDay = allocatedDays && allocatedDays[0];

            if (!firstFoundAllocatedDay) {
              this.get('notifications').error('missingUserEmploymentForDate', {
                firstname: user.get('firstname'),
                date: startDate.format('L')
              }, {
                key: 'missingUserEmploymentForDate',
                clearDuration: 3000
              });
              return false;
            }

            allocation = 1;

            if (firstFoundAllocatedDay && Ember.computed.notEmpty(firstFoundAllocatedDay['expectedHours']) && Ember.computed.notEmpty(firstFoundAllocatedDay['allocation'])) {
              allocation = Math.max(1, firstFoundAllocatedDay['expectedHours'] - firstFoundAllocatedDay['allocation']);
            }
          }

          var plannerAssignment = this.get('store').createRecord('planner-assignment');
          plannerAssignment.set('startDate', _moment.default.utc(startDate));
          plannerAssignment.set('endDate', _moment.default.utc(endDate));
          plannerAssignment.set('project', project);
          plannerAssignment.set('user', user);
          plannerAssignment.set('allocation', allocation);

          if (plannerAssignment.overlapsAnySibling()) {
            this.get('notifications').error('plannerAssignmentsMayNotOverlap');
            plannerAssignment.destroyRecord();
          } else {
            plannerAssignment.save().then(function () {
              _this2.get('viewport').selectDay(_moment.default.utc(startDate));

              _this2.get('activeModal').setPlannerAssignment(plannerAssignment.get('id'));

              if (plannerAssignment.inRange(_this2.get('viewport.startDate'), _this2.get('viewport.endDate'))) {
                user.get('plannerAssignments').pushObject(plannerAssignment);
                project.get('plannerAssignments').pushObject(plannerAssignment);
              }

              Ember.run.next(function () {
                _this2.sendAction('editAssignment', plannerAssignment,
                /*isInitialEditing*/
                true);
              });
            }).catch(function (error) {
              _this2.get('notifications').errorWithObject(error, 'alert.errorOnSave', 'plannerAssignment');
            });
          }
        }
      },
      createAssignmentForUser: function createAssignmentForUser(user, startDate, endDate, dailyAllocation) {
        startDate = startDate || this.get('viewport.defaultAssignmentStartDate');
        endDate = endDate || this.get('viewport.defaultAssignmentEndDate');
        this.send('createAssignment', user, this.get('model'), startDate, endDate, dailyAllocation);
      },
      afterUpdateAssignment: function afterUpdateAssignment(plannerAssignment) {
        this.sendAction('afterUpdateAssignment', plannerAssignment);
      },
      editAssignment: function editAssignment(plannerAssignment) {
        this.sendAction('editAssignment', plannerAssignment);
      },
      splitAssignment: function splitAssignment(plannerAssignment) {
        this.sendAction('splitAssignment', plannerAssignment, this.get('viewport.selectedDay'));
      },
      editMilestone: function editMilestone(milestone) {
        this.sendAction('editMilestone', milestone);
      },
      createMilestone: function createMilestone(date) {
        var _this3 = this;

        if (this.get('creatingMilestone') === true) {
          return;
        }

        this.set('creatingMilestone', true);
        var project = this.get('model');
        var milestone = this.get('store').createRecord('project-milestone');
        milestone.set('day', _moment.default.utc(date));
        milestone.set('project', project);
        milestone.save().then(function () {
          _this3.get('viewport').selectDay(_moment.default.utc(date));

          _this3.get('activeModal').setMilestone(milestone.get('id'));

          if (milestone.inRange(_this3.get('viewport.startDate'), _this3.get('viewport.endDate'))) {
            project.get('milestones').pushObject(milestone);
          }

          Ember.run.next(function () {
            _this3.sendAction('editMilestone', milestone,
            /*isInitialEditing*/
            true);

            _this3.set('creatingMilestone', false);
          });
        }).catch(function (error) {
          _this3.set('creatingMilestone', false);

          _this3.get('notifications').errorWithObject(error, 'alert.errorOnSave', 'milestone');
        });
      },
      completeProject: function completeProject(project) {
        this.sendAction('completeProject', project);
      },
      billProject: function billProject(project) {
        this.sendAction('billProject', project);
      },
      showTimeEntries: function showTimeEntries() {
        this.sendAction.apply(this, ['showTimeEntries'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });

  _exports.default = _default;
});