define("coffeecup/components/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'input',
    type: 'radio',
    classNameBindings: ['hasError'],
    attributeBindings: ['type', 'htmlChecked:checked', 'value', 'name', 'disabled'],
    htmlChecked: Ember.computed('value', 'checked', function () {
      return this.get('value') === this.get('checked');
    }),
    change: function change() {
      var val = this.get('value');

      if (!isNaN(val)) {
        val = parseInt(val);
      }

      this.set('checked', val);
    },
    _updateElementValue: Ember.observer('htmlChecked', function () {
      Ember.run.next(this, function () {
        this.$() && this.$().prop('checked', this.get('htmlChecked'));
      });
    })
  });

  _exports.default = _default;
});