define("coffeecup/components/absence-tracking/scheduler", ["exports", "moment", "coffeecup/config/environment", "coffeecup/utils/build-url", "coffeecup/utils/color-hex2rgba"], function (_exports, _moment, _environment, _buildUrl, _colorHex2rgba) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VERTICAL_PADDING = 45;
  var CALENDAR_CLASS = '.absence-scheduler-container';

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    formatter: Ember.inject.service(),
    events: [],
    resources: [],
    year: null,
    readOnly: false,
    isInitialLoading: true,
    clickedRequest: null,
    _eventsObserver: Ember.observer('events.[]', function () {
      Ember.run.later(this, function () {
        this.calendar('refetchEvents');
        this.calendar('gotoDate', (0, _moment.default)(this.get('year').toString(), 'YYYY'));
      });
    }),
    _resourcesObserver: Ember.observer('resources.[]', function () {
      Ember.run.later(this, function () {
        this.calendar('refetchResources');
      });
    }),
    _eventHandler: function _eventHandler(start, end, timezone, callback) {
      callback(this.get('events'));
    },
    _resourceHandler: function _resourceHandler(callback) {
      callback(this.get('resources'));
    },
    _handleResize: function _handleResize() {
      var elem = CALENDAR_CLASS && this && this.$ && this.$(CALENDAR_CLASS);

      if (elem) {
        elem.fullCalendar('option', 'resourceAreaWidth', this._calculateResourceAreaWidth());
        elem.fullCalendar('option', 'height', this._calculateHeight());
      }
    },
    calendar: function calendar(settings, value) {
      var elem = CALENDAR_CLASS && this && this.$ && this.$(CALENDAR_CLASS);

      if (elem) {
        elem.fullCalendar(settings, value);
        jQuery(window).on('resize', Ember.run.bind(this, this._handleResize));
      }
    },
    _calculateHeight: function _calculateHeight() {
      var container = this.$(CALENDAR_CLASS).parents('article:first');
      var containerHeight = container.height();
      var headerHeight = container.find('header:first').height();
      return containerHeight - headerHeight - VERTICAL_PADDING;
    },
    _calculateResourceAreaWidth: function _calculateResourceAreaWidth() {
      var container = this.$(CALENDAR_CLASS).parents('article:first');
      var containerWidth = container.width();

      if (containerWidth < 768) {
        return 70;
      } else {
        return 319;
      }
    },
    jumpToClickedRequest: Ember.observer('clickedRequest', function () {
      var _this2 = this;

      var clickedRequest = this.get('clickedRequest');

      if (clickedRequest && clickedRequest.get('startDate').format('YYYY') === this.get('year').toString()) {
        var startDate = clickedRequest.get('startDate');
        Ember.run.next(this, function () {
          _this2._goToDate(startDate.format(_environment.default.constants.DATE_FORMAT_DAY));
        });
      }
    }),
    _goToDate: function _goToDate(date) {
      var currentDatePosition = Ember.$('.fc-content').find("[data-date='" + date + "']").position();

      if (currentDatePosition) {
        Ember.$('.fc-scroller').scrollLeft(currentDatePosition.left - 150);
      }
    },

    /**
     * Scrolls to the row of today. 150 is substracted so that the days before are shown.
     * @private
     */
    _scrollToToday: function _scrollToToday() {
      if (this.get('clock.year').isSame((0, _moment.default)(this.get('year'), 'YYYY'), 'year')) {
        var position = Ember.$('.fc-today').position();
        if (position && position.left) Ember.$('.fc-scroller').scrollLeft(position.left - 150);
      }
    },
    _generateSlotLabelFormat: function _generateSlotLabelFormat() {
      if (this.get('firstDayOfWeek') === _environment.default.constants.WEEKDAY_MONDAY) {
        return ['MMMM YYYY', // top level of text
        '[' + this.get('i18n').t('calendarWeek') + ' ]W', // W = Monday first day of the week
        'DD'];
      } else {
        return ['MMMM YYYY', '[' + this.get('i18n').t('calendarWeek') + ' ]w', // w = Sunday first day of the week
        'DD'];
      }
    },
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      this._super();

      var i18n = this.get('i18n');
      var formatter = this.get('formatter');
      var readOnly = this.get('readOnly');

      var _this = this;

      var holidayList = _this.get('events').filterBy('type', 'holiday');

      var settings = {
        schedulerLicenseKey: _environment.default.constants.FULLCALENDAR_LICENSE_KEY,
        // Configuration
        header: false,
        slotWidth: 30,
        defaultDate: (0, _moment.default)(this.get('year'), 'YYYY'),
        resourceAreaWidth: _this._calculateResourceAreaWidth(),
        editable: !readOnly,
        locale: _moment.default.locale(),
        height: _this._calculateHeight(),
        slotLabelFormat: this._generateSlotLabelFormat(),
        defaultView: 'timelineYear',
        resourceColumns: [{
          labelText: '',
          render: function render(resource, element) {
            element.html("<div class=\"fc-user-avatar-wrapper text-overflow\">\n                ".concat(resource.user.get('imageSmallHTML').string, "\n                <div class=\"hidden-xs\">\n                  <div>\n                    ").concat(resource.user.get('fullName'), "\n                    ").concat(!resource.userTag ? '' : "&nbsp;<span class=\"mini bg-light-gray label label-default code-label text-overflow expandable\">".concat(resource.userTag, "</span>"), "\n                  </div>\n                  <div class=\"subtitle\">\n                    ").concat(resource.user.team ? resource.user.team.name : '', "\n                  <div>\n                </div>\n              </div>"));
          }
        }],
        // Content of calendar
        events: Ember.$.proxy(this._eventHandler, this),
        resources: Ember.$.proxy(this._resourceHandler, this),
        // Events
        eventClick: Ember.$.proxy(function (event) {
          if (readOnly) {
            return;
          }

          _this3.onEventClick(event);
        }, this),
        dayClick: Ember.$.proxy(function (date, jsEvent, view, resourceObj) {
          if (readOnly) {
            return;
          }

          _this3.onDayClick(date, resourceObj.id);
        }, this),
        eventDrop: Ember.$.proxy(function (event, jsEvent, ui, view) {
          if (readOnly) {
            return;
          }

          _this3.onEventEdit(event);
        }, this),
        eventResizeStop: Ember.$.proxy(function (event, jsEvent, ui, view) {
          if (readOnly) {
            return;
          }

          Ember.run.later(_this3, function () {
            _this3.onEventEdit(event);
          });
        }, this),
        // Rendering functions
        dayRender: function dayRender(date, cell) {
          holidayList.forEach(function (holiday) {
            if (holiday.start.isSame(date)) {
              cell.addClass('holiday');
              cell.find('> div').addClass('fc-holiday-title').text(holiday.title);
            }
          });
        },
        eventRender: function eventRender(e, $el, view) {
          var _e$type, _e$type3, _e$type3$get;

          var isPublicHoliday = (_e$type = e.type) === null || _e$type === void 0 ? void 0 : _e$type.isPublicHoliday;
          var typeClass = e.type ? e.type.get('iconClass') : _environment.default.constants.ABSENCE_TYPE_ANONYMOUS_VALUE;
          var typeColor = e.type ? e.type.get('colorCode') : _environment.default.constants.ABSENCE_TYPE_ANONYMOUS_VALUE;
          var borderColor = typeColor;
          var typeImage = '<span class="calendar-icon icomoon-' + typeClass + '"></span>';

          if (!isPublicHoliday) {
            $el.find('.fc-time').html(typeImage);

            if (e.comment) {
              $el.find('.fc-title').html(e.comment);
            } else {
              $el.find('.fc-title').html('');
            }
          }

          $el.mouseenter(function () {
            if (e.usedAbsenceDays === undefined) {
              var tooltipOptions = {
                contentAsHTML: true,
                delay: 0,
                animationDuration: 0,
                theme: 'tooltipster-borderless'
              };

              if (!isPublicHoliday) {
                tooltipOptions.functionBefore = function (instance, helper) {
                  _this.get('session').getJSON((0, _buildUrl.default)(_this.get('store'), 'absences/numberOfUsedUserWorkingDaysForDateRange?' + e.urlParams)).then(function (data) {
                    var _e$type2;

                    if ((_e$type2 = e.type) === null || _e$type2 === void 0 ? void 0 : _e$type2.isPublicHoliday) {
                      instance.content(e.comment);
                    } else {
                      instance.content(i18n.t('absenceDurationTooltip', {
                        title: "".concat(e.type ? e.type.get('localizedLabel') : i18n.t('unknownLabel'), " - ").concat(e.title),
                        startDate: formatter.formatDate(e.start),
                        endDate: formatter.formatDate((0, _moment.default)(e.end).subtract(1, 'day')),
                        duration: data.usedAbsenceDays
                      }).string);
                    }
                  });
                };
              }

              tooltipOptions.content = isPublicHoliday ? e.comment : 'Loading...';
              $el.tooltipster(tooltipOptions).tooltipster('open');
            }
          });

          if (e.options && e.options.isRequest) {
            typeClass += ' request';
            typeColor = (0, _colorHex2rgba.convertHex2Rgba)(typeColor, 45);
          }

          var parentEl = $el.find('.fc-content').parent();

          if ((_e$type3 = e.type) === null || _e$type3 === void 0 ? void 0 : (_e$type3$get = _e$type3.get) === null || _e$type3$get === void 0 ? void 0 : _e$type3$get.call(_e$type3, 'isPublicHoliday')) {
            $el.addClass('public-holiday');
          } else {
            parentEl.addClass(typeClass).css('border-color', borderColor).css('background-color', typeColor);
          }

          if (e.halfDay) {
            parentEl.addClass('half-day');
          }
        },
        eventAfterAllRender: function eventAfterAllRender(view) {
          Ember.run.later(_this, function () {
            if (_this.get('isInitialLoading')) {
              try {
                _this._scrollToToday();

                _this.set('isInitialLoading', false);
              } catch (e) {}
            } else if (_this.get('clickedRequest')) {
              _this.jumpToClickedRequest();
            }
          }, 500);
        }
      };
      Ember.run.scheduleOnce('afterRender', this, function () {
        // some jQuery UI stuff
        this.calendar(settings);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.calendar('destroy');
    }
  });

  _exports.default = _default;
});