define("coffeecup/components/absence-tracking/calendar", ["exports", "moment", "coffeecup/config/environment", "coffeecup/utils/build-url", "coffeecup/utils/color-hex2rgba"], function (_exports, _moment, _environment, _buildUrl, _colorHex2rgba) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VERTICAL_PADDING = 45;
  var CALENDAR_CLASS = '.absence-calendar-container';

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    formatter: Ember.inject.service(),
    events: [],
    month: null,
    readOnly: false,
    _eventsObserver: Ember.observer('events.[]', function () {
      this.calendar('gotoDate', this.get('month'));
      this.calendar('refetchEvents');
    }),
    _eventHandler: function _eventHandler(start, end, timezone, callback) {
      callback(this.get('events'));
    },
    calendar: function calendar(settings, value) {
      var elem = CALENDAR_CLASS && this && this.$ && this.$(CALENDAR_CLASS);
      elem && elem.fullCalendar(settings, value);
    },
    _calculateHeight: function _calculateHeight() {
      var container = this.$(CALENDAR_CLASS).parents('article:first');
      var containerHeight = container.height();
      var headerHeight = container.find('header:first').height();
      return containerHeight - headerHeight - VERTICAL_PADDING;
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super();

      var i18n = this.get('i18n');
      var formatter = this.get('formatter');
      var readOnly = this.get('readOnly');

      var _this = this;

      var settings = {
        schedulerLicenseKey: _environment.default.constants.FULLCALENDAR_LICENSE_KEY,
        header: false,
        events: Ember.$.proxy(this._eventHandler, this),
        columnFormat: 'dddd',
        locale: _moment.default.locale(),
        weekNumbers: true,
        weekNumbersWithinDays: true,
        height: Ember.$.proxy(this._calculateHeight, this),
        defaultDate: (0, _moment.default)(this.get('month')),
        dayClick: Ember.$.proxy(function (day) {
          _this2.onDayClick(day);
        }, this),
        eventClick: Ember.$.proxy(function (event) {
          if (readOnly) {
            return;
          }

          _this2.onEventClick(event);
        }, this),
        eventRender: function eventRender(e, $el, view) {
          if (e.type === 'holiday' || e.type === 'freeday') {
            var dateSelector = '"' + e.start.format(_environment.default.constants.DATE_FORMAT_YYYYMMDD_DASH) + '"';
            Ember.$('td[data-date=' + dateSelector + ']').addClass(e.type);
            var description = '';

            if (e.type === 'holiday') {
              description = e.title;
            }

            Ember.$('td[data-date=' + dateSelector + '].fc-day').html('<div class="specialday-description text-overflow">' + description + '<span class="calendar-icon icomoon-church small-space-left"></span>' + '</div>');
            $el.css('display', 'none'); // } else if () {
          } else {
            var _e$type, _e$type$get;

            var isPublicHoliday = (_e$type = e.type) === null || _e$type === void 0 ? void 0 : (_e$type$get = _e$type.get) === null || _e$type$get === void 0 ? void 0 : _e$type$get.call(_e$type, 'isPublicHoliday');

            if (isPublicHoliday) {
              $el.addClass('public-holiday');
            } else {
              var type = e.type ? e.type.get('iconClass') : _environment.default.constants.ABSENCE_TYPE_ANONYMOUS_VALUE;
              var colorCode = e.type ? e.type.get('colorCode') : _environment.default.constants.ABSENCE_TYPE_ANONYMOUS_VALUE;
              var typeImage = '<span class="calendar-icon icomoon-' + type + '"></span>';
              $el.find('.fc-time').html(typeImage);
              $el.find('.fc-content').parent().css('border-color', colorCode);

              if (e.options && e.options.isRequest) {
                type += ' request';
                colorCode = (0, _colorHex2rgba.convertHex2Rgba)(colorCode, 45);
              }

              $el.find('.fc-content').parent().addClass(type);
              $el.find('.fc-content').parent().css('background-color', colorCode);
            }

            var tooltipOptions = {
              contentAsHTML: true,
              theme: 'tooltipster-borderless',
              delay: 0,
              animationDuration: 0
            };
            $el.mouseenter(function () {
              if (e.usedAbsenceDays === undefined) {
                if (!isPublicHoliday) {
                  tooltipOptions.functionBefore = function (instance, helper) {
                    _this.get('session').getJSON((0, _buildUrl.default)(_this.get('store'), 'absences/numberOfUsedUserWorkingDaysForDateRange?' + e.urlParams)).then(function (data) {
                      if (e && e.type) {
                        instance.content(i18n.t('absenceDurationTooltip', {
                          title: "".concat(e.type.get('localizedLabel'), " - ").concat(e.title),
                          startDate: formatter.formatDate(e.start),
                          endDate: formatter.formatDate((0, _moment.default)(e.end).subtract(1, 'day')),
                          duration: data.usedAbsenceDays
                        }).string);
                      }
                    });
                  };
                }

                if (!_this.get('readOnly')) {
                  tooltipOptions.content = isPublicHoliday ? e.comment : 'Loading...';
                  $el.tooltipster(tooltipOptions).tooltipster('open');
                }
              }
            });
          }
        }
      };
      Ember.run.scheduleOnce('afterRender', this, function () {
        // some jQuery UI stuff
        this.calendar(settings);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.calendar('destroy');
    }
  });

  _exports.default = _default;
});