define("coffeecup/components/percentage-difference", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['percentage-difference'],
    val: 0,
    old: 0,
    label: '',
    // positions behind the decimal point to determine direction arrows
    accuracy: 2,
    valRounded: Ember.computed('val', {
      get: function get() {
        var val = this.get('val');
        var accuracy = this.get('accuracy');
        return Number(parseFloat(val).toFixed(accuracy));
      }
    }),
    oldRounded: Ember.computed('old', {
      get: function get() {
        var old = this.get('old');
        var accuracy = this.get('accuracy');
        return Number(parseFloat(old).toFixed(accuracy));
      }
    }),
    direction: Ember.computed('valRounded', 'oldRounded', {
      get: function get() {
        var valRounded = parseFloat(this.get('valRounded'));
        var oldRounded = parseFloat(this.get('oldRounded'));

        if (valRounded > oldRounded) {
          return 'up';
        }

        if (valRounded < oldRounded) {
          return 'down';
        }

        if (valRounded === oldRounded) {
          return 'equal';
        }
      }
    }),
    up: Ember.computed.equal('direction', 'up'),
    down: Ember.computed.equal('direction', 'down'),
    equal: Ember.computed.equal('direction', 'equal'),
    diff: Ember.computed('val', 'old', {
      get: function get() {
        var val = parseFloat(this.get('val'));
        var old = parseFloat(this.get('old'));
        return (val - old) / 2;
      }
    })
  });

  _exports.default = _default;
});