define("coffeecup/components/time-entry/bottom", ["exports", "coffeecup/utils/color-hex2rgba"], function (_exports, _colorHex2rgba) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'section',
    attributeBindings: ['style'],
    classNames: ['bottom'],
    backgroundColor: null,
    style: Ember.computed('backgroundColor', {
      get: function get() {
        var backgroundColor = this.get('backgroundColor');

        if (backgroundColor) {
          return Ember.String.htmlSafe("background-color: ".concat(backgroundColor, ";"));
        }
      }
    }),
    topGradientStyle: Ember.computed('backgroundColor', {
      get: function get() {
        var backgroundColor = this.get('backgroundColor');

        if (backgroundColor) {
          var from = _colorHex2rgba.RGBA.convertFromHex(backgroundColor, 0.1);

          var to = _colorHex2rgba.RGBA.convertFromHex(backgroundColor, 1);

          return Ember.String.htmlSafe("background: linear-gradient(to bottom, ".concat(from.toCSS(), " 0%,").concat(to.toCSS(), " 100%);"));
        }
      }
    }),
    gradientStyle: Ember.computed('backgroundColor', {
      get: function get() {
        var backgroundColor = this.get('backgroundColor');

        if (backgroundColor) {
          var from = _colorHex2rgba.RGBA.convertFromHex(backgroundColor, 0.1);

          var to = _colorHex2rgba.RGBA.convertFromHex(backgroundColor, 1);

          return Ember.String.htmlSafe("background: linear-gradient(to right, ".concat(from.toCSS(), " 0%,").concat(to.toCSS(), " 100%);"));
        }
      }
    })
  });

  _exports.default = _default;
});