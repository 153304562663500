define("coffeecup/adapters/application", ["exports", "coffeecup/adapters/-super-sails-socket", "coffeecup/mixins/cascade-delete"], function (_exports, _superSailsSocket, _cascadeDelete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // We inherit from the Socker Adapter rather than REST, because we need to rely
  // on the subscriptions that happen in the mixin on the Ember Data Store.
  var ApplicationAdapter = _superSailsSocket.default.extend(_cascadeDelete.default, {
    /**
     * List of models that the adapter is subscribed to for receiving socket updated messages
     */
    subscribedModels: [],
    session: Ember.inject.service(),
    _restAdapter: Ember.computed({
      get: function get() {
        var owner = Ember.getOwner(this);
        var restAdapter = owner.lookup('adapter:rest');
        return restAdapter;
      }
    }),
    access_token: Ember.computed.alias('session.data.authenticated.access_token'),

    /**
     * Schedule a record subscription
     *
     * @since 0.0.11
     * @method _scheduleSubscribe
     * @param {subclass of DS.Model} type
     * @param {String|Number} id
     * @private
     */
    _scheduleSubscribe: function _scheduleSubscribe(type, id) {
      if (this._shouldSubscribe(type.modelName)) {
        this._super(type, id);
      }
    },
    _resubscribeToModels: Ember.observer('access_token', function () {
      var _this = this;

      var subscribedModels = this.get('subscribedModels');
      var out = {};
      Ember.run.later(function () {
        subscribedModels.forEach(function (model) {
          var subscribeUrl = _this.host + '/' + _this.namespace + '/' + model + '/subscribe';

          _this._request(out, subscribeUrl, 'GET', {}, true);

          console.info('Socket for %s model resubscribed ', model);
        });
      }, 500);
    }),
    _shouldSubscribe: function _shouldSubscribe(modelName) {
      var key = this.pathForType(modelName);

      if (this.get('subscribedModels').indexOf(key) === -1) {
        return key;
      }

      return false;
    },

    /**
     * @param url
     * @returns {string}
     * @private
     */
    _getModelFromUrl: function _getModelFromUrl(url) {
      var baseURL = this.host + '/' + this.namespace + '/';
      var model = url.substring(baseURL.length);

      if (model.indexOf('/') > 0) {
        model = model.substring(0, model.indexOf('/'));
      }

      if (model.indexOf('?') > 0) {
        model = model.substring(0, model.indexOf('?'));
      }

      return model;
    },
    _request: function _request(out, url, method, options, isResubscribe) {
      var model = this._getModelFromUrl(url);

      var subscribeUrl = this.host + '/' + this.namespace + '/' + model + '/subscribe';

      if (isResubscribe) {
        this._super(out, subscribeUrl, 'GET', {});
      } else {
        if (model = this._shouldSubscribe(model)) {
          this._super(out, subscribeUrl, 'GET', {});

          this.get('subscribedModels').push(model);
          console.info('Socket for %s model created ', model);
        }

        var restAdapter = this.get('_restAdapter');
        return restAdapter._request.apply(restAdapter, arguments);
      }
    },
    _handleSocketRecordCreated: function _handleSocketRecordCreated(store, type, message) {
      var nonceToken = this.get('_restAdapter.nonceToken');

      if (message.nonce !== nonceToken) {
        try {
          var normalizedType = Ember.String.camelize(type.modelName);
          var record = store.push(store.normalize(normalizedType, message.data)); // store.push does not trigger didUpdate on a model, so we have to manually trigger
          // our archivation cascading logic here
          // https://github.com/emberjs/data/issues/3748

          if (record.checkForCascadingArchivation && typeof record.checkForCascadingArchivation === 'function') {
            record.checkForCascadingArchivation();
          }

          console.info('Record of type %s created ', normalizedType);
        } catch (err) {
          console.warn('Record of type %s could not be saved ', type, err);
        }
      } // Should we generate a new token?
      // TODO: Check For Race Condition
      // this.get('_restAdapter').generateNewNonceToken();
      // console.info('New Nonce Token generated.');

    },
    _handleSocketRecordDeleted: function _handleSocketRecordDeleted(store, type, message) {
      var nonceToken = this.get('_restAdapter.nonceToken');

      if (message.nonce !== nonceToken) {
        try {
          var normalizedType = Ember.String.camelize(type.modelName);
          var record = store.peekRecord(normalizedType, message.id);
          record.deleteRecord();
          record.unloadRecord();
          console.info('Record of type %s deleted ', normalizedType);
        } catch (err) {
          console.warn('Record of type %s could not be deleted ', type, err);
        }
      }
    },
    _handleSocketRecordUpdated: function _handleSocketRecordUpdated() {
      return this._handleSocketRecordCreated.apply(this, arguments);
    },

    /**
     @private
     @method query
     @param {DS.Store} store
     @param {DS.Model} type
     @param {Object} query
     @return {Promise} promise
     */
    query: function query(store, type, _query) {
      if (_query && _query['where'] && JSON.stringify) {
        _query['where'] = JSON.stringify(_query['where']);
      }

      return this._super.apply(this, arguments);
    },
    createRecord: function createRecord(store, type, snapshot) {
      var normalizedType = Ember.String.camelize(type.modelName);
      var modelName = type.modelName;

      var saving = this._super(store, type, snapshot);

      return new Ember.RSVP.Promise(function (resolve, reject) {
        saving.then(function (payload) {
          var id = payload[normalizedType].id;
          var existingInternalModel = store.peekRecord(modelName, id);

          if (!Ember.isNone(existingInternalModel)) {
            store.unloadRecord(existingInternalModel);
            Ember.run.next(function () {
              resolve(payload);
            });
          } else {
            resolve(payload);
          }
        }, function (error) {
          reject(error);
        });
      });
    }
  });

  var _default = ApplicationAdapter;
  _exports.default = _default;
});