define("coffeecup/controllers/team/users/analytics", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberController$ext;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend((_EmberController$ext = {
    /*
    INJECTIONS
     */
    formatter: Ember.inject.service(),
    i18n: Ember.inject.service(),
    permissions: Ember.inject.service(),
    workingDaysService: Ember.inject.service('working-days'),
    application: Ember.inject.controller(),
    currentUser: Ember.computed.alias('application.currentUser'),
    hasAbsenceIntegration: Ember.computed.alias('permissions.absenceIntegrationActive'),

    /*
    PROPERTIES
     */
    year: Ember.computed('clock.year', function () {
      return this.get('clock.year').format('YYYY');
    }),
    user: null,
    vacationEntries: null,
    projects: [],
    userEmployments: null,
    userAssignments: null,
    userStartDate: null,
    alertColor: _environment.default.constants.ALERT_COLOR,
    hourlyRate: 0,

    /*
    COMPUTED PROPERTIES
     */
    isSameUser: Ember.computed('currentUser', 'user', function () {
      return this.get('currentUser.id') === this.get('user.id');
    }),
    meetsTeamStaffPermission: Ember.computed('user.id', 'permissions.limitedTeamStaffAccess', function () {
      return this.get('permissions.limitedTeamStaffAccess') && this.get('currentUser.teamAssignment.team') === this.get('user.teamAssignment.team');
    }),
    canAccessDetails: Ember.computed.or('permissions.canAccessUsers', 'isSameUser', 'permissions.isRootAdmin'),
    canAccessDocuments: Ember.computed.or('permissions.isRootAdmin', 'permissions.canAccessUsers', 'isSameUser'),
    canAccessRemunerations: Ember.computed.or('permissions.isRootAdmin', 'canAccessRemunerations', 'isSameUser')
  }, _defineProperty(_EmberController$ext, "canAccessRemunerations", Ember.computed('user.id', 'permissions.canAccessRemunerations', 'permissions.canAccessAdminRemunerations', function () {
    var user = this.user;
    var canAccessRemunerations = this.permissions.canAccessRemunerations;
    var canAccessAdminRemunerations = this.permissions.canAccessAdminRemunerations;
    return canAccessAdminRemunerations || user && !user.get('role.isRootAdmin') && canAccessRemunerations;
  })), _defineProperty(_EmberController$ext, "canAccessUserRevenue", Ember.computed.or('permissions.isRootAdmin', 'meetsTeamStaffPermission')), _defineProperty(_EmberController$ext, "currentEmploymentEntry", Ember.computed('userEmployments', function () {
    var userEmployments = this.get('userEmployments').sortBy('validFrom');
    var currentEntry = null;
    var existingEntries = userEmployments.length;

    if (existingEntries > 0) {
      this.set('userStartDate', userEmployments[0].get('validFrom'));
      var today = this.get('clock.day');
      var entry = userEmployments.pop();

      while (entry) {
        var validFrom = entry.get('validFrom');

        if (!entry.get('isDeleted') && today.diff(moment(validFrom), 'days') >= 0) {
          currentEntry = entry;
          break;
        }

        entry = userEmployments.pop();
      }
    }

    return currentEntry;
  })), _defineProperty(_EmberController$ext, "vacationDays", Ember.computed('vacationEntries', function () {
    var vacationTaken = 0;
    var total = 0;
    var currentYearContingent = 0;
    var vacationEntries = this.get('vacationEntries');
    vacationEntries.forEach(function (entry) {
      if (entry.vacationType === _environment.default.constants.VACATION_TYPE_VACATION_TAKEN) {
        vacationTaken += Math.abs(entry.quantity);
      } else {
        total += entry.quantity;
      }

      if (entry.vacationType === 1) {
        currentYearContingent = entry.quantity;
      }
    });
    var remaining = total - vacationTaken;
    return {
      remaining: remaining,
      total: total,
      currentYearContingent: currentYearContingent
    };
  })), _defineProperty(_EmberController$ext, "remunerationModule", Ember.computed('currentUser', function () {
    var _this = this;

    var i18n = this.get('i18n');
    var uiState = this.get('currentUser.uiState');
    return Ember.Object.create({
      settingsObj: uiState.get('analyticsModuleState.remuneration') || {},
      localizedLabel: i18n.t('hoursModuleTitle'),
      isTypeUserWorktime: true,
      save: function save() {
        _this._updateAnalyticsModule('remuneration');
      }
    });
  })), _defineProperty(_EmberController$ext, "revenueModule", Ember.computed('currentUser', function () {
    var _this2 = this;

    var i18n = this.get('i18n');
    var uiState = this.get('currentUser.uiState');
    var revenueModule = Ember.Object.create({
      settingsObj: uiState.get('state.analyticsModuleState.revenue') || {},
      localizedLabel: i18n.t('hoursModuleTitle'),
      isTypeUserWorktime: true,
      save: function save() {
        _this2._updateAnalyticsModule('revenue');
      }
    });
    return revenueModule;
  })), _defineProperty(_EmberController$ext, "hoursModule", Ember.computed('currentUser', function () {
    var _this3 = this;

    var i18n = this.get('i18n');
    var uiState = this.get('currentUser.uiState');
    return Ember.Object.create({
      settingsObj: uiState.get('state.analyticsModuleState.hours') || {},
      constructor: {
        modelName: 'dashboard-module'
      },
      localizedLabel: i18n.t('hoursModuleTitle'),
      isTypeUserWorktime: true,
      save: function save() {
        _this3._updateAnalyticsModule('hours');
      }
    });
  })), _defineProperty(_EmberController$ext, "_updateAnalyticsModule", function _updateAnalyticsModule(moduleName) {
    var currentUser = this.get('currentUser');
    var analyticsModuleState = currentUser.get('uiState.analyticsModuleState');
    analyticsModuleState[moduleName] = this.get(moduleName + 'Module.settingsObj');
    currentUser.get('uiState').setProperties({
      analyticsModuleState: analyticsModuleState
    });
    currentUser.save();
  }), _defineProperty(_EmberController$ext, "actions", {
    closePane: function closePane() {
      this.transitionToRoute('team');
    }
  }), _EmberController$ext));

  _exports.default = _default;
});