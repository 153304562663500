define("coffeecup/controllers/analytics/time-entries", ["exports", "coffeecup/config/environment", "coffeecup/mixins/pagination-params"], function (_exports, _environment, _paginationParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var TimeEntriesController = Ember.Controller.extend(_paginationParams.default, {
    timeEntryService: Ember.inject.service('time-entry'),
    projectService: Ember.inject.service('project'),
    userService: Ember.inject.service('user'),
    currentUserService: Ember.inject.service('currentUser'),
    clientService: Ember.inject.service('client'),
    taskService: Ember.inject.service('task'),
    teamService: Ember.inject.service('team'),
    responsive: Ember.inject.service(),
    easybill: Ember.inject.service(),
    permissions: Ember.inject.service(),
    application: Ember.inject.controller(),
    currentUser: Ember.computed.reads('application.currentUser'),
    currentUserIsAdmin: Ember.computed.alias('application.currentUserIsAdmin'),
    currentUserIsDefaultUser: Ember.computed.not('permissions.canAccessProjectAnalytics'),
    currentUserCanFilterStaff: Ember.computed('currentUserIsDefaultUser', 'permissions.canViewTeamTimeEntries', function () {
      return !this.get('currentUserIsDefaultUSer') || this.get('permissions.canVeiwTeamTimeEntries');
    }),
    isIndeterminate: Ember.computed('checkedArray', function () {
      return this.get('checkedArray').length > 0 && this.get('checkedArray').length < Math.min(this.get('timeEntries').meta.total, this.get('page') * this.get('limit') + this.get('limit'));
    }),
    isPmOfOneOfTheProjects: Ember.computed('model.@each.project.userAssignments', {
      get: function get() {
        var currentUserIsAdmin = this.get('currentUserIsAdmin');
        var currentUser = this.get('currentUser');
        var timeEntries = this.get('model');
        var userAssignments = [];

        if (currentUserIsAdmin) {
          return true;
        }

        if (timeEntries && timeEntries.get('length') > 0) {
          var groupedByProjects = timeEntries.uniqBy('project.id');

          if (!groupedByProjects) {
            return false;
          }

          groupedByProjects.forEach(function (entry) {
            if (entry.get('project.userAssignments')) {
              entry.get('project.userAssignments').forEach(function (assgnmt) {
                userAssignments.pushObject(assgnmt);
              });
            }
          });
          var projectsWhereUserIsPm = this.get('projectService').projectsWhereUserIsPm(groupedByProjects.getEach('project'), currentUser);
          return projectsWhereUserIsPm && projectsWhereUserIsPm.get('length') > 0;
        }

        return false;
      }
    }),
    isNotPmOfOneOfTheProjects: Ember.computed.not('isPmOfOneOfTheProjects'),
    isDefaultUser: Ember.computed.or('currentUserIsDefaultUser', 'isNotPmOfOneOfTheProjects'),
    filterHeight: 0,
    _filterHeight: Ember.observer('client', 'project', 'task', 'tag', 'user', 'timeEntryReference', 'showFilter', 'team', function () {
      var _this2 = this;

      if (!(this.get('responsive').isMobileWidth() || this.get('responsive').isTabletWidth())) {
        Ember.run.next(function () {
          _this2.set('filterHeight', $('.option-buttons').outerHeight());
        });
      } else {
        this.set('filterHeight', 0);
      }
    }),
    init: function init() {
      var _this3 = this;

      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, function () {
        _this3._filterHeight();
      });
      $(window).on('resize', Ember.run.bind(this, this._filterHeight));
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      $(window).off('resize', Ember.run.bind(this, this._filterHeight));
    },

    /**
     * url query params
     */
    queryParams: ['from', 'to', {
      client: {
        type: 'array'
      }
    }, {
      project: {
        type: 'array'
      }
    }, {
      invoice: {
        type: 'array'
      }
    }, {
      task: {
        type: 'array'
      }
    }, {
      tag: {
        type: 'array'
      }
    }, {
      timeEntryReference: {
        type: 'array'
      }
    }, {
      user: {
        type: 'array'
      }
    }, {
      team: {
        type: 'array'
      }
    }, 'limit', 'sort', 'page', 'status', 'billableType'],

    /**
     * number of timeEntries to be displayed
     */
    limit: 100,

    /**
     * the sorting direction
     */
    sort: _environment.default.constants.SORT_DESC,

    /**
     * query param from date
     */
    fromDefaultValue: Ember.computed('clock.month', function () {
      return this.get('clock.month').subtract(1, 'month').startOf('month').format(_environment.default.constants.DATE_FORMAT_DAY);
    }),
    from: Ember.computed.reads('fromDefaultValue'),

    /**
     * query param to date
     */
    toDefaultValue: Ember.computed('clock.month', function () {
      return this.get('clock.month').subtract(1, 'month').endOf('month').format(_environment.default.constants.DATE_FORMAT_DAY);
    }),
    to: Ember.computed.reads('toDefaultValue'),

    /**
     * array of filtered clients
     */
    client: [],

    /**
     * array of filtered projects
     */
    project: [],

    /**
     * array of filtered tasks
     */
    task: [],

    /**
     * array of filtered teams
     */
    team: [],

    /**
     * array of filtered users
     */
    user: [],
    timeEntryReference: [],
    tag: [],
    invoice: [],
    comment: '',
    screen: Ember.inject.service(),
    _resetClientSelection: Ember.observer('client', function () {
      var _this4 = this;

      var clientIds = this.get('client');

      if (clientIds && _typeof(clientIds) === 'object') {
        clientIds.forEach(function (clientId) {
          var client = clientId && _this4.store.peekRecord('client', clientId);

          if (!client) {
            clientIds.removeObject(clientId);
          }
        });
        this.set('client', clientIds);
      } else {
        this.set('client', []);
      }
    }),
    _resetProjectSelection: Ember.observer('project', function () {
      var _this5 = this;

      var projectIds = this.get('project');

      if (projectIds && _typeof(projectIds) === 'object') {
        projectIds.forEach(function (projectId) {
          var project = projectId && _this5.store.peekRecord('project', projectId);

          if (!project) {
            projectIds.removeObject(projectId);
          }
        });
        this.set('project', projectIds);
      } else {
        this.set('project', []);
      }
    }),
    _resetTaskSelection: Ember.observer('task', function () {
      var _this6 = this;

      var taskIds = this.get('task');

      if (taskIds && _typeof(taskIds) === 'object') {
        taskIds.forEach(function (taskId) {
          var task = taskId && _this6.store.peekRecord('task', taskId);

          if (!task) {
            taskIds.removeObject(taskId);
          }
        });
        this.set('task', taskIds);
      } else {
        this.set('task', []);
      }
    }),
    _resetUserSelection: Ember.observer('user', function () {
      var _this7 = this;

      var userIds = this.get('user');

      if (userIds && _typeof(userIds) === 'object') {
        userIds.forEach(function (userId) {
          var user = userId && _this7.store.peekRecord('user', userId);

          if (!user) {
            userIds.removeObject(userId);
          }
        });
        this.set('user', userIds);
      } else {
        this.set('user', []);
      }
    }),
    _resetTimeEntryReferenceSelection: Ember.observer('timeEntryReference', function () {
      var _this8 = this;

      var timeEntryReferenceIds = this.get('timeEntryReference');

      if (timeEntryReferenceIds && _typeof(timeEntryReferenceIds) === 'object') {
        timeEntryReferenceIds.forEach(function (timeEntryReferenceId) {
          var timeEntryReference = timeEntryReferenceId && _this8.store.peekRecord('timeEntryReference', timeEntryReferenceId);

          if (!timeEntryReference) {
            timeEntryReferenceIds.removeObject(timeEntryReferenceId);
          }
        });
        this.set('timeEntryReference', timeEntryReferenceIds);
      } else {
        this.set('timeEntryReference', []);
      }
    }),
    _resetTagSelection: Ember.observer('tag', function () {
      var _this9 = this;

      var tagIds = this.get('tag');

      if (tagIds && _typeof(tagIds) === 'object') {
        tagIds.forEach(function (tagId) {
          var tag = tagId && _this9.store.peekRecord('tag', tagId);

          if (!tag) {
            tagIds.removeObject(tagId);
          }
        });
        this.set('tag', tagIds);
      } else {
        this.set('tag', []);
      }
    }),

    /**
     * array of timeEntries
     */
    timeEntries: Ember.computed.alias('model'),

    /**
     * array of selectedTimeEntries
     */
    //selectedTimeEntries: Ember.A(),
    showBillingButton: Ember.computed('timeEntries.length', 'project', {
      get: function get() {
        var timeEntriesLength = this.get('timeEntries.length');
        var oneProject = this.get('project.length') === 1;
        return timeEntriesLength && oneProject;
      }
    }),

    /**
     * array of all clients
     */
    clients: Ember.computed.reads('application.allClients'),

    /**
     * array of all clients sorted by label
     */
    sortedClients: Ember.computed('clients', {
      get: function get() {
        return this.get('clientService').clientsSortedAndGroupedByInactive(this.get('clients'));
      }
    }),
    selectedClients: Ember.computed('client', function () {
      if (!this.get('client') || !this.get('client').map) return [];
      var clients = this.get('clients');
      return this.get('client').map(function (clientId) {
        if (clients.isAny('id', clientId.toString())) {
          return clients.findBy('id', clientId.toString());
        }
      });
    }),
    selectedUsers: Ember.computed('user', function () {
      if (!this.get('user') || !this.get('user').map) return [];
      var users = this.get('users');
      return this.get('user').map(function (userId) {
        if (users.isAny('id', userId.toString())) {
          return users.findBy('id', userId.toString());
        }
      });
    }),
    selectedProjects: Ember.computed('project', function () {
      if (!this.get('project') || !this.get('project').map) return [];
      var projects = this.get('projects');
      return this.get('project').map(function (projectId) {
        if (projects.isAny('id', projectId.toString())) {
          return projects.findBy('id', projectId.toString());
        }
      });
    }),
    selectedTasks: Ember.computed('task', function () {
      if (!this.get('task') || !this.get('task').map) return [];
      var tasks = this.get('tasks');
      return this.get('task').map(function (taskId) {
        if (tasks.isAny('id', taskId.toString())) {
          return tasks.findBy('id', taskId.toString());
        }
      });
    }),
    selectedTeams: Ember.computed('team', function () {
      if (!this.get('team') || !this.get('team').map) return [];
      var teams = this.get('teams');
      return this.get('team').map(function (teamId) {
        if (teams.isAny('id', teamId.toString())) {
          return teams.findBy('id', teamId.toString());
        }
      });
    }),
    selectedTimeEntryReferences: Ember.computed('timeEntryReference', function () {
      if (!this.get('timeEntryReference') || !this.get('timeEntryReference').map) return [];
      var timeEntryReferences = this.get('timeEntryReferences');
      return this.get('timeEntryReference').map(function (timeEntryReferenceId) {
        if (timeEntryReferences.isAny('id', timeEntryReferenceId.toString())) {
          return timeEntryReferences.findBy('id', timeEntryReferenceId.toString());
        }
      });
    }),
    selectedTags: Ember.computed('tag', function () {
      if (!this.get('tag') || !this.get('tag').map) return [];
      var tags = this.get('tags');
      return this.get('tag').map(function (tagId) {
        if (tags.isAny('id', tagId.toString())) {
          return tags.findBy('id', tagId.toString());
        }
      });
    }),
    selectedInvoices: Ember.computed('invoice', function () {
      if (!this.get('invoice') || !this.get('invoice').map) return [];
      var invoices = this.get('invoices');
      return this.get('invoice').map(function (invoiceId) {
        if (invoices.isAny('id', invoiceId.toString())) {
          return invoices.findBy('id', invoiceId.toString());
        }
      });
    }),

    /**
     * array of all projects
     */
    projects: Ember.computed.reads('application.allProjects'),

    /**
     * array of all projects sorted by label
     */
    projectSortKeys: _environment.default.constants.PROJECT_SORT_KEYS,
    sortedProjects: Ember.computed.sort('projects', 'projectSortKeys'),
    groupedProjects: Ember.computed('sortedProjects', {
      get: function get() {
        return this.get('projectService').projectsGroupedByClientAndInactive(this.get('sortedProjects'));
      }
    }),

    /**
     * array of all teams
     */
    teams: Ember.computed.reads('application.allTeams'),

    /**
     * array of all teams sorted by name
     */
    sortedAndActiveTeams: Ember.computed('teams', {
      get: function get() {
        var sortedTeams = this.get('teamService').sortTeamsByName(this.get('teams'));
        return this.get('teamService').activeTeams(sortedTeams);
      }
    }),

    /**
     * array of all tasks
     */
    tasks: Ember.computed.reads('application.allTasks'),

    /**
     * array of all invoices
     */
    invoices: Ember.computed.reads('application.allInvoices'),

    /**
     * array of all tasks sorted by label
     */
    sortedTasks: Ember.computed('tasks', {
      get: function get() {
        return this.get('taskService').tasksSortedAndGroupedByInactive(this.get('tasks'));
      }
    }),
    timeEntryReferences: Ember.computed.reads('application.allTimeEntryReferences'),

    /**
     * array of all users sorted by label
     */
    timeEntryReferencesSorted: Ember.computed('timeEntryReferences.[]', {
      get: function get() {
        return this.get('timeEntryReferences').sortBy('shortCode');
      }
    }),
    timeEntryRefencesGrouped: Ember.computed('timeEntryReferences.[]', 'selectedProjects', {
      get: function get() {
        var selectedProjects = this.get('selectedProjects');
        var grouped = Object.values(this.get('timeEntryReferencesSorted').reduce(function (grouped, reference) {
          var project = reference.get('projectReferencePlatform.project');
          var projectId = (project === null || project === void 0 ? void 0 : project.get('id')) || 0;

          if (project && (project.get('isActive') || selectedProjects.any(function (selectedProject) {
            return selectedProject.id == projectId;
          }))) {
            if (!grouped[projectId]) {
              grouped[projectId] = {
                groupName: reference.get('projectReferencePlatform.project.name'),
                options: []
              };
            }

            grouped[projectId].options.push(reference);
          }

          return grouped;
        }, {}));
        return grouped.sortBy('groupName');
      }
    }),
    timeEntryReferenceCount: Ember.computed('timeEntryReferences', function () {
      return this.get('timeEntryReferences').filterBy('projectReferencePlatform.project.isActive').length;
    }),
    tags: Ember.computed.reads('application.allTags'),

    /**
     * array of all users sorted by label
     */
    tagsSorted: Ember.computed('tags.[]', {
      get: function get() {
        return this.get('tags').sortBy('label');
      }
    }),

    /**
     * array of all users
     */
    users: Ember.computed.reads('application.allUsers'),
    usersFiltered: Ember.computed('users', 'currentUser.team.id', function () {
      var users = this.get('users');

      if (this.get('currentUserIsDefaultUser') && this.get('permissions.canViewTeamTimeEntries')) {
        var currentUserTeamId = this.get('currentUser.team.id');
        return users.filter(function (user) {
          return user.team.id === currentUserTeamId;
        });
      }

      return users;
    }),

    /**
     * array of all users sorted by label
     */
    userSortKeys: _environment.default.constants.USER_SORT_KEYS,
    _usersSorted: Ember.computed.sort('usersFiltered', 'userSortKeys'),
    usersSorted: Ember.computed('_usersSorted', {
      get: function get() {
        return this.get('userService').usersGroupedByInactive(this.get('_usersSorted'));
      }
    }),
    isShowNonBillable: Ember.computed.equal('billableType', _environment.default.constants.SHOW_NON_BILLABLE),

    /**
     * {analytics/time-entry}
     */
    analytics: null,

    /**
     * Query used for loading time entry analytics
     */
    analyticsQuery: null,

    /**
     * Query used for loading time entries
     */
    timeEntriesQuery: null,

    /**
     * combined params to use for custom url queries, e.e. export or batchUpdate
     */
    filterParams: Ember.computed('from', 'to', 'client', 'status', 'billableType', 'project', 'task', 'team', 'user', 'tag', 'timeEntryReference', {
      get: function get() {
        var _this = this;

        var query = {
          start_date: moment.utc(this.get('from'), _environment.default.constants.DATE_FORMAT_DAY).toJSON(),
          end_date: moment.utc(this.get('to'), _environment.default.constants.DATE_FORMAT_DAY).endOf('day').toJSON()
        };

        switch (this.billableType) {
          case _environment.default.constants.SHOW_BILLABLE:
            query['billable'] = true;
            break;

          case _environment.default.constants.SHOW_NON_BILLABLE:
            query['billable'] = false;
            break;
        } // add billed and billable


        switch (this.get('status')) {
          case _environment.default.constants.SHOW_BILLED_LOCKED:
            query['billed'] = true;
            break;

          case _environment.default.constants.SHOW_BILLABLE_OPEN:
            query['billed'] = false;
            break;
        } // add projects, users and tasks


        ['project', 'task', 'user', 'team', 'timeEntryReference', 'invoice'].forEach(function (param) {
          if (_this.get(param) && _this.get(param).length > 0) {
            query[param] = _this.get(param).map(function (id) {
              return id.toString();
            });
          }
        }); // add clients

        if (this.get('client') && this.get('client').length > 0) {
          query['project.client'] = this.get('client').map(function (id) {
            return id.toString();
          });
        } // add tags


        if (this.get('tag') && this.get('tag').length > 0) {
          query['tagAssignments.tag'] = this.get('tag').map(function (id) {
            return id.toString();
          });
        }

        return query;
      }
    }),
    status: _environment.default.constants.SHOW_ALL,
    billableType: _environment.default.constants.SHOW_ALL,
    showAll: _environment.default.constants.SHOW_ALL,
    showBillable: _environment.default.constants.SHOW_BILLABLE,
    showNonBillable: _environment.default.constants.SHOW_NON_BILLABLE,
    showBilledLocked: _environment.default.constants.SHOW_BILLED_LOCKED,
    showBillableOpen: _environment.default.constants.SHOW_BILLABLE_OPEN,
    showOnlyBilled: _environment.default.constants.ONLY_OPEN,
    showOnlyBillable: _environment.default.constants.ONLY_LOCKED,
    showOnlyNonBillable: _environment.default.constants.ONLY_NON_BILLABLE,
    isOnlyBillable: Ember.computed.equal('billableType', _environment.default.constants.SHOW_BILLABLE),
    isOnlyNonBillable: Ember.computed.equal('billableType', _environment.default.constants.SHOW_NON_BILLABLE),
    _isOnlyBilledLocked: Ember.computed.equal('status', _environment.default.constants.SHOW_BILLED_LOCKED),
    _isOnlyBillableOpen: Ember.computed.equal('status', _environment.default.constants.SHOW_BILLABLE_OPEN),
    showCombinedFilterName: Ember.computed.not('isOnlyNonBillable'),
    isOnlyBilledLocked: Ember.computed.and('showCombinedFilterName', '_isOnlyBilledLocked'),
    isOnlyBillableOpen: Ember.computed.and('showCombinedFilterName', '_isOnlyBillableOpen'),
    isOnlyOpen: Ember.computed.and('isOnlyNonBillable', '_isOnlyBillableOpen'),
    isOnlyLocked: Ember.computed.and('isOnlyNonBillable', '_isOnlyBilledLocked'),
    commentCompleteLineBreaks: _environment.default.constants.COMMENT_COMPLETE_LINE_BREAKS,
    commentComplete: _environment.default.constants.COMMENT_COMPLETE,
    commentCompact: _environment.default.constants.COMMENT_COMPACT,
    commentIconOnly: _environment.default.constants.COMMENT_ICON_ONLY,
    isTooltipHintsVisible: Ember.computed.reads('currentUserService.currentUser.uiState.isTooltipHintsVisible'),
    hasStatusFilter: Ember.computed('status', 'billableType', function () {
      return this.get('status') !== _environment.default.constants.SHOW_ALL || this.get('billableType') !== _environment.default.constants.SHOW_ALL;
    }),
    hasNoStatusFilter: Ember.computed.not('hasStatusFilter'),
    showFilterPlaceholder: Ember.computed('hasNoStatusFilter', 'selectedClients.[]', 'selectedProjects.[]', 'selectedUsers.[]', 'selectedTasks.[]', 'selectedTimeEntryReferences.[]', 'selectedTags.[]', 'selectedTeams.[]', function () {
      var nothingSelected = this.get('selectedClients.length') === 0 && this.get('selectedProjects.length') === 0 && this.get('selectedUsers.length') === 0 && this.get('selectedTasks.length') === 0 && this.get('selectedTimeEntryReferences.length') === 0 && this.get('selectedTags.length') === 0 && this.get('selectedTeams.length') === 0;
      return this.get('hasNoStatusFilter') && nothingSelected;
    }),
    noTimeEntriesClass: Ember.computed('showFilter', function () {
      var cssClass = 'no-time-entries-body';
      if (this.get('showFilter')) cssClass += ' filter-shown';
      return cssClass;
    }),
    //Modal Classes
    overlayClassNames: ['transparent'],
    extendedContainerClass: 'board-filter-modal-analytics',

    /**
     * ---------- Group(optional)
     * you can provide a function to groupBy
     * @param {Time-Entry}
     * @returns string
     */
    groupFn: Ember.computed('currentGroupByCriterion', function () {
      switch (this.get('currentGroupByCriterion')) {
        case _environment.default.constants.GROUP_BY_DATE:
          return function groupFn(item) {
            return moment(item.get('day')).startOf('day').format();
          };

        case _environment.default.constants.GROUP_BY_CLIENT:
          return function groupFn(item) {
            return item.get('project').get('clientName');
          };

        case _environment.default.constants.GROUP_BY_PROJECT:
          return function groupFn(item) {
            return item.get('project').get('label');
          };

        case _environment.default.constants.GROUP_BY_STAFF:
          return function groupFn(item) {
            return item.get('user').get('label');
          };

        case _environment.default.constants.GROUP_BY_TEAM:
          return function groupFn(item) {
            return item.get('team').get('label');
          };

        case _environment.default.constants.GROUP_BY_TASK:
          return function groupFn(item) {
            return item.get('task').get('label');
          };

        case _environment.default.constants.GROUP_BY_REFERENCE:
          return function groupFn(item) {
            return item.get('timeEntryReference').get('shortCodeAndPageTitle');
          };
      }
    }),
    isGroupedByDate: Ember.computed('currentGroupByCriterion', function () {
      return this.get('currentGroupByCriterion') === _environment.default.constants.GROUP_BY_DATE;
    }),

    /**
     * ---------- Divider(optional)
     * property that divides each list element
     *
     */
    dividerFn: function dividerFn(item) {
      return item.get('comment');
    },
    isDisabledFn: function isDisabledFn(item) {
      return item.get('isEditDisabled');
    },
    refreshAnalytics: function refreshAnalytics() {
      var _this10 = this;

      var timeEntriesQuery = this.get('timeEntriesQuery');

      if (timeEntriesQuery.where) {
        timeEntriesQuery.where = JSON.parse(timeEntriesQuery.where);
      }

      this.store.query('time-entry', timeEntriesQuery).then(function (timeEntries) {
        _this10.set('model', timeEntries);
      });
      var query = this.get('analyticsQuery');

      if (query) {
        this.store.query('analytics/time-entry', query).then(function (analytics) {
          _this10.set('analytics', analytics.get('firstObject'));
        });
      }
    },
    deselectElement: function deselectElement(value, elementName) {
      var selectedElements = this.get(elementName);
      this.set(elementName, selectedElements.filter(function (el) {
        return el !== value.get('idAsInt') && el !== value.get('id');
      }));
    },
    commentFilter: '',
    showedColumns: Ember.computed.reads('currentUserService.currentUser.uiState.showedColumns'),
    defaultColumnOrder: Ember.computed('groupByCriterion', {
      get: function get() {
        return [{
          id: _environment.default.constants.GROUP_BY_DATE,
          label: 'date',
          shown: this.get('currentGroupByCriterion') !== _environment.default.constants.GROUP_BY_DATE
        }, {
          id: _environment.default.constants.GROUP_BY_CLIENT,
          label: 'client',
          shown: this.get('currentGroupByCriterion') !== _environment.default.constants.GROUP_BY_CLIENT
        }, {
          id: _environment.default.constants.GROUP_BY_PROJECT,
          label: 'project',
          shown: this.get('currentGroupByCriterion') !== _environment.default.constants.GROUP_BY_PROJECT
        }, {
          id: _environment.default.constants.GROUP_BY_STAFF,
          label: 'staff',
          shown: this.get('currentGroupByCriterion') !== _environment.default.constants.GROUP_BY_STAFF
        }, {
          id: _environment.default.constants.GROUP_BY_TEAM,
          label: 'team_',
          shown: this.get('currentGroupByCriterion') !== _environment.default.constants.GROUP_BY_TEAM
        }, {
          id: _environment.default.constants.GROUP_BY_TASK,
          label: 'task',
          shown: this.get('currentGroupByCriterion') !== _environment.default.constants.GROUP_BY_TASK
        }, {
          id: _environment.default.constants.GROUP_BY_REFERENCE,
          label: 'reference',
          shown: this.get('currentGroupByCriterion') !== _environment.default.constants.GROUP_BY_REFERENCE
        }, {
          id: _environment.default.constants.SHOW_TRACKED_ANALYTICS,
          label: 'tracked',
          shown: true
        }, {
          id: _environment.default.constants.SHOW_BILLABLE_ANALYTICS,
          label: 'billable',
          shown: true
        }, {
          id: _environment.default.constants.SHOW_AMOUNT_ANALYTICS,
          label: 'amount',
          shown: true
        }];
      }
    }),
    groupByCriterion: Ember.computed.reads('currentUserService.currentUser.uiState.showGroupAnalyticsBy'),
    currentGroupByCriterion: '',
    criteriaLabel: Ember.computed('groupByCriterion', {
      get: function get() {
        var criteria;

        switch (this.get('groupByCriterion')) {
          case _environment.default.constants.GROUP_BY_DATE:
            criteria = 'dateSmall';
            break;

          case _environment.default.constants.GROUP_BY_CLIENT:
            criteria = 'clientSmall';
            break;

          case _environment.default.constants.GROUP_BY_PROJECT:
            criteria = 'projectSmall';
            break;

          case _environment.default.constants.GROUP_BY_STAFF:
            criteria = 'staffSmall';
            break;

          case _environment.default.constants.GROUP_BY_TEAM:
            criteria = 'teamSmall';
            break;

          case _environment.default.constants.GROUP_BY_TASK:
            criteria = 'taskSmall';
            break;

          case _environment.default.constants.GROUP_BY_REFERENCE:
            criteria = 'referenceSmall';
            break;

          default:
            criteria = 'dateSmall';
            break;
        }

        return criteria;
      }
    }),
    overflowCount: 0,
    showCommentType: Ember.computed.reads('currentUserService.currentUser.uiState.showCommentType'),
    checkedArray: [],
    showBulkModal: false,
    showReorderModal: false,
    isDateInTable: Ember.computed('groupByCriterion', 'showedColumns.@each.shown', {
      get: function get() {
        var columns = this.get('showedColumns') || this.get('defaultColumnOrder');
        var searchedColumn = columns.find(function (column) {
          return column.id === _environment.default.constants.GROUP_BY_DATE && column.shown === true;
        });
        return searchedColumn !== undefined && searchedColumn.id === this.get('groupByCriterion') ? this.get('isGroupedValueShowed') : !!searchedColumn;
      }
    }),
    isClientInTable: Ember.computed('groupByCriterion', 'showedColumns.@each.shown', {
      get: function get() {
        var columns = this.get('showedColumns') || this.get('defaultColumnOrder');
        var searchedColumn = columns.find(function (column) {
          return column.id === _environment.default.constants.GROUP_BY_CLIENT && column.shown === true;
        });
        return searchedColumn !== undefined && searchedColumn.id === this.get('groupByCriterion') ? this.get('isGroupedValueShowed') : !!searchedColumn;
      }
    }),
    isProjectInTable: Ember.computed('groupByCriterion', 'showedColumns.@each.shown', {
      get: function get() {
        var columns = this.get('showedColumns') || this.get('defaultColumnOrder');
        var searchedColumn = columns.find(function (column) {
          return column.id === _environment.default.constants.GROUP_BY_PROJECT && column.shown === true;
        });
        return searchedColumn !== undefined && (searchedColumn === null || searchedColumn === void 0 ? void 0 : searchedColumn.id) === this.get('groupByCriterion') ? this.get('isGroupedValueShowed') : !!searchedColumn;
      }
    }),
    isTeamInTable: Ember.computed('groupByCriterion', 'showedColumns.@each.shown', {
      get: function get() {
        var columns = this.get('showedColumns') || this.get('defaultColumnOrder');
        var searchedColumn = columns.find(function (column) {
          return column.id === _environment.default.constants.GROUP_BY_TEAM && column.shown === true;
        });
        return searchedColumn !== undefined && searchedColumn.id === this.get('groupByCriterion') ? this.get('isGroupedValueShowed') : !!searchedColumn;
      }
    }),
    isTaskInTable: Ember.computed('groupByCriterion', 'showedColumns.@each.shown', {
      get: function get() {
        var columns = this.get('showedColumns') || this.get('defaultColumnOrder');
        var searchedColumn = columns.find(function (column) {
          return column.id === _environment.default.constants.GROUP_BY_TASK && column.shown === true;
        });
        return searchedColumn !== undefined && searchedColumn.id === this.get('groupByCriterion') ? this.get('isGroupedValueShowed') : !!searchedColumn;
      }
    }),
    isReferenceInTable: Ember.computed('groupByCriterion', 'showedColumns.@each.shown', {
      get: function get() {
        var columns = this.get('showedColumns') || this.get('defaultColumnOrder');
        var searchedColumn = columns.find(function (column) {
          return column.id === _environment.default.constants.GROUP_BY_REFERENCE && column.shown === true;
        });
        return searchedColumn !== undefined && searchedColumn.id === this.get('groupByCriterion') ? this.get('isGroupedValueShowed') : !!searchedColumn;
      }
    }),
    isStaffInTable: Ember.computed('groupByCriterion', 'showedColumns.@each.shown', {
      get: function get() {
        var columns = this.get('showedColumns') || this.get('defaultColumnOrder');
        var searchedColumn = columns.find(function (column) {
          return column.id === _environment.default.constants.GROUP_BY_STAFF && column.shown === true;
        });
        return searchedColumn !== undefined && searchedColumn.id === this.get('groupByCriterion') ? this.get('isGroupedValueShowed') : !!searchedColumn;
      }
    }),
    isHoursInTable: Ember.computed('showedColumns.@each.shown', {
      get: function get() {
        var columns = this.get('showedColumns') || this.get('defaultColumnOrder');
        var searchedColumn = columns.find(function (column) {
          return column.id === _environment.default.constants.SHOW_BILLABLE_ANALYTICS && column.shown === true;
        });
        return searchedColumn !== undefined && searchedColumn.id === this.get('groupByCriterion') ? this.get('isGroupedValueShowed') : !!searchedColumn;
      }
    }),
    isValueInTable: Ember.computed('showedColumns.@each.shown', {
      get: function get() {
        var columns = this.get('showedColumns') || this.get('defaultColumnOrder');
        var searchedColumn = columns.find(function (column) {
          return column.id === _environment.default.constants.SHOW_AMOUNT_ANALYTICS && column.shown === true;
        });
        return searchedColumn !== undefined && searchedColumn.id === this.get('groupByCriterion') ? this.get('isGroupedValueShowed') : !!searchedColumn;
      }
    }),
    isTrackedInTable: Ember.computed('showedColumns.@each.shown', {
      get: function get() {
        var columns = this.get('showedColumns') || this.get('defaultColumnOrder');
        var searchedColumn = columns.find(function (column) {
          return column.id === _environment.default.constants.SHOW_TRACKED_ANALYTICS && column.shown === true;
        });
        return searchedColumn !== undefined && searchedColumn.id === this.get('groupByCriterion') ? this.get('isGroupedValueShowed') : !!searchedColumn;
      }
    }),
    isGroupedValueShowed: false,

    /**
     * set of actions
     */
    actions: {
      markAllAsBilled: function markAllAsBilled() {
        var route = 'analytics.time-entries.batch-update';
        this.transitionToRoute(route, {
          k: 'billedAt',
          v: this.get('clock.now').format()
        });
      },
      toggleBulkModal: function toggleBulkModal() {
        this.set('showBulkModal', !this.get('showBulkModal'));
      },
      toggleTooltipVisibility: function toggleTooltipVisibility() {
        this.get('currentUserService').saveUISettings({
          isTooltipHintsVisible: !this.get('isTooltipHintsVisible')
        });
      },
      onHide: function onHide() {
        this.set('checkedArray', []);
        this.set('showBulkModal', false);
        this.transitionToRoute('analytics');
      },
      chooseAllTimeEntries: function chooseAllTimeEntries() {
        var tempArray = [];

        if (this.get('checkboxValue') === true) {
          this.get('timeEntries').map(function (entry) {
            tempArray.push(entry.id);
          });
        }

        this.set('checkedArray', tempArray);
      },
      receiveChecked: function receiveChecked(id, isChecked) {
        this.set('checkboxValue', false);
        var entriesCountOnPage = Math.min(this.get('timeEntries').meta.total, this.get('page') * this.get('limit') + this.get('limit'));

        if (isChecked) {
          this.set('checkedArray', [].concat(_toConsumableArray(this.get('checkedArray')), [id]));

          if (this.get('checkedArray').length === entriesCountOnPage) {
            this.set('checkboxValue', true);
          }
        } else {
          this.set('checkedArray', this.get('checkedArray').filter(function (check) {
            return check !== id;
          }));

          if (this.get('checkedArray').length === 0) {
            this.set('checkboxValue', false);
          }
        }
      },
      markAllAsUnBilled: function markAllAsUnBilled() {
        var route = 'analytics.time-entries.batch-update';
        this.transitionToRoute(route, {
          k: 'billedAt',
          v: null
        });
      },
      markAsBilled: function markAsBilled(item) {
        var _this11 = this;

        this.get('timeEntryService').markAsBilled(item).then(function () {
          _this11.get('notifications').success('alert.saved', 'timeEntry');

          Ember.run.later(function () {
            return _this11.refreshAnalytics();
          }, 1000);
        }).catch(function (err) {
          item.rollbackAttributes();

          _this11.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'timeEntry');
        });
      },
      markAsUnBilled: function markAsUnBilled(item) {
        var _this12 = this;

        this.get('timeEntryService').markAsUnBilled(item).then(function () {
          _this12.get('notifications').success('alert.saved', 'timeEntry');

          Ember.run.later(function () {
            return _this12.refreshAnalytics();
          }, 1000);
        }).catch(function (err) {
          item.rollbackAttributes();

          _this12.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'timeEntry');
        });
      },
      billProject: function billProject() {
        this.get('application').send('billProject', this.get('project').get('firstObject'), moment.utc(this.get('from'), _environment.default.constants.DATE_FORMAT_DAY), moment.utc(this.get('to'), _environment.default.constants.DATE_FORMAT_DAY));
      },
      onChangeColumns: function onChangeColumns(columns, isGroupedShowed) {
        this.get('currentUserService').saveUISettings({
          showedColumns: columns
        });
        this.set('isGroupedValueShowed', isGroupedShowed);
      },
      changePage: function changePage(curPage) {
        this.set('page', curPage - 1);
      },
      toggleGroupBy: function toggleGroupBy(newVal) {
        this.get('currentUserService').saveUISettings({
          showGroupAnalyticsBy: newVal
        });
        this.send('refreshModel');
        this.set('isGroupedValueShowed', false);
      },
      commentChange: function commentChange() {
        this.set('comment', this.get('commentFilter'));
      },
      clearCommentFilter: function clearCommentFilter() {
        this.set('comment', '');
        this.set('commentFilter', '');
      },
      //Tags
      hideOverflowingChildren: function hideOverflowingChildren(parent) {
        var parentWidth = window.innerWidth - parent.getBoundingClientRect().left - 200;
        var hiddenCount = 0;
        parent.querySelectorAll('.filter-search-choice:not(.overflow-message)').forEach(function (child) {
          child.classList.remove('hidden');

          if (hiddenCount > 0 || child.offsetWidth + child.offsetLeft > parentWidth) {
            hiddenCount++;
            child.classList.add('hidden');
          }
        });
        this.set('overflowCount', hiddenCount);
      },
      makeRoomForOverflowCount: function makeRoomForOverflowCount(overflow) {
        var hiddenCount = this.overflowCount;
        var parent = overflow.parentElement;
        var parentRight = window.innerWidth - parent.getBoundingClientRect().left - 200;

        while (overflow.offsetLeft + overflow.offsetWidth > parentRight) {
          var unhiddenChildren = parent.querySelectorAll('.filter-search-choice:not(.hidden):not(.overflow-message)');
          unhiddenChildren[unhiddenChildren.length - 1].classList.add('hidden');
          hiddenCount++;
        }

        this.set('overflowCount', hiddenCount);
      },
      toggleShowCommentType: function toggleShowCommentType(newVal) {
        this.get('currentUserService').saveUISettings({
          showCommentType: newVal
        });
      },
      toggleFilter: function toggleFilter() {
        this.toggleProperty('showFilter');
      },
      toggleReorderModal: function toggleReorderModal() {
        this.toggleProperty('showReorderModal');
      },
      resetFilters: function resetFilters() {
        this.set('client', []);
        this.set('project', []);
        this.set('user', []);
        this.set('task', []);
        this.set('invoice', []);
        this.set('team', []);
        this.set('timeEntryReference', []);
        this.set('tag', []);
        this.set('billableType', _environment.default.constants.SHOW_ALL);
        this.set('status', _environment.default.constants.SHOW_ALL);
      },
      deselectClient: function deselectClient(client) {
        this.deselectElement(client, 'client');
      },
      deselectProject: function deselectProject(project) {
        this.deselectElement(project, 'project');
      },
      deselectUser: function deselectUser(user) {
        this.deselectElement(user, 'user');
      },
      deselectTask: function deselectTask(task) {
        this.deselectElement(task, 'task');
      },
      deselectInvoice: function deselectInvoice(invoice) {
        this.deselectElement(invoice, 'invoice');
      },
      deselectTeam: function deselectTeam(team) {
        this.deselectElement(team, 'team');
      },
      deselectTimeEntryReference: function deselectTimeEntryReference(timeEntryReference) {
        this.deselectElement(timeEntryReference, 'timeEntryReference');
      },
      deselectTag: function deselectTag(tag) {
        this.deselectElement(tag, 'tag');
      },
      resetTypeFilterProperty: function resetTypeFilterProperty() {
        this.set('billableType', _environment.default.constants.SHOW_ALL);
      },
      resetStatusFilterProperty: function resetStatusFilterProperty() {
        this.set('status', _environment.default.constants.SHOW_ALL);
      },
      selectClients: function selectClients(client) {
        this.set('client', client.getEach('id'));
      },
      addClient: function addClient(client) {
        if (!this.get('client').includes(client)) this.set('client', [].concat(_toConsumableArray(this.get('client')), [client]));
      },
      selectUsers: function selectUsers(user) {
        this.set('user', user.getEach('id'));
      },
      addUsers: function addUsers(user) {
        if (!this.get('user').includes(user)) this.set('user', [].concat(_toConsumableArray(this.get('user')), [user]));
        this.set('checkedArray', []);
        this.set('checkboxValue', false);
      },
      selectTasks: function selectTasks(task) {
        this.set('task', task.getEach('id'));
      },
      addTasks: function addTasks(task) {
        if (!this.get('task').includes(task)) this.set('task', [].concat(_toConsumableArray(this.get('task')), [task]));
        this.set('checkedArray', []);
        this.set('checkboxValue', false);
      },
      selectTeams: function selectTeams(team) {
        this.set('team', team.getEach('id'));
      },
      addTeams: function addTeams(team) {
        if (!this.get('team').includes(team)) this.set('team', [].concat(_toConsumableArray(this.get('team')), [team]));
        this.set('checkedArray', []);
        this.set('checkboxValue', false);
      },
      selectTimeEntryReferences: function selectTimeEntryReferences(timeEntryReference) {
        this.set('timeEntryReference', timeEntryReference.getEach('id'));
      },
      addTimeEntryReferences: function addTimeEntryReferences(timeEntryReference) {
        if (!this.get('timeEntryReference').includes(timeEntryReference)) this.set('timeEntryReference', [].concat(_toConsumableArray(this.get('timeEntryReference')), [timeEntryReference]));
        this.set('checkedArray', []);
        this.set('checkboxValue', false);
      },
      selectProjects: function selectProjects(project) {
        this.set('project', project.getEach('id'));
      },
      addProjects: function addProjects(project) {
        if (!this.get('project').includes(project)) this.set('project', [].concat(_toConsumableArray(this.get('project')), [project]));
        this.set('checkedArray', []);
        this.set('checkboxValue', false);
      },
      selectTags: function selectTags(tag) {
        this.set('tag', tag.getEach('id'));
      } //markSelectedAsBilled: function(){
      //  confirm('mark selected as billed? (...not implemented yet)');
      //},
      //
      //markSelectedAsUnBilled: function(){
      //  confirm('mark selected as unbilled? (...not implemented yet)');
      //},
      //toggleSelection: function(value) {
      //
      //  let selected = this.get('selectedTimeEntries').contains(value);
      //
      //  if (value && !selected) {
      //    this.get('selectedTimeEntries').addObject(value);
      //  } else if (!value && selected) {
      //    this.get('selectedTimeEntries').removeObject(value);
      //  }
      //
      //},
      //selectTimeEntries: function() {
      // TODO Handle all that exist..
      //var ids = this.get('timeEntries').map(function(timeEntry) {
      //  return timeEntry.get('id');
      //});
      //this.set('selectedTimeEntries', ids);
      //},
      //unselectTimeEntries: function() {
      //  this.set('selectedTimeEntries', Ember.A());
      //},

    }
  });
  var _default = TimeEntriesController;
  _exports.default = _default;
});