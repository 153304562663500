define("coffeecup/controllers/time-entries/verifying", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    permissions: Ember.inject.service(),
    validationService: Ember.inject.service('validation'),
    isChildRoute: Ember.computed.notEmpty('router.currentRoute.child'),
    application: Ember.inject.controller(),
    teamService: Ember.inject.service('team'),
    isWeekView: Ember.computed('router.currentRouteName', function () {
      return this.get('router.currentRouteName').indexOf('verifying.index') !== -1;
    }),
    isMonthView: Ember.computed('router.currentRouteName', function () {
      return this.get('router.currentRouteName').indexOf('verifying.monthly') !== -1;
    }),
    isListView: Ember.computed('router.currentRouteName', function () {
      return this.get('router.currentRouteName').indexOf('verifying.list') !== -1;
    }),
    month: moment().format(_environment.default.constants.DATE_MONTH_PICKER_FORMAT),
    maxMonth: moment().format(_environment.default.constants.DATE_MONTH_PICKER_FORMAT),
    week: moment().startOf('week').format(_environment.default.constants.DATE_FORMAT_DAY),
    adminFilter: 'assignedTimeSheets',
    validationFilter: 'all',
    validationFilterLabel: Ember.computed('validationFilter', function () {
      return this.get('validationFilter') + 'TimeSheets';
    }),
    selectedTeam: Ember.computed('team', {
      get: function get() {
        var _this = this;

        if (this.get('team')) {
          return this.get('teams').find(function (t) {
            return t.get('id') === _this.get('team');
          });
        }
      }
    }),
    teams: Ember.computed.reads('application.allTeams'),
    sortedAndActiveTeams: Ember.computed('teams', function () {
      var sortedTeams = this.get('teamService').sortTeamsByName(this.get('teams').filter(function (team) {
        return team.users.length > 0;
      }));
      return this.get('teamService').activeTeams(sortedTeams);
    }),
    maxWeek: moment().startOf('week').format(_environment.default.constants.DATE_FORMAT_DAY),
    _updateDateProperties: Ember.observer('week', 'month', 'router.currentRouteName', function () {
      if (this.get('router.currentRouteName')) {
        if (this.get('isWeekView')) {
          if (this.get('month') !== moment(this.get('week')).format(_environment.default.constants.DATE_MONTH_PICKER_FORMAT)) {
            this.set('month', moment(this.get('week')).format(_environment.default.constants.DATE_MONTH_PICKER_FORMAT));
          }
        } else if (this.get('isMonthView')) {
          if (this.get('week') !== moment(this.get('month')).format(_environment.default.constants.DATE_FORMAT_DAY)) {
            this.set('week', moment(this.get('month')).startOf('isoWeek').format(_environment.default.constants.DATE_FORMAT_DAY));
          }
        }
      }
    }),
    actions: {
      showToday: function showToday() {
        this.set('month', moment().format(_environment.default.constants.DATE_MONTH_PICKER_FORMAT));
      },
      selectTeam: function selectTeam(team) {
        this.set('team', team ? team.get('id') : team);
      }
    }
  });

  _exports.default = _default;
});