define("coffeecup/controllers/analytics/users", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /*
    INJECTIONS
     */
    application: Ember.inject.controller(),
    detailsController: Ember.inject.controller('analytics.users.details'),
    router: Ember.inject.service(),
    permissions: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    userService: Ember.inject.service('user'),

    /*
    PROPERTIES
     */
    openModal: false,

    /*
    COMPUTED PROPERTIES
     */
    currentUser: Ember.computed.alias('application.currentUser'),
    selectedUser: Ember.computed.alias('detailsController.selectedUser'),
    accountSettings: Ember.computed.alias('accountService.accountSettings'),
    isSameUser: Ember.computed('selectedUser.id', 'currentUser.id', function () {
      return this.get('selectedUser.id') === this.get('currentUser.id');
    }),
    canManageAllUsers: Ember.computed.or('permissions.canManageUsers', 'permissions.isRootAdmin'),
    canAccessPlanner: Ember.computed('selectedUser.showInPlanner', 'permissions.plannerActive', 'currentUser.isAdminOrProjectManager', 'permissions.canAccessPlanner', 'permissions.canManagePlanner', function () {
      var showInPlanner = this.get('selectedUser.showInPlanner');
      var plannerActive = this.get('permissions.plannerActive');
      var userHasAccessToPlanner = this.get('currentUser.isAdminOrProjectManager') || this.get('permissions.canAccessPlanner') || this.get('permissions.canManagePlanner');
      return showInPlanner && plannerActive && userHasAccessToPlanner;
    }),

    /**
     * @return array of active projects sorted by label
     */
    userSortKeys: _environment.default.constants.USER_SORT_KEYS,
    _userSorted: Ember.computed.sort('users', 'userSortKeys'),
    usersSorted: Ember.computed('_userSorted', function () {
      return this.get('userService').usersGroupedByInactive(this.get('_userSorted'));
    }),

    /*
    OBSERVERS
     */
    changeToUser: Ember.observer('selectedUser.id', function () {
      var selectedUser = this.get('selectedUser.id') || this.get('currentUser.id');

      if (selectedUser) {
        this.get('router').transitionTo('analytics.users.details', selectedUser);
      }
    }),

    /*
    ACTIONS
     */
    actions: {
      openExportModule: function openExportModule() {
        this.set('openModal', true);
      }
    }
  });

  _exports.default = _default;
});