define("coffeecup/demo-data/dashboard/project-billing-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.projectBillingData = projectBillingData;
  _exports.totalBillableAmount = void 0;

  function projectBillingData(locale) {
    var amountSubLabel = locale === 'de' ? 'Nach Aufwand' : 'By Effort';
    return [{
      project: {
        label: 'Sales Companion App',
        clientName: 'Deutsche Telekom'
      },
      amount: '€ 90',
      amountSubLabel: amountSubLabel,
      overBudget: false
    }, {
      project: {
        label: 'Service Companion App',
        clientName: 'Deutsche Telekom'
      },
      amount: '€ 47.898',
      amountSubLabel: amountSubLabel,
      overBudget: false
    }, {
      project: {
        label: 'Shopping App Module',
        clientName: 'EBM Design'
      },
      amount: '€ 27.393',
      amountSubLabel: amountSubLabel,
      overBudget: false
    }, {
      project: {
        label: 'Dicke Beute AG - NaA',
        clientName: 'Dicke Beute AG'
      },
      amount: '€ 318',
      amountSubLabel: amountSubLabel,
      overBudget: false
    }, {
      project: {
        label: 'Wartung 2021',
        clientName: 'Deutsche Telekom'
      },
      amount: '€ 1.323',
      amountSubLabel: amountSubLabel,
      overBudget: false
    }, {
      project: {
        label: 'Mobile App Extension',
        clientName: 'EBM Design'
      },
      amount: '€ 2.139',
      amountSubLabel: amountSubLabel,
      overBudget: false
    }, {
      project: {
        label: 'NiAb',
        clientName: 'Caritas'
      },
      amount: '€ 24',
      amountSubLabel: amountSubLabel,
      overBudget: false
    }, {
      project: {
        label: 'Social Media Campaign',
        clientName: 'BSH GmbH'
      },
      amount: '€ 73',
      amountSubLabel: amountSubLabel,
      overBudget: false
    }];
  }

  var totalBillableAmount = '€ 79.258';
  _exports.totalBillableAmount = totalBillableAmount;
});