define("coffeecup/components/x-pane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['tab-pane'],
    classNameBindings: ['hidden:hidden'],

    /**
     * the title of the tab
     */
    title: null,
    didInsertElement: function didInsertElement() {
      this.get('parentView').addPane(this);
    },
    selected: Ember.computed('parentView.selectedPane', {
      get: function get() {
        return this.get('parentView.selectedPane') === this;
      }
    }),
    hidden: Ember.computed.not('selected')
  });

  _exports.default = _default;
});