define("coffeecup/components/planner/project-span", ["exports", "coffeecup/components/planner/time-frame"], function (_exports, _timeFrame) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _timeFrame.default.extend({
    classNames: ['project-span', 'project-active'],
    project: null,
    showWeekends: Ember.computed.alias('viewport.showWeekends'),
    startDate: Ember.computed('project.startDate', 'viewport.showWeekends', {
      get: function get() {
        return this.get('showWeekends') ? this.get('project.startDate') : this.get('project.startDateBusinessDay');
      }
    }),
    endDate: Ember.computed('project.endDate', 'viewport.showWeekends', {
      get: function get() {
        return this.get('showWeekends') ? this.get('project.endDate') : this.get('project.endDateBusinessDay');
      }
    }),
    backgroundColor: Ember.computed.reads('project.color'),
    style: Ember.computed('width', 'left', 'backgroundColor', function () {
      var backgroundColor = this.get('backgroundColor') ? "background-color: ".concat(this.get('backgroundColor.hexString'), ";") : '';
      return Ember.String.htmlSafe('width:' + this.get('width') + 'px;left:' + this.get('left') + 'px;' + backgroundColor);
    })
  });

  _exports.default = _default;
});