define("coffeecup/components/cc-colorpicker", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Ember wrapper for Bootstrap Color Picker.
   *
   * @author Alexander Stonehouse
   */
  var _default = Ember.Component.extend({
    attributeBindings: ['title', 'type'],
    required: false,
    format: 'hex',
    defaultColor: _environment.default.constants.COLOR_SCHEME_DEFAULT,
    didInsertElement: function didInsertElement() {
      var settings = this.getProperties('color', 'format', 'align');
      var defaultColor = this.get('defaultColor');
      var usingDefault = false;

      if (!settings.color) {
        usingDefault = true;
        settings.color = defaultColor;
      }

      settings.sliders = {
        saturation: {
          maxLeft: 200,
          maxTop: 200
        },
        hue: {
          maxTop: 200
        },
        alpha: {
          maxTop: 200
        }
      };
      this.$().colorpicker(settings);

      if (usingDefault) {
        this.$('input').val('');
      }

      this.$().on('changeColor', Ember.$.proxy(this.didChangeColorFromComponent, this));
      this.$().on('showPicker', Ember.$.proxy(this.didChangeColorFromComponent, this));
      this.$().on('hidePicker', Ember.$.proxy(this.emitInputChange, this));

      if (!this.get('required')) {
        this.$('input').on('change', Ember.$.proxy(this.didChangeInputValue, this));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.$().off('changeColor');
      this.$().off('showPicker');
      this.$().colorpicker('destroy');

      if (!this.get('required')) {
        this.$('input').off('change');
      }
    },
    emitInputChange: function emitInputChange(event) {
      this.sendAction('action');
    },
    didChangeColorFromComponent: function didChangeColorFromComponent(event) {
      var componentColor = event.color.toString(this.get('format'));

      if (componentColor !== this.get('color')) {
        this.set('color', componentColor);
      }
    },

    /**
     * Color picker ignores changes to empty values, so we observe the input property to allow the user to
     * enter an empty value.
     */
    didChangeInputValue: function didChangeInputValue() {
      var newVal = this.$('input').val();

      if (newVal === '') {
        this.set('color', null);
      }
    }
  });

  _exports.default = _default;
});