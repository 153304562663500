define("coffeecup/demo-data/dashboard/project-invoices-module", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.openInvoicesAmount = _exports.projectInvoicesData = void 0;
  var projectInvoicesData = [{
    number: '123456',
    dueDate: (0, _moment.default)().subtract(1, 'month'),
    overdue: true,
    draft: false,
    clientName: 'Deutsche Telekom',
    amount: 595,
    paid: false
  }, {
    number: '234567',
    dueDate: (0, _moment.default)().subtract(2, 'week'),
    overdue: true,
    draft: false,
    clientName: 'EBM Design',
    amount: 59500,
    paid: false
  }, {
    number: '345678',
    dueDate: (0, _moment.default)().subtract(1, 'week'),
    overdue: true,
    draft: false,
    clientName: 'BSH GmbH',
    amount: 1309,
    paid: false
  }, {
    number: '456789',
    dueDate: (0, _moment.default)().add(1, 'day'),
    overdue: false,
    draft: false,
    clientName: 'Caritas',
    amount: 1309,
    paid: false
  }, {
    number: '654321',
    dueDate: (0, _moment.default)().add(4, 'day'),
    overdue: false,
    draft: false,
    clientName: 'Dicke Beute AG',
    amount: 9520,
    paid: false
  }, {
    number: '765432',
    dueDate: (0, _moment.default)().add(1, 'week'),
    draft: false,
    clientName: 'ZDF',
    amount: 1785,
    paid: false
  }, {
    number: '876543',
    dueDate: (0, _moment.default)().add(2, 'week'),
    draft: false,
    clientName: 'AppLetics GmbH',
    amount: 6545,
    paid: false
  }, {
    number: '987654',
    dueDate: (0, _moment.default)().add(1, 'month'),
    draft: false,
    clientName: 'The Fab AG',
    amount: 2528.75,
    paid: false
  }]; // Easybill/Invoices Model

  _exports.projectInvoicesData = projectInvoicesData;
  var openInvoicesAmount = '€ 81.783';
  _exports.openInvoicesAmount = openInvoicesAmount;
});