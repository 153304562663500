define("coffeecup/controllers/welcome", ["exports", "coffeecup/config/environment", "coffeecup/controllers/login", "coffeecup/utils/build-url"], function (_exports, _environment, _login, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var trimPassword = function trimPassword() {
    var password = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

    if (password && typeof password.toString === 'function') {
      password = password.toString();
    }

    if (password && typeof password.trim === 'function') {
      password = password.trim();
    }

    return password;
  };

  var _default = _login.default.extend({
    authenticator: 'oauth-custom-authenticator:oauth2-password-grant',
    status: 0,
    //
    identification: Ember.computed.alias('user.email'),
    password: null,
    passwordTrimmed: Ember.computed('password', {
      get: function get() {
        return trimPassword(this.get('password'));
      }
    }),
    passwordRepeat: null,
    passwordRepeatTrimmed: Ember.computed('passwordRepeat', {
      get: function get() {
        return trimPassword(this.get('passwordRepeat'));
      }
    }),
    terms: null,
    token: null,
    user: null,
    email: null,
    page: 0,
    submitting: null,
    submitIsDisabled: Ember.computed('submitting', 'formIsValid', {
      get: function get() {
        var submitting = this.get('submitting');
        var isValid = this.get('formIsValid');
        return submitting || !isValid;
      }
    }),
    formIsValid: Ember.computed.and('passwordTrimmed', 'passwordRepeatTrimmed'),
    statusDefault: Ember.computed('status', {
      get: function get() {
        return this.status === _environment.default.constants.RESET_PWD_STATUS_DEFAULT;
      }
    }),
    statusError: Ember.computed.or('statusPasswordMismatch', 'statusPasswordTooShort', 'statusGeneralError', 'statusTermsError'),
    statusPasswordError: Ember.computed.or('statusPasswordMismatch', 'statusPasswordTooShort'),
    statusPasswordMismatch: Ember.computed('status', {
      get: function get() {
        return this.status === _environment.default.constants.RESET_PWD_STATUS_PASSWORD_MISMATCH;
      }
    }),
    statusPasswordTooShort: Ember.computed('status', {
      get: function get() {
        return this.status === _environment.default.constants.RESET_PWD_STATUS_TOO_SHORT;
      }
    }),
    statusTermsError: Ember.computed('status', {
      get: function get() {
        return this.status === _environment.default.constants.RESET_PWD_TERMS_NOT_ACCEPTED;
      }
    }),
    statusGeneralError: Ember.computed('status', {
      get: function get() {
        return this.status === _environment.default.constants.RESET_PWD_STATUS_ERROR;
      }
    }),
    resetError: Ember.observer('password', 'passwordRepeat', 'terms', function () {
      this.set('status', _environment.default.constants.RESET_PWD_STATUS_DEFAULT);
    }),
    secondPage: Ember.computed('page', {
      get: function get() {
        return this.page === 1;
      }
    }),
    _setPasswordAndLogin: function _setPasswordAndLogin() {
      var _this = this;

      this.set('submitting', true);
      var password = this.get('passwordTrimmed');
      var url = (0, _buildUrl.default)(this.store, 'users/resetpassword');
      var request = {
        url: url,
        type: 'POST',
        data: {
          passwordtokenrequest: {
            token: this.token,
            password: password
          }
        }
      };
      Ember.$.ajax(request).then(function () {
        _this.set('password', password);

        _this.set('status', _environment.default.constants.RESET_PWD_STATUS_SUCCESS);

        _this.set('page', 1 - _this.get('page'));

        setTimeout(function () {
          _this.send('authenticate');
        }, 1000);
      }).fail(function (err) {
        _this.set('status', _environment.default.constants.RESET_PWD_STATUS_ERROR); // TODO implement this error

      }).always(function () {
        _this.set('submitting', false);
      });
    },
    _checkPasswords: function _checkPasswords() {
      var password = this.get('passwordTrimmed');
      var passwordRepeat = this.get('passwordRepeatTrimmed');

      if (password && passwordRepeat) {
        if (password !== passwordRepeat) {
          this.set('status', _environment.default.constants.RESET_PWD_STATUS_PASSWORD_MISMATCH);
          return false;
        } else if (password.length < _environment.default.constants.PASSWORD_LENGTH) {
          this.set('status', _environment.default.constants.RESET_PWD_STATUS_TOO_SHORT);
          return false;
        } else {
          return true;
        }
      }

      return false;
    },
    _checkTerms: function _checkTerms() {
      var terms = this.get('terms');

      if (!terms) {
        this.set('status', _environment.default.constants.RESET_PWD_TERMS_NOT_ACCEPTED);
        return false;
      }

      return true;
    },
    actions: {
      authenticate: function authenticate() {
        this._super();
      },
      submit: function submit() {
        this._checkTerms() && this._checkPasswords() && this._setPasswordAndLogin();
      },
      togglePage: function togglePage() {
        this.resetError();
        this.set('page', 1 - this.get('page'));
      }
    }
  });

  _exports.default = _default;
});