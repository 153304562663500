define("coffeecup/controllers/login", ["exports", "coffeecup/config/environment", "coffeecup/utils/build-url"], function (_exports, _environment, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var trimInput = function trimInput() {
    var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

    if (str && typeof str.toString === 'function') {
      str = str.toString();
    }

    if (str && typeof str.trim === 'function') {
      str = str.trim();
    }

    return str;
  };

  var LoginController = Ember.Controller.extend({
    application: Ember.inject.controller(),
    appVersion: Ember.computed.alias('application.appVersion'),
    status: 0,
    session: Ember.inject.service(),
    cookies: Ember.inject.service(),
    router: Ember.inject.service(),
    notifications: Ember.inject.service(),
    identification: Ember.computed(function () {
      var cookies = this.get('cookies');
      var reLoginUsername = cookies.read('reLoginUsername');
      return reLoginUsername || '';
    }),
    password: null,
    email: Ember.computed.alias('identification'),
    page: 0,
    reloginEnabled: Ember.computed(function () {
      var cookies = this.get('cookies');
      var reLoginForTransition = cookies.read('reLoginForTransition');
      return !!reLoginForTransition;
    }),
    statusDefault: Ember.computed('status', {
      get: function get() {
        return this.status === _environment.default.constants.LOGIN_STATUS_DEFAULT;
      }
    }),
    statusAuthError: Ember.computed('status', {
      get: function get() {
        return this.status === _environment.default.constants.LOGIN_STATUS_WRONG_USERNAME_PASSWORD;
      }
    }),
    statusEmailError: Ember.computed('status', {
      get: function get() {
        return this.status === _environment.default.constants.LOGIN_STATUS_RESETLINK_ERROR;
      }
    }),
    statusEmailSuccess: Ember.computed('status', {
      get: function get() {
        return this.status === _environment.default.constants.LOGIN_STATUS_RESETLINK_SUCCESS;
      }
    }),
    resetError: Ember.observer('identification', 'password', 'email', function () {
      this.set('status', _environment.default.constants.LOGIN_STATUS_DEFAULT);
    }),
    secondPage: Ember.computed.equal('page', 1),
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        this.set('submitting', true);

        var _this$getProperties = this.getProperties('identification', 'password'),
            identification = _this$getProperties.identification,
            password = _this$getProperties.password;

        this.get('session').authenticate('authenticator:oauth2', trimInput(identification), trimInput(password)).catch(function (reason) {
          _this.set('submitting', false);

          if (!reason || typeof reason.error === 'undefined') {
            // if there is no reason the server is most likely currently not available
            _this.get('notifications').error('503');
          }

          _this.set('status', _environment.default.constants.LOGIN_STATUS_WRONG_USERNAME_PASSWORD);
        });
      },
      sendResetLink: function sendResetLink(email) {
        var _this2 = this;

        var url = (0, _buildUrl.default)(this.store, 'users/forgotPassword');
        email = trimInput(email);
        var request = {
          url: url,
          type: 'PUT',
          data: {
            passwordforgotrequest: {
              email: email
            }
          }
        };

        if (email !== '') {
          //console.log(request);
          Ember.$.ajax(request).then(function (response) {
            _this2.set('status', _environment.default.constants.LOGIN_STATUS_RESETLINK_SUCCESS);

            _this2.set('page', 1 - _this2.get('page'));
          }, function (error) {
            var reason = error && error.responseJSON ? error.responseJSON : false;

            if (!reason || typeof reason.error === 'undefined') {
              // if there is no reason the server is most likely currently not available
              _this2.get('notifications').error('503');
            }

            _this2.set('status', _environment.default.constants.LOGIN_STATUS_RESETLINK_ERROR);
          });
        } else {
          this.set('status', _environment.default.constants.LOGIN_STATUS_RESETLINK_ERROR);
        }
      },
      togglePage: function togglePage() {
        this.resetError();
        this.set('page', 1 - this.get('page'));
      },
      cancelRelogin: function cancelRelogin() {
        if (!this.get('reloginEnabled')) {
          return;
        }

        var cookies = this.get('cookies');
        cookies.clear('reLoginForTransition');
        cookies.clear('reLoginForUser');
        cookies.clear('reLoginUsername');
        window.location.reload();
      }
    }
  });
  var _default = LoginController;
  _exports.default = _default;
});