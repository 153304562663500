define("coffeecup/components/planner/user-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'row-info',
    isCollapsed: null,
    click: function click() {
      this.sendAction('onExpand');
    },
    userTag: Ember.computed('user', function () {
      var i18n = this.get('i18n');
      var user = this.get('user');

      if (user.get('isAdmin')) {
        return i18n.t('admin');
      } else if (user.get('isProjectManager')) {
        return 'PM';
      } else if (user.get('isFreelancer')) {
        return i18n.t('freelancerShort');
      }

      return null;
    }),
    labelClasses: Ember.computed('userTag', function () {
      if (this.get('user.isAdmin')) return 'mini primary-background-color';
      if (this.get('user.isProjectManager')) return 'mini bg-gray';
      return 'mini bg-light-gray';
    }),
    actions: {
      showTimeEntries: function showTimeEntries() {
        this.sendAction.apply(this, ['showTimeEntries'].concat(Array.prototype.slice.call(arguments)));
      },
      removeUserFromPlanner: function removeUserFromPlanner() {
        var _this = this;

        var user = this.get('user');

        if (user) {
          user.set('showInPlanner', false);
          user.save().then(function () {
            _this.get('notifications').success('alert.saved', 'user');
          }).catch(function (err) {
            user.rollbackAttributes();

            _this.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'user');
          });
        }
      }
    }
  });

  _exports.default = _default;
});