define("coffeecup/components/analytics-module/user/documents", ["exports", "coffeecup/mixins/download-attachment"], function (_exports, _downloadAttachment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_downloadAttachment.default, {
    store: Ember.inject.service(),
    permissions: Ember.inject.service(),
    documentService: Ember.inject.service('document'),
    user: null,
    documents: null,
    documentsGrouped: Ember.computed('documents', function () {
      if (!this.get('documents')) return null;
      return this.get('documentService').sortAndGroupedByCategory(this.get('documents'));
    }),
    documentUploadActive: Ember.computed.reads('permissions.documentUploadActive'),
    didInsertElement: function didInsertElement() {
      this._loadDocuments();
    },
    _loadDocumentsObserver: Ember.observer('user', function () {
      Ember.run.once(this, '_loadDocuments');
    }),
    _loadDocuments: function _loadDocuments() {
      var _this = this;

      this.get('store').query('document', {
        where: {
          record: this.get('user.id'),
          model: 'user'
        },
        sort: 'date desc'
      }).then(function (documents) {
        _this.set('documents', documents);
      });
    }
  });

  _exports.default = _default;
});