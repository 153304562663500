define("coffeecup/components/radial-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RadialProgressComponent = Ember.Component.extend({
    progress: 0,
    verb: '',
    hidePercentSign: false,
    fulfilledClass: 'fulfilled',
    isFulfilled: Ember.computed('progress', {
      get: function get() {
        var progress = this.get('progress');
        return progress > 100 || progress < 0;
      }
    }),
    progressText: Ember.computed('progress', {
      get: function get() {
        var per = this.get('progress');
        return Math.round(per);
      }
    }),
    presentedProgress: Ember.computed('progress', {
      get: function get() {
        var per = this.get('progress');

        if (per < 0) {
          per = 0;
        } else if (per > 200) {
          per = 100;
        } else if (per > 100) {
          per = per % 100;
        }

        return Math.round(per);
      }
    })
  });
  var _default = RadialProgressComponent;
  _exports.default = _default;
});