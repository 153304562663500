define("coffeecup/components/planner/milestone-count", ["exports", "coffeecup/components/planner/time-frame"], function (_exports, _timeFrame) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _timeFrame.default.extend({
    formatter: Ember.inject.service(),
    viewport: Ember.inject.service('planner/viewport'),
    classNames: ['milestone', 'milestone-count'],
    classNameBindings: ['isInverted:inverted-colors'],
    milestone: null,
    isInverted: false,
    inlineUse: false,
    style: Ember.computed('left', 'inlineUse', 'viewport.subNavWidth', function () {
      if (!this.inlineUse) {
        return Ember.String.htmlSafe("left: ".concat(this.get('left') + this.get('viewport.subNavWidth'), "px;"));
      }
    }),
    day: Ember.computed.alias('milestone.day'),
    startDate: Ember.computed.alias('milestone.day'),
    endDate: Ember.computed.alias('milestone.day')
  });

  _exports.default = _default;
});