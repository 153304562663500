define("coffeecup/components/clickable-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNameBindings: ['iconClassName'],
    iconClassName: Ember.computed('icon', function () {
      return "icomoon-".concat(this.get('icon'));
    }),
    propagateClicks: true,
    click: function click(event) {
      var propagateClicks = this.get('propagateClicks');

      if (propagateClicks === false || propagateClicks === 'false') {
        event.stopPropagation();
      }

      this.sendAction('onClick');
    }
  });

  _exports.default = _default;
});