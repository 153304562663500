define("coffeecup/components/deferred-row", ["exports", "coffeecup/utils/dom", "coffeecup/mixins/deferred-setup"], function (_exports, _dom, _deferredSetup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_deferredSetup.default, {
    isSetup: null,
    registerSetup: Ember.on('didInsertElement', function () {
      this._super.apply(this, arguments);

      this.$scrollable = this.$().parents('.scrollable');
      this.resolveSetupIfOnScreen();
      this.scroll = 'scroll.' + Ember.guidFor(this);
      this.refresh = 'refresh.' + Ember.guidFor(this);
      this.$scrollable.on(this.scroll, function () {
        this.debounce = Ember.run.debounce(this, 'resolveSetupIfOnScreen', 50);
      }.bind(this));
      this.$scrollable.on(this.refresh, function () {
        this.debounce = Ember.run.debounce(this, 'resolveSetupIfOnScreen', 1);
      }.bind(this));
    }),
    unSetupIfNoLongerOnScreen: Ember.on('didUpdateAttrs', function () {
      if (!this.isOnScreen()) {
        this.set('isSetup', false);
        this.unbind();
        this.registerSetup();
      }
    }),
    isOnScreen: function isOnScreen() {
      return this.element && (0, _dom.verticallyOnScreen)(this.$()[0]);
    },
    resolveSetupIfOnScreen: function resolveSetupIfOnScreen() {
      if (this.isOnScreen()) {
        this.unbind();
        this.resolveSetup();
        return true;
      }

      return false;
    },
    unbind: Ember.on('willDestroyElement', function () {
      if (this.debounce) {
        Ember.run.cancel(this.debounce);
        this.debounce = null;
      }

      if (this.scroll) {
        this.$scrollable.off(this.scroll);
        this.scroll = null;
      }

      if (this.refresh) {
        this.$scrollable.off(this.refresh);
        this.refresh = null;
      }
    })
  });

  _exports.default = _default;
});