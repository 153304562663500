define("coffeecup/components/dashboard-module/project-revenue", ["exports", "coffeecup/utils/color-hex2rgba", "coffeecup/config/environment"], function (_exports, _colorHex2rgba, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    /**
     * INJECTIONS
     */
    formatter: Ember.inject.service(),
    permissions: Ember.inject.service(),
    currentUserService: Ember.inject.service('currentUser'),
    currentUser: Ember.computed.oneWay('currentUserService.currentUser'),

    /**
     * PROPERTIES
     */
    histogram: [],
    graphData: [],
    modes: ['showPeriodic', 'showAccumulated'],
    amountSpentTotal: 0,
    alertColor: _environment.default.constants.ALERT_COLOR,
    hasHourBudget: false,
    selectedProject: null,
    budget: 0,
    startDate: null,
    endDate: null,
    draggable: true,
    currentModeOverride: null,

    /**
     * COMPUTED PROPERTIES
     */
    isModePeriodic: Ember.computed.equal('currentMode', 'showPeriodic'),
    isModeAccumulated: Ember.computed.equal('currentMode', 'showAccumulated'),
    canAccessProjectCosts: Ember.computed.or('currentUser.isAdmin', 'permissions.canAccessProjectCosts'),
    yearPickerCustomElement: Ember.computed('module', function () {
      var i18n = this.get('i18n');

      if (this.get('module.isTypeClientReport')) {
        return i18n.t('sinceBegin');
      } else {
        return i18n.t('sinceProjectBegin');
      }
    }),
    showAllData: Ember.computed('module', 'selectedProject', {
      get: function get() {
        var showAllData = true;
        var settings = this.get('module.settingsObj');
        var projectId = this.get('selectedProject.id');

        if (settings && projectId && settings[projectId] && settings[projectId].showAllData !== undefined) {
          showAllData = settings[projectId].showAllData;
        }

        return showAllData;
      },
      set: function set(key, newValue) {
        var module = this.get('module');
        var settings = module.get('settingsObj');
        var projectId = this.get('selectedProject.id');

        if (newValue !== undefined && projectId) {
          if (!settings[projectId]) settings[projectId] = {};
          settings[projectId].showAllData = newValue;
          module.set('settingsObj', settings);
          module.save();
        }

        return newValue;
      }
    }),
    currentMode: Ember.computed('module', 'currentModeOverride', 'selectedProject', {
      get: function get() {
        if (this.get('currentModeOverride')) return this.get('currentModeOverride');
        var currentMode = 'showPeriodic';
        var settings = this.get('module.settingsObj');
        var projectId = this.get('selectedProject.id');

        if (settings && projectId && settings[projectId] && settings[projectId].currentMode) {
          currentMode = settings[projectId].currentMode;
        }

        return currentMode;
      },
      set: function set(key, newValue) {
        var module = this.get('module');
        var settings = module.get('settingsObj');
        var projectId = this.get('selectedProject.id');

        if (newValue !== '' && projectId) {
          if (!settings[projectId]) settings[projectId] = {};
          settings[projectId].currentMode = newValue;
          module.set('settingsObj', settings);
          module.save();
        }

        return newValue;
      }
    }),
    year: Ember.computed('module', 'selectedProject', {
      get: function get() {
        var year = moment().format('YYYY');
        var projectId = this.get('selectedProject.id');
        var settings = this.get('module.settingsObj');

        if (settings && projectId && settings[projectId] && settings[projectId].year) {
          year = settings[projectId].year;
        }

        return year;
      },
      set: function set(key, newValue) {
        var module = this.get('module');
        var projectId = this.get('selectedProject.id');
        var settings = module.get('settingsObj');

        if (newValue !== '' && projectId) {
          if (!settings[projectId]) settings[projectId] = {};
          settings[projectId].year = newValue;
          module.set('settingsObj', settings);
          module.save();
        }

        return newValue;
      }
    }),
    hasDataForPeriod: Ember.computed('monthlyRevenue.length', function () {
      return !!this.get('monthlyRevenue.length');
    }),
    monthlyRevenue: Ember.computed('histogram', 'year', 'showAllData', function () {
      var _this = this;

      var histogram = this.get('histogram').sortBy('date');

      if (!this.get('showAllData')) {
        histogram = this.get('histogram').filter(function (el) {
          return moment(el.get('date')).isSame(moment().year(_this.get('year')), 'year');
        });
      }

      Ember.run.once(function () {
        if (histogram.length < 1 && !_this.get('showAllData')) {
          _this.set('startDate', moment().year(_this.get('year')).startOf('year').format(_environment.default.constants.DATE_FORMAT_DAY));

          _this.set('endDate', moment().year(_this.get('year')).endOf('year').format(_environment.default.constants.DATE_FORMAT_DAY));
        } else {
          _this.set('startDate', moment(histogram.get('firstObject.date')).startOf('month').format(_environment.default.constants.DATE_FORMAT_DAY));

          _this.set('endDate', moment(histogram.get('lastObject.date')).endOf('month').format(_environment.default.constants.DATE_FORMAT_DAY));
        }
      });
      return histogram;
    }),
    revenueYearlyAccumulators: Ember.computed('histogram', function () {
      var _this2 = this;

      var histogram = this.get('histogram').sortBy('date');
      var year = moment(histogram.get('firstObject.date')).format('YYYY');
      var accumulators = {};
      histogram.forEach(function (el) {
        var value = _this2.get('selectedProject.isModeNotBillable') ? 'hoursTotal' : 'amountSpentTotal';

        if (moment(el.get('date')).isSame(year, 'year')) {
          if (!accumulators[year]) {
            accumulators[year] = accumulators[year - 1] ? accumulators[year - 1] : 0;
          }

          accumulators[year] += el.get(value);
        } else {
          year = moment(el.get('date')).format('YYYY');

          if (!accumulators[year]) {
            accumulators[year] = accumulators[year - 1] ? accumulators[year - 1] : 0;
          }

          accumulators[year] += el.get(value);
        }
      });
      return accumulators;
    }),
    costsYearlyAccumulators: Ember.computed('histogram', function () {
      var histogram = this.get('histogram').sortBy('date');
      var year = moment(histogram.get('firstObject.date')).format('YYYY');
      var accumulators = {};
      histogram.forEach(function (el) {
        if (moment(el.get('date')).isSame(year, 'year')) {
          if (!accumulators[year]) {
            accumulators[year] = accumulators[year - 1] ? accumulators[year - 1] : 0;
          }

          accumulators[year] += el.get('totalCosts');
        } else {
          year = moment(el.get('date')).format('YYYY');

          if (!accumulators[year]) {
            accumulators[year] = accumulators[year - 1] ? accumulators[year - 1] : 0;
          }

          accumulators[year] += el.get('totalCosts');
        }
      });
      return accumulators;
    }),
    profitability: Ember.computed('amountSpentTotal', 'totalCosts', function () {
      return (this.get('amountProjectTotal') - this.get('totalCosts')) / this.get('amountProjectTotal');
    }),
    amountProjectTotal: Ember.computed('amountSpentTotal', 'selectedProject', function () {
      return this.get('selectedProject.isCompletedFixedBudgetProject') ? this.get('selectedProject.analytics.invoicesTotal') : this.get('amountSpentTotal');
    }),
    legendItems: Ember.computed('amountSpentTotal', 'totalCosts', 'year', 'canAccessProjectCosts', function () {
      var formatter = this.get('formatter');
      var totalAmount = this.get('amountSpentTotal');
      var totalCosts = this.get('totalCosts');

      var labelFunc = function labelFunc(title, titleValue, color, alpha) {
        return {
          title: title,
          titleValue: titleValue,
          color: _colorHex2rgba.RGBA.convertFromHex(color, alpha).toCSS()
        };
      };

      if (this.get('canAccessProjectCosts')) {
        if (!this.get('selectedProject.isModeNotBillable')) {
          var isDeficit = totalAmount - totalCosts < 0;
          return [labelFunc('effort', "".concat(formatter.formatCurrency(totalAmount)), _environment.default.constants.REVENUE_COLOR_LIGHT, 1), labelFunc('costs', "".concat(formatter.formatCurrency(totalCosts)), _environment.default.constants.ALERT_COLOR_LIGHT, 1), labelFunc(isDeficit ? 'loss' : 'surplus', "".concat(formatter.formatCurrency(totalAmount - totalCosts)), isDeficit ? _environment.default.constants.ALERT_COLOR_LIGHT : _environment.default.constants.REVENUE_COLOR_LIGHT, 1), labelFunc('profitability', formatter.formatPercentage(this.get('profitability')), _environment.default.constants.PROFIT_COLOR, 1)];
        } else {
          return [labelFunc('costs', "".concat(formatter.formatCurrency(totalCosts)), _environment.default.constants.ALERT_COLOR_LIGHT, 1), labelFunc('totalHours', "".concat(formatter.formatDuration(totalAmount)), _environment.default.constants.REVENUE_COLOR_LIGHT, 1)];
        }
      } else {
        if (!this.get('selectedProject.isModeNotBillable')) {
          return [labelFunc('effort', "".concat(formatter.formatCurrency(totalAmount)), _environment.default.constants.REVENUE_COLOR_LIGHT, 1)];
        } else {
          return [labelFunc('totalHours', "".concat(formatter.formatDuration(totalAmount)), _environment.default.constants.REVENUE_COLOR_LIGHT, 1)];
        }
      }
    }),

    /**
     * OBSERVERS
     */
    _prepareGraphData: Ember.observer('monthlyRevenue.length', 'currentMode', 'canAccessProjectCosts', function () {
      var _this3 = this;

      var monthlyRevenue = this.get('monthlyRevenue').map(function (el) {
        return el;
      });

      if (monthlyRevenue.length > 0) {
        var formatter = this.get('formatter');
        var yearlyAccumulators = this.get('revenueYearlyAccumulators');
        var costsYearlyAccumulators = this.get('costsYearlyAccumulators');
        var graphData = [];
        var showAllData = this.get('showAllData');
        var previousYear = parseInt(this.get('year')) - 1;
        var hasRevenueAccumulator = yearlyAccumulators[previousYear] && !showAllData && !this.get('isModePeriodic');
        var hasCostsAccumulator = costsYearlyAccumulators[previousYear] && !showAllData && !this.get('isModePeriodic');
        var revenueAccumulator = hasRevenueAccumulator ? yearlyAccumulators[previousYear] : 0;
        var costsAccumulator = hasCostsAccumulator ? costsYearlyAccumulators[previousYear] : 0;

        if (!showAllData || monthlyRevenue.length < 3) {
          var _loop = function _loop(index) {
            var revenueElement = monthlyRevenue.find(function (el) {
              return moment(el.get('date')).month() === index;
            });
            var isFuture = moment().year(_this3.get('year')).month(index).isAfter(_this3.get('clock.now'), 'month');
            var graphElement = {
              month: moment.monthsShort(index)
            };

            if (!isFuture) {
              var amountSpentTotal = 0;

              if (revenueElement) {
                var _this3$_calculateCost = _this3._calculateCostsAndRevenueOfElement(revenueElement),
                    _this3$_calculateCost2 = _slicedToArray(_this3$_calculateCost, 4),
                    revenue = _this3$_calculateCost2[0],
                    revenueSurplus = _this3$_calculateCost2[1],
                    costs = _this3$_calculateCost2[2],
                    costsSurplus = _this3$_calculateCost2[3];

                amountSpentTotal = _this3.get('selectedProject.isModeNotBillable') ? revenueElement.get('hoursTotal') || 0 : revenueElement.get('amountSpentTotal');
                revenueAccumulator += amountSpentTotal;
                costsAccumulator += revenueElement.get('totalCosts');
                graphElement.revenue = _this3.get('canAccessProjectCosts') ? revenue : 0;
                graphElement.revenueSurplus = _this3.get('canAccessProjectCosts') ? revenueSurplus : amountSpentTotal;
                graphElement.costs = _this3.get('canAccessProjectCosts') ? costs : 0;
                graphElement.costsSurplus = _this3.get('canAccessProjectCosts') ? costsSurplus : 0;
                graphElement.balloonText = _this3._buildPeriodicChartBalloon(revenueElement);
                graphElement.hoursBalloonText = _this3._buildPeriodicHoursChartBalloon(revenueElement);
              } else {}

              graphElement.revenueAccumulator = revenueAccumulator;

              if (_this3.get('canAccessProjectCosts')) {
                graphElement.costsAccumulator = costsAccumulator;
              }

              graphElement.accumulatedBalloonText = _this3._buildAccumulatedChartBalloon(revenueAccumulator, costsAccumulator);
              graphElement.accumulatedHoursBalloonText = _this3._buildAccumulatedHoursChartBalloon(revenueAccumulator);
            }

            graphData.push(graphElement);
          };

          // showing a whole year if one is selected or not enough data is available
          for (var index = 0; index < 12; index++) {
            _loop(index);
          }
        } else {
          var currentMonth = moment(monthlyRevenue.get('firstObject.date'));
          var revenueElement = monthlyRevenue[0];

          while (monthlyRevenue.length > 0) {
            var revenueDate = moment(monthlyRevenue[0].get('date'));
            var balloonText = formatter.formatCurrency(0);
            var hoursBalloonText = formatter.formatDuration(0);
            var revenue = 0;
            var revenueSurplus = 0;
            var costs = 0;
            var costsSurplus = 0;
            var amountSpentTotal = 0;

            if (currentMonth.isSame(revenueDate, 'month')) {
              revenueElement = monthlyRevenue.shift();
              amountSpentTotal = this.get('selectedProject.isModeNotBillable') ? revenueElement.get('hoursTotal') || 0 : revenueElement.get('amountSpentTotal') || 0;
              revenueAccumulator += amountSpentTotal;
              costsAccumulator += revenueElement.get('totalCosts');

              var _this$_calculateCosts = this._calculateCostsAndRevenueOfElement(revenueElement);

              var _this$_calculateCosts2 = _slicedToArray(_this$_calculateCosts, 4);

              revenue = _this$_calculateCosts2[0];
              revenueSurplus = _this$_calculateCosts2[1];
              costs = _this$_calculateCosts2[2];
              costsSurplus = _this$_calculateCosts2[3];
              balloonText = this._buildPeriodicChartBalloon(revenueElement);
              hoursBalloonText = this._buildPeriodicHoursChartBalloon(revenueElement);
            }

            var graphElement = {
              month: currentMonth.format('MMM YY'),
              revenue: this.get('canAccessProjectCosts') ? revenue : 0,
              revenueSurplus: this.get('canAccessProjectCosts') ? revenueSurplus : amountSpentTotal,
              costs: this.get('canAccessProjectCosts') ? costs : 0,
              costsSurplus: this.get('canAccessProjectCosts') ? costsSurplus : 0,
              revenueAccumulator: revenueAccumulator,
              costsAccumulator: this.get('canAccessProjectCosts') ? costsAccumulator : null,
              balloonText: balloonText,
              hoursBalloonText: hoursBalloonText,
              accumulatedBalloonText: this._buildAccumulatedChartBalloon(revenueAccumulator, costsAccumulator),
              accumulatedHoursBalloonText: this._buildAccumulatedHoursChartBalloon(revenueAccumulator)
            };
            graphData.push(graphElement);
            currentMonth.add(1, 'month');
          }
        }

        this.set('amountSpentTotal', revenueAccumulator);
        this.set('totalCosts', costsAccumulator);
        this.set('graphData', graphData);
      }
    }),
    _calculateCostsAndRevenueOfElement: function _calculateCostsAndRevenueOfElement(revenueElement) {
      var revenue = 0;
      var revenueSurplus = 0;
      var costs = 0;
      var costsSurplus = 0;
      var amountSpentTotal = revenueElement.get('amountSpentTotal');

      if (amountSpentTotal - revenueElement.get('totalCosts') > 0) {
        // revenue is equal to costs because the mainbar in the chart should be the same height than the costs
        revenue = revenueElement.get('totalCosts');
        revenueSurplus = amountSpentTotal - revenueElement.get('totalCosts');
        costs = this.get('selectedProject.isModeNotBillable') ? revenueElement.get('totalCosts') // if the project is not billable, the costs should start at the bottom
        : -revenueElement.get('totalCosts');
        costsSurplus = 0;
      } else if (amountSpentTotal - revenueElement.get('totalCosts') < 0) {
        revenue = this.get('selectedProject.isModeNotBillable') ? revenueElement.get('hoursTotal') || 0 : amountSpentTotal;
        revenueSurplus = 0;
        costs = this.get('selectedProject.isModeNotBillable') ? amountSpentTotal : -amountSpentTotal;
        costsSurplus = this.get('selectedProject.isModeNotBillable') ? Math.abs(amountSpentTotal - revenueElement.get('totalCosts')) // if the project is not billable, the costs should start at the bottom
        : amountSpentTotal - revenueElement.get('totalCosts');
      }

      return [revenue, revenueSurplus, costs, costsSurplus];
    },
    _buildAccumulatedHoursChartBalloon: function _buildAccumulatedHoursChartBalloon(revenue) {
      var formatter = this.get('formatter');
      var i18n = this.get('i18n');
      return "<div class=\"am-flex-container\"><span>".concat(i18n.t('totalHours'), ":</span> <span>").concat(formatter.formatDuration(revenue), "</span></div>");
    },
    _buildPeriodicHoursChartBalloon: function _buildPeriodicHoursChartBalloon(revenueElement) {
      var formatter = this.get('formatter');
      var i18n = this.get('i18n');
      return "<div class=\"am-flex-container am-text-bold\"><span>".concat(i18n.t('hours'), ":</span> <span>").concat(formatter.formatDuration(revenueElement.get('hoursTotal')), "</span></div>");
    },
    _buildPeriodicChartBalloon: function _buildPeriodicChartBalloon(revenueElement) {
      var formatter = this.get('formatter');
      var i18n = this.get('i18n');

      var boldLine = function boldLine(title, value) {
        var raw = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        return line(title, value, 'am-text-bold', raw);
      };

      var line = function line(title, value, extraClass, raw) {
        return "<div class=\"am-flex-container ".concat(extraClass, "\"><span>").concat(i18n.t(title), ":</span> <span>").concat(raw ? value : formatter.formatCurrency(value), "</span></div>");
      };

      if (this.get('canAccessProjectCosts')) {
        if (!this.get('selectedProject.isModeNotBillable')) {
          return boldLine('effort', revenueElement.get('amountSpentTotal')) + line('time-entries', revenueElement.get('amountSpent')) + line('expensesLabel', revenueElement.get('expensesTotal')) + '<br>' + boldLine('aggregatedCosts', revenueElement.get('totalCosts')) + line('employeesCosts', revenueElement.get('userCosts')) + line('specialExpenses', revenueElement.get('expensesCosts')) + '<br>' + boldLine('deltaLabel', revenueElement.get('amountSpentTotal') - revenueElement.get('totalCosts')) + '<br>' + boldLine('profit', formatter.formatPercentage((revenueElement.get('amountSpentTotal') - revenueElement.get('totalCosts')) / revenueElement.get('amountSpentTotal')), true);
        } else {
          return boldLine('aggregatedCosts', revenueElement.get('totalCosts')) + line('employeesCosts', revenueElement.get('userCosts')) + line('specialExpenses', revenueElement.get('expensesCosts'));
        }
      } else {
        return boldLine('effort', revenueElement.get('amountSpentTotal')) + line('time-entries', revenueElement.get('amountSpent')) + line('expensesLabel', revenueElement.get('expensesTotal'));
      }
    },
    _buildAccumulatedChartBalloon: function _buildAccumulatedChartBalloon(revenue, costs) {
      var formatter = this.get('formatter');
      var i18n = this.get('i18n');

      if (this.get('canAccessProjectCosts')) {
        if (!this.get('selectedProject.isModeNotBillable')) {
          return "<div class=\"am-flex-container\"><span>".concat(i18n.t('effort'), ":</span> <span>").concat(formatter.formatCurrency(revenue), "</span></div>") + "<div class=\"am-flex-container\"><span>".concat(i18n.t('costs'), ":</span> <span>").concat(formatter.formatCurrency(costs), "</span></div>") + "<div class=\"am-flex-container\"><span>".concat(i18n.t(revenue > costs ? 'surplus' : 'loss'), ":</span> <span>").concat(formatter.formatCurrency(revenue - costs), "</span></div>") + "<div class=\"am-flex-container\"><span>".concat(i18n.t('profit'), ":</span> <span>").concat(formatter.formatPercentage((revenue - costs) / revenue), "</span></div>");
        } else {
          return "<div class=\"am-flex-container\"><span>".concat(i18n.t('costs'), ":</span> <span>").concat(formatter.formatCurrency(costs), "</span></div>");
        }
      } else {
        return "<div class=\"am-flex-container\"><span>".concat(i18n.t('effort'), ":</span> <span>").concat(formatter.formatCurrency(revenue), "</span></div>");
      }
    },
    _insertGraph: Ember.observer('graphData', 'currentMode', 'canAccessProjectCosts', function () {
      var _this4 = this;

      var data = this.get('graphData');
      var i18n = this.get('i18n');
      var userValueAxis = [];

      if (this.get('selectedProject.isModeNotBillable')) {
        if (this.get('canAccessProjectCosts')) {
          userValueAxis = [{
            id: 'amountAxis',
            stackType: this.get('isModePeriodic') ? 'regular' : 'none',
            gridAlpha: 0.07,
            position: 'left',
            unit: '€',
            autoGridCount: false,
            axisAlpha: 0,
            gridCount: 2
          }, {
            id: 'hourAxis',
            stackType: this.get('isModePeriodic') ? 'regular' : 'none',
            gridAlpha: 0.07,
            position: 'right',
            unit: 'h',
            autoGridCount: false,
            axisAlpha: 0,
            gridCount: 2
          }];
        } else {
          userValueAxis = [{
            id: 'hourAxis',
            stackType: this.get('isModePeriodic') ? 'regular' : 'none',
            gridAlpha: 0.07,
            position: 'left',
            unit: 'h',
            autoGridCount: false,
            axisAlpha: 0,
            gridCount: 2
          }];
        }
      } else {
        userValueAxis = [{
          id: 'amountAxis',
          stackType: this.get('isModePeriodic') ? 'regular' : 'none',
          gridAlpha: 0.07,
          position: 'left',
          unit: '€',
          autoGridCount: false,
          axisAlpha: 0,
          gridCount: 2
        }];
      }

      var graphs = [];

      if (this.get('isModePeriodic')) {
        if (this.get('canAccessProjectCosts')) {
          graphs = [{
            fillAlphas: 0.4,
            lineThickness: 0,
            valueField: 'costs',
            type: 'column',
            balloonText: '',
            valueAxis: 'amountAxis'
          }, {
            fillAlphas: 1,
            lineThickness: 0,
            valueField: 'costsSurplus',
            type: 'column',
            balloonText: this.get('selectedProject.isModeNotBillable') ? '[[balloonText]]' : '',
            valueAxis: 'amountAxis'
          }];
        }

        graphs.push({
          fillAlphas: 0.4,
          lineThickness: 0,
          valueField: 'revenue',
          type: 'column',
          balloonText: '',
          valueAxis: this.get('selectedProject.isModeNotBillable') ? 'hourAxis' : 'amountAxis'
        }, {
          fillAlphas: 1,
          lineThickness: 0,
          valueField: 'revenueSurplus',
          type: 'column',
          balloonText: this.get('selectedProject.isModeNotBillable') ? '[[hoursBalloonText]]' : '[[balloonText]]',
          valueAxis: this.get('selectedProject.isModeNotBillable') ? 'hourAxis' : 'amountAxis'
        });
      } else {
        graphs = [{
          bullet: 'circle',
          bulletBorderAlpha: 1,
          bulletBorderColor: '#FFFFFF',
          bulletColor: _environment.default.constants.ALERT_COLOR_LIGHT,
          bulletSize: 10,
          hideBulletsCount: 50,
          lineThickness: 2,
          title: 'red line',
          valueField: 'costsAccumulator',
          balloonText: '[[accumulatedBalloonText]]',
          valueAxis: 'amountAxis'
        }, {
          bullet: 'circle',
          bulletBorderAlpha: 1,
          bulletBorderColor: '#FFFFFF',
          bulletColor: _environment.default.constants.REVENUE_COLOR_LIGHT,
          bulletSize: 10,
          hideBulletsCount: 50,
          lineThickness: 2,
          title: 'red line',
          valueField: 'revenueAccumulator',
          balloonText: this.get('selectedProject.isModeNotBillable') ? '[[accumulatedHoursBalloonText]]' : '[[accumulatedBalloonText]]',
          valueAxis: this.get('selectedProject.isModeNotBillable') ? 'hourAxis' : 'amountAxis'
        }];
      }

      var guides = [];

      if (this.get('isModeAccumulated') && this.get('budget') > 0) {
        guides.push({
          balloonText: i18n.t('budget'),
          id: 'Guide-1',
          fillColor: this.get('alertColor'),
          lineColor: this.get('alertColor'),
          lineAlpha: 1,
          inside: true,
          dashLength: 2,
          value: this.get('budget'),
          valueAxis: this.get('selectedProject.isModeNotBillable') ? 'hourAxis' : 'amountAxis'
        });
      }

      var chartOptions = {
        type: 'serial',
        theme: 'light',
        fontFamily: 'robotolight',
        color: _environment.default.constants.GRAY_DARKER,
        fontSize: 11,
        marginRight: 30,
        dataProvider: data,
        valueAxes: userValueAxis,
        colors: this.get('isModePeriodic') ? this.get('canAccessProjectCosts') ? [_environment.default.constants.ALERT_COLOR_LIGHT, _environment.default.constants.ALERT_COLOR_LIGHT, _environment.default.constants.REVENUE_COLOR_LIGHT, _environment.default.constants.REVENUE_COLOR_LIGHT] : [_environment.default.constants.REVENUE_COLOR_LIGHT, _environment.default.constants.REVENUE_COLOR_LIGHT] : [_environment.default.constants.ALERT_COLOR_LIGHT, _environment.default.constants.REVENUE_COLOR_LIGHT],
        graphs: graphs,
        chartCursor: {
          enabled: true,
          cursorColor: _environment.default.constants.ALERT_COLOR_LIGHT,
          bulletsEnabled: true,
          zoomable: false
        },
        balloon: {
          textAlign: 'left',
          maxWidth: 500,
          fillAlpha: 1
        },
        guides: guides,
        plotAreaBorderAlpha: 0,
        marginTop: 10,
        marginLeft: 0,
        marginBottom: 0,
        categoryField: 'month',
        categoryAxis: {
          axisColor: _environment.default.constants.BASE_CHART_AXIS_COLOR,
          gridAlpha: 0.07,
          gridThickness: 0
        }
      };
      Ember.run.later(function () {
        AmCharts.makeChart('chartdiv-' + _this4.get('elementId'), chartOptions);
      });
    }),

    /**
     * FUNCTIONS
     */
    didInsertElement: function didInsertElement() {
      this._prepareGraphData();
    },

    /**
     * ACTIONS
     */
    actions: {
      yearPickerCustomElementAction: function yearPickerCustomElementAction(value) {
        this.set('showAllData', value);
      }
    }
  });

  _exports.default = _default;
});