define("coffeecup/controllers/account/password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _this = void 0;

  // TODO! Add Validation again
  var PasswordController = Ember.Controller.extend({
    needs: ['application'],
    application: Ember.inject.controller(),
    // TODO rename / refactor to currentUser for naming consistency
    user: Ember.computed.alias('application.currentUser'),
    password: null,
    passwordConfirmation: null,
    // TODO : regEx: ENV.constants.PASSWORD_REGEX,
    validations: {
      password: {
        confirmation: {
          message: function message() {
            return _this.get('i18n').t('passwordRepeatConfirm');
          }
        },
        presence: true,
        format: {
          with: /^([a-zA-Z0-9@*#]{8,15})$/,
          allowBlank: false,
          message: function message() {
            return _this.get('i18n').t('passwordFormatMessage');
          }
        }
      },
      passwordConfirmation: {
        presence: true
      },
      currentPassword: {
        presence: true
      }
    }
  });
  var _default = PasswordController;
  _exports.default = _default;
});