define("coffeecup/components/detailpane-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'header',

    /**
     * the title of the header
     */
    title: null
  });

  _exports.default = _default;
});