define("coffeecup/components/planner/project-user-timeline", ["exports", "coffeecup/components/planner/user-project-timeline"], function (_exports, _userProjectTimeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userProjectTimeline.default.extend({
    classNames: ['project-user-timeline'],
    filteredAssignments: Ember.computed.alias('user.plannerAssignments')
  });

  _exports.default = _default;
});