define("coffeecup/components/custom-icon-configurator/color-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['color-wrapper'],
    classNameBindings: ['isColorSelected:active'],
    color: null,
    colorSafe: Ember.computed('color', function () {
      var _this$get;

      return Ember.String.htmlSafe((_this$get = this.get('color.hexString')) !== null && _this$get !== void 0 ? _this$get : this.get('color'));
    }),
    index: null,
    selectedColorIndex: null,
    isColorSelected: Ember.computed('selectedColorIndex', 'index', function () {
      return this.get('index') === this.get('selectedColorIndex') - 1;
    }),
    click: function click() {
      this.sendAction('selectColor');
    }
  });

  _exports.default = _default;
});