define("coffeecup/components/modals/edit-break", ["exports", "coffeecup/components/modals/edit-time-entry"], function (_exports, _editTimeEntry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _editTimeEntry.default.extend({
    isFormDisabled: Ember.computed('form.isEditDisabled', 'allowBreaks', 'lockedByValidation', 'currentUserIsOwnerOfTimeEntry', function () {
      return !this.get('currentUserIsOwnerOfTimeEntry') || this.get('lockedByValidation') || this.get('form.isEditDisabled') || this.get('form.isBreak') && !this.get('allowBreaks');
    })
  });

  _exports.default = _default;
});