define("coffeecup/components/select-items/dashboard-milestones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember._templateOnlyComponent();

  _exports.default = _default;
});