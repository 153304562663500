define("coffeecup/components/analytics/project-budget-remaining-hours", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'span',
    classNameBindings: ['valueIsNegative:negative-value'],
    project: null,
    subtrahend: 0,
    freezeValue: false,
    hoursObserver: Ember.observer('project.id', 'project.analytics.hoursRemaining', 'subtrahend', 'freezeValue', function () {
      var hoursRemaining = this.get('project.analytics.hoursRemaining');
      var subtrahend = this.get('subtrahend');

      if (!this.get('freezeValue')) {
        this.set('shownHours', hoursRemaining - subtrahend);
      }
    }).on('init'),
    shownHours: null,
    valueIsNegative: Ember.computed.lt('shownHours', 0),
    _loadAnalyicsData: Ember.observer('project.id', function () {
      var _this = this;

      var projectId = this.get('project.id');
      var hoursRemaining = this.get('project.analytics.hoursRemaining');

      if (projectId && typeof hoursRemaining === 'undefined') {
        this.get('store').query('analytics/project', {
          project: projectId
        }).then(function (analytics) {
          return _this.set('project.analytics', analytics.objectAt(0));
        });
      }
    }).on('init')
  });

  _exports.default = _default;
});