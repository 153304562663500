define("coffeecup/components/planner/milestones-row", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['milestones-row'],
    viewport: Ember.inject.service('planner.viewport'),
    milestones: null,
    groupedMilestones: Ember.computed('milestones.@each.day', 'milestones.length', function () {
      var milestones = this.get('milestones');
      var result = [];
      var found = false;
      var dayFmt;
      var viewPortStartDate = this.viewport.startDate;
      milestones.forEach(function (milestone) {
        var day = milestone.get('day');

        if (day.isBefore(viewPortStartDate, day)) {
          return;
        }

        dayFmt = day.format(_environment.default.constants.DATE_FORMAT_DAY);
        found = result.findBy('dayFmt', dayFmt);

        if (!found) {
          result.pushObject(Ember.Object.create({
            day: day,
            dayFmt: dayFmt,
            count: 1,
            milestones: [milestone]
          }));
        } else {
          found.count++;
          found.milestones.push(milestone);
        }
      });
      return result;
    })
  });

  _exports.default = _default;
});