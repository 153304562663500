define("coffeecup/components/resizable-item/handle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui-resizable-handle', 'ui-resizable-s'],
    classNameBindings: ['type'],
    type: null
  });

  _exports.default = _default;
});