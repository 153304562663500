define("coffeecup/components/pie-chart", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    classNames: ['pie-chart-container'],
    data: [],
    small: false,
    indicatorColor: _environment.default.constants.REVENUE_COLOR_LIGHT,
    alertColor: _environment.default.constants.ALERT_COLOR_LIGHT,
    additionalColor: null,
    showEmptyChart: false,
    appendAlertColor: false,
    useAlertColor: false,
    didInsertElement: function didInsertElement() {
      this.updateChart();
    },

    /**
     * OBSERVER
     */
    updateChart: Ember.observer('data.[]', 'indicatorColor', 'useAlertColor', 'appendAlertColor', function () {
      var chart = this.get('currentChart');

      if (chart) {
        try {
          chart.destroy();
        } catch (error) {
          console.warn(error);
        }
      }

      var colorCodes = this.get('useAlertColor') ? [this.get('alertColor'), '#DDDDDD'] : ['#DDDDDD', this.get('indicatorColor')];
      colorCodes = this.get('appendAlertColor') ? [].concat(_toConsumableArray(colorCodes), [this.get('alertColor')]) : colorCodes;
      var data = this.get('showEmptyChart') ? [{
        value: 1
      }] : this.get('data');
      chart = AmCharts.makeChart('project-chart-div-' + this.get('elementId'), {
        type: 'pie',
        startDuration: 0,
        pullOutRadius: 0,
        pullOutDuration: 0,
        pullOutOnlyOne: true,
        hoverAlpha: 1,
        addClassNames: true,
        radius: this.get('small') ? 70 : 105,
        colors: colorCodes,
        balloon: {
          enabled: false
        },
        labelsEnabled: false,
        innerRadius: '85%',
        titleField: 'name',
        valueField: 'value',
        allLabels: [],
        legend: {
          enabled: false
        },
        titles: [],
        dataProvider: data
      });
      this.set('currentChart', chart);
    })
  });

  _exports.default = _default;
});