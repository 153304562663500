define("coffeecup/controllers/planner/team/edit", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    activeUsers: Ember.computed.reads('application.activeUsers'),
    sortKeys: _environment.default.constants.USER_SORT_KEYS,
    sortedUsers: Ember.computed.sort('activeUsers', 'sortKeys'),
    isInitialEdit: false
  });

  _exports.default = _default;
});