define("coffeecup/components/draggable-object", ["exports", "ember-drag-drop/components/draggable-object"], function (_exports, _draggableObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //components/sortable-objects.js
  var _default = _draggableObject.default.extend({
    dragOver: function dragOver(event) {
      if (this.get('isSortable')) {
        this.get('dragCoordinator').draggingOver(event, this);
      }

      return false;
    }
  });

  _exports.default = _default;
});