define("coffeecup/controllers/planner/absences/scheduler", ["exports", "coffeecup/controllers/planner/absences/calendar", "coffeecup/mixins/planner/filter-terms", "coffeecup/config/environment", "coffeecup/utils/string"], function (_exports, _calendar, _filterTerms, _environment, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _calendar.default.extend(_filterTerms.default, {
    queryParams: ['year'],
    currentRoute: 'planner.absences.scheduler',
    teamService: Ember.inject.service('team'),
    application: Ember.inject.controller(),
    absenceController: Ember.inject.controller('planner/absences'),
    permissions: Ember.inject.service(),
    existingTags: [],
    tagAssignments: [],
    filterKeys: _environment.default.constants.USER_FILTER_KEYS,
    year: null,
    isDropdownOpen: false,
    teams: Ember.computed.alias('application.allTeams'),
    teamId: Ember.computed.alias('absenceController.teamId'),
    selectedTeam: Ember.computed('teamId', function () {
      var _this = this;

      return this.get('teams').find(function (team) {
        return team.get('id') === _this.get('teamId');
      }) || null;
    }),
    sortedAndActiveTeams: Ember.computed('teams', function () {
      var sortedTeams = this.get('teamService').sortTeamsByName(this.get('teams'));
      return this.get('teamService').activeTeams(sortedTeams);
    }),
    usersFiltered: Ember.computed.filter('usersSorted', function (user) {
      var _this2 = this;

      if (!user.get('showInAbsencePlanner') && !this.get('showHiddenUsersInAbsences')) return false;
      var tagAssignments = this.get('filteredTagAssignments');
      var hasUserTags = tagAssignments.any(function (tagAssignment) {
        return tagAssignment.get('model') === 'user';
      });
      var userInAssignments = hasUserTags && tagAssignments.any(function (tagAssignment) {
        return _this2.tagAssignmentFits(tagAssignment, user, 'user');
      });
      var searchParams = this.get('filterKeys').map(function (param) {
        return user.get(param);
      });
      var searchString = (0, _string.purify)(searchParams.join(''));
      var filterTerms = this.get('filterTerms');
      var filterTermIsCorrect = filterTerms.any(function (filterTerm) {
        return searchString.indexOf((0, _string.purify)(filterTerm.get('label'))) > -1;
      });
      var hasTeamSelected = !!this.get('teamId');
      var userInTeam = user.get('teamAssignment.team.id') === this.get('teamId');
      if (hasTeamSelected && !filterTerms.get('length') === 0 && !tagAssignments.get('length') === 0) return false;
      if (hasTeamSelected && hasUserTags && !userInTeam && !userInAssignments) return false;
      if (hasTeamSelected && !userInTeam) return false;
      if (hasUserTags && !userInAssignments) return false;
      if (filterTerms.get('length') > 0 && !filterTermIsCorrect) return false;
      return true;
    }).property('filterTerms.[]', 'filteredTagAssignments.[]', 'sortedUsers.@each.showInAbsencePlanner', 'showHiddenUsersInAbsences', 'teamId'),
    handleRequestClick: Ember.observer('clickedRequest', function () {
      if (this.get('clickedRequest')) {
        this.set('year', this.get('clickedRequest.startDate').get('year'));
      }
    }),
    showExportModal: false,
    actions: {
      handleTeamChange: function handleTeamChange(newTeamSelection) {
        this.absenceController.set('teamId', newTeamSelection ? newTeamSelection.get('id') : null);
      },
      toggleExportModal: function toggleExportModal() {
        this.set('showExportModal', !this.get('showExportModal'));
      }
    }
  });

  _exports.default = _default;
});