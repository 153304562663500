define("coffeecup/controllers/time-entries/tracking", ["exports", "coffeecup/config/environment", "coffeecup/controllers/time-entries/tracking/day", "coffeecup/utils/format-duration", "coffeecup/utils/build-url", "coffeecup/utils/date"], function (_exports, _environment, _day2, _formatDuration, _buildUrl, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var TimeEntriesController = Ember.Controller.extend({
    needs: 'application',
    application: Ember.inject.controller(),
    screen: Ember.inject.service(),
    formatter: Ember.inject.service(),
    session: Ember.inject.service(),
    permissions: Ember.inject.service(),
    projectService: Ember.inject.service('project'),
    timeEntryService: Ember.inject.service('time-entry'),
    workingDaysService: Ember.inject.service('working-days'),
    currentUserService: Ember.inject.service('currentUser'),
    currentUser: Ember.computed.alias('currentUserService.currentUser'),
    accountService: Ember.inject.service('account'),
    accountSettings: Ember.computed.alias('accountService.accountSettings'),
    queryParams: ['week', 'user'],

    /**
     * query param
     */
    _week: null,
    activeWeek: null,
    week: Ember.computed('currentUser.beginningOfWeek', 'user', {
      get: function get() {
        var currentWeek = this.get('_week');

        if (!currentWeek) {
          currentWeek = this.get('clock.week').format(_environment.default.constants.DATE_FORMAT_DAY);
          this.set('_week', currentWeek);
        } else if (!moment(currentWeek).isSame(moment(currentWeek).startOf('week'))) {
          // add 1 day is necessary to ensure that the week does not change
          // when you switch from sunday to monday as your start of the week
          currentWeek = moment(currentWeek).add(1, 'd').startOf('week').format(_environment.default.constants.DATE_FORMAT_DAY);
          this.set('_week', currentWeek);
        }

        return currentWeek;
      },
      set: function set(key, newVal) {
        this.set('_week', newVal);
        return newVal;
      }
    }),

    /**
     * query param
     */
    user: null,

    /**
     * {User} the user Object of the currently selected user (by query param)
     */
    userObj: Ember.computed('user', 'users.length', {
      get: function get() {
        if (!this.get('users')) {
          return this.get('currentUser');
        }

        var user = this.get('users').findBy('id', this.get('user'));
        return user || this.get('currentUser');
      }
    }),

    /**
     * @return {boolean} true if user must NOT fulfill a certain amount of daily hours
     */
    userHasFlexibleHours: Ember.computed.readOnly('userObj.hasFlexibleHours'),

    /**
     * @return {boolean} true if user has to fulfill a certain amount of daily hours
     */
    userHasStrictWorkType: Ember.computed.readOnly('userObj.hasStrictWorkType'),
    validation: null,
    validationLogs: [],
    milestones: [],

    /**
     * the style of the time-entries
     * @return String 'clean' or 'color'
     */
    timeEntryColorTypeClass: Ember.computed('timeEntryBackgroundColor', {
      get: function get() {
        var bgColor = this.get('timeEntryBackgroundColor');

        switch (bgColor) {
          case _environment.default.constants.TIME_ENTRY_COLOR_CLEAN:
            return 'clean';

          case _environment.default.constants.TIME_ENTRY_COLOR_DARK:
            return 'dark';

          default:
            return 'color';
        }
      }
    }),
    timeEntryBackgroundColor: Ember.computed('currentUser.uiState.timeEntryBackgroundColor', {
      get: function get() {
        return this.get('currentUser.uiState.timeEntryBackgroundColor');
      }
    }),
    placeholderClass: Ember.computed('timerModeIsDuration', {
      get: function get() {
        if (this.get('timerModeIsDuration')) {
          return 'placeholder-container';
        } else {
          return 'hidden';
        }
      }
    }),
    canSeeUserDropdown: Ember.computed('usersSorted', function () {
      return this.get('usersSorted.length') > 1;
    }),

    /**
     * array of users
     */
    users: null,

    /**
     * array of timeEntries
     */
    timeEntries: null,

    /**
     * array of plannerAssignments
     */
    plannerAssignments: null,

    /**
     * array of absences
     */
    absences: null,

    /**
     * array of userAssignments (only needed for (staff) pm's)
     */
    userAssignments: [],

    /**
     * array of timeEntryBackgroundColorOptions
     */
    timeEntryBackgroundColorOptions: [],
    screenObserver: Ember.observer('screen.isSmallAndDown', function () {
      var isMobile = this.get('screen.isSmallAndDown');

      if (isMobile) {
        this.set('numberOfDays', 1);
      } else {
        this.set('numberOfDays', 7);
      }
    }).on('init'),

    /**
     * On smaller screens only show one day
     */
    numberOfDays: 7,
    plannerActive: Ember.computed(function () {
      return this.get('permissions.plannerActive');
    }),
    showPlannerAssignments: Ember.computed('plannerActive', 'currentUser.uiState.showPlannerAssignments', function () {
      return this.get('plannerActive') && this.get('currentUser.uiState.showPlannerAssignments');
    }),
    hasPlannerAssignments: Ember.computed.notEmpty('plannerAssignments'),
    showWeeklyPlannerAssignments: Ember.computed.and('showPlannerAssignments', 'accountSettings.planningModeIsWeekly', 'hasPlannerAssignments'),
    showDailyPlannerAssignments: Ember.computed('showPlannerAssignments', 'accountSettings.planningModeIsWeekly', function () {
      return this.get('showPlannerAssignments') && !this.get('accountSettings.planningModeIsWeekly');
    }),
    _activeDay: null,

    /**
     * Indicates active day, must be within active week, and if numberOfDays is 7, then it must be the start of the week
     */
    activeDay: Ember.computed('startOfWeek', 'numberOfDays', {
      get: function get() {
        var startOfWeek = this.get('startOfWeek');
        var endOfWeek = this.get('endOfWeek');
        var activeDay = this.get('_activeDay');
        var numberOfDays = this.get('numberOfDays');

        if (!activeDay || numberOfDays === 1 && (activeDay.isBefore(startOfWeek) || activeDay.isAfter(endOfWeek)) || numberOfDays === 7 && !activeDay.isSame(startOfWeek, 'day')) {
          var today = this.get('clock.day');

          if (numberOfDays !== 7 && today.isAfter(startOfWeek) && today.isBefore(endOfWeek)) {
            activeDay = today;
          } else {
            activeDay = startOfWeek;
          }
        }

        return activeDay;
      },
      set: function set(key, value) {
        this.set('_activeDay', value);
        return value;
      }
    }),
    activeTimeEntryDay: Ember.computed('days', 'activeDay', {
      get: function get() {
        var activeDay = this.get('activeDay');
        var days = this.get('days');
        var timeEntryDay = null;
        days.forEach(function (day) {
          if (day.get('day').isSame(activeDay, 'day')) {
            timeEntryDay = day;
          }
        });
        return timeEntryDay;
      }
    }),
    newTimeEntryDay: Ember.computed('startOfWeek', 'endOfWeek', {
      get: function get() {
        var today = this.get('clock.day');

        if (today.isBefore(this.get('startOfWeek')) || today.isAfter(this.get('endOfWeek'))) {
          return this.get('activeDay');
        }

        return this.get('clock.day');
      }
    }),
    activeDayShortLabel: Ember.computed('activeDay', function () {
      var activeDay = this.get('activeDay');
      return activeDay ? activeDay.format('dd[ ]D[.]') : '';
    }),
    activeDayMonthYearLabel: Ember.computed('activeDay', function () {
      var activeDay = this.get('activeDay');
      return activeDay ? activeDay.format('MMMM[ ]YYYY') : '';
    }),
    days: [],
    _generateDays: function _generateDays() {
      var _this = this;

      // clean up observers of previous days
      var prevDays = this.get('days');
      prevDays.forEach(function (day) {
        try {
          day.destroy();
        } catch (err) {
          console.warn('Day could not be destroyed ', err);
        }
      });
      var days = [];
      var timerModeIsDuration = this.get('timerModeIsDuration');
      var numberOfDays = this.get('numberOfDays');
      var currentDay = this.get('activeDay').clone();
      var timeEntries = this.get('timeEntries');
      var absences = this.get('absences');
      var plannerAssignments = this.get('plannerAssignments');
      var userHolidays = this.get('userHolidays');
      var user = this.get('userObj');
      var currentUser = this.get('currentUser');
      var userWorkTimeCheck = this.get('userWorkTimeCheck');
      var dayValidations = userWorkTimeCheck && userWorkTimeCheck.days;
      var userHoursExpectedArray = this.get('workingDaysService').getWorkingHoursArray(user, this.get('startOfWeek'), this.get('endOfWeek'), absences);
      /**
       * Some elements are matched based on the day id and some users are matched based on the day.
       */

      var checkForAccuracyForDay = function checkForAccuracyForDay(dayElement, dayToCompare) {
        return dayElement.day === dayToCompare.weekday() || moment(dayElement.day).isSame(dayToCompare, 'day');
      }; // iterate over weekdays to predefine groups


      var _loop = function _loop(i) {
        var _day = currentDay.clone();

        var _dayFmt = _day.format(_environment.default.constants.DATE_FORMAT_DAY);

        var timeEntryDay = _day2.default.create({
          clock: _this.get('clock'),
          screen: _this.get('screen'),
          timerModeIsDuration: timerModeIsDuration,
          day: _day,
          user: user,
          milestones: _this.milestones.filter(function (milestone) {
            return moment(milestone.day).format(_environment.default.constants.DATE_FORMAT_DAY) === _dayFmt;
          }),
          validation: dayValidations && dayValidations.filter(function (el) {
            return checkForAccuracyForDay(el, _day);
          })[0],
          timeEntries: timeEntries && timeEntries.filter(function (timeEntry) {
            return timeEntry.get('dayFmt') === _dayFmt;
          }),
          currentUser: currentUser,
          absences: absences,
          plannerAssignments: plannerAssignments,
          userHolidays: userHolidays,
          expectedHours: userHoursExpectedArray[i] ? userHoursExpectedArray[i]['expectedHours'] : 0,
          isWorking: userHoursExpectedArray[i] ? userHoursExpectedArray[i]['isWorking'] : false
        });

        days.push(timeEntryDay); // add next day

        currentDay.add(1, 'd');
      };

      for (var i = 0; i < numberOfDays; i++) {
        _loop(i);
      }

      this.set('days', days);
      this.set('isLoading', false);
    },
    assignedUsers: Ember.computed('userAssignments.[]', 'users.[]', 'users.@each.status', function () {
      var currentPermissions = this.get('application.currentPermissions');
      var users = this.get('users');
      var userAssignments = this.get('userAssignments');

      if (userAssignments && Ember.get(userAssignments, 'length') > 0 && users && Ember.get(users, 'length') > 0) {
        var activeUsers = users.filterBy('isActive', true);

        if (currentPermissions === _environment.default.constants.ROLE_ADMIN || this.get('permissions.canManageAllProjects')) {
          return activeUsers;
        }

        var ourUserAssignmentsAsPM = userAssignments.filterBy('user.id', this.get('currentUser.id')).filterBy('isProjectManager', true); // find all user assignments where project equals ourUserAssignmentsAsPM.project

        var userAssignmentsWhereIsPM = userAssignments.filter(function (ua) {
          return ourUserAssignmentsAsPM.isAny('project.id', ua.get('project.id')) && ua.get('project.isActive');
        });
        var currentUserTeamId = this.get('permissions.canViewTeamTimeEntries') ? this.get('currentUser.team.id') : -1;
        return activeUsers.filter(function (user) {
          var isInProjectAsPm = userAssignmentsWhereIsPM.findBy('user.id', user.id);
          var isInTeam = user.get('team.id') === currentUserTeamId;
          return isInProjectAsPm || isInTeam;
        });
      }

      return [];
    }),
    userHoursExpected: Ember.computed('days.@each.expectedHours', function () {
      var hoursToWorkArray = this.get('days');
      return hoursToWorkArray.reduce(function (prev, el) {
        return prev + el.expectedHours;
      }, 0);
    }),
    userHoursTotal: Ember.computed('days.@each.hoursTotal', function () {
      return this.get('days').reduce(function (sum, day) {
        return sum + day.hoursTotal;
      }, 0);
    }),

    /**
     * allow concurrent timeeentry setting from account settings
     */
    generalAllowConcurrentTimeEntries: Ember.computed.alias('accountSettings.allowConcurrentTimeEntries'),

    /**
     * allow break setting from account settings
     */
    allowBreaks: Ember.computed.alias('accountSettings.allowBreaks'),

    /**
     * timerModeIsDuration setting from account settings
     */
    timerModeIsDuration: Ember.computed.readOnly('accountSettings.timerModeIsDuration'),
    timerModeIsStartTimeEndTime: Ember.computed.readOnly('accountSettings.timerModeIsStartTimeEndTime'),
    activeTimeEntryQuality: Ember.computed.readOnly('accountSettings.activeTimeEntryQuality'),
    userWorkTimeCheck: null,
    _updateCheckWorktime: function _updateCheckWorktime() {
      var _this2 = this;

      var user = this.get('user') ? this.store.peekRecord('user', this.get('user')) : this.get('currentUser');
      var currentUser = this.get('currentUser');
      var startDate = this.get('startOfWeek').format(_environment.default.constants.DATE_FORMAT_DAY);
      var endDate = this.get('endOfWeek').format(_environment.default.constants.DATE_FORMAT_DAY);

      if (user && user.get('supervisor.id')) {
        this.get('session').getJSON((0, _buildUrl.default)(this.store, 'validate/checkUserWorktime', {
          startDate: startDate,
          endDate: endDate,
          users: JSON.stringify([user.get('id')])
        })).then(function (userWorkTimeCheck) {
          var userData = userWorkTimeCheck && userWorkTimeCheck.userData && userWorkTimeCheck.userData[0];

          if (userData) {
            _this2.set('userWorkTimeCheck', userData);

            _this2._generateDays();
          }
        }).catch(function (err) {
          resolve();
        });
      } else {
        this._generateDays();
      }
    },
    _localeDidChange: Ember.observer('i18n.locale', function () {
      Ember.run.once(this, '_afterTimeEntriesChanged');
    }),
    _mobileObserver: Ember.observer('activeDay', 'activeWeek', 'timeEntries.length', function () {
      if (this.get('numberOfDays') === 1 && this.get('timeEntries.length') >= 0) {
        this._generateDays();
      }
    }),
    _mainObserver: Ember.observer('activeWeek', 'timeEntries.@each.dayFmt', // do NOT observe @each.day because this seems to be updated every time the modal opens
    'timeEntries.@each.comment', 'timeEntries.@each.duration', 'timeEntries.@each.durationRounded', 'timeEntries.@each.amount', 'timeEntries.[]', 'timeEntries.length', 'absences.[]', 'absences.length', 'absences.@each.startDate', 'absences.@each.endDate', 'absences.@each.duration', 'numberOfDays', 'timerModeIsDuration', function () {
      if (!this.get('isResizing') && !this.get('isDragging') && this.get('numberOfDays') === 7) {
        Ember.run.once(this, '_afterTimeEntriesChanged');
      }
    }),
    _afterTimeEntriesChanged: function _afterTimeEntriesChanged() {
      var _this3 = this;

      Ember.run.later(function () {
        _this3._updateCheckWorktime();
      }, 300);
    },
    timeEntryDayChanged: Ember.observer('timeEntries.@each.dayFmt', function () {
      Ember.run.once(this, '_afterTimeEntryDayChanged');
    }),
    _afterTimeEntryDayChanged: function _afterTimeEntryDayChanged() {
      var timeEntries = this.get('timeEntries');
      var startOfWeek = this.get('startOfWeek');
      var endOfWeek = this.get('endOfWeek');
      var entriesNotInThisWeek = timeEntries.filter(function (entry) {
        var day = entry.get('day');
        return !(0, _date.isInRange)(day, startOfWeek, endOfWeek);
      });
      timeEntries.removeObjects(entriesNotInThisWeek);
    },

    /**
     * @return array of active users sorted by currentUser, lastname
     */
    userSortKeys: _environment.default.constants.USER_SORT_KEYS,
    _userSorted: Ember.computed.sort('assignedUsers', 'userSortKeys'),
    usersSorted: Ember.computed('_userSorted', {
      get: function get() {
        var userSorted = this.get('_userSorted');
        var currentUser = this.get('currentUser'); // make sure currentUser is at first position

        userSorted = userSorted.removeObject(currentUser);
        userSorted.unshiftObject(currentUser);
        return userSorted;
      }
    }),
    _bailIfUserParamIsNotWithinUserList: Ember.observer('user', 'assignedUsers.@each.id', 'assignedUsers.length', function () {
      var userId = this.get('user');
      var assignedUsers = this.get('assignedUsers');
      var assignedUsersLength = this.get('assignedUsers.length');
      var currentUserId = this.get('currentUser.id');

      if (assignedUsersLength) {
        var found = assignedUsers.any(function (item) {
          return item.get('id') === userId;
        });

        if (!found) {
          this.set('user', currentUserId);
        }
      }
    }),

    /**
     * @return date object of selected week's first day
     */
    startOfWeek: Ember.computed('week', {
      get: function get() {
        var week = this.get('week');
        var weekObj = moment(week, _environment.default.constants.DATE_FORMAT_DAY); // make sure week starts at the correct start of an isoweek

        var startOfWeek = weekObj.startOf('week');
        return startOfWeek;
      }
    }),

    /**
     * @return date object of selected week's last day
     */
    endOfWeek: Ember.computed('week', {
      get: function get() {
        var week = this.get('week');
        var weekObj = moment(week, _environment.default.constants.DATE_FORMAT_DAY); // make sure week ends at the correct start of an isoweek

        return weekObj.endOf('week');
      }
    }),

    /**
     *
     * @param timeEntries
     * @param timeEntryId
     * @returns number index of timeEntry in the array
     * @private
     */
    _findEntryIndex: function _findEntryIndex(timeEntries, timeEntryId) {
      for (var i = 0; i < timeEntries.length; i++) {
        if (timeEntries[i].get('id') === timeEntryId) return i;
      }

      return -1;
    },

    /**
     * @returns boolean true when weekends should be hidden
     */
    hideWeekends: Ember.computed('currentUser.uiState.visibleDaysPerWeek', function () {
      return this.get('currentUser.uiState.visibleDaysPerWeek') === 5;
    }),
    showWeekends: Ember.computed('currentUser.uiState.visibleDaysPerWeek', function () {
      return this.get('currentUser.uiState.visibleDaysPerWeek') === 7;
    }),
    showSensitiveData: Ember.computed('currentUser.uiState.showSensitiveData', function () {
      return this.get('currentUser.uiState.showSensitiveData');
    }),
    shortTimeEntryTag: Ember.computed('currentUser.uiState.timeEntryLabelStyle', function () {
      return this.get('currentUser.uiState.timeEntryLabelStyle') === _environment.default.constants.COMPACT_TIME_ENTRY_LABEL;
    }),
    showStartAndEndTime: Ember.computed.and('currentUser.uiState.showStartAndEndTime', 'timerModeIsStartTimeEndTime'),
    showTimeEntryQuality: Ember.computed.and('currentUser.uiState.showTimeEntryQuality', 'activeTimeEntryQuality'),
    allowConcurrentTimeEntries: Ember.computed('currentUser.allowConcurrentTimeEntries', 'generalAllowConcurrentTimeEntries', function () {
      return this.get('currentUser.allowConcurrentTimeEntries') && this.get('generalAllowConcurrentTimeEntries');
    }),
    curretUserIsPmForSelectedUser: Ember.computed('currentUser', 'selectedUser', function () {
      if (this.currentUser.role.canManageAllProject) return true;
      return this.projectService.userIsAdminOrPmOfAnyProject(this.selectedUser.userAssignments.map(function (el) {
        return el.project;
      }), this.currentUser);
    }),
    userCanCreateTimeEntry: Ember.computed('curretUserIsPmForSelectedUser', 'currentUser.isAdmin', 'currentUserIsOwnerOfTimeEntry', 'validation.isApproved', function () {
      if (this.get('validation.isApproved')) {
        return false;
      }

      return this.get('currentUserIsOwnerOfTimeEntry') || this.get('currentUser.isAdmin') || this.get('curretUserIsPmForSelectedUser');
    }),
    userCanCreateBreaks: Ember.computed('currentUserIsOwnerOfTimeEntry', 'validation.isApproved', function () {
      return !this.get('validation.isApproved') && this.get('currentUserIsOwnerOfTimeEntry');
    }),
    currentUserIsOwnerOfTimeEntry: Ember.computed('currentUser.id', 'userObj.id', function () {
      return this.get('currentUser.id') === this.get('userObj.id');
    }),
    currentUserIsPmOfTimeEntry: false,
    timeEntryDuplicate: null,
    selectedUser: Ember.computed('user', 'users', function () {
      var _this4 = this;

      var users = this.get('users');

      if (!users) {
        return null;
      }

      return users.find(function (x) {
        return x.id === _this4.get('user');
      });
    }),
    plannerAssignmentBar: Ember.computed('plannerAssignments', 'timeEntries.[]', 'timeEntries.@each.duration', 'absences.[]', function () {
      var _this5 = this;

      var weeklyAllocations = this.get('plannerAssignments').map(function (assignment) {
        return {
          assignment: assignment,
          allocation: assignment.allocationForPeriod(_this5.get('startOfWeek'), _this5.get('endOfWeek'))
        };
      }); // Theoretically only one assignment per project in weekly mode, but you never know

      var allocationsByProject = this.get('plannerAssignments').reduce(function (assignmentMap, assignment) {
        var projectId = assignment.get('project.id');
        assignmentMap[projectId] = assignmentMap[projectId] ? [].concat(_toConsumableArray(assignmentMap[projectId]), [assignment]) : [assignment];
        return assignmentMap;
      }, {});
      var timeEntriesByProject = this.get('timeEntries').filter(function (timeEntry) {
        return timeEntry.isWork;
      }).reduce(function (projectMap, timeEntry) {
        var projectId = timeEntry.get('project.id');
        projectMap[projectId] = projectMap[projectId] ? [].concat(_toConsumableArray(projectMap[projectId]), [timeEntry]) : [timeEntry];
        return projectMap;
      }, {}); // De-dupe'd list of ProjectIds

      var allProjectIds = _toConsumableArray(new Set([].concat(_toConsumableArray(Object.keys(allocationsByProject)), _toConsumableArray(Object.keys(timeEntriesByProject)))));

      var assignmentBars = [];
      var trackedBars = [];
      var startDate = this.get('startOfWeek');
      var endDate = this.get('endOfWeek');

      var _round = function _round(val) {
        return Math.round(val * 10) / 10;
      };

      allProjectIds.forEach(function (projectId) {
        var assignments = allocationsByProject[projectId];
        var entries = timeEntriesByProject[projectId] || [];
        var trackedTime = entries.reduce(function (total, entry) {
          return total + entry.duration;
        }, 0) / 3600;
        var allocationTotal = 0;

        if (assignments) {
          allocationTotal = assignments.reduce(function (total, assignment) {
            return total + assignment.allocationForPeriod(startDate, endDate, _this5.get('absences'));
          }, 0);
          assignmentBars.push({
            project: assignments[0].get('project'),
            displayValue: _round(allocationTotal - trackedTime),
            planned: allocationTotal,
            comment: assignments[0].get('comment'),
            tracked: trackedTime,
            isFaded: true
          });
        }

        if (entries.length) {
          trackedBars.push({
            project: entries[0].get('project'),
            comment: assignments === null || assignments === void 0 ? void 0 : assignments[0].get('comment'),
            displayValue: _round(trackedTime),
            planned: allocationTotal,
            tracked: trackedTime
          });
        }
      });
      return [].concat(trackedBars, assignmentBars);
    }),
    mobileWeeklyAssignmentBar: Ember.computed('plannerAssignmentBar', function () {
      return this.get('plannerAssignmentBar').filter(function (_ref) {
        var isFaded = _ref.isFaded;
        return isFaded;
      }); //.map((bar) => ({...bar, idFaded: false}));
    }),

    /**
     * set of actions
     */
    actions: {
      /**
       * toggle the weekend display
       */
      toggleWeekends: function toggleWeekends() {
        var days = this.get('currentUser.uiState.visibleDaysPerWeek') === 5 ? 7 : 5;
        this.get('currentUserService').saveUISettings({
          visibleDaysPerWeek: days
        });
      },
      toggleShowStartAndEndTime: function toggleShowStartAndEndTime() {
        var showStartAndEndTime = !this.get('currentUser.uiState.showStartAndEndTime');
        this.get('currentUserService').saveUISettings({
          showStartAndEndTime: showStartAndEndTime
        });
      },
      toggleShowTimeEntryQuality: function toggleShowTimeEntryQuality() {
        var showTimeEntryQuality = !this.get('currentUser.uiState.showTimeEntryQuality');
        this.get('currentUserService').saveUISettings({
          showTimeEntryQuality: showTimeEntryQuality
        });
      },
      toggleShowPlannerAssignments: function toggleShowPlannerAssignments() {
        var showPlannerAssignments = !this.get('currentUser.uiState.showPlannerAssignments');
        this.get('currentUserService').saveUISettings({
          showPlannerAssignments: showPlannerAssignments
        });
      },
      toggleConcurrentTimeEntries: function toggleConcurrentTimeEntries() {
        var allowConcurrentTimeEntries = !this.get('currentUser.allowConcurrentTimeEntries');
        this.get('currentUserService').saveUserSetting('allowConcurrentTimeEntries', allowConcurrentTimeEntries);
      },
      toggleShowSensitiveData: function toggleShowSensitiveData() {
        var showSensitiveData = this.get('currentUser.uiState.showSensitiveData');
        this.get('currentUserService').saveUISettings({
          showSensitiveData: !showSensitiveData
        });
      },
      toggleTimeEntryLabelStyle: function toggleTimeEntryLabelStyle() {
        var timeEntryLabelStyle = this.get('currentUser.uiState.timeEntryLabelStyle');
        timeEntryLabelStyle = this.get('shortTimeEntryTag') ? _environment.default.constants.LONG_TIME_ENTRY_LABEL : _environment.default.constants.COMPACT_TIME_ENTRY_LABEL;
        this.get('currentUserService').saveUISettings({
          timeEntryLabelStyle: timeEntryLabelStyle
        });
      },
      setTimeEntryBackgroundColor: function setTimeEntryBackgroundColor(item) {
        this.get('currentUserService').saveUISettings({
          timeEntryBackgroundColor: parseInt(item, 10)
        });
      },
      moveTimeEntry: function moveTimeEntry(timeEntry) {//console.log('time entry action');
      },
      startDragTimeEntry: function startDragTimeEntry(timeEntry) {
        this.set('isDragging', true);
      },

      /**
       * after the user has stopped dragging the time entry it can be sorted into the list of the day
       * and the time entry model can be saved.
       *
       * @param data
       */
      endDragTimeEntry: function endDragTimeEntry(data, jObj) {
        var _this6 = this;

        var timeEntryDuplicate = this.get('timeEntryDuplicate');

        if (timeEntryDuplicate) {
          // just to make sure all required values exist
          this.get('timeEntryService').beforeSave(timeEntryDuplicate).then(function () {
            timeEntryDuplicate.save().then(function () {
              _this6.get('timeEntries').pushObject(timeEntryDuplicate);

              _this6.set('isDragging', false);
            }).catch(function (err) {
              timeEntryDuplicate.rollbackAttributes();

              _this6.set('isDragging', false);

              _this6.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'timeEntry');
            });
          });
          this.set('timeEntryDuplicate', null);
        }

        if (this.get('timerModeIsDuration')) {
          this.send('endDragTimeEntryWithSorting', data, jObj);
          this.set('isDragging', false);
        } else {
          // do nothing and cancel event if user tries to sort entries within the same day-list
          if (data.withinList) {
            data.cancelDrag = true;
            this.set('isDragging', false);
            return false;
          }

          this.send('endDragTimeEntryWithStartEnd', data, jObj);
          this.set('isDragging', false);
        }
      },
      endDragTimeEntryWithStartEnd: function endDragTimeEntryWithStartEnd(data, jObj) {
        var _this7 = this;

        var day = data.timeEntryDay.get('day'); // get the current time entry entity that has been dragged

        var timeEntry = this.get('timeEntries').findBy('id', data.timeEntryIds.timeEntryCurrent); // stop timer if day is not today

        if (!day.isSame(moment(), 'day')) {
          this.get('timeEntryService').stopTimeEntry(timeEntry, {
            noSave: true
          });
        } // save the new date and sorting


        timeEntry.set('day', day); // just to make sure all required values exist

        this.get('timeEntryService').beforeSave(timeEntry).then(function () {
          timeEntry.save().catch(function (err) {
            timeEntry.rollbackAttributes();

            _this7.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'timeEntry');
          });
        });
      },
      endDragTimeEntryWithSorting: function endDragTimeEntryWithSorting(data, jObj) {
        var _this8 = this;

        var day = data.timeEntryDay.get('day'); // get ids from the data

        var idPrev = data.timeEntryIds.timeEntryPrevious;
        var idCurr = data.timeEntryIds.timeEntryCurrent;
        var idNext = data.timeEntryIds.timeEntryNext; // get the current time entry entity that has been dragged

        var timeEntry = this.get('timeEntries').findBy('id', idCurr); // get entry items

        var entriesForDaySorted = data.timeEntryDay.get('entriesForDaySorted').toArray();
        var index; // check what process to be performed on the dragged entry

        if (typeof idPrev == 'undefined' && typeof idNext == 'undefined') {
          // just add the time entry to the list since there's no previous or next entry in the day
          index = 1;
        } else if (typeof idPrev == 'undefined') {
          // add the time entry to the beginning of the list, since there's no previous entry in the day
          index = 1;
        } else if (typeof idNext == 'undefined') {
          // add the time entry to the end of the list, since there's no next entry in the day
          index = entriesForDaySorted.length + 1;
        } else {
          // add the time entry in between the existing entries
          index = this._findEntryIndex(entriesForDaySorted, idNext) + 1;
        } // remove the entry if it's already in the list to be able to insert it correctly


        var indexTimeEntry = this._findEntryIndex(entriesForDaySorted, idCurr);

        if (indexTimeEntry > -1) {
          entriesForDaySorted.splice(indexTimeEntry, 1);
        }

        var promises = [];
        var newSorting = 1;
        entriesForDaySorted.forEach(function (item, i) {
          if (newSorting === index) {
            newSorting++;
          }

          if (item.get('isEditEnabled')) {
            item.set('sorting', newSorting);
            promises.push(item.save());
          }

          newSorting++;
        }); // stop timer if day is not today

        if (!day.isSame(moment(), 'day')) {
          this.get('timeEntryService').stopTimeEntry(timeEntry, {
            noSave: true
          });
        } // save the new date and sorting


        timeEntry.set('sorting', index);
        timeEntry.set('day', day);
        promises.push(timeEntry.save());
        Ember.RSVP.all(promises).catch(function (err) {
          _this8.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'timeEntry');
        });
      },
      overDragTimeEntry: function overDragTimeEntry() {//console.log('time entry action over');
      },
      outDragTimeEntry: function outDragTimeEntry() {//console.log('time entry action out');
      },
      startResizeTimeEntry: function startResizeTimeEntry(data) {
        this.set('isResizing', true);
        var days = this.get('days');
        days.forEach(function (day) {
          return day.set('isResizing', true);
        });
      },

      /**
       * will be called when the time entry is currently being re-sized by the user
       * the model will not be saved until the resize event stopped.
       *
       * @param data
       */
      resizeTimeEntry: function resizeTimeEntry(data) {
        this.set('isResizing', true); // get the current the time entry entity that has been dragged

        var timeEntry = this.get('timeEntries').findBy('id', data.timeEntryId);
        var property = data.propertyToUpdateOnResize;
        var duration = (0, _formatDuration.calculateTimeFromUIHeight)(data.timeEntrySize);

        if (property !== null) {
          if (property === _environment.default.constants.SYNC) {
            timeEntry.set('durationRounded', duration);
            timeEntry.set('duration', duration);
          } else {
            timeEntry.set(property, duration);
          }
        }
      },

      /**
       * will be called when the user has stopped resizing the time entry.
       * since the size has already been calculated, just save the model.
       *
       * @param data
       */
      stopResizeTimeEntry: function stopResizeTimeEntry(data) {
        // get the current time of the entry entity that has been dragged
        var timeEntry = this.get('timeEntries').findBy('id', data.timeEntryId);
        var timeEntryService = this.get('timeEntryService');
        var minuteSnap = data.resizeMinuteSnap || _environment.default.constants.RESIZE_MINUTE_SNAP;
        var property = data.propertyToUpdateOnResize;
        var factor = 60 * minuteSnap;
        var duration = Math.ceil((0, _formatDuration.calculateTimeFromUIHeight)(data.timeEntrySize) / factor) * factor;

        if (property == _environment.default.constants.SYNC) {
          var projectRoundingType = timeEntry.get('project.roundingType');
          var projectRoundingAmount = timeEntry.get('projectRoundingAmount');
          var durationRounded = (0, _formatDuration.getRoundedDuration)(duration, projectRoundingAmount, projectRoundingType);
          timeEntry.set('durationRounded', durationRounded);
          timeEntryService.setDuration(timeEntry, duration);
        }

        if (property == 'durationRounded') {
          var _projectRoundingType = timeEntry.get('project.roundingType');

          var _projectRoundingAmount = timeEntry.get('projectRoundingAmount');

          var _durationRounded = (0, _formatDuration.getRoundedDuration)(duration, _projectRoundingAmount, _projectRoundingType);

          timeEntry.set(property, _durationRounded);
        }

        if (property == 'duration') {
          timeEntryService.setDuration(timeEntry, duration);
        }

        if (this.get('timerModeIsStartTimeEndTime')) {
          timeEntry.set('running', false);
        } // call beforeSave to make sure all required values exist


        this.get('timeEntryService').beforeSave(timeEntry).then(function () {
          timeEntry.save();
        });
        this.set('isResizing', false);
        var days = this.get('days');
        days.forEach(function (day) {
          return day.set('isResizing', false);
        });
      },
      timeEntryCreated: function timeEntryCreated(data) {
        var timeEntries = this.get('timeEntries');

        if (timeEntries === null) {
          // we've an ugly situation where timeEntries might be null when this is called
          return;
        }

        var entry = data.entry;
        var switchUser = !!data.switchUser;
        var id = !Ember.isEmpty(data.id) ? data.id : entry.get('id');

        if (Ember.isEmpty(id)) {
          return;
        }

        if (timeEntries.findBy('id', id)) {
          return;
        }

        var userId = this.get('user'); // Need to make sure time entry is relevant to currently displayed user

        if (entry.get('user.id') === userId) {
          var day = entry.get('day');
          var startOfWeek = this.get('startOfWeek');
          var endOfWeek = this.get('endOfWeek');

          if (day.isSameOrAfter(startOfWeek) && day.isSameOrBefore(endOfWeek)) {
            this.get('timeEntries').pushObject(entry);
          }
        } else {
          if (switchUser === true) {
            this.set('user', entry.get('user.id'));
          }
        }
      },
      timeEntryDeleted: function timeEntryDeleted(data) {
        if (this.get('timeEntries') === null) {
          // we've an ugly situation where timeEntries might be null when this is called
          return;
        }

        var entry = data.entry;
        this.get('timeEntries').removeObject(entry);
      },
      duplicateTimeEntry: function duplicateTimeEntry(timeEntry) {
        var duplicate = this.get('timeEntryDuplicate'); // There isn't already a duplicate

        if (!duplicate) {
          var timeEntryDuplicate = this.store.createRecord('time-entry', timeEntry.clone());
          this.set('timeEntryDuplicate', timeEntryDuplicate);
        }
      },
      cancelDuplicateTimeEntry: function cancelDuplicateTimeEntry() {
        var duplicate = this.get('timeEntryDuplicate');

        if (duplicate) {
          duplicate.rollbackAttributes();
          this.set('timeEntryDuplicate', null);
        }
      },
      playTimeEntry: function playTimeEntry(timeEntry) {
        this.get('timeEntryService').startTimeEntry(timeEntry);
      },
      pauseTimeEntry: function pauseTimeEntry(timeEntry) {
        this.get('timeEntryService').stopTimeEntry(timeEntry);
      },
      prevDay: function prevDay() {
        var activeDay = moment(this.get('activeDay'));
        activeDay.subtract(1, 'day'); // parsing again startOfWeek to prevent a date shift

        var startOfWeek = moment(this.get('startOfWeek')).startOf('week'); // Changing week, need to alter week first

        if (activeDay.isBefore(startOfWeek, 'day')) {
          startOfWeek.subtract(1, 'week');
          this.set('week', startOfWeek.format(_environment.default.constants.DATE_FORMAT_DAY));
        }

        this.set('activeDay', activeDay);
      },
      nextDay: function nextDay() {
        var activeDay = this.get('activeDay');
        activeDay.add(1, 'day');
        var endOfWeek = this.get('endOfWeek'); // Changing week, need to alter week first

        if (activeDay.isAfter(endOfWeek)) {
          var startOfWeek = this.get('startOfWeek');
          startOfWeek.add(1, 'week');
          this.set('week', startOfWeek.format(_environment.default.constants.DATE_FORMAT_DAY));
        }

        this.set('activeDay', activeDay.clone());
      },
      absenceCreated: function absenceCreated(data) {
        var absence = data.entry;
        var startDate = absence.get('startDate');
        var startOfWeek = this.get('startOfWeek');
        var endOfWeek = this.get('endOfWeek');

        if (startDate.isSameOrAfter(startOfWeek) && startDate.isSameOrBefore(endOfWeek)) {
          var absences = this.get('absences');
          absences.pushObject(absence);
        }
      },
      absenceDeleted: function absenceDeleted(data) {
        var entry = data.entry;
        this.get('absences').removeObject(entry);
      }
    }
  });
  var _default = TimeEntriesController;
  _exports.default = _default;
});