define("coffeecup/controllers/projects/tasks/edit", ["exports", "coffeecup/controllers/manage/_super-edit", "ember-changeset", "ember-changeset-validations", "coffeecup/validations/task", "coffeecup/mixins/manage-tags", "coffeecup/utils/strip-tags"], function (_exports, _superEdit, _emberChangeset, _emberChangesetValidations, _task, _manageTags, _stripTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //controllers/projects/tasks/edit.js
  var _default = _superEdit.default.extend(_manageTags.default, {
    isQuickCreate: false,

    /**
     * returns a headline for the detailpane
     * @return {Handlebars.SafeString} a string that will not be html escaped by Handlebars
     */
    headline: Ember.computed('model.label', function () {
      var str = this.get('model.label');
      str = (0, _stripTags.default)(str);
      return Ember.String.htmlSafe(str);
    }),

    /**
     * array of colors
     */
    colors: null,

    /**
     * Name (key) of input field to put focus on
     */
    focus: 'label',

    /**
     * selected project
     */
    model: null,
    errors: {},
    TaskValidations: _task.default,

    /**
     * If the task label consists out of 1 or 2 words, the words small vowels are removed and the first 2 characters of
     * each word are combined as the task's tag code. If the task label consists out of 3 or more words, each first
     * character of a word is used in the task's tag code.
     */
    _generatedCodeTag: Ember.observer('model.label', function () {
      if (this.get('model.hasDirtyAttributes')) {
        var existingCodes = this.get('store').peekAll('task').getEach('code').removeObject(this.get('model.code'));
        var label = this.get('model.label') || '';
        this.set('model.code', this.createShortTag(label, 5, existingCodes));
      }
    }),
    actions: {
      backAction: function backAction() {
        this.transitionToRoute('projects.tasks');
      },
      saveTask: function saveTask() {
        var _this = this;

        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var skipNotification = options.skipNotification;
        Ember.run.next(function () {
          _this.set('errors', {});

          if (!_this.get('isQuickCreate') && _this.get('model.isDirty')) {
            var changeset = (0, _emberChangeset.Changeset)(_this.get('model'), (0, _emberChangesetValidations.default)(_task.default), _task.default);
            changeset.validate().then(function () {
              if (changeset.get('isValid')) {
                _this.get('model').save().then(function () {
                  if (!skipNotification) _this.get('notifications').success('alert.saved', 'task');
                }).catch(function (err) {
                  _this.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'task');
                });
              } else {
                _this.set('errors', changeset.error);

                _this.get('notifications').info('pleaseCheckYourInputs');
              }
            }).catch(function () {
              _this.get('notifications').info('pleaseCheckYourInputs');
            });
          }
        });
      },
      resetTask: function resetTask() {
        this.get('model').rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});