define("coffeecup/components/input/cc-clipboard-field", ["exports", "coffeecup/components/input/cc-text-field", "coffeecup/templates/components/input/cc-text-field"], function (_exports, _ccTextField, _ccTextField2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ccTextField.default.extend({
    layout: _ccTextField2.default,
    inputClass: 'no-overflow',
    copyButtonSelector: null,
    onCopySuccess: null,
    onCopyError: null,
    _clipboard: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var $input = this.$('input');
      var copyButtonSelector = this.get('copyButtonSelector');

      if (copyButtonSelector) {
        var clipboard = new Clipboard(copyButtonSelector, {
          target: function target() {
            return $input.get(0);
          }
        });
        var onCopySuccess = this.get('onCopySuccess');

        if (onCopySuccess) {
          clipboard.on('success', onCopySuccess);
        }

        var onCopyError = this.get('onCopyError');

        if (onCopyError) {
          clipboard.on('error', onCopyError);
        }

        this.set('_clipboard', clipboard);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var clipboard = this.get('_clipboard');

      if (clipboard) {
        clipboard.destroy();
      }
    }
  });

  _exports.default = _default;
});