define("coffeecup/components/time-entry/background", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['background'],
    attributeBindings: ['style'],
    background: true,
    isResizing: false,
    height: null,
    backgroundColor: null,
    style: Ember.computed('height', 'backgroundColor', 'isResizing', {
      get: function get() {
        return Ember.String.htmlSafe("height: ".concat(this.get('height'), "px; background-color: ").concat(this.get('backgroundColor'), ";"));
      }
    })
  });

  _exports.default = _default;
});